[
  {
    "SNo": 1,
    "Rank": 1.01,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2,
    "Rank": 1.02,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3,
    "Rank": 1.03,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4,
    "Rank": 1.04,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5,
    "Rank": 1.05,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6,
    "Rank": 1.06,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7,
    "Rank": 1.07,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8,
    "Rank": 1.08,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9,
    "Rank": 1.09,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10,
    "Rank": 1.1,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11,
    "Rank": 1.11,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12,
    "Rank": 1.12,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13,
    "Rank": 1.13,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14,
    "Rank": 1.14,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15,
    "Rank": 1.15,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16,
    "Rank": 1.16,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17,
    "Rank": 1.17,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18,
    "Rank": 18,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19,
    "Rank": 19,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20,
    "Rank": 20,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21,
    "Rank": 21,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22,
    "Rank": 22,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23,
    "Rank": 23,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24,
    "Rank": 24,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25,
    "Rank": 25,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26,
    "Rank": 26,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 27,
    "Rank": 27,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 28,
    "Rank": 28,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 29,
    "Rank": 29,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 30,
    "Rank": 30,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 31,
    "Rank": 31,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 32,
    "Rank": 32,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 33,
    "Rank": 33,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 34,
    "Rank": 34,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 35,
    "Rank": 35,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 36,
    "Rank": 36,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 37,
    "Rank": 37,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 38,
    "Rank": 38,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 39,
    "Rank": 39,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 40,
    "Rank": 40,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 41,
    "Rank": 41,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 42,
    "Rank": 42,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 43,
    "Rank": 43,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 44,
    "Rank": 44,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 45,
    "Rank": 45,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 46,
    "Rank": 46,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 47,
    "Rank": 47,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 48,
    "Rank": 48,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 49,
    "Rank": 49,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 50,
    "Rank": 50,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 51,
    "Rank": 51,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 52,
    "Rank": 52,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 53,
    "Rank": 53,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 54,
    "Rank": 54,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 55,
    "Rank": 55,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 56,
    "Rank": 56,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 57,
    "Rank": 57,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 58,
    "Rank": 58,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 59,
    "Rank": 59,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 60,
    "Rank": 60,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 61,
    "Rank": 61,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 62,
    "Rank": 62,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 63,
    "Rank": 63,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 64,
    "Rank": 64,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 65,
    "Rank": 65,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 66,
    "Rank": 66,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 67,
    "Rank": 67,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 68,
    "Rank": 68,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 69,
    "Rank": 69,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 70,
    "Rank": 70,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 71,
    "Rank": 71,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 72,
    "Rank": 72,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 73,
    "Rank": 73,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 74,
    "Rank": 74,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 75,
    "Rank": 75,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 76,
    "Rank": 76,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 77,
    "Rank": 77,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 78,
    "Rank": 78,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 79,
    "Rank": 79,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 80,
    "Rank": 80,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 81,
    "Rank": 81,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 82,
    "Rank": 82,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 83,
    "Rank": 83,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 84,
    "Rank": 84,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 85,
    "Rank": 85,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 86,
    "Rank": 86,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 87,
    "Rank": 87,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 88,
    "Rank": 88,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 89,
    "Rank": 89,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 90,
    "Rank": 90,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 91,
    "Rank": 91,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 92,
    "Rank": 92,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 93,
    "Rank": 93,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 94,
    "Rank": 94,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 95,
    "Rank": 95,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 96,
    "Rank": 96,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 97,
    "Rank": 97,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 98,
    "Rank": 98,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 99,
    "Rank": 99,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 100,
    "Rank": 100,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 101,
    "Rank": 101,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 102,
    "Rank": 102,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 103,
    "Rank": 103,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 104,
    "Rank": 104,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 105,
    "Rank": 105,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 106,
    "Rank": 106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 107,
    "Rank": 107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 108,
    "Rank": 108,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 109,
    "Rank": 109,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 110,
    "Rank": 110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 111,
    "Rank": 111,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 112,
    "Rank": 112,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 113,
    "Rank": 113,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 114,
    "Rank": 114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 115,
    "Rank": 115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 116,
    "Rank": 116,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 117,
    "Rank": 117,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 118,
    "Rank": 118,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 119,
    "Rank": 119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 120,
    "Rank": 120,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 121,
    "Rank": 121,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 122,
    "Rank": 122,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 123,
    "Rank": 123,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 124,
    "Rank": 124,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 125,
    "Rank": 125,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 126,
    "Rank": 126,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 127,
    "Rank": 127,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 128,
    "Rank": 128,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 129,
    "Rank": 129,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 130,
    "Rank": 130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 131,
    "Rank": 131,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 132,
    "Rank": 132,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 133,
    "Rank": 133,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 134,
    "Rank": 134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 135,
    "Rank": 135,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 136,
    "Rank": 136,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 137,
    "Rank": 137,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 138,
    "Rank": 138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 139,
    "Rank": 139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 140,
    "Rank": 140,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 141,
    "Rank": 141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 142,
    "Rank": 142,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 143,
    "Rank": 143,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 144,
    "Rank": 144,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 145,
    "Rank": 145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 146,
    "Rank": 146,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 147,
    "Rank": 147,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 148,
    "Rank": 148,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 149,
    "Rank": 149,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 150,
    "Rank": 150,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 151,
    "Rank": 151,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 152,
    "Rank": 152,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 153,
    "Rank": 153,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 154,
    "Rank": 154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 155,
    "Rank": 155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 156,
    "Rank": 156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 157,
    "Rank": 157,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 158,
    "Rank": 158,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 159,
    "Rank": 159,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 160,
    "Rank": 160,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 161,
    "Rank": 161,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 162,
    "Rank": 162,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 163,
    "Rank": 163,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 164,
    "Rank": 164,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 165,
    "Rank": 165,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 166,
    "Rank": 166,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 167,
    "Rank": 167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 168,
    "Rank": 168,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 169,
    "Rank": 169,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 170,
    "Rank": 170,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 171,
    "Rank": 171,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 172,
    "Rank": 172,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 173,
    "Rank": 173,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 174,
    "Rank": 174,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 175,
    "Rank": 175,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 176,
    "Rank": 176,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 177,
    "Rank": 177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 178,
    "Rank": 178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 179,
    "Rank": 179,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 180,
    "Rank": 180,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 181,
    "Rank": 181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 182,
    "Rank": 182,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 183,
    "Rank": 183,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 184,
    "Rank": 185,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 185,
    "Rank": 186,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 186,
    "Rank": 188,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 187,
    "Rank": 189,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 188,
    "Rank": 190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 189,
    "Rank": 191,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 190,
    "Rank": 192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 191,
    "Rank": 193,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 192,
    "Rank": 194,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 193,
    "Rank": 195,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 194,
    "Rank": 196,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 195,
    "Rank": 197,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 196,
    "Rank": 198,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 197,
    "Rank": 199,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 198,
    "Rank": 200,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 199,
    "Rank": 201,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 200,
    "Rank": 202,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 201,
    "Rank": 203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 202,
    "Rank": 204,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 203,
    "Rank": 205,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 204,
    "Rank": 206,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 205,
    "Rank": 207,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 206,
    "Rank": 208,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 207,
    "Rank": 209,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 208,
    "Rank": 210,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 209,
    "Rank": 211,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 210,
    "Rank": 212,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 211,
    "Rank": 213,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 212,
    "Rank": 214,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 213,
    "Rank": 215,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 214,
    "Rank": 216,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 215,
    "Rank": 217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 216,
    "Rank": 218,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 217,
    "Rank": 219,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 218,
    "Rank": 220,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 219,
    "Rank": 221,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 220,
    "Rank": 222,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 221,
    "Rank": 223,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 222,
    "Rank": 224,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 223,
    "Rank": 225,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 224,
    "Rank": 226,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 225,
    "Rank": 227,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 226,
    "Rank": 228,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 227,
    "Rank": 229,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 228,
    "Rank": 230,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 229,
    "Rank": 231,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 230,
    "Rank": 232,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 231,
    "Rank": 233,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 232,
    "Rank": 234,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 233,
    "Rank": 235,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 234,
    "Rank": 236,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 235,
    "Rank": 237,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 236,
    "Rank": 238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 237,
    "Rank": 239,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 238,
    "Rank": 240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 239,
    "Rank": 241,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 240,
    "Rank": 242,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 241,
    "Rank": 244,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 242,
    "Rank": 245,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 243,
    "Rank": 246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 244,
    "Rank": 247,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 245,
    "Rank": 248,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 246,
    "Rank": 249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 247,
    "Rank": 250,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 248,
    "Rank": 251,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 249,
    "Rank": 252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 250,
    "Rank": 253,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 251,
    "Rank": 254,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 252,
    "Rank": 255,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 253,
    "Rank": 256,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 254,
    "Rank": 257,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 255,
    "Rank": 258,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 256,
    "Rank": 259,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 257,
    "Rank": 260,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 258,
    "Rank": 261,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 259,
    "Rank": 262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 260,
    "Rank": 263,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 261,
    "Rank": 264,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 262,
    "Rank": 265,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 263,
    "Rank": 266,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 264,
    "Rank": 267,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 265,
    "Rank": 268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 266,
    "Rank": 269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 267,
    "Rank": 270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 268,
    "Rank": 271,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 269,
    "Rank": 272,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 270,
    "Rank": 273,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 271,
    "Rank": 274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 272,
    "Rank": 275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 273,
    "Rank": 276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 274,
    "Rank": 277,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 275,
    "Rank": 278,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 276,
    "Rank": 279,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 277,
    "Rank": 280,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 278,
    "Rank": 281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 279,
    "Rank": 282,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 280,
    "Rank": 283,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 281,
    "Rank": 284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 282,
    "Rank": 285,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 283,
    "Rank": 286,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 284,
    "Rank": 287,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 285,
    "Rank": 288,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 286,
    "Rank": 290,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 287,
    "Rank": 291,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 288,
    "Rank": 292,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 289,
    "Rank": 293,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 290,
    "Rank": 294,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 291,
    "Rank": 295,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 292,
    "Rank": 296,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 293,
    "Rank": 297,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 294,
    "Rank": 298,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 295,
    "Rank": 299,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 296,
    "Rank": 300,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 297,
    "Rank": 301,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 298,
    "Rank": 302,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 299,
    "Rank": 303,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 300,
    "Rank": 304,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 301,
    "Rank": 305,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 302,
    "Rank": 306,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 303,
    "Rank": 307,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 304,
    "Rank": 308,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 305,
    "Rank": 309,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 306,
    "Rank": 310,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 307,
    "Rank": 311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 308,
    "Rank": 312,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 309,
    "Rank": 313,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 310,
    "Rank": 314,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 311,
    "Rank": 315,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 312,
    "Rank": 316,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 313,
    "Rank": 317,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 314,
    "Rank": 318,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 315,
    "Rank": 319,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 316,
    "Rank": 320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 317,
    "Rank": 321,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 318,
    "Rank": 322,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 319,
    "Rank": 323,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 320,
    "Rank": 324,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 321,
    "Rank": 325,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 322,
    "Rank": 326,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 323,
    "Rank": 327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 324,
    "Rank": 328,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 325,
    "Rank": 329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 326,
    "Rank": 330,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 327,
    "Rank": 332,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 328,
    "Rank": 333,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 329,
    "Rank": 334,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 330,
    "Rank": 335,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 331,
    "Rank": 336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 332,
    "Rank": 337,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 333,
    "Rank": 338,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 334,
    "Rank": 339,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 335,
    "Rank": 340,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 336,
    "Rank": 341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 337,
    "Rank": 342,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 338,
    "Rank": 343,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 339,
    "Rank": 344,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 340,
    "Rank": 345,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 341,
    "Rank": 346,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 342,
    "Rank": 347,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 343,
    "Rank": 348,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 344,
    "Rank": 349,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 345,
    "Rank": 350,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 346,
    "Rank": 351,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 347,
    "Rank": 352,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 348,
    "Rank": 353,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 349,
    "Rank": 354,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 350,
    "Rank": 355,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 351,
    "Rank": 356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 352,
    "Rank": 357,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 353,
    "Rank": 358,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 354,
    "Rank": 359,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 355,
    "Rank": 360,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 356,
    "Rank": 361,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 357,
    "Rank": 362,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 358,
    "Rank": 363,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 359,
    "Rank": 364,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 360,
    "Rank": 365,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 361,
    "Rank": 366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 362,
    "Rank": 367,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 363,
    "Rank": 368,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 364,
    "Rank": 369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 365,
    "Rank": 370,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 366,
    "Rank": 371,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 367,
    "Rank": 372,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 368,
    "Rank": 373,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 369,
    "Rank": 374,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 370,
    "Rank": 375,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 371,
    "Rank": 376,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 372,
    "Rank": 377,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 373,
    "Rank": 378,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 374,
    "Rank": 379,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 375,
    "Rank": 380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 376,
    "Rank": 381,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 377,
    "Rank": 382,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 378,
    "Rank": 383,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 379,
    "Rank": 384,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 380,
    "Rank": 385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 381,
    "Rank": 386,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 382,
    "Rank": 387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 383,
    "Rank": 388,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 384,
    "Rank": 389,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 385,
    "Rank": 390,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 386,
    "Rank": 391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 387,
    "Rank": 392,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 388,
    "Rank": 393,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 389,
    "Rank": 394,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 390,
    "Rank": 395,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 391,
    "Rank": 396,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 392,
    "Rank": 397,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 393,
    "Rank": 398,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 394,
    "Rank": 399,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 395,
    "Rank": 400,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 396,
    "Rank": 401,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 397,
    "Rank": 402,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 398,
    "Rank": 403,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 399,
    "Rank": 404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 400,
    "Rank": 405,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 401,
    "Rank": 406,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 402,
    "Rank": 407,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 403,
    "Rank": 408,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 404,
    "Rank": 409,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 405,
    "Rank": 410,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 406,
    "Rank": 411,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 407,
    "Rank": 412,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 408,
    "Rank": 413,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 409,
    "Rank": 414,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 410,
    "Rank": 415,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 411,
    "Rank": 416,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 412,
    "Rank": 417,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 413,
    "Rank": 418,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 414,
    "Rank": 419,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 415,
    "Rank": 420,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 416,
    "Rank": 421,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 417,
    "Rank": 422,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 418,
    "Rank": 423,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 419,
    "Rank": 424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 420,
    "Rank": 425,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 421,
    "Rank": 426,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 422,
    "Rank": 427,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 423,
    "Rank": 428,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 424,
    "Rank": 429,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 425,
    "Rank": 430,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 426,
    "Rank": 431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 427,
    "Rank": 432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 428,
    "Rank": 433,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 429,
    "Rank": 434,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 430,
    "Rank": 435,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 431,
    "Rank": 436,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 432,
    "Rank": 437,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 433,
    "Rank": 438,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 434,
    "Rank": 439,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 435,
    "Rank": 440,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 436,
    "Rank": 441,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 437,
    "Rank": 442,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 438,
    "Rank": 443,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 439,
    "Rank": 444,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 440,
    "Rank": 445,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 441,
    "Rank": 446,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 442,
    "Rank": 447,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 443,
    "Rank": 448,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 444,
    "Rank": 449,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 445,
    "Rank": 450,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 446,
    "Rank": 451,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 447,
    "Rank": 452,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 448,
    "Rank": 453,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 449,
    "Rank": 454,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 450,
    "Rank": 455,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 451,
    "Rank": 456,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 452,
    "Rank": 457,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 453,
    "Rank": 458,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 454,
    "Rank": 459,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 455,
    "Rank": 460,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 456,
    "Rank": 461,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 457,
    "Rank": 462,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 458,
    "Rank": 463,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 459,
    "Rank": 464,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 460,
    "Rank": 465,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 461,
    "Rank": 466,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 462,
    "Rank": 467,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 463,
    "Rank": 468,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 464,
    "Rank": 469,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 465,
    "Rank": 470,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 466,
    "Rank": 471,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 467,
    "Rank": 472,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 468,
    "Rank": 473,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 469,
    "Rank": 474,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 470,
    "Rank": 475,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 471,
    "Rank": 476,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 472,
    "Rank": 477,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 473,
    "Rank": 478,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 474,
    "Rank": 479,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 475,
    "Rank": 480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 476,
    "Rank": 481,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 477,
    "Rank": 482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 478,
    "Rank": 483,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 479,
    "Rank": 484,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 480,
    "Rank": 485,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 481,
    "Rank": 486,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 482,
    "Rank": 487,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 483,
    "Rank": 488,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 484,
    "Rank": 489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 485,
    "Rank": 490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 486,
    "Rank": 491,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 487,
    "Rank": 492,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 488,
    "Rank": 493,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 489,
    "Rank": 494,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 490,
    "Rank": 495,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 491,
    "Rank": 496,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 492,
    "Rank": 497,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 493,
    "Rank": 498,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 494,
    "Rank": 499,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 495,
    "Rank": 500,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 496,
    "Rank": 501,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 497,
    "Rank": 502,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 498,
    "Rank": 503,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 499,
    "Rank": 504,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 500,
    "Rank": 505,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 501,
    "Rank": 506,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 502,
    "Rank": 507,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 503,
    "Rank": 508,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 504,
    "Rank": 509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 505,
    "Rank": 510,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 506,
    "Rank": 511,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 507,
    "Rank": 512,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 508,
    "Rank": 513,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 509,
    "Rank": 514,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 510,
    "Rank": 515,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 511,
    "Rank": 516,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 512,
    "Rank": 517,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 513,
    "Rank": 518,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 514,
    "Rank": 519,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 515,
    "Rank": 520,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 516,
    "Rank": 521,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 517,
    "Rank": 522,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 518,
    "Rank": 523,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 519,
    "Rank": 524,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 520,
    "Rank": 525,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 521,
    "Rank": 526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 522,
    "Rank": 527,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 523,
    "Rank": 528,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 524,
    "Rank": 529,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 525,
    "Rank": 530,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 526,
    "Rank": 531,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 527,
    "Rank": 532,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 528,
    "Rank": 533,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 529,
    "Rank": 534,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 530,
    "Rank": 535,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 531,
    "Rank": 536,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 532,
    "Rank": 537,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 533,
    "Rank": 538,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 534,
    "Rank": 539,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 535,
    "Rank": 540,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 536,
    "Rank": 541,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 537,
    "Rank": 542,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 538,
    "Rank": 543,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 539,
    "Rank": 544,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 540,
    "Rank": 545,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 541,
    "Rank": 546,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 542,
    "Rank": 547,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 543,
    "Rank": 548,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 544,
    "Rank": 549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 545,
    "Rank": 550,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 546,
    "Rank": 551,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 547,
    "Rank": 552,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 548,
    "Rank": 553,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 549,
    "Rank": 554,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 550,
    "Rank": 555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 551,
    "Rank": 556,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 552,
    "Rank": 557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 553,
    "Rank": 558,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 554,
    "Rank": 559,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 555,
    "Rank": 560,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 556,
    "Rank": 561,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 557,
    "Rank": 562,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 558,
    "Rank": 563,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 559,
    "Rank": 564,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 560,
    "Rank": 565,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 561,
    "Rank": 566,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 562,
    "Rank": 567,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 563,
    "Rank": 568,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 564,
    "Rank": 569,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 565,
    "Rank": 570,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 566,
    "Rank": 571,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 567,
    "Rank": 572,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 568,
    "Rank": 573,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 569,
    "Rank": 574,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 570,
    "Rank": 575,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 571,
    "Rank": 576,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 572,
    "Rank": 577,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 573,
    "Rank": 578,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 574,
    "Rank": 579,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 575,
    "Rank": 580,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 576,
    "Rank": 581,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 577,
    "Rank": 582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 578,
    "Rank": 583,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 579,
    "Rank": 584,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 580,
    "Rank": 585,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 581,
    "Rank": 586,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 582,
    "Rank": 587,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 583,
    "Rank": 588,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 584,
    "Rank": 589,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 585,
    "Rank": 590,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 586,
    "Rank": 591,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 587,
    "Rank": 592,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 588,
    "Rank": 593,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 589,
    "Rank": 594,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 590,
    "Rank": 595,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 591,
    "Rank": 596,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 592,
    "Rank": 597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 593,
    "Rank": 598,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 594,
    "Rank": 599,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 595,
    "Rank": 600,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 596,
    "Rank": 602,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 597,
    "Rank": 603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 598,
    "Rank": 604,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 599,
    "Rank": 605,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 600,
    "Rank": 606,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 601,
    "Rank": 607,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 602,
    "Rank": 608,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 603,
    "Rank": 609,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 604,
    "Rank": 610,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 605,
    "Rank": 611,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 606,
    "Rank": 612,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 607,
    "Rank": 613,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 608,
    "Rank": 614,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 609,
    "Rank": 615,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 610,
    "Rank": 616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 611,
    "Rank": 617,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 612,
    "Rank": 618,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 613,
    "Rank": 619,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 614,
    "Rank": 620,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 615,
    "Rank": 621,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 616,
    "Rank": 622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 617,
    "Rank": 623,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 618,
    "Rank": 624,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 619,
    "Rank": 625,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 620,
    "Rank": 626,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 621,
    "Rank": 627,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 622,
    "Rank": 628,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 623,
    "Rank": 629,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 624,
    "Rank": 630,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 625,
    "Rank": 631,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 626,
    "Rank": 632,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 627,
    "Rank": 633,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 628,
    "Rank": 634,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 629,
    "Rank": 635,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 630,
    "Rank": 636,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 631,
    "Rank": 637,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 632,
    "Rank": 638,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 633,
    "Rank": 639,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 634,
    "Rank": 640,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 635,
    "Rank": 641,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 636,
    "Rank": 642,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 637,
    "Rank": 643,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 638,
    "Rank": 644,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 639,
    "Rank": 645,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 640,
    "Rank": 646,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 641,
    "Rank": 647,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 642,
    "Rank": 648,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 643,
    "Rank": 649,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 644,
    "Rank": 650,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 645,
    "Rank": 651,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 646,
    "Rank": 652,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 647,
    "Rank": 653,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 648,
    "Rank": 654,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 649,
    "Rank": 655,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 650,
    "Rank": 656,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 651,
    "Rank": 657,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 652,
    "Rank": 658,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 653,
    "Rank": 659,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 654,
    "Rank": 660,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 655,
    "Rank": 661,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 656,
    "Rank": 662,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 657,
    "Rank": 663,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 658,
    "Rank": 664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 659,
    "Rank": 665,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 660,
    "Rank": 667,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 661,
    "Rank": 668,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 662,
    "Rank": 669,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 663,
    "Rank": 670,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 664,
    "Rank": 671,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 665,
    "Rank": 672,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 666,
    "Rank": 673,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 667,
    "Rank": 674,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 668,
    "Rank": 675,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 669,
    "Rank": 676,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 670,
    "Rank": 677,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 671,
    "Rank": 678,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 672,
    "Rank": 679,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 673,
    "Rank": 680,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 674,
    "Rank": 681,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 675,
    "Rank": 682,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 676,
    "Rank": 683,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 677,
    "Rank": 684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 678,
    "Rank": 685,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 679,
    "Rank": 686,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 680,
    "Rank": 687,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 681,
    "Rank": 688,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 682,
    "Rank": 689,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 683,
    "Rank": 690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 684,
    "Rank": 691,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 685,
    "Rank": 692,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 686,
    "Rank": 693,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 687,
    "Rank": 694,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 688,
    "Rank": 695,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 689,
    "Rank": 696,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 690,
    "Rank": 697,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 691,
    "Rank": 698,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 692,
    "Rank": 699,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 693,
    "Rank": 700,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 694,
    "Rank": 701,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 695,
    "Rank": 702,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 696,
    "Rank": 703,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 697,
    "Rank": 704,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 698,
    "Rank": 705,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 699,
    "Rank": 706,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 700,
    "Rank": 707,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 701,
    "Rank": 708,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 702,
    "Rank": 709,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 703,
    "Rank": 710,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 704,
    "Rank": 711,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 705,
    "Rank": 712,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 706,
    "Rank": 713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 707,
    "Rank": 714,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 708,
    "Rank": 715,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 709,
    "Rank": 716,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 710,
    "Rank": 717,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 711,
    "Rank": 718,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 712,
    "Rank": 719,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 713,
    "Rank": 720,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 714,
    "Rank": 721,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 715,
    "Rank": 722,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 716,
    "Rank": 723,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 717,
    "Rank": 724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 718,
    "Rank": 725,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 719,
    "Rank": 726,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 720,
    "Rank": 727,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 721,
    "Rank": 728,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 722,
    "Rank": 729,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 723,
    "Rank": 730,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 724,
    "Rank": 731,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 725,
    "Rank": 732,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 726,
    "Rank": 733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 727,
    "Rank": 734,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 728,
    "Rank": 735,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 729,
    "Rank": 736,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 730,
    "Rank": 737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 731,
    "Rank": 738,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 732,
    "Rank": 739,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 733,
    "Rank": 740,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 734,
    "Rank": 741,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 735,
    "Rank": 742,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 736,
    "Rank": 743,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 737,
    "Rank": 744,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 738,
    "Rank": 745,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 739,
    "Rank": 746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 740,
    "Rank": 747,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 741,
    "Rank": 748,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 742,
    "Rank": 749,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 743,
    "Rank": 750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 744,
    "Rank": 751,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 745,
    "Rank": 752,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 746,
    "Rank": 753,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 747,
    "Rank": 754,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 748,
    "Rank": 755,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 749,
    "Rank": 756,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 750,
    "Rank": 757,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 751,
    "Rank": 758,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 752,
    "Rank": 759,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 753,
    "Rank": 760,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 754,
    "Rank": 761,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 755,
    "Rank": 762,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 756,
    "Rank": 763,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 757,
    "Rank": 764,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 758,
    "Rank": 765,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 759,
    "Rank": 766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 760,
    "Rank": 767,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 761,
    "Rank": 768,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 762,
    "Rank": 769,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 763,
    "Rank": 770,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 764,
    "Rank": 771,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 765,
    "Rank": 773,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 766,
    "Rank": 774,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 767,
    "Rank": 775,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 768,
    "Rank": 776,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 769,
    "Rank": 777,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 770,
    "Rank": 778,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 771,
    "Rank": 779,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 772,
    "Rank": 780,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 773,
    "Rank": 781,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 774,
    "Rank": 782,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 775,
    "Rank": 783,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 776,
    "Rank": 784,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 777,
    "Rank": 785,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 778,
    "Rank": 786,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 779,
    "Rank": 787,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 780,
    "Rank": 788,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 781,
    "Rank": 789,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 782,
    "Rank": 790,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 783,
    "Rank": 791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 784,
    "Rank": 792,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 785,
    "Rank": 793,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 786,
    "Rank": 794,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 787,
    "Rank": 795,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 788,
    "Rank": 796,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 789,
    "Rank": 797,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 790,
    "Rank": 798,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 791,
    "Rank": 799,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 792,
    "Rank": 800,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 793,
    "Rank": 801,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 794,
    "Rank": 802,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 795,
    "Rank": 803,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 796,
    "Rank": 804,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 797,
    "Rank": 805,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 798,
    "Rank": 806,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 799,
    "Rank": 807,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 800,
    "Rank": 808,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 801,
    "Rank": 809,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 802,
    "Rank": 810,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 803,
    "Rank": 811,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 804,
    "Rank": 812,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 805,
    "Rank": 813,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 806,
    "Rank": 814,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 807,
    "Rank": 815,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 808,
    "Rank": 816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 809,
    "Rank": 817,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 810,
    "Rank": 818,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 811,
    "Rank": 819,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 812,
    "Rank": 820,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 813,
    "Rank": 822,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 814,
    "Rank": 823,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 815,
    "Rank": 824,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 816,
    "Rank": 825,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 817,
    "Rank": 826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 818,
    "Rank": 827,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 819,
    "Rank": 828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 820,
    "Rank": 829,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 821,
    "Rank": 830,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 822,
    "Rank": 831,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 823,
    "Rank": 832,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 824,
    "Rank": 833,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 825,
    "Rank": 834,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 826,
    "Rank": 835,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 827,
    "Rank": 836,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 828,
    "Rank": 837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 829,
    "Rank": 838,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 830,
    "Rank": 839,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 831,
    "Rank": 840,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 832,
    "Rank": 841,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 833,
    "Rank": 843,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 834,
    "Rank": 844,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 835,
    "Rank": 845,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 836,
    "Rank": 846,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 837,
    "Rank": 847,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 838,
    "Rank": 848,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 839,
    "Rank": 849,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 840,
    "Rank": 850,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 841,
    "Rank": 851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 842,
    "Rank": 852,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 843,
    "Rank": 853,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 844,
    "Rank": 854,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 845,
    "Rank": 855,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 846,
    "Rank": 856,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 847,
    "Rank": 857,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 848,
    "Rank": 858,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 849,
    "Rank": 859,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 850,
    "Rank": 860,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 851,
    "Rank": 861,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 852,
    "Rank": 862,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 853,
    "Rank": 863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 854,
    "Rank": 864,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 855,
    "Rank": 865,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 856,
    "Rank": 866,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 857,
    "Rank": 867,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 858,
    "Rank": 868,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 859,
    "Rank": 869,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 860,
    "Rank": 870,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 861,
    "Rank": 871,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 862,
    "Rank": 872,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 863,
    "Rank": 873,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 864,
    "Rank": 874,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 865,
    "Rank": 875,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 866,
    "Rank": 876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 867,
    "Rank": 877,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 868,
    "Rank": 878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 869,
    "Rank": 879,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 870,
    "Rank": 880,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 871,
    "Rank": 881,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 872,
    "Rank": 882,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 873,
    "Rank": 883,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 874,
    "Rank": 884,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 875,
    "Rank": 885,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 876,
    "Rank": 886,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 877,
    "Rank": 887,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 878,
    "Rank": 888,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 879,
    "Rank": 889,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 880,
    "Rank": 890,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 881,
    "Rank": 891,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 882,
    "Rank": 892,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 883,
    "Rank": 893,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 884,
    "Rank": 894,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 885,
    "Rank": 895,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 886,
    "Rank": 896,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 887,
    "Rank": 897,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 888,
    "Rank": 899,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 889,
    "Rank": 900,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 890,
    "Rank": 901,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 891,
    "Rank": 902,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 892,
    "Rank": 903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 893,
    "Rank": 904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 894,
    "Rank": 905,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 895,
    "Rank": 906,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 896,
    "Rank": 907,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 897,
    "Rank": 908,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 898,
    "Rank": 909,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 899,
    "Rank": 910,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 900,
    "Rank": 911,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 901,
    "Rank": 912,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 902,
    "Rank": 913,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 903,
    "Rank": 914,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 904,
    "Rank": 915,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 905,
    "Rank": 916,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 906,
    "Rank": 917,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 907,
    "Rank": 918,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 908,
    "Rank": 919,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 909,
    "Rank": 920,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 910,
    "Rank": 921,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 911,
    "Rank": 922,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 912,
    "Rank": 923,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 913,
    "Rank": 924,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 914,
    "Rank": 925,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 915,
    "Rank": 926,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 916,
    "Rank": 928,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 917,
    "Rank": 929,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 918,
    "Rank": 930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 919,
    "Rank": 931,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 920,
    "Rank": 932,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 921,
    "Rank": 933,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 922,
    "Rank": 934,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 923,
    "Rank": 935,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 924,
    "Rank": 936,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 925,
    "Rank": 937,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 926,
    "Rank": 938,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 927,
    "Rank": 939,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 928,
    "Rank": 940,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 929,
    "Rank": 941,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 930,
    "Rank": 942,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 931,
    "Rank": 943,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 932,
    "Rank": 944,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 933,
    "Rank": 945,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 934,
    "Rank": 946,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 935,
    "Rank": 947,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 936,
    "Rank": 948,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 937,
    "Rank": 950,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 938,
    "Rank": 951,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 939,
    "Rank": 952,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 940,
    "Rank": 953,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 941,
    "Rank": 954,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 942,
    "Rank": 955,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 943,
    "Rank": 956,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 944,
    "Rank": 957,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 945,
    "Rank": 958,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 946,
    "Rank": 960,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 947,
    "Rank": 961,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 948,
    "Rank": 963,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 949,
    "Rank": 964,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 950,
    "Rank": 966,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 951,
    "Rank": 967,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 952,
    "Rank": 968,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 953,
    "Rank": 969,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 954,
    "Rank": 970,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 955,
    "Rank": 971,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 956,
    "Rank": 972,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 957,
    "Rank": 973,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 958,
    "Rank": 974,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 959,
    "Rank": 975,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 960,
    "Rank": 976,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 961,
    "Rank": 977,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 962,
    "Rank": 978,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 963,
    "Rank": 979,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 964,
    "Rank": 981,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 965,
    "Rank": 982,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 966,
    "Rank": 983,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 967,
    "Rank": 984,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 968,
    "Rank": 985,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 969,
    "Rank": 986,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 970,
    "Rank": 987,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 971,
    "Rank": 989,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 972,
    "Rank": 990,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 973,
    "Rank": 991,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 974,
    "Rank": 992,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 975,
    "Rank": 994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 976,
    "Rank": 995,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 977,
    "Rank": 996,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 978,
    "Rank": 997,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 979,
    "Rank": 998,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 980,
    "Rank": 999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 981,
    "Rank": 1000,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 982,
    "Rank": 1001,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 983,
    "Rank": 1002,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 984,
    "Rank": 1003,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 985,
    "Rank": 1004,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 986,
    "Rank": 1006,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 987,
    "Rank": 1007,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 988,
    "Rank": 1008,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 989,
    "Rank": 1009,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 990,
    "Rank": 1010,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 991,
    "Rank": 1011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 992,
    "Rank": 1012,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 993,
    "Rank": 1013,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 994,
    "Rank": 1014,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 995,
    "Rank": 1015,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 996,
    "Rank": 1016,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 997,
    "Rank": 1018,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 998,
    "Rank": 1019,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 999,
    "Rank": 1020,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1000,
    "Rank": 1021,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1001,
    "Rank": 1022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1002,
    "Rank": 1023,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1003,
    "Rank": 1024,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1004,
    "Rank": 1025,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1005,
    "Rank": 1026,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1006,
    "Rank": 1027,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1007,
    "Rank": 1028,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1008,
    "Rank": 1029,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1009,
    "Rank": 1030,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1010,
    "Rank": 1031,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1011,
    "Rank": 1033,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1012,
    "Rank": 1034,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1013,
    "Rank": 1035,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1014,
    "Rank": 1036,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1015,
    "Rank": 1037,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1016,
    "Rank": 1038,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1017,
    "Rank": 1039,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1018,
    "Rank": 1040,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1019,
    "Rank": 1041,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1020,
    "Rank": 1042,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1021,
    "Rank": 1043,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1022,
    "Rank": 1044,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1023,
    "Rank": 1045,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1024,
    "Rank": 1046,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1025,
    "Rank": 1047,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1026,
    "Rank": 1048,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1027,
    "Rank": 1049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1028,
    "Rank": 1050,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1029,
    "Rank": 1051,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1030,
    "Rank": 1052,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1031,
    "Rank": 1053,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1032,
    "Rank": 1054,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1033,
    "Rank": 1055,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1034,
    "Rank": 1056,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1035,
    "Rank": 1058,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1036,
    "Rank": 1059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1037,
    "Rank": 1060,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1038,
    "Rank": 1061,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1039,
    "Rank": 1062,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1040,
    "Rank": 1063,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1041,
    "Rank": 1064,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1042,
    "Rank": 1065,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1043,
    "Rank": 1066,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1044,
    "Rank": 1067,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1045,
    "Rank": 1068,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1046,
    "Rank": 1069,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1047,
    "Rank": 1070,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1048,
    "Rank": 1071,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1049,
    "Rank": 1072,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1050,
    "Rank": 1073,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1051,
    "Rank": 1074,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1052,
    "Rank": 1075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1053,
    "Rank": 1076,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1054,
    "Rank": 1077,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1055,
    "Rank": 1078,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1056,
    "Rank": 1079,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1057,
    "Rank": 1080,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1058,
    "Rank": 1081,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1059,
    "Rank": 1082,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1060,
    "Rank": 1083,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1061,
    "Rank": 1084,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1062,
    "Rank": 1085,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1063,
    "Rank": 1086,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1064,
    "Rank": 1087,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1065,
    "Rank": 1088,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1066,
    "Rank": 1089,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1067,
    "Rank": 1090,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1068,
    "Rank": 1091,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1069,
    "Rank": 1092,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1070,
    "Rank": 1093,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1071,
    "Rank": 1094,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1072,
    "Rank": 1095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1073,
    "Rank": 1096,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1074,
    "Rank": 1097,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1075,
    "Rank": 1098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1076,
    "Rank": 1099,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1077,
    "Rank": 1100,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1078,
    "Rank": 1101,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1079,
    "Rank": 1102,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1080,
    "Rank": 1103,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1081,
    "Rank": 1104,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1082,
    "Rank": 1106,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1083,
    "Rank": 1107,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1084,
    "Rank": 1108,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1085,
    "Rank": 1109,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1086,
    "Rank": 1110,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1087,
    "Rank": 1111,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1088,
    "Rank": 1112,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1089,
    "Rank": 1113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1090,
    "Rank": 1114,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1091,
    "Rank": 1115,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1092,
    "Rank": 1116,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1093,
    "Rank": 1117,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1094,
    "Rank": 1118,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1095,
    "Rank": 1119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1096,
    "Rank": 1120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1097,
    "Rank": 1121,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1098,
    "Rank": 1122,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1099,
    "Rank": 1123,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1100,
    "Rank": 1124,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1101,
    "Rank": 1125,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1102,
    "Rank": 1126,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1103,
    "Rank": 1127,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1104,
    "Rank": 1128,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1105,
    "Rank": 1129,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1106,
    "Rank": 1130,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1107,
    "Rank": 1131,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1108,
    "Rank": 1132,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1109,
    "Rank": 1133,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1110,
    "Rank": 1134,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1111,
    "Rank": 1135,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1112,
    "Rank": 1136,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1113,
    "Rank": 1137,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1114,
    "Rank": 1138,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1115,
    "Rank": 1140,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1116,
    "Rank": 1141,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1117,
    "Rank": 1142,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1118,
    "Rank": 1143,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1119,
    "Rank": 1145,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1120,
    "Rank": 1146,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1121,
    "Rank": 1147,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1122,
    "Rank": 1149,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1123,
    "Rank": 1150,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1124,
    "Rank": 1151,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1125,
    "Rank": 1152,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1126,
    "Rank": 1153,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1127,
    "Rank": 1154,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1128,
    "Rank": 1156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1129,
    "Rank": 1157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1130,
    "Rank": 1158,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1131,
    "Rank": 1159,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1132,
    "Rank": 1160,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1133,
    "Rank": 1161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1134,
    "Rank": 1162,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1135,
    "Rank": 1163,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1136,
    "Rank": 1164,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1137,
    "Rank": 1165,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1138,
    "Rank": 1166,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1139,
    "Rank": 1167,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1140,
    "Rank": 1168,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1141,
    "Rank": 1169,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1142,
    "Rank": 1170,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1143,
    "Rank": 1171,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1144,
    "Rank": 1172,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1145,
    "Rank": 1174,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1146,
    "Rank": 1175,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1147,
    "Rank": 1176,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1148,
    "Rank": 1177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1149,
    "Rank": 1178,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1150,
    "Rank": 1179,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1151,
    "Rank": 1180,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1152,
    "Rank": 1181,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1153,
    "Rank": 1182,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1154,
    "Rank": 1183,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1155,
    "Rank": 1184,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1156,
    "Rank": 1186,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1157,
    "Rank": 1187,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1158,
    "Rank": 1188,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1159,
    "Rank": 1189,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1160,
    "Rank": 1190,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1161,
    "Rank": 1191,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1162,
    "Rank": 1192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1163,
    "Rank": 1193,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1164,
    "Rank": 1194,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1165,
    "Rank": 1195,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1166,
    "Rank": 1196,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1167,
    "Rank": 1197,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1168,
    "Rank": 1198,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1169,
    "Rank": 1199,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1170,
    "Rank": 1200,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1171,
    "Rank": 1201,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1172,
    "Rank": 1202,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1173,
    "Rank": 1203,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1174,
    "Rank": 1204,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1175,
    "Rank": 1205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1176,
    "Rank": 1206,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1177,
    "Rank": 1207,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1178,
    "Rank": 1208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1179,
    "Rank": 1209,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1180,
    "Rank": 1210,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1181,
    "Rank": 1211,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1182,
    "Rank": 1212,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1183,
    "Rank": 1213,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1184,
    "Rank": 1214,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1185,
    "Rank": 1215,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1186,
    "Rank": 1216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1187,
    "Rank": 1217,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1188,
    "Rank": 1219,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1189,
    "Rank": 1220,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1190,
    "Rank": 1222,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1191,
    "Rank": 1223,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1192,
    "Rank": 1224,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1193,
    "Rank": 1225,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1194,
    "Rank": 1226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1195,
    "Rank": 1227,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1196,
    "Rank": 1228,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1197,
    "Rank": 1229,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1198,
    "Rank": 1230,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1199,
    "Rank": 1232,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1200,
    "Rank": 1233,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1201,
    "Rank": 1234,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1202,
    "Rank": 1235,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1203,
    "Rank": 1236,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1204,
    "Rank": 1237,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1205,
    "Rank": 1238,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1206,
    "Rank": 1239,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1207,
    "Rank": 1240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1208,
    "Rank": 1241,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1209,
    "Rank": 1242,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1210,
    "Rank": 1244,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1211,
    "Rank": 1245,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1212,
    "Rank": 1246,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1213,
    "Rank": 1247,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1214,
    "Rank": 1248,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1215,
    "Rank": 1250,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1216,
    "Rank": 1251,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1217,
    "Rank": 1252,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1218,
    "Rank": 1253,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1219,
    "Rank": 1256,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1220,
    "Rank": 1257,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1221,
    "Rank": 1258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1222,
    "Rank": 1259,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1223,
    "Rank": 1260,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1224,
    "Rank": 1261,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1225,
    "Rank": 1262,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1226,
    "Rank": 1263,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1227,
    "Rank": 1264,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1228,
    "Rank": 1265,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1229,
    "Rank": 1266,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1230,
    "Rank": 1267,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1231,
    "Rank": 1270,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1232,
    "Rank": 1271,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1233,
    "Rank": 1272,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1234,
    "Rank": 1274,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1235,
    "Rank": 1275,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1236,
    "Rank": 1276,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1237,
    "Rank": 1277,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1238,
    "Rank": 1278,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1239,
    "Rank": 1280,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1240,
    "Rank": 1281,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1241,
    "Rank": 1282,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1242,
    "Rank": 1283,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1243,
    "Rank": 1285,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1244,
    "Rank": 1286,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1245,
    "Rank": 1289,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1246,
    "Rank": 1290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1247,
    "Rank": 1291,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1248,
    "Rank": 1292,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1249,
    "Rank": 1293,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1250,
    "Rank": 1294,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1251,
    "Rank": 1297,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1252,
    "Rank": 1298,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1253,
    "Rank": 1299,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1254,
    "Rank": 1300,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1255,
    "Rank": 1301,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1256,
    "Rank": 1302,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1257,
    "Rank": 1303,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1258,
    "Rank": 1304,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1259,
    "Rank": 1305,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1260,
    "Rank": 1306,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1261,
    "Rank": 1307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1262,
    "Rank": 1308,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1263,
    "Rank": 1309,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1264,
    "Rank": 1310,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1265,
    "Rank": 1311,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1266,
    "Rank": 1312,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1267,
    "Rank": 1313,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1268,
    "Rank": 1314,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1269,
    "Rank": 1315,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1270,
    "Rank": 1316,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1271,
    "Rank": 1317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1272,
    "Rank": 1318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1273,
    "Rank": 1319,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1274,
    "Rank": 1320,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1275,
    "Rank": 1321,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1276,
    "Rank": 1322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1277,
    "Rank": 1325,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1278,
    "Rank": 1326,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1279,
    "Rank": 1327,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1280,
    "Rank": 1328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1281,
    "Rank": 1329,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1282,
    "Rank": 1330,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1283,
    "Rank": 1331,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1284,
    "Rank": 1332,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1285,
    "Rank": 1333,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1286,
    "Rank": 1334,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1287,
    "Rank": 1335,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1288,
    "Rank": 1336,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1289,
    "Rank": 1337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1290,
    "Rank": 1338,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1291,
    "Rank": 1339,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1292,
    "Rank": 1340,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1293,
    "Rank": 1341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1294,
    "Rank": 1343,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1295,
    "Rank": 1344,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1296,
    "Rank": 1345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1297,
    "Rank": 1347,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1298,
    "Rank": 1349,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1299,
    "Rank": 1350,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1300,
    "Rank": 1351,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1301,
    "Rank": 1352,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1302,
    "Rank": 1353,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1303,
    "Rank": 1354,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1304,
    "Rank": 1355,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1305,
    "Rank": 1356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1306,
    "Rank": 1357,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1307,
    "Rank": 1358,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1308,
    "Rank": 1359,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1309,
    "Rank": 1360,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1310,
    "Rank": 1361,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1311,
    "Rank": 1362,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1312,
    "Rank": 1363,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1313,
    "Rank": 1364,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1314,
    "Rank": 1365,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1315,
    "Rank": 1366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1316,
    "Rank": 1367,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1317,
    "Rank": 1369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1318,
    "Rank": 1371,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1319,
    "Rank": 1372,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1320,
    "Rank": 1373,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1321,
    "Rank": 1374,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1322,
    "Rank": 1375,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1323,
    "Rank": 1376,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1324,
    "Rank": 1377,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1325,
    "Rank": 1380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1326,
    "Rank": 1381,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1327,
    "Rank": 1382,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1328,
    "Rank": 1383,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1329,
    "Rank": 1385,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1330,
    "Rank": 1386,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1331,
    "Rank": 1387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1332,
    "Rank": 1388,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1333,
    "Rank": 1389,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1334,
    "Rank": 1391,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1335,
    "Rank": 1392,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1336,
    "Rank": 1393,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1337,
    "Rank": 1394,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1338,
    "Rank": 1395,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1339,
    "Rank": 1396,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1340,
    "Rank": 1397,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1341,
    "Rank": 1398,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1342,
    "Rank": 1399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1343,
    "Rank": 1400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1344,
    "Rank": 1402,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1345,
    "Rank": 1404,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1346,
    "Rank": 1405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1347,
    "Rank": 1406,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1348,
    "Rank": 1407,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1349,
    "Rank": 1408,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1350,
    "Rank": 1409,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1351,
    "Rank": 1410,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1352,
    "Rank": 1411,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1353,
    "Rank": 1412,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1354,
    "Rank": 1414,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1355,
    "Rank": 1415,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1356,
    "Rank": 1417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1357,
    "Rank": 1419,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1358,
    "Rank": 1421,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1359,
    "Rank": 1422,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1360,
    "Rank": 1423,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1361,
    "Rank": 1425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1362,
    "Rank": 1426,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1363,
    "Rank": 1427,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1364,
    "Rank": 1428,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1365,
    "Rank": 1430,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1366,
    "Rank": 1431,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1367,
    "Rank": 1432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1368,
    "Rank": 1433,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1369,
    "Rank": 1434,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1370,
    "Rank": 1435,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1371,
    "Rank": 1436,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1372,
    "Rank": 1437,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1373,
    "Rank": 1438,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1374,
    "Rank": 1439,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1375,
    "Rank": 1440,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1376,
    "Rank": 1441,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1377,
    "Rank": 1442,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1378,
    "Rank": 1443,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1379,
    "Rank": 1444,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1380,
    "Rank": 1445,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1381,
    "Rank": 1447,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1382,
    "Rank": 1448,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1383,
    "Rank": 1449,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1384,
    "Rank": 1451,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1385,
    "Rank": 1452,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1386,
    "Rank": 1453,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1387,
    "Rank": 1454,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1388,
    "Rank": 1455,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1389,
    "Rank": 1456,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1390,
    "Rank": 1458,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1391,
    "Rank": 1459,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1392,
    "Rank": 1460,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1393,
    "Rank": 1461,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1394,
    "Rank": 1462,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1395,
    "Rank": 1464,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1396,
    "Rank": 1465,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1397,
    "Rank": 1466,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1398,
    "Rank": 1468,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1399,
    "Rank": 1469,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1400,
    "Rank": 1470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1401,
    "Rank": 1472,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1402,
    "Rank": 1476,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1403,
    "Rank": 1477,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1404,
    "Rank": 1478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1405,
    "Rank": 1480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1406,
    "Rank": 1481,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1407,
    "Rank": 1482,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1408,
    "Rank": 1483,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1409,
    "Rank": 1484,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1410,
    "Rank": 1485,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1411,
    "Rank": 1486,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1412,
    "Rank": 1488,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1413,
    "Rank": 1489,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1414,
    "Rank": 1490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1415,
    "Rank": 1491,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1416,
    "Rank": 1492,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1417,
    "Rank": 1493,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1418,
    "Rank": 1494,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1419,
    "Rank": 1495,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1420,
    "Rank": 1496,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1421,
    "Rank": 1498,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1422,
    "Rank": 1499,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1423,
    "Rank": 1500,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1424,
    "Rank": 1502,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1425,
    "Rank": 1504,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1426,
    "Rank": 1505,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1427,
    "Rank": 1506,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1428,
    "Rank": 1507,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1429,
    "Rank": 1508,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1430,
    "Rank": 1509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1431,
    "Rank": 1510,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1432,
    "Rank": 1512,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1433,
    "Rank": 1515,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1434,
    "Rank": 1516,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1435,
    "Rank": 1517,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1436,
    "Rank": 1518,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1437,
    "Rank": 1519,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1438,
    "Rank": 1520,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1439,
    "Rank": 1521,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1440,
    "Rank": 1522,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1441,
    "Rank": 1523,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1442,
    "Rank": 1524,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1443,
    "Rank": 1525,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1444,
    "Rank": 1527,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1445,
    "Rank": 1528,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1446,
    "Rank": 1529,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1447,
    "Rank": 1530,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1448,
    "Rank": 1531,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1449,
    "Rank": 1532,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1450,
    "Rank": 1533,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1451,
    "Rank": 1534,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1452,
    "Rank": 1538,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1453,
    "Rank": 1539,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1454,
    "Rank": 1540,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1455,
    "Rank": 1541,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1456,
    "Rank": 1542,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1457,
    "Rank": 1544,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1458,
    "Rank": 1545,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1459,
    "Rank": 1546,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1460,
    "Rank": 1548,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1461,
    "Rank": 1549,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1462,
    "Rank": 1550,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1463,
    "Rank": 1551,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1464,
    "Rank": 1552,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1465,
    "Rank": 1553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1466,
    "Rank": 1554,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1467,
    "Rank": 1555,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1468,
    "Rank": 1556,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1469,
    "Rank": 1558,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1470,
    "Rank": 1559,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1471,
    "Rank": 1560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1472,
    "Rank": 1561,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1473,
    "Rank": 1562,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1474,
    "Rank": 1563,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1475,
    "Rank": 1564,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1476,
    "Rank": 1565,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1477,
    "Rank": 1566,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1478,
    "Rank": 1567,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1479,
    "Rank": 1568,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1480,
    "Rank": 1569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1481,
    "Rank": 1570,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1482,
    "Rank": 1571,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1483,
    "Rank": 1573,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1484,
    "Rank": 1574,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1485,
    "Rank": 1575,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1486,
    "Rank": 1576,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1487,
    "Rank": 1577,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1488,
    "Rank": 1578,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1489,
    "Rank": 1579,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1490,
    "Rank": 1580,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1491,
    "Rank": 1581,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1492,
    "Rank": 1583,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1493,
    "Rank": 1584,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1494,
    "Rank": 1585,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1495,
    "Rank": 1586,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1496,
    "Rank": 1587,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1497,
    "Rank": 1589,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1498,
    "Rank": 1590,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1499,
    "Rank": 1591,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1500,
    "Rank": 1593,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1501,
    "Rank": 1594,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1502,
    "Rank": 1595,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1503,
    "Rank": 1596,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1504,
    "Rank": 1597,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1505,
    "Rank": 1599,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1506,
    "Rank": 1600,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1507,
    "Rank": 1601,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1508,
    "Rank": 1602,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1509,
    "Rank": 1605,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1510,
    "Rank": 1606,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1511,
    "Rank": 1607,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1512,
    "Rank": 1609,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1513,
    "Rank": 1610,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1514,
    "Rank": 1611,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1515,
    "Rank": 1612,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1516,
    "Rank": 1613,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1517,
    "Rank": 1614,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1518,
    "Rank": 1615,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1519,
    "Rank": 1616,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1520,
    "Rank": 1617,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1521,
    "Rank": 1618,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1522,
    "Rank": 1620,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1523,
    "Rank": 1621,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1524,
    "Rank": 1622,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1525,
    "Rank": 1623,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1526,
    "Rank": 1624,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1527,
    "Rank": 1625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1528,
    "Rank": 1626,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1529,
    "Rank": 1627,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1530,
    "Rank": 1628,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1531,
    "Rank": 1629,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1532,
    "Rank": 1630,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1533,
    "Rank": 1631,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1534,
    "Rank": 1632,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1535,
    "Rank": 1633,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1536,
    "Rank": 1634,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1537,
    "Rank": 1635,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1538,
    "Rank": 1637,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1539,
    "Rank": 1638,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1540,
    "Rank": 1639,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1541,
    "Rank": 1640,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1542,
    "Rank": 1641,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1543,
    "Rank": 1642,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1544,
    "Rank": 1644,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1545,
    "Rank": 1647,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1546,
    "Rank": 1648,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1547,
    "Rank": 1649,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1548,
    "Rank": 1650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1549,
    "Rank": 1651,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1550,
    "Rank": 1652,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1551,
    "Rank": 1653,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1552,
    "Rank": 1654,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1553,
    "Rank": 1655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1554,
    "Rank": 1656,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1555,
    "Rank": 1659,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1556,
    "Rank": 1660,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1557,
    "Rank": 1661,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1558,
    "Rank": 1662,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1559,
    "Rank": 1663,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1560,
    "Rank": 1665,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1561,
    "Rank": 1666,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1562,
    "Rank": 1667,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1563,
    "Rank": 1668,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1564,
    "Rank": 1669,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1565,
    "Rank": 1670,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1566,
    "Rank": 1671,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1567,
    "Rank": 1672,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1568,
    "Rank": 1673,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1569,
    "Rank": 1674,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1570,
    "Rank": 1675,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1571,
    "Rank": 1676,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1572,
    "Rank": 1678,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1573,
    "Rank": 1679,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1574,
    "Rank": 1680,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1575,
    "Rank": 1681,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1576,
    "Rank": 1682,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1577,
    "Rank": 1683,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1578,
    "Rank": 1684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1579,
    "Rank": 1685,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1580,
    "Rank": 1686,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1581,
    "Rank": 1687,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1582,
    "Rank": 1688,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1583,
    "Rank": 1689,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1584,
    "Rank": 1690,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1585,
    "Rank": 1692,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1586,
    "Rank": 1693,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1587,
    "Rank": 1694,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1588,
    "Rank": 1695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1589,
    "Rank": 1696,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1590,
    "Rank": 1698,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1591,
    "Rank": 1699,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1592,
    "Rank": 1700,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1593,
    "Rank": 1701,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1594,
    "Rank": 1703,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1595,
    "Rank": 1704,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1596,
    "Rank": 1705,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1597,
    "Rank": 1706,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1598,
    "Rank": 1707,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1599,
    "Rank": 1708,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1600,
    "Rank": 1709,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1601,
    "Rank": 1711,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1602,
    "Rank": 1712,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1603,
    "Rank": 1713,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1604,
    "Rank": 1714,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1605,
    "Rank": 1715,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1606,
    "Rank": 1716,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1607,
    "Rank": 1717,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1608,
    "Rank": 1719,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1609,
    "Rank": 1720,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1610,
    "Rank": 1721,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1611,
    "Rank": 1722,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1612,
    "Rank": 1723,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1613,
    "Rank": 1725,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1614,
    "Rank": 1727,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1615,
    "Rank": 1728,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1616,
    "Rank": 1729,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1617,
    "Rank": 1731,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1618,
    "Rank": 1732,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1619,
    "Rank": 1733,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1620,
    "Rank": 1734,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1621,
    "Rank": 1735,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1622,
    "Rank": 1736,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1623,
    "Rank": 1737,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1624,
    "Rank": 1738,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1625,
    "Rank": 1739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1626,
    "Rank": 1740,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1627,
    "Rank": 1741,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1628,
    "Rank": 1743,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1629,
    "Rank": 1744,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1630,
    "Rank": 1745,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1631,
    "Rank": 1747,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1632,
    "Rank": 1749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1633,
    "Rank": 1750,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1634,
    "Rank": 1751,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1635,
    "Rank": 1752,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1636,
    "Rank": 1754,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1637,
    "Rank": 1756,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1638,
    "Rank": 1757,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1639,
    "Rank": 1758,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1640,
    "Rank": 1759,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1641,
    "Rank": 1760,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1642,
    "Rank": 1761,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1643,
    "Rank": 1762,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1644,
    "Rank": 1763,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1645,
    "Rank": 1765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1646,
    "Rank": 1766,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1647,
    "Rank": 1767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1648,
    "Rank": 1769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1649,
    "Rank": 1770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1650,
    "Rank": 1771,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1651,
    "Rank": 1772,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1652,
    "Rank": 1773,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1653,
    "Rank": 1774,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1654,
    "Rank": 1775,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1655,
    "Rank": 1776,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1656,
    "Rank": 1777,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1657,
    "Rank": 1779,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1658,
    "Rank": 1780,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1659,
    "Rank": 1781,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1660,
    "Rank": 1782,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1661,
    "Rank": 1783,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1662,
    "Rank": 1784,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1663,
    "Rank": 1785,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1664,
    "Rank": 1786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1665,
    "Rank": 1787,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1666,
    "Rank": 1789,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1667,
    "Rank": 1790,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1668,
    "Rank": 1791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1669,
    "Rank": 1792,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1670,
    "Rank": 1794,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1671,
    "Rank": 1795,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1672,
    "Rank": 1796,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1673,
    "Rank": 1797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1674,
    "Rank": 1800,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1675,
    "Rank": 1801,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1676,
    "Rank": 1802,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1677,
    "Rank": 1803,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1678,
    "Rank": 1804,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1679,
    "Rank": 1805,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1680,
    "Rank": 1806,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1681,
    "Rank": 1807,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1682,
    "Rank": 1809,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1683,
    "Rank": 1810,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1684,
    "Rank": 1811,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1685,
    "Rank": 1812,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1686,
    "Rank": 1813,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1687,
    "Rank": 1814,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1688,
    "Rank": 1815,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1689,
    "Rank": 1816,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1690,
    "Rank": 1817,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1691,
    "Rank": 1818,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1692,
    "Rank": 1819,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1693,
    "Rank": 1820,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1694,
    "Rank": 1821,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1695,
    "Rank": 1822,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1696,
    "Rank": 1824,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1697,
    "Rank": 1825,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1698,
    "Rank": 1826,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1699,
    "Rank": 1828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1700,
    "Rank": 1829,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1701,
    "Rank": 1830,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1702,
    "Rank": 1831,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1703,
    "Rank": 1832,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1704,
    "Rank": 1833,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1705,
    "Rank": 1834,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1706,
    "Rank": 1835,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1707,
    "Rank": 1836,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1708,
    "Rank": 1837,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1709,
    "Rank": 1838,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1710,
    "Rank": 1839,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1711,
    "Rank": 1840,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1712,
    "Rank": 1841,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1713,
    "Rank": 1842,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1714,
    "Rank": 1843,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1715,
    "Rank": 1844,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1716,
    "Rank": 1846,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1717,
    "Rank": 1847,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1718,
    "Rank": 1848,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1719,
    "Rank": 1849,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1720,
    "Rank": 1850,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1721,
    "Rank": 1852,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1722,
    "Rank": 1853,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1723,
    "Rank": 1854,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1724,
    "Rank": 1856,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1725,
    "Rank": 1857,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1726,
    "Rank": 1859,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1727,
    "Rank": 1860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1728,
    "Rank": 1861,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1729,
    "Rank": 1863,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1730,
    "Rank": 1864,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1731,
    "Rank": 1865,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1732,
    "Rank": 1866,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1733,
    "Rank": 1867,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1734,
    "Rank": 1868,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1735,
    "Rank": 1869,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1736,
    "Rank": 1870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1737,
    "Rank": 1871,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1738,
    "Rank": 1872,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1739,
    "Rank": 1874,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1740,
    "Rank": 1875,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1741,
    "Rank": 1876,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1742,
    "Rank": 1879,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1743,
    "Rank": 1880,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1744,
    "Rank": 1881,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1745,
    "Rank": 1882,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1746,
    "Rank": 1883,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1747,
    "Rank": 1884,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1748,
    "Rank": 1885,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1749,
    "Rank": 1886,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1750,
    "Rank": 1888,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1751,
    "Rank": 1891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1752,
    "Rank": 1892,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1753,
    "Rank": 1893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1754,
    "Rank": 1894,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1755,
    "Rank": 1895,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1756,
    "Rank": 1896,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1757,
    "Rank": 1897,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1758,
    "Rank": 1900,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1759,
    "Rank": 1901,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1760,
    "Rank": 1903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1761,
    "Rank": 1904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1762,
    "Rank": 1905,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1763,
    "Rank": 1906,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1764,
    "Rank": 1907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1765,
    "Rank": 1909,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1766,
    "Rank": 1910,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1767,
    "Rank": 1911,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1768,
    "Rank": 1912,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1769,
    "Rank": 1913,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1770,
    "Rank": 1914,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1771,
    "Rank": 1915,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1772,
    "Rank": 1916,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1773,
    "Rank": 1918,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1774,
    "Rank": 1920,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1775,
    "Rank": 1921,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1776,
    "Rank": 1922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1777,
    "Rank": 1923,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1778,
    "Rank": 1925,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1779,
    "Rank": 1926,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1780,
    "Rank": 1927,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1781,
    "Rank": 1929,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1782,
    "Rank": 1930,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1783,
    "Rank": 1933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1784,
    "Rank": 1934,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1785,
    "Rank": 1935,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1786,
    "Rank": 1937,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1787,
    "Rank": 1938,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1788,
    "Rank": 1939,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1789,
    "Rank": 1940,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1790,
    "Rank": 1941,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1791,
    "Rank": 1942,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1792,
    "Rank": 1943,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1793,
    "Rank": 1944,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1794,
    "Rank": 1946,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1795,
    "Rank": 1947,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1796,
    "Rank": 1948,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1797,
    "Rank": 1949,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1798,
    "Rank": 1950,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1799,
    "Rank": 1951,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1800,
    "Rank": 1952,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1801,
    "Rank": 1954,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1802,
    "Rank": 1956,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1803,
    "Rank": 1957,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1804,
    "Rank": 1958,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1805,
    "Rank": 1959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1806,
    "Rank": 1960,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1807,
    "Rank": 1962,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1808,
    "Rank": 1963,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1809,
    "Rank": 1964,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1810,
    "Rank": 1966,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1811,
    "Rank": 1967,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1812,
    "Rank": 1968,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1813,
    "Rank": 1970,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1814,
    "Rank": 1971,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1815,
    "Rank": 1972,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1816,
    "Rank": 1973,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1817,
    "Rank": 1974,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1818,
    "Rank": 1975,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1819,
    "Rank": 1976,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1820,
    "Rank": 1977,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1821,
    "Rank": 1978,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1822,
    "Rank": 1980,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1823,
    "Rank": 1982,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1824,
    "Rank": 1983,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1825,
    "Rank": 1984,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1826,
    "Rank": 1985,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1827,
    "Rank": 1986,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1828,
    "Rank": 1988,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1829,
    "Rank": 1990,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1830,
    "Rank": 1991,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1831,
    "Rank": 1993,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1832,
    "Rank": 1994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1833,
    "Rank": 1995,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1834,
    "Rank": 1996,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1835,
    "Rank": 1997,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1836,
    "Rank": 1998,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1837,
    "Rank": 1999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1838,
    "Rank": 2000,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1839,
    "Rank": 2001,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1840,
    "Rank": 2003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1841,
    "Rank": 2006,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1842,
    "Rank": 2007,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1843,
    "Rank": 2008,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1844,
    "Rank": 2009,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1845,
    "Rank": 2010,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1846,
    "Rank": 2011,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1847,
    "Rank": 2012,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1848,
    "Rank": 2015,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1849,
    "Rank": 2016,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1850,
    "Rank": 2017,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1851,
    "Rank": 2018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1852,
    "Rank": 2019,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1853,
    "Rank": 2020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1854,
    "Rank": 2021,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1855,
    "Rank": 2022,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1856,
    "Rank": 2024,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1857,
    "Rank": 2025,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1858,
    "Rank": 2027,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1859,
    "Rank": 2028,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1860,
    "Rank": 2029,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1861,
    "Rank": 2030,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1862,
    "Rank": 2031,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1863,
    "Rank": 2032,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1864,
    "Rank": 2033,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1865,
    "Rank": 2034,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1866,
    "Rank": 2035,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1867,
    "Rank": 2036,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1868,
    "Rank": 2037,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1869,
    "Rank": 2038,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1870,
    "Rank": 2039,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1871,
    "Rank": 2040,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1872,
    "Rank": 2041,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1873,
    "Rank": 2042,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1874,
    "Rank": 2043,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1875,
    "Rank": 2044,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1876,
    "Rank": 2045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1877,
    "Rank": 2047,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1878,
    "Rank": 2048,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1879,
    "Rank": 2049,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1880,
    "Rank": 2050,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1881,
    "Rank": 2051,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1882,
    "Rank": 2054,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1883,
    "Rank": 2055,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1884,
    "Rank": 2056,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1885,
    "Rank": 2057,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1886,
    "Rank": 2058,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1887,
    "Rank": 2060,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1888,
    "Rank": 2061,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1889,
    "Rank": 2063,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1890,
    "Rank": 2065,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1891,
    "Rank": 2066,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1892,
    "Rank": 2069,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1893,
    "Rank": 2070,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1894,
    "Rank": 2071,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1895,
    "Rank": 2072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1896,
    "Rank": 2073,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1897,
    "Rank": 2074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1898,
    "Rank": 2075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1899,
    "Rank": 2076,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1900,
    "Rank": 2078,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1901,
    "Rank": 2080,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1902,
    "Rank": 2082,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1903,
    "Rank": 2083,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1904,
    "Rank": 2084,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1905,
    "Rank": 2085,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1906,
    "Rank": 2086,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1907,
    "Rank": 2088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1908,
    "Rank": 2089,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1909,
    "Rank": 2090,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1910,
    "Rank": 2091,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1911,
    "Rank": 2092,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1912,
    "Rank": 2094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1913,
    "Rank": 2095,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1914,
    "Rank": 2096,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1915,
    "Rank": 2097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1916,
    "Rank": 2099,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1917,
    "Rank": 2100,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1918,
    "Rank": 2101,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1919,
    "Rank": 2102,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1920,
    "Rank": 2103,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1921,
    "Rank": 2104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1922,
    "Rank": 2105,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1923,
    "Rank": 2106,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1924,
    "Rank": 2107,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1925,
    "Rank": 2108,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1926,
    "Rank": 2109,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1927,
    "Rank": 2110,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1928,
    "Rank": 2111,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1929,
    "Rank": 2112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1930,
    "Rank": 2113,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1931,
    "Rank": 2114,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1932,
    "Rank": 2115,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1933,
    "Rank": 2116,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1934,
    "Rank": 2118,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1935,
    "Rank": 2119,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1936,
    "Rank": 2121,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1937,
    "Rank": 2123,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1938,
    "Rank": 2125,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1939,
    "Rank": 2126,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1940,
    "Rank": 2128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1941,
    "Rank": 2129,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1942,
    "Rank": 2130,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1943,
    "Rank": 2131,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1944,
    "Rank": 2132,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1945,
    "Rank": 2133,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1946,
    "Rank": 2134,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1947,
    "Rank": 2135,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1948,
    "Rank": 2136,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1949,
    "Rank": 2137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1950,
    "Rank": 2138,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1951,
    "Rank": 2139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1952,
    "Rank": 2140,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1953,
    "Rank": 2141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1954,
    "Rank": 2142,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1955,
    "Rank": 2144,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1956,
    "Rank": 2145,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1957,
    "Rank": 2147,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1958,
    "Rank": 2149,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1959,
    "Rank": 2150,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1960,
    "Rank": 2151,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1961,
    "Rank": 2152,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1962,
    "Rank": 2153,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1963,
    "Rank": 2155,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1964,
    "Rank": 2157,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1965,
    "Rank": 2158,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1966,
    "Rank": 2159,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1967,
    "Rank": 2161,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1968,
    "Rank": 2163,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1969,
    "Rank": 2164,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1970,
    "Rank": 2165,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1971,
    "Rank": 2168,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1972,
    "Rank": 2169,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1973,
    "Rank": 2170,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1974,
    "Rank": 2171,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1975,
    "Rank": 2172,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1976,
    "Rank": 2173,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1977,
    "Rank": 2175,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1978,
    "Rank": 2176,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1979,
    "Rank": 2177,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1980,
    "Rank": 2178,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1981,
    "Rank": 2180,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1982,
    "Rank": 2181,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1983,
    "Rank": 2183,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1984,
    "Rank": 2184,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1985,
    "Rank": 2185,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1986,
    "Rank": 2187,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1987,
    "Rank": 2188,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1988,
    "Rank": 2189,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1989,
    "Rank": 2190,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1990,
    "Rank": 2192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1991,
    "Rank": 2193,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1992,
    "Rank": 2194,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1993,
    "Rank": 2195,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1994,
    "Rank": 2197,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1995,
    "Rank": 2198,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1996,
    "Rank": 2199,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1997,
    "Rank": 2200,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1998,
    "Rank": 2203,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 1999,
    "Rank": 2204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2000,
    "Rank": 2206,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2001,
    "Rank": 2207,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2002,
    "Rank": 2208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2003,
    "Rank": 2209,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2004,
    "Rank": 2210,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2005,
    "Rank": 2214,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2006,
    "Rank": 2216,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2007,
    "Rank": 2217,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2008,
    "Rank": 2218,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2009,
    "Rank": 2219,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2010,
    "Rank": 2221,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2011,
    "Rank": 2222,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2012,
    "Rank": 2223,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2013,
    "Rank": 2224,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2014,
    "Rank": 2225,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2015,
    "Rank": 2226,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2016,
    "Rank": 2227,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2017,
    "Rank": 2228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2018,
    "Rank": 2229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2019,
    "Rank": 2230,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2020,
    "Rank": 2231,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2021,
    "Rank": 2232,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2022,
    "Rank": 2233,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2023,
    "Rank": 2234,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2024,
    "Rank": 2236,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2025,
    "Rank": 2237,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2026,
    "Rank": 2238,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2027,
    "Rank": 2239,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2028,
    "Rank": 2240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2029,
    "Rank": 2241,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2030,
    "Rank": 2242,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2031,
    "Rank": 2243,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2032,
    "Rank": 2244,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2033,
    "Rank": 2245,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2034,
    "Rank": 2246,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2035,
    "Rank": 2247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2036,
    "Rank": 2248,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2037,
    "Rank": 2250,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2038,
    "Rank": 2251,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2039,
    "Rank": 2252,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2040,
    "Rank": 2254,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2041,
    "Rank": 2255,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2042,
    "Rank": 2256,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2043,
    "Rank": 2257,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2044,
    "Rank": 2258,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2045,
    "Rank": 2259,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2046,
    "Rank": 2260,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2047,
    "Rank": 2261,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2048,
    "Rank": 2263,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2049,
    "Rank": 2264,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2050,
    "Rank": 2266,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2051,
    "Rank": 2267,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2052,
    "Rank": 2268,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2053,
    "Rank": 2269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2054,
    "Rank": 2270,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2055,
    "Rank": 2271,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2056,
    "Rank": 2272,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2057,
    "Rank": 2273,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2058,
    "Rank": 2277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2059,
    "Rank": 2278,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2060,
    "Rank": 2279,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2061,
    "Rank": 2280,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2062,
    "Rank": 2282,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2063,
    "Rank": 2283,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2064,
    "Rank": 2285,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2065,
    "Rank": 2286,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2066,
    "Rank": 2287,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2067,
    "Rank": 2288,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2068,
    "Rank": 2289,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2069,
    "Rank": 2290,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2070,
    "Rank": 2291,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2071,
    "Rank": 2292,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2072,
    "Rank": 2293,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2073,
    "Rank": 2295,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2074,
    "Rank": 2296,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2075,
    "Rank": 2297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2076,
    "Rank": 2298,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2077,
    "Rank": 2299,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2078,
    "Rank": 2300,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2079,
    "Rank": 2301,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2080,
    "Rank": 2302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2081,
    "Rank": 2303,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2082,
    "Rank": 2304,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2083,
    "Rank": 2305,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2084,
    "Rank": 2306,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2085,
    "Rank": 2307,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2086,
    "Rank": 2308,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2087,
    "Rank": 2309,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2088,
    "Rank": 2310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2089,
    "Rank": 2312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2090,
    "Rank": 2313,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2091,
    "Rank": 2314,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2092,
    "Rank": 2315,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2093,
    "Rank": 2316,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2094,
    "Rank": 2318,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2095,
    "Rank": 2319,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2096,
    "Rank": 2321,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2097,
    "Rank": 2323,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2098,
    "Rank": 2324,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2099,
    "Rank": 2327,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2100,
    "Rank": 2328,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2101,
    "Rank": 2330,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2102,
    "Rank": 2331,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2103,
    "Rank": 2332,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2104,
    "Rank": 2334,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2105,
    "Rank": 2335,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2106,
    "Rank": 2336,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2107,
    "Rank": 2338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2108,
    "Rank": 2339,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2109,
    "Rank": 2340,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2110,
    "Rank": 2342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2111,
    "Rank": 2343,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2112,
    "Rank": 2344,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2113,
    "Rank": 2345,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2114,
    "Rank": 2346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2115,
    "Rank": 2347,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2116,
    "Rank": 2348,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2117,
    "Rank": 2350,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2118,
    "Rank": 2351,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2119,
    "Rank": 2352,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2120,
    "Rank": 2354,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2121,
    "Rank": 2355,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2122,
    "Rank": 2356,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2123,
    "Rank": 2357,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2124,
    "Rank": 2358,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2125,
    "Rank": 2359,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2126,
    "Rank": 2361,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2127,
    "Rank": 2362,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2128,
    "Rank": 2363,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2129,
    "Rank": 2365,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2130,
    "Rank": 2366,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2131,
    "Rank": 2368,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2132,
    "Rank": 2369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2133,
    "Rank": 2370,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2134,
    "Rank": 2371,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2135,
    "Rank": 2372,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2136,
    "Rank": 2373,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2137,
    "Rank": 2374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2138,
    "Rank": 2376,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2139,
    "Rank": 2378,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2140,
    "Rank": 2379,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2141,
    "Rank": 2380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2142,
    "Rank": 2381,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2143,
    "Rank": 2382,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2144,
    "Rank": 2383,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2145,
    "Rank": 2384,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2146,
    "Rank": 2385,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2147,
    "Rank": 2387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2148,
    "Rank": 2388,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2149,
    "Rank": 2389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2150,
    "Rank": 2390,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2151,
    "Rank": 2391,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2152,
    "Rank": 2394,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2153,
    "Rank": 2395,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2154,
    "Rank": 2396,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2155,
    "Rank": 2397,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2156,
    "Rank": 2398,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2157,
    "Rank": 2399,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2158,
    "Rank": 2400,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2159,
    "Rank": 2401,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2160,
    "Rank": 2402,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2161,
    "Rank": 2403,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2162,
    "Rank": 2404,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2163,
    "Rank": 2405,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2164,
    "Rank": 2406,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2165,
    "Rank": 2407,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2166,
    "Rank": 2408,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2167,
    "Rank": 2409,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2168,
    "Rank": 2412,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2169,
    "Rank": 2413,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2170,
    "Rank": 2414,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2171,
    "Rank": 2416,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2172,
    "Rank": 2417,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2173,
    "Rank": 2418,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2174,
    "Rank": 2420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2175,
    "Rank": 2421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2176,
    "Rank": 2422,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2177,
    "Rank": 2423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2178,
    "Rank": 2424,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2179,
    "Rank": 2425,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2180,
    "Rank": 2428,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2181,
    "Rank": 2429,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2182,
    "Rank": 2430,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2183,
    "Rank": 2431,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2184,
    "Rank": 2432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2185,
    "Rank": 2433,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2186,
    "Rank": 2434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2187,
    "Rank": 2435,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2188,
    "Rank": 2436,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2189,
    "Rank": 2437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2190,
    "Rank": 2438,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2191,
    "Rank": 2442,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2192,
    "Rank": 2443,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2193,
    "Rank": 2444,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2194,
    "Rank": 2445,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2195,
    "Rank": 2446,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2196,
    "Rank": 2447,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2197,
    "Rank": 2448,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2198,
    "Rank": 2449,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2199,
    "Rank": 2450,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2200,
    "Rank": 2451,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2201,
    "Rank": 2452,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2202,
    "Rank": 2453,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2203,
    "Rank": 2454,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2204,
    "Rank": 2455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2205,
    "Rank": 2458,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2206,
    "Rank": 2459,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2207,
    "Rank": 2460,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2208,
    "Rank": 2461,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2209,
    "Rank": 2462,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2210,
    "Rank": 2463,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2211,
    "Rank": 2464,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2212,
    "Rank": 2466,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2213,
    "Rank": 2467,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2214,
    "Rank": 2469,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2215,
    "Rank": 2471,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2216,
    "Rank": 2472,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2217,
    "Rank": 2473,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2218,
    "Rank": 2474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2219,
    "Rank": 2475,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2220,
    "Rank": 2478,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2221,
    "Rank": 2479,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2222,
    "Rank": 2480,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2223,
    "Rank": 2482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2224,
    "Rank": 2483,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2225,
    "Rank": 2484,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2226,
    "Rank": 2486,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2227,
    "Rank": 2488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2228,
    "Rank": 2489,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2229,
    "Rank": 2490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2230,
    "Rank": 2494,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2231,
    "Rank": 2495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2232,
    "Rank": 2496,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2233,
    "Rank": 2497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2234,
    "Rank": 2501,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2235,
    "Rank": 2502,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2236,
    "Rank": 2503,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2237,
    "Rank": 2504,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2238,
    "Rank": 2505,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2239,
    "Rank": 2506,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2240,
    "Rank": 2507,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2241,
    "Rank": 2509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2242,
    "Rank": 2510,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2243,
    "Rank": 2511,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2244,
    "Rank": 2512,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2245,
    "Rank": 2513,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2246,
    "Rank": 2514,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2247,
    "Rank": 2515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2248,
    "Rank": 2516,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2249,
    "Rank": 2517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2250,
    "Rank": 2518,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2251,
    "Rank": 2520,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2252,
    "Rank": 2521,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2253,
    "Rank": 2522,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2254,
    "Rank": 2523,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2255,
    "Rank": 2524,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2256,
    "Rank": 2525,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2257,
    "Rank": 2526,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2258,
    "Rank": 2527,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2259,
    "Rank": 2528,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2260,
    "Rank": 2529,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2261,
    "Rank": 2532,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2262,
    "Rank": 2533,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2263,
    "Rank": 2534,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2264,
    "Rank": 2535,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2265,
    "Rank": 2536,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2266,
    "Rank": 2538,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2267,
    "Rank": 2539,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2268,
    "Rank": 2540,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2269,
    "Rank": 2541,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2270,
    "Rank": 2542,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2271,
    "Rank": 2544,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2272,
    "Rank": 2545,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2273,
    "Rank": 2546,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2274,
    "Rank": 2548,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2275,
    "Rank": 2549,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2276,
    "Rank": 2550,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2277,
    "Rank": 2551,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2278,
    "Rank": 2552,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2279,
    "Rank": 2553,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2280,
    "Rank": 2554,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2281,
    "Rank": 2555,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2282,
    "Rank": 2556,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2283,
    "Rank": 2558,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2284,
    "Rank": 2559,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2285,
    "Rank": 2560,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2286,
    "Rank": 2561,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2287,
    "Rank": 2563,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2288,
    "Rank": 2564,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2289,
    "Rank": 2565,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2290,
    "Rank": 2566,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2291,
    "Rank": 2567,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2292,
    "Rank": 2568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2293,
    "Rank": 2569,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2294,
    "Rank": 2570,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2295,
    "Rank": 2571,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2296,
    "Rank": 2572,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2297,
    "Rank": 2573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2298,
    "Rank": 2574,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2299,
    "Rank": 2575,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2300,
    "Rank": 2576,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2301,
    "Rank": 2578,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2302,
    "Rank": 2579,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2303,
    "Rank": 2580,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2304,
    "Rank": 2584,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2305,
    "Rank": 2585,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2306,
    "Rank": 2586,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2307,
    "Rank": 2587,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2308,
    "Rank": 2588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2309,
    "Rank": 2591,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2310,
    "Rank": 2592,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2311,
    "Rank": 2593,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2312,
    "Rank": 2594,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2313,
    "Rank": 2595,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2314,
    "Rank": 2596,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2315,
    "Rank": 2597,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2316,
    "Rank": 2599,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2317,
    "Rank": 2600,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2318,
    "Rank": 2602,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2319,
    "Rank": 2603,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2320,
    "Rank": 2606,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2321,
    "Rank": 2607,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2322,
    "Rank": 2609,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2323,
    "Rank": 2610,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2324,
    "Rank": 2611,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2325,
    "Rank": 2613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2326,
    "Rank": 2614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2327,
    "Rank": 2615,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2328,
    "Rank": 2616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2329,
    "Rank": 2617,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2330,
    "Rank": 2618,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2331,
    "Rank": 2620,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2332,
    "Rank": 2621,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2333,
    "Rank": 2622,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2334,
    "Rank": 2623,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2335,
    "Rank": 2624,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2336,
    "Rank": 2625,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2337,
    "Rank": 2626,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2338,
    "Rank": 2627,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2339,
    "Rank": 2628,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2340,
    "Rank": 2629,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2341,
    "Rank": 2630,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2342,
    "Rank": 2631,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2343,
    "Rank": 2632,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2344,
    "Rank": 2633,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2345,
    "Rank": 2634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2346,
    "Rank": 2635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2347,
    "Rank": 2637,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2348,
    "Rank": 2638,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2349,
    "Rank": 2639,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2350,
    "Rank": 2640,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2351,
    "Rank": 2641,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2352,
    "Rank": 2642,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2353,
    "Rank": 2643,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2354,
    "Rank": 2644,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2355,
    "Rank": 2645,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2356,
    "Rank": 2646,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2357,
    "Rank": 2647,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2358,
    "Rank": 2648,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2359,
    "Rank": 2649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2360,
    "Rank": 2650,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2361,
    "Rank": 2651,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2362,
    "Rank": 2652,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2363,
    "Rank": 2653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2364,
    "Rank": 2656,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2365,
    "Rank": 2657,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2366,
    "Rank": 2658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2367,
    "Rank": 2659,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2368,
    "Rank": 2661,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2369,
    "Rank": 2662,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2370,
    "Rank": 2663,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2371,
    "Rank": 2664,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2372,
    "Rank": 2665,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2373,
    "Rank": 2669,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2374,
    "Rank": 2670,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2375,
    "Rank": 2671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2376,
    "Rank": 2672,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2377,
    "Rank": 2674,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2378,
    "Rank": 2675,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2379,
    "Rank": 2676,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2380,
    "Rank": 2678,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2381,
    "Rank": 2679,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2382,
    "Rank": 2680,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2383,
    "Rank": 2681,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2384,
    "Rank": 2683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2385,
    "Rank": 2684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2386,
    "Rank": 2685,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2387,
    "Rank": 2686,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2388,
    "Rank": 2687,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2389,
    "Rank": 2688,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2390,
    "Rank": 2689,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2391,
    "Rank": 2690,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2392,
    "Rank": 2691,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2393,
    "Rank": 2692,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2394,
    "Rank": 2693,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2395,
    "Rank": 2694,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2396,
    "Rank": 2695,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2397,
    "Rank": 2697,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2398,
    "Rank": 2698,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2399,
    "Rank": 2699,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2400,
    "Rank": 2702,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2401,
    "Rank": 2703,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2402,
    "Rank": 2705,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2403,
    "Rank": 2706,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2404,
    "Rank": 2708,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2405,
    "Rank": 2709,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2406,
    "Rank": 2710,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2407,
    "Rank": 2711,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2408,
    "Rank": 2712,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2409,
    "Rank": 2713,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2410,
    "Rank": 2714,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2411,
    "Rank": 2715,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2412,
    "Rank": 2716,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2413,
    "Rank": 2717,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2414,
    "Rank": 2719,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2415,
    "Rank": 2720,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2416,
    "Rank": 2721,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2417,
    "Rank": 2723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2418,
    "Rank": 2724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2419,
    "Rank": 2725,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2420,
    "Rank": 2727,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2421,
    "Rank": 2729,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2422,
    "Rank": 2730,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2423,
    "Rank": 2731,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2424,
    "Rank": 2732,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2425,
    "Rank": 2734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2426,
    "Rank": 2735,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2427,
    "Rank": 2736,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2428,
    "Rank": 2737,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2429,
    "Rank": 2738,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2430,
    "Rank": 2739,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2431,
    "Rank": 2740,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2432,
    "Rank": 2741,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2433,
    "Rank": 2742,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2434,
    "Rank": 2745,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2435,
    "Rank": 2746,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2436,
    "Rank": 2747,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2437,
    "Rank": 2749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2438,
    "Rank": 2751,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2439,
    "Rank": 2752,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2440,
    "Rank": 2753,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2441,
    "Rank": 2754,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2442,
    "Rank": 2756,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2443,
    "Rank": 2757,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2444,
    "Rank": 2758,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2445,
    "Rank": 2759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2446,
    "Rank": 2760,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2447,
    "Rank": 2762,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2448,
    "Rank": 2763,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2449,
    "Rank": 2764,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2450,
    "Rank": 2765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2451,
    "Rank": 2766,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2452,
    "Rank": 2767,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2453,
    "Rank": 2768,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2454,
    "Rank": 2769,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2455,
    "Rank": 2771,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2456,
    "Rank": 2772,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2457,
    "Rank": 2773,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2458,
    "Rank": 2774,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2459,
    "Rank": 2775,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2460,
    "Rank": 2776,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2461,
    "Rank": 2777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2462,
    "Rank": 2778,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2463,
    "Rank": 2780,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2464,
    "Rank": 2784,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2465,
    "Rank": 2785,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2466,
    "Rank": 2787,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2467,
    "Rank": 2789,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2468,
    "Rank": 2790,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2469,
    "Rank": 2791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2470,
    "Rank": 2792,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2471,
    "Rank": 2793,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2472,
    "Rank": 2794,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2473,
    "Rank": 2795,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2474,
    "Rank": 2796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2475,
    "Rank": 2797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2476,
    "Rank": 2798,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2477,
    "Rank": 2799,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2478,
    "Rank": 2802,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2479,
    "Rank": 2803,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2480,
    "Rank": 2804,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2481,
    "Rank": 2805,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2482,
    "Rank": 2806,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2483,
    "Rank": 2807,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2484,
    "Rank": 2808,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2485,
    "Rank": 2809,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2486,
    "Rank": 2810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2487,
    "Rank": 2811,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2488,
    "Rank": 2812,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2489,
    "Rank": 2814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2490,
    "Rank": 2816,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2491,
    "Rank": 2818,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2492,
    "Rank": 2819,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2493,
    "Rank": 2821,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2494,
    "Rank": 2822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2495,
    "Rank": 2823,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2496,
    "Rank": 2824,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2497,
    "Rank": 2825,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2498,
    "Rank": 2826,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2499,
    "Rank": 2827,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2500,
    "Rank": 2828,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2501,
    "Rank": 2829,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2502,
    "Rank": 2830,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2503,
    "Rank": 2831,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2504,
    "Rank": 2832,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2505,
    "Rank": 2833,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2506,
    "Rank": 2834,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2507,
    "Rank": 2835,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2508,
    "Rank": 2836,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2509,
    "Rank": 2837,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2510,
    "Rank": 2838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2511,
    "Rank": 2840,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2512,
    "Rank": 2841,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2513,
    "Rank": 2842,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2514,
    "Rank": 2844,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2515,
    "Rank": 2845,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2516,
    "Rank": 2846,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2517,
    "Rank": 2847,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2518,
    "Rank": 2848,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2519,
    "Rank": 2849,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2520,
    "Rank": 2850,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2521,
    "Rank": 2852,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2522,
    "Rank": 2854,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2523,
    "Rank": 2855,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2524,
    "Rank": 2857,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2525,
    "Rank": 2858,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2526,
    "Rank": 2859,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2527,
    "Rank": 2860,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2528,
    "Rank": 2861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2529,
    "Rank": 2862,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2530,
    "Rank": 2864,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2531,
    "Rank": 2866,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2532,
    "Rank": 2867,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2533,
    "Rank": 2869,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2534,
    "Rank": 2871,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2535,
    "Rank": 2872,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2536,
    "Rank": 2873,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2537,
    "Rank": 2874,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2538,
    "Rank": 2875,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2539,
    "Rank": 2876,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2540,
    "Rank": 2877,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2541,
    "Rank": 2878,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2542,
    "Rank": 2880,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2543,
    "Rank": 2881,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2544,
    "Rank": 2882,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2545,
    "Rank": 2883,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2546,
    "Rank": 2885,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2547,
    "Rank": 2887,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2548,
    "Rank": 2888,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2549,
    "Rank": 2889,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2550,
    "Rank": 2897,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2551,
    "Rank": 2898,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2552,
    "Rank": 2899,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2553,
    "Rank": 2901,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2554,
    "Rank": 2902,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2555,
    "Rank": 2904,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2556,
    "Rank": 2905,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2557,
    "Rank": 2906,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2558,
    "Rank": 2907,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2559,
    "Rank": 2908,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2560,
    "Rank": 2909,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2561,
    "Rank": 2911,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2562,
    "Rank": 2912,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2563,
    "Rank": 2913,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2564,
    "Rank": 2915,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2565,
    "Rank": 2916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2566,
    "Rank": 2918,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2567,
    "Rank": 2919,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2568,
    "Rank": 2920,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2569,
    "Rank": 2921,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2570,
    "Rank": 2922,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2571,
    "Rank": 2923,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2572,
    "Rank": 2926,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2573,
    "Rank": 2927,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2574,
    "Rank": 2928,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2575,
    "Rank": 2930,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2576,
    "Rank": 2931,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2577,
    "Rank": 2932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2578,
    "Rank": 2933,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2579,
    "Rank": 2934,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2580,
    "Rank": 2935,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2581,
    "Rank": 2936,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2582,
    "Rank": 2939,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2583,
    "Rank": 2940,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2584,
    "Rank": 2941,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2585,
    "Rank": 2942,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2586,
    "Rank": 2943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2587,
    "Rank": 2946,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2588,
    "Rank": 2947,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2589,
    "Rank": 2948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2590,
    "Rank": 2949,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2591,
    "Rank": 2950,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2592,
    "Rank": 2951,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2593,
    "Rank": 2952,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2594,
    "Rank": 2955,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2595,
    "Rank": 2956,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2596,
    "Rank": 2959,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2597,
    "Rank": 2961,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2598,
    "Rank": 2962,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2599,
    "Rank": 2963,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2600,
    "Rank": 2964,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2601,
    "Rank": 2965,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2602,
    "Rank": 2966,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2603,
    "Rank": 2967,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2604,
    "Rank": 2968,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2605,
    "Rank": 2969,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2606,
    "Rank": 2970,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2607,
    "Rank": 2971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2608,
    "Rank": 2973,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2609,
    "Rank": 2974,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2610,
    "Rank": 2976,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2611,
    "Rank": 2977,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2612,
    "Rank": 2978,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2613,
    "Rank": 2981,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2614,
    "Rank": 2982,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2615,
    "Rank": 2983,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2616,
    "Rank": 2985,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2617,
    "Rank": 2989,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2618,
    "Rank": 2990,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2619,
    "Rank": 2991,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2620,
    "Rank": 2992,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2621,
    "Rank": 2993,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2622,
    "Rank": 2994,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2623,
    "Rank": 2995,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2624,
    "Rank": 2996,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2625,
    "Rank": 2997,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2626,
    "Rank": 2998,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2627,
    "Rank": 2999,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2628,
    "Rank": 3000,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2629,
    "Rank": 3001,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2630,
    "Rank": 3003,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2631,
    "Rank": 3004,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2632,
    "Rank": 3005,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2633,
    "Rank": 3006,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2634,
    "Rank": 3007,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2635,
    "Rank": 3008,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2636,
    "Rank": 3009,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2637,
    "Rank": 3010,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2638,
    "Rank": 3011,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2639,
    "Rank": 3013,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2640,
    "Rank": 3015,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2641,
    "Rank": 3016,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2642,
    "Rank": 3018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2643,
    "Rank": 3020,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2644,
    "Rank": 3021,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2645,
    "Rank": 3022,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2646,
    "Rank": 3023,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2647,
    "Rank": 3024,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2648,
    "Rank": 3025,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2649,
    "Rank": 3026,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2650,
    "Rank": 3027,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2651,
    "Rank": 3028,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2652,
    "Rank": 3029,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2653,
    "Rank": 3030,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2654,
    "Rank": 3032,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2655,
    "Rank": 3033,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2656,
    "Rank": 3035,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2657,
    "Rank": 3036,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2658,
    "Rank": 3037,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2659,
    "Rank": 3038,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2660,
    "Rank": 3039,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2661,
    "Rank": 3040,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2662,
    "Rank": 3041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2663,
    "Rank": 3042,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2664,
    "Rank": 3043,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2665,
    "Rank": 3044,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2666,
    "Rank": 3045,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2667,
    "Rank": 3046,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2668,
    "Rank": 3049,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2669,
    "Rank": 3052,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2670,
    "Rank": 3053,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2671,
    "Rank": 3054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2672,
    "Rank": 3055,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2673,
    "Rank": 3056,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2674,
    "Rank": 3057,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2675,
    "Rank": 3059,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2676,
    "Rank": 3060,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2677,
    "Rank": 3062,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2678,
    "Rank": 3064,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2679,
    "Rank": 3065,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2680,
    "Rank": 3066,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2681,
    "Rank": 3068,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2682,
    "Rank": 3069,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2683,
    "Rank": 3070,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2684,
    "Rank": 3071,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2685,
    "Rank": 3072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2686,
    "Rank": 3074,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2687,
    "Rank": 3075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2688,
    "Rank": 3078,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2689,
    "Rank": 3079,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2690,
    "Rank": 3080,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2691,
    "Rank": 3081,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2692,
    "Rank": 3083,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2693,
    "Rank": 3084,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2694,
    "Rank": 3085,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2695,
    "Rank": 3086,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2696,
    "Rank": 3090,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2697,
    "Rank": 3091,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2698,
    "Rank": 3094,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2699,
    "Rank": 3095,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2700,
    "Rank": 3097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2701,
    "Rank": 3098,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2702,
    "Rank": 3100,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2703,
    "Rank": 3101,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2704,
    "Rank": 3104,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2705,
    "Rank": 3105,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2706,
    "Rank": 3106,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2707,
    "Rank": 3107,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2708,
    "Rank": 3108,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2709,
    "Rank": 3109,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2710,
    "Rank": 3110,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2711,
    "Rank": 3114,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2712,
    "Rank": 3115,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2713,
    "Rank": 3118,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2714,
    "Rank": 3120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2715,
    "Rank": 3121,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2716,
    "Rank": 3122,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2717,
    "Rank": 3123,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2718,
    "Rank": 3124,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2719,
    "Rank": 3126,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2720,
    "Rank": 3128,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2721,
    "Rank": 3129,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2722,
    "Rank": 3130,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2723,
    "Rank": 3131,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2724,
    "Rank": 3132,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2725,
    "Rank": 3133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2726,
    "Rank": 3134,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2727,
    "Rank": 3135,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2728,
    "Rank": 3136,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2729,
    "Rank": 3137,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2730,
    "Rank": 3138,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2731,
    "Rank": 3139,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2732,
    "Rank": 3140,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2733,
    "Rank": 3141,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2734,
    "Rank": 3142,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2735,
    "Rank": 3144,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2736,
    "Rank": 3145,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2737,
    "Rank": 3147,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2738,
    "Rank": 3148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2739,
    "Rank": 3150,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2740,
    "Rank": 3153,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2741,
    "Rank": 3154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2742,
    "Rank": 3156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2743,
    "Rank": 3157,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2744,
    "Rank": 3158,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2745,
    "Rank": 3159,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2746,
    "Rank": 3161,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2747,
    "Rank": 3162,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2748,
    "Rank": 3163,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2749,
    "Rank": 3165,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2750,
    "Rank": 3166,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2751,
    "Rank": 3169,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2752,
    "Rank": 3170,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2753,
    "Rank": 3171,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2754,
    "Rank": 3172,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2755,
    "Rank": 3174,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2756,
    "Rank": 3176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2757,
    "Rank": 3177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2758,
    "Rank": 3178,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2759,
    "Rank": 3181,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2760,
    "Rank": 3184,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2761,
    "Rank": 3186,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2762,
    "Rank": 3187,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2763,
    "Rank": 3189,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2764,
    "Rank": 3190,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2765,
    "Rank": 3191,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2766,
    "Rank": 3193,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2767,
    "Rank": 3194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2768,
    "Rank": 3195,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2769,
    "Rank": 3196,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2770,
    "Rank": 3197,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2771,
    "Rank": 3198,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2772,
    "Rank": 3199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2773,
    "Rank": 3201,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2774,
    "Rank": 3202,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2775,
    "Rank": 3204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2776,
    "Rank": 3206,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2777,
    "Rank": 3207,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2778,
    "Rank": 3208,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2779,
    "Rank": 3209,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2780,
    "Rank": 3210,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2781,
    "Rank": 3211,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2782,
    "Rank": 3213,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2783,
    "Rank": 3214,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2784,
    "Rank": 3215,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2785,
    "Rank": 3216,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2786,
    "Rank": 3218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2787,
    "Rank": 3220,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2788,
    "Rank": 3221,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2789,
    "Rank": 3222,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2790,
    "Rank": 3224,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2791,
    "Rank": 3225,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2792,
    "Rank": 3227,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2793,
    "Rank": 3228,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2794,
    "Rank": 3229,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2795,
    "Rank": 3230,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2796,
    "Rank": 3232,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2797,
    "Rank": 3233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2798,
    "Rank": 3234,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2799,
    "Rank": 3236,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2800,
    "Rank": 3237,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2801,
    "Rank": 3238,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2802,
    "Rank": 3239,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2803,
    "Rank": 3240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2804,
    "Rank": 3242,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2805,
    "Rank": 3243,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2806,
    "Rank": 3245,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2807,
    "Rank": 3247,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2808,
    "Rank": 3248,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2809,
    "Rank": 3250,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2810,
    "Rank": 3251,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2811,
    "Rank": 3252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2812,
    "Rank": 3254,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2813,
    "Rank": 3255,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2814,
    "Rank": 3257,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2815,
    "Rank": 3258,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2816,
    "Rank": 3259,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2817,
    "Rank": 3260,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2818,
    "Rank": 3264,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2819,
    "Rank": 3265,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2820,
    "Rank": 3267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2821,
    "Rank": 3270,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2822,
    "Rank": 3271,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2823,
    "Rank": 3273,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2824,
    "Rank": 3274,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2825,
    "Rank": 3275,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2826,
    "Rank": 3276,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2827,
    "Rank": 3278,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2828,
    "Rank": 3280,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2829,
    "Rank": 3281,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2830,
    "Rank": 3283,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2831,
    "Rank": 3285,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2832,
    "Rank": 3286,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2833,
    "Rank": 3287,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2834,
    "Rank": 3288,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2835,
    "Rank": 3289,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2836,
    "Rank": 3290,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2837,
    "Rank": 3291,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2838,
    "Rank": 3292,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2839,
    "Rank": 3298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2840,
    "Rank": 3299,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2841,
    "Rank": 3300,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2842,
    "Rank": 3301,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2843,
    "Rank": 3302,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2844,
    "Rank": 3303,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2845,
    "Rank": 3304,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2846,
    "Rank": 3305,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2847,
    "Rank": 3306,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2848,
    "Rank": 3308,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2849,
    "Rank": 3310,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2850,
    "Rank": 3311,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2851,
    "Rank": 3312,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2852,
    "Rank": 3314,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2853,
    "Rank": 3315,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2854,
    "Rank": 3316,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2855,
    "Rank": 3317,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2856,
    "Rank": 3318,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2857,
    "Rank": 3319,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2858,
    "Rank": 3320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2859,
    "Rank": 3321,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2860,
    "Rank": 3322,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2861,
    "Rank": 3323,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2862,
    "Rank": 3324,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2863,
    "Rank": 3325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2864,
    "Rank": 3326,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2865,
    "Rank": 3328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2866,
    "Rank": 3329,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2867,
    "Rank": 3330,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2868,
    "Rank": 3333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2869,
    "Rank": 3334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2870,
    "Rank": 3336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2871,
    "Rank": 3339,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2872,
    "Rank": 3340,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2873,
    "Rank": 3341,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2874,
    "Rank": 3342,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2875,
    "Rank": 3345,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2876,
    "Rank": 3346,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2877,
    "Rank": 3348,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2878,
    "Rank": 3349,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2879,
    "Rank": 3353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2880,
    "Rank": 3355,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2881,
    "Rank": 3357,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2882,
    "Rank": 3360,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2883,
    "Rank": 3361,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2884,
    "Rank": 3362,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2885,
    "Rank": 3363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2886,
    "Rank": 3364,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2887,
    "Rank": 3365,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2888,
    "Rank": 3367,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2889,
    "Rank": 3368,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2890,
    "Rank": 3369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2891,
    "Rank": 3370,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2892,
    "Rank": 3371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2893,
    "Rank": 3372,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2894,
    "Rank": 3373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2895,
    "Rank": 3374,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2896,
    "Rank": 3375,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2897,
    "Rank": 3379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2898,
    "Rank": 3380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2899,
    "Rank": 3381,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2900,
    "Rank": 3382,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2901,
    "Rank": 3384,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2902,
    "Rank": 3385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2903,
    "Rank": 3386,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2904,
    "Rank": 3387,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2905,
    "Rank": 3388,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2906,
    "Rank": 3389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2907,
    "Rank": 3390,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2908,
    "Rank": 3391,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2909,
    "Rank": 3392,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2910,
    "Rank": 3394,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2911,
    "Rank": 3396,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2912,
    "Rank": 3397,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2913,
    "Rank": 3400,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2914,
    "Rank": 3403,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2915,
    "Rank": 3404,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2916,
    "Rank": 3405,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2917,
    "Rank": 3408,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2918,
    "Rank": 3410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2919,
    "Rank": 3411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2920,
    "Rank": 3413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2921,
    "Rank": 3414,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2922,
    "Rank": 3416,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2923,
    "Rank": 3418,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2924,
    "Rank": 3419,
    "Allotted Quota": "Foreign Country Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2925,
    "Rank": 3420,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2926,
    "Rank": 3421,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2927,
    "Rank": 3422,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2928,
    "Rank": 3423,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2929,
    "Rank": 3425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2930,
    "Rank": 3426,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2931,
    "Rank": 3427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2932,
    "Rank": 3429,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2933,
    "Rank": 3431,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2934,
    "Rank": 3432,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2935,
    "Rank": 3433,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2936,
    "Rank": 3436,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2937,
    "Rank": 3439,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2938,
    "Rank": 3440,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2939,
    "Rank": 3442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2940,
    "Rank": 3443,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2941,
    "Rank": 3444,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2942,
    "Rank": 3445,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2943,
    "Rank": 3446,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2944,
    "Rank": 3447,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2945,
    "Rank": 3448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2946,
    "Rank": 3449,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2947,
    "Rank": 3450,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2948,
    "Rank": 3451,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2949,
    "Rank": 3453,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2950,
    "Rank": 3454,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2951,
    "Rank": 3455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2952,
    "Rank": 3461,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2953,
    "Rank": 3462,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2954,
    "Rank": 3463,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2955,
    "Rank": 3464,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2956,
    "Rank": 3465,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2957,
    "Rank": 3466,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2958,
    "Rank": 3469,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2959,
    "Rank": 3470,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2960,
    "Rank": 3471,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2961,
    "Rank": 3473,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2962,
    "Rank": 3476,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2963,
    "Rank": 3477,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2964,
    "Rank": 3478,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2965,
    "Rank": 3479,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2966,
    "Rank": 3480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2967,
    "Rank": 3482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2968,
    "Rank": 3486,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2969,
    "Rank": 3488,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2970,
    "Rank": 3490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2971,
    "Rank": 3491,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2972,
    "Rank": 3492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2973,
    "Rank": 3493,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2974,
    "Rank": 3494,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2975,
    "Rank": 3495,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2976,
    "Rank": 3496,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2977,
    "Rank": 3497,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2978,
    "Rank": 3498,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2979,
    "Rank": 3500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2980,
    "Rank": 3501,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2981,
    "Rank": 3502,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2982,
    "Rank": 3505,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2983,
    "Rank": 3506,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2984,
    "Rank": 3507,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2985,
    "Rank": 3508,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2986,
    "Rank": 3509,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2987,
    "Rank": 3511,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2988,
    "Rank": 3513,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2989,
    "Rank": 3514,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2990,
    "Rank": 3515,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2991,
    "Rank": 3517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2992,
    "Rank": 3519,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2993,
    "Rank": 3521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2994,
    "Rank": 3522,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2995,
    "Rank": 3524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2996,
    "Rank": 3525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2997,
    "Rank": 3527,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2998,
    "Rank": 3531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 2999,
    "Rank": 3532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3000,
    "Rank": 3533,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3001,
    "Rank": 3534,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3002,
    "Rank": 3536,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3003,
    "Rank": 3537,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3004,
    "Rank": 3538,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3005,
    "Rank": 3540,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3006,
    "Rank": 3542,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3007,
    "Rank": 3544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3008,
    "Rank": 3545,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3009,
    "Rank": 3549,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3010,
    "Rank": 3551,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3011,
    "Rank": 3553,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3012,
    "Rank": 3555,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3013,
    "Rank": 3557,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3014,
    "Rank": 3559,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3015,
    "Rank": 3560,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3016,
    "Rank": 3561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3017,
    "Rank": 3562,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3018,
    "Rank": 3563,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3019,
    "Rank": 3564,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3020,
    "Rank": 3565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3021,
    "Rank": 3567,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3022,
    "Rank": 3568,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3023,
    "Rank": 3569,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3024,
    "Rank": 3571,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3025,
    "Rank": 3572,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3026,
    "Rank": 3573,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3027,
    "Rank": 3576,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3028,
    "Rank": 3577,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3029,
    "Rank": 3578,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3030,
    "Rank": 3579,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3031,
    "Rank": 3580,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3032,
    "Rank": 3584,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3033,
    "Rank": 3585,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3034,
    "Rank": 3587,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3035,
    "Rank": 3588,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3036,
    "Rank": 3590,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3037,
    "Rank": 3593,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3038,
    "Rank": 3596,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3039,
    "Rank": 3597,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3040,
    "Rank": 3599,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3041,
    "Rank": 3601,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3042,
    "Rank": 3603,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3043,
    "Rank": 3604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3044,
    "Rank": 3608,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3045,
    "Rank": 3609,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3046,
    "Rank": 3610,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3047,
    "Rank": 3611,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3048,
    "Rank": 3612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3049,
    "Rank": 3613,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3050,
    "Rank": 3615,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3051,
    "Rank": 3616,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3052,
    "Rank": 3617,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3053,
    "Rank": 3618,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3054,
    "Rank": 3619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3055,
    "Rank": 3620,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3056,
    "Rank": 3621,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3057,
    "Rank": 3622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3058,
    "Rank": 3624,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3059,
    "Rank": 3626,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3060,
    "Rank": 3627,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3061,
    "Rank": 3629,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3062,
    "Rank": 3630,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3063,
    "Rank": 3633,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3064,
    "Rank": 3635,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3065,
    "Rank": 3636,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3066,
    "Rank": 3637,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3067,
    "Rank": 3640,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3068,
    "Rank": 3641,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3069,
    "Rank": 3644,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3070,
    "Rank": 3645,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3071,
    "Rank": 3651,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3072,
    "Rank": 3652,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3073,
    "Rank": 3654,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3074,
    "Rank": 3655,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3075,
    "Rank": 3657,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3076,
    "Rank": 3659,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3077,
    "Rank": 3661,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3078,
    "Rank": 3662,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3079,
    "Rank": 3663,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3080,
    "Rank": 3665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3081,
    "Rank": 3666,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3082,
    "Rank": 3667,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3083,
    "Rank": 3668,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3084,
    "Rank": 3669,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3085,
    "Rank": 3670,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3086,
    "Rank": 3671,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3087,
    "Rank": 3672,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3088,
    "Rank": 3675,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3089,
    "Rank": 3676,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3090,
    "Rank": 3678,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3091,
    "Rank": 3679,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3092,
    "Rank": 3680,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3093,
    "Rank": 3681,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3094,
    "Rank": 3682,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3095,
    "Rank": 3683,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3096,
    "Rank": 3684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3097,
    "Rank": 3688,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3098,
    "Rank": 3691,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3099,
    "Rank": 3692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3100,
    "Rank": 3693,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3101,
    "Rank": 3694,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3102,
    "Rank": 3697,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3103,
    "Rank": 3699,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3104,
    "Rank": 3700,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3105,
    "Rank": 3701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3106,
    "Rank": 3702,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3107,
    "Rank": 3703,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3108,
    "Rank": 3705,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3109,
    "Rank": 3706,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3110,
    "Rank": 3709,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3111,
    "Rank": 3710,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3112,
    "Rank": 3711,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3113,
    "Rank": 3712,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3114,
    "Rank": 3713,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3115,
    "Rank": 3714,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3116,
    "Rank": 3715,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3117,
    "Rank": 3716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3118,
    "Rank": 3718,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3119,
    "Rank": 3719,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3120,
    "Rank": 3720,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3121,
    "Rank": 3722,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3122,
    "Rank": 3724,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3123,
    "Rank": 3725,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3124,
    "Rank": 3727,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3125,
    "Rank": 3728,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3126,
    "Rank": 3729,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3127,
    "Rank": 3732,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3128,
    "Rank": 3734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3129,
    "Rank": 3735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3130,
    "Rank": 3736,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3131,
    "Rank": 3737,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3132,
    "Rank": 3738,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3133,
    "Rank": 3739,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3134,
    "Rank": 3740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3135,
    "Rank": 3741,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3136,
    "Rank": 3742,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3137,
    "Rank": 3743,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3138,
    "Rank": 3744,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3139,
    "Rank": 3748,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3140,
    "Rank": 3750,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3141,
    "Rank": 3751,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3142,
    "Rank": 3753,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3143,
    "Rank": 3754,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3144,
    "Rank": 3755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3145,
    "Rank": 3756,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3146,
    "Rank": 3758,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3147,
    "Rank": 3759,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3148,
    "Rank": 3763,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3149,
    "Rank": 3764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3150,
    "Rank": 3766,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3151,
    "Rank": 3767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3152,
    "Rank": 3768,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3153,
    "Rank": 3769,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3154,
    "Rank": 3770,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3155,
    "Rank": 3773,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3156,
    "Rank": 3774,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3157,
    "Rank": 3775,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3158,
    "Rank": 3777,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3159,
    "Rank": 3779,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3160,
    "Rank": 3780,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3161,
    "Rank": 3783,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3162,
    "Rank": 3786,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3163,
    "Rank": 3787,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3164,
    "Rank": 3790,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3165,
    "Rank": 3793,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3166,
    "Rank": 3795,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3167,
    "Rank": 3799,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3168,
    "Rank": 3800,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3169,
    "Rank": 3801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3170,
    "Rank": 3802,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3171,
    "Rank": 3803,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3172,
    "Rank": 3804,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3173,
    "Rank": 3807,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3174,
    "Rank": 3808,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3175,
    "Rank": 3809,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3176,
    "Rank": 3810,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3177,
    "Rank": 3811,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3178,
    "Rank": 3812,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3179,
    "Rank": 3813,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3180,
    "Rank": 3815,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3181,
    "Rank": 3816,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3182,
    "Rank": 3818,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3183,
    "Rank": 3819,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3184,
    "Rank": 3820,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3185,
    "Rank": 3822,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3186,
    "Rank": 3823,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3187,
    "Rank": 3824,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3188,
    "Rank": 3825,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3189,
    "Rank": 3826,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3190,
    "Rank": 3827,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3191,
    "Rank": 3828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3192,
    "Rank": 3833,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3193,
    "Rank": 3834,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3194,
    "Rank": 3836,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3195,
    "Rank": 3837,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3196,
    "Rank": 3840,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3197,
    "Rank": 3843,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3198,
    "Rank": 3845,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3199,
    "Rank": 3846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3200,
    "Rank": 3847,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3201,
    "Rank": 3848,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3202,
    "Rank": 3849,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3203,
    "Rank": 3853,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3204,
    "Rank": 3856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3205,
    "Rank": 3860,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3206,
    "Rank": 3861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3207,
    "Rank": 3863,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3208,
    "Rank": 3866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3209,
    "Rank": 3868,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3210,
    "Rank": 3869,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3211,
    "Rank": 3870,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3212,
    "Rank": 3871,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3213,
    "Rank": 3874,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3214,
    "Rank": 3875,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3215,
    "Rank": 3878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3216,
    "Rank": 3879,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3217,
    "Rank": 3882,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3218,
    "Rank": 3883,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3219,
    "Rank": 3887,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3220,
    "Rank": 3889,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3221,
    "Rank": 3890,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3222,
    "Rank": 3891,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3223,
    "Rank": 3892,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3224,
    "Rank": 3894,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3225,
    "Rank": 3897,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3226,
    "Rank": 3898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3227,
    "Rank": 3900,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3228,
    "Rank": 3901,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3229,
    "Rank": 3903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3230,
    "Rank": 3904,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3231,
    "Rank": 3906,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3232,
    "Rank": 3908,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3233,
    "Rank": 3911,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3234,
    "Rank": 3912,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3235,
    "Rank": 3913,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3236,
    "Rank": 3916,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3237,
    "Rank": 3918,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3238,
    "Rank": 3919,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3239,
    "Rank": 3920,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3240,
    "Rank": 3922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3241,
    "Rank": 3923,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3242,
    "Rank": 3924,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3243,
    "Rank": 3926,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3244,
    "Rank": 3929,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3245,
    "Rank": 3930,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3246,
    "Rank": 3931,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3247,
    "Rank": 3932,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3248,
    "Rank": 3933,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3249,
    "Rank": 3934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3250,
    "Rank": 3935,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3251,
    "Rank": 3936,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3252,
    "Rank": 3937,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3253,
    "Rank": 3938,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3254,
    "Rank": 3939,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3255,
    "Rank": 3940,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3256,
    "Rank": 3942,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3257,
    "Rank": 3944,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3258,
    "Rank": 3945,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3259,
    "Rank": 3947,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3260,
    "Rank": 3948,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3261,
    "Rank": 3949,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3262,
    "Rank": 3950,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3263,
    "Rank": 3951,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3264,
    "Rank": 3952,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3265,
    "Rank": 3953,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3266,
    "Rank": 3955,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3267,
    "Rank": 3956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3268,
    "Rank": 3957,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3269,
    "Rank": 3958,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3270,
    "Rank": 3960,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3271,
    "Rank": 3961,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3272,
    "Rank": 3963,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3273,
    "Rank": 3964,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3274,
    "Rank": 3965,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3275,
    "Rank": 3968,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3276,
    "Rank": 3971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3277,
    "Rank": 3977,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3278,
    "Rank": 3978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3279,
    "Rank": 3981,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3280,
    "Rank": 3983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3281,
    "Rank": 3987,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3282,
    "Rank": 3988,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3283,
    "Rank": 3989,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3284,
    "Rank": 3990,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3285,
    "Rank": 3992,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3286,
    "Rank": 3997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3287,
    "Rank": 3998,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3288,
    "Rank": 3999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3289,
    "Rank": 4000,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3290,
    "Rank": 4001,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3291,
    "Rank": 4002,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3292,
    "Rank": 4003,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3293,
    "Rank": 4004,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3294,
    "Rank": 4005,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3295,
    "Rank": 4006,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3296,
    "Rank": 4007,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3297,
    "Rank": 4009,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3298,
    "Rank": 4011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3299,
    "Rank": 4012,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3300,
    "Rank": 4013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3301,
    "Rank": 4014,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3302,
    "Rank": 4015,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3303,
    "Rank": 4017,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3304,
    "Rank": 4018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3305,
    "Rank": 4019,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3306,
    "Rank": 4020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3307,
    "Rank": 4021,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3308,
    "Rank": 4022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3309,
    "Rank": 4023,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3310,
    "Rank": 4024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3311,
    "Rank": 4025,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3312,
    "Rank": 4026,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3313,
    "Rank": 4028,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3314,
    "Rank": 4029,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3315,
    "Rank": 4032,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3316,
    "Rank": 4033,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3317,
    "Rank": 4036,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3318,
    "Rank": 4037,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3319,
    "Rank": 4038,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3320,
    "Rank": 4040,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3321,
    "Rank": 4041,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3322,
    "Rank": 4046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3323,
    "Rank": 4048,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3324,
    "Rank": 4049,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3325,
    "Rank": 4052,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3326,
    "Rank": 4054,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3327,
    "Rank": 4055,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3328,
    "Rank": 4058,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3329,
    "Rank": 4062,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3330,
    "Rank": 4063,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3331,
    "Rank": 4064,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3332,
    "Rank": 4065,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3333,
    "Rank": 4067,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3334,
    "Rank": 4070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3335,
    "Rank": 4071,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3336,
    "Rank": 4074,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3337,
    "Rank": 4075,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3338,
    "Rank": 4076,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3339,
    "Rank": 4081,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3340,
    "Rank": 4085,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3341,
    "Rank": 4087,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3342,
    "Rank": 4088,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3343,
    "Rank": 4089,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3344,
    "Rank": 4090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3345,
    "Rank": 4091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3346,
    "Rank": 4095,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3347,
    "Rank": 4097,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3348,
    "Rank": 4099,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3349,
    "Rank": 4100,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3350,
    "Rank": 4101,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3351,
    "Rank": 4102,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3352,
    "Rank": 4104,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3353,
    "Rank": 4105,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3354,
    "Rank": 4106,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3355,
    "Rank": 4107,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3356,
    "Rank": 4108,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3357,
    "Rank": 4110,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3358,
    "Rank": 4112,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3359,
    "Rank": 4116,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3360,
    "Rank": 4117,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3361,
    "Rank": 4118,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3362,
    "Rank": 4119,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3363,
    "Rank": 4121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3364,
    "Rank": 4122,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3365,
    "Rank": 4124,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3366,
    "Rank": 4125,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3367,
    "Rank": 4129,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3368,
    "Rank": 4130,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3369,
    "Rank": 4133,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3370,
    "Rank": 4135,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3371,
    "Rank": 4136,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3372,
    "Rank": 4138,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3373,
    "Rank": 4140,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3374,
    "Rank": 4141,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3375,
    "Rank": 4146,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3376,
    "Rank": 4148,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3377,
    "Rank": 4151,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3378,
    "Rank": 4152,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3379,
    "Rank": 4153,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3380,
    "Rank": 4154,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3381,
    "Rank": 4155,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3382,
    "Rank": 4158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3383,
    "Rank": 4161,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3384,
    "Rank": 4162,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3385,
    "Rank": 4165,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3386,
    "Rank": 4166,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3387,
    "Rank": 4167,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3388,
    "Rank": 4169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3389,
    "Rank": 4170,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3390,
    "Rank": 4172,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3391,
    "Rank": 4174,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3392,
    "Rank": 4175,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3393,
    "Rank": 4176,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3394,
    "Rank": 4177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3395,
    "Rank": 4178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3396,
    "Rank": 4179,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3397,
    "Rank": 4180,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3398,
    "Rank": 4181,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3399,
    "Rank": 4182,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3400,
    "Rank": 4183,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3401,
    "Rank": 4184,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3402,
    "Rank": 4186,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3403,
    "Rank": 4189,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3404,
    "Rank": 4191,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3405,
    "Rank": 4192,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3406,
    "Rank": 4193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3407,
    "Rank": 4195,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3408,
    "Rank": 4197,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3409,
    "Rank": 4202,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3410,
    "Rank": 4206,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3411,
    "Rank": 4208,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3412,
    "Rank": 4210,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3413,
    "Rank": 4211,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3414,
    "Rank": 4212,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3415,
    "Rank": 4215,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3416,
    "Rank": 4216,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3417,
    "Rank": 4217,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3418,
    "Rank": 4218,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3419,
    "Rank": 4219,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3420,
    "Rank": 4220,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3421,
    "Rank": 4224,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3422,
    "Rank": 4225,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3423,
    "Rank": 4226,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3424,
    "Rank": 4228,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3425,
    "Rank": 4232,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3426,
    "Rank": 4233,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3427,
    "Rank": 4234,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3428,
    "Rank": 4235,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3429,
    "Rank": 4237,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3430,
    "Rank": 4238,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3431,
    "Rank": 4239,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3432,
    "Rank": 4240,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3433,
    "Rank": 4242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3434,
    "Rank": 4243,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3435,
    "Rank": 4249,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3436,
    "Rank": 4251,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3437,
    "Rank": 4253,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3438,
    "Rank": 4254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3439,
    "Rank": 4255,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3440,
    "Rank": 4259,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3441,
    "Rank": 4262,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3442,
    "Rank": 4263,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3443,
    "Rank": 4265,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3444,
    "Rank": 4266,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3445,
    "Rank": 4267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3446,
    "Rank": 4268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3447,
    "Rank": 4269,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3448,
    "Rank": 4273,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3449,
    "Rank": 4274,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3450,
    "Rank": 4275,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3451,
    "Rank": 4277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3452,
    "Rank": 4278,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3453,
    "Rank": 4279,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3454,
    "Rank": 4281,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3455,
    "Rank": 4282,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3456,
    "Rank": 4283,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3457,
    "Rank": 4284,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3458,
    "Rank": 4285,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3459,
    "Rank": 4286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3460,
    "Rank": 4287,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3461,
    "Rank": 4288,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3462,
    "Rank": 4293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3463,
    "Rank": 4295,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3464,
    "Rank": 4296,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3465,
    "Rank": 4301,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3466,
    "Rank": 4302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3467,
    "Rank": 4303,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3468,
    "Rank": 4305,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3469,
    "Rank": 4307,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3470,
    "Rank": 4312,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3471,
    "Rank": 4314,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3472,
    "Rank": 4315,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3473,
    "Rank": 4319,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3474,
    "Rank": 4321,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3475,
    "Rank": 4322,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3476,
    "Rank": 4323,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3477,
    "Rank": 4324,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3478,
    "Rank": 4326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3479,
    "Rank": 4327,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3480,
    "Rank": 4328,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3481,
    "Rank": 4329,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3482,
    "Rank": 4331,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3483,
    "Rank": 4332,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3484,
    "Rank": 4334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3485,
    "Rank": 4335,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3486,
    "Rank": 4336,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3487,
    "Rank": 4337,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3488,
    "Rank": 4339,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3489,
    "Rank": 4341,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3490,
    "Rank": 4342,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3491,
    "Rank": 4346,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3492,
    "Rank": 4348,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3493,
    "Rank": 4350,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3494,
    "Rank": 4351,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3495,
    "Rank": 4352,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3496,
    "Rank": 4353,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3497,
    "Rank": 4354,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3498,
    "Rank": 4356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3499,
    "Rank": 4357,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3500,
    "Rank": 4358,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3501,
    "Rank": 4363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3502,
    "Rank": 4364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3503,
    "Rank": 4365,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3504,
    "Rank": 4366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3505,
    "Rank": 4367,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3506,
    "Rank": 4368,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3507,
    "Rank": 4369,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3508,
    "Rank": 4370,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3509,
    "Rank": 4373,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3510,
    "Rank": 4374,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3511,
    "Rank": 4375,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3512,
    "Rank": 4376,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3513,
    "Rank": 4377,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3514,
    "Rank": 4380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3515,
    "Rank": 4385,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3516,
    "Rank": 4386,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3517,
    "Rank": 4388,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3518,
    "Rank": 4390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3519,
    "Rank": 4391,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3520,
    "Rank": 4392,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3521,
    "Rank": 4393,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3522,
    "Rank": 4394,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3523,
    "Rank": 4397,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3524,
    "Rank": 4400,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3525,
    "Rank": 4404,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3526,
    "Rank": 4405,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3527,
    "Rank": 4408,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3528,
    "Rank": 4410,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3529,
    "Rank": 4411,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3530,
    "Rank": 4412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3531,
    "Rank": 4413,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3532,
    "Rank": 4416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3533,
    "Rank": 4420,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3534,
    "Rank": 4421,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3535,
    "Rank": 4422,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3536,
    "Rank": 4423,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3537,
    "Rank": 4424,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3538,
    "Rank": 4425,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3539,
    "Rank": 4426,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3540,
    "Rank": 4427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3541,
    "Rank": 4428,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3542,
    "Rank": 4433,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3543,
    "Rank": 4434,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3544,
    "Rank": 4435,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3545,
    "Rank": 4437,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3546,
    "Rank": 4438,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3547,
    "Rank": 4439,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3548,
    "Rank": 4440,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3549,
    "Rank": 4443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3550,
    "Rank": 4446,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3551,
    "Rank": 4447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3552,
    "Rank": 4449,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3553,
    "Rank": 4450,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3554,
    "Rank": 4452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3555,
    "Rank": 4453,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3556,
    "Rank": 4454,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3557,
    "Rank": 4456,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3558,
    "Rank": 4457,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3559,
    "Rank": 4459,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3560,
    "Rank": 4462,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3561,
    "Rank": 4463,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3562,
    "Rank": 4464,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3563,
    "Rank": 4467,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3564,
    "Rank": 4469,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3565,
    "Rank": 4470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3566,
    "Rank": 4472,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3567,
    "Rank": 4473,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3568,
    "Rank": 4475,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3569,
    "Rank": 4477,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3570,
    "Rank": 4480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3571,
    "Rank": 4484,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3572,
    "Rank": 4485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3573,
    "Rank": 4486,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3574,
    "Rank": 4487,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3575,
    "Rank": 4488,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3576,
    "Rank": 4489,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3577,
    "Rank": 4490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3578,
    "Rank": 4492,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3579,
    "Rank": 4494,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3580,
    "Rank": 4495,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3581,
    "Rank": 4496,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3582,
    "Rank": 4498,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3583,
    "Rank": 4499,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3584,
    "Rank": 4500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3585,
    "Rank": 4503,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3586,
    "Rank": 4504,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3587,
    "Rank": 4505,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3588,
    "Rank": 4506,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3589,
    "Rank": 4507,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3590,
    "Rank": 4509,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3591,
    "Rank": 4511,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3592,
    "Rank": 4512,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3593,
    "Rank": 4513,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3594,
    "Rank": 4514,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3595,
    "Rank": 4515,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3596,
    "Rank": 4516,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3597,
    "Rank": 4517,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3598,
    "Rank": 4519,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3599,
    "Rank": 4520,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3600,
    "Rank": 4523,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3601,
    "Rank": 4525,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3602,
    "Rank": 4526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3603,
    "Rank": 4527,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3604,
    "Rank": 4528,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3605,
    "Rank": 4531,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3606,
    "Rank": 4532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3607,
    "Rank": 4534,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3608,
    "Rank": 4536,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3609,
    "Rank": 4539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3610,
    "Rank": 4540,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3611,
    "Rank": 4543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3612,
    "Rank": 4544,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3613,
    "Rank": 4545,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3614,
    "Rank": 4546,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3615,
    "Rank": 4547,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3616,
    "Rank": 4551,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3617,
    "Rank": 4552,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3618,
    "Rank": 4553,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3619,
    "Rank": 4555,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3620,
    "Rank": 4559,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3621,
    "Rank": 4560,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3622,
    "Rank": 4561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3623,
    "Rank": 4563,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3624,
    "Rank": 4565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3625,
    "Rank": 4566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3626,
    "Rank": 4567,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3627,
    "Rank": 4568,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3628,
    "Rank": 4571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3629,
    "Rank": 4575,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3630,
    "Rank": 4576,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3631,
    "Rank": 4577,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3632,
    "Rank": 4580,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3633,
    "Rank": 4581,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3634,
    "Rank": 4583,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3635,
    "Rank": 4585,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3636,
    "Rank": 4586,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3637,
    "Rank": 4588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3638,
    "Rank": 4590,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3639,
    "Rank": 4591,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3640,
    "Rank": 4592,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3641,
    "Rank": 4595,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3642,
    "Rank": 4596,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3643,
    "Rank": 4597,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3644,
    "Rank": 4598,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3645,
    "Rank": 4599,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3646,
    "Rank": 4600,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3647,
    "Rank": 4601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3648,
    "Rank": 4602,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3649,
    "Rank": 4603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3650,
    "Rank": 4604,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3651,
    "Rank": 4605,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3652,
    "Rank": 4606,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3653,
    "Rank": 4611,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3654,
    "Rank": 4612,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3655,
    "Rank": 4614,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3656,
    "Rank": 4617,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3657,
    "Rank": 4618,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3658,
    "Rank": 4619,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3659,
    "Rank": 4620,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3660,
    "Rank": 4621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3661,
    "Rank": 4622,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3662,
    "Rank": 4623,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3663,
    "Rank": 4624,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3664,
    "Rank": 4625,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3665,
    "Rank": 4626,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3666,
    "Rank": 4627,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3667,
    "Rank": 4629,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3668,
    "Rank": 4634,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3669,
    "Rank": 4636,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3670,
    "Rank": 4638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3671,
    "Rank": 4639,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3672,
    "Rank": 4640,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3673,
    "Rank": 4643,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3674,
    "Rank": 4648,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3675,
    "Rank": 4649,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3676,
    "Rank": 4650,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3677,
    "Rank": 4652,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3678,
    "Rank": 4653,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3679,
    "Rank": 4654,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3680,
    "Rank": 4655,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3681,
    "Rank": 4659,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3682,
    "Rank": 4660,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3683,
    "Rank": 4661,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3684,
    "Rank": 4662,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3685,
    "Rank": 4663,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3686,
    "Rank": 4664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3687,
    "Rank": 4665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3688,
    "Rank": 4666,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3689,
    "Rank": 4668,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3690,
    "Rank": 4669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3691,
    "Rank": 4670,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3692,
    "Rank": 4673,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3693,
    "Rank": 4674,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3694,
    "Rank": 4675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3695,
    "Rank": 4677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3696,
    "Rank": 4679,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3697,
    "Rank": 4682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3698,
    "Rank": 4684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3699,
    "Rank": 4687,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3700,
    "Rank": 4688,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3701,
    "Rank": 4692,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3702,
    "Rank": 4693,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3703,
    "Rank": 4694,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3704,
    "Rank": 4695,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3705,
    "Rank": 4696,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3706,
    "Rank": 4699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3707,
    "Rank": 4703,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3708,
    "Rank": 4710,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3709,
    "Rank": 4711,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3710,
    "Rank": 4715,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3711,
    "Rank": 4716,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3712,
    "Rank": 4717,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3713,
    "Rank": 4718,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3714,
    "Rank": 4721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3715,
    "Rank": 4722,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3716,
    "Rank": 4723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3717,
    "Rank": 4724,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3718,
    "Rank": 4725,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3719,
    "Rank": 4728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3720,
    "Rank": 4731,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3721,
    "Rank": 4733,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3722,
    "Rank": 4735,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3723,
    "Rank": 4736,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3724,
    "Rank": 4739,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3725,
    "Rank": 4746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3726,
    "Rank": 4747,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3727,
    "Rank": 4749,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3728,
    "Rank": 4756,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3729,
    "Rank": 4757,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3730,
    "Rank": 4758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3731,
    "Rank": 4760,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3732,
    "Rank": 4762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3733,
    "Rank": 4763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3734,
    "Rank": 4764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3735,
    "Rank": 4765,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3736,
    "Rank": 4766,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3737,
    "Rank": 4767,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3738,
    "Rank": 4768,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3739,
    "Rank": 4770,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3740,
    "Rank": 4773,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3741,
    "Rank": 4775,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3742,
    "Rank": 4776,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3743,
    "Rank": 4779,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3744,
    "Rank": 4780,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3745,
    "Rank": 4781,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3746,
    "Rank": 4784,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3747,
    "Rank": 4795,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3748,
    "Rank": 4798,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3749,
    "Rank": 4799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3750,
    "Rank": 4800,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3751,
    "Rank": 4801,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3752,
    "Rank": 4802,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3753,
    "Rank": 4804,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3754,
    "Rank": 4805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3755,
    "Rank": 4807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3756,
    "Rank": 4808,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3757,
    "Rank": 4809,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3758,
    "Rank": 4812,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3759,
    "Rank": 4814,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3760,
    "Rank": 4816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3761,
    "Rank": 4818,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3762,
    "Rank": 4820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3763,
    "Rank": 4821,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3764,
    "Rank": 4822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3765,
    "Rank": 4824,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3766,
    "Rank": 4826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3767,
    "Rank": 4827,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3768,
    "Rank": 4828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3769,
    "Rank": 4832,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3770,
    "Rank": 4834,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3771,
    "Rank": 4838,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3772,
    "Rank": 4841,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3773,
    "Rank": 4844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3774,
    "Rank": 4845,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3775,
    "Rank": 4846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3776,
    "Rank": 4848,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3777,
    "Rank": 4849,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3778,
    "Rank": 4850,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3779,
    "Rank": 4851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3780,
    "Rank": 4852,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3781,
    "Rank": 4855,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3782,
    "Rank": 4859,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3783,
    "Rank": 4861,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3784,
    "Rank": 4863,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3785,
    "Rank": 4864,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3786,
    "Rank": 4865,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3787,
    "Rank": 4870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3788,
    "Rank": 4875,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3789,
    "Rank": 4878,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3790,
    "Rank": 4879,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3791,
    "Rank": 4881,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3792,
    "Rank": 4884,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3793,
    "Rank": 4885,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3794,
    "Rank": 4888,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3795,
    "Rank": 4889,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3796,
    "Rank": 4890,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3797,
    "Rank": 4891,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3798,
    "Rank": 4892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3799,
    "Rank": 4893,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3800,
    "Rank": 4894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3801,
    "Rank": 4898,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3802,
    "Rank": 4899,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3803,
    "Rank": 4903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3804,
    "Rank": 4904,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3805,
    "Rank": 4907,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3806,
    "Rank": 4908,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3807,
    "Rank": 4910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3808,
    "Rank": 4911,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3809,
    "Rank": 4912,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3810,
    "Rank": 4915,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3811,
    "Rank": 4916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3812,
    "Rank": 4917,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3813,
    "Rank": 4919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3814,
    "Rank": 4920,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3815,
    "Rank": 4921,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3816,
    "Rank": 4922,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3817,
    "Rank": 4923,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3818,
    "Rank": 4924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3819,
    "Rank": 4928,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3820,
    "Rank": 4930,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3821,
    "Rank": 4935,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3822,
    "Rank": 4937,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3823,
    "Rank": 4938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3824,
    "Rank": 4939,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3825,
    "Rank": 4941,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3826,
    "Rank": 4943,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3827,
    "Rank": 4948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3828,
    "Rank": 4950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3829,
    "Rank": 4952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3830,
    "Rank": 4957,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3831,
    "Rank": 4958,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3832,
    "Rank": 4959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3833,
    "Rank": 4961,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3834,
    "Rank": 4964,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3835,
    "Rank": 4967,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3836,
    "Rank": 4968,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3837,
    "Rank": 4969,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3838,
    "Rank": 4970,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3839,
    "Rank": 4973,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3840,
    "Rank": 4976,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3841,
    "Rank": 4978,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3842,
    "Rank": 4979,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3843,
    "Rank": 4980,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3844,
    "Rank": 4981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3845,
    "Rank": 4982,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3846,
    "Rank": 4984,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3847,
    "Rank": 4985,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3848,
    "Rank": 4986,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3849,
    "Rank": 4988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3850,
    "Rank": 4990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3851,
    "Rank": 4992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3852,
    "Rank": 4994,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3853,
    "Rank": 4995,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3854,
    "Rank": 4996,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3855,
    "Rank": 4998,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3856,
    "Rank": 5002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3857,
    "Rank": 5005,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3858,
    "Rank": 5009,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3859,
    "Rank": 5010,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3860,
    "Rank": 5012,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3861,
    "Rank": 5013,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3862,
    "Rank": 5015,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3863,
    "Rank": 5018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3864,
    "Rank": 5020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3865,
    "Rank": 5021,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3866,
    "Rank": 5023,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3867,
    "Rank": 5024,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3868,
    "Rank": 5026,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3869,
    "Rank": 5027,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3870,
    "Rank": 5029,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3871,
    "Rank": 5031,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3872,
    "Rank": 5032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3873,
    "Rank": 5033,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3874,
    "Rank": 5035,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3875,
    "Rank": 5037,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3876,
    "Rank": 5040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3877,
    "Rank": 5044,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3878,
    "Rank": 5046,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3879,
    "Rank": 5047,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3880,
    "Rank": 5050,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3881,
    "Rank": 5052,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3882,
    "Rank": 5053,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3883,
    "Rank": 5054,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3884,
    "Rank": 5055,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3885,
    "Rank": 5060,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3886,
    "Rank": 5062,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3887,
    "Rank": 5063,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3888,
    "Rank": 5065,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3889,
    "Rank": 5067,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3890,
    "Rank": 5069,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3891,
    "Rank": 5073,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3892,
    "Rank": 5078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3893,
    "Rank": 5079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3894,
    "Rank": 5080,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3895,
    "Rank": 5083,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3896,
    "Rank": 5084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3897,
    "Rank": 5087,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3898,
    "Rank": 5091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3899,
    "Rank": 5096,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3900,
    "Rank": 5099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3901,
    "Rank": 5100,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3902,
    "Rank": 5102,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3903,
    "Rank": 5104,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3904,
    "Rank": 5106,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3905,
    "Rank": 5110,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3906,
    "Rank": 5112,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3907,
    "Rank": 5113,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3908,
    "Rank": 5114,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3909,
    "Rank": 5118,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3910,
    "Rank": 5119,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3911,
    "Rank": 5121,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3912,
    "Rank": 5122,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3913,
    "Rank": 5123,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3914,
    "Rank": 5124,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3915,
    "Rank": 5125,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3916,
    "Rank": 5127,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3917,
    "Rank": 5130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3918,
    "Rank": 5131,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3919,
    "Rank": 5136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3920,
    "Rank": 5137,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3921,
    "Rank": 5138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3922,
    "Rank": 5139,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3923,
    "Rank": 5140,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3924,
    "Rank": 5141,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3925,
    "Rank": 5142,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3926,
    "Rank": 5144,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3927,
    "Rank": 5148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3928,
    "Rank": 5150,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3929,
    "Rank": 5152,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3930,
    "Rank": 5154,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3931,
    "Rank": 5158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3932,
    "Rank": 5160,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3933,
    "Rank": 5163,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3934,
    "Rank": 5164,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3935,
    "Rank": 5165,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3936,
    "Rank": 5166,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3937,
    "Rank": 5167,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3938,
    "Rank": 5171,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3939,
    "Rank": 5174,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3940,
    "Rank": 5177,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3941,
    "Rank": 5179,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3942,
    "Rank": 5180,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3943,
    "Rank": 5182,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3944,
    "Rank": 5183,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3945,
    "Rank": 5184,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3946,
    "Rank": 5187,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3947,
    "Rank": 5190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3948,
    "Rank": 5195,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3949,
    "Rank": 5197,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3950,
    "Rank": 5198,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3951,
    "Rank": 5201,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3952,
    "Rank": 5203,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3953,
    "Rank": 5204,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3954,
    "Rank": 5205,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3955,
    "Rank": 5206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3956,
    "Rank": 5207,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3957,
    "Rank": 5210,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3958,
    "Rank": 5211,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3959,
    "Rank": 5212,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3960,
    "Rank": 5213,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3961,
    "Rank": 5215,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3962,
    "Rank": 5217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3963,
    "Rank": 5226,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3964,
    "Rank": 5229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3965,
    "Rank": 5230,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3966,
    "Rank": 5231,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3967,
    "Rank": 5232,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3968,
    "Rank": 5239,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3969,
    "Rank": 5243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3970,
    "Rank": 5246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3971,
    "Rank": 5247,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3972,
    "Rank": 5248,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3973,
    "Rank": 5250,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3974,
    "Rank": 5254,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3975,
    "Rank": 5259,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3976,
    "Rank": 5261,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3977,
    "Rank": 5263,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3978,
    "Rank": 5267,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3979,
    "Rank": 5268,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3980,
    "Rank": 5269,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3981,
    "Rank": 5270,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3982,
    "Rank": 5271,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3983,
    "Rank": 5273,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3984,
    "Rank": 5274,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3985,
    "Rank": 5279,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3986,
    "Rank": 5283,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3987,
    "Rank": 5286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3988,
    "Rank": 5287,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3989,
    "Rank": 5291,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3990,
    "Rank": 5292,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3991,
    "Rank": 5293,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3992,
    "Rank": 5294,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3993,
    "Rank": 5296,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3994,
    "Rank": 5301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3995,
    "Rank": 5303,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3996,
    "Rank": 5306,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3997,
    "Rank": 5307,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3998,
    "Rank": 5308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 3999,
    "Rank": 5312,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4000,
    "Rank": 5313,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4001,
    "Rank": 5318,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4002,
    "Rank": 5319,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4003,
    "Rank": 5320,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4004,
    "Rank": 5322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4005,
    "Rank": 5324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4006,
    "Rank": 5326,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4007,
    "Rank": 5327,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4008,
    "Rank": 5331,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4009,
    "Rank": 5333,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4010,
    "Rank": 5341,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4011,
    "Rank": 5343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4012,
    "Rank": 5345,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4013,
    "Rank": 5348,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4014,
    "Rank": 5352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4015,
    "Rank": 5353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4016,
    "Rank": 5354,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4017,
    "Rank": 5356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4018,
    "Rank": 5357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4019,
    "Rank": 5359,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4020,
    "Rank": 5360,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4021,
    "Rank": 5361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4022,
    "Rank": 5362,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4023,
    "Rank": 5363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4024,
    "Rank": 5366,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4025,
    "Rank": 5369,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4026,
    "Rank": 5372,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4027,
    "Rank": 5374,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4028,
    "Rank": 5379,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4029,
    "Rank": 5380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4030,
    "Rank": 5381,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4031,
    "Rank": 5382,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4032,
    "Rank": 5383,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4033,
    "Rank": 5384,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4034,
    "Rank": 5386,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4035,
    "Rank": 5387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4036,
    "Rank": 5393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4037,
    "Rank": 5395,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4038,
    "Rank": 5396,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4039,
    "Rank": 5397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4040,
    "Rank": 5398,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4041,
    "Rank": 5399,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4042,
    "Rank": 5401,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4043,
    "Rank": 5402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4044,
    "Rank": 5403,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4045,
    "Rank": 5404,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4046,
    "Rank": 5409,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4047,
    "Rank": 5410,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4048,
    "Rank": 5412,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4049,
    "Rank": 5414,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4050,
    "Rank": 5416,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4051,
    "Rank": 5418,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4052,
    "Rank": 5419,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4053,
    "Rank": 5423,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4054,
    "Rank": 5424,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4055,
    "Rank": 5427,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4056,
    "Rank": 5428,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4057,
    "Rank": 5429,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4058,
    "Rank": 5432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4059,
    "Rank": 5433,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4060,
    "Rank": 5434,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4061,
    "Rank": 5436,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4062,
    "Rank": 5437,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4063,
    "Rank": 5439,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4064,
    "Rank": 5444,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4065,
    "Rank": 5445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4066,
    "Rank": 5446,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4067,
    "Rank": 5447,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4068,
    "Rank": 5449,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4069,
    "Rank": 5451,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4070,
    "Rank": 5452,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4071,
    "Rank": 5453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4072,
    "Rank": 5456,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4073,
    "Rank": 5457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4074,
    "Rank": 5458,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4075,
    "Rank": 5459,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4076,
    "Rank": 5462,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4077,
    "Rank": 5463,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4078,
    "Rank": 5464,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4079,
    "Rank": 5466,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4080,
    "Rank": 5468,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4081,
    "Rank": 5469,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4082,
    "Rank": 5470,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4083,
    "Rank": 5472,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4084,
    "Rank": 5473,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4085,
    "Rank": 5478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4086,
    "Rank": 5480,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4087,
    "Rank": 5481,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4088,
    "Rank": 5482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4089,
    "Rank": 5484,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4090,
    "Rank": 5490,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4091,
    "Rank": 5491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4092,
    "Rank": 5492,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4093,
    "Rank": 5494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4094,
    "Rank": 5497,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4095,
    "Rank": 5498,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4096,
    "Rank": 5506,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4097,
    "Rank": 5507,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4098,
    "Rank": 5509,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4099,
    "Rank": 5513,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4100,
    "Rank": 5517,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4101,
    "Rank": 5520,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4102,
    "Rank": 5523,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4103,
    "Rank": 5524,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4104,
    "Rank": 5528,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4105,
    "Rank": 5529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4106,
    "Rank": 5532,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4107,
    "Rank": 5533,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4108,
    "Rank": 5534,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4109,
    "Rank": 5535,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4110,
    "Rank": 5540,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4111,
    "Rank": 5541,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4112,
    "Rank": 5542,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4113,
    "Rank": 5547,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4114,
    "Rank": 5549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4115,
    "Rank": 5552,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4116,
    "Rank": 5553,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4117,
    "Rank": 5565,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4118,
    "Rank": 5569,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4119,
    "Rank": 5573,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4120,
    "Rank": 5574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4121,
    "Rank": 5577,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4122,
    "Rank": 5580,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4123,
    "Rank": 5581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4124,
    "Rank": 5582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4125,
    "Rank": 5583,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4126,
    "Rank": 5585,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4127,
    "Rank": 5588,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4128,
    "Rank": 5589,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4129,
    "Rank": 5593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4130,
    "Rank": 5601,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4131,
    "Rank": 5605,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4132,
    "Rank": 5607,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4133,
    "Rank": 5608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4134,
    "Rank": 5609,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4135,
    "Rank": 5612,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4136,
    "Rank": 5613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4137,
    "Rank": 5614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4138,
    "Rank": 5617,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4139,
    "Rank": 5618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4140,
    "Rank": 5620,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4141,
    "Rank": 5622,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4142,
    "Rank": 5628,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4143,
    "Rank": 5629,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4144,
    "Rank": 5631,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4145,
    "Rank": 5633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4146,
    "Rank": 5634,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4147,
    "Rank": 5636,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4148,
    "Rank": 5639,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4149,
    "Rank": 5640,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4150,
    "Rank": 5642,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4151,
    "Rank": 5651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4152,
    "Rank": 5652,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4153,
    "Rank": 5653,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4154,
    "Rank": 5654,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4155,
    "Rank": 5657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4156,
    "Rank": 5658,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4157,
    "Rank": 5660,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4158,
    "Rank": 5661,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4159,
    "Rank": 5662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4160,
    "Rank": 5663,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4161,
    "Rank": 5664,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4162,
    "Rank": 5665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4163,
    "Rank": 5666,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4164,
    "Rank": 5670,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4165,
    "Rank": 5671,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4166,
    "Rank": 5673,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4167,
    "Rank": 5674,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4168,
    "Rank": 5675,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4169,
    "Rank": 5682,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4170,
    "Rank": 5692,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4171,
    "Rank": 5693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4172,
    "Rank": 5694,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4173,
    "Rank": 5695,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4174,
    "Rank": 5697,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4175,
    "Rank": 5698,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4176,
    "Rank": 5699,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4177,
    "Rank": 5700,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4178,
    "Rank": 5702,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4179,
    "Rank": 5706,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4180,
    "Rank": 5710,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4181,
    "Rank": 5713,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4182,
    "Rank": 5714,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4183,
    "Rank": 5720,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4184,
    "Rank": 5722,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4185,
    "Rank": 5723,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4186,
    "Rank": 5724,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4187,
    "Rank": 5725,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4188,
    "Rank": 5728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4189,
    "Rank": 5731,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4190,
    "Rank": 5735,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4191,
    "Rank": 5741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4192,
    "Rank": 5742,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4193,
    "Rank": 5744,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4194,
    "Rank": 5745,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4195,
    "Rank": 5747,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4196,
    "Rank": 5748,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4197,
    "Rank": 5750,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4198,
    "Rank": 5753,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4199,
    "Rank": 5754,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4200,
    "Rank": 5757,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4201,
    "Rank": 5759,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4202,
    "Rank": 5760,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4203,
    "Rank": 5761,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4204,
    "Rank": 5762,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4205,
    "Rank": 5764,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4206,
    "Rank": 5768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4207,
    "Rank": 5770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4208,
    "Rank": 5777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4209,
    "Rank": 5785,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4210,
    "Rank": 5786,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4211,
    "Rank": 5789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4212,
    "Rank": 5791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4213,
    "Rank": 5793,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4214,
    "Rank": 5795,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4215,
    "Rank": 5796,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4216,
    "Rank": 5797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4217,
    "Rank": 5801,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4218,
    "Rank": 5806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4219,
    "Rank": 5808,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4220,
    "Rank": 5809,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4221,
    "Rank": 5810,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4222,
    "Rank": 5811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4223,
    "Rank": 5813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4224,
    "Rank": 5820,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4225,
    "Rank": 5821,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4226,
    "Rank": 5824,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4227,
    "Rank": 5826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4228,
    "Rank": 5828,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4229,
    "Rank": 5830,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4230,
    "Rank": 5832,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4231,
    "Rank": 5835,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4232,
    "Rank": 5836,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4233,
    "Rank": 5838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4234,
    "Rank": 5839,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4235,
    "Rank": 5843,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4236,
    "Rank": 5844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4237,
    "Rank": 5848,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4238,
    "Rank": 5850,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4239,
    "Rank": 5851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4240,
    "Rank": 5852,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4241,
    "Rank": 5855,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4242,
    "Rank": 5857,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4243,
    "Rank": 5861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4244,
    "Rank": 5864,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4245,
    "Rank": 5865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4246,
    "Rank": 5866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4247,
    "Rank": 5873,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4248,
    "Rank": 5874,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4249,
    "Rank": 5875,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4250,
    "Rank": 5876,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4251,
    "Rank": 5878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4252,
    "Rank": 5882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4253,
    "Rank": 5886,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4254,
    "Rank": 5888,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4255,
    "Rank": 5890,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4256,
    "Rank": 5893,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4257,
    "Rank": 5895,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4258,
    "Rank": 5904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4259,
    "Rank": 5907,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4260,
    "Rank": 5909,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4261,
    "Rank": 5914,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4262,
    "Rank": 5915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4263,
    "Rank": 5919,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4264,
    "Rank": 5921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4265,
    "Rank": 5927,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4266,
    "Rank": 5928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4267,
    "Rank": 5935,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4268,
    "Rank": 5938,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4269,
    "Rank": 5940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4270,
    "Rank": 5942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4271,
    "Rank": 5943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4272,
    "Rank": 5944,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4273,
    "Rank": 5949,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4274,
    "Rank": 5952,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4275,
    "Rank": 5953,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4276,
    "Rank": 5954,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4277,
    "Rank": 5961,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4278,
    "Rank": 5968,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4279,
    "Rank": 5970,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4280,
    "Rank": 5971,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4281,
    "Rank": 5972,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4282,
    "Rank": 5975,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4283,
    "Rank": 5984,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4284,
    "Rank": 5989,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4285,
    "Rank": 5991,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4286,
    "Rank": 5992,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4287,
    "Rank": 5994,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4288,
    "Rank": 5995,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4289,
    "Rank": 5996,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4290,
    "Rank": 5997,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4291,
    "Rank": 5999,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4292,
    "Rank": 6001,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4293,
    "Rank": 6002,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4294,
    "Rank": 6006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4295,
    "Rank": 6009,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4296,
    "Rank": 6011,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4297,
    "Rank": 6013,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4298,
    "Rank": 6014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4299,
    "Rank": 6015,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4300,
    "Rank": 6017,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4301,
    "Rank": 6020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4302,
    "Rank": 6026,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4303,
    "Rank": 6028,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4304,
    "Rank": 6030,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4305,
    "Rank": 6032,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4306,
    "Rank": 6034,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4307,
    "Rank": 6035,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4308,
    "Rank": 6038,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4309,
    "Rank": 6040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4310,
    "Rank": 6043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4311,
    "Rank": 6044,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4312,
    "Rank": 6046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4313,
    "Rank": 6049,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4314,
    "Rank": 6050,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4315,
    "Rank": 6051,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4316,
    "Rank": 6053,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4317,
    "Rank": 6055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4318,
    "Rank": 6056,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4319,
    "Rank": 6057,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4320,
    "Rank": 6058,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4321,
    "Rank": 6060,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4322,
    "Rank": 6064,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4323,
    "Rank": 6065,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4324,
    "Rank": 6066,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4325,
    "Rank": 6067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4326,
    "Rank": 6068,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4327,
    "Rank": 6072,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4328,
    "Rank": 6075,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4329,
    "Rank": 6082,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4330,
    "Rank": 6084,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4331,
    "Rank": 6086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4332,
    "Rank": 6089,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4333,
    "Rank": 6091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4334,
    "Rank": 6095,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4335,
    "Rank": 6096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4336,
    "Rank": 6098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4337,
    "Rank": 6101,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4338,
    "Rank": 6102,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4339,
    "Rank": 6103,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4340,
    "Rank": 6106,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4341,
    "Rank": 6107,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4342,
    "Rank": 6108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4343,
    "Rank": 6109,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4344,
    "Rank": 6117,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4345,
    "Rank": 6120,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4346,
    "Rank": 6123,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4347,
    "Rank": 6125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4348,
    "Rank": 6126,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4349,
    "Rank": 6127,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4350,
    "Rank": 6128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4351,
    "Rank": 6130,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4352,
    "Rank": 6132,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4353,
    "Rank": 6134,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4354,
    "Rank": 6137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4355,
    "Rank": 6139,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4356,
    "Rank": 6141,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4357,
    "Rank": 6143,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4358,
    "Rank": 6144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4359,
    "Rank": 6146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4360,
    "Rank": 6150,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4361,
    "Rank": 6151,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4362,
    "Rank": 6152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4363,
    "Rank": 6159,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4364,
    "Rank": 6163,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4365,
    "Rank": 6166,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4366,
    "Rank": 6167,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4367,
    "Rank": 6169,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4368,
    "Rank": 6170,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4369,
    "Rank": 6172,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4370,
    "Rank": 6173,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4371,
    "Rank": 6174,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4372,
    "Rank": 6175,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4373,
    "Rank": 6176,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4374,
    "Rank": 6180,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4375,
    "Rank": 6181,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4376,
    "Rank": 6182,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4377,
    "Rank": 6183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4378,
    "Rank": 6184,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4379,
    "Rank": 6186,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4380,
    "Rank": 6188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4381,
    "Rank": 6194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4382,
    "Rank": 6195,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4383,
    "Rank": 6197,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4384,
    "Rank": 6199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4385,
    "Rank": 6200,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4386,
    "Rank": 6203,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4387,
    "Rank": 6204,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4388,
    "Rank": 6207,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4389,
    "Rank": 6208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4390,
    "Rank": 6210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4391,
    "Rank": 6212,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4392,
    "Rank": 6223,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4393,
    "Rank": 6224,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4394,
    "Rank": 6228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4395,
    "Rank": 6233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4396,
    "Rank": 6235,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4397,
    "Rank": 6236,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4398,
    "Rank": 6237,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4399,
    "Rank": 6238,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4400,
    "Rank": 6239,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4401,
    "Rank": 6242,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4402,
    "Rank": 6243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4403,
    "Rank": 6244,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4404,
    "Rank": 6245,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4405,
    "Rank": 6246,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4406,
    "Rank": 6253,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4407,
    "Rank": 6260,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4408,
    "Rank": 6262,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4409,
    "Rank": 6264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4410,
    "Rank": 6266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4411,
    "Rank": 6270,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4412,
    "Rank": 6276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4413,
    "Rank": 6280,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4414,
    "Rank": 6281,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4415,
    "Rank": 6285,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4416,
    "Rank": 6286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4417,
    "Rank": 6288,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4418,
    "Rank": 6289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4419,
    "Rank": 6291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4420,
    "Rank": 6292,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4421,
    "Rank": 6295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4422,
    "Rank": 6297,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4423,
    "Rank": 6300,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4424,
    "Rank": 6301,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4425,
    "Rank": 6302,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4426,
    "Rank": 6303,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4427,
    "Rank": 6304,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4428,
    "Rank": 6305,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4429,
    "Rank": 6307,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4430,
    "Rank": 6308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4431,
    "Rank": 6309,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4432,
    "Rank": 6310,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4433,
    "Rank": 6315,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4434,
    "Rank": 6317,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4435,
    "Rank": 6319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4436,
    "Rank": 6321,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4437,
    "Rank": 6322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4438,
    "Rank": 6323,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4439,
    "Rank": 6326,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4440,
    "Rank": 6331,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4441,
    "Rank": 6333,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4442,
    "Rank": 6335,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4443,
    "Rank": 6340,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4444,
    "Rank": 6343,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4445,
    "Rank": 6344,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4446,
    "Rank": 6348,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4447,
    "Rank": 6352,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4448,
    "Rank": 6353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4449,
    "Rank": 6354,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4450,
    "Rank": 6355,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4451,
    "Rank": 6360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4452,
    "Rank": 6361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4453,
    "Rank": 6363,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4454,
    "Rank": 6366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4455,
    "Rank": 6368,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4456,
    "Rank": 6372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4457,
    "Rank": 6373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4458,
    "Rank": 6375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4459,
    "Rank": 6378,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4460,
    "Rank": 6379,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4461,
    "Rank": 6380,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4462,
    "Rank": 6381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4463,
    "Rank": 6382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4464,
    "Rank": 6383,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4465,
    "Rank": 6388,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4466,
    "Rank": 6390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4467,
    "Rank": 6391,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4468,
    "Rank": 6396,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4469,
    "Rank": 6402,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4470,
    "Rank": 6404,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4471,
    "Rank": 6411,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4472,
    "Rank": 6415,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4473,
    "Rank": 6419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4474,
    "Rank": 6420,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4475,
    "Rank": 6423,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4476,
    "Rank": 6426,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4477,
    "Rank": 6428,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4478,
    "Rank": 6429,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4479,
    "Rank": 6432,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4480,
    "Rank": 6433,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4481,
    "Rank": 6434,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4482,
    "Rank": 6437,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4483,
    "Rank": 6438,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4484,
    "Rank": 6444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4485,
    "Rank": 6445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4486,
    "Rank": 6448,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4487,
    "Rank": 6449,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4488,
    "Rank": 6450,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4489,
    "Rank": 6452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4490,
    "Rank": 6459,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4491,
    "Rank": 6462,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4492,
    "Rank": 6468,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4493,
    "Rank": 6469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4494,
    "Rank": 6477,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4495,
    "Rank": 6479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4496,
    "Rank": 6481,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4497,
    "Rank": 6482,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4498,
    "Rank": 6483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4499,
    "Rank": 6485,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4500,
    "Rank": 6487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4501,
    "Rank": 6493,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4502,
    "Rank": 6496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4503,
    "Rank": 6498,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4504,
    "Rank": 6499,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4505,
    "Rank": 6500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4506,
    "Rank": 6503,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4507,
    "Rank": 6504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4508,
    "Rank": 6506,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4509,
    "Rank": 6511,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4510,
    "Rank": 6513,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4511,
    "Rank": 6516,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4512,
    "Rank": 6517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4513,
    "Rank": 6518,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4514,
    "Rank": 6519,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4515,
    "Rank": 6520,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4516,
    "Rank": 6521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4517,
    "Rank": 6524,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4518,
    "Rank": 6525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4519,
    "Rank": 6532,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4520,
    "Rank": 6534,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4521,
    "Rank": 6541,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4522,
    "Rank": 6543,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4523,
    "Rank": 6544,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4524,
    "Rank": 6546,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4525,
    "Rank": 6547,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4526,
    "Rank": 6548,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4527,
    "Rank": 6550,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4528,
    "Rank": 6552,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4529,
    "Rank": 6557,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4530,
    "Rank": 6559,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4531,
    "Rank": 6560,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4532,
    "Rank": 6561,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4533,
    "Rank": 6563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4534,
    "Rank": 6564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4535,
    "Rank": 6565,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4536,
    "Rank": 6566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4537,
    "Rank": 6568,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4538,
    "Rank": 6569,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4539,
    "Rank": 6570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4540,
    "Rank": 6573,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4541,
    "Rank": 6574,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4542,
    "Rank": 6575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4543,
    "Rank": 6577,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4544,
    "Rank": 6580,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4545,
    "Rank": 6583,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4546,
    "Rank": 6585,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4547,
    "Rank": 6587,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4548,
    "Rank": 6588,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4549,
    "Rank": 6591,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4550,
    "Rank": 6592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4551,
    "Rank": 6597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4552,
    "Rank": 6599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4553,
    "Rank": 6602,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4554,
    "Rank": 6606,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4555,
    "Rank": 6607,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4556,
    "Rank": 6616,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4557,
    "Rank": 6617,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4558,
    "Rank": 6618,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4559,
    "Rank": 6620,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4560,
    "Rank": 6622,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4561,
    "Rank": 6623,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4562,
    "Rank": 6628,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4563,
    "Rank": 6631,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4564,
    "Rank": 6634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4565,
    "Rank": 6640,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4566,
    "Rank": 6645,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4567,
    "Rank": 6650,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4568,
    "Rank": 6651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4569,
    "Rank": 6652,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4570,
    "Rank": 6653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4571,
    "Rank": 6656,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4572,
    "Rank": 6669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4573,
    "Rank": 6670,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4574,
    "Rank": 6675,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4575,
    "Rank": 6676,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4576,
    "Rank": 6680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4577,
    "Rank": 6682,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4578,
    "Rank": 6686,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4579,
    "Rank": 6687,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4580,
    "Rank": 6688,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4581,
    "Rank": 6689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4582,
    "Rank": 6691,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4583,
    "Rank": 6696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4584,
    "Rank": 6698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4585,
    "Rank": 6699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4586,
    "Rank": 6703,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4587,
    "Rank": 6704,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4588,
    "Rank": 6707,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4589,
    "Rank": 6713,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4590,
    "Rank": 6717,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4591,
    "Rank": 6718,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4592,
    "Rank": 6719,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4593,
    "Rank": 6721,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4594,
    "Rank": 6724,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4595,
    "Rank": 6725,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4596,
    "Rank": 6726,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4597,
    "Rank": 6727,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4598,
    "Rank": 6728,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4599,
    "Rank": 6731,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4600,
    "Rank": 6732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4601,
    "Rank": 6733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4602,
    "Rank": 6736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4603,
    "Rank": 6737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4604,
    "Rank": 6739,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4605,
    "Rank": 6744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4606,
    "Rank": 6749,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4607,
    "Rank": 6755,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4608,
    "Rank": 6756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4609,
    "Rank": 6763,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4610,
    "Rank": 6765,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4611,
    "Rank": 6772,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4612,
    "Rank": 6773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4613,
    "Rank": 6774,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4614,
    "Rank": 6778,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4615,
    "Rank": 6781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4616,
    "Rank": 6783,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4617,
    "Rank": 6784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4618,
    "Rank": 6786,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4619,
    "Rank": 6788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4620,
    "Rank": 6791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4621,
    "Rank": 6794,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 45 )"
  },
  {
    "SNo": 4622,
    "Rank": 6796,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4623,
    "Rank": 6802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4624,
    "Rank": 6803,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4625,
    "Rank": 6805,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4626,
    "Rank": 6806,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4627,
    "Rank": 6811,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4628,
    "Rank": 6812,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4629,
    "Rank": 6814,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4630,
    "Rank": 6815,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4631,
    "Rank": 6822,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4632,
    "Rank": 6826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4633,
    "Rank": 6827,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4634,
    "Rank": 6830,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4635,
    "Rank": 6832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4636,
    "Rank": 6836,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4637,
    "Rank": 6842,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4638,
    "Rank": 6844,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4639,
    "Rank": 6849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4640,
    "Rank": 6850,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4641,
    "Rank": 6853,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4642,
    "Rank": 6855,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4643,
    "Rank": 6856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4644,
    "Rank": 6859,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4645,
    "Rank": 6861,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4646,
    "Rank": 6864,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4647,
    "Rank": 6866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4648,
    "Rank": 6871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4649,
    "Rank": 6872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4650,
    "Rank": 6874,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4651,
    "Rank": 6878,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4652,
    "Rank": 6879,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4653,
    "Rank": 6884,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4654,
    "Rank": 6889,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4655,
    "Rank": 6891,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4656,
    "Rank": 6894,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4657,
    "Rank": 6897,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4658,
    "Rank": 6900,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4659,
    "Rank": 6905,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4660,
    "Rank": 6906,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4661,
    "Rank": 6907,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4662,
    "Rank": 6909,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4663,
    "Rank": 6911,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4664,
    "Rank": 6913,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4665,
    "Rank": 6915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4666,
    "Rank": 6918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4667,
    "Rank": 6919,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4668,
    "Rank": 6921,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4669,
    "Rank": 6923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4670,
    "Rank": 6925,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4671,
    "Rank": 6926,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4672,
    "Rank": 6929,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4673,
    "Rank": 6936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4674,
    "Rank": 6946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4675,
    "Rank": 6954,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4676,
    "Rank": 6958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4677,
    "Rank": 6960,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4678,
    "Rank": 6961,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4679,
    "Rank": 6968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4680,
    "Rank": 6969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4681,
    "Rank": 6970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4682,
    "Rank": 6975,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4683,
    "Rank": 6976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4684,
    "Rank": 6977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4685,
    "Rank": 6978,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4686,
    "Rank": 6980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4687,
    "Rank": 6982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4688,
    "Rank": 6984,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4689,
    "Rank": 6985,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4690,
    "Rank": 6986,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4691,
    "Rank": 6988,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4692,
    "Rank": 6990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4693,
    "Rank": 6997,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4694,
    "Rank": 6999,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4695,
    "Rank": 7000,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4696,
    "Rank": 7001,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4697,
    "Rank": 7004,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4698,
    "Rank": 7005,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4699,
    "Rank": 7007,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4700,
    "Rank": 7009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4701,
    "Rank": 7014,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4702,
    "Rank": 7015,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4703,
    "Rank": 7017,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4704,
    "Rank": 7021,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4705,
    "Rank": 7022,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4706,
    "Rank": 7025,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4707,
    "Rank": 7027,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4708,
    "Rank": 7033,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4709,
    "Rank": 7034,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4710,
    "Rank": 7038,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4711,
    "Rank": 7041,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4712,
    "Rank": 7042,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4713,
    "Rank": 7045,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4714,
    "Rank": 7050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4715,
    "Rank": 7051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4716,
    "Rank": 7052,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4717,
    "Rank": 7053,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4718,
    "Rank": 7056,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4719,
    "Rank": 7059,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4720,
    "Rank": 7060,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4721,
    "Rank": 7062,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4722,
    "Rank": 7063,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4723,
    "Rank": 7064,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4724,
    "Rank": 7065,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4725,
    "Rank": 7068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4726,
    "Rank": 7070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4727,
    "Rank": 7073,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4728,
    "Rank": 7078,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4729,
    "Rank": 7080,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4730,
    "Rank": 7082,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4731,
    "Rank": 7085,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4732,
    "Rank": 7086,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4733,
    "Rank": 7087,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4734,
    "Rank": 7090,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4735,
    "Rank": 7092,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4736,
    "Rank": 7100,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4737,
    "Rank": 7105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4738,
    "Rank": 7106,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4739,
    "Rank": 7107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4740,
    "Rank": 7114,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4741,
    "Rank": 7118,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4742,
    "Rank": 7119,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4743,
    "Rank": 7121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4744,
    "Rank": 7126,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4745,
    "Rank": 7129,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4746,
    "Rank": 7130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4747,
    "Rank": 7134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4748,
    "Rank": 7135,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4749,
    "Rank": 7137,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4750,
    "Rank": 7138,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4751,
    "Rank": 7143,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4752,
    "Rank": 7147,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4753,
    "Rank": 7151,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4754,
    "Rank": 7152,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4755,
    "Rank": 7158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4756,
    "Rank": 7160,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4757,
    "Rank": 7162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4758,
    "Rank": 7167,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4759,
    "Rank": 7171,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4760,
    "Rank": 7173,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4761,
    "Rank": 7176,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4762,
    "Rank": 7179,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4763,
    "Rank": 7181,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4764,
    "Rank": 7184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4765,
    "Rank": 7187,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4766,
    "Rank": 7188,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4767,
    "Rank": 7189,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4768,
    "Rank": 7191,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4769,
    "Rank": 7192,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4770,
    "Rank": 7196,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4771,
    "Rank": 7197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4772,
    "Rank": 7199,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4773,
    "Rank": 7200,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4774,
    "Rank": 7203,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4775,
    "Rank": 7205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4776,
    "Rank": 7206,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4777,
    "Rank": 7207,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4778,
    "Rank": 7215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4779,
    "Rank": 7218,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4780,
    "Rank": 7220,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4781,
    "Rank": 7224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4782,
    "Rank": 7225,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4783,
    "Rank": 7226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4784,
    "Rank": 7227,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4785,
    "Rank": 7231,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4786,
    "Rank": 7232,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4787,
    "Rank": 7233,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4788,
    "Rank": 7234,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4789,
    "Rank": 7235,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4790,
    "Rank": 7237,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4791,
    "Rank": 7238,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4792,
    "Rank": 7241,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4793,
    "Rank": 7242,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4794,
    "Rank": 7247,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4795,
    "Rank": 7248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4796,
    "Rank": 7249,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4797,
    "Rank": 7250,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4798,
    "Rank": 7255,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4799,
    "Rank": 7258,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4800,
    "Rank": 7260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4801,
    "Rank": 7261,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4802,
    "Rank": 7263,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4803,
    "Rank": 7264,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4804,
    "Rank": 7266,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4805,
    "Rank": 7269,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4806,
    "Rank": 7271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4807,
    "Rank": 7278,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4808,
    "Rank": 7280,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4809,
    "Rank": 7282,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4810,
    "Rank": 7283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4811,
    "Rank": 7284,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4812,
    "Rank": 7292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4813,
    "Rank": 7293,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4814,
    "Rank": 7295,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4815,
    "Rank": 7303,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4816,
    "Rank": 7304,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4817,
    "Rank": 7306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4818,
    "Rank": 7307,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4819,
    "Rank": 7311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4820,
    "Rank": 7314,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4821,
    "Rank": 7317,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4822,
    "Rank": 7319,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4823,
    "Rank": 7324,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4824,
    "Rank": 7328,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4825,
    "Rank": 7330,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4826,
    "Rank": 7332,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4827,
    "Rank": 7334,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4828,
    "Rank": 7337,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4829,
    "Rank": 7342,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4830,
    "Rank": 7344,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4831,
    "Rank": 7347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4832,
    "Rank": 7350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4833,
    "Rank": 7351,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4834,
    "Rank": 7352,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4835,
    "Rank": 7356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4836,
    "Rank": 7360,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4837,
    "Rank": 7364,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4838,
    "Rank": 7365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4839,
    "Rank": 7368,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4840,
    "Rank": 7369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4841,
    "Rank": 7370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4842,
    "Rank": 7371,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4843,
    "Rank": 7372,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4844,
    "Rank": 7374,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4845,
    "Rank": 7375,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4846,
    "Rank": 7381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4847,
    "Rank": 7385,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4848,
    "Rank": 7387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4849,
    "Rank": 7404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4850,
    "Rank": 7405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4851,
    "Rank": 7407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4852,
    "Rank": 7408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4853,
    "Rank": 7414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4854,
    "Rank": 7417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4855,
    "Rank": 7420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4856,
    "Rank": 7421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4857,
    "Rank": 7423,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4858,
    "Rank": 7425,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4859,
    "Rank": 7432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4860,
    "Rank": 7433,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4861,
    "Rank": 7436,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4862,
    "Rank": 7437,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4863,
    "Rank": 7439,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4864,
    "Rank": 7440,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4865,
    "Rank": 7441,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4866,
    "Rank": 7442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4867,
    "Rank": 7453,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4868,
    "Rank": 7456,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4869,
    "Rank": 7457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4870,
    "Rank": 7458,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4871,
    "Rank": 7459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4872,
    "Rank": 7463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4873,
    "Rank": 7465,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4874,
    "Rank": 7467,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4875,
    "Rank": 7470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4876,
    "Rank": 7473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4877,
    "Rank": 7475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4878,
    "Rank": 7476,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4879,
    "Rank": 7477,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4880,
    "Rank": 7479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4881,
    "Rank": 7482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4882,
    "Rank": 7486,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4883,
    "Rank": 7490,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4884,
    "Rank": 7494,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4885,
    "Rank": 7495,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4886,
    "Rank": 7498,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4887,
    "Rank": 7504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4888,
    "Rank": 7506,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4889,
    "Rank": 7509,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4890,
    "Rank": 7510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4891,
    "Rank": 7518,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4892,
    "Rank": 7521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4893,
    "Rank": 7523,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4894,
    "Rank": 7524,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4895,
    "Rank": 7525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4896,
    "Rank": 7532,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4897,
    "Rank": 7539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4898,
    "Rank": 7542,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4899,
    "Rank": 7545,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4900,
    "Rank": 7547,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4901,
    "Rank": 7550,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4902,
    "Rank": 7552,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4903,
    "Rank": 7555,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4904,
    "Rank": 7558,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4905,
    "Rank": 7559,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4906,
    "Rank": 7560,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4907,
    "Rank": 7561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4908,
    "Rank": 7562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4909,
    "Rank": 7565,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4910,
    "Rank": 7578,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4911,
    "Rank": 7580,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4912,
    "Rank": 7586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4913,
    "Rank": 7590,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4914,
    "Rank": 7591,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4915,
    "Rank": 7594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4916,
    "Rank": 7596,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4917,
    "Rank": 7598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4918,
    "Rank": 7601,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4919,
    "Rank": 7606,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4920,
    "Rank": 7610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4921,
    "Rank": 7611,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4922,
    "Rank": 7613,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4923,
    "Rank": 7614,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4924,
    "Rank": 7615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4925,
    "Rank": 7616,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4926,
    "Rank": 7621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4927,
    "Rank": 7622,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4928,
    "Rank": 7623,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4929,
    "Rank": 7624,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4930,
    "Rank": 7625,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4931,
    "Rank": 7631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4932,
    "Rank": 7639,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4933,
    "Rank": 7642,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4934,
    "Rank": 7644,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4935,
    "Rank": 7645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4936,
    "Rank": 7646,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4937,
    "Rank": 7652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4938,
    "Rank": 7655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4939,
    "Rank": 7658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4940,
    "Rank": 7660,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4941,
    "Rank": 7661,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4942,
    "Rank": 7662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4943,
    "Rank": 7664,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4944,
    "Rank": 7668,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4945,
    "Rank": 7670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4946,
    "Rank": 7671,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4947,
    "Rank": 7672,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4948,
    "Rank": 7674,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4949,
    "Rank": 7675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4950,
    "Rank": 7678,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4951,
    "Rank": 7682,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4952,
    "Rank": 7683,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4953,
    "Rank": 7686,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4954,
    "Rank": 7688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4955,
    "Rank": 7689,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4956,
    "Rank": 7692,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4957,
    "Rank": 7698,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4958,
    "Rank": 7704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4959,
    "Rank": 7709,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4960,
    "Rank": 7711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4961,
    "Rank": 7712,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4962,
    "Rank": 7714,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4963,
    "Rank": 7715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4964,
    "Rank": 7717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4965,
    "Rank": 7723,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4966,
    "Rank": 7726,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4967,
    "Rank": 7728,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4968,
    "Rank": 7733,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4969,
    "Rank": 7741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4970,
    "Rank": 7742,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4971,
    "Rank": 7743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4972,
    "Rank": 7747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4973,
    "Rank": 7749,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4974,
    "Rank": 7752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4975,
    "Rank": 7753,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4976,
    "Rank": 7755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4977,
    "Rank": 7757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4978,
    "Rank": 7758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4979,
    "Rank": 7760,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4980,
    "Rank": 7762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4981,
    "Rank": 7764,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4982,
    "Rank": 7765,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4983,
    "Rank": 7766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4984,
    "Rank": 7771,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4985,
    "Rank": 7773,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4986,
    "Rank": 7774,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4987,
    "Rank": 7775,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4988,
    "Rank": 7779,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4989,
    "Rank": 7787,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4990,
    "Rank": 7793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4991,
    "Rank": 7795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4992,
    "Rank": 7797,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4993,
    "Rank": 7798,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4994,
    "Rank": 7799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4995,
    "Rank": 7800,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4996,
    "Rank": 7801,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4997,
    "Rank": 7805,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4998,
    "Rank": 7806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 4999,
    "Rank": 7810,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5000,
    "Rank": 7814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5001,
    "Rank": 7818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5002,
    "Rank": 7820,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5003,
    "Rank": 7824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5004,
    "Rank": 7827,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5005,
    "Rank": 7828,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5006,
    "Rank": 7831,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5007,
    "Rank": 7837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5008,
    "Rank": 7838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5009,
    "Rank": 7846,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5010,
    "Rank": 7847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5011,
    "Rank": 7850,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5012,
    "Rank": 7853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5013,
    "Rank": 7854,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5014,
    "Rank": 7855,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5015,
    "Rank": 7857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5016,
    "Rank": 7858,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5017,
    "Rank": 7863,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5018,
    "Rank": 7865,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5019,
    "Rank": 7866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5020,
    "Rank": 7873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5021,
    "Rank": 7874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5022,
    "Rank": 7875,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5023,
    "Rank": 7877,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5024,
    "Rank": 7879,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5025,
    "Rank": 7883,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5026,
    "Rank": 7884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5027,
    "Rank": 7889,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5028,
    "Rank": 7890,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5029,
    "Rank": 7893,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5030,
    "Rank": 7897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5031,
    "Rank": 7898,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5032,
    "Rank": 7901,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5033,
    "Rank": 7903,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5034,
    "Rank": 7905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5035,
    "Rank": 7912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5036,
    "Rank": 7913,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5037,
    "Rank": 7915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5038,
    "Rank": 7919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5039,
    "Rank": 7920,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5040,
    "Rank": 7921,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5041,
    "Rank": 7922,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5042,
    "Rank": 7931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5043,
    "Rank": 7932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5044,
    "Rank": 7933,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5045,
    "Rank": 7935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5046,
    "Rank": 7940,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5047,
    "Rank": 7950,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5048,
    "Rank": 7951,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5049,
    "Rank": 7954,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5050,
    "Rank": 7959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5051,
    "Rank": 7962,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5052,
    "Rank": 7963,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5053,
    "Rank": 7965,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5054,
    "Rank": 7967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5055,
    "Rank": 7969,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5056,
    "Rank": 7971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5057,
    "Rank": 7972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5058,
    "Rank": 7973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5059,
    "Rank": 7975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5060,
    "Rank": 7979,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5061,
    "Rank": 7980,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5062,
    "Rank": 7981,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5063,
    "Rank": 7985,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5064,
    "Rank": 7987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5065,
    "Rank": 7989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5066,
    "Rank": 7993,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5067,
    "Rank": 7996,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5068,
    "Rank": 8000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5069,
    "Rank": 8001,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5070,
    "Rank": 8002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5071,
    "Rank": 8005,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5072,
    "Rank": 8010,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5073,
    "Rank": 8011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5074,
    "Rank": 8012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5075,
    "Rank": 8013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5076,
    "Rank": 8014,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5077,
    "Rank": 8016,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5078,
    "Rank": 8017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5079,
    "Rank": 8027,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5080,
    "Rank": 8029,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5081,
    "Rank": 8030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5082,
    "Rank": 8036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5083,
    "Rank": 8038,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5084,
    "Rank": 8042,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5085,
    "Rank": 8043,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5086,
    "Rank": 8044,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5087,
    "Rank": 8052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5088,
    "Rank": 8053,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5089,
    "Rank": 8056,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5090,
    "Rank": 8057,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5091,
    "Rank": 8059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5092,
    "Rank": 8060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5093,
    "Rank": 8061,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5094,
    "Rank": 8063,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5095,
    "Rank": 8065,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5096,
    "Rank": 8068,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5097,
    "Rank": 8070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5098,
    "Rank": 8071,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5099,
    "Rank": 8073,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5100,
    "Rank": 8074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5101,
    "Rank": 8075,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5102,
    "Rank": 8076,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5103,
    "Rank": 8079,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5104,
    "Rank": 8080,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5105,
    "Rank": 8081,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5106,
    "Rank": 8082,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5107,
    "Rank": 8083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5108,
    "Rank": 8087,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5109,
    "Rank": 8090,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5110,
    "Rank": 8093,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5111,
    "Rank": 8094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5112,
    "Rank": 8099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5113,
    "Rank": 8102,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5114,
    "Rank": 8103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5115,
    "Rank": 8105,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5116,
    "Rank": 8108,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5117,
    "Rank": 8110,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5118,
    "Rank": 8111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5119,
    "Rank": 8112,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5120,
    "Rank": 8114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5121,
    "Rank": 8116,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5122,
    "Rank": 8123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5123,
    "Rank": 8126,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5124,
    "Rank": 8127,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5125,
    "Rank": 8130,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5126,
    "Rank": 8131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5127,
    "Rank": 8137,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5128,
    "Rank": 8139,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5129,
    "Rank": 8141,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5130,
    "Rank": 8143,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5131,
    "Rank": 8144,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5132,
    "Rank": 8145,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5133,
    "Rank": 8148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5134,
    "Rank": 8152,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5135,
    "Rank": 8156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5136,
    "Rank": 8161,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5137,
    "Rank": 8164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5138,
    "Rank": 8167,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5139,
    "Rank": 8168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5140,
    "Rank": 8173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5141,
    "Rank": 8176,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5142,
    "Rank": 8178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5143,
    "Rank": 8181,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5144,
    "Rank": 8182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5145,
    "Rank": 8184,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5146,
    "Rank": 8185,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5147,
    "Rank": 8189,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5148,
    "Rank": 8190,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5149,
    "Rank": 8193,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5150,
    "Rank": 8194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5151,
    "Rank": 8197,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5152,
    "Rank": 8198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5153,
    "Rank": 8200,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5154,
    "Rank": 8201,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5155,
    "Rank": 8203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5156,
    "Rank": 8204,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5157,
    "Rank": 8209,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5158,
    "Rank": 8212,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5159,
    "Rank": 8216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5160,
    "Rank": 8218,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5161,
    "Rank": 8220,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5162,
    "Rank": 8221,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5163,
    "Rank": 8222,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5164,
    "Rank": 8223,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5165,
    "Rank": 8225,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5166,
    "Rank": 8236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5167,
    "Rank": 8238,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5168,
    "Rank": 8239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5169,
    "Rank": 8243,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5170,
    "Rank": 8244,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5171,
    "Rank": 8246,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5172,
    "Rank": 8249,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5173,
    "Rank": 8250,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5174,
    "Rank": 8257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5175,
    "Rank": 8259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5176,
    "Rank": 8260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5177,
    "Rank": 8261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5178,
    "Rank": 8262,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5179,
    "Rank": 8266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5180,
    "Rank": 8267,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5181,
    "Rank": 8268,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5182,
    "Rank": 8269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5183,
    "Rank": 8271,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5184,
    "Rank": 8272,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5185,
    "Rank": 8274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5186,
    "Rank": 8282,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5187,
    "Rank": 8284,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5188,
    "Rank": 8289,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5189,
    "Rank": 8290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5190,
    "Rank": 8292,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5191,
    "Rank": 8294,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5192,
    "Rank": 8298,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5193,
    "Rank": 8301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5194,
    "Rank": 8303,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5195,
    "Rank": 8312,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5196,
    "Rank": 8314,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5197,
    "Rank": 8320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5198,
    "Rank": 8321,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5199,
    "Rank": 8324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5200,
    "Rank": 8326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5201,
    "Rank": 8329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5202,
    "Rank": 8330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5203,
    "Rank": 8334,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5204,
    "Rank": 8335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5205,
    "Rank": 8340,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5206,
    "Rank": 8341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5207,
    "Rank": 8342,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5208,
    "Rank": 8345,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5209,
    "Rank": 8348,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5210,
    "Rank": 8349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5211,
    "Rank": 8351,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5212,
    "Rank": 8357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5213,
    "Rank": 8359,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5214,
    "Rank": 8360,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5215,
    "Rank": 8361,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5216,
    "Rank": 8362,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5217,
    "Rank": 8364,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5218,
    "Rank": 8365,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5219,
    "Rank": 8366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5220,
    "Rank": 8368,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5221,
    "Rank": 8371,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5222,
    "Rank": 8373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5223,
    "Rank": 8375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5224,
    "Rank": 8377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5225,
    "Rank": 8379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5226,
    "Rank": 8381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5227,
    "Rank": 8382,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5228,
    "Rank": 8383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5229,
    "Rank": 8386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5230,
    "Rank": 8387,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5231,
    "Rank": 8388,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5232,
    "Rank": 8393,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5233,
    "Rank": 8394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5234,
    "Rank": 8395,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5235,
    "Rank": 8396,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5236,
    "Rank": 8398,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5237,
    "Rank": 8405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5238,
    "Rank": 8406,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5239,
    "Rank": 8407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5240,
    "Rank": 8412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5241,
    "Rank": 8414,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5242,
    "Rank": 8419,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5243,
    "Rank": 8424,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5244,
    "Rank": 8427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5245,
    "Rank": 8436,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5246,
    "Rank": 8437,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5247,
    "Rank": 8446,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5248,
    "Rank": 8447,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5249,
    "Rank": 8449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5250,
    "Rank": 8450,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5251,
    "Rank": 8456,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5252,
    "Rank": 8457,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5253,
    "Rank": 8460,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5254,
    "Rank": 8464,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5255,
    "Rank": 8465,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5256,
    "Rank": 8467,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5257,
    "Rank": 8470,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5258,
    "Rank": 8475,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5259,
    "Rank": 8476,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5260,
    "Rank": 8481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5261,
    "Rank": 8487,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5262,
    "Rank": 8489,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5263,
    "Rank": 8493,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5264,
    "Rank": 8496,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5265,
    "Rank": 8497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5266,
    "Rank": 8512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5267,
    "Rank": 8514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5268,
    "Rank": 8520,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5269,
    "Rank": 8521,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5270,
    "Rank": 8523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5271,
    "Rank": 8524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5272,
    "Rank": 8525,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5273,
    "Rank": 8530,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5274,
    "Rank": 8532,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5275,
    "Rank": 8533,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5276,
    "Rank": 8535,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5277,
    "Rank": 8536,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5278,
    "Rank": 8537,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5279,
    "Rank": 8539,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5280,
    "Rank": 8541,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5281,
    "Rank": 8546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5282,
    "Rank": 8552,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5283,
    "Rank": 8553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5284,
    "Rank": 8556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5285,
    "Rank": 8557,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5286,
    "Rank": 8576,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5287,
    "Rank": 8577,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5288,
    "Rank": 8578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5289,
    "Rank": 8579,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5290,
    "Rank": 8580,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5291,
    "Rank": 8585,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5292,
    "Rank": 8586,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5293,
    "Rank": 8588,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5294,
    "Rank": 8590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5295,
    "Rank": 8597,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5296,
    "Rank": 8601,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5297,
    "Rank": 8603,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5298,
    "Rank": 8604,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5299,
    "Rank": 8615,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5300,
    "Rank": 8616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5301,
    "Rank": 8619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5302,
    "Rank": 8620,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5303,
    "Rank": 8624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5304,
    "Rank": 8625,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5305,
    "Rank": 8631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5306,
    "Rank": 8633,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5307,
    "Rank": 8636,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5308,
    "Rank": 8637,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5309,
    "Rank": 8641,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5310,
    "Rank": 8642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5311,
    "Rank": 8643,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5312,
    "Rank": 8646,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5313,
    "Rank": 8647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5314,
    "Rank": 8652,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5315,
    "Rank": 8655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5316,
    "Rank": 8657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5317,
    "Rank": 8659,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5318,
    "Rank": 8661,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5319,
    "Rank": 8666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5320,
    "Rank": 8668,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5321,
    "Rank": 8672,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5322,
    "Rank": 8678,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5323,
    "Rank": 8680,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5324,
    "Rank": 8682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5325,
    "Rank": 8683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5326,
    "Rank": 8684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5327,
    "Rank": 8687,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5328,
    "Rank": 8688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5329,
    "Rank": 8690,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5330,
    "Rank": 8693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5331,
    "Rank": 8696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5332,
    "Rank": 8697,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5333,
    "Rank": 8704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5334,
    "Rank": 8709,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5335,
    "Rank": 8713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5336,
    "Rank": 8714,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5337,
    "Rank": 8716,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5338,
    "Rank": 8718,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5339,
    "Rank": 8720,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5340,
    "Rank": 8726,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5341,
    "Rank": 8732,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5342,
    "Rank": 8736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5343,
    "Rank": 8737,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5344,
    "Rank": 8748,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5345,
    "Rank": 8750,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5346,
    "Rank": 8751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5347,
    "Rank": 8753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5348,
    "Rank": 8757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5349,
    "Rank": 8760,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5350,
    "Rank": 8768,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5351,
    "Rank": 8770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5352,
    "Rank": 8777,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5353,
    "Rank": 8779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5354,
    "Rank": 8784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5355,
    "Rank": 8786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5356,
    "Rank": 8788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5357,
    "Rank": 8792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5358,
    "Rank": 8793,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5359,
    "Rank": 8794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5360,
    "Rank": 8796,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5361,
    "Rank": 8797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5362,
    "Rank": 8798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5363,
    "Rank": 8800,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5364,
    "Rank": 8804,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5365,
    "Rank": 8807,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5366,
    "Rank": 8809,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5367,
    "Rank": 8810,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5368,
    "Rank": 8813,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5369,
    "Rank": 8816,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5370,
    "Rank": 8820,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5371,
    "Rank": 8825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5372,
    "Rank": 8827,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5373,
    "Rank": 8828,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5374,
    "Rank": 8830,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5375,
    "Rank": 8834,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5376,
    "Rank": 8835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5377,
    "Rank": 8837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5378,
    "Rank": 8839,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5379,
    "Rank": 8840,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5380,
    "Rank": 8843,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5381,
    "Rank": 8844,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5382,
    "Rank": 8847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5383,
    "Rank": 8849,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5384,
    "Rank": 8852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5385,
    "Rank": 8854,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5386,
    "Rank": 8855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5387,
    "Rank": 8858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5388,
    "Rank": 8863,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5389,
    "Rank": 8864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5390,
    "Rank": 8867,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5391,
    "Rank": 8868,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5392,
    "Rank": 8869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5393,
    "Rank": 8870,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5394,
    "Rank": 8872,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5395,
    "Rank": 8873,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5396,
    "Rank": 8875,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5397,
    "Rank": 8876,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5398,
    "Rank": 8877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5399,
    "Rank": 8880,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5400,
    "Rank": 8881,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5401,
    "Rank": 8885,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5402,
    "Rank": 8886,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5403,
    "Rank": 8889,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5404,
    "Rank": 8890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5405,
    "Rank": 8894,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5406,
    "Rank": 8895,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5407,
    "Rank": 8896,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5408,
    "Rank": 8902,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5409,
    "Rank": 8904,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5410,
    "Rank": 8905,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5411,
    "Rank": 8906,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5412,
    "Rank": 8907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5413,
    "Rank": 8910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5414,
    "Rank": 8911,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5415,
    "Rank": 8915,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5416,
    "Rank": 8916,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5417,
    "Rank": 8917,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5418,
    "Rank": 8918,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5419,
    "Rank": 8919,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5420,
    "Rank": 8924,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5421,
    "Rank": 8926,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5422,
    "Rank": 8927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5423,
    "Rank": 8928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5424,
    "Rank": 8929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5425,
    "Rank": 8931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5426,
    "Rank": 8933,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5427,
    "Rank": 8943,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5428,
    "Rank": 8944,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5429,
    "Rank": 8947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5430,
    "Rank": 8952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5431,
    "Rank": 8954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5432,
    "Rank": 8957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5433,
    "Rank": 8962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5434,
    "Rank": 8967,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5435,
    "Rank": 8970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5436,
    "Rank": 8974,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5437,
    "Rank": 8980,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5438,
    "Rank": 8983,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5439,
    "Rank": 8986,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5440,
    "Rank": 8988,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5441,
    "Rank": 8989,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5442,
    "Rank": 8990,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5443,
    "Rank": 8991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5444,
    "Rank": 8993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5445,
    "Rank": 8996,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5446,
    "Rank": 8998,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5447,
    "Rank": 9003,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5448,
    "Rank": 9009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5449,
    "Rank": 9011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5450,
    "Rank": 9012,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5451,
    "Rank": 9013,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5452,
    "Rank": 9014,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5453,
    "Rank": 9017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5454,
    "Rank": 9019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5455,
    "Rank": 9020,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5456,
    "Rank": 9021,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5457,
    "Rank": 9024,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5458,
    "Rank": 9025,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5459,
    "Rank": 9027,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5460,
    "Rank": 9029,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5461,
    "Rank": 9036,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5462,
    "Rank": 9040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5463,
    "Rank": 9045,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5464,
    "Rank": 9049,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5465,
    "Rank": 9050,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5466,
    "Rank": 9052,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5467,
    "Rank": 9058,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5468,
    "Rank": 9063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5469,
    "Rank": 9064,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5470,
    "Rank": 9066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5471,
    "Rank": 9068,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5472,
    "Rank": 9069,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5473,
    "Rank": 9073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5474,
    "Rank": 9075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5475,
    "Rank": 9076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5476,
    "Rank": 9080,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5477,
    "Rank": 9081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5478,
    "Rank": 9084,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5479,
    "Rank": 9085,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5480,
    "Rank": 9087,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5481,
    "Rank": 9093,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5482,
    "Rank": 9096,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5483,
    "Rank": 9099,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5484,
    "Rank": 9103,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5485,
    "Rank": 9106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5486,
    "Rank": 9107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5487,
    "Rank": 9108,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5488,
    "Rank": 9110,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5489,
    "Rank": 9116,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5490,
    "Rank": 9118,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5491,
    "Rank": 9126,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5492,
    "Rank": 9128,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5493,
    "Rank": 9129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5494,
    "Rank": 9132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5495,
    "Rank": 9135,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5496,
    "Rank": 9136,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5497,
    "Rank": 9139,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5498,
    "Rank": 9141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5499,
    "Rank": 9142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5500,
    "Rank": 9143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5501,
    "Rank": 9152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5502,
    "Rank": 9154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5503,
    "Rank": 9156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5504,
    "Rank": 9157,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5505,
    "Rank": 9159,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5506,
    "Rank": 9160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5507,
    "Rank": 9161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5508,
    "Rank": 9162,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5509,
    "Rank": 9163,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5510,
    "Rank": 9164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5511,
    "Rank": 9167,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5512,
    "Rank": 9174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5513,
    "Rank": 9175,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5514,
    "Rank": 9178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5515,
    "Rank": 9181,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5516,
    "Rank": 9183,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5517,
    "Rank": 9184,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5518,
    "Rank": 9185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5519,
    "Rank": 9190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5520,
    "Rank": 9194,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5521,
    "Rank": 9196,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5522,
    "Rank": 9197,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5523,
    "Rank": 9200,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5524,
    "Rank": 9202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5525,
    "Rank": 9205,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5526,
    "Rank": 9206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5527,
    "Rank": 9207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5528,
    "Rank": 9211,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5529,
    "Rank": 9213,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5530,
    "Rank": 9214,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5531,
    "Rank": 9218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5532,
    "Rank": 9220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5533,
    "Rank": 9223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5534,
    "Rank": 9224,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5535,
    "Rank": 9226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5536,
    "Rank": 9228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5537,
    "Rank": 9237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5538,
    "Rank": 9238,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5539,
    "Rank": 9239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5540,
    "Rank": 9244,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5541,
    "Rank": 9245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5542,
    "Rank": 9253,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5543,
    "Rank": 9254,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5544,
    "Rank": 9257,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5545,
    "Rank": 9259,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5546,
    "Rank": 9261,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5547,
    "Rank": 9262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5548,
    "Rank": 9264,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5549,
    "Rank": 9272,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5550,
    "Rank": 9273,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5551,
    "Rank": 9274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5552,
    "Rank": 9276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5553,
    "Rank": 9285,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5554,
    "Rank": 9286,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5555,
    "Rank": 9289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5556,
    "Rank": 9294,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5557,
    "Rank": 9299,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5558,
    "Rank": 9301,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5559,
    "Rank": 9308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5560,
    "Rank": 9315,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5561,
    "Rank": 9323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5562,
    "Rank": 9324,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5563,
    "Rank": 9328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5564,
    "Rank": 9329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5565,
    "Rank": 9330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5566,
    "Rank": 9331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5567,
    "Rank": 9333,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5568,
    "Rank": 9336,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5569,
    "Rank": 9337,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5570,
    "Rank": 9340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5571,
    "Rank": 9341,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5572,
    "Rank": 9342,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5573,
    "Rank": 9343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5574,
    "Rank": 9345,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5575,
    "Rank": 9346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5576,
    "Rank": 9348,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5577,
    "Rank": 9350,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5578,
    "Rank": 9353,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5579,
    "Rank": 9354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5580,
    "Rank": 9360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5581,
    "Rank": 9367,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5582,
    "Rank": 9368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5583,
    "Rank": 9371,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5584,
    "Rank": 9372,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5585,
    "Rank": 9374,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5586,
    "Rank": 9377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5587,
    "Rank": 9381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5588,
    "Rank": 9382,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5589,
    "Rank": 9383,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5590,
    "Rank": 9385,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5591,
    "Rank": 9387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5592,
    "Rank": 9390,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5593,
    "Rank": 9392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5594,
    "Rank": 9393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5595,
    "Rank": 9394,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5596,
    "Rank": 9395,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5597,
    "Rank": 9400,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5598,
    "Rank": 9401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5599,
    "Rank": 9403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5600,
    "Rank": 9405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5601,
    "Rank": 9406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5602,
    "Rank": 9407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5603,
    "Rank": 9412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5604,
    "Rank": 9414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5605,
    "Rank": 9417,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5606,
    "Rank": 9418,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5607,
    "Rank": 9420,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5608,
    "Rank": 9425,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5609,
    "Rank": 9428,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5610,
    "Rank": 9429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5611,
    "Rank": 9433,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5612,
    "Rank": 9434,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5613,
    "Rank": 9439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5614,
    "Rank": 9440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5615,
    "Rank": 9442,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5616,
    "Rank": 9443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5617,
    "Rank": 9444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5618,
    "Rank": 9446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5619,
    "Rank": 9448,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5620,
    "Rank": 9452,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5621,
    "Rank": 9457,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5622,
    "Rank": 9458,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5623,
    "Rank": 9464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5624,
    "Rank": 9467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5625,
    "Rank": 9468,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5626,
    "Rank": 9470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5627,
    "Rank": 9474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5628,
    "Rank": 9475,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5629,
    "Rank": 9477,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5630,
    "Rank": 9478,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5631,
    "Rank": 9481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5632,
    "Rank": 9485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5633,
    "Rank": 9492,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5634,
    "Rank": 9496,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5635,
    "Rank": 9497,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5636,
    "Rank": 9498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5637,
    "Rank": 9499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5638,
    "Rank": 9502,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5639,
    "Rank": 9504,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5640,
    "Rank": 9509,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5641,
    "Rank": 9511,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5642,
    "Rank": 9521,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5643,
    "Rank": 9522,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5644,
    "Rank": 9530,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5645,
    "Rank": 9532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5646,
    "Rank": 9534,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5647,
    "Rank": 9535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5648,
    "Rank": 9538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5649,
    "Rank": 9540,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5650,
    "Rank": 9545,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5651,
    "Rank": 9546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5652,
    "Rank": 9550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5653,
    "Rank": 9551,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5654,
    "Rank": 9554,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5655,
    "Rank": 9556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5656,
    "Rank": 9561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5657,
    "Rank": 9563,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5658,
    "Rank": 9564,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5659,
    "Rank": 9569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5660,
    "Rank": 9570,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5661,
    "Rank": 9572,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5662,
    "Rank": 9573,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5663,
    "Rank": 9575,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5664,
    "Rank": 9576,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5665,
    "Rank": 9583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5666,
    "Rank": 9588,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5667,
    "Rank": 9592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5668,
    "Rank": 9594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5669,
    "Rank": 9595,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5670,
    "Rank": 9599,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5671,
    "Rank": 9604,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5672,
    "Rank": 9605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5673,
    "Rank": 9607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5674,
    "Rank": 9608,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5675,
    "Rank": 9609,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5676,
    "Rank": 9610,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5677,
    "Rank": 9612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5678,
    "Rank": 9614,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5679,
    "Rank": 9615,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5680,
    "Rank": 9619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5681,
    "Rank": 9620,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5682,
    "Rank": 9623,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5683,
    "Rank": 9624,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5684,
    "Rank": 9627,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5685,
    "Rank": 9631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5686,
    "Rank": 9634,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5687,
    "Rank": 9637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5688,
    "Rank": 9638,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5689,
    "Rank": 9639,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5690,
    "Rank": 9641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5691,
    "Rank": 9645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5692,
    "Rank": 9647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5693,
    "Rank": 9649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5694,
    "Rank": 9651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5695,
    "Rank": 9656,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5696,
    "Rank": 9660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5697,
    "Rank": 9662,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5698,
    "Rank": 9663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5699,
    "Rank": 9665,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5700,
    "Rank": 9668,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5701,
    "Rank": 9669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5702,
    "Rank": 9670,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5703,
    "Rank": 9671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5704,
    "Rank": 9674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5705,
    "Rank": 9676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5706,
    "Rank": 9682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5707,
    "Rank": 9686,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5708,
    "Rank": 9687,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5709,
    "Rank": 9689,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5710,
    "Rank": 9691,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5711,
    "Rank": 9694,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5712,
    "Rank": 9696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5713,
    "Rank": 9698,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5714,
    "Rank": 9699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5715,
    "Rank": 9708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5716,
    "Rank": 9716,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5717,
    "Rank": 9718,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5718,
    "Rank": 9719,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5719,
    "Rank": 9723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5720,
    "Rank": 9724,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5721,
    "Rank": 9727,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5722,
    "Rank": 9728,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5723,
    "Rank": 9729,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5724,
    "Rank": 9738,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5725,
    "Rank": 9741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5726,
    "Rank": 9753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5727,
    "Rank": 9754,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5728,
    "Rank": 9759,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5729,
    "Rank": 9760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5730,
    "Rank": 9761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5731,
    "Rank": 9763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5732,
    "Rank": 9764,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5733,
    "Rank": 9768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5734,
    "Rank": 9773,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5735,
    "Rank": 9774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5736,
    "Rank": 9782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5737,
    "Rank": 9784,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5738,
    "Rank": 9786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5739,
    "Rank": 9787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5740,
    "Rank": 9792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5741,
    "Rank": 9793,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5742,
    "Rank": 9797,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5743,
    "Rank": 9799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5744,
    "Rank": 9801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5745,
    "Rank": 9803,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5746,
    "Rank": 9805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5747,
    "Rank": 9812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5748,
    "Rank": 9815,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5749,
    "Rank": 9816,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5750,
    "Rank": 9818,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5751,
    "Rank": 9819,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5752,
    "Rank": 9821,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5753,
    "Rank": 9825,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5754,
    "Rank": 9827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5755,
    "Rank": 9828,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5756,
    "Rank": 9834,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5757,
    "Rank": 9835,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5758,
    "Rank": 9837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5759,
    "Rank": 9839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5760,
    "Rank": 9840,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5761,
    "Rank": 9841,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5762,
    "Rank": 9842,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5763,
    "Rank": 9847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5764,
    "Rank": 9848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5765,
    "Rank": 9849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5766,
    "Rank": 9850,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5767,
    "Rank": 9854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5768,
    "Rank": 9855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5769,
    "Rank": 9856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5770,
    "Rank": 9857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5771,
    "Rank": 9859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5772,
    "Rank": 9860,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5773,
    "Rank": 9866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5774,
    "Rank": 9868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5775,
    "Rank": 9869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5776,
    "Rank": 9872,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5777,
    "Rank": 9875,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5778,
    "Rank": 9876,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5779,
    "Rank": 9878,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5780,
    "Rank": 9881,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5781,
    "Rank": 9882,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5782,
    "Rank": 9884,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5783,
    "Rank": 9888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5784,
    "Rank": 9890,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5785,
    "Rank": 9892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5786,
    "Rank": 9893,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5787,
    "Rank": 9901,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5788,
    "Rank": 9912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5789,
    "Rank": 9913,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5790,
    "Rank": 9918,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5791,
    "Rank": 9924,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5792,
    "Rank": 9925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5793,
    "Rank": 9932,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5794,
    "Rank": 9935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5795,
    "Rank": 9936,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5796,
    "Rank": 9938,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5797,
    "Rank": 9939,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5798,
    "Rank": 9943,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5799,
    "Rank": 9944,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5800,
    "Rank": 9947,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5801,
    "Rank": 9950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5802,
    "Rank": 9951,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5803,
    "Rank": 9953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5804,
    "Rank": 9955,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5805,
    "Rank": 9962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5806,
    "Rank": 9964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5807,
    "Rank": 9970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5808,
    "Rank": 9971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5809,
    "Rank": 9973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5810,
    "Rank": 9974,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5811,
    "Rank": 9976,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5812,
    "Rank": 9977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5813,
    "Rank": 9979,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5814,
    "Rank": 9981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5815,
    "Rank": 9982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5816,
    "Rank": 9986,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5817,
    "Rank": 9987,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5818,
    "Rank": 9989,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5819,
    "Rank": 9992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5820,
    "Rank": 9997,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5821,
    "Rank": 9998,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5822,
    "Rank": 9999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5823,
    "Rank": 10001,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5824,
    "Rank": 10003,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5825,
    "Rank": 10004,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5826,
    "Rank": 10007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5827,
    "Rank": 10009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5828,
    "Rank": 10010,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5829,
    "Rank": 10011,
    "Allotted Quota": "All India",
    "Allotted Institute": "NEIGRIHMS, SHILLONG,Mawdiangdiang Shillong East Khasi Hills District, Meghalaya, 793018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5830,
    "Rank": 10012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5831,
    "Rank": 10013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5832,
    "Rank": 10016,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5833,
    "Rank": 10018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5834,
    "Rank": 10020,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5835,
    "Rank": 10023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5836,
    "Rank": 10026,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5837,
    "Rank": 10028,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5838,
    "Rank": 10030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5839,
    "Rank": 10034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5840,
    "Rank": 10038,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5841,
    "Rank": 10042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5842,
    "Rank": 10046,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5843,
    "Rank": 10048,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5844,
    "Rank": 10049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5845,
    "Rank": 10055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5846,
    "Rank": 10056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5847,
    "Rank": 10058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5848,
    "Rank": 10059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5849,
    "Rank": 10061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5850,
    "Rank": 10062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5851,
    "Rank": 10065,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5852,
    "Rank": 10069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5853,
    "Rank": 10070,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5854,
    "Rank": 10073,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5855,
    "Rank": 10074,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5856,
    "Rank": 10076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5857,
    "Rank": 10081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5858,
    "Rank": 10084,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5859,
    "Rank": 10088,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5860,
    "Rank": 10091,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5861,
    "Rank": 10093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5862,
    "Rank": 10096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5863,
    "Rank": 10100,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5864,
    "Rank": 10109,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5865,
    "Rank": 10110,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5866,
    "Rank": 10112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5867,
    "Rank": 10113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5868,
    "Rank": 10116,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5869,
    "Rank": 10117,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5870,
    "Rank": 10118,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5871,
    "Rank": 10122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5872,
    "Rank": 10124,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5873,
    "Rank": 10126,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5874,
    "Rank": 10129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5875,
    "Rank": 10130,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5876,
    "Rank": 10131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5877,
    "Rank": 10132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5878,
    "Rank": 10133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5879,
    "Rank": 10134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5880,
    "Rank": 10136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5881,
    "Rank": 10138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5882,
    "Rank": 10139,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5883,
    "Rank": 10140,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5884,
    "Rank": 10143,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5885,
    "Rank": 10144,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5886,
    "Rank": 10145,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5887,
    "Rank": 10149,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5888,
    "Rank": 10154,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5889,
    "Rank": 10155,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5890,
    "Rank": 10166,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5891,
    "Rank": 10168,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5892,
    "Rank": 10169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5893,
    "Rank": 10172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5894,
    "Rank": 10174,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5895,
    "Rank": 10176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5896,
    "Rank": 10178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5897,
    "Rank": 10181,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5898,
    "Rank": 10183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5899,
    "Rank": 10193,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5900,
    "Rank": 10197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5901,
    "Rank": 10198,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5902,
    "Rank": 10201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5903,
    "Rank": 10204,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5904,
    "Rank": 10205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5905,
    "Rank": 10208,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5906,
    "Rank": 10213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5907,
    "Rank": 10215,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5908,
    "Rank": 10216,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5909,
    "Rank": 10219,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5910,
    "Rank": 10222,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5911,
    "Rank": 10227,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5912,
    "Rank": 10228,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5913,
    "Rank": 10230,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5914,
    "Rank": 10231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5915,
    "Rank": 10233,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5916,
    "Rank": 10238,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5917,
    "Rank": 10245,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5918,
    "Rank": 10252,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5919,
    "Rank": 10253,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5920,
    "Rank": 10255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5921,
    "Rank": 10258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5922,
    "Rank": 10259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5923,
    "Rank": 10260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5924,
    "Rank": 10261,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5925,
    "Rank": 10262,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5926,
    "Rank": 10264,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5927,
    "Rank": 10265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5928,
    "Rank": 10270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5929,
    "Rank": 10276,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5930,
    "Rank": 10277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5931,
    "Rank": 10278,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5932,
    "Rank": 10280,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5933,
    "Rank": 10281,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5934,
    "Rank": 10284,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5935,
    "Rank": 10285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5936,
    "Rank": 10286,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5937,
    "Rank": 10287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5938,
    "Rank": 10288,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5939,
    "Rank": 10289,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5940,
    "Rank": 10290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5941,
    "Rank": 10295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5942,
    "Rank": 10296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5943,
    "Rank": 10300,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5944,
    "Rank": 10304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5945,
    "Rank": 10306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5946,
    "Rank": 10308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5947,
    "Rank": 10311,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5948,
    "Rank": 10317,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5949,
    "Rank": 10318,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5950,
    "Rank": 10319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5951,
    "Rank": 10320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5952,
    "Rank": 10321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5953,
    "Rank": 10324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5954,
    "Rank": 10326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5955,
    "Rank": 10331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5956,
    "Rank": 10337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5957,
    "Rank": 10338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5958,
    "Rank": 10344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5959,
    "Rank": 10345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5960,
    "Rank": 10350,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5961,
    "Rank": 10356,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5962,
    "Rank": 10357,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5963,
    "Rank": 10358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5964,
    "Rank": 10359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5965,
    "Rank": 10362,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5966,
    "Rank": 10365,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5967,
    "Rank": 10370,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5968,
    "Rank": 10372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5969,
    "Rank": 10373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5970,
    "Rank": 10375,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5971,
    "Rank": 10376,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5972,
    "Rank": 10381,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5973,
    "Rank": 10384,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5974,
    "Rank": 10389,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5975,
    "Rank": 10390,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5976,
    "Rank": 10391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5977,
    "Rank": 10393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5978,
    "Rank": 10394,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5979,
    "Rank": 10399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5980,
    "Rank": 10402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5981,
    "Rank": 10403,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5982,
    "Rank": 10404,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5983,
    "Rank": 10407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5984,
    "Rank": 10409,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5985,
    "Rank": 10411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5986,
    "Rank": 10413,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5987,
    "Rank": 10416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5988,
    "Rank": 10418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5989,
    "Rank": 10420,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5990,
    "Rank": 10423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5991,
    "Rank": 10426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5992,
    "Rank": 10429,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5993,
    "Rank": 10433,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5994,
    "Rank": 10435,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5995,
    "Rank": 10436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5996,
    "Rank": 10438,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5997,
    "Rank": 10439,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5998,
    "Rank": 10442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 5999,
    "Rank": 10443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6000,
    "Rank": 10444,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6001,
    "Rank": 10445,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6002,
    "Rank": 10447,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6003,
    "Rank": 10448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6004,
    "Rank": 10450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6005,
    "Rank": 10452,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6006,
    "Rank": 10457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6007,
    "Rank": 10458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6008,
    "Rank": 10460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6009,
    "Rank": 10462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6010,
    "Rank": 10463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6011,
    "Rank": 10467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6012,
    "Rank": 10469,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6013,
    "Rank": 10474,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6014,
    "Rank": 10476,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6015,
    "Rank": 10480,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6016,
    "Rank": 10489,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6017,
    "Rank": 10491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6018,
    "Rank": 10493,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6019,
    "Rank": 10494,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6020,
    "Rank": 10497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6021,
    "Rank": 10498,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6022,
    "Rank": 10499,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6023,
    "Rank": 10500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6024,
    "Rank": 10506,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6025,
    "Rank": 10508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6026,
    "Rank": 10516,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6027,
    "Rank": 10519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6028,
    "Rank": 10522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6029,
    "Rank": 10524,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6030,
    "Rank": 10525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6031,
    "Rank": 10526,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6032,
    "Rank": 10527,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6033,
    "Rank": 10529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6034,
    "Rank": 10532,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6035,
    "Rank": 10533,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6036,
    "Rank": 10534,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6037,
    "Rank": 10536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6038,
    "Rank": 10537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6039,
    "Rank": 10545,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6040,
    "Rank": 10548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6041,
    "Rank": 10551,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6042,
    "Rank": 10552,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6043,
    "Rank": 10555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6044,
    "Rank": 10557,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6045,
    "Rank": 10559,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6046,
    "Rank": 10560,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6047,
    "Rank": 10564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6048,
    "Rank": 10566,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6049,
    "Rank": 10567,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6050,
    "Rank": 10568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6051,
    "Rank": 10569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6052,
    "Rank": 10572,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6053,
    "Rank": 10573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6054,
    "Rank": 10574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6055,
    "Rank": 10575,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6056,
    "Rank": 10579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6057,
    "Rank": 10582,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6058,
    "Rank": 10586,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6059,
    "Rank": 10587,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6060,
    "Rank": 10590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6061,
    "Rank": 10594,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6062,
    "Rank": 10598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6063,
    "Rank": 10601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6064,
    "Rank": 10603,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6065,
    "Rank": 10604,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6066,
    "Rank": 10606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6067,
    "Rank": 10607,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6068,
    "Rank": 10609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6069,
    "Rank": 10612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6070,
    "Rank": 10614,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6071,
    "Rank": 10615,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6072,
    "Rank": 10617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6073,
    "Rank": 10619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6074,
    "Rank": 10621,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6075,
    "Rank": 10623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6076,
    "Rank": 10624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6077,
    "Rank": 10627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6078,
    "Rank": 10635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6079,
    "Rank": 10636,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6080,
    "Rank": 10638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6081,
    "Rank": 10639,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6082,
    "Rank": 10642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6083,
    "Rank": 10644,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6084,
    "Rank": 10649,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6085,
    "Rank": 10651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6086,
    "Rank": 10663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6087,
    "Rank": 10665,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6088,
    "Rank": 10670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6089,
    "Rank": 10673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6090,
    "Rank": 10675,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6091,
    "Rank": 10676,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6092,
    "Rank": 10677,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6093,
    "Rank": 10679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6094,
    "Rank": 10685,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6095,
    "Rank": 10690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6096,
    "Rank": 10693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6097,
    "Rank": 10697,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6098,
    "Rank": 10698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6099,
    "Rank": 10707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6100,
    "Rank": 10713,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6101,
    "Rank": 10714,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6102,
    "Rank": 10720,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6103,
    "Rank": 10721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6104,
    "Rank": 10723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6105,
    "Rank": 10730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6106,
    "Rank": 10732,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6107,
    "Rank": 10733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6108,
    "Rank": 10735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6109,
    "Rank": 10737,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6110,
    "Rank": 10743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6111,
    "Rank": 10745,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6112,
    "Rank": 10747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6113,
    "Rank": 10750,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6114,
    "Rank": 10757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6115,
    "Rank": 10759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6116,
    "Rank": 10761,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6117,
    "Rank": 10763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6118,
    "Rank": 10764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6119,
    "Rank": 10765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6120,
    "Rank": 10766,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6121,
    "Rank": 10769,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6122,
    "Rank": 10772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6123,
    "Rank": 10773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6124,
    "Rank": 10774,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6125,
    "Rank": 10781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6126,
    "Rank": 10783,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6127,
    "Rank": 10786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6128,
    "Rank": 10788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6129,
    "Rank": 10792,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6130,
    "Rank": 10793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6131,
    "Rank": 10794,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6132,
    "Rank": 10798,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6133,
    "Rank": 10801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6134,
    "Rank": 10802,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6135,
    "Rank": 10807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6136,
    "Rank": 10815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6137,
    "Rank": 10827,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6138,
    "Rank": 10832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6139,
    "Rank": 10833,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6140,
    "Rank": 10834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6141,
    "Rank": 10836,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6142,
    "Rank": 10839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6143,
    "Rank": 10840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6144,
    "Rank": 10845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6145,
    "Rank": 10846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6146,
    "Rank": 10848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6147,
    "Rank": 10849,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6148,
    "Rank": 10855,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6149,
    "Rank": 10858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6150,
    "Rank": 10860,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6151,
    "Rank": 10862,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6152,
    "Rank": 10863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6153,
    "Rank": 10864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6154,
    "Rank": 10866,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6155,
    "Rank": 10867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6156,
    "Rank": 10876,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6157,
    "Rank": 10878,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6158,
    "Rank": 10883,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6159,
    "Rank": 10885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6160,
    "Rank": 10888,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6161,
    "Rank": 10889,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6162,
    "Rank": 10891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6163,
    "Rank": 10892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6164,
    "Rank": 10894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6165,
    "Rank": 10895,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6166,
    "Rank": 10896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6167,
    "Rank": 10898,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6168,
    "Rank": 10902,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6169,
    "Rank": 10903,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6170,
    "Rank": 10907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6171,
    "Rank": 10912,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6172,
    "Rank": 10915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6173,
    "Rank": 10916,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6174,
    "Rank": 10918,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6175,
    "Rank": 10919,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6176,
    "Rank": 10926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6177,
    "Rank": 10931,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6178,
    "Rank": 10933,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6179,
    "Rank": 10934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6180,
    "Rank": 10936,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6181,
    "Rank": 10937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6182,
    "Rank": 10938,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6183,
    "Rank": 10940,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6184,
    "Rank": 10942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6185,
    "Rank": 10944,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6186,
    "Rank": 10948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6187,
    "Rank": 10949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6188,
    "Rank": 10950,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6189,
    "Rank": 10951,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6190,
    "Rank": 10957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6191,
    "Rank": 10958,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6192,
    "Rank": 10964,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6193,
    "Rank": 10967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6194,
    "Rank": 10968,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6195,
    "Rank": 10969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6196,
    "Rank": 10970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6197,
    "Rank": 10975,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6198,
    "Rank": 10976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6199,
    "Rank": 10978,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6200,
    "Rank": 10979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6201,
    "Rank": 10986,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6202,
    "Rank": 10992,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6203,
    "Rank": 10993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6204,
    "Rank": 11005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6205,
    "Rank": 11008,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6206,
    "Rank": 11009,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6207,
    "Rank": 11011,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6208,
    "Rank": 11012,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6209,
    "Rank": 11018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6210,
    "Rank": 11020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6211,
    "Rank": 11022,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6212,
    "Rank": 11023,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6213,
    "Rank": 11028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6214,
    "Rank": 11031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6215,
    "Rank": 11037,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6216,
    "Rank": 11042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6217,
    "Rank": 11050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6218,
    "Rank": 11053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6219,
    "Rank": 11061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6220,
    "Rank": 11062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6221,
    "Rank": 11064,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6222,
    "Rank": 11065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6223,
    "Rank": 11069,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6224,
    "Rank": 11070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6225,
    "Rank": 11072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6226,
    "Rank": 11074,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6227,
    "Rank": 11076,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6228,
    "Rank": 11077,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6229,
    "Rank": 11080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6230,
    "Rank": 11082,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6231,
    "Rank": 11083,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6232,
    "Rank": 11091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6233,
    "Rank": 11093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6234,
    "Rank": 11098,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6235,
    "Rank": 11104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6236,
    "Rank": 11109,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6237,
    "Rank": 11110,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6238,
    "Rank": 11127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6239,
    "Rank": 11130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6240,
    "Rank": 11139,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6241,
    "Rank": 11141,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6242,
    "Rank": 11144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6243,
    "Rank": 11150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6244,
    "Rank": 11151,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6245,
    "Rank": 11154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6246,
    "Rank": 11156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6247,
    "Rank": 11157,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6248,
    "Rank": 11163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6249,
    "Rank": 11164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6250,
    "Rank": 11166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6251,
    "Rank": 11168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6252,
    "Rank": 11171,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6253,
    "Rank": 11172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6254,
    "Rank": 11178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6255,
    "Rank": 11179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6256,
    "Rank": 11182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6257,
    "Rank": 11186,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6258,
    "Rank": 11187,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6259,
    "Rank": 11195,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6260,
    "Rank": 11196,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6261,
    "Rank": 11200,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6262,
    "Rank": 11201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6263,
    "Rank": 11202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6264,
    "Rank": 11207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6265,
    "Rank": 11208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6266,
    "Rank": 11209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6267,
    "Rank": 11211,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6268,
    "Rank": 11216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6269,
    "Rank": 11221,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6270,
    "Rank": 11229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6271,
    "Rank": 11231,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6272,
    "Rank": 11233,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6273,
    "Rank": 11234,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6274,
    "Rank": 11235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6275,
    "Rank": 11240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6276,
    "Rank": 11242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6277,
    "Rank": 11244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6278,
    "Rank": 11248,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6279,
    "Rank": 11251,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6280,
    "Rank": 11253,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6281,
    "Rank": 11255,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6282,
    "Rank": 11256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6283,
    "Rank": 11257,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6284,
    "Rank": 11259,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6285,
    "Rank": 11264,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6286,
    "Rank": 11269,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6287,
    "Rank": 11274,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6288,
    "Rank": 11278,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6289,
    "Rank": 11282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6290,
    "Rank": 11284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6291,
    "Rank": 11293,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6292,
    "Rank": 11300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6293,
    "Rank": 11301,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6294,
    "Rank": 11305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6295,
    "Rank": 11315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6296,
    "Rank": 11318,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6297,
    "Rank": 11327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6298,
    "Rank": 11329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6299,
    "Rank": 11330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6300,
    "Rank": 11333,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6301,
    "Rank": 11335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6302,
    "Rank": 11338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6303,
    "Rank": 11340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6304,
    "Rank": 11343,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6305,
    "Rank": 11344,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6306,
    "Rank": 11347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6307,
    "Rank": 11351,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6308,
    "Rank": 11356,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6309,
    "Rank": 11359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6310,
    "Rank": 11361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6311,
    "Rank": 11365,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6312,
    "Rank": 11367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6313,
    "Rank": 11371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6314,
    "Rank": 11376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6315,
    "Rank": 11377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6316,
    "Rank": 11382,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6317,
    "Rank": 11383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6318,
    "Rank": 11385,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6319,
    "Rank": 11386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6320,
    "Rank": 11387,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6321,
    "Rank": 11393,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6322,
    "Rank": 11400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6323,
    "Rank": 11404,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6324,
    "Rank": 11411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6325,
    "Rank": 11412,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6326,
    "Rank": 11414,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6327,
    "Rank": 11419,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6328,
    "Rank": 11420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6329,
    "Rank": 11425,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6330,
    "Rank": 11427,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6331,
    "Rank": 11428,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6332,
    "Rank": 11431,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6333,
    "Rank": 11432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6334,
    "Rank": 11433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6335,
    "Rank": 11435,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6336,
    "Rank": 11440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6337,
    "Rank": 11441,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6338,
    "Rank": 11450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6339,
    "Rank": 11452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6340,
    "Rank": 11453,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6341,
    "Rank": 11456,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6342,
    "Rank": 11459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6343,
    "Rank": 11461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6344,
    "Rank": 11462,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6345,
    "Rank": 11465,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6346,
    "Rank": 11466,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6347,
    "Rank": 11467,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6348,
    "Rank": 11471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6349,
    "Rank": 11476,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6350,
    "Rank": 11479,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6351,
    "Rank": 11481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6352,
    "Rank": 11484,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6353,
    "Rank": 11485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6354,
    "Rank": 11486,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6355,
    "Rank": 11487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6356,
    "Rank": 11488,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6357,
    "Rank": 11489,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6358,
    "Rank": 11490,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6359,
    "Rank": 11491,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6360,
    "Rank": 11492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6361,
    "Rank": 11495,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6362,
    "Rank": 11501,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6363,
    "Rank": 11503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6364,
    "Rank": 11505,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6365,
    "Rank": 11506,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6366,
    "Rank": 11507,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6367,
    "Rank": 11510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6368,
    "Rank": 11514,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6369,
    "Rank": 11521,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6370,
    "Rank": 11525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6371,
    "Rank": 11527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6372,
    "Rank": 11528,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6373,
    "Rank": 11529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6374,
    "Rank": 11530,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6375,
    "Rank": 11533,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6376,
    "Rank": 11534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6377,
    "Rank": 11544,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6378,
    "Rank": 11549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6379,
    "Rank": 11552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6380,
    "Rank": 11555,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6381,
    "Rank": 11557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6382,
    "Rank": 11558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6383,
    "Rank": 11560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6384,
    "Rank": 11562,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6385,
    "Rank": 11566,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6386,
    "Rank": 11567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6387,
    "Rank": 11570,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6388,
    "Rank": 11571,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6389,
    "Rank": 11575,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 50 )"
  },
  {
    "SNo": 6390,
    "Rank": 11576,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6391,
    "Rank": 11577,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6392,
    "Rank": 11579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6393,
    "Rank": 11580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6394,
    "Rank": 11581,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6395,
    "Rank": 11584,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6396,
    "Rank": 11586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6397,
    "Rank": 11588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6398,
    "Rank": 11592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6399,
    "Rank": 11593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6400,
    "Rank": 11596,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6401,
    "Rank": 11598,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6402,
    "Rank": 11599,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6403,
    "Rank": 11603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6404,
    "Rank": 11604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6405,
    "Rank": 11605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6406,
    "Rank": 11611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6407,
    "Rank": 11613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6408,
    "Rank": 11615,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6409,
    "Rank": 11624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6410,
    "Rank": 11626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6411,
    "Rank": 11630,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6412,
    "Rank": 11633,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6413,
    "Rank": 11634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6414,
    "Rank": 11643,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6415,
    "Rank": 11644,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6416,
    "Rank": 11646,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6417,
    "Rank": 11648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6418,
    "Rank": 11650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6419,
    "Rank": 11653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6420,
    "Rank": 11656,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6421,
    "Rank": 11657,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6422,
    "Rank": 11660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6423,
    "Rank": 11661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6424,
    "Rank": 11665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6425,
    "Rank": 11667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6426,
    "Rank": 11668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6427,
    "Rank": 11675,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6428,
    "Rank": 11679,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6429,
    "Rank": 11682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6430,
    "Rank": 11683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6431,
    "Rank": 11688,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6432,
    "Rank": 11689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6433,
    "Rank": 11690,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6434,
    "Rank": 11695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6435,
    "Rank": 11697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6436,
    "Rank": 11698,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6437,
    "Rank": 11701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6438,
    "Rank": 11702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6439,
    "Rank": 11703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6440,
    "Rank": 11704,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6441,
    "Rank": 11706,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6442,
    "Rank": 11715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6443,
    "Rank": 11716,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6444,
    "Rank": 11718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6445,
    "Rank": 11719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6446,
    "Rank": 11720,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6447,
    "Rank": 11721,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6448,
    "Rank": 11725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6449,
    "Rank": 11727,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6450,
    "Rank": 11730,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6451,
    "Rank": 11734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6452,
    "Rank": 11736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6453,
    "Rank": 11744,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6454,
    "Rank": 11745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6455,
    "Rank": 11748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6456,
    "Rank": 11749,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6457,
    "Rank": 11754,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6458,
    "Rank": 11755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6459,
    "Rank": 11757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6460,
    "Rank": 11763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6461,
    "Rank": 11766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6462,
    "Rank": 11768,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6463,
    "Rank": 11769,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6464,
    "Rank": 11771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6465,
    "Rank": 11773,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6466,
    "Rank": 11775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6467,
    "Rank": 11776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6468,
    "Rank": 11777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6469,
    "Rank": 11778,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6470,
    "Rank": 11779,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6471,
    "Rank": 11783,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6472,
    "Rank": 11793,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6473,
    "Rank": 11794,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6474,
    "Rank": 11795,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6475,
    "Rank": 11797,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6476,
    "Rank": 11799,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6477,
    "Rank": 11800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6478,
    "Rank": 11803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6479,
    "Rank": 11806,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6480,
    "Rank": 11809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6481,
    "Rank": 11810,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6482,
    "Rank": 11813,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6483,
    "Rank": 11816,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6484,
    "Rank": 11817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6485,
    "Rank": 11819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6486,
    "Rank": 11821,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6487,
    "Rank": 11822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6488,
    "Rank": 11832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6489,
    "Rank": 11838,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6490,
    "Rank": 11840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6491,
    "Rank": 11844,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6492,
    "Rank": 11858,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6493,
    "Rank": 11861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6494,
    "Rank": 11865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6495,
    "Rank": 11866,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6496,
    "Rank": 11868,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6497,
    "Rank": 11870,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6498,
    "Rank": 11871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6499,
    "Rank": 11873,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6500,
    "Rank": 11875,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6501,
    "Rank": 11878,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6502,
    "Rank": 11879,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6503,
    "Rank": 11881,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6504,
    "Rank": 11883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6505,
    "Rank": 11884,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6506,
    "Rank": 11885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6507,
    "Rank": 11887,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6508,
    "Rank": 11890,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6509,
    "Rank": 11893,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6510,
    "Rank": 11894,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6511,
    "Rank": 11896,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6512,
    "Rank": 11897,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6513,
    "Rank": 11898,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6514,
    "Rank": 11901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6515,
    "Rank": 11904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6516,
    "Rank": 11906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6517,
    "Rank": 11910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6518,
    "Rank": 11914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6519,
    "Rank": 11915,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6520,
    "Rank": 11918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6521,
    "Rank": 11919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6522,
    "Rank": 11922,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6523,
    "Rank": 11925,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6524,
    "Rank": 11926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6525,
    "Rank": 11928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6526,
    "Rank": 11930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6527,
    "Rank": 11931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6528,
    "Rank": 11937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6529,
    "Rank": 11938,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6530,
    "Rank": 11941,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6531,
    "Rank": 11944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6532,
    "Rank": 11949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6533,
    "Rank": 11950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6534,
    "Rank": 11958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6535,
    "Rank": 11961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6536,
    "Rank": 11962,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6537,
    "Rank": 11970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6538,
    "Rank": 11971,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6539,
    "Rank": 11973,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6540,
    "Rank": 11982,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6541,
    "Rank": 11987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6542,
    "Rank": 11990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6543,
    "Rank": 11996,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6544,
    "Rank": 12000,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6545,
    "Rank": 12002,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6546,
    "Rank": 12003,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6547,
    "Rank": 12004,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6548,
    "Rank": 12010,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6549,
    "Rank": 12014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6550,
    "Rank": 12022,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6551,
    "Rank": 12023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6552,
    "Rank": 12024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6553,
    "Rank": 12025,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6554,
    "Rank": 12028,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6555,
    "Rank": 12032,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6556,
    "Rank": 12033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6557,
    "Rank": 12036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6558,
    "Rank": 12043,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6559,
    "Rank": 12044,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6560,
    "Rank": 12045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6561,
    "Rank": 12050,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6562,
    "Rank": 12051,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6563,
    "Rank": 12053,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6564,
    "Rank": 12054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6565,
    "Rank": 12057,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6566,
    "Rank": 12062,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6567,
    "Rank": 12064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6568,
    "Rank": 12065,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6569,
    "Rank": 12067,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6570,
    "Rank": 12068,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6571,
    "Rank": 12071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6572,
    "Rank": 12072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6573,
    "Rank": 12074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6574,
    "Rank": 12075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6575,
    "Rank": 12077,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6576,
    "Rank": 12078,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6577,
    "Rank": 12079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6578,
    "Rank": 12084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6579,
    "Rank": 12088,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6580,
    "Rank": 12089,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6581,
    "Rank": 12093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6582,
    "Rank": 12095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6583,
    "Rank": 12096,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6584,
    "Rank": 12097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6585,
    "Rank": 12100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6586,
    "Rank": 12105,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6587,
    "Rank": 12107,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6588,
    "Rank": 12109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6589,
    "Rank": 12111,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6590,
    "Rank": 12112,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6591,
    "Rank": 12115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6592,
    "Rank": 12116,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6593,
    "Rank": 12118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6594,
    "Rank": 12121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6595,
    "Rank": 12122,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6596,
    "Rank": 12123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6597,
    "Rank": 12126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6598,
    "Rank": 12127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6599,
    "Rank": 12128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6600,
    "Rank": 12129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6601,
    "Rank": 12135,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6602,
    "Rank": 12139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6603,
    "Rank": 12140,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6604,
    "Rank": 12142,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6605,
    "Rank": 12143,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6606,
    "Rank": 12148,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6607,
    "Rank": 12150,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6608,
    "Rank": 12156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6609,
    "Rank": 12157,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6610,
    "Rank": 12160,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6611,
    "Rank": 12165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6612,
    "Rank": 12169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6613,
    "Rank": 12170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6614,
    "Rank": 12174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6615,
    "Rank": 12176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6616,
    "Rank": 12178,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6617,
    "Rank": 12181,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6618,
    "Rank": 12183,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6619,
    "Rank": 12184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6620,
    "Rank": 12186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6621,
    "Rank": 12188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6622,
    "Rank": 12192,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6623,
    "Rank": 12196,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6624,
    "Rank": 12197,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6625,
    "Rank": 12198,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6626,
    "Rank": 12207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6627,
    "Rank": 12208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6628,
    "Rank": 12212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6629,
    "Rank": 12213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6630,
    "Rank": 12217,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6631,
    "Rank": 12222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6632,
    "Rank": 12223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6633,
    "Rank": 12225,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6634,
    "Rank": 12227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6635,
    "Rank": 12228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6636,
    "Rank": 12229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6637,
    "Rank": 12232,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6638,
    "Rank": 12240,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6639,
    "Rank": 12241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6640,
    "Rank": 12244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6641,
    "Rank": 12246,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6642,
    "Rank": 12247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6643,
    "Rank": 12252,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6644,
    "Rank": 12257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6645,
    "Rank": 12260,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6646,
    "Rank": 12262,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6647,
    "Rank": 12269,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6648,
    "Rank": 12271,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6649,
    "Rank": 12272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6650,
    "Rank": 12274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6651,
    "Rank": 12275,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6652,
    "Rank": 12276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6653,
    "Rank": 12278,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6654,
    "Rank": 12281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6655,
    "Rank": 12283,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6656,
    "Rank": 12285,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6657,
    "Rank": 12286,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6658,
    "Rank": 12287,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6659,
    "Rank": 12292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6660,
    "Rank": 12294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6661,
    "Rank": 12295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6662,
    "Rank": 12297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6663,
    "Rank": 12298,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6664,
    "Rank": 12299,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6665,
    "Rank": 12300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6666,
    "Rank": 12301,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6667,
    "Rank": 12310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6668,
    "Rank": 12316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6669,
    "Rank": 12318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6670,
    "Rank": 12319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6671,
    "Rank": 12321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6672,
    "Rank": 12322,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6673,
    "Rank": 12328,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6674,
    "Rank": 12329,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6675,
    "Rank": 12331,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6676,
    "Rank": 12339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6677,
    "Rank": 12342,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6678,
    "Rank": 12345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6679,
    "Rank": 12346,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6680,
    "Rank": 12347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6681,
    "Rank": 12354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6682,
    "Rank": 12357,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6683,
    "Rank": 12361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6684,
    "Rank": 12363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6685,
    "Rank": 12365,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6686,
    "Rank": 12367,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6687,
    "Rank": 12368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6688,
    "Rank": 12370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6689,
    "Rank": 12372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6690,
    "Rank": 12379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6691,
    "Rank": 12381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6692,
    "Rank": 12383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6693,
    "Rank": 12387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6694,
    "Rank": 12388,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6695,
    "Rank": 12393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6696,
    "Rank": 12394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6697,
    "Rank": 12395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6698,
    "Rank": 12397,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6699,
    "Rank": 12404,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6700,
    "Rank": 12406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6701,
    "Rank": 12411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6702,
    "Rank": 12412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6703,
    "Rank": 12418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6704,
    "Rank": 12419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6705,
    "Rank": 12420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6706,
    "Rank": 12421,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6707,
    "Rank": 12430,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6708,
    "Rank": 12432,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6709,
    "Rank": 12433,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6710,
    "Rank": 12438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6711,
    "Rank": 12441,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6712,
    "Rank": 12442,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6713,
    "Rank": 12444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6714,
    "Rank": 12445,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6715,
    "Rank": 12449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6716,
    "Rank": 12454,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6717,
    "Rank": 12458,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6718,
    "Rank": 12459,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6719,
    "Rank": 12461,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6720,
    "Rank": 12463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6721,
    "Rank": 12464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6722,
    "Rank": 12465,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6723,
    "Rank": 12466,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6724,
    "Rank": 12469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6725,
    "Rank": 12473,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6726,
    "Rank": 12475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6727,
    "Rank": 12480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6728,
    "Rank": 12482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6729,
    "Rank": 12486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6730,
    "Rank": 12487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6731,
    "Rank": 12492,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6732,
    "Rank": 12493,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6733,
    "Rank": 12496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6734,
    "Rank": 12497,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6735,
    "Rank": 12499,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6736,
    "Rank": 12505,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6737,
    "Rank": 12509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6738,
    "Rank": 12513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6739,
    "Rank": 12514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6740,
    "Rank": 12516,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6741,
    "Rank": 12519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6742,
    "Rank": 12525,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6743,
    "Rank": 12526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6744,
    "Rank": 12529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6745,
    "Rank": 12533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6746,
    "Rank": 12534,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6747,
    "Rank": 12540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6748,
    "Rank": 12544,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6749,
    "Rank": 12547,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6750,
    "Rank": 12559,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6751,
    "Rank": 12561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6752,
    "Rank": 12564,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6753,
    "Rank": 12565,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6754,
    "Rank": 12570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6755,
    "Rank": 12571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6756,
    "Rank": 12574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6757,
    "Rank": 12579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6758,
    "Rank": 12581,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6759,
    "Rank": 12587,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6760,
    "Rank": 12588,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6761,
    "Rank": 12593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6762,
    "Rank": 12594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6763,
    "Rank": 12598,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6764,
    "Rank": 12601,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6765,
    "Rank": 12603,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6766,
    "Rank": 12605,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6767,
    "Rank": 12606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6768,
    "Rank": 12608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6769,
    "Rank": 12609,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6770,
    "Rank": 12610,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6771,
    "Rank": 12612,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6772,
    "Rank": 12613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6773,
    "Rank": 12622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6774,
    "Rank": 12623,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6775,
    "Rank": 12627,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6776,
    "Rank": 12629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6777,
    "Rank": 12632,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6778,
    "Rank": 12633,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6779,
    "Rank": 12636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6780,
    "Rank": 12638,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6781,
    "Rank": 12640,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6782,
    "Rank": 12641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6783,
    "Rank": 12647,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6784,
    "Rank": 12648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6785,
    "Rank": 12655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6786,
    "Rank": 12656,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6787,
    "Rank": 12658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6788,
    "Rank": 12660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6789,
    "Rank": 12661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6790,
    "Rank": 12664,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6791,
    "Rank": 12665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6792,
    "Rank": 12666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6793,
    "Rank": 12669,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6794,
    "Rank": 12670,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6795,
    "Rank": 12672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6796,
    "Rank": 12674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6797,
    "Rank": 12679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6798,
    "Rank": 12681,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6799,
    "Rank": 12682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6800,
    "Rank": 12684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6801,
    "Rank": 12690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6802,
    "Rank": 12692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6803,
    "Rank": 12694,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6804,
    "Rank": 12696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6805,
    "Rank": 12697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6806,
    "Rank": 12701,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6807,
    "Rank": 12703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6808,
    "Rank": 12706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6809,
    "Rank": 12707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6810,
    "Rank": 12713,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6811,
    "Rank": 12716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6812,
    "Rank": 12718,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6813,
    "Rank": 12720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6814,
    "Rank": 12721,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6815,
    "Rank": 12722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6816,
    "Rank": 12723,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6817,
    "Rank": 12726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6818,
    "Rank": 12728,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6819,
    "Rank": 12737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6820,
    "Rank": 12741,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6821,
    "Rank": 12745,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6822,
    "Rank": 12747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6823,
    "Rank": 12748,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6824,
    "Rank": 12749,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6825,
    "Rank": 12753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6826,
    "Rank": 12758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6827,
    "Rank": 12759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6828,
    "Rank": 12761,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6829,
    "Rank": 12763,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6830,
    "Rank": 12764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6831,
    "Rank": 12765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6832,
    "Rank": 12768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6833,
    "Rank": 12770,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6834,
    "Rank": 12772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6835,
    "Rank": 12773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6836,
    "Rank": 12775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6837,
    "Rank": 12778,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6838,
    "Rank": 12781,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6839,
    "Rank": 12783,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6840,
    "Rank": 12788,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6841,
    "Rank": 12792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6842,
    "Rank": 12794,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6843,
    "Rank": 12798,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6844,
    "Rank": 12799,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6845,
    "Rank": 12800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6846,
    "Rank": 12801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6847,
    "Rank": 12810,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6848,
    "Rank": 12811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6849,
    "Rank": 12815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6850,
    "Rank": 12816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6851,
    "Rank": 12817,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6852,
    "Rank": 12822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6853,
    "Rank": 12823,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6854,
    "Rank": 12825,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6855,
    "Rank": 12832,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6856,
    "Rank": 12834,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6857,
    "Rank": 12841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6858,
    "Rank": 12844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6859,
    "Rank": 12853,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6860,
    "Rank": 12857,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6861,
    "Rank": 12862,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6862,
    "Rank": 12863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6863,
    "Rank": 12864,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6864,
    "Rank": 12866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6865,
    "Rank": 12872,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6866,
    "Rank": 12878,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6867,
    "Rank": 12881,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6868,
    "Rank": 12882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6869,
    "Rank": 12889,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6870,
    "Rank": 12893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6871,
    "Rank": 12895,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6872,
    "Rank": 12896,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6873,
    "Rank": 12903,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6874,
    "Rank": 12904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6875,
    "Rank": 12907,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6876,
    "Rank": 12912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6877,
    "Rank": 12920,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6878,
    "Rank": 12923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6879,
    "Rank": 12926,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6880,
    "Rank": 12928,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6881,
    "Rank": 12930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6882,
    "Rank": 12934,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6883,
    "Rank": 12939,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6884,
    "Rank": 12940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6885,
    "Rank": 12941,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6886,
    "Rank": 12942,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6887,
    "Rank": 12945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6888,
    "Rank": 12948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6889,
    "Rank": 12951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6890,
    "Rank": 12953,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6891,
    "Rank": 12960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6892,
    "Rank": 12967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6893,
    "Rank": 12975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6894,
    "Rank": 12978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6895,
    "Rank": 12983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6896,
    "Rank": 12986,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6897,
    "Rank": 12987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6898,
    "Rank": 12991,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6899,
    "Rank": 13003,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6900,
    "Rank": 13004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6901,
    "Rank": 13009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6902,
    "Rank": 13012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6903,
    "Rank": 13014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6904,
    "Rank": 13016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6905,
    "Rank": 13019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6906,
    "Rank": 13022,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6907,
    "Rank": 13027,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6908,
    "Rank": 13028,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6909,
    "Rank": 13031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6910,
    "Rank": 13033,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6911,
    "Rank": 13034,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6912,
    "Rank": 13038,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6913,
    "Rank": 13041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6914,
    "Rank": 13043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6915,
    "Rank": 13044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6916,
    "Rank": 13048,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6917,
    "Rank": 13059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6918,
    "Rank": 13060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6919,
    "Rank": 13061,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6920,
    "Rank": 13062,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6921,
    "Rank": 13068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6922,
    "Rank": 13070,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6923,
    "Rank": 13075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6924,
    "Rank": 13077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6925,
    "Rank": 13079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6926,
    "Rank": 13080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6927,
    "Rank": 13088,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6928,
    "Rank": 13091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6929,
    "Rank": 13092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6930,
    "Rank": 13097,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6931,
    "Rank": 13098,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6932,
    "Rank": 13099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6933,
    "Rank": 13100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6934,
    "Rank": 13101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6935,
    "Rank": 13103,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6936,
    "Rank": 13104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6937,
    "Rank": 13107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6938,
    "Rank": 13108,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6939,
    "Rank": 13111,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6940,
    "Rank": 13113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6941,
    "Rank": 13114,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6942,
    "Rank": 13115,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6943,
    "Rank": 13116,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6944,
    "Rank": 13125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6945,
    "Rank": 13132,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6946,
    "Rank": 13133,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6947,
    "Rank": 13136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6948,
    "Rank": 13146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6949,
    "Rank": 13148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6950,
    "Rank": 13149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6951,
    "Rank": 13155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6952,
    "Rank": 13158,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6953,
    "Rank": 13160,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6954,
    "Rank": 13167,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6955,
    "Rank": 13173,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6956,
    "Rank": 13175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6957,
    "Rank": 13177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6958,
    "Rank": 13179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6959,
    "Rank": 13180,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6960,
    "Rank": 13182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6961,
    "Rank": 13183,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6962,
    "Rank": 13184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6963,
    "Rank": 13186,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6964,
    "Rank": 13187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6965,
    "Rank": 13188,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6966,
    "Rank": 13191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6967,
    "Rank": 13194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6968,
    "Rank": 13203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6969,
    "Rank": 13207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6970,
    "Rank": 13208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6971,
    "Rank": 13211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6972,
    "Rank": 13212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6973,
    "Rank": 13216,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6974,
    "Rank": 13217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6975,
    "Rank": 13222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6976,
    "Rank": 13223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6977,
    "Rank": 13224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6978,
    "Rank": 13225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6979,
    "Rank": 13226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6980,
    "Rank": 13227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6981,
    "Rank": 13231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6982,
    "Rank": 13236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6983,
    "Rank": 13237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6984,
    "Rank": 13238,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6985,
    "Rank": 13240,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6986,
    "Rank": 13241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6987,
    "Rank": 13242,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6988,
    "Rank": 13244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6989,
    "Rank": 13245,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6990,
    "Rank": 13246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6991,
    "Rank": 13248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6992,
    "Rank": 13250,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6993,
    "Rank": 13253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6994,
    "Rank": 13255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6995,
    "Rank": 13258,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6996,
    "Rank": 13264,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6997,
    "Rank": 13265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6998,
    "Rank": 13273,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 6999,
    "Rank": 13274,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7000,
    "Rank": 13275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7001,
    "Rank": 13278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7002,
    "Rank": 13280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7003,
    "Rank": 13294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7004,
    "Rank": 13299,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7005,
    "Rank": 13304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7006,
    "Rank": 13308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7007,
    "Rank": 13309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7008,
    "Rank": 13311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7009,
    "Rank": 13316,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7010,
    "Rank": 13319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7011,
    "Rank": 13322,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7012,
    "Rank": 13326,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7013,
    "Rank": 13327,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7014,
    "Rank": 13331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7015,
    "Rank": 13337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7016,
    "Rank": 13338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7017,
    "Rank": 13339,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7018,
    "Rank": 13346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7019,
    "Rank": 13348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7020,
    "Rank": 13351,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7021,
    "Rank": 13355,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7022,
    "Rank": 13356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7023,
    "Rank": 13357,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7024,
    "Rank": 13358,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7025,
    "Rank": 13359,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7026,
    "Rank": 13360,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7027,
    "Rank": 13361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7028,
    "Rank": 13362,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7029,
    "Rank": 13363,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7030,
    "Rank": 13366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7031,
    "Rank": 13371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7032,
    "Rank": 13372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7033,
    "Rank": 13374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7034,
    "Rank": 13376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7035,
    "Rank": 13377,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7036,
    "Rank": 13380,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7037,
    "Rank": 13382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7038,
    "Rank": 13383,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7039,
    "Rank": 13384,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7040,
    "Rank": 13386,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7041,
    "Rank": 13387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7042,
    "Rank": 13388,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7043,
    "Rank": 13394,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7044,
    "Rank": 13398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7045,
    "Rank": 13399,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7046,
    "Rank": 13407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7047,
    "Rank": 13414,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7048,
    "Rank": 13415,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7049,
    "Rank": 13419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7050,
    "Rank": 13422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7051,
    "Rank": 13423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7052,
    "Rank": 13427,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7053,
    "Rank": 13431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7054,
    "Rank": 13433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7055,
    "Rank": 13436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7056,
    "Rank": 13437,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7057,
    "Rank": 13438,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7058,
    "Rank": 13441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7059,
    "Rank": 13443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7060,
    "Rank": 13445,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7061,
    "Rank": 13447,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7062,
    "Rank": 13452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7063,
    "Rank": 13453,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7064,
    "Rank": 13454,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7065,
    "Rank": 13455,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7066,
    "Rank": 13457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7067,
    "Rank": 13458,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7068,
    "Rank": 13461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7069,
    "Rank": 13467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7070,
    "Rank": 13468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7071,
    "Rank": 13469,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7072,
    "Rank": 13474,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7073,
    "Rank": 13478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7074,
    "Rank": 13480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7075,
    "Rank": 13481,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7076,
    "Rank": 13483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7077,
    "Rank": 13486,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7078,
    "Rank": 13492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7079,
    "Rank": 13493,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7080,
    "Rank": 13499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7081,
    "Rank": 13503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7082,
    "Rank": 13505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7083,
    "Rank": 13511,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7084,
    "Rank": 13512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7085,
    "Rank": 13513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7086,
    "Rank": 13514,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7087,
    "Rank": 13517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7088,
    "Rank": 13518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7089,
    "Rank": 13522,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7090,
    "Rank": 13525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7091,
    "Rank": 13529,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7092,
    "Rank": 13537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7093,
    "Rank": 13539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7094,
    "Rank": 13540,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7095,
    "Rank": 13541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7096,
    "Rank": 13544,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7097,
    "Rank": 13548,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7098,
    "Rank": 13549,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7099,
    "Rank": 13552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7100,
    "Rank": 13554,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7101,
    "Rank": 13558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7102,
    "Rank": 13563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7103,
    "Rank": 13566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7104,
    "Rank": 13569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7105,
    "Rank": 13571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7106,
    "Rank": 13575,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7107,
    "Rank": 13578,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7108,
    "Rank": 13584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7109,
    "Rank": 13589,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7110,
    "Rank": 13594,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7111,
    "Rank": 13597,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7112,
    "Rank": 13598,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7113,
    "Rank": 13599,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7114,
    "Rank": 13601,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7115,
    "Rank": 13605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7116,
    "Rank": 13606,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7117,
    "Rank": 13608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7118,
    "Rank": 13611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7119,
    "Rank": 13613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7120,
    "Rank": 13616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7121,
    "Rank": 13618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7122,
    "Rank": 13621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7123,
    "Rank": 13623,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7124,
    "Rank": 13625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7125,
    "Rank": 13626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7126,
    "Rank": 13634,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7127,
    "Rank": 13635,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7128,
    "Rank": 13638,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7129,
    "Rank": 13640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7130,
    "Rank": 13641,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7131,
    "Rank": 13643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7132,
    "Rank": 13649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7133,
    "Rank": 13651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7134,
    "Rank": 13661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7135,
    "Rank": 13662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7136,
    "Rank": 13666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7137,
    "Rank": 13668,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7138,
    "Rank": 13671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7139,
    "Rank": 13674,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7140,
    "Rank": 13680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7141,
    "Rank": 13681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7142,
    "Rank": 13683,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7143,
    "Rank": 13684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7144,
    "Rank": 13685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7145,
    "Rank": 13689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7146,
    "Rank": 13691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7147,
    "Rank": 13692,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7148,
    "Rank": 13695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7149,
    "Rank": 13696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7150,
    "Rank": 13697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7151,
    "Rank": 13700,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 53 )"
  },
  {
    "SNo": 7152,
    "Rank": 13703,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7153,
    "Rank": 13706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7154,
    "Rank": 13707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7155,
    "Rank": 13708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7156,
    "Rank": 13713,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7157,
    "Rank": 13715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7158,
    "Rank": 13717,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7159,
    "Rank": 13719,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7160,
    "Rank": 13720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7161,
    "Rank": 13721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7162,
    "Rank": 13722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7163,
    "Rank": 13724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7164,
    "Rank": 13725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7165,
    "Rank": 13728,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7166,
    "Rank": 13730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7167,
    "Rank": 13732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7168,
    "Rank": 13733,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7169,
    "Rank": 13734,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7170,
    "Rank": 13737,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7171,
    "Rank": 13740,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7172,
    "Rank": 13742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7173,
    "Rank": 13743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7174,
    "Rank": 13744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7175,
    "Rank": 13749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7176,
    "Rank": 13756,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7177,
    "Rank": 13758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7178,
    "Rank": 13761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7179,
    "Rank": 13762,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7180,
    "Rank": 13763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7181,
    "Rank": 13769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7182,
    "Rank": 13775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7183,
    "Rank": 13776,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7184,
    "Rank": 13779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7185,
    "Rank": 13787,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7186,
    "Rank": 13788,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7187,
    "Rank": 13794,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7188,
    "Rank": 13795,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7189,
    "Rank": 13798,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7190,
    "Rank": 13803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7191,
    "Rank": 13818,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7192,
    "Rank": 13823,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7193,
    "Rank": 13826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7194,
    "Rank": 13827,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7195,
    "Rank": 13832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7196,
    "Rank": 13835,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7197,
    "Rank": 13836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7198,
    "Rank": 13841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7199,
    "Rank": 13845,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7200,
    "Rank": 13852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7201,
    "Rank": 13853,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7202,
    "Rank": 13856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7203,
    "Rank": 13859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7204,
    "Rank": 13866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7205,
    "Rank": 13873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7206,
    "Rank": 13874,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7207,
    "Rank": 13876,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7208,
    "Rank": 13882,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7209,
    "Rank": 13883,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7210,
    "Rank": 13886,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7211,
    "Rank": 13887,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7212,
    "Rank": 13894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7213,
    "Rank": 13896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7214,
    "Rank": 13900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7215,
    "Rank": 13904,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7216,
    "Rank": 13906,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7217,
    "Rank": 13914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7218,
    "Rank": 13921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7219,
    "Rank": 13923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7220,
    "Rank": 13927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7221,
    "Rank": 13931,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7222,
    "Rank": 13940,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7223,
    "Rank": 13946,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7224,
    "Rank": 13947,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7225,
    "Rank": 13950,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7226,
    "Rank": 13952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7227,
    "Rank": 13955,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7228,
    "Rank": 13958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7229,
    "Rank": 13961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7230,
    "Rank": 13962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7231,
    "Rank": 13963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7232,
    "Rank": 13965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7233,
    "Rank": 13966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7234,
    "Rank": 13967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7235,
    "Rank": 13971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7236,
    "Rank": 13972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7237,
    "Rank": 13973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7238,
    "Rank": 13976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7239,
    "Rank": 13978,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7240,
    "Rank": 13982,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7241,
    "Rank": 13987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7242,
    "Rank": 13993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7243,
    "Rank": 13994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7244,
    "Rank": 13997,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7245,
    "Rank": 14001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7246,
    "Rank": 14002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7247,
    "Rank": 14011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7248,
    "Rank": 14016,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7249,
    "Rank": 14019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7250,
    "Rank": 14035,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7251,
    "Rank": 14036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7252,
    "Rank": 14037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7253,
    "Rank": 14039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7254,
    "Rank": 14042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7255,
    "Rank": 14043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7256,
    "Rank": 14049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7257,
    "Rank": 14050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7258,
    "Rank": 14051,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7259,
    "Rank": 14056,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7260,
    "Rank": 14065,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7261,
    "Rank": 14067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7262,
    "Rank": 14070,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7263,
    "Rank": 14074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7264,
    "Rank": 14075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7265,
    "Rank": 14076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7266,
    "Rank": 14079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7267,
    "Rank": 14080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7268,
    "Rank": 14083,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7269,
    "Rank": 14087,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7270,
    "Rank": 14088,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7271,
    "Rank": 14089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7272,
    "Rank": 14091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7273,
    "Rank": 14096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7274,
    "Rank": 14103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7275,
    "Rank": 14107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7276,
    "Rank": 14108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7277,
    "Rank": 14111,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7278,
    "Rank": 14117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7279,
    "Rank": 14122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7280,
    "Rank": 14125,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7281,
    "Rank": 14127,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7282,
    "Rank": 14129,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7283,
    "Rank": 14131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7284,
    "Rank": 14132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7285,
    "Rank": 14134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7286,
    "Rank": 14135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7287,
    "Rank": 14136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7288,
    "Rank": 14141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7289,
    "Rank": 14142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7290,
    "Rank": 14145,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7291,
    "Rank": 14146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7292,
    "Rank": 14150,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7293,
    "Rank": 14151,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7294,
    "Rank": 14157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7295,
    "Rank": 14160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7296,
    "Rank": 14161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7297,
    "Rank": 14162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7298,
    "Rank": 14163,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7299,
    "Rank": 14164,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7300,
    "Rank": 14166,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7301,
    "Rank": 14178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7302,
    "Rank": 14183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7303,
    "Rank": 14186,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7304,
    "Rank": 14193,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7305,
    "Rank": 14194,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7306,
    "Rank": 14206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7307,
    "Rank": 14215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7308,
    "Rank": 14216,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7309,
    "Rank": 14218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7310,
    "Rank": 14220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7311,
    "Rank": 14223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7312,
    "Rank": 14229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7313,
    "Rank": 14230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7314,
    "Rank": 14232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7315,
    "Rank": 14237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7316,
    "Rank": 14238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7317,
    "Rank": 14239,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7318,
    "Rank": 14241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7319,
    "Rank": 14245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7320,
    "Rank": 14247,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7321,
    "Rank": 14249,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7322,
    "Rank": 14251,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7323,
    "Rank": 14253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7324,
    "Rank": 14254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7325,
    "Rank": 14258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7326,
    "Rank": 14264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7327,
    "Rank": 14265,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7328,
    "Rank": 14266,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7329,
    "Rank": 14267,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7330,
    "Rank": 14269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7331,
    "Rank": 14271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7332,
    "Rank": 14273,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7333,
    "Rank": 14274,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7334,
    "Rank": 14277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7335,
    "Rank": 14279,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7336,
    "Rank": 14282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7337,
    "Rank": 14283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7338,
    "Rank": 14290,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7339,
    "Rank": 14292,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7340,
    "Rank": 14297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7341,
    "Rank": 14298,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7342,
    "Rank": 14307,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7343,
    "Rank": 14310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7344,
    "Rank": 14313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7345,
    "Rank": 14316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7346,
    "Rank": 14317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7347,
    "Rank": 14320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7348,
    "Rank": 14323,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7349,
    "Rank": 14325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7350,
    "Rank": 14326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7351,
    "Rank": 14328,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7352,
    "Rank": 14330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7353,
    "Rank": 14334,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7354,
    "Rank": 14337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7355,
    "Rank": 14339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7356,
    "Rank": 14340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7357,
    "Rank": 14346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7358,
    "Rank": 14347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7359,
    "Rank": 14349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7360,
    "Rank": 14353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7361,
    "Rank": 14356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7362,
    "Rank": 14362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7363,
    "Rank": 14364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7364,
    "Rank": 14369,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7365,
    "Rank": 14381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7366,
    "Rank": 14382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7367,
    "Rank": 14387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7368,
    "Rank": 14389,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7369,
    "Rank": 14391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7370,
    "Rank": 14392,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7371,
    "Rank": 14393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7372,
    "Rank": 14397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7373,
    "Rank": 14399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7374,
    "Rank": 14401,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7375,
    "Rank": 14403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7376,
    "Rank": 14404,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7377,
    "Rank": 14408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7378,
    "Rank": 14413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7379,
    "Rank": 14418,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7380,
    "Rank": 14421,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7381,
    "Rank": 14423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7382,
    "Rank": 14430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7383,
    "Rank": 14431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7384,
    "Rank": 14436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7385,
    "Rank": 14437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7386,
    "Rank": 14439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7387,
    "Rank": 14441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7388,
    "Rank": 14445,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7389,
    "Rank": 14449,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7390,
    "Rank": 14450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7391,
    "Rank": 14452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7392,
    "Rank": 14456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7393,
    "Rank": 14461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7394,
    "Rank": 14463,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7395,
    "Rank": 14466,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7396,
    "Rank": 14469,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7397,
    "Rank": 14472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7398,
    "Rank": 14476,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7399,
    "Rank": 14480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7400,
    "Rank": 14481,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7401,
    "Rank": 14485,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7402,
    "Rank": 14487,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7403,
    "Rank": 14491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7404,
    "Rank": 14494,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7405,
    "Rank": 14496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7406,
    "Rank": 14499,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7407,
    "Rank": 14502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7408,
    "Rank": 14503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7409,
    "Rank": 14504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7410,
    "Rank": 14508,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7411,
    "Rank": 14509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7412,
    "Rank": 14510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7413,
    "Rank": 14512,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7414,
    "Rank": 14513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7415,
    "Rank": 14514,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7416,
    "Rank": 14517,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7417,
    "Rank": 14518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7418,
    "Rank": 14528,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7419,
    "Rank": 14530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7420,
    "Rank": 14531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7421,
    "Rank": 14533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7422,
    "Rank": 14537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7423,
    "Rank": 14541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7424,
    "Rank": 14542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7425,
    "Rank": 14546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7426,
    "Rank": 14548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7427,
    "Rank": 14555,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7428,
    "Rank": 14560,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7429,
    "Rank": 14562,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7430,
    "Rank": 14563,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7431,
    "Rank": 14566,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7432,
    "Rank": 14569,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7433,
    "Rank": 14571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7434,
    "Rank": 14574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7435,
    "Rank": 14576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7436,
    "Rank": 14577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7437,
    "Rank": 14579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7438,
    "Rank": 14581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7439,
    "Rank": 14582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7440,
    "Rank": 14584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7441,
    "Rank": 14586,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7442,
    "Rank": 14589,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7443,
    "Rank": 14590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7444,
    "Rank": 14591,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7445,
    "Rank": 14595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7446,
    "Rank": 14602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7447,
    "Rank": 14603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7448,
    "Rank": 14605,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7449,
    "Rank": 14608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7450,
    "Rank": 14609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7451,
    "Rank": 14612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7452,
    "Rank": 14613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7453,
    "Rank": 14615,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7454,
    "Rank": 14618,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7455,
    "Rank": 14620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7456,
    "Rank": 14624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7457,
    "Rank": 14631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7458,
    "Rank": 14632,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7459,
    "Rank": 14633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7460,
    "Rank": 14636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7461,
    "Rank": 14641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7462,
    "Rank": 14656,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7463,
    "Rank": 14659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7464,
    "Rank": 14660,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7465,
    "Rank": 14663,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7466,
    "Rank": 14665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7467,
    "Rank": 14668,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7468,
    "Rank": 14672,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7469,
    "Rank": 14674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7470,
    "Rank": 14677,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7471,
    "Rank": 14684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7472,
    "Rank": 14685,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7473,
    "Rank": 14688,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7474,
    "Rank": 14699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7475,
    "Rank": 14709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7476,
    "Rank": 14711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7477,
    "Rank": 14713,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7478,
    "Rank": 14714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7479,
    "Rank": 14718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7480,
    "Rank": 14721,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7481,
    "Rank": 14726,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7482,
    "Rank": 14727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7483,
    "Rank": 14728,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7484,
    "Rank": 14731,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7485,
    "Rank": 14735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7486,
    "Rank": 14736,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7487,
    "Rank": 14749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7488,
    "Rank": 14752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7489,
    "Rank": 14755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7490,
    "Rank": 14759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7491,
    "Rank": 14761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7492,
    "Rank": 14765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7493,
    "Rank": 14766,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7494,
    "Rank": 14774,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7495,
    "Rank": 14775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7496,
    "Rank": 14776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7497,
    "Rank": 14777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7498,
    "Rank": 14783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7499,
    "Rank": 14788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7500,
    "Rank": 14790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7501,
    "Rank": 14792,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7502,
    "Rank": 14794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7503,
    "Rank": 14795,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7504,
    "Rank": 14801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7505,
    "Rank": 14802,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7506,
    "Rank": 14803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7507,
    "Rank": 14813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7508,
    "Rank": 14815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7509,
    "Rank": 14817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7510,
    "Rank": 14820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7511,
    "Rank": 14821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7512,
    "Rank": 14823,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7513,
    "Rank": 14824,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7514,
    "Rank": 14825,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7515,
    "Rank": 14826,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7516,
    "Rank": 14832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7517,
    "Rank": 14834,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7518,
    "Rank": 14843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7519,
    "Rank": 14845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7520,
    "Rank": 14855,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7521,
    "Rank": 14856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7522,
    "Rank": 14857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7523,
    "Rank": 14866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7524,
    "Rank": 14872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7525,
    "Rank": 14882,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7526,
    "Rank": 14884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7527,
    "Rank": 14888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7528,
    "Rank": 14891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7529,
    "Rank": 14892,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7530,
    "Rank": 14894,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7531,
    "Rank": 14895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7532,
    "Rank": 14897,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7533,
    "Rank": 14901,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7534,
    "Rank": 14906,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7535,
    "Rank": 14907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7536,
    "Rank": 14908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7537,
    "Rank": 14909,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7538,
    "Rank": 14910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7539,
    "Rank": 14911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7540,
    "Rank": 14912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7541,
    "Rank": 14915,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7542,
    "Rank": 14919,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7543,
    "Rank": 14920,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7544,
    "Rank": 14923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7545,
    "Rank": 14925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7546,
    "Rank": 14928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7547,
    "Rank": 14933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7548,
    "Rank": 14935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7549,
    "Rank": 14937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7550,
    "Rank": 14939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7551,
    "Rank": 14941,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7552,
    "Rank": 14951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7553,
    "Rank": 14963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7554,
    "Rank": 14967,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7555,
    "Rank": 14970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7556,
    "Rank": 14971,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7557,
    "Rank": 14974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7558,
    "Rank": 14975,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7559,
    "Rank": 14977,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7560,
    "Rank": 14978,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7561,
    "Rank": 14985,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7562,
    "Rank": 14988,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7563,
    "Rank": 14991,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7564,
    "Rank": 14994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7565,
    "Rank": 14995,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7566,
    "Rank": 14999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7567,
    "Rank": 15005,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7568,
    "Rank": 15008,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7569,
    "Rank": 15012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7570,
    "Rank": 15013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7571,
    "Rank": 15026,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7572,
    "Rank": 15029,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7573,
    "Rank": 15031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7574,
    "Rank": 15033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7575,
    "Rank": 15037,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7576,
    "Rank": 15046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7577,
    "Rank": 15049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7578,
    "Rank": 15051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7579,
    "Rank": 15052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7580,
    "Rank": 15055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7581,
    "Rank": 15056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7582,
    "Rank": 15057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7583,
    "Rank": 15058,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7584,
    "Rank": 15061,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7585,
    "Rank": 15063,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7586,
    "Rank": 15066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7587,
    "Rank": 15069,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7588,
    "Rank": 15074,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7589,
    "Rank": 15081,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7590,
    "Rank": 15082,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7591,
    "Rank": 15085,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7592,
    "Rank": 15086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7593,
    "Rank": 15087,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7594,
    "Rank": 15088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7595,
    "Rank": 15098,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7596,
    "Rank": 15100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7597,
    "Rank": 15102,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7598,
    "Rank": 15105,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7599,
    "Rank": 15112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7600,
    "Rank": 15119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7601,
    "Rank": 15120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7602,
    "Rank": 15129,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7603,
    "Rank": 15135,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7604,
    "Rank": 15137,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7605,
    "Rank": 15140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7606,
    "Rank": 15143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7607,
    "Rank": 15144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7608,
    "Rank": 15147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7609,
    "Rank": 15154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7610,
    "Rank": 15155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7611,
    "Rank": 15158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7612,
    "Rank": 15160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7613,
    "Rank": 15163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7614,
    "Rank": 15164,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7615,
    "Rank": 15165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7616,
    "Rank": 15167,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7617,
    "Rank": 15170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7618,
    "Rank": 15178,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7619,
    "Rank": 15180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7620,
    "Rank": 15183,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7621,
    "Rank": 15185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7622,
    "Rank": 15186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7623,
    "Rank": 15188,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7624,
    "Rank": 15189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7625,
    "Rank": 15196,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7626,
    "Rank": 15197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7627,
    "Rank": 15198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7628,
    "Rank": 15212,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7629,
    "Rank": 15217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7630,
    "Rank": 15222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7631,
    "Rank": 15223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7632,
    "Rank": 15225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7633,
    "Rank": 15228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7634,
    "Rank": 15229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7635,
    "Rank": 15236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7636,
    "Rank": 15241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7637,
    "Rank": 15246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7638,
    "Rank": 15247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7639,
    "Rank": 15250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7640,
    "Rank": 15252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7641,
    "Rank": 15258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7642,
    "Rank": 15259,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7643,
    "Rank": 15260,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7644,
    "Rank": 15267,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7645,
    "Rank": 15268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7646,
    "Rank": 15269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7647,
    "Rank": 15270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7648,
    "Rank": 15274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7649,
    "Rank": 15277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7650,
    "Rank": 15278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7651,
    "Rank": 15286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7652,
    "Rank": 15293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7653,
    "Rank": 15302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7654,
    "Rank": 15304,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7655,
    "Rank": 15307,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7656,
    "Rank": 15308,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7657,
    "Rank": 15314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7658,
    "Rank": 15318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7659,
    "Rank": 15324,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7660,
    "Rank": 15327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7661,
    "Rank": 15330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7662,
    "Rank": 15331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7663,
    "Rank": 15332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7664,
    "Rank": 15339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7665,
    "Rank": 15343,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7666,
    "Rank": 15344,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7667,
    "Rank": 15347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7668,
    "Rank": 15349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7669,
    "Rank": 15371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7670,
    "Rank": 15375,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7671,
    "Rank": 15385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7672,
    "Rank": 15389,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7673,
    "Rank": 15401,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7674,
    "Rank": 15403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7675,
    "Rank": 15406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7676,
    "Rank": 15411,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7677,
    "Rank": 15412,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7678,
    "Rank": 15416,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7679,
    "Rank": 15417,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7680,
    "Rank": 15418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7681,
    "Rank": 15419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7682,
    "Rank": 15422,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7683,
    "Rank": 15432,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7684,
    "Rank": 15433,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7685,
    "Rank": 15439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7686,
    "Rank": 15443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7687,
    "Rank": 15447,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7688,
    "Rank": 15452,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7689,
    "Rank": 15460,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7690,
    "Rank": 15464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7691,
    "Rank": 15472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7692,
    "Rank": 15474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7693,
    "Rank": 15475,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7694,
    "Rank": 15476,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7695,
    "Rank": 15480,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7696,
    "Rank": 15482,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7697,
    "Rank": 15487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7698,
    "Rank": 15494,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7699,
    "Rank": 15498,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7700,
    "Rank": 15500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7701,
    "Rank": 15510,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7702,
    "Rank": 15512,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7703,
    "Rank": 15515,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7704,
    "Rank": 15517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7705,
    "Rank": 15519,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7706,
    "Rank": 15522,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7707,
    "Rank": 15523,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7708,
    "Rank": 15528,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7709,
    "Rank": 15538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7710,
    "Rank": 15539,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7711,
    "Rank": 15544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7712,
    "Rank": 15546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7713,
    "Rank": 15550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7714,
    "Rank": 15552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7715,
    "Rank": 15554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7716,
    "Rank": 15555,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7717,
    "Rank": 15562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7718,
    "Rank": 15567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7719,
    "Rank": 15568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7720,
    "Rank": 15576,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7721,
    "Rank": 15583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7722,
    "Rank": 15586,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7723,
    "Rank": 15587,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7724,
    "Rank": 15588,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7725,
    "Rank": 15589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7726,
    "Rank": 15591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7727,
    "Rank": 15593,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7728,
    "Rank": 15594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7729,
    "Rank": 15604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7730,
    "Rank": 15606,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7731,
    "Rank": 15610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7732,
    "Rank": 15611,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7733,
    "Rank": 15612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7734,
    "Rank": 15618,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7735,
    "Rank": 15621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7736,
    "Rank": 15622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7737,
    "Rank": 15625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7738,
    "Rank": 15627,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7739,
    "Rank": 15629,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7740,
    "Rank": 15634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7741,
    "Rank": 15643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7742,
    "Rank": 15645,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7743,
    "Rank": 15646,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7744,
    "Rank": 15650,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7745,
    "Rank": 15651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7746,
    "Rank": 15653,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7747,
    "Rank": 15656,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7748,
    "Rank": 15657,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7749,
    "Rank": 15663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7750,
    "Rank": 15667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7751,
    "Rank": 15668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7752,
    "Rank": 15673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7753,
    "Rank": 15675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7754,
    "Rank": 15676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7755,
    "Rank": 15678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7756,
    "Rank": 15680,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7757,
    "Rank": 15681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7758,
    "Rank": 15684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7759,
    "Rank": 15686,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7760,
    "Rank": 15688,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7761,
    "Rank": 15695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7762,
    "Rank": 15698,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7763,
    "Rank": 15702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7764,
    "Rank": 15703,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7765,
    "Rank": 15704,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7766,
    "Rank": 15714,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7767,
    "Rank": 15719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7768,
    "Rank": 15725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7769,
    "Rank": 15728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7770,
    "Rank": 15729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7771,
    "Rank": 15736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7772,
    "Rank": 15737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7773,
    "Rank": 15742,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7774,
    "Rank": 15750,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7775,
    "Rank": 15757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7776,
    "Rank": 15759,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7777,
    "Rank": 15762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7778,
    "Rank": 15764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7779,
    "Rank": 15765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7780,
    "Rank": 15766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7781,
    "Rank": 15781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7782,
    "Rank": 15787,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7783,
    "Rank": 15800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7784,
    "Rank": 15804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7785,
    "Rank": 15810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7786,
    "Rank": 15811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7787,
    "Rank": 15813,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7788,
    "Rank": 15816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7789,
    "Rank": 15823,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7790,
    "Rank": 15825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7791,
    "Rank": 15826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7792,
    "Rank": 15828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7793,
    "Rank": 15830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7794,
    "Rank": 15837,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7795,
    "Rank": 15839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7796,
    "Rank": 15841,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7797,
    "Rank": 15843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7798,
    "Rank": 15846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7799,
    "Rank": 15848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7800,
    "Rank": 15852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7801,
    "Rank": 15855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7802,
    "Rank": 15856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7803,
    "Rank": 15860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7804,
    "Rank": 15864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7805,
    "Rank": 15865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7806,
    "Rank": 15868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7807,
    "Rank": 15871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7808,
    "Rank": 15877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7809,
    "Rank": 15879,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7810,
    "Rank": 15894,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7811,
    "Rank": 15895,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7812,
    "Rank": 15901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7813,
    "Rank": 15904,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7814,
    "Rank": 15907,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7815,
    "Rank": 15908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7816,
    "Rank": 15918,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7817,
    "Rank": 15921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7818,
    "Rank": 15925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7819,
    "Rank": 15927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7820,
    "Rank": 15931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7821,
    "Rank": 15935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7822,
    "Rank": 15940,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7823,
    "Rank": 15942,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7824,
    "Rank": 15943,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7825,
    "Rank": 15946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7826,
    "Rank": 15950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7827,
    "Rank": 15953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7828,
    "Rank": 15964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7829,
    "Rank": 15968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7830,
    "Rank": 15970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7831,
    "Rank": 15973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7832,
    "Rank": 15983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7833,
    "Rank": 15984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7834,
    "Rank": 15988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7835,
    "Rank": 15990,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7836,
    "Rank": 15998,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7837,
    "Rank": 16003,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7838,
    "Rank": 16004,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7839,
    "Rank": 16006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7840,
    "Rank": 16007,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7841,
    "Rank": 16008,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7842,
    "Rank": 16010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7843,
    "Rank": 16011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7844,
    "Rank": 16013,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7845,
    "Rank": 16014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7846,
    "Rank": 16015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7847,
    "Rank": 16019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7848,
    "Rank": 16020,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7849,
    "Rank": 16021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7850,
    "Rank": 16028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7851,
    "Rank": 16039,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7852,
    "Rank": 16043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7853,
    "Rank": 16044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7854,
    "Rank": 16046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7855,
    "Rank": 16047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7856,
    "Rank": 16049,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7857,
    "Rank": 16055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7858,
    "Rank": 16059,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7859,
    "Rank": 16068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7860,
    "Rank": 16073,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7861,
    "Rank": 16074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7862,
    "Rank": 16084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7863,
    "Rank": 16086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7864,
    "Rank": 16087,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7865,
    "Rank": 16090,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7866,
    "Rank": 16092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7867,
    "Rank": 16094,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7868,
    "Rank": 16099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7869,
    "Rank": 16101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7870,
    "Rank": 16102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7871,
    "Rank": 16103,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7872,
    "Rank": 16109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7873,
    "Rank": 16113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7874,
    "Rank": 16123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7875,
    "Rank": 16126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7876,
    "Rank": 16128,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7877,
    "Rank": 16131,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7878,
    "Rank": 16132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7879,
    "Rank": 16135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7880,
    "Rank": 16136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7881,
    "Rank": 16137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7882,
    "Rank": 16138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7883,
    "Rank": 16142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7884,
    "Rank": 16146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7885,
    "Rank": 16151,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7886,
    "Rank": 16152,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7887,
    "Rank": 16154,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7888,
    "Rank": 16156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7889,
    "Rank": 16163,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7890,
    "Rank": 16164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7891,
    "Rank": 16165,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7892,
    "Rank": 16166,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7893,
    "Rank": 16168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7894,
    "Rank": 16169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7895,
    "Rank": 16174,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7896,
    "Rank": 16175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7897,
    "Rank": 16191,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7898,
    "Rank": 16192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7899,
    "Rank": 16194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7900,
    "Rank": 16201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7901,
    "Rank": 16212,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7902,
    "Rank": 16215,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7903,
    "Rank": 16216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7904,
    "Rank": 16219,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7905,
    "Rank": 16220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7906,
    "Rank": 16221,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7907,
    "Rank": 16223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7908,
    "Rank": 16225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7909,
    "Rank": 16226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7910,
    "Rank": 16228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7911,
    "Rank": 16229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7912,
    "Rank": 16232,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7913,
    "Rank": 16236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7914,
    "Rank": 16240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7915,
    "Rank": 16243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7916,
    "Rank": 16249,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7917,
    "Rank": 16250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7918,
    "Rank": 16251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7919,
    "Rank": 16259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7920,
    "Rank": 16260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7921,
    "Rank": 16263,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7922,
    "Rank": 16266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7923,
    "Rank": 16270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7924,
    "Rank": 16271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7925,
    "Rank": 16275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7926,
    "Rank": 16276,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7927,
    "Rank": 16278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7928,
    "Rank": 16279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7929,
    "Rank": 16280,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7930,
    "Rank": 16281,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7931,
    "Rank": 16285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7932,
    "Rank": 16287,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7933,
    "Rank": 16292,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7934,
    "Rank": 16295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7935,
    "Rank": 16297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7936,
    "Rank": 16298,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7937,
    "Rank": 16305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7938,
    "Rank": 16307,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7939,
    "Rank": 16313,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7940,
    "Rank": 16314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7941,
    "Rank": 16320,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7942,
    "Rank": 16322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7943,
    "Rank": 16324,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7944,
    "Rank": 16331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7945,
    "Rank": 16336,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7946,
    "Rank": 16339,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7947,
    "Rank": 16342,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7948,
    "Rank": 16353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7949,
    "Rank": 16359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7950,
    "Rank": 16362,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7951,
    "Rank": 16363,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7952,
    "Rank": 16364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7953,
    "Rank": 16377,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7954,
    "Rank": 16378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7955,
    "Rank": 16381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7956,
    "Rank": 16382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7957,
    "Rank": 16384,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7958,
    "Rank": 16385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7959,
    "Rank": 16386,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7960,
    "Rank": 16390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7961,
    "Rank": 16395,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7962,
    "Rank": 16397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7963,
    "Rank": 16398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7964,
    "Rank": 16410,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7965,
    "Rank": 16412,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7966,
    "Rank": 16413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7967,
    "Rank": 16415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7968,
    "Rank": 16418,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7969,
    "Rank": 16421,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7970,
    "Rank": 16423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7971,
    "Rank": 16424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7972,
    "Rank": 16428,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7973,
    "Rank": 16430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7974,
    "Rank": 16440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7975,
    "Rank": 16444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7976,
    "Rank": 16446,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7977,
    "Rank": 16450,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7978,
    "Rank": 16451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7979,
    "Rank": 16456,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7980,
    "Rank": 16457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7981,
    "Rank": 16460,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7982,
    "Rank": 16461,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7983,
    "Rank": 16464,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7984,
    "Rank": 16465,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7985,
    "Rank": 16467,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7986,
    "Rank": 16468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7987,
    "Rank": 16469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7988,
    "Rank": 16471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7989,
    "Rank": 16475,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7990,
    "Rank": 16477,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7991,
    "Rank": 16482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7992,
    "Rank": 16483,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7993,
    "Rank": 16487,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7994,
    "Rank": 16491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7995,
    "Rank": 16500,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7996,
    "Rank": 16504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7997,
    "Rank": 16505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7998,
    "Rank": 16510,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 7999,
    "Rank": 16511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8000,
    "Rank": 16517,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8001,
    "Rank": 16520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8002,
    "Rank": 16521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8003,
    "Rank": 16522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8004,
    "Rank": 16532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8005,
    "Rank": 16533,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8006,
    "Rank": 16535,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8007,
    "Rank": 16536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8008,
    "Rank": 16539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8009,
    "Rank": 16544,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8010,
    "Rank": 16550,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8011,
    "Rank": 16551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8012,
    "Rank": 16553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8013,
    "Rank": 16554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8014,
    "Rank": 16555,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8015,
    "Rank": 16556,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8016,
    "Rank": 16562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8017,
    "Rank": 16572,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8018,
    "Rank": 16574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8019,
    "Rank": 16576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8020,
    "Rank": 16581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8021,
    "Rank": 16582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8022,
    "Rank": 16595,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8023,
    "Rank": 16599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8024,
    "Rank": 16607,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8025,
    "Rank": 16611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8026,
    "Rank": 16613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8027,
    "Rank": 16621,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8028,
    "Rank": 16627,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8029,
    "Rank": 16630,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8030,
    "Rank": 16635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8031,
    "Rank": 16642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8032,
    "Rank": 16646,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8033,
    "Rank": 16652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8034,
    "Rank": 16659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8035,
    "Rank": 16660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8036,
    "Rank": 16664,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8037,
    "Rank": 16665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8038,
    "Rank": 16666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8039,
    "Rank": 16671,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8040,
    "Rank": 16672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8041,
    "Rank": 16689,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8042,
    "Rank": 16691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8043,
    "Rank": 16695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8044,
    "Rank": 16697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8045,
    "Rank": 16700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8046,
    "Rank": 16701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8047,
    "Rank": 16703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8048,
    "Rank": 16708,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8049,
    "Rank": 16709,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8050,
    "Rank": 16718,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8051,
    "Rank": 16722,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8052,
    "Rank": 16726,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8053,
    "Rank": 16732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8054,
    "Rank": 16734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8055,
    "Rank": 16748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8056,
    "Rank": 16750,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8057,
    "Rank": 16758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8058,
    "Rank": 16759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8059,
    "Rank": 16766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8060,
    "Rank": 16769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8061,
    "Rank": 16772,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8062,
    "Rank": 16782,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8063,
    "Rank": 16791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8064,
    "Rank": 16792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8065,
    "Rank": 16793,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8066,
    "Rank": 16794,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8067,
    "Rank": 16797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8068,
    "Rank": 16798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8069,
    "Rank": 16799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8070,
    "Rank": 16800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8071,
    "Rank": 16812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8072,
    "Rank": 16821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8073,
    "Rank": 16823,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8074,
    "Rank": 16824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8075,
    "Rank": 16827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8076,
    "Rank": 16830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8077,
    "Rank": 16835,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8078,
    "Rank": 16839,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8079,
    "Rank": 16842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8080,
    "Rank": 16846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8081,
    "Rank": 16847,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8082,
    "Rank": 16852,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8083,
    "Rank": 16853,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8084,
    "Rank": 16864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8085,
    "Rank": 16869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8086,
    "Rank": 16870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8087,
    "Rank": 16871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8088,
    "Rank": 16872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8089,
    "Rank": 16874,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8090,
    "Rank": 16879,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8091,
    "Rank": 16884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8092,
    "Rank": 16890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8093,
    "Rank": 16892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8094,
    "Rank": 16893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8095,
    "Rank": 16894,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8096,
    "Rank": 16898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8097,
    "Rank": 16901,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8098,
    "Rank": 16902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8099,
    "Rank": 16904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8100,
    "Rank": 16905,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8101,
    "Rank": 16906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8102,
    "Rank": 16908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8103,
    "Rank": 16910,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8104,
    "Rank": 16912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8105,
    "Rank": 16914,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8106,
    "Rank": 16922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8107,
    "Rank": 16923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8108,
    "Rank": 16927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8109,
    "Rank": 16930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8110,
    "Rank": 16931,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8111,
    "Rank": 16933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8112,
    "Rank": 16935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8113,
    "Rank": 16937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8114,
    "Rank": 16939,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8115,
    "Rank": 16958,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8116,
    "Rank": 16960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8117,
    "Rank": 16961,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8118,
    "Rank": 16963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8119,
    "Rank": 16964,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8120,
    "Rank": 16967,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8121,
    "Rank": 16973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8122,
    "Rank": 16975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8123,
    "Rank": 16981,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8124,
    "Rank": 16982,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8125,
    "Rank": 16983,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8126,
    "Rank": 16984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8127,
    "Rank": 16986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8128,
    "Rank": 16990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8129,
    "Rank": 17003,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8130,
    "Rank": 17011,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8131,
    "Rank": 17012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8132,
    "Rank": 17016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8133,
    "Rank": 17018,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8134,
    "Rank": 17025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8135,
    "Rank": 17036,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8136,
    "Rank": 17047,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8137,
    "Rank": 17049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8138,
    "Rank": 17050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8139,
    "Rank": 17051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8140,
    "Rank": 17052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8141,
    "Rank": 17055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8142,
    "Rank": 17058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8143,
    "Rank": 17062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8144,
    "Rank": 17063,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8145,
    "Rank": 17064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8146,
    "Rank": 17066,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8147,
    "Rank": 17069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8148,
    "Rank": 17073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8149,
    "Rank": 17074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8150,
    "Rank": 17076,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8151,
    "Rank": 17077,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8152,
    "Rank": 17079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8153,
    "Rank": 17080,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8154,
    "Rank": 17083,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8155,
    "Rank": 17090,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8156,
    "Rank": 17092,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8157,
    "Rank": 17100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8158,
    "Rank": 17101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8159,
    "Rank": 17103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8160,
    "Rank": 17104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8161,
    "Rank": 17106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8162,
    "Rank": 17114,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8163,
    "Rank": 17116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8164,
    "Rank": 17117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8165,
    "Rank": 17118,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8166,
    "Rank": 17119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8167,
    "Rank": 17120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8168,
    "Rank": 17122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8169,
    "Rank": 17134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8170,
    "Rank": 17139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8171,
    "Rank": 17146,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8172,
    "Rank": 17152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8173,
    "Rank": 17157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8174,
    "Rank": 17160,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8175,
    "Rank": 17164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8176,
    "Rank": 17168,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8177,
    "Rank": 17170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8178,
    "Rank": 17172,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8179,
    "Rank": 17174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8180,
    "Rank": 17176,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8181,
    "Rank": 17184,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8182,
    "Rank": 17187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8183,
    "Rank": 17190,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8184,
    "Rank": 17193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8185,
    "Rank": 17199,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8186,
    "Rank": 17202,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8187,
    "Rank": 17205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8188,
    "Rank": 17212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8189,
    "Rank": 17216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8190,
    "Rank": 17220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8191,
    "Rank": 17225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8192,
    "Rank": 17226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8193,
    "Rank": 17227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8194,
    "Rank": 17228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8195,
    "Rank": 17231,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8196,
    "Rank": 17234,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8197,
    "Rank": 17237,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8198,
    "Rank": 17240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8199,
    "Rank": 17243,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8200,
    "Rank": 17244,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8201,
    "Rank": 17245,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8202,
    "Rank": 17249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8203,
    "Rank": 17256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8204,
    "Rank": 17262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8205,
    "Rank": 17268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8206,
    "Rank": 17269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8207,
    "Rank": 17272,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8208,
    "Rank": 17275,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8209,
    "Rank": 17279,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8210,
    "Rank": 17295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8211,
    "Rank": 17297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8212,
    "Rank": 17298,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8213,
    "Rank": 17302,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8214,
    "Rank": 17304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8215,
    "Rank": 17306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8216,
    "Rank": 17312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8217,
    "Rank": 17313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8218,
    "Rank": 17315,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8219,
    "Rank": 17320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8220,
    "Rank": 17328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8221,
    "Rank": 17329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8222,
    "Rank": 17333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8223,
    "Rank": 17337,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8224,
    "Rank": 17338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8225,
    "Rank": 17339,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8226,
    "Rank": 17341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8227,
    "Rank": 17343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8228,
    "Rank": 17351,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted( CW Rank\n: 59 )"
  },
  {
    "SNo": 8229,
    "Rank": 17353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8230,
    "Rank": 17356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8231,
    "Rank": 17360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8232,
    "Rank": 17361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8233,
    "Rank": 17362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8234,
    "Rank": 17372,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8235,
    "Rank": 17374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8236,
    "Rank": 17376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8237,
    "Rank": 17377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8238,
    "Rank": 17378,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8239,
    "Rank": 17381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8240,
    "Rank": 17386,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8241,
    "Rank": 17388,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8242,
    "Rank": 17394,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8243,
    "Rank": 17399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8244,
    "Rank": 17404,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8245,
    "Rank": 17413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8246,
    "Rank": 17414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8247,
    "Rank": 17416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8248,
    "Rank": 17421,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8249,
    "Rank": 17423,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8250,
    "Rank": 17424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8251,
    "Rank": 17430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8252,
    "Rank": 17434,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8253,
    "Rank": 17436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8254,
    "Rank": 17441,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8255,
    "Rank": 17443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8256,
    "Rank": 17444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8257,
    "Rank": 17445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8258,
    "Rank": 17447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8259,
    "Rank": 17448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8260,
    "Rank": 17451,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8261,
    "Rank": 17453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8262,
    "Rank": 17457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8263,
    "Rank": 17464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8264,
    "Rank": 17468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8265,
    "Rank": 17471,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8266,
    "Rank": 17472,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8267,
    "Rank": 17475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8268,
    "Rank": 17480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8269,
    "Rank": 17486,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8270,
    "Rank": 17492,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8271,
    "Rank": 17493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8272,
    "Rank": 17496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8273,
    "Rank": 17501,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8274,
    "Rank": 17506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8275,
    "Rank": 17509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8276,
    "Rank": 17511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8277,
    "Rank": 17512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8278,
    "Rank": 17513,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8279,
    "Rank": 17516,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8280,
    "Rank": 17518,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8281,
    "Rank": 17520,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8282,
    "Rank": 17523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8283,
    "Rank": 17525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8284,
    "Rank": 17541,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8285,
    "Rank": 17542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8286,
    "Rank": 17546,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8287,
    "Rank": 17551,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8288,
    "Rank": 17557,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8289,
    "Rank": 17560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8290,
    "Rank": 17564,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8291,
    "Rank": 17567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8292,
    "Rank": 17568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8293,
    "Rank": 17578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8294,
    "Rank": 17579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8295,
    "Rank": 17586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8296,
    "Rank": 17587,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8297,
    "Rank": 17589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8298,
    "Rank": 17590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8299,
    "Rank": 17595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8300,
    "Rank": 17596,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8301,
    "Rank": 17601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8302,
    "Rank": 17602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8303,
    "Rank": 17604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8304,
    "Rank": 17606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8305,
    "Rank": 17607,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8306,
    "Rank": 17608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8307,
    "Rank": 17609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8308,
    "Rank": 17617,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8309,
    "Rank": 17619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8310,
    "Rank": 17620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8311,
    "Rank": 17631,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8312,
    "Rank": 17637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8313,
    "Rank": 17638,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8314,
    "Rank": 17640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8315,
    "Rank": 17642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8316,
    "Rank": 17645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8317,
    "Rank": 17654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8318,
    "Rank": 17658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8319,
    "Rank": 17671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8320,
    "Rank": 17676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8321,
    "Rank": 17688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8322,
    "Rank": 17689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8323,
    "Rank": 17698,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8324,
    "Rank": 17701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8325,
    "Rank": 17707,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8326,
    "Rank": 17709,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8327,
    "Rank": 17710,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8328,
    "Rank": 17719,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8329,
    "Rank": 17720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8330,
    "Rank": 17722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8331,
    "Rank": 17723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8332,
    "Rank": 17726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8333,
    "Rank": 17728,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8334,
    "Rank": 17732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8335,
    "Rank": 17735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8336,
    "Rank": 17740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8337,
    "Rank": 17741,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8338,
    "Rank": 17744,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8339,
    "Rank": 17748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8340,
    "Rank": 17751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8341,
    "Rank": 17759,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8342,
    "Rank": 17760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8343,
    "Rank": 17762,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8344,
    "Rank": 17764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8345,
    "Rank": 17768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8346,
    "Rank": 17780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8347,
    "Rank": 17782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8348,
    "Rank": 17784,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8349,
    "Rank": 17786,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8350,
    "Rank": 17789,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8351,
    "Rank": 17790,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8352,
    "Rank": 17792,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8353,
    "Rank": 17796,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8354,
    "Rank": 17800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8355,
    "Rank": 17803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8356,
    "Rank": 17805,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8357,
    "Rank": 17809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8358,
    "Rank": 17813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8359,
    "Rank": 17820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8360,
    "Rank": 17823,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8361,
    "Rank": 17824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8362,
    "Rank": 17825,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8363,
    "Rank": 17826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8364,
    "Rank": 17837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8365,
    "Rank": 17839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8366,
    "Rank": 17843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8367,
    "Rank": 17844,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8368,
    "Rank": 17846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8369,
    "Rank": 17850,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8370,
    "Rank": 17860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8371,
    "Rank": 17864,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8372,
    "Rank": 17867,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8373,
    "Rank": 17869,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8374,
    "Rank": 17872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8375,
    "Rank": 17874,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8376,
    "Rank": 17875,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8377,
    "Rank": 17876,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8378,
    "Rank": 17878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8379,
    "Rank": 17886,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8380,
    "Rank": 17891,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8381,
    "Rank": 17899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.S.A. Medical College, Delhi,DR.BABA SAHEB AMBEDKAR MEDICAL COLLEGE and HOSPITAL,\nROHINI SEC-6, DELHI-110085, Delhi (NCT), 110085",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8382,
    "Rank": 17901,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8383,
    "Rank": 17904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8384,
    "Rank": 17914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8385,
    "Rank": 17916,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8386,
    "Rank": 17919,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8387,
    "Rank": 17928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8388,
    "Rank": 17931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8389,
    "Rank": 17935,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8390,
    "Rank": 17939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8391,
    "Rank": 17942,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8392,
    "Rank": 17944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8393,
    "Rank": 17958,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8394,
    "Rank": 17965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8395,
    "Rank": 17978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8396,
    "Rank": 17981,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8397,
    "Rank": 17983,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8398,
    "Rank": 17986,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8399,
    "Rank": 17991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8400,
    "Rank": 18017,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8401,
    "Rank": 18021,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8402,
    "Rank": 18022,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8403,
    "Rank": 18025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8404,
    "Rank": 18031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8405,
    "Rank": 18034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8406,
    "Rank": 18037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8407,
    "Rank": 18039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8408,
    "Rank": 18043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8409,
    "Rank": 18045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8410,
    "Rank": 18048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8411,
    "Rank": 18050,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8412,
    "Rank": 18052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8413,
    "Rank": 18054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8414,
    "Rank": 18057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8415,
    "Rank": 18059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8416,
    "Rank": 18063,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8417,
    "Rank": 18065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8418,
    "Rank": 18068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8419,
    "Rank": 18069,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8420,
    "Rank": 18073,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8421,
    "Rank": 18076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8422,
    "Rank": 18080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8423,
    "Rank": 18103,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8424,
    "Rank": 18104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8425,
    "Rank": 18106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8426,
    "Rank": 18110,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8427,
    "Rank": 18113,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8428,
    "Rank": 18125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8429,
    "Rank": 18126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8430,
    "Rank": 18127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8431,
    "Rank": 18128,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8432,
    "Rank": 18132,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8433,
    "Rank": 18136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8434,
    "Rank": 18144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8435,
    "Rank": 18152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8436,
    "Rank": 18157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8437,
    "Rank": 18158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8438,
    "Rank": 18161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8439,
    "Rank": 18162,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8440,
    "Rank": 18164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8441,
    "Rank": 18173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8442,
    "Rank": 18181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8443,
    "Rank": 18182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8444,
    "Rank": 18186,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8445,
    "Rank": 18191,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8446,
    "Rank": 18192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8447,
    "Rank": 18195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8448,
    "Rank": 18197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8449,
    "Rank": 18201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8450,
    "Rank": 18202,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8451,
    "Rank": 18211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8452,
    "Rank": 18213,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8453,
    "Rank": 18216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8454,
    "Rank": 18219,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8455,
    "Rank": 18223,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8456,
    "Rank": 18224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8457,
    "Rank": 18225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8458,
    "Rank": 18228,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8459,
    "Rank": 18231,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8460,
    "Rank": 18234,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8461,
    "Rank": 18242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8462,
    "Rank": 18247,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8463,
    "Rank": 18254,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8464,
    "Rank": 18256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8465,
    "Rank": 18262,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8466,
    "Rank": 18271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8467,
    "Rank": 18279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8468,
    "Rank": 18283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8469,
    "Rank": 18286,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8470,
    "Rank": 18288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8471,
    "Rank": 18294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8472,
    "Rank": 18302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8473,
    "Rank": 18303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8474,
    "Rank": 18305,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8475,
    "Rank": 18307,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8476,
    "Rank": 18309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8477,
    "Rank": 18314,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8478,
    "Rank": 18317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8479,
    "Rank": 18322,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8480,
    "Rank": 18323,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8481,
    "Rank": 18324,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8482,
    "Rank": 18326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8483,
    "Rank": 18334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8484,
    "Rank": 18336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8485,
    "Rank": 18340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8486,
    "Rank": 18341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8487,
    "Rank": 18346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8488,
    "Rank": 18347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8489,
    "Rank": 18349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8490,
    "Rank": 18350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8491,
    "Rank": 18354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8492,
    "Rank": 18355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8493,
    "Rank": 18356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8494,
    "Rank": 18358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8495,
    "Rank": 18365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8496,
    "Rank": 18366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8497,
    "Rank": 18367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8498,
    "Rank": 18368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8499,
    "Rank": 18369,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8500,
    "Rank": 18371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8501,
    "Rank": 18372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8502,
    "Rank": 18379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8503,
    "Rank": 18380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8504,
    "Rank": 18381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8505,
    "Rank": 18383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8506,
    "Rank": 18387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8507,
    "Rank": 18390,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8508,
    "Rank": 18391,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8509,
    "Rank": 18393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8510,
    "Rank": 18395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8511,
    "Rank": 18397,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8512,
    "Rank": 18399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8513,
    "Rank": 18400,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8514,
    "Rank": 18405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8515,
    "Rank": 18415,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8516,
    "Rank": 18419,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8517,
    "Rank": 18420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8518,
    "Rank": 18422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8519,
    "Rank": 18427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8520,
    "Rank": 18429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8521,
    "Rank": 18438,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8522,
    "Rank": 18441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8523,
    "Rank": 18442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8524,
    "Rank": 18445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8525,
    "Rank": 18449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8526,
    "Rank": 18452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8527,
    "Rank": 18453,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8528,
    "Rank": 18464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8529,
    "Rank": 18465,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8530,
    "Rank": 18468,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8531,
    "Rank": 18469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8532,
    "Rank": 18471,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8533,
    "Rank": 18476,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8534,
    "Rank": 18479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8535,
    "Rank": 18481,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8536,
    "Rank": 18484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8537,
    "Rank": 18485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8538,
    "Rank": 18486,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8539,
    "Rank": 18518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8540,
    "Rank": 18528,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8541,
    "Rank": 18532,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8542,
    "Rank": 18538,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8543,
    "Rank": 18539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8544,
    "Rank": 18543,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8545,
    "Rank": 18556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8546,
    "Rank": 18570,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8547,
    "Rank": 18572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8548,
    "Rank": 18577,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8549,
    "Rank": 18578,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8550,
    "Rank": 18579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8551,
    "Rank": 18582,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8552,
    "Rank": 18587,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8553,
    "Rank": 18588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8554,
    "Rank": 18591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8555,
    "Rank": 18603,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8556,
    "Rank": 18605,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8557,
    "Rank": 18612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8558,
    "Rank": 18617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8559,
    "Rank": 18618,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8560,
    "Rank": 18625,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8561,
    "Rank": 18633,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8562,
    "Rank": 18640,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8563,
    "Rank": 18644,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8564,
    "Rank": 18666,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8565,
    "Rank": 18673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8566,
    "Rank": 18674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8567,
    "Rank": 18675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8568,
    "Rank": 18683,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8569,
    "Rank": 18687,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8570,
    "Rank": 18690,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8571,
    "Rank": 18696,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8572,
    "Rank": 18698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8573,
    "Rank": 18707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8574,
    "Rank": 18711,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8575,
    "Rank": 18717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8576,
    "Rank": 18720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8577,
    "Rank": 18725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8578,
    "Rank": 18727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8579,
    "Rank": 18731,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8580,
    "Rank": 18732,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8581,
    "Rank": 18740,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8582,
    "Rank": 18741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8583,
    "Rank": 18745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8584,
    "Rank": 18748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8585,
    "Rank": 18752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8586,
    "Rank": 18764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8587,
    "Rank": 18768,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8588,
    "Rank": 18773,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8589,
    "Rank": 18774,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8590,
    "Rank": 18775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8591,
    "Rank": 18776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8592,
    "Rank": 18778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8593,
    "Rank": 18779,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8594,
    "Rank": 18785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8595,
    "Rank": 18791,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8596,
    "Rank": 18807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8597,
    "Rank": 18809,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8598,
    "Rank": 18811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8599,
    "Rank": 18814,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8600,
    "Rank": 18816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8601,
    "Rank": 18819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8602,
    "Rank": 18820,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8603,
    "Rank": 18824,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8604,
    "Rank": 18827,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8605,
    "Rank": 18834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8606,
    "Rank": 18836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8607,
    "Rank": 18837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8608,
    "Rank": 18838,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8609,
    "Rank": 18840,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8610,
    "Rank": 18841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8611,
    "Rank": 18844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8612,
    "Rank": 18855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8613,
    "Rank": 18856,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8614,
    "Rank": 18857,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8615,
    "Rank": 18858,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8616,
    "Rank": 18872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8617,
    "Rank": 18877,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8618,
    "Rank": 18880,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8619,
    "Rank": 18882,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8620,
    "Rank": 18884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8621,
    "Rank": 18891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8622,
    "Rank": 18892,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8623,
    "Rank": 18896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8624,
    "Rank": 18900,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8625,
    "Rank": 18908,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8626,
    "Rank": 18915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8627,
    "Rank": 18919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8628,
    "Rank": 18933,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8629,
    "Rank": 18940,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8630,
    "Rank": 18946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8631,
    "Rank": 18950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8632,
    "Rank": 18952,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8633,
    "Rank": 18954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8634,
    "Rank": 18955,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8635,
    "Rank": 18956,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8636,
    "Rank": 18965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8637,
    "Rank": 18966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8638,
    "Rank": 18970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8639,
    "Rank": 18971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8640,
    "Rank": 18981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8641,
    "Rank": 18986,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8642,
    "Rank": 18987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8643,
    "Rank": 18989,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8644,
    "Rank": 18992,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8645,
    "Rank": 19001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8646,
    "Rank": 19004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8647,
    "Rank": 19013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8648,
    "Rank": 19025,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8649,
    "Rank": 19026,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8650,
    "Rank": 19031,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8651,
    "Rank": 19037,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8652,
    "Rank": 19043,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8653,
    "Rank": 19052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8654,
    "Rank": 19054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8655,
    "Rank": 19056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8656,
    "Rank": 19057,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8657,
    "Rank": 19058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8658,
    "Rank": 19059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8659,
    "Rank": 19068,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8660,
    "Rank": 19070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8661,
    "Rank": 19071,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8662,
    "Rank": 19073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8663,
    "Rank": 19076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8664,
    "Rank": 19077,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8665,
    "Rank": 19081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8666,
    "Rank": 19082,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8667,
    "Rank": 19088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8668,
    "Rank": 19096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8669,
    "Rank": 19098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8670,
    "Rank": 19100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8671,
    "Rank": 19106,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8672,
    "Rank": 19108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8673,
    "Rank": 19127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8674,
    "Rank": 19131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8675,
    "Rank": 19132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8676,
    "Rank": 19133,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8677,
    "Rank": 19134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8678,
    "Rank": 19135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8679,
    "Rank": 19139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8680,
    "Rank": 19142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8681,
    "Rank": 19144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8682,
    "Rank": 19149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8683,
    "Rank": 19151,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8684,
    "Rank": 19152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8685,
    "Rank": 19154,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8686,
    "Rank": 19156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8687,
    "Rank": 19160,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8688,
    "Rank": 19161,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8689,
    "Rank": 19162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8690,
    "Rank": 19169,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8691,
    "Rank": 19170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8692,
    "Rank": 19180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8693,
    "Rank": 19189,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8694,
    "Rank": 19191,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8695,
    "Rank": 19192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8696,
    "Rank": 19193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8697,
    "Rank": 19198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8698,
    "Rank": 19201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8699,
    "Rank": 19206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8700,
    "Rank": 19210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8701,
    "Rank": 19216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8702,
    "Rank": 19218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8703,
    "Rank": 19219,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8704,
    "Rank": 19222,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8705,
    "Rank": 19227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8706,
    "Rank": 19228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8707,
    "Rank": 19237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8708,
    "Rank": 19240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8709,
    "Rank": 19256,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8710,
    "Rank": 19259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8711,
    "Rank": 19261,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8712,
    "Rank": 19263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8713,
    "Rank": 19266,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8714,
    "Rank": 19269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8715,
    "Rank": 19271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8716,
    "Rank": 19272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8717,
    "Rank": 19282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8718,
    "Rank": 19283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8719,
    "Rank": 19285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8720,
    "Rank": 19287,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8721,
    "Rank": 19295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8722,
    "Rank": 19296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8723,
    "Rank": 19297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8724,
    "Rank": 19302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8725,
    "Rank": 19304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8726,
    "Rank": 19305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8727,
    "Rank": 19310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8728,
    "Rank": 19311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8729,
    "Rank": 19313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8730,
    "Rank": 19318,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8731,
    "Rank": 19319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8732,
    "Rank": 19322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8733,
    "Rank": 19324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8734,
    "Rank": 19336,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8735,
    "Rank": 19337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8736,
    "Rank": 19340,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8737,
    "Rank": 19347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8738,
    "Rank": 19354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8739,
    "Rank": 19357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8740,
    "Rank": 19365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8741,
    "Rank": 19368,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8742,
    "Rank": 19373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8743,
    "Rank": 19384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8744,
    "Rank": 19389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8745,
    "Rank": 19392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8746,
    "Rank": 19394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8747,
    "Rank": 19395,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8748,
    "Rank": 19396,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8749,
    "Rank": 19399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8750,
    "Rank": 19403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8751,
    "Rank": 19410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8752,
    "Rank": 19416,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8753,
    "Rank": 19417,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8754,
    "Rank": 19418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8755,
    "Rank": 19420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8756,
    "Rank": 19436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8757,
    "Rank": 19437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8758,
    "Rank": 19438,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8759,
    "Rank": 19443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8760,
    "Rank": 19446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8761,
    "Rank": 19450,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8762,
    "Rank": 19460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8763,
    "Rank": 19462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8764,
    "Rank": 19466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8765,
    "Rank": 19469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8766,
    "Rank": 19475,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8767,
    "Rank": 19477,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8768,
    "Rank": 19481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8769,
    "Rank": 19482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8770,
    "Rank": 19484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8771,
    "Rank": 19489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8772,
    "Rank": 19490,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8773,
    "Rank": 19491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8774,
    "Rank": 19499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8775,
    "Rank": 19500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8776,
    "Rank": 19503,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8777,
    "Rank": 19506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8778,
    "Rank": 19508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8779,
    "Rank": 19512,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8780,
    "Rank": 19522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8781,
    "Rank": 19526,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8782,
    "Rank": 19537,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8783,
    "Rank": 19539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8784,
    "Rank": 19542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8785,
    "Rank": 19553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8786,
    "Rank": 19557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8787,
    "Rank": 19559,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8788,
    "Rank": 19565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8789,
    "Rank": 19567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8790,
    "Rank": 19569,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8791,
    "Rank": 19572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8792,
    "Rank": 19574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8793,
    "Rank": 19579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8794,
    "Rank": 19581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8795,
    "Rank": 19582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8796,
    "Rank": 19588,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8797,
    "Rank": 19589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8798,
    "Rank": 19592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8799,
    "Rank": 19595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8800,
    "Rank": 19596,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8801,
    "Rank": 19603,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8802,
    "Rank": 19604,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8803,
    "Rank": 19607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8804,
    "Rank": 19612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8805,
    "Rank": 19615,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8806,
    "Rank": 19624,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8807,
    "Rank": 19644,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8808,
    "Rank": 19646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8809,
    "Rank": 19651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8810,
    "Rank": 19652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8811,
    "Rank": 19658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8812,
    "Rank": 19660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8813,
    "Rank": 19664,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8814,
    "Rank": 19669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8815,
    "Rank": 19677,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8816,
    "Rank": 19679,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8817,
    "Rank": 19685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8818,
    "Rank": 19686,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8819,
    "Rank": 19688,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8820,
    "Rank": 19702,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8821,
    "Rank": 19705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8822,
    "Rank": 19718,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8823,
    "Rank": 19721,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8824,
    "Rank": 19730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8825,
    "Rank": 19731,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8826,
    "Rank": 19734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8827,
    "Rank": 19736,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8828,
    "Rank": 19749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8829,
    "Rank": 19756,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8830,
    "Rank": 19757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8831,
    "Rank": 19762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8832,
    "Rank": 19767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8833,
    "Rank": 19784,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8834,
    "Rank": 19792,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8835,
    "Rank": 19800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8836,
    "Rank": 19814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8837,
    "Rank": 19820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8838,
    "Rank": 19828,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8839,
    "Rank": 19831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8840,
    "Rank": 19839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8841,
    "Rank": 19841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8842,
    "Rank": 19844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8843,
    "Rank": 19845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8844,
    "Rank": 19848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8845,
    "Rank": 19850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8846,
    "Rank": 19854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8847,
    "Rank": 19857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8848,
    "Rank": 19867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8849,
    "Rank": 19871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8850,
    "Rank": 19879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8851,
    "Rank": 19922,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8852,
    "Rank": 19925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8853,
    "Rank": 19939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8854,
    "Rank": 19950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8855,
    "Rank": 19961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8856,
    "Rank": 19963,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8857,
    "Rank": 19966,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8858,
    "Rank": 19991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8859,
    "Rank": 19993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8860,
    "Rank": 20003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8861,
    "Rank": 20006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8862,
    "Rank": 20011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8863,
    "Rank": 20014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8864,
    "Rank": 20029,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8865,
    "Rank": 20039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8866,
    "Rank": 20053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8867,
    "Rank": 20054,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8868,
    "Rank": 20055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8869,
    "Rank": 20057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8870,
    "Rank": 20060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8871,
    "Rank": 20061,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8872,
    "Rank": 20076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8873,
    "Rank": 20079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8874,
    "Rank": 20082,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8875,
    "Rank": 20083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8876,
    "Rank": 20085,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8877,
    "Rank": 20089,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8878,
    "Rank": 20095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8879,
    "Rank": 20101,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8880,
    "Rank": 20115,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8881,
    "Rank": 20121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8882,
    "Rank": 20124,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8883,
    "Rank": 20133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8884,
    "Rank": 20136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8885,
    "Rank": 20151,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8886,
    "Rank": 20153,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8887,
    "Rank": 20156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8888,
    "Rank": 20168,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8889,
    "Rank": 20185,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8890,
    "Rank": 20188,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8891,
    "Rank": 20189,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8892,
    "Rank": 20215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8893,
    "Rank": 20219,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8894,
    "Rank": 20227,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8895,
    "Rank": 20244,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8896,
    "Rank": 20267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8897,
    "Rank": 20275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8898,
    "Rank": 20280,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8899,
    "Rank": 20281,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8900,
    "Rank": 20282,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8901,
    "Rank": 20305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8902,
    "Rank": 20320,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8903,
    "Rank": 20327,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8904,
    "Rank": 20332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8905,
    "Rank": 20350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8906,
    "Rank": 20355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8907,
    "Rank": 20363,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8908,
    "Rank": 20377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8909,
    "Rank": 20378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8910,
    "Rank": 20389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8911,
    "Rank": 20406,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8912,
    "Rank": 20407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8913,
    "Rank": 20410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8914,
    "Rank": 20415,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8915,
    "Rank": 20424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8916,
    "Rank": 20426,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8917,
    "Rank": 20430,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8918,
    "Rank": 20431,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8919,
    "Rank": 20432,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8920,
    "Rank": 20438,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8921,
    "Rank": 20439,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8922,
    "Rank": 20447,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8923,
    "Rank": 20450,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8924,
    "Rank": 20488,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8925,
    "Rank": 20497,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8926,
    "Rank": 20500,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8927,
    "Rank": 20507,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8928,
    "Rank": 20514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8929,
    "Rank": 20519,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8930,
    "Rank": 20524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8931,
    "Rank": 20556,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8932,
    "Rank": 20595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8933,
    "Rank": 20598,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8934,
    "Rank": 20623,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8935,
    "Rank": 20626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8936,
    "Rank": 20631,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8937,
    "Rank": 20639,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8938,
    "Rank": 20650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8939,
    "Rank": 20653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8940,
    "Rank": 20660,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8941,
    "Rank": 20672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8942,
    "Rank": 20677,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8943,
    "Rank": 20690,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8944,
    "Rank": 20718,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8945,
    "Rank": 20722,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8946,
    "Rank": 20728,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8947,
    "Rank": 20744,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8948,
    "Rank": 20776,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8949,
    "Rank": 20808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8950,
    "Rank": 20822,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8951,
    "Rank": 20833,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8952,
    "Rank": 20840,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8953,
    "Rank": 20844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8954,
    "Rank": 20852,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8955,
    "Rank": 20854,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8956,
    "Rank": 20860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8957,
    "Rank": 20867,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8958,
    "Rank": 20868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8959,
    "Rank": 20877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8960,
    "Rank": 20879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8961,
    "Rank": 20883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8962,
    "Rank": 20914,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8963,
    "Rank": 20940,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8964,
    "Rank": 20947,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8965,
    "Rank": 20970,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8966,
    "Rank": 20981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8967,
    "Rank": 20996,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8968,
    "Rank": 21001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8969,
    "Rank": 21007,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8970,
    "Rank": 21014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8971,
    "Rank": 21015,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8972,
    "Rank": 21022,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8973,
    "Rank": 21025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8974,
    "Rank": 21041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8975,
    "Rank": 21057,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8976,
    "Rank": 21094,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8977,
    "Rank": 21104,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8978,
    "Rank": 21106,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8979,
    "Rank": 21109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8980,
    "Rank": 21141,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8981,
    "Rank": 21164,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8982,
    "Rank": 21169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8983,
    "Rank": 21170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8984,
    "Rank": 21172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8985,
    "Rank": 21182,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8986,
    "Rank": 21186,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8987,
    "Rank": 21193,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8988,
    "Rank": 21198,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8989,
    "Rank": 21209,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8990,
    "Rank": 21210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8991,
    "Rank": 21221,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8992,
    "Rank": 21228,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8993,
    "Rank": 21231,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8994,
    "Rank": 21234,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8995,
    "Rank": 21250,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8996,
    "Rank": 21272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8997,
    "Rank": 21277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8998,
    "Rank": 21298,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 8999,
    "Rank": 21304,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9000,
    "Rank": 21311,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9001,
    "Rank": 21331,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9002,
    "Rank": 21338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9003,
    "Rank": 21341,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9004,
    "Rank": 21347,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9005,
    "Rank": 21384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9006,
    "Rank": 21388,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9007,
    "Rank": 21430,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9008,
    "Rank": 21439,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9009,
    "Rank": 21444,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9010,
    "Rank": 21472,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9011,
    "Rank": 21479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9012,
    "Rank": 21480,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9013,
    "Rank": 21485,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9014,
    "Rank": 21489,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9015,
    "Rank": 21490,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9016,
    "Rank": 21496,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9017,
    "Rank": 21499,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9018,
    "Rank": 21508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9019,
    "Rank": 21513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9020,
    "Rank": 21530,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9021,
    "Rank": 21531,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9022,
    "Rank": 21558,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9023,
    "Rank": 21569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9024,
    "Rank": 21579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9025,
    "Rank": 21591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9026,
    "Rank": 21616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9027,
    "Rank": 21622,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9028,
    "Rank": 21639,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9029,
    "Rank": 21663,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9030,
    "Rank": 21664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9031,
    "Rank": 21681,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9032,
    "Rank": 21683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9033,
    "Rank": 21702,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9034,
    "Rank": 21703,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9035,
    "Rank": 21710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9036,
    "Rank": 21712,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9037,
    "Rank": 21722,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9038,
    "Rank": 21729,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9039,
    "Rank": 21738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9040,
    "Rank": 21766,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9041,
    "Rank": 21769,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9042,
    "Rank": 21770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9043,
    "Rank": 21805,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9044,
    "Rank": 21807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9045,
    "Rank": 21813,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9046,
    "Rank": 21831,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9047,
    "Rank": 21836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9048,
    "Rank": 21840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9049,
    "Rank": 21877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9050,
    "Rank": 21891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9051,
    "Rank": 21893,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9052,
    "Rank": 21910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9053,
    "Rank": 21921,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9054,
    "Rank": 21926,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9055,
    "Rank": 21933,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9056,
    "Rank": 21936,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9057,
    "Rank": 21938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9058,
    "Rank": 21943,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9059,
    "Rank": 21946,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9060,
    "Rank": 21968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9061,
    "Rank": 21973,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9062,
    "Rank": 21993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9063,
    "Rank": 22011,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9064,
    "Rank": 22017,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9065,
    "Rank": 22023,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9066,
    "Rank": 22036,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9067,
    "Rank": 22048,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9068,
    "Rank": 22080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9069,
    "Rank": 22088,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9070,
    "Rank": 22101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9071,
    "Rank": 22109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9072,
    "Rank": 22118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9073,
    "Rank": 22121,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9074,
    "Rank": 22122,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9075,
    "Rank": 22129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9076,
    "Rank": 22144,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9077,
    "Rank": 22145,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9078,
    "Rank": 22156,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9079,
    "Rank": 22178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9080,
    "Rank": 22209,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9081,
    "Rank": 22220,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9082,
    "Rank": 22229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9083,
    "Rank": 22237,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9084,
    "Rank": 22331,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9085,
    "Rank": 22336,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9086,
    "Rank": 22340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9087,
    "Rank": 22384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9088,
    "Rank": 22387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9089,
    "Rank": 22401,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9090,
    "Rank": 22408,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9091,
    "Rank": 22414,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9092,
    "Rank": 22426,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9093,
    "Rank": 22428,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9094,
    "Rank": 22430,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9095,
    "Rank": 22446,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9096,
    "Rank": 22472,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9097,
    "Rank": 22500,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9098,
    "Rank": 22508,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9099,
    "Rank": 22525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9100,
    "Rank": 22547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9101,
    "Rank": 22561,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9102,
    "Rank": 22570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9103,
    "Rank": 22581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9104,
    "Rank": 22601,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9105,
    "Rank": 22602,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9106,
    "Rank": 22606,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9107,
    "Rank": 22612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9108,
    "Rank": 22614,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9109,
    "Rank": 22622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9110,
    "Rank": 22629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9111,
    "Rank": 22643,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9112,
    "Rank": 22654,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9113,
    "Rank": 22657,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9114,
    "Rank": 22677,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9115,
    "Rank": 22689,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9116,
    "Rank": 22692,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9117,
    "Rank": 22702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9118,
    "Rank": 22725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9119,
    "Rank": 22731,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9120,
    "Rank": 22755,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9121,
    "Rank": 22762,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9122,
    "Rank": 22770,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9123,
    "Rank": 22771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9124,
    "Rank": 22789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9125,
    "Rank": 22795,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9126,
    "Rank": 22805,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9127,
    "Rank": 22808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9128,
    "Rank": 22816,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9129,
    "Rank": 22824,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9130,
    "Rank": 22826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9131,
    "Rank": 22831,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9132,
    "Rank": 22849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9133,
    "Rank": 22861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9134,
    "Rank": 22871,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9135,
    "Rank": 22883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9136,
    "Rank": 22891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9137,
    "Rank": 22903,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9138,
    "Rank": 22913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9139,
    "Rank": 22934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9140,
    "Rank": 22935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9141,
    "Rank": 22943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9142,
    "Rank": 22958,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9143,
    "Rank": 22969,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9144,
    "Rank": 22975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9145,
    "Rank": 22979,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9146,
    "Rank": 22983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9147,
    "Rank": 22998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9148,
    "Rank": 23001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9149,
    "Rank": 23013,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9150,
    "Rank": 23026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9151,
    "Rank": 23028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9152,
    "Rank": 23029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9153,
    "Rank": 23036,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9154,
    "Rank": 23055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9155,
    "Rank": 23061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9156,
    "Rank": 23064,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9157,
    "Rank": 23070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9158,
    "Rank": 23086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9159,
    "Rank": 23087,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9160,
    "Rank": 23097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9161,
    "Rank": 23128,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9162,
    "Rank": 23138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9163,
    "Rank": 23157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9164,
    "Rank": 23171,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9165,
    "Rank": 23213,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9166,
    "Rank": 23240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9167,
    "Rank": 23245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9168,
    "Rank": 23258,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9169,
    "Rank": 23264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9170,
    "Rank": 23270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9171,
    "Rank": 23276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9172,
    "Rank": 23305,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9173,
    "Rank": 23339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9174,
    "Rank": 23361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9175,
    "Rank": 23380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9176,
    "Rank": 23391,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9177,
    "Rank": 23394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9178,
    "Rank": 23417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9179,
    "Rank": 23419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9180,
    "Rank": 23427,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9181,
    "Rank": 23453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9182,
    "Rank": 23461,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9183,
    "Rank": 23473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9184,
    "Rank": 23479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9185,
    "Rank": 23483,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9186,
    "Rank": 23495,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9187,
    "Rank": 23498,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9188,
    "Rank": 23505,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9189,
    "Rank": 23521,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9190,
    "Rank": 23544,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9191,
    "Rank": 23569,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9192,
    "Rank": 23593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9193,
    "Rank": 23620,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9194,
    "Rank": 23650,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9195,
    "Rank": 23652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9196,
    "Rank": 23697,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9197,
    "Rank": 23710,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9198,
    "Rank": 23744,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9199,
    "Rank": 23755,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9200,
    "Rank": 23786,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9201,
    "Rank": 23800,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9202,
    "Rank": 23810,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9203,
    "Rank": 23813,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9204,
    "Rank": 23828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9205,
    "Rank": 23840,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9206,
    "Rank": 23860,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9207,
    "Rank": 23881,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9208,
    "Rank": 23895,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9209,
    "Rank": 23917,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9210,
    "Rank": 23947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9211,
    "Rank": 23951,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9212,
    "Rank": 23952,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9213,
    "Rank": 23966,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9214,
    "Rank": 24000,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9215,
    "Rank": 24002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9216,
    "Rank": 24046,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9217,
    "Rank": 24064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9218,
    "Rank": 24093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9219,
    "Rank": 24097,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9220,
    "Rank": 24108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9221,
    "Rank": 24133,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9222,
    "Rank": 24136,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9223,
    "Rank": 24162,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9224,
    "Rank": 24163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9225,
    "Rank": 24172,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9226,
    "Rank": 24175,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9227,
    "Rank": 24177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9228,
    "Rank": 24209,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9229,
    "Rank": 24231,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9230,
    "Rank": 24234,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9231,
    "Rank": 24243,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9232,
    "Rank": 24277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9233,
    "Rank": 24278,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9234,
    "Rank": 24281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9235,
    "Rank": 24290,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9236,
    "Rank": 24292,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9237,
    "Rank": 24303,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9238,
    "Rank": 24310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9239,
    "Rank": 24345,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9240,
    "Rank": 24352,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9241,
    "Rank": 24357,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9242,
    "Rank": 24378,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9243,
    "Rank": 24388,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9244,
    "Rank": 24396,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9245,
    "Rank": 24400,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9246,
    "Rank": 24408,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9247,
    "Rank": 24426,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9248,
    "Rank": 24440,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9249,
    "Rank": 24459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9250,
    "Rank": 24487,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9251,
    "Rank": 24500,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9252,
    "Rank": 24506,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9253,
    "Rank": 24514,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9254,
    "Rank": 24515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9255,
    "Rank": 24519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9256,
    "Rank": 24527,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9257,
    "Rank": 24558,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9258,
    "Rank": 24567,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9259,
    "Rank": 24573,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9260,
    "Rank": 24595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9261,
    "Rank": 24598,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9262,
    "Rank": 24641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9263,
    "Rank": 24642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9264,
    "Rank": 24665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9265,
    "Rank": 24668,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9266,
    "Rank": 24678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9267,
    "Rank": 24683,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9268,
    "Rank": 24708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9269,
    "Rank": 24713,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9270,
    "Rank": 24716,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9271,
    "Rank": 24717,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9272,
    "Rank": 24754,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9273,
    "Rank": 24759,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9274,
    "Rank": 24778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9275,
    "Rank": 24785,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9276,
    "Rank": 24789,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9277,
    "Rank": 24803,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9278,
    "Rank": 24807,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9279,
    "Rank": 24808,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9280,
    "Rank": 24813,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9281,
    "Rank": 24848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9282,
    "Rank": 24868,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9283,
    "Rank": 24888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9284,
    "Rank": 24890,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9285,
    "Rank": 24895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9286,
    "Rank": 24933,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9287,
    "Rank": 24960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9288,
    "Rank": 24980,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9289,
    "Rank": 24994,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9290,
    "Rank": 24998,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9291,
    "Rank": 25001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9292,
    "Rank": 25006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9293,
    "Rank": 25013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9294,
    "Rank": 25017,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9295,
    "Rank": 25046,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9296,
    "Rank": 25047,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9297,
    "Rank": 25048,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9298,
    "Rank": 25053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9299,
    "Rank": 25078,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9300,
    "Rank": 25134,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9301,
    "Rank": 25163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9302,
    "Rank": 25173,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9303,
    "Rank": 25182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9304,
    "Rank": 25222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9305,
    "Rank": 25240,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9306,
    "Rank": 25254,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9307,
    "Rank": 25284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9308,
    "Rank": 25304,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9309,
    "Rank": 25321,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9310,
    "Rank": 25331,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9311,
    "Rank": 25332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9312,
    "Rank": 25334,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9313,
    "Rank": 25351,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9314,
    "Rank": 25355,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9315,
    "Rank": 25368,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9316,
    "Rank": 25369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9317,
    "Rank": 25421,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9318,
    "Rank": 25422,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9319,
    "Rank": 25441,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9320,
    "Rank": 25453,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9321,
    "Rank": 25478,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9322,
    "Rank": 25480,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9323,
    "Rank": 25500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9324,
    "Rank": 25505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9325,
    "Rank": 25508,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9326,
    "Rank": 25516,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9327,
    "Rank": 25542,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9328,
    "Rank": 25545,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9329,
    "Rank": 25547,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9330,
    "Rank": 25554,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9331,
    "Rank": 25565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9332,
    "Rank": 25568,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9333,
    "Rank": 25580,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9334,
    "Rank": 25593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9335,
    "Rank": 25610,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9336,
    "Rank": 25621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9337,
    "Rank": 25624,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9338,
    "Rank": 25631,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9339,
    "Rank": 25647,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9340,
    "Rank": 25656,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9341,
    "Rank": 25680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9342,
    "Rank": 25705,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9343,
    "Rank": 25743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9344,
    "Rank": 25744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9345,
    "Rank": 25748,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9346,
    "Rank": 25784,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9347,
    "Rank": 25788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9348,
    "Rank": 25789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9349,
    "Rank": 25794,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9350,
    "Rank": 25813,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9351,
    "Rank": 25836,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9352,
    "Rank": 25854,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9353,
    "Rank": 25877,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9354,
    "Rank": 25905,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9355,
    "Rank": 25940,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9356,
    "Rank": 25955,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9357,
    "Rank": 25956,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9358,
    "Rank": 25970,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9359,
    "Rank": 26015,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9360,
    "Rank": 26054,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9361,
    "Rank": 26056,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9362,
    "Rank": 26068,
    "Allotted Quota": "Muslim OBC Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9363,
    "Rank": 26076,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9364,
    "Rank": 26111,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9365,
    "Rank": 26126,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9366,
    "Rank": 26133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9367,
    "Rank": 26141,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9368,
    "Rank": 26147,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9369,
    "Rank": 26153,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9370,
    "Rank": 26168,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9371,
    "Rank": 26173,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9372,
    "Rank": 26181,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9373,
    "Rank": 26204,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9374,
    "Rank": 26209,
    "Allotted Quota": "All India",
    "Allotted Institute": "NDMC Medical College, Delhi,North Delhi Municipal Corporation Medical College and Hindu Rao Hospital, Malka Ganj, Delhi (NCT), 110007",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9375,
    "Rank": 26224,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9376,
    "Rank": 26229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9377,
    "Rank": 26240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9378,
    "Rank": 26243,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9379,
    "Rank": 26264,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9380,
    "Rank": 26276,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9381,
    "Rank": 26291,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9382,
    "Rank": 26308,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9383,
    "Rank": 26331,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9384,
    "Rank": 26332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9385,
    "Rank": 26356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9386,
    "Rank": 26366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9387,
    "Rank": 26375,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9388,
    "Rank": 26384,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9389,
    "Rank": 26398,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9390,
    "Rank": 26411,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9391,
    "Rank": 26424,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9392,
    "Rank": 26429,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9393,
    "Rank": 26437,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9394,
    "Rank": 26467,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9395,
    "Rank": 26474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9396,
    "Rank": 26479,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9397,
    "Rank": 26483,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9398,
    "Rank": 26484,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9399,
    "Rank": 26490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9400,
    "Rank": 26514,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9401,
    "Rank": 26525,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9402,
    "Rank": 26548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9403,
    "Rank": 26549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9404,
    "Rank": 26578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9405,
    "Rank": 26599,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9406,
    "Rank": 26605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9407,
    "Rank": 26622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9408,
    "Rank": 26628,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9409,
    "Rank": 26630,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9410,
    "Rank": 26649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9411,
    "Rank": 26650,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9412,
    "Rank": 26656,
    "Allotted Quota": "Muslim Women Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9413,
    "Rank": 26691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9414,
    "Rank": 26725,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9415,
    "Rank": 26735,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9416,
    "Rank": 26743,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9417,
    "Rank": 26766,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9418,
    "Rank": 26776,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9419,
    "Rank": 26799,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9420,
    "Rank": 26823,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9421,
    "Rank": 26847,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9422,
    "Rank": 26867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9423,
    "Rank": 26886,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9424,
    "Rank": 26902,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9425,
    "Rank": 26904,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9426,
    "Rank": 26906,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9427,
    "Rank": 26907,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9428,
    "Rank": 26915,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9429,
    "Rank": 26953,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9430,
    "Rank": 26970,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9431,
    "Rank": 26973,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9432,
    "Rank": 26979,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9433,
    "Rank": 26983,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9434,
    "Rank": 27010,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9435,
    "Rank": 27012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9436,
    "Rank": 27030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9437,
    "Rank": 27072,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9438,
    "Rank": 27100,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9439,
    "Rank": 27102,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9440,
    "Rank": 27112,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9441,
    "Rank": 27144,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9442,
    "Rank": 27196,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9443,
    "Rank": 27204,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9444,
    "Rank": 27209,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9445,
    "Rank": 27210,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9446,
    "Rank": 27219,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9447,
    "Rank": 27223,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9448,
    "Rank": 27227,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9449,
    "Rank": 27262,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9450,
    "Rank": 27273,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9451,
    "Rank": 27276,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9452,
    "Rank": 27310,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9453,
    "Rank": 27319,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9454,
    "Rank": 27323,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9455,
    "Rank": 27343,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9456,
    "Rank": 27356,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9457,
    "Rank": 27363,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9458,
    "Rank": 27378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9459,
    "Rank": 27384,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9460,
    "Rank": 27422,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9461,
    "Rank": 27425,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9462,
    "Rank": 27447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9463,
    "Rank": 27475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9464,
    "Rank": 27480,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9465,
    "Rank": 27501,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9466,
    "Rank": 27514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9467,
    "Rank": 27517,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9468,
    "Rank": 27523,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9469,
    "Rank": 27535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9470,
    "Rank": 27545,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9471,
    "Rank": 27557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9472,
    "Rank": 27559,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9473,
    "Rank": 27586,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9474,
    "Rank": 27597,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9475,
    "Rank": 27603,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9476,
    "Rank": 27613,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9477,
    "Rank": 27614,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9478,
    "Rank": 27631,
    "Allotted Quota": "Muslim Women Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9479,
    "Rank": 27637,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9480,
    "Rank": 27677,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9481,
    "Rank": 27685,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9482,
    "Rank": 27698,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9483,
    "Rank": 27711,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9484,
    "Rank": 27721,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9485,
    "Rank": 27743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9486,
    "Rank": 27747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9487,
    "Rank": 27749,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9488,
    "Rank": 27778,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9489,
    "Rank": 27788,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9490,
    "Rank": 27791,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9491,
    "Rank": 27801,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9492,
    "Rank": 27809,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9493,
    "Rank": 27810,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9494,
    "Rank": 27811,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9495,
    "Rank": 27862,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9496,
    "Rank": 27875,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9497,
    "Rank": 27894,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9498,
    "Rank": 27900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9499,
    "Rank": 27942,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9500,
    "Rank": 27953,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9501,
    "Rank": 27961,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9502,
    "Rank": 27983,
    "Allotted Quota": "Muslim Women Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9503,
    "Rank": 28008,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9504,
    "Rank": 28009,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9505,
    "Rank": 28016,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9506,
    "Rank": 28026,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9507,
    "Rank": 28028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9508,
    "Rank": 28050,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9509,
    "Rank": 28051,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9510,
    "Rank": 28068,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9511,
    "Rank": 28070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9512,
    "Rank": 28075,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9513,
    "Rank": 28084,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9514,
    "Rank": 28090,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9515,
    "Rank": 28095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9516,
    "Rank": 28097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9517,
    "Rank": 28104,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9518,
    "Rank": 28116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9519,
    "Rank": 28117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9520,
    "Rank": 28130,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9521,
    "Rank": 28133,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9522,
    "Rank": 28135,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9523,
    "Rank": 28136,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9524,
    "Rank": 28143,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9525,
    "Rank": 28148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9526,
    "Rank": 28157,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9527,
    "Rank": 28168,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9528,
    "Rank": 28187,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9529,
    "Rank": 28196,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9530,
    "Rank": 28197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9531,
    "Rank": 28211,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9532,
    "Rank": 28214,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9533,
    "Rank": 28217,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9534,
    "Rank": 28222,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9535,
    "Rank": 28260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9536,
    "Rank": 28261,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9537,
    "Rank": 28263,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9538,
    "Rank": 28267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9539,
    "Rank": 28273,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9540,
    "Rank": 28278,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9541,
    "Rank": 28289,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9542,
    "Rank": 28292,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9543,
    "Rank": 28295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9544,
    "Rank": 28323,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9545,
    "Rank": 28324,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9546,
    "Rank": 28327,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9547,
    "Rank": 28343,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9548,
    "Rank": 28357,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9549,
    "Rank": 28374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9550,
    "Rank": 28380,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9551,
    "Rank": 28386,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9552,
    "Rank": 28405,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9553,
    "Rank": 28417,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9554,
    "Rank": 28421,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9555,
    "Rank": 28441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9556,
    "Rank": 28465,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9557,
    "Rank": 28488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9558,
    "Rank": 28489,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9559,
    "Rank": 28490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9560,
    "Rank": 28503,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9561,
    "Rank": 28507,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9562,
    "Rank": 28522,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9563,
    "Rank": 28523,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9564,
    "Rank": 28524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9565,
    "Rank": 28529,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9566,
    "Rank": 28535,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9567,
    "Rank": 28553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9568,
    "Rank": 28555,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9569,
    "Rank": 28559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9570,
    "Rank": 28562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9571,
    "Rank": 28567,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9572,
    "Rank": 28573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9573,
    "Rank": 28594,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9574,
    "Rank": 28602,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9575,
    "Rank": 28618,
    "Allotted Quota": "Muslim Women Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9576,
    "Rank": 28622,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9577,
    "Rank": 28653,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9578,
    "Rank": 28660,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9579,
    "Rank": 28676,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9580,
    "Rank": 28679,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9581,
    "Rank": 28680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9582,
    "Rank": 28698,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9583,
    "Rank": 28701,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9584,
    "Rank": 28721,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9585,
    "Rank": 28733,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9586,
    "Rank": 28746,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9587,
    "Rank": 28756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9588,
    "Rank": 28769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9589,
    "Rank": 28778,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9590,
    "Rank": 28803,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9591,
    "Rank": 28811,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9592,
    "Rank": 28828,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9593,
    "Rank": 28841,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9594,
    "Rank": 28852,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9595,
    "Rank": 28856,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9596,
    "Rank": 28862,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9597,
    "Rank": 28877,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9598,
    "Rank": 28884,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9599,
    "Rank": 28885,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9600,
    "Rank": 28889,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9601,
    "Rank": 28890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9602,
    "Rank": 28892,
    "Allotted Quota": "Muslim Women Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9603,
    "Rank": 28904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9604,
    "Rank": 28912,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9605,
    "Rank": 28916,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9606,
    "Rank": 28927,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9607,
    "Rank": 28966,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9608,
    "Rank": 28971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9609,
    "Rank": 28978,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9610,
    "Rank": 29013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9611,
    "Rank": 29016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9612,
    "Rank": 29024,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9613,
    "Rank": 29031,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9614,
    "Rank": 29063,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9615,
    "Rank": 29066,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9616,
    "Rank": 29080,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9617,
    "Rank": 29090,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9618,
    "Rank": 29097,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9619,
    "Rank": 29100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9620,
    "Rank": 29120,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9621,
    "Rank": 29129,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9622,
    "Rank": 29130,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9623,
    "Rank": 29161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9624,
    "Rank": 29168,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9625,
    "Rank": 29178,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9626,
    "Rank": 29187,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9627,
    "Rank": 29194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9628,
    "Rank": 29230,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9629,
    "Rank": 29245,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9630,
    "Rank": 29246,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9631,
    "Rank": 29249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9632,
    "Rank": 29254,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9633,
    "Rank": 29269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9634,
    "Rank": 29296,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9635,
    "Rank": 29308,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9636,
    "Rank": 29335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9637,
    "Rank": 29355,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9638,
    "Rank": 29367,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9639,
    "Rank": 29372,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9640,
    "Rank": 29385,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9641,
    "Rank": 29396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9642,
    "Rank": 29414,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9643,
    "Rank": 29431,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9644,
    "Rank": 29433,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9645,
    "Rank": 29434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9646,
    "Rank": 29456,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9647,
    "Rank": 29457,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9648,
    "Rank": 29470,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9649,
    "Rank": 29478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9650,
    "Rank": 29498,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9651,
    "Rank": 29502,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9652,
    "Rank": 29524,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9653,
    "Rank": 29531,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9654,
    "Rank": 29535,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9655,
    "Rank": 29564,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9656,
    "Rank": 29583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9657,
    "Rank": 29600,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9658,
    "Rank": 29602,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9659,
    "Rank": 29612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9660,
    "Rank": 29621,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9661,
    "Rank": 29634,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9662,
    "Rank": 29638,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9663,
    "Rank": 29640,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9664,
    "Rank": 29657,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9665,
    "Rank": 29662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9666,
    "Rank": 29681,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9667,
    "Rank": 29693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9668,
    "Rank": 29716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9669,
    "Rank": 29726,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9670,
    "Rank": 29729,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9671,
    "Rank": 29750,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9672,
    "Rank": 29758,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9673,
    "Rank": 29759,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9674,
    "Rank": 29788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9675,
    "Rank": 29805,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9676,
    "Rank": 29807,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9677,
    "Rank": 29829,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9678,
    "Rank": 29866,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9679,
    "Rank": 29882,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9680,
    "Rank": 29900,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9681,
    "Rank": 29935,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9682,
    "Rank": 29954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9683,
    "Rank": 29982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9684,
    "Rank": 29986,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9685,
    "Rank": 29990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9686,
    "Rank": 29995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9687,
    "Rank": 30003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9688,
    "Rank": 30023,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9689,
    "Rank": 30025,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9690,
    "Rank": 30037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9691,
    "Rank": 30051,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9692,
    "Rank": 30062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9693,
    "Rank": 30068,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9694,
    "Rank": 30089,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9695,
    "Rank": 30100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9696,
    "Rank": 30114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9697,
    "Rank": 30121,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9698,
    "Rank": 30126,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9699,
    "Rank": 30131,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9700,
    "Rank": 30135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9701,
    "Rank": 30141,
    "Allotted Quota": "Muslim OBC Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9702,
    "Rank": 30149,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9703,
    "Rank": 30150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9704,
    "Rank": 30152,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9705,
    "Rank": 30188,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9706,
    "Rank": 30213,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9707,
    "Rank": 30228,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9708,
    "Rank": 30234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9709,
    "Rank": 30239,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9710,
    "Rank": 30245,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9711,
    "Rank": 30271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9712,
    "Rank": 30273,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9713,
    "Rank": 30278,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9714,
    "Rank": 30309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9715,
    "Rank": 30320,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9716,
    "Rank": 30351,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9717,
    "Rank": 30361,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9718,
    "Rank": 30370,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9719,
    "Rank": 30375,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9720,
    "Rank": 30376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9721,
    "Rank": 30383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9722,
    "Rank": 30390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9723,
    "Rank": 30395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9724,
    "Rank": 30396,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9725,
    "Rank": 30399,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9726,
    "Rank": 30400,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9727,
    "Rank": 30417,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9728,
    "Rank": 30420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9729,
    "Rank": 30437,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9730,
    "Rank": 30478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9731,
    "Rank": 30510,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9732,
    "Rank": 30515,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9733,
    "Rank": 30529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9734,
    "Rank": 30541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9735,
    "Rank": 30562,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9736,
    "Rank": 30563,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9737,
    "Rank": 30613,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9738,
    "Rank": 30618,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9739,
    "Rank": 30622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9740,
    "Rank": 30643,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9741,
    "Rank": 30671,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9742,
    "Rank": 30721,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9743,
    "Rank": 30748,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9744,
    "Rank": 30769,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9745,
    "Rank": 30780,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9746,
    "Rank": 30827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9747,
    "Rank": 30837,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9748,
    "Rank": 30838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9749,
    "Rank": 30842,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9750,
    "Rank": 30860,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9751,
    "Rank": 30928,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9752,
    "Rank": 30968,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9753,
    "Rank": 30982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9754,
    "Rank": 30987,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9755,
    "Rank": 31005,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9756,
    "Rank": 31019,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9757,
    "Rank": 31023,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9758,
    "Rank": 31031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9759,
    "Rank": 31038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9760,
    "Rank": 31065,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9761,
    "Rank": 31093,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9762,
    "Rank": 31094,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9763,
    "Rank": 31099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9764,
    "Rank": 31123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9765,
    "Rank": 31131,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9766,
    "Rank": 31149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9767,
    "Rank": 31150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9768,
    "Rank": 31152,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9769,
    "Rank": 31153,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9770,
    "Rank": 31166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9771,
    "Rank": 31191,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9772,
    "Rank": 31198,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9773,
    "Rank": 31207,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9774,
    "Rank": 31219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9775,
    "Rank": 31237,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9776,
    "Rank": 31274,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9777,
    "Rank": 31283,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9778,
    "Rank": 31285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9779,
    "Rank": 31303,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9780,
    "Rank": 31308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9781,
    "Rank": 31319,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9782,
    "Rank": 31337,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9783,
    "Rank": 31346,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9784,
    "Rank": 31356,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9785,
    "Rank": 31359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9786,
    "Rank": 31368,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9787,
    "Rank": 31371,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9788,
    "Rank": 31381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9789,
    "Rank": 31434,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9790,
    "Rank": 31454,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9791,
    "Rank": 31456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9792,
    "Rank": 31468,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9793,
    "Rank": 31482,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9794,
    "Rank": 31486,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9795,
    "Rank": 31499,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9796,
    "Rank": 31509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9797,
    "Rank": 31516,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9798,
    "Rank": 31521,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9799,
    "Rank": 31531,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9800,
    "Rank": 31552,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9801,
    "Rank": 31557,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9802,
    "Rank": 31559,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9803,
    "Rank": 31562,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9804,
    "Rank": 31567,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9805,
    "Rank": 31572,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9806,
    "Rank": 31576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9807,
    "Rank": 31595,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9808,
    "Rank": 31615,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9809,
    "Rank": 31656,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9810,
    "Rank": 31673,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9811,
    "Rank": 31697,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9812,
    "Rank": 31704,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9813,
    "Rank": 31725,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9814,
    "Rank": 31734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9815,
    "Rank": 31755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9816,
    "Rank": 31758,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9817,
    "Rank": 31763,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9818,
    "Rank": 31768,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9819,
    "Rank": 31790,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9820,
    "Rank": 31792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9821,
    "Rank": 31795,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9822,
    "Rank": 31796,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9823,
    "Rank": 31804,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9824,
    "Rank": 31815,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9825,
    "Rank": 31831,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9826,
    "Rank": 31837,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9827,
    "Rank": 31869,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9828,
    "Rank": 31894,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9829,
    "Rank": 31900,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9830,
    "Rank": 31901,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9831,
    "Rank": 31912,
    "Allotted Quota": "Muslim OBC Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9832,
    "Rank": 31930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9833,
    "Rank": 31936,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9834,
    "Rank": 31957,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9835,
    "Rank": 31963,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9836,
    "Rank": 31966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9837,
    "Rank": 31993,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9838,
    "Rank": 32000,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9839,
    "Rank": 32005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9840,
    "Rank": 32008,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9841,
    "Rank": 32037,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9842,
    "Rank": 32058,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9843,
    "Rank": 32080,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9844,
    "Rank": 32095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9845,
    "Rank": 32110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9846,
    "Rank": 32129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9847,
    "Rank": 32130,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9848,
    "Rank": 32131,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9849,
    "Rank": 32137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9850,
    "Rank": 32139,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9851,
    "Rank": 32146,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9852,
    "Rank": 32188,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9853,
    "Rank": 32198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9854,
    "Rank": 32199,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9855,
    "Rank": 32208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9856,
    "Rank": 32233,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9857,
    "Rank": 32235,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9858,
    "Rank": 32256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9859,
    "Rank": 32293,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9860,
    "Rank": 32301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9861,
    "Rank": 32307,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9862,
    "Rank": 32309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9863,
    "Rank": 32310,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9864,
    "Rank": 32313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9865,
    "Rank": 32322,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9866,
    "Rank": 32340,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9867,
    "Rank": 32344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9868,
    "Rank": 32355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9869,
    "Rank": 32389,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9870,
    "Rank": 32391,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9871,
    "Rank": 32394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9872,
    "Rank": 32395,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9873,
    "Rank": 32416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9874,
    "Rank": 32432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9875,
    "Rank": 32463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9876,
    "Rank": 32469,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9877,
    "Rank": 32472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9878,
    "Rank": 32484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9879,
    "Rank": 32494,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9880,
    "Rank": 32501,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9881,
    "Rank": 32529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9882,
    "Rank": 32534,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9883,
    "Rank": 32553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9884,
    "Rank": 32565,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9885,
    "Rank": 32582,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9886,
    "Rank": 32588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9887,
    "Rank": 32599,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9888,
    "Rank": 32609,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9889,
    "Rank": 32619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9890,
    "Rank": 32635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9891,
    "Rank": 32678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9892,
    "Rank": 32735,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9893,
    "Rank": 32743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9894,
    "Rank": 32757,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9895,
    "Rank": 32758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9896,
    "Rank": 32766,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9897,
    "Rank": 32781,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9898,
    "Rank": 32796,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9899,
    "Rank": 32812,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9900,
    "Rank": 32820,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9901,
    "Rank": 32826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9902,
    "Rank": 32836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9903,
    "Rank": 32839,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9904,
    "Rank": 32852,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9905,
    "Rank": 32856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9906,
    "Rank": 32860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9907,
    "Rank": 32877,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9908,
    "Rank": 32881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9909,
    "Rank": 32899,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9910,
    "Rank": 32945,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9911,
    "Rank": 32965,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9912,
    "Rank": 32981,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9913,
    "Rank": 32993,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9914,
    "Rank": 32999,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9915,
    "Rank": 33004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9916,
    "Rank": 33033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9917,
    "Rank": 33079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9918,
    "Rank": 33086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9919,
    "Rank": 33100,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9920,
    "Rank": 33113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9921,
    "Rank": 33114,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9922,
    "Rank": 33115,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9923,
    "Rank": 33123,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9924,
    "Rank": 33142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9925,
    "Rank": 33145,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9926,
    "Rank": 33163,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9927,
    "Rank": 33205,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9928,
    "Rank": 33225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9929,
    "Rank": 33228,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9930,
    "Rank": 33247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9931,
    "Rank": 33277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9932,
    "Rank": 33279,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9933,
    "Rank": 33320,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9934,
    "Rank": 33321,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9935,
    "Rank": 33333,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9936,
    "Rank": 33350,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9937,
    "Rank": 33406,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9938,
    "Rank": 33422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9939,
    "Rank": 33425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9940,
    "Rank": 33433,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9941,
    "Rank": 33434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9942,
    "Rank": 33440,
    "Allotted Quota": "Muslim OBC Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9943,
    "Rank": 33459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9944,
    "Rank": 33470,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9945,
    "Rank": 33485,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9946,
    "Rank": 33508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9947,
    "Rank": 33517,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.N.T. MEDICAL COLLEGE, UDAIPUR,Opposite Court Chourha, Udaipur, Rajasthan, 313001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9948,
    "Rank": 33521,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9949,
    "Rank": 33537,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9950,
    "Rank": 33547,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9951,
    "Rank": 33560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9952,
    "Rank": 33583,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9953,
    "Rank": 33600,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9954,
    "Rank": 33602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9955,
    "Rank": 33610,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9956,
    "Rank": 33618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9957,
    "Rank": 33620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9958,
    "Rank": 33624,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9959,
    "Rank": 33638,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9960,
    "Rank": 33644,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9961,
    "Rank": 33707,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9962,
    "Rank": 33709,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9963,
    "Rank": 33713,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9964,
    "Rank": 33714,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9965,
    "Rank": 33715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9966,
    "Rank": 33723,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9967,
    "Rank": 33777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9968,
    "Rank": 33798,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9969,
    "Rank": 33802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9970,
    "Rank": 33805,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9971,
    "Rank": 33817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9972,
    "Rank": 33818,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9973,
    "Rank": 33825,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9974,
    "Rank": 33864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9975,
    "Rank": 33868,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9976,
    "Rank": 33874,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9977,
    "Rank": 33905,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9978,
    "Rank": 33910,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9979,
    "Rank": 33913,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9980,
    "Rank": 33926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9981,
    "Rank": 33931,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9982,
    "Rank": 33965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9983,
    "Rank": 33979,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9984,
    "Rank": 33985,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9985,
    "Rank": 34043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9986,
    "Rank": 34053,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9987,
    "Rank": 34081,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9988,
    "Rank": 34085,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9989,
    "Rank": 34095,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9990,
    "Rank": 34096,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9991,
    "Rank": 34100,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9992,
    "Rank": 34153,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9993,
    "Rank": 34159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9994,
    "Rank": 34169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9995,
    "Rank": 34186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9996,
    "Rank": 34197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9997,
    "Rank": 34198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9998,
    "Rank": 34211,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 9999,
    "Rank": 34214,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10000,
    "Rank": 34218,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10001,
    "Rank": 34221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10002,
    "Rank": 34244,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10003,
    "Rank": 34245,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10004,
    "Rank": 34266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10005,
    "Rank": 34282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10006,
    "Rank": 34302,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10007,
    "Rank": 34319,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10008,
    "Rank": 34325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10009,
    "Rank": 34345,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10010,
    "Rank": 34348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10011,
    "Rank": 34361,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10012,
    "Rank": 34400,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10013,
    "Rank": 34411,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10014,
    "Rank": 34420,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10015,
    "Rank": 34430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10016,
    "Rank": 34442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10017,
    "Rank": 34443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10018,
    "Rank": 34445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10019,
    "Rank": 34447,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10020,
    "Rank": 34457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10021,
    "Rank": 34470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10022,
    "Rank": 34486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10023,
    "Rank": 34528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10024,
    "Rank": 34530,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10025,
    "Rank": 34537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10026,
    "Rank": 34543,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10027,
    "Rank": 34544,
    "Allotted Quota": "Muslim Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10028,
    "Rank": 34549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10029,
    "Rank": 34551,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10030,
    "Rank": 34586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10031,
    "Rank": 34588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10032,
    "Rank": 34596,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10033,
    "Rank": 34628,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10034,
    "Rank": 34634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10035,
    "Rank": 34639,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10036,
    "Rank": 34648,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10037,
    "Rank": 34652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10038,
    "Rank": 34653,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10039,
    "Rank": 34657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10040,
    "Rank": 34662,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10041,
    "Rank": 34677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10042,
    "Rank": 34696,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10043,
    "Rank": 34727,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10044,
    "Rank": 34738,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10045,
    "Rank": 34748,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10046,
    "Rank": 34765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10047,
    "Rank": 34788,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10048,
    "Rank": 34805,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10049,
    "Rank": 34832,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10050,
    "Rank": 34852,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10051,
    "Rank": 34865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10052,
    "Rank": 34866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10053,
    "Rank": 34903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10054,
    "Rank": 34918,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10055,
    "Rank": 34932,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10056,
    "Rank": 34942,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10057,
    "Rank": 34951,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10058,
    "Rank": 34954,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10059,
    "Rank": 34961,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10060,
    "Rank": 34984,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10061,
    "Rank": 34992,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10062,
    "Rank": 35022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10063,
    "Rank": 35026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10064,
    "Rank": 35059,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10065,
    "Rank": 35145,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10066,
    "Rank": 35146,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10067,
    "Rank": 35159,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10068,
    "Rank": 35169,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10069,
    "Rank": 35171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10070,
    "Rank": 35204,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10071,
    "Rank": 35213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10072,
    "Rank": 35223,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10073,
    "Rank": 35228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10074,
    "Rank": 35240,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10075,
    "Rank": 35275,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10076,
    "Rank": 35302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10077,
    "Rank": 35306,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10078,
    "Rank": 35310,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10079,
    "Rank": 35312,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10080,
    "Rank": 35322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10081,
    "Rank": 35323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10082,
    "Rank": 35327,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10083,
    "Rank": 35334,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10084,
    "Rank": 35376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10085,
    "Rank": 35378,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10086,
    "Rank": 35384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10087,
    "Rank": 35387,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10088,
    "Rank": 35407,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10089,
    "Rank": 35414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10090,
    "Rank": 35417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10091,
    "Rank": 35422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10092,
    "Rank": 35437,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10093,
    "Rank": 35441,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10094,
    "Rank": 35449,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10095,
    "Rank": 35508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10096,
    "Rank": 35513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10097,
    "Rank": 35529,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10098,
    "Rank": 35534,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10099,
    "Rank": 35535,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10100,
    "Rank": 35569,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10101,
    "Rank": 35581,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10102,
    "Rank": 35606,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10103,
    "Rank": 35651,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10104,
    "Rank": 35690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10105,
    "Rank": 35718,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10106,
    "Rank": 35719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10107,
    "Rank": 35727,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10108,
    "Rank": 35748,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10109,
    "Rank": 35750,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10110,
    "Rank": 35770,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10111,
    "Rank": 35776,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10112,
    "Rank": 35779,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10113,
    "Rank": 35793,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10114,
    "Rank": 35795,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10115,
    "Rank": 35956,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10116,
    "Rank": 35964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10117,
    "Rank": 35987,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10118,
    "Rank": 35989,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10119,
    "Rank": 36001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10120,
    "Rank": 36033,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10121,
    "Rank": 36068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10122,
    "Rank": 36070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10123,
    "Rank": 36083,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10124,
    "Rank": 36098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10125,
    "Rank": 36104,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10126,
    "Rank": 36115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10127,
    "Rank": 36138,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10128,
    "Rank": 36165,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10129,
    "Rank": 36205,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10130,
    "Rank": 36214,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10131,
    "Rank": 36222,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10132,
    "Rank": 36228,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10133,
    "Rank": 36248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10134,
    "Rank": 36252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10135,
    "Rank": 36271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10136,
    "Rank": 36305,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10137,
    "Rank": 36343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10138,
    "Rank": 36363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10139,
    "Rank": 36365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10140,
    "Rank": 36366,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10141,
    "Rank": 36379,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10142,
    "Rank": 36423,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10143,
    "Rank": 36436,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10144,
    "Rank": 36445,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10145,
    "Rank": 36448,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10146,
    "Rank": 36454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10147,
    "Rank": 36523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10148,
    "Rank": 36547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10149,
    "Rank": 36548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10150,
    "Rank": 36574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10151,
    "Rank": 36595,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10152,
    "Rank": 36604,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10153,
    "Rank": 36611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10154,
    "Rank": 36619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10155,
    "Rank": 36620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10156,
    "Rank": 36630,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10157,
    "Rank": 36632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10158,
    "Rank": 36643,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10159,
    "Rank": 36660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10160,
    "Rank": 36663,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10161,
    "Rank": 36665,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10162,
    "Rank": 36694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10163,
    "Rank": 36695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10164,
    "Rank": 36703,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10165,
    "Rank": 36709,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10166,
    "Rank": 36717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10167,
    "Rank": 36728,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10168,
    "Rank": 36749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10169,
    "Rank": 36780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10170,
    "Rank": 36786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10171,
    "Rank": 36792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10172,
    "Rank": 36801,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10173,
    "Rank": 36804,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10174,
    "Rank": 36811,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10175,
    "Rank": 36824,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10176,
    "Rank": 36828,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10177,
    "Rank": 36841,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10178,
    "Rank": 36868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10179,
    "Rank": 36869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10180,
    "Rank": 36877,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10181,
    "Rank": 36901,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10182,
    "Rank": 36912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10183,
    "Rank": 36929,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10184,
    "Rank": 36948,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10185,
    "Rank": 36950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10186,
    "Rank": 36951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10187,
    "Rank": 36952,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10188,
    "Rank": 36972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10189,
    "Rank": 36981,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10190,
    "Rank": 36982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10191,
    "Rank": 36984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10192,
    "Rank": 36986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10193,
    "Rank": 37003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10194,
    "Rank": 37004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10195,
    "Rank": 37024,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10196,
    "Rank": 37027,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10197,
    "Rank": 37029,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10198,
    "Rank": 37047,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10199,
    "Rank": 37068,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10200,
    "Rank": 37074,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10201,
    "Rank": 37076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10202,
    "Rank": 37101,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10203,
    "Rank": 37109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10204,
    "Rank": 37121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10205,
    "Rank": 37134,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10206,
    "Rank": 37161,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10207,
    "Rank": 37164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10208,
    "Rank": 37168,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10209,
    "Rank": 37169,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10210,
    "Rank": 37183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10211,
    "Rank": 37184,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10212,
    "Rank": 37235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10213,
    "Rank": 37258,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10214,
    "Rank": 37269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10215,
    "Rank": 37283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10216,
    "Rank": 37287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10217,
    "Rank": 37303,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10218,
    "Rank": 37328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10219,
    "Rank": 37334,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10220,
    "Rank": 37335,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10221,
    "Rank": 37342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10222,
    "Rank": 37364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10223,
    "Rank": 37368,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10224,
    "Rank": 37386,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10225,
    "Rank": 37394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10226,
    "Rank": 37396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10227,
    "Rank": 37421,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10228,
    "Rank": 37426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10229,
    "Rank": 37437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10230,
    "Rank": 37452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10231,
    "Rank": 37454,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10232,
    "Rank": 37471,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10233,
    "Rank": 37482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10234,
    "Rank": 37490,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10235,
    "Rank": 37491,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10236,
    "Rank": 37509,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10237,
    "Rank": 37512,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10238,
    "Rank": 37529,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10239,
    "Rank": 37538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10240,
    "Rank": 37546,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10241,
    "Rank": 37550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10242,
    "Rank": 37557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10243,
    "Rank": 37563,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10244,
    "Rank": 37578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10245,
    "Rank": 37592,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10246,
    "Rank": 37609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10247,
    "Rank": 37613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10248,
    "Rank": 37615,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10249,
    "Rank": 37619,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10250,
    "Rank": 37626,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10251,
    "Rank": 37664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10252,
    "Rank": 37699,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10253,
    "Rank": 37725,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10254,
    "Rank": 37750,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10255,
    "Rank": 37757,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10256,
    "Rank": 37765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10257,
    "Rank": 37792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10258,
    "Rank": 37809,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10259,
    "Rank": 37866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10260,
    "Rank": 37895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10261,
    "Rank": 37901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10262,
    "Rank": 37905,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10263,
    "Rank": 37928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10264,
    "Rank": 37935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10265,
    "Rank": 37955,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10266,
    "Rank": 37961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10267,
    "Rank": 37968,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10268,
    "Rank": 37972,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10269,
    "Rank": 38015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10270,
    "Rank": 38043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10271,
    "Rank": 38045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10272,
    "Rank": 38108,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10273,
    "Rank": 38114,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10274,
    "Rank": 38121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10275,
    "Rank": 38125,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10276,
    "Rank": 38137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10277,
    "Rank": 38158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10278,
    "Rank": 38159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10279,
    "Rank": 38196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10280,
    "Rank": 38215,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10281,
    "Rank": 38258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10282,
    "Rank": 38260,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10283,
    "Rank": 38297,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10284,
    "Rank": 38334,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10285,
    "Rank": 38361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10286,
    "Rank": 38380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10287,
    "Rank": 38384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10288,
    "Rank": 38386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10289,
    "Rank": 38389,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10290,
    "Rank": 38397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10291,
    "Rank": 38401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10292,
    "Rank": 38407,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10293,
    "Rank": 38411,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10294,
    "Rank": 38421,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10295,
    "Rank": 38426,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10296,
    "Rank": 38433,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10297,
    "Rank": 38439,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10298,
    "Rank": 38443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10299,
    "Rank": 38455,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10300,
    "Rank": 38461,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10301,
    "Rank": 38488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10302,
    "Rank": 38492,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10303,
    "Rank": 38520,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10304,
    "Rank": 38529,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10305,
    "Rank": 38535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10306,
    "Rank": 38559,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10307,
    "Rank": 38563,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10308,
    "Rank": 38568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10309,
    "Rank": 38575,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10310,
    "Rank": 38588,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10311,
    "Rank": 38596,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10312,
    "Rank": 38617,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10313,
    "Rank": 38648,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10314,
    "Rank": 38670,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10315,
    "Rank": 38674,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10316,
    "Rank": 38682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10317,
    "Rank": 38699,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10318,
    "Rank": 38700,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10319,
    "Rank": 38734,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10320,
    "Rank": 38742,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10321,
    "Rank": 38804,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10322,
    "Rank": 38832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10323,
    "Rank": 38840,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10324,
    "Rank": 38859,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10325,
    "Rank": 38862,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10326,
    "Rank": 38871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10327,
    "Rank": 38875,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10328,
    "Rank": 38904,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10329,
    "Rank": 38909,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10330,
    "Rank": 38925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10331,
    "Rank": 38928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10332,
    "Rank": 38955,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10333,
    "Rank": 39009,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10334,
    "Rank": 39014,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10335,
    "Rank": 39029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10336,
    "Rank": 39043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10337,
    "Rank": 39073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10338,
    "Rank": 39082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10339,
    "Rank": 39083,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10340,
    "Rank": 39098,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10341,
    "Rank": 39113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10342,
    "Rank": 39153,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10343,
    "Rank": 39168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10344,
    "Rank": 39181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10345,
    "Rank": 39183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10346,
    "Rank": 39193,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10347,
    "Rank": 39195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10348,
    "Rank": 39271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10349,
    "Rank": 39288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10350,
    "Rank": 39308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10351,
    "Rank": 39318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10352,
    "Rank": 39324,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10353,
    "Rank": 39335,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10354,
    "Rank": 39406,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10355,
    "Rank": 39433,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10356,
    "Rank": 39440,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10357,
    "Rank": 39498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10358,
    "Rank": 39533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10359,
    "Rank": 39540,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10360,
    "Rank": 39553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10361,
    "Rank": 39556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10362,
    "Rank": 39570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10363,
    "Rank": 39583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10364,
    "Rank": 39588,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10365,
    "Rank": 39613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10366,
    "Rank": 39618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10367,
    "Rank": 39637,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10368,
    "Rank": 39641,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10369,
    "Rank": 39685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10370,
    "Rank": 39715,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10371,
    "Rank": 39723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10372,
    "Rank": 39750,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10373,
    "Rank": 39758,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10374,
    "Rank": 39776,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10375,
    "Rank": 39789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10376,
    "Rank": 39798,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10377,
    "Rank": 39799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10378,
    "Rank": 39809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10379,
    "Rank": 39830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10380,
    "Rank": 39834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10381,
    "Rank": 39836,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10382,
    "Rank": 39847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10383,
    "Rank": 39855,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10384,
    "Rank": 39868,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10385,
    "Rank": 39881,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10386,
    "Rank": 39937,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10387,
    "Rank": 39951,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10388,
    "Rank": 39952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10389,
    "Rank": 39968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10390,
    "Rank": 40000,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10391,
    "Rank": 40016,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10392,
    "Rank": 40047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10393,
    "Rank": 40106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10394,
    "Rank": 40117,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10395,
    "Rank": 40119,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10396,
    "Rank": 40135,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10397,
    "Rank": 40145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10398,
    "Rank": 40155,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10399,
    "Rank": 40187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10400,
    "Rank": 40203,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10401,
    "Rank": 40206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10402,
    "Rank": 40211,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10403,
    "Rank": 40258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10404,
    "Rank": 40260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10405,
    "Rank": 40281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10406,
    "Rank": 40302,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10407,
    "Rank": 40316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10408,
    "Rank": 40363,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10409,
    "Rank": 40404,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10410,
    "Rank": 40425,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10411,
    "Rank": 40440,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10412,
    "Rank": 40441,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10413,
    "Rank": 40513,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10414,
    "Rank": 40515,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10415,
    "Rank": 40540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10416,
    "Rank": 40555,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10417,
    "Rank": 40559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10418,
    "Rank": 40612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10419,
    "Rank": 40622,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10420,
    "Rank": 40627,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10421,
    "Rank": 40635,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10422,
    "Rank": 40675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10423,
    "Rank": 40698,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10424,
    "Rank": 40731,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10425,
    "Rank": 40759,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10426,
    "Rank": 40791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10427,
    "Rank": 40804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10428,
    "Rank": 40810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10429,
    "Rank": 40833,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10430,
    "Rank": 40835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10431,
    "Rank": 40848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10432,
    "Rank": 40873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10433,
    "Rank": 40893,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10434,
    "Rank": 40894,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10435,
    "Rank": 40910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10436,
    "Rank": 40936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10437,
    "Rank": 40991,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10438,
    "Rank": 41021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10439,
    "Rank": 41048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10440,
    "Rank": 41062,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10441,
    "Rank": 41072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10442,
    "Rank": 41093,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10443,
    "Rank": 41100,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10444,
    "Rank": 41122,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10445,
    "Rank": 41132,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10446,
    "Rank": 41198,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10447,
    "Rank": 41212,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10448,
    "Rank": 41213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10449,
    "Rank": 41233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10450,
    "Rank": 41257,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10451,
    "Rank": 41262,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10452,
    "Rank": 41295,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10453,
    "Rank": 41304,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10454,
    "Rank": 41331,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10455,
    "Rank": 41345,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10456,
    "Rank": 41352,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10457,
    "Rank": 41425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10458,
    "Rank": 41436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10459,
    "Rank": 41459,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10460,
    "Rank": 41466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10461,
    "Rank": 41495,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10462,
    "Rank": 41512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10463,
    "Rank": 41518,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10464,
    "Rank": 41546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10465,
    "Rank": 41561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10466,
    "Rank": 41564,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10467,
    "Rank": 41566,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10468,
    "Rank": 41592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10469,
    "Rank": 41593,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10470,
    "Rank": 41621,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10471,
    "Rank": 41691,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10472,
    "Rank": 41716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10473,
    "Rank": 41743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10474,
    "Rank": 41748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10475,
    "Rank": 41757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10476,
    "Rank": 41759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10477,
    "Rank": 41761,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10478,
    "Rank": 41762,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10479,
    "Rank": 41773,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10480,
    "Rank": 41782,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10481,
    "Rank": 41792,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10482,
    "Rank": 41794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10483,
    "Rank": 41816,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10484,
    "Rank": 41832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10485,
    "Rank": 41857,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10486,
    "Rank": 41862,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10487,
    "Rank": 41936,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10488,
    "Rank": 41993,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10489,
    "Rank": 41995,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10490,
    "Rank": 42044,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10491,
    "Rank": 42069,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10492,
    "Rank": 42070,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10493,
    "Rank": 42071,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10494,
    "Rank": 42102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10495,
    "Rank": 42107,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10496,
    "Rank": 42129,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10497,
    "Rank": 42142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10498,
    "Rank": 42146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10499,
    "Rank": 42154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10500,
    "Rank": 42165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10501,
    "Rank": 42173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10502,
    "Rank": 42178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10503,
    "Rank": 42208,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10504,
    "Rank": 42214,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10505,
    "Rank": 42223,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10506,
    "Rank": 42236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10507,
    "Rank": 42285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10508,
    "Rank": 42301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10509,
    "Rank": 42302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10510,
    "Rank": 42342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10511,
    "Rank": 42348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10512,
    "Rank": 42386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10513,
    "Rank": 42411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10514,
    "Rank": 42420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10515,
    "Rank": 42429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10516,
    "Rank": 42440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10517,
    "Rank": 42478,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10518,
    "Rank": 42487,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10519,
    "Rank": 42499,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10520,
    "Rank": 42531,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10521,
    "Rank": 42550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10522,
    "Rank": 42577,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10523,
    "Rank": 42605,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10524,
    "Rank": 42635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10525,
    "Rank": 42636,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10526,
    "Rank": 42651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10527,
    "Rank": 42664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10528,
    "Rank": 42687,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10529,
    "Rank": 42718,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10530,
    "Rank": 42719,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10531,
    "Rank": 42723,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10532,
    "Rank": 42725,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10533,
    "Rank": 42727,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10534,
    "Rank": 42772,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10535,
    "Rank": 42817,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10536,
    "Rank": 42864,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10537,
    "Rank": 42872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10538,
    "Rank": 42892,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10539,
    "Rank": 42929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10540,
    "Rank": 42932,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10541,
    "Rank": 42944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10542,
    "Rank": 43003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10543,
    "Rank": 43014,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10544,
    "Rank": 43020,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10545,
    "Rank": 43043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10546,
    "Rank": 43045,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10547,
    "Rank": 43056,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10548,
    "Rank": 43060,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10549,
    "Rank": 43073,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10550,
    "Rank": 43087,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10551,
    "Rank": 43108,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10552,
    "Rank": 43148,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10553,
    "Rank": 43173,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10554,
    "Rank": 43198,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10555,
    "Rank": 43202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10556,
    "Rank": 43215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10557,
    "Rank": 43245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10558,
    "Rank": 43256,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10559,
    "Rank": 43288,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10560,
    "Rank": 43308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10561,
    "Rank": 43318,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10562,
    "Rank": 43350,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10563,
    "Rank": 43375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10564,
    "Rank": 43424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10565,
    "Rank": 43433,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10566,
    "Rank": 43446,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10567,
    "Rank": 43449,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10568,
    "Rank": 43456,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10569,
    "Rank": 43500,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10570,
    "Rank": 43522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10571,
    "Rank": 43546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10572,
    "Rank": 43567,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10573,
    "Rank": 43610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10574,
    "Rank": 43631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10575,
    "Rank": 43635,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10576,
    "Rank": 43640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10577,
    "Rank": 43650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10578,
    "Rank": 43672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10579,
    "Rank": 43689,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10580,
    "Rank": 43693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10581,
    "Rank": 43720,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10582,
    "Rank": 43725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10583,
    "Rank": 43757,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10584,
    "Rank": 43784,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10585,
    "Rank": 43797,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10586,
    "Rank": 43806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10587,
    "Rank": 43825,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10588,
    "Rank": 43850,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10589,
    "Rank": 43856,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10590,
    "Rank": 43864,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10591,
    "Rank": 43867,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10592,
    "Rank": 43874,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10593,
    "Rank": 43896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10594,
    "Rank": 43920,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10595,
    "Rank": 43937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10596,
    "Rank": 43951,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10597,
    "Rank": 43955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10598,
    "Rank": 43959,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10599,
    "Rank": 43991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10600,
    "Rank": 44018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10601,
    "Rank": 44028,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10602,
    "Rank": 44033,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10603,
    "Rank": 44034,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10604,
    "Rank": 44073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10605,
    "Rank": 44094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10606,
    "Rank": 44111,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10607,
    "Rank": 44128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10608,
    "Rank": 44135,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10609,
    "Rank": 44152,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10610,
    "Rank": 44195,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10611,
    "Rank": 44209,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10612,
    "Rank": 44217,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10613,
    "Rank": 44219,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10614,
    "Rank": 44285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10615,
    "Rank": 44300,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10616,
    "Rank": 44330,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10617,
    "Rank": 44331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10618,
    "Rank": 44360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10619,
    "Rank": 44365,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10620,
    "Rank": 44387,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10621,
    "Rank": 44423,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10622,
    "Rank": 44435,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10623,
    "Rank": 44443,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10624,
    "Rank": 44445,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10625,
    "Rank": 44450,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10626,
    "Rank": 44459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10627,
    "Rank": 44461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10628,
    "Rank": 44488,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10629,
    "Rank": 44497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10630,
    "Rank": 44520,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10631,
    "Rank": 44528,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10632,
    "Rank": 44585,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10633,
    "Rank": 44603,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10634,
    "Rank": 44644,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10635,
    "Rank": 44652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10636,
    "Rank": 44671,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10637,
    "Rank": 44726,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10638,
    "Rank": 44763,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10639,
    "Rank": 44799,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10640,
    "Rank": 44831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10641,
    "Rank": 44842,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10642,
    "Rank": 44871,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10643,
    "Rank": 44882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10644,
    "Rank": 44897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10645,
    "Rank": 44973,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10646,
    "Rank": 45004,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10647,
    "Rank": 45037,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10648,
    "Rank": 45039,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10649,
    "Rank": 45044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10650,
    "Rank": 45050,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10651,
    "Rank": 45058,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10652,
    "Rank": 45085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10653,
    "Rank": 45100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10654,
    "Rank": 45139,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10655,
    "Rank": 45145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10656,
    "Rank": 45164,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10657,
    "Rank": 45167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10658,
    "Rank": 45207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10659,
    "Rank": 45251,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10660,
    "Rank": 45269,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10661,
    "Rank": 45272,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10662,
    "Rank": 45315,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10663,
    "Rank": 45320,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10664,
    "Rank": 45343,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10665,
    "Rank": 45369,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10666,
    "Rank": 45388,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10667,
    "Rank": 45401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10668,
    "Rank": 45460,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10669,
    "Rank": 45467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10670,
    "Rank": 45482,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10671,
    "Rank": 45604,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10672,
    "Rank": 45612,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10673,
    "Rank": 45622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10674,
    "Rank": 45650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10675,
    "Rank": 45659,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10676,
    "Rank": 45671,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10677,
    "Rank": 45674,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10678,
    "Rank": 45677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10679,
    "Rank": 45684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10680,
    "Rank": 45708,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10681,
    "Rank": 45768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10682,
    "Rank": 45771,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10683,
    "Rank": 45847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10684,
    "Rank": 45909,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10685,
    "Rank": 45931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10686,
    "Rank": 45940,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10687,
    "Rank": 45971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10688,
    "Rank": 45986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10689,
    "Rank": 45990,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10690,
    "Rank": 45992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10691,
    "Rank": 46000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10692,
    "Rank": 46060,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10693,
    "Rank": 46096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10694,
    "Rank": 46110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10695,
    "Rank": 46113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10696,
    "Rank": 46114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10697,
    "Rank": 46129,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10698,
    "Rank": 46136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10699,
    "Rank": 46142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10700,
    "Rank": 46172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10701,
    "Rank": 46175,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10702,
    "Rank": 46179,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10703,
    "Rank": 46229,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10704,
    "Rank": 46230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10705,
    "Rank": 46262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10706,
    "Rank": 46290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10707,
    "Rank": 46302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10708,
    "Rank": 46346,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10709,
    "Rank": 46363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10710,
    "Rank": 46371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10711,
    "Rank": 46379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10712,
    "Rank": 46416,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10713,
    "Rank": 46430,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10714,
    "Rank": 46460,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10715,
    "Rank": 46473,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10716,
    "Rank": 46500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10717,
    "Rank": 46511,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10718,
    "Rank": 46531,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10719,
    "Rank": 46643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10720,
    "Rank": 46650,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10721,
    "Rank": 46651,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10722,
    "Rank": 46676,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10723,
    "Rank": 46709,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10724,
    "Rank": 46748,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10725,
    "Rank": 46750,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10726,
    "Rank": 46756,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10727,
    "Rank": 46765,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10728,
    "Rank": 46776,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10729,
    "Rank": 46784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10730,
    "Rank": 46812,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10731,
    "Rank": 46830,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10732,
    "Rank": 46837,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10733,
    "Rank": 46848,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10734,
    "Rank": 46850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,SHIVAJINAGAR,\nBENGALURU, Karnataka, 560001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10735,
    "Rank": 46903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10736,
    "Rank": 46914,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10737,
    "Rank": 46941,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10738,
    "Rank": 46945,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10739,
    "Rank": 46959,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10740,
    "Rank": 46971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10741,
    "Rank": 46975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10742,
    "Rank": 46980,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10743,
    "Rank": 47000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences ,Sector 56 Mohali, Punjab, 160055",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10744,
    "Rank": 47015,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10745,
    "Rank": 47029,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10746,
    "Rank": 47030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10747,
    "Rank": 47052,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10748,
    "Rank": 47057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10749,
    "Rank": 47072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10750,
    "Rank": 47073,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10751,
    "Rank": 47078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10752,
    "Rank": 47093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10753,
    "Rank": 47103,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10754,
    "Rank": 47166,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10755,
    "Rank": 47200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10756,
    "Rank": 47203,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10757,
    "Rank": 47220,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10758,
    "Rank": 47221,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10759,
    "Rank": 47233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10760,
    "Rank": 47263,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10761,
    "Rank": 47295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10762,
    "Rank": 47296,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10763,
    "Rank": 47297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10764,
    "Rank": 47326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10765,
    "Rank": 47344,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10766,
    "Rank": 47355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10767,
    "Rank": 47371,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10768,
    "Rank": 47375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10769,
    "Rank": 47389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10770,
    "Rank": 47411,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10771,
    "Rank": 47448,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10772,
    "Rank": 47451,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10773,
    "Rank": 47476,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10774,
    "Rank": 47482,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10775,
    "Rank": 47492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10776,
    "Rank": 47504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10777,
    "Rank": 47510,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10778,
    "Rank": 47515,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10779,
    "Rank": 47567,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10780,
    "Rank": 47621,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10781,
    "Rank": 47630,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10782,
    "Rank": 47638,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10783,
    "Rank": 47686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10784,
    "Rank": 47701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10785,
    "Rank": 47709,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10786,
    "Rank": 47715,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10787,
    "Rank": 47762,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10788,
    "Rank": 47769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10789,
    "Rank": 47773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10790,
    "Rank": 47785,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10791,
    "Rank": 47797,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10792,
    "Rank": 47821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10793,
    "Rank": 47878,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10794,
    "Rank": 47890,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10795,
    "Rank": 47901,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10796,
    "Rank": 47975,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10797,
    "Rank": 47992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10798,
    "Rank": 48010,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10799,
    "Rank": 48016,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10800,
    "Rank": 48024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10801,
    "Rank": 48044,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10802,
    "Rank": 48047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10803,
    "Rank": 48107,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10804,
    "Rank": 48117,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10805,
    "Rank": 48132,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10806,
    "Rank": 48169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10807,
    "Rank": 48176,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10808,
    "Rank": 48208,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10809,
    "Rank": 48258,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10810,
    "Rank": 48295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10811,
    "Rank": 48298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10812,
    "Rank": 48333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10813,
    "Rank": 48336,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10814,
    "Rank": 48342,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10815,
    "Rank": 48367,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10816,
    "Rank": 48378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10817,
    "Rank": 48389,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10818,
    "Rank": 48399,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10819,
    "Rank": 48415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10820,
    "Rank": 48453,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10821,
    "Rank": 48469,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE, CHENNAI,MADRAS MEDICAL COLLEGE EVR PERIYAR SALAI,PARK TOWN,CHENNAI,TAMIL NADU., Tamil Nadu, 600003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10822,
    "Rank": 48472,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10823,
    "Rank": 48484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10824,
    "Rank": 48487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10825,
    "Rank": 48498,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10826,
    "Rank": 48512,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10827,
    "Rank": 48564,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10828,
    "Rank": 48565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10829,
    "Rank": 48566,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10830,
    "Rank": 48636,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10831,
    "Rank": 48640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10832,
    "Rank": 48643,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10833,
    "Rank": 48654,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10834,
    "Rank": 48659,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10835,
    "Rank": 48667,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10836,
    "Rank": 48672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10837,
    "Rank": 48674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10838,
    "Rank": 48684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10839,
    "Rank": 48738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10840,
    "Rank": 48787,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10841,
    "Rank": 48796,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10842,
    "Rank": 48798,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10843,
    "Rank": 48841,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10844,
    "Rank": 48869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10845,
    "Rank": 48873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10846,
    "Rank": 48875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10847,
    "Rank": 48878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10848,
    "Rank": 48914,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10849,
    "Rank": 48915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10850,
    "Rank": 48977,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10851,
    "Rank": 48983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10852,
    "Rank": 48995,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10853,
    "Rank": 48998,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10854,
    "Rank": 49011,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10855,
    "Rank": 49036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10856,
    "Rank": 49048,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10857,
    "Rank": 49070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10858,
    "Rank": 49095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10859,
    "Rank": 49127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10860,
    "Rank": 49133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10861,
    "Rank": 49134,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10862,
    "Rank": 49180,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10863,
    "Rank": 49181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10864,
    "Rank": 49182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10865,
    "Rank": 49184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10866,
    "Rank": 49190,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10867,
    "Rank": 49193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10868,
    "Rank": 49234,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10869,
    "Rank": 49252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10870,
    "Rank": 49262,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10871,
    "Rank": 49268,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10872,
    "Rank": 49278,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10873,
    "Rank": 49284,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10874,
    "Rank": 49318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10875,
    "Rank": 49333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10876,
    "Rank": 49338,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10877,
    "Rank": 49383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10878,
    "Rank": 49419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10879,
    "Rank": 49425,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10880,
    "Rank": 49459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10881,
    "Rank": 49487,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10882,
    "Rank": 49496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10883,
    "Rank": 49503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10884,
    "Rank": 49507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10885,
    "Rank": 49528,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10886,
    "Rank": 49541,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10887,
    "Rank": 49559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10888,
    "Rank": 49579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10889,
    "Rank": 49588,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10890,
    "Rank": 49599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10891,
    "Rank": 49605,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10892,
    "Rank": 49620,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10893,
    "Rank": 49629,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10894,
    "Rank": 49680,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10895,
    "Rank": 49697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10896,
    "Rank": 49700,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10897,
    "Rank": 49715,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10898,
    "Rank": 49752,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10899,
    "Rank": 49754,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10900,
    "Rank": 49817,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10901,
    "Rank": 49828,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10902,
    "Rank": 49831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10903,
    "Rank": 49837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10904,
    "Rank": 49858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10905,
    "Rank": 49860,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10906,
    "Rank": 49869,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10907,
    "Rank": 49895,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10908,
    "Rank": 49916,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10909,
    "Rank": 49940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10910,
    "Rank": 50008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10911,
    "Rank": 50013,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10912,
    "Rank": 50026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10913,
    "Rank": 50046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10914,
    "Rank": 50061,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10915,
    "Rank": 50083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10916,
    "Rank": 50105,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10917,
    "Rank": 50111,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10918,
    "Rank": 50116,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10919,
    "Rank": 50187,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10920,
    "Rank": 50213,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10921,
    "Rank": 50226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10922,
    "Rank": 50280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10923,
    "Rank": 50282,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10924,
    "Rank": 50288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10925,
    "Rank": 50325,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10926,
    "Rank": 50353,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10927,
    "Rank": 50466,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10928,
    "Rank": 50487,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10929,
    "Rank": 50490,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10930,
    "Rank": 50498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10931,
    "Rank": 50584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10932,
    "Rank": 50611,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10933,
    "Rank": 50669,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10934,
    "Rank": 50670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10935,
    "Rank": 50727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10936,
    "Rank": 50732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10937,
    "Rank": 50736,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10938,
    "Rank": 50784,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10939,
    "Rank": 50791,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10940,
    "Rank": 50829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10941,
    "Rank": 50904,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10942,
    "Rank": 50926,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10943,
    "Rank": 50937,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10944,
    "Rank": 51018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10945,
    "Rank": 51030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10946,
    "Rank": 51061,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10947,
    "Rank": 51076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10948,
    "Rank": 51150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10949,
    "Rank": 51177,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10950,
    "Rank": 51211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10951,
    "Rank": 51245,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10952,
    "Rank": 51277,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10953,
    "Rank": 51282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10954,
    "Rank": 51301,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10955,
    "Rank": 51312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10956,
    "Rank": 51348,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10957,
    "Rank": 51381,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10958,
    "Rank": 51386,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10959,
    "Rank": 51390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10960,
    "Rank": 51395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10961,
    "Rank": 51434,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10962,
    "Rank": 51446,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10963,
    "Rank": 51449,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10964,
    "Rank": 51459,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10965,
    "Rank": 51460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10966,
    "Rank": 51527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10967,
    "Rank": 51531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10968,
    "Rank": 51568,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10969,
    "Rank": 51573,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10970,
    "Rank": 51604,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10971,
    "Rank": 51652,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10972,
    "Rank": 51665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10973,
    "Rank": 51679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10974,
    "Rank": 51686,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10975,
    "Rank": 51693,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10976,
    "Rank": 51722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10977,
    "Rank": 51747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10978,
    "Rank": 51777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10979,
    "Rank": 51799,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10980,
    "Rank": 51803,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10981,
    "Rank": 51805,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10982,
    "Rank": 51827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10983,
    "Rank": 51835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10984,
    "Rank": 51845,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10985,
    "Rank": 51850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10986,
    "Rank": 51855,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10987,
    "Rank": 51875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10988,
    "Rank": 51890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10989,
    "Rank": 51903,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10990,
    "Rank": 51920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10991,
    "Rank": 51923,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10992,
    "Rank": 51974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10993,
    "Rank": 51986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10994,
    "Rank": 51994,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10995,
    "Rank": 52060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10996,
    "Rank": 52101,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10997,
    "Rank": 52111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10998,
    "Rank": 52166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 10999,
    "Rank": 52174,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11000,
    "Rank": 52184,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11001,
    "Rank": 52193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11002,
    "Rank": 52207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC DAUSA RAJASTHAN,GMC DAUSA MITRAPURA BHANDAREJ MOD DAUSA,\nRajasthan, 303303",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11003,
    "Rank": 52253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11004,
    "Rank": 52260,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11005,
    "Rank": 52316,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11006,
    "Rank": 52360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11007,
    "Rank": 52378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11008,
    "Rank": 52423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11009,
    "Rank": 52427,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11010,
    "Rank": 52442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11011,
    "Rank": 52452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11012,
    "Rank": 52460,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11013,
    "Rank": 52470,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11014,
    "Rank": 52489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11015,
    "Rank": 52501,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11016,
    "Rank": 52540,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11017,
    "Rank": 52577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11018,
    "Rank": 52593,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11019,
    "Rank": 52631,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11020,
    "Rank": 52664,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11021,
    "Rank": 52665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhilwara,Sindari Ke Balaji Road Sanganer Bhilwara, Rajasthan, 311001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11022,
    "Rank": 52690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11023,
    "Rank": 52692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11024,
    "Rank": 52696,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11025,
    "Rank": 52700,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11026,
    "Rank": 52714,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11027,
    "Rank": 52719,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11028,
    "Rank": 52732,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11029,
    "Rank": 52776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11030,
    "Rank": 52777,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11031,
    "Rank": 52809,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11032,
    "Rank": 52814,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11033,
    "Rank": 52850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11034,
    "Rank": 52911,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11035,
    "Rank": 52971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11036,
    "Rank": 52973,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11037,
    "Rank": 52998,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11038,
    "Rank": 52999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11039,
    "Rank": 53028,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11040,
    "Rank": 53045,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11041,
    "Rank": 53104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11042,
    "Rank": 53107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11043,
    "Rank": 53131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11044,
    "Rank": 53135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11045,
    "Rank": 53143,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11046,
    "Rank": 53158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11047,
    "Rank": 53167,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11048,
    "Rank": 53199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11049,
    "Rank": 53226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11050,
    "Rank": 53248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11051,
    "Rank": 53254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11052,
    "Rank": 53282,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11053,
    "Rank": 53285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11054,
    "Rank": 53298,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11055,
    "Rank": 53312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11056,
    "Rank": 53322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11057,
    "Rank": 53327,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11058,
    "Rank": 53334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jhunjhunu, Rajasthan,jjnmedicalcollege@gmail.com, Rajasthan, 333001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11059,
    "Rank": 53339,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11060,
    "Rank": 53346,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11061,
    "Rank": 53349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11062,
    "Rank": 53352,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11063,
    "Rank": 53367,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11064,
    "Rank": 53378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11065,
    "Rank": 53474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11066,
    "Rank": 53489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11067,
    "Rank": 53503,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11068,
    "Rank": 53517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11069,
    "Rank": 53524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11070,
    "Rank": 53525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11071,
    "Rank": 53536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11072,
    "Rank": 53537,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11073,
    "Rank": 53625,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11074,
    "Rank": 53630,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11075,
    "Rank": 53650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11076,
    "Rank": 53680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11077,
    "Rank": 53700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11078,
    "Rank": 53728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11079,
    "Rank": 53747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11080,
    "Rank": 53758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11081,
    "Rank": 53768,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11082,
    "Rank": 53807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11083,
    "Rank": 53824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11084,
    "Rank": 53844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11085,
    "Rank": 53856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11086,
    "Rank": 53867,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,MUMBAI,Dr. A.L. Nair Road, Mumbai\nCentral, Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11087,
    "Rank": 53889,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11088,
    "Rank": 53893,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11089,
    "Rank": 53932,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11090,
    "Rank": 53943,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11091,
    "Rank": 53973,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11092,
    "Rank": 53980,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11093,
    "Rank": 54010,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11094,
    "Rank": 54018,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11095,
    "Rank": 54037,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11096,
    "Rank": 54048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11097,
    "Rank": 54054,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11098,
    "Rank": 54055,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11099,
    "Rank": 54146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11100,
    "Rank": 54185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11101,
    "Rank": 54236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11102,
    "Rank": 54245,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11103,
    "Rank": 54345,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11104,
    "Rank": 54365,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11105,
    "Rank": 54371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11106,
    "Rank": 54429,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11107,
    "Rank": 54433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11108,
    "Rank": 54490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11109,
    "Rank": 54495,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11110,
    "Rank": 54508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11111,
    "Rank": 54515,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11112,
    "Rank": 54526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11113,
    "Rank": 54589,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11114,
    "Rank": 54590,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11115,
    "Rank": 54606,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11116,
    "Rank": 54623,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11117,
    "Rank": 54629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11118,
    "Rank": 54651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11119,
    "Rank": 54671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11120,
    "Rank": 54762,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11121,
    "Rank": 54786,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11122,
    "Rank": 54793,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11123,
    "Rank": 54875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11124,
    "Rank": 54899,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11125,
    "Rank": 54942,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11126,
    "Rank": 54948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11127,
    "Rank": 54949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11128,
    "Rank": 54978,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11129,
    "Rank": 54992,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11130,
    "Rank": 55015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11131,
    "Rank": 55028,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11132,
    "Rank": 55034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11133,
    "Rank": 55065,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11134,
    "Rank": 55069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11135,
    "Rank": 55089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11136,
    "Rank": 55117,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11137,
    "Rank": 55140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11138,
    "Rank": 55183,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11139,
    "Rank": 55184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11140,
    "Rank": 55218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11141,
    "Rank": 55241,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11142,
    "Rank": 55254,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11143,
    "Rank": 55267,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11144,
    "Rank": 55282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11145,
    "Rank": 55324,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11146,
    "Rank": 55356,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 17 )"
  },
  {
    "SNo": 11147,
    "Rank": 55358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11148,
    "Rank": 55368,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11149,
    "Rank": 55439,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11150,
    "Rank": 55446,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11151,
    "Rank": 55478,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11152,
    "Rank": 55503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11153,
    "Rank": 55516,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11154,
    "Rank": 55541,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11155,
    "Rank": 55598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11156,
    "Rank": 55624,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11157,
    "Rank": 55657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11158,
    "Rank": 55676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11159,
    "Rank": 55686,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11160,
    "Rank": 55699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11161,
    "Rank": 55711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11162,
    "Rank": 55753,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11163,
    "Rank": 55832,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11164,
    "Rank": 55865,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11165,
    "Rank": 55880,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11166,
    "Rank": 55910,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11167,
    "Rank": 55947,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11168,
    "Rank": 55974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11169,
    "Rank": 55986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11170,
    "Rank": 56046,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11171,
    "Rank": 56053,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11172,
    "Rank": 56057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11173,
    "Rank": 56070,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11174,
    "Rank": 56149,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11175,
    "Rank": 56169,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11176,
    "Rank": 56203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11177,
    "Rank": 56211,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11178,
    "Rank": 56212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11179,
    "Rank": 56273,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11180,
    "Rank": 56278,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11181,
    "Rank": 56363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11182,
    "Rank": 56367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11183,
    "Rank": 56383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11184,
    "Rank": 56385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11185,
    "Rank": 56397,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11186,
    "Rank": 56426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11187,
    "Rank": 56429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11188,
    "Rank": 56430,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11189,
    "Rank": 56446,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11190,
    "Rank": 56454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11191,
    "Rank": 56513,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11192,
    "Rank": 56527,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11193,
    "Rank": 56570,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11194,
    "Rank": 56582,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11195,
    "Rank": 56634,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11196,
    "Rank": 56641,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11197,
    "Rank": 56697,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11198,
    "Rank": 56713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11199,
    "Rank": 56725,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11200,
    "Rank": 56737,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11201,
    "Rank": 56771,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE,THIRUVANANTHAPURAM,OFFICE OF THE PRINCIPAL,GOVT.MEDICAL COLLEGE THIRUVANANTHAPURAM,MEDICAL COLLEGE PO,KERALA, Kerala, 695011",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11202,
    "Rank": 56921,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11203,
    "Rank": 56962,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11204,
    "Rank": 56980,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11205,
    "Rank": 56993,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11206,
    "Rank": 57001,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11207,
    "Rank": 57032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11208,
    "Rank": 57036,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11209,
    "Rank": 57052,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11210,
    "Rank": 57078,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11211,
    "Rank": 57080,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11212,
    "Rank": 57095,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11213,
    "Rank": 57131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11214,
    "Rank": 57132,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11215,
    "Rank": 57149,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11216,
    "Rank": 57176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11217,
    "Rank": 57180,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11218,
    "Rank": 57189,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11219,
    "Rank": 57207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11220,
    "Rank": 57214,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11221,
    "Rank": 57222,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11222,
    "Rank": 57242,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11223,
    "Rank": 57291,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11224,
    "Rank": 57447,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11225,
    "Rank": 57465,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11226,
    "Rank": 57469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11227,
    "Rank": 57536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11228,
    "Rank": 57554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11229,
    "Rank": 57579,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11230,
    "Rank": 57680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11231,
    "Rank": 57687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11232,
    "Rank": 57704,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11233,
    "Rank": 57707,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11234,
    "Rank": 57772,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11235,
    "Rank": 57773,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11236,
    "Rank": 57792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11237,
    "Rank": 57803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11238,
    "Rank": 57838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11239,
    "Rank": 57877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11240,
    "Rank": 57889,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11241,
    "Rank": 57900,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11242,
    "Rank": 57946,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11243,
    "Rank": 57948,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11244,
    "Rank": 58005,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11245,
    "Rank": 58006,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11246,
    "Rank": 58014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11247,
    "Rank": 58067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11248,
    "Rank": 58087,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11249,
    "Rank": 58099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11250,
    "Rank": 58100,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11251,
    "Rank": 58117,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11252,
    "Rank": 58132,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11253,
    "Rank": 58144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11254,
    "Rank": 58235,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11255,
    "Rank": 58256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11256,
    "Rank": 58293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11257,
    "Rank": 58296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11258,
    "Rank": 58314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11259,
    "Rank": 58329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11260,
    "Rank": 58411,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11261,
    "Rank": 58498,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11262,
    "Rank": 58555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11263,
    "Rank": 58576,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11264,
    "Rank": 58590,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11265,
    "Rank": 58651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11266,
    "Rank": 58675,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11267,
    "Rank": 58707,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11268,
    "Rank": 58717,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11269,
    "Rank": 58726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11270,
    "Rank": 58728,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11271,
    "Rank": 58743,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11272,
    "Rank": 58779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11273,
    "Rank": 58805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11274,
    "Rank": 58811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11275,
    "Rank": 58831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11276,
    "Rank": 58838,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11277,
    "Rank": 58931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11278,
    "Rank": 58947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11279,
    "Rank": 58983,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11280,
    "Rank": 58987,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11281,
    "Rank": 59007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11282,
    "Rank": 59023,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11283,
    "Rank": 59075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11284,
    "Rank": 59111,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11285,
    "Rank": 59128,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11286,
    "Rank": 59138,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11287,
    "Rank": 59174,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11288,
    "Rank": 59237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11289,
    "Rank": 59344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11290,
    "Rank": 59377,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11291,
    "Rank": 59378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11292,
    "Rank": 59389,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11293,
    "Rank": 59412,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11294,
    "Rank": 59426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11295,
    "Rank": 59464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11296,
    "Rank": 59476,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11297,
    "Rank": 59487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11298,
    "Rank": 59508,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11299,
    "Rank": 59512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11300,
    "Rank": 59575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11301,
    "Rank": 59607,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11302,
    "Rank": 59628,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11303,
    "Rank": 59651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11304,
    "Rank": 59670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11305,
    "Rank": 59694,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11306,
    "Rank": 59700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11307,
    "Rank": 59739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11308,
    "Rank": 59768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11309,
    "Rank": 59783,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11310,
    "Rank": 59805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11311,
    "Rank": 59857,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11312,
    "Rank": 59876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11313,
    "Rank": 59904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11314,
    "Rank": 59914,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11315,
    "Rank": 59936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11316,
    "Rank": 59988,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11317,
    "Rank": 60001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11318,
    "Rank": 60013,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11319,
    "Rank": 60050,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11320,
    "Rank": 60091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11321,
    "Rank": 60128,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11322,
    "Rank": 60247,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11323,
    "Rank": 60250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11324,
    "Rank": 60271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11325,
    "Rank": 60306,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11326,
    "Rank": 60320,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11327,
    "Rank": 60393,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11328,
    "Rank": 60402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11329,
    "Rank": 60437,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11330,
    "Rank": 60441,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11331,
    "Rank": 60451,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11332,
    "Rank": 60460,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11333,
    "Rank": 60464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11334,
    "Rank": 60481,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11335,
    "Rank": 60483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11336,
    "Rank": 60496,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11337,
    "Rank": 60585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11338,
    "Rank": 60610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11339,
    "Rank": 60620,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11340,
    "Rank": 60630,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11341,
    "Rank": 60717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11342,
    "Rank": 60739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11343,
    "Rank": 60769,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11344,
    "Rank": 60786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11345,
    "Rank": 60802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11346,
    "Rank": 60899,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11347,
    "Rank": 60907,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11348,
    "Rank": 60909,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11349,
    "Rank": 60912,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11350,
    "Rank": 60954,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11351,
    "Rank": 60966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11352,
    "Rank": 60979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11353,
    "Rank": 61043,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11354,
    "Rank": 61047,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11355,
    "Rank": 61075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11356,
    "Rank": 61122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11357,
    "Rank": 61123,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11358,
    "Rank": 61126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11359,
    "Rank": 61225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11360,
    "Rank": 61235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11361,
    "Rank": 61238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11362,
    "Rank": 61268,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11363,
    "Rank": 61323,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11364,
    "Rank": 61355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11365,
    "Rank": 61357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11366,
    "Rank": 61375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11367,
    "Rank": 61396,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11368,
    "Rank": 61416,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11369,
    "Rank": 61433,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11370,
    "Rank": 61438,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11371,
    "Rank": 61440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11372,
    "Rank": 61442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11373,
    "Rank": 61497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11374,
    "Rank": 61502,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11375,
    "Rank": 61514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11376,
    "Rank": 61589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11377,
    "Rank": 61711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11378,
    "Rank": 61740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11379,
    "Rank": 61742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11380,
    "Rank": 61785,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11381,
    "Rank": 61797,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11382,
    "Rank": 61852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11383,
    "Rank": 61879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11384,
    "Rank": 61896,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11385,
    "Rank": 61913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11386,
    "Rank": 61926,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11387,
    "Rank": 62013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11388,
    "Rank": 62040,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11389,
    "Rank": 62065,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11390,
    "Rank": 62079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11391,
    "Rank": 62173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11392,
    "Rank": 62185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11393,
    "Rank": 62195,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11394,
    "Rank": 62303,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11395,
    "Rank": 62308,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11396,
    "Rank": 62313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11397,
    "Rank": 62315,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11398,
    "Rank": 62370,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11399,
    "Rank": 62372,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11400,
    "Rank": 62427,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11401,
    "Rank": 62437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11402,
    "Rank": 62497,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11403,
    "Rank": 62514,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11404,
    "Rank": 62563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11405,
    "Rank": 62582,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11406,
    "Rank": 62590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11407,
    "Rank": 62634,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11408,
    "Rank": 62642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11409,
    "Rank": 62671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11410,
    "Rank": 62693,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11411,
    "Rank": 62811,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11412,
    "Rank": 62829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11413,
    "Rank": 62851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11414,
    "Rank": 62853,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11415,
    "Rank": 62941,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11416,
    "Rank": 62944,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11417,
    "Rank": 62967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11418,
    "Rank": 62995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11419,
    "Rank": 63044,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11420,
    "Rank": 63065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11421,
    "Rank": 63101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11422,
    "Rank": 63104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11423,
    "Rank": 63125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11424,
    "Rank": 63126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11425,
    "Rank": 63127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11426,
    "Rank": 63189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11427,
    "Rank": 63191,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11428,
    "Rank": 63209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11429,
    "Rank": 63223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11430,
    "Rank": 63238,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11431,
    "Rank": 63254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11432,
    "Rank": 63272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11433,
    "Rank": 63274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11434,
    "Rank": 63275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11435,
    "Rank": 63280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11436,
    "Rank": 63325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11437,
    "Rank": 63352,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11438,
    "Rank": 63388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11439,
    "Rank": 63399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11440,
    "Rank": 63402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11441,
    "Rank": 63405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11442,
    "Rank": 63432,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11443,
    "Rank": 63496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11444,
    "Rank": 63522,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11445,
    "Rank": 63527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11446,
    "Rank": 63567,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11447,
    "Rank": 63600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11448,
    "Rank": 63659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11449,
    "Rank": 63706,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11450,
    "Rank": 63707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11451,
    "Rank": 63739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11452,
    "Rank": 63744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11453,
    "Rank": 63774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11454,
    "Rank": 63797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11455,
    "Rank": 63805,
    "Allotted Quota": "Jamia Internal Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11456,
    "Rank": 63809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11457,
    "Rank": 63851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11458,
    "Rank": 63854,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11459,
    "Rank": 63959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11460,
    "Rank": 64015,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11461,
    "Rank": 64043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11462,
    "Rank": 64119,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11463,
    "Rank": 64176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11464,
    "Rank": 64206,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11465,
    "Rank": 64217,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11466,
    "Rank": 64241,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11467,
    "Rank": 64247,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11468,
    "Rank": 64284,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11469,
    "Rank": 64291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11470,
    "Rank": 64363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11471,
    "Rank": 64372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11472,
    "Rank": 64561,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11473,
    "Rank": 64568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11474,
    "Rank": 64842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11475,
    "Rank": 64847,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11476,
    "Rank": 64851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11477,
    "Rank": 64889,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11478,
    "Rank": 64929,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11479,
    "Rank": 64942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11480,
    "Rank": 64968,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11481,
    "Rank": 65010,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11482,
    "Rank": 65042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11483,
    "Rank": 65061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11484,
    "Rank": 65111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11485,
    "Rank": 65165,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11486,
    "Rank": 65184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11487,
    "Rank": 65235,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11488,
    "Rank": 65259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11489,
    "Rank": 65263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11490,
    "Rank": 65289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11491,
    "Rank": 65294,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11492,
    "Rank": 65308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11493,
    "Rank": 65344,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11494,
    "Rank": 65388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11495,
    "Rank": 65397,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted( CW Rank\n: 81 )"
  },
  {
    "SNo": 11496,
    "Rank": 65406,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INST. OF MEDICAL SCI., MANDYA,BANGALORE-MYSORE MAIN ROAD,\nMANDYA, Karnataka, 571401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11497,
    "Rank": 65412,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11498,
    "Rank": 65432,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11499,
    "Rank": 65448,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11500,
    "Rank": 65472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11501,
    "Rank": 65487,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11502,
    "Rank": 65507,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11503,
    "Rank": 65551,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11504,
    "Rank": 65560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11505,
    "Rank": 65568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11506,
    "Rank": 65582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11507,
    "Rank": 65590,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11508,
    "Rank": 65591,
    "Allotted Quota": "All India",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11509,
    "Rank": 65598,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11510,
    "Rank": 65632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11511,
    "Rank": 65634,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11512,
    "Rank": 65677,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11513,
    "Rank": 65679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11514,
    "Rank": 65684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11515,
    "Rank": 65785,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11516,
    "Rank": 65795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11517,
    "Rank": 65818,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11518,
    "Rank": 65828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11519,
    "Rank": 65831,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11520,
    "Rank": 65834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11521,
    "Rank": 65858,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11522,
    "Rank": 65953,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11523,
    "Rank": 65972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11524,
    "Rank": 66004,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11525,
    "Rank": 66022,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11526,
    "Rank": 66035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11527,
    "Rank": 66126,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11528,
    "Rank": 66167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11529,
    "Rank": 66222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11530,
    "Rank": 66227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11531,
    "Rank": 66247,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11532,
    "Rank": 66355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11533,
    "Rank": 66416,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11534,
    "Rank": 66422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11535,
    "Rank": 66434,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11536,
    "Rank": 66447,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11537,
    "Rank": 66467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11538,
    "Rank": 66477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11539,
    "Rank": 66479,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11540,
    "Rank": 66484,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11541,
    "Rank": 66514,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11542,
    "Rank": 66543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11543,
    "Rank": 66562,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11544,
    "Rank": 66571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11545,
    "Rank": 66612,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11546,
    "Rank": 66662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11547,
    "Rank": 66667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11548,
    "Rank": 66674,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11549,
    "Rank": 66724,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11550,
    "Rank": 66778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11551,
    "Rank": 66802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11552,
    "Rank": 66804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11553,
    "Rank": 66834,
    "Allotted Quota": "All India",
    "Allotted Institute": "College Government Medical College Alwar,Government Medical College Alwar\nJail Circle alwar, Rajasthan, 301001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11554,
    "Rank": 66920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11555,
    "Rank": 66934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11556,
    "Rank": 66947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11557,
    "Rank": 66950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11558,
    "Rank": 67003,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11559,
    "Rank": 67020,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11560,
    "Rank": 67022,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11561,
    "Rank": 67080,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11562,
    "Rank": 67106,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11563,
    "Rank": 67120,
    "Allotted Quota": "Jamia Internal Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11564,
    "Rank": 67124,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11565,
    "Rank": 67152,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11566,
    "Rank": 67159,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11567,
    "Rank": 67193,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11568,
    "Rank": 67195,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11569,
    "Rank": 67227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11570,
    "Rank": 67241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11571,
    "Rank": 67246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11572,
    "Rank": 67294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11573,
    "Rank": 67298,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11574,
    "Rank": 67354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11575,
    "Rank": 67386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11576,
    "Rank": 67398,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11577,
    "Rank": 67419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11578,
    "Rank": 67422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11579,
    "Rank": 67432,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11580,
    "Rank": 67435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11581,
    "Rank": 67467,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11582,
    "Rank": 67494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11583,
    "Rank": 67514,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11584,
    "Rank": 67556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11585,
    "Rank": 67568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11586,
    "Rank": 67613,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11587,
    "Rank": 67672,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11588,
    "Rank": 67752,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11589,
    "Rank": 67862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11590,
    "Rank": 67916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11591,
    "Rank": 67918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11592,
    "Rank": 67939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11593,
    "Rank": 67944,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11594,
    "Rank": 67982,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11595,
    "Rank": 67987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11596,
    "Rank": 68016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11597,
    "Rank": 68026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11598,
    "Rank": 68042,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11599,
    "Rank": 68120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11600,
    "Rank": 68158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11601,
    "Rank": 68219,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11602,
    "Rank": 68264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11603,
    "Rank": 68281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11604,
    "Rank": 68286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11605,
    "Rank": 68312,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11606,
    "Rank": 68315,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11607,
    "Rank": 68364,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11608,
    "Rank": 68380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11609,
    "Rank": 68382,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11610,
    "Rank": 68386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11611,
    "Rank": 68411,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11612,
    "Rank": 68434,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11613,
    "Rank": 68446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11614,
    "Rank": 68571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11615,
    "Rank": 68637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11616,
    "Rank": 68682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11617,
    "Rank": 68687,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11618,
    "Rank": 68719,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11619,
    "Rank": 68720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11620,
    "Rank": 68742,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11621,
    "Rank": 68815,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11622,
    "Rank": 68848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11623,
    "Rank": 68862,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11624,
    "Rank": 68892,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11625,
    "Rank": 68895,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11626,
    "Rank": 68930,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11627,
    "Rank": 68946,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11628,
    "Rank": 68963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11629,
    "Rank": 69045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11630,
    "Rank": 69064,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11631,
    "Rank": 69096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11632,
    "Rank": 69174,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11633,
    "Rank": 69177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11634,
    "Rank": 69188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11635,
    "Rank": 69192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11636,
    "Rank": 69260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11637,
    "Rank": 69295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11638,
    "Rank": 69342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11639,
    "Rank": 69348,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11640,
    "Rank": 69352,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11641,
    "Rank": 69383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11642,
    "Rank": 69384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11643,
    "Rank": 69412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11644,
    "Rank": 69431,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11645,
    "Rank": 69457,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11646,
    "Rank": 69487,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11647,
    "Rank": 69504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11648,
    "Rank": 69552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11649,
    "Rank": 69614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11650,
    "Rank": 69648,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11651,
    "Rank": 69703,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11652,
    "Rank": 69716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11653,
    "Rank": 69746,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11654,
    "Rank": 69748,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11655,
    "Rank": 69758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11656,
    "Rank": 69786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11657,
    "Rank": 69805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11658,
    "Rank": 69809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11659,
    "Rank": 69816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11660,
    "Rank": 69829,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11661,
    "Rank": 69874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11662,
    "Rank": 69935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11663,
    "Rank": 69964,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11664,
    "Rank": 70020,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11665,
    "Rank": 70041,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11666,
    "Rank": 70048,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11667,
    "Rank": 70051,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11668,
    "Rank": 70073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11669,
    "Rank": 70082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11670,
    "Rank": 70089,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11671,
    "Rank": 70092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11672,
    "Rank": 70105,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11673,
    "Rank": 70120,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11674,
    "Rank": 70131,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11675,
    "Rank": 70137,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11676,
    "Rank": 70210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11677,
    "Rank": 70248,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11678,
    "Rank": 70269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11679,
    "Rank": 70302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11680,
    "Rank": 70344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11681,
    "Rank": 70370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11682,
    "Rank": 70378,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11683,
    "Rank": 70379,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11684,
    "Rank": 70390,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11685,
    "Rank": 70391,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11686,
    "Rank": 70435,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11687,
    "Rank": 70443,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11688,
    "Rank": 70473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11689,
    "Rank": 70507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11690,
    "Rank": 70526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11691,
    "Rank": 70581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11692,
    "Rank": 70588,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11693,
    "Rank": 70704,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11694,
    "Rank": 70788,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11695,
    "Rank": 70811,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11696,
    "Rank": 70861,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11697,
    "Rank": 70892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11698,
    "Rank": 70949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11699,
    "Rank": 70964,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11700,
    "Rank": 70992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11701,
    "Rank": 71035,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11702,
    "Rank": 71044,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELGAUM INST. OF MEDICAL SCI., BELGAUM,DR B R AMBEDKAR ROAD\nBELAGAVI., Karnataka, 590001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11703,
    "Rank": 71073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11704,
    "Rank": 71074,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11705,
    "Rank": 71092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11706,
    "Rank": 71134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11707,
    "Rank": 71167,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11708,
    "Rank": 71168,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11709,
    "Rank": 71177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11710,
    "Rank": 71193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11711,
    "Rank": 71345,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11712,
    "Rank": 71349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11713,
    "Rank": 71362,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11714,
    "Rank": 71436,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11715,
    "Rank": 71456,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11716,
    "Rank": 71471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11717,
    "Rank": 71473,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11718,
    "Rank": 71484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11719,
    "Rank": 71487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11720,
    "Rank": 71499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11721,
    "Rank": 71511,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11722,
    "Rank": 71568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11723,
    "Rank": 71580,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11724,
    "Rank": 71643,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11725,
    "Rank": 71650,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11726,
    "Rank": 71678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11727,
    "Rank": 71720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11728,
    "Rank": 71751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11729,
    "Rank": 71756,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11730,
    "Rank": 71782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11731,
    "Rank": 71788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11732,
    "Rank": 71798,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11733,
    "Rank": 71803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11734,
    "Rank": 71807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11735,
    "Rank": 71817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11736,
    "Rank": 71839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11737,
    "Rank": 71871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11738,
    "Rank": 71877,
    "Allotted Quota": "All India",
    "Allotted Institute": "GVMC, VILLUPURAM,THE DEAN, GOVT. VILLUPURAM MEDICAL COLLEGE, TRICHY TRUNK ROAD, MUNDIYAMPAKKAM, VILLUPURAM DISTRICT, Tamil Nadu, 605601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11739,
    "Rank": 71894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11740,
    "Rank": 71971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11741,
    "Rank": 71980,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11742,
    "Rank": 72028,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11743,
    "Rank": 72096,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11744,
    "Rank": 72160,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11745,
    "Rank": 72173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11746,
    "Rank": 72186,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11747,
    "Rank": 72222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11748,
    "Rank": 72249,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11749,
    "Rank": 72263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11750,
    "Rank": 72310,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11751,
    "Rank": 72320,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11752,
    "Rank": 72401,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11753,
    "Rank": 72444,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11754,
    "Rank": 72505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11755,
    "Rank": 72520,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11756,
    "Rank": 72545,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11757,
    "Rank": 72554,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11758,
    "Rank": 72559,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJENDRA INST. OF MED. SCI., RANCHI,Director, Rajendra Institute of Medical Sciences, Bariatu,Ranchi, Jharkhand, 843009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11759,
    "Rank": 72561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11760,
    "Rank": 72588,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11761,
    "Rank": 72677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11762,
    "Rank": 72698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11763,
    "Rank": 72700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11764,
    "Rank": 72714,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11765,
    "Rank": 72740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11766,
    "Rank": 72760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11767,
    "Rank": 72765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11768,
    "Rank": 72782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11769,
    "Rank": 72809,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11770,
    "Rank": 72829,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11771,
    "Rank": 72842,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11772,
    "Rank": 72869,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11773,
    "Rank": 72874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11774,
    "Rank": 72912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11775,
    "Rank": 72932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11776,
    "Rank": 72963,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11777,
    "Rank": 72982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11778,
    "Rank": 73003,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11779,
    "Rank": 73007,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11780,
    "Rank": 73010,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11781,
    "Rank": 73011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11782,
    "Rank": 73083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11783,
    "Rank": 73117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11784,
    "Rank": 73139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11785,
    "Rank": 73166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11786,
    "Rank": 73196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11787,
    "Rank": 73229,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11788,
    "Rank": 73231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11789,
    "Rank": 73239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11790,
    "Rank": 73244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11791,
    "Rank": 73261,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11792,
    "Rank": 73275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11793,
    "Rank": 73287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11794,
    "Rank": 73329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11795,
    "Rank": 73369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11796,
    "Rank": 73399,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11797,
    "Rank": 73426,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11798,
    "Rank": 73441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11799,
    "Rank": 73485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11800,
    "Rank": 73528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11801,
    "Rank": 73601,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11802,
    "Rank": 73604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11803,
    "Rank": 73622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11804,
    "Rank": 73641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11805,
    "Rank": 73649,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11806,
    "Rank": 73660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11807,
    "Rank": 73668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11808,
    "Rank": 73676,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11809,
    "Rank": 73685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11810,
    "Rank": 73709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11811,
    "Rank": 73721,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11812,
    "Rank": 73794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11813,
    "Rank": 73802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11814,
    "Rank": 73815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11815,
    "Rank": 73849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11816,
    "Rank": 73852,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11817,
    "Rank": 73864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11818,
    "Rank": 73869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11819,
    "Rank": 73881,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11820,
    "Rank": 73918,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11821,
    "Rank": 73932,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11822,
    "Rank": 73958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11823,
    "Rank": 73985,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11824,
    "Rank": 73991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11825,
    "Rank": 73992,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11826,
    "Rank": 74007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11827,
    "Rank": 74010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11828,
    "Rank": 74085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11829,
    "Rank": 74101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11830,
    "Rank": 74182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11831,
    "Rank": 74195,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11832,
    "Rank": 74239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11833,
    "Rank": 74240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11834,
    "Rank": 74249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11835,
    "Rank": 74262,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11836,
    "Rank": 74314,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11837,
    "Rank": 74319,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11838,
    "Rank": 74322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11839,
    "Rank": 74364,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11840,
    "Rank": 74366,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11841,
    "Rank": 74373,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11842,
    "Rank": 74375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11843,
    "Rank": 74396,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11844,
    "Rank": 74403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11845,
    "Rank": 74417,
    "Allotted Quota": "All India",
    "Allotted Institute": "SKIMS Medical College, Bemina, Srinagar,SKIMS MC BEMINA , SRINAGAR, JAMMU KASHMIR, Jammu And Kashmir, 190018",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11846,
    "Rank": 74436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11847,
    "Rank": 74449,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11848,
    "Rank": 74480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11849,
    "Rank": 74497,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11850,
    "Rank": 74558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11851,
    "Rank": 74560,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11852,
    "Rank": 74588,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11853,
    "Rank": 74625,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11854,
    "Rank": 74628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11855,
    "Rank": 74664,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11856,
    "Rank": 74667,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11857,
    "Rank": 74682,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11858,
    "Rank": 74696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11859,
    "Rank": 74707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11860,
    "Rank": 74749,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11861,
    "Rank": 74756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11862,
    "Rank": 74780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.Radhakrishnan Government Medical College, Hamirpur,Dr.Radhakrishnan Government Medical College,Hamirpur, Himachal Pradesh, 177001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11863,
    "Rank": 74787,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11864,
    "Rank": 74830,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11865,
    "Rank": 74850,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11866,
    "Rank": 74865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11867,
    "Rank": 74890,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11868,
    "Rank": 74901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11869,
    "Rank": 74913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11870,
    "Rank": 74970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11871,
    "Rank": 74981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11872,
    "Rank": 74997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11873,
    "Rank": 75027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11874,
    "Rank": 75044,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11875,
    "Rank": 75083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11876,
    "Rank": 75085,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11877,
    "Rank": 75135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11878,
    "Rank": 75141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11879,
    "Rank": 75158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11880,
    "Rank": 75174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11881,
    "Rank": 75182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11882,
    "Rank": 75263,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11883,
    "Rank": 75282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11884,
    "Rank": 75303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11885,
    "Rank": 75309,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11886,
    "Rank": 75312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11887,
    "Rank": 75316,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11888,
    "Rank": 75317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11889,
    "Rank": 75318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11890,
    "Rank": 75320,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11891,
    "Rank": 75330,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11892,
    "Rank": 75341,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11893,
    "Rank": 75352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11894,
    "Rank": 75403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11895,
    "Rank": 75436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11896,
    "Rank": 75443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11897,
    "Rank": 75455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11898,
    "Rank": 75543,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11899,
    "Rank": 75578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11900,
    "Rank": 75602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11901,
    "Rank": 75619,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11902,
    "Rank": 75630,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11903,
    "Rank": 75650,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11904,
    "Rank": 75661,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11905,
    "Rank": 75664,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11906,
    "Rank": 75667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11907,
    "Rank": 75668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11908,
    "Rank": 75682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11909,
    "Rank": 75690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11910,
    "Rank": 75730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11911,
    "Rank": 75767,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11912,
    "Rank": 75793,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11913,
    "Rank": 75797,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11914,
    "Rank": 75910,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11915,
    "Rank": 75918,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11916,
    "Rank": 76022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11917,
    "Rank": 76023,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11918,
    "Rank": 76036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11919,
    "Rank": 76086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11920,
    "Rank": 76110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11921,
    "Rank": 76114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11922,
    "Rank": 76156,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11923,
    "Rank": 76172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11924,
    "Rank": 76189,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11925,
    "Rank": 76204,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11926,
    "Rank": 76236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11927,
    "Rank": 76295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11928,
    "Rank": 76311,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11929,
    "Rank": 76352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11930,
    "Rank": 76353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11931,
    "Rank": 76363,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11932,
    "Rank": 76369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11933,
    "Rank": 76518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11934,
    "Rank": 76556,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11935,
    "Rank": 76593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11936,
    "Rank": 76600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11937,
    "Rank": 76611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11938,
    "Rank": 76620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11939,
    "Rank": 76677,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 8 )"
  },
  {
    "SNo": 11940,
    "Rank": 76699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11941,
    "Rank": 76779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11942,
    "Rank": 76815,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11943,
    "Rank": 76820,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11944,
    "Rank": 76823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11945,
    "Rank": 76836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11946,
    "Rank": 76851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11947,
    "Rank": 76912,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11948,
    "Rank": 76927,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11949,
    "Rank": 77036,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11950,
    "Rank": 77039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11951,
    "Rank": 77062,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11952,
    "Rank": 77103,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11953,
    "Rank": 77111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11954,
    "Rank": 77129,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11955,
    "Rank": 77150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11956,
    "Rank": 77156,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11957,
    "Rank": 77167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11958,
    "Rank": 77226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11959,
    "Rank": 77228,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11960,
    "Rank": 77251,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11961,
    "Rank": 77267,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11962,
    "Rank": 77270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11963,
    "Rank": 77315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11964,
    "Rank": 77360,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11965,
    "Rank": 77413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11966,
    "Rank": 77437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11967,
    "Rank": 77467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11968,
    "Rank": 77470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11969,
    "Rank": 77482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11970,
    "Rank": 77511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11971,
    "Rank": 77527,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11972,
    "Rank": 77529,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11973,
    "Rank": 77538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11974,
    "Rank": 77562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11975,
    "Rank": 77586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11976,
    "Rank": 77641,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11977,
    "Rank": 77704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11978,
    "Rank": 77729,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11979,
    "Rank": 77742,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11980,
    "Rank": 77805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11981,
    "Rank": 77834,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11982,
    "Rank": 77838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11983,
    "Rank": 77866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11984,
    "Rank": 77873,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11985,
    "Rank": 77879,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11986,
    "Rank": 78086,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11987,
    "Rank": 78088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11988,
    "Rank": 78091,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11989,
    "Rank": 78100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11990,
    "Rank": 78128,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11991,
    "Rank": 78178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11992,
    "Rank": 78228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11993,
    "Rank": 78236,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11994,
    "Rank": 78260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11995,
    "Rank": 78268,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11996,
    "Rank": 78339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11997,
    "Rank": 78347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11998,
    "Rank": 78348,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 11999,
    "Rank": 78357,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12000,
    "Rank": 78369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12001,
    "Rank": 78404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12002,
    "Rank": 78408,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12003,
    "Rank": 78460,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12004,
    "Rank": 78489,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12005,
    "Rank": 78493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12006,
    "Rank": 78496,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12007,
    "Rank": 78541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12008,
    "Rank": 78589,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12009,
    "Rank": 78597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12010,
    "Rank": 78599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12011,
    "Rank": 78602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12012,
    "Rank": 78609,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12013,
    "Rank": 78631,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12014,
    "Rank": 78644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12015,
    "Rank": 78658,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12016,
    "Rank": 78669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Virendra Kumar Sakhlecha Government Medical College, Neemuch, Madhya Pradesh,Virendra Kumar Sakhlecha Government Medical College, Kanawati, Mhow- Naseerabad, Neemuch, Madhya Pradesh, 458441",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12017,
    "Rank": 78703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12018,
    "Rank": 78712,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12019,
    "Rank": 78756,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, CHHAINSA,\nFARIDABAD,Shri Atal Bihari Vajpayee Government Medical College Chhainsa Faridabad, Haryana, 121004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12020,
    "Rank": 78768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12021,
    "Rank": 78778,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12022,
    "Rank": 78779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12023,
    "Rank": 78823,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12024,
    "Rank": 78837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12025,
    "Rank": 78864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12026,
    "Rank": 78895,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12027,
    "Rank": 78896,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12028,
    "Rank": 78903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12029,
    "Rank": 78912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12030,
    "Rank": 78919,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12031,
    "Rank": 78923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dholpur,411, OLD BARI ROAD, DHOLPUR, Rajasthan, 328001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12032,
    "Rank": 78931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12033,
    "Rank": 78946,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12034,
    "Rank": 78973,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12035,
    "Rank": 79011,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12036,
    "Rank": 79042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12037,
    "Rank": 79064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12038,
    "Rank": 79087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12039,
    "Rank": 79127,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12040,
    "Rank": 79142,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12041,
    "Rank": 79144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12042,
    "Rank": 79155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12043,
    "Rank": 79221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12044,
    "Rank": 79238,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12045,
    "Rank": 79299,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12046,
    "Rank": 79301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12047,
    "Rank": 79344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12048,
    "Rank": 79348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12049,
    "Rank": 79366,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12050,
    "Rank": 79372,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12051,
    "Rank": 79381,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12052,
    "Rank": 79453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12053,
    "Rank": 79478,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12054,
    "Rank": 79481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12055,
    "Rank": 79517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12056,
    "Rank": 79530,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12057,
    "Rank": 79666,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Azamgarh, UP,Government Medical College and Super Facility Hospital Chakrapanpur Post Office-Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12058,
    "Rank": 79713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12059,
    "Rank": 79722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12060,
    "Rank": 79732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12061,
    "Rank": 79733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12062,
    "Rank": 79821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12063,
    "Rank": 79895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12064,
    "Rank": 79924,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12065,
    "Rank": 79931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12066,
    "Rank": 79934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12067,
    "Rank": 79949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12068,
    "Rank": 79991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12069,
    "Rank": 79996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12070,
    "Rank": 80017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12071,
    "Rank": 80019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12072,
    "Rank": 80028,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12073,
    "Rank": 80061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12074,
    "Rank": 80076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kathua,Chak Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12075,
    "Rank": 80124,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12076,
    "Rank": 80195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12077,
    "Rank": 80273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12078,
    "Rank": 80292,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12079,
    "Rank": 80307,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12080,
    "Rank": 80316,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12081,
    "Rank": 80362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12082,
    "Rank": 80407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12083,
    "Rank": 80447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12084,
    "Rank": 80449,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12085,
    "Rank": 80450,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12086,
    "Rank": 80455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12087,
    "Rank": 80463,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12088,
    "Rank": 80520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12089,
    "Rank": 80532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12090,
    "Rank": 80548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12091,
    "Rank": 80553,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12092,
    "Rank": 80584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12093,
    "Rank": 80599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12094,
    "Rank": 80646,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12095,
    "Rank": 80657,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12096,
    "Rank": 80686,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12097,
    "Rank": 80714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12098,
    "Rank": 80717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12099,
    "Rank": 80761,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12100,
    "Rank": 80813,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12101,
    "Rank": 80816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12102,
    "Rank": 80922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12103,
    "Rank": 80989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12104,
    "Rank": 81002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12105,
    "Rank": 81030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12106,
    "Rank": 81066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12107,
    "Rank": 81075,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12108,
    "Rank": 81090,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 86 )"
  },
  {
    "SNo": 12109,
    "Rank": 81102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12110,
    "Rank": 81107,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12111,
    "Rank": 81109,
    "Allotted Quota": "Jamia Internal Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12112,
    "Rank": 81147,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12113,
    "Rank": 81169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12114,
    "Rank": 81178,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12115,
    "Rank": 81191,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12116,
    "Rank": 81252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12117,
    "Rank": 81271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12118,
    "Rank": 81274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12119,
    "Rank": 81275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12120,
    "Rank": 81286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12121,
    "Rank": 81333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12122,
    "Rank": 81347,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12123,
    "Rank": 81378,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12124,
    "Rank": 81396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12125,
    "Rank": 81418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12126,
    "Rank": 81427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12127,
    "Rank": 81440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12128,
    "Rank": 81457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12129,
    "Rank": 81468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12130,
    "Rank": 81472,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12131,
    "Rank": 81546,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12132,
    "Rank": 81563,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12133,
    "Rank": 81576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12134,
    "Rank": 81588,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12135,
    "Rank": 81601,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12136,
    "Rank": 81632,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12137,
    "Rank": 81709,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12138,
    "Rank": 81713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12139,
    "Rank": 81727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12140,
    "Rank": 81731,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12141,
    "Rank": 81737,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12142,
    "Rank": 81746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12143,
    "Rank": 81765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12144,
    "Rank": 81792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Churu,Shekhawat Colony, Civil Lines Road,Churu, Rajasthan, 331001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12145,
    "Rank": 81930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12146,
    "Rank": 81964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12147,
    "Rank": 81984,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12148,
    "Rank": 82025,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12149,
    "Rank": 82030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12150,
    "Rank": 82035,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12151,
    "Rank": 82047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12152,
    "Rank": 82077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12153,
    "Rank": 82131,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12154,
    "Rank": 82158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12155,
    "Rank": 82204,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12156,
    "Rank": 82211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12157,
    "Rank": 82213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12158,
    "Rank": 82229,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12159,
    "Rank": 82304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12160,
    "Rank": 82313,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12161,
    "Rank": 82315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12162,
    "Rank": 82339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12163,
    "Rank": 82346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12164,
    "Rank": 82354,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12165,
    "Rank": 82365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12166,
    "Rank": 82373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12167,
    "Rank": 82380,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12168,
    "Rank": 82381,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12169,
    "Rank": 82414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12170,
    "Rank": 82425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12171,
    "Rank": 82469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12172,
    "Rank": 82470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12173,
    "Rank": 82475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12174,
    "Rank": 82514,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12175,
    "Rank": 82531,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.R.D. MEDICAL COLLEGE, GORAKHPUR,MAHARAJGANJ ROAD,GORAKHPUR, Uttar Pradesh, 273013",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12176,
    "Rank": 82542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12177,
    "Rank": 82621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12178,
    "Rank": 82645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12179,
    "Rank": 82659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12180,
    "Rank": 82701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12181,
    "Rank": 82710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12182,
    "Rank": 82725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12183,
    "Rank": 82738,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12184,
    "Rank": 82756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12185,
    "Rank": 82757,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12186,
    "Rank": 82838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12187,
    "Rank": 82851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12188,
    "Rank": 82863,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12189,
    "Rank": 82868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12190,
    "Rank": 82909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12191,
    "Rank": 82915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12192,
    "Rank": 82917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12193,
    "Rank": 82983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12194,
    "Rank": 82992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12195,
    "Rank": 83032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12196,
    "Rank": 83090,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12197,
    "Rank": 83103,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12198,
    "Rank": 83130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12199,
    "Rank": 83157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12200,
    "Rank": 83275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12201,
    "Rank": 83282,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12202,
    "Rank": 83287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12203,
    "Rank": 83408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12204,
    "Rank": 83430,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12205,
    "Rank": 83435,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12206,
    "Rank": 83440,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12207,
    "Rank": 83448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12208,
    "Rank": 83456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12209,
    "Rank": 83591,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12210,
    "Rank": 83600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12211,
    "Rank": 83632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12212,
    "Rank": 83661,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12213,
    "Rank": 83662,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12214,
    "Rank": 83672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12215,
    "Rank": 83677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12216,
    "Rank": 83707,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12217,
    "Rank": 83734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12218,
    "Rank": 83744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12219,
    "Rank": 83814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12220,
    "Rank": 83816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12221,
    "Rank": 83829,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12222,
    "Rank": 83850,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12223,
    "Rank": 83895,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12224,
    "Rank": 83918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12225,
    "Rank": 83959,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. KILPAUK MEDICAL COLLEGE,CHENNAI,No. 822, EVR Periyar salai ,\nKilpauk,Chennai, Tamil Nadu, 600010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12226,
    "Rank": 83973,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12227,
    "Rank": 84015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12228,
    "Rank": 84114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12229,
    "Rank": 84133,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12230,
    "Rank": 84152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12231,
    "Rank": 84172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12232,
    "Rank": 84209,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12233,
    "Rank": 84263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12234,
    "Rank": 84266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12235,
    "Rank": 84283,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12236,
    "Rank": 84318,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12237,
    "Rank": 84327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12238,
    "Rank": 84334,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE, PATNA,OLD BYEPASS ROAD, KANKERBAGH, PATNA, Bihar, 800026",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12239,
    "Rank": 84392,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12240,
    "Rank": 84442,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12241,
    "Rank": 84459,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12242,
    "Rank": 84478,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12243,
    "Rank": 84535,
    "Allotted Quota": "All India",
    "Allotted Institute": "NILRATAN SIRKAR MEDICAL COLLEGE, KOLKATA,138 AJC Bose Road, Kolkata -\n700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12244,
    "Rank": 84536,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12245,
    "Rank": 84576,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12246,
    "Rank": 84642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12247,
    "Rank": 84671,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12248,
    "Rank": 84719,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12249,
    "Rank": 84741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12250,
    "Rank": 84753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12251,
    "Rank": 84766,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12252,
    "Rank": 84800,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12253,
    "Rank": 84817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12254,
    "Rank": 84823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12255,
    "Rank": 84846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Lalitpur,  U.P,Amarpur Galla Mandi Jhansi Road Lalitpur, Uttar Pradesh, 284403",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12256,
    "Rank": 84854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12257,
    "Rank": 84891,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12258,
    "Rank": 84928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12259,
    "Rank": 84957,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12260,
    "Rank": 84959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12261,
    "Rank": 84991,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12262,
    "Rank": 85004,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12263,
    "Rank": 85039,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12264,
    "Rank": 85068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12265,
    "Rank": 85112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12266,
    "Rank": 85134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12267,
    "Rank": 85164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12268,
    "Rank": 85194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12269,
    "Rank": 85229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12270,
    "Rank": 85232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12271,
    "Rank": 85237,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12272,
    "Rank": 85242,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12273,
    "Rank": 85258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12274,
    "Rank": 85283,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12275,
    "Rank": 85300,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12276,
    "Rank": 85315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12277,
    "Rank": 85318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12278,
    "Rank": 85353,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12279,
    "Rank": 85381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12280,
    "Rank": 85384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12281,
    "Rank": 85474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12282,
    "Rank": 85482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12283,
    "Rank": 85485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12284,
    "Rank": 85507,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12285,
    "Rank": 85537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Chittorgarh ,Government Medical College Chittorgarh Bojunda - Udaipur Road Chittorgarh, Rajasthan, 312025",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12286,
    "Rank": 85542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12287,
    "Rank": 85554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12288,
    "Rank": 85560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12289,
    "Rank": 85573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12290,
    "Rank": 85666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12291,
    "Rank": 85684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12292,
    "Rank": 85717,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12293,
    "Rank": 85729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12294,
    "Rank": 85744,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12295,
    "Rank": 85773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12296,
    "Rank": 85795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12297,
    "Rank": 85837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12298,
    "Rank": 85869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12299,
    "Rank": 85910,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12300,
    "Rank": 85912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12301,
    "Rank": 85927,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12302,
    "Rank": 85936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12303,
    "Rank": 85946,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12304,
    "Rank": 85952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12305,
    "Rank": 85959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12306,
    "Rank": 85971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12307,
    "Rank": 85986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12308,
    "Rank": 85995,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12309,
    "Rank": 85996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12310,
    "Rank": 86008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12311,
    "Rank": 86031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12312,
    "Rank": 86043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12313,
    "Rank": 86079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12314,
    "Rank": 86089,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12315,
    "Rank": 86133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12316,
    "Rank": 86168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12317,
    "Rank": 86182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12318,
    "Rank": 86251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12319,
    "Rank": 86263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12320,
    "Rank": 86289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. YS Parmar Govt. Medical College, Nahan,Nahan, District Sirmaur, Himachal Pradesh, Himachal Pradesh, 173001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12321,
    "Rank": 86323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12322,
    "Rank": 86365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12323,
    "Rank": 86380,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12324,
    "Rank": 86390,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12325,
    "Rank": 86421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12326,
    "Rank": 86489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute of Medical Sciences, Gulbarga,Gulbarga Institute of Medical Sciences, Veeresh Nagar, Sedam Road, Kalaburagi, Karnataka, 585105",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12327,
    "Rank": 86494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12328,
    "Rank": 86512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12329,
    "Rank": 86559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12330,
    "Rank": 86591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Hanumangarh,Ganganagar-sangria Bypass Road Hanumangarh Junction, Rajasthan, 335512",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12331,
    "Rank": 86617,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12332,
    "Rank": 86627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12333,
    "Rank": 86666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12334,
    "Rank": 86687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12335,
    "Rank": 86689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12336,
    "Rank": 86702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12337,
    "Rank": 86711,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12338,
    "Rank": 86719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12339,
    "Rank": 86721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12340,
    "Rank": 86771,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12341,
    "Rank": 86776,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12342,
    "Rank": 86794,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12343,
    "Rank": 86801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12344,
    "Rank": 86805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12345,
    "Rank": 86932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12346,
    "Rank": 86982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12347,
    "Rank": 87097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12348,
    "Rank": 87108,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12349,
    "Rank": 87135,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12350,
    "Rank": 87183,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12351,
    "Rank": 87184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12352,
    "Rank": 87253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12353,
    "Rank": 87289,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12354,
    "Rank": 87324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12355,
    "Rank": 87346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12356,
    "Rank": 87366,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12357,
    "Rank": 87493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12358,
    "Rank": 87508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12359,
    "Rank": 87570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12360,
    "Rank": 87572,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12361,
    "Rank": 87577,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12362,
    "Rank": 87596,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12363,
    "Rank": 87606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12364,
    "Rank": 87667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12365,
    "Rank": 87733,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12366,
    "Rank": 87745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12367,
    "Rank": 87748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12368,
    "Rank": 87766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12369,
    "Rank": 87774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12370,
    "Rank": 87794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12371,
    "Rank": 87811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12372,
    "Rank": 87818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12373,
    "Rank": 87838,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12374,
    "Rank": 87864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12375,
    "Rank": 87902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12376,
    "Rank": 87943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12377,
    "Rank": 87949,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12378,
    "Rank": 88012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12379,
    "Rank": 88024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12380,
    "Rank": 88035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12381,
    "Rank": 88036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12382,
    "Rank": 88065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12383,
    "Rank": 88077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12384,
    "Rank": 88103,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12385,
    "Rank": 88106,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12386,
    "Rank": 88127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12387,
    "Rank": 88141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12388,
    "Rank": 88145,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12389,
    "Rank": 88162,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12390,
    "Rank": 88232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12391,
    "Rank": 88253,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12392,
    "Rank": 88299,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12393,
    "Rank": 88306,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12394,
    "Rank": 88361,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12395,
    "Rank": 88394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12396,
    "Rank": 88402,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12397,
    "Rank": 88411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12398,
    "Rank": 88440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12399,
    "Rank": 88442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12400,
    "Rank": 88479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12401,
    "Rank": 88505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12402,
    "Rank": 88508,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12403,
    "Rank": 88540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12404,
    "Rank": 88572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12405,
    "Rank": 88582,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12406,
    "Rank": 88593,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted( CW Rank\n: 9 )"
  },
  {
    "SNo": 12407,
    "Rank": 88605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12408,
    "Rank": 88607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12409,
    "Rank": 88632,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12410,
    "Rank": 88680,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12411,
    "Rank": 88703,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12412,
    "Rank": 88737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12413,
    "Rank": 88739,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12414,
    "Rank": 88774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12415,
    "Rank": 88801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12416,
    "Rank": 88815,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12417,
    "Rank": 88825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12418,
    "Rank": 88845,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12419,
    "Rank": 88863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12420,
    "Rank": 88886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12421,
    "Rank": 88925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12422,
    "Rank": 88973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12423,
    "Rank": 88986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12424,
    "Rank": 89030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12425,
    "Rank": 89039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12426,
    "Rank": 89050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12427,
    "Rank": 89063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12428,
    "Rank": 89108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12429,
    "Rank": 89140,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12430,
    "Rank": 89214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12431,
    "Rank": 89265,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12432,
    "Rank": 89288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12433,
    "Rank": 89312,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12434,
    "Rank": 89381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12435,
    "Rank": 89383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12436,
    "Rank": 89400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12437,
    "Rank": 89417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12438,
    "Rank": 89469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12439,
    "Rank": 89474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12440,
    "Rank": 89490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Khaleelwadi,R.P.Road, Near Bustand, Khaleelwadi Nizamabad, Telangana, 503001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12441,
    "Rank": 89519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12442,
    "Rank": 89529,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12443,
    "Rank": 89535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12444,
    "Rank": 89549,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12445,
    "Rank": 89577,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12446,
    "Rank": 89626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12447,
    "Rank": 89639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12448,
    "Rank": 89694,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12449,
    "Rank": 89696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12450,
    "Rank": 89704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12451,
    "Rank": 89708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12452,
    "Rank": 89711,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12453,
    "Rank": 89764,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12454,
    "Rank": 89772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12455,
    "Rank": 89776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12456,
    "Rank": 89823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12457,
    "Rank": 89835,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12458,
    "Rank": 89884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12459,
    "Rank": 89897,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12460,
    "Rank": 89938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12461,
    "Rank": 89995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12462,
    "Rank": 90002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12463,
    "Rank": 90008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12464,
    "Rank": 90049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12465,
    "Rank": 90056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12466,
    "Rank": 90067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12467,
    "Rank": 90112,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12468,
    "Rank": 90130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mumbai, Maharashtra,Gokuldas Tejpal Hospital Premises L T Marg Near L T Marg Police Station Mumbai, Maharashtra, 400001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12469,
    "Rank": 90134,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12470,
    "Rank": 90148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12471,
    "Rank": 90230,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12472,
    "Rank": 90236,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12473,
    "Rank": 90245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12474,
    "Rank": 90295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12475,
    "Rank": 90311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12476,
    "Rank": 90317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12477,
    "Rank": 90356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12478,
    "Rank": 90381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12479,
    "Rank": 90393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12480,
    "Rank": 90405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12481,
    "Rank": 90417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12482,
    "Rank": 90442,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE, THANE,THANE BELAPUR ROAD  KALWA\nTHANE WEST, Maharashtra, 400605",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12483,
    "Rank": 90447,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12484,
    "Rank": 90463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12485,
    "Rank": 90509,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12486,
    "Rank": 90577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12487,
    "Rank": 90601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12488,
    "Rank": 90628,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12489,
    "Rank": 90652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12490,
    "Rank": 90795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12491,
    "Rank": 90803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12492,
    "Rank": 90816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12493,
    "Rank": 90867,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12494,
    "Rank": 90870,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12495,
    "Rank": 90881,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12496,
    "Rank": 90922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12497,
    "Rank": 90956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12498,
    "Rank": 91017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12499,
    "Rank": 91065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12500,
    "Rank": 91123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12501,
    "Rank": 91162,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12502,
    "Rank": 91171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12503,
    "Rank": 91181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12504,
    "Rank": 91192,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12505,
    "Rank": 91207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12506,
    "Rank": 91216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12507,
    "Rank": 91241,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12508,
    "Rank": 91242,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12509,
    "Rank": 91254,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12510,
    "Rank": 91284,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12511,
    "Rank": 91294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12512,
    "Rank": 91308,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12513,
    "Rank": 91318,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12514,
    "Rank": 91339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12515,
    "Rank": 91341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12516,
    "Rank": 91342,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12517,
    "Rank": 91354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12518,
    "Rank": 91386,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12519,
    "Rank": 91428,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12520,
    "Rank": 91452,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12521,
    "Rank": 91475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12522,
    "Rank": 91485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12523,
    "Rank": 91510,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12524,
    "Rank": 91520,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12525,
    "Rank": 91525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12526,
    "Rank": 91546,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12527,
    "Rank": 91552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12528,
    "Rank": 91553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12529,
    "Rank": 91573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12530,
    "Rank": 91591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12531,
    "Rank": 91609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12532,
    "Rank": 91639,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12533,
    "Rank": 91662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12534,
    "Rank": 91683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12535,
    "Rank": 91701,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA, Madhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12536,
    "Rank": 91757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12537,
    "Rank": 91775,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12538,
    "Rank": 91782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12539,
    "Rank": 91838,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12540,
    "Rank": 91840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12541,
    "Rank": 91867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12542,
    "Rank": 91885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12543,
    "Rank": 91895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12544,
    "Rank": 91913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12545,
    "Rank": 91978,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12546,
    "Rank": 92014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12547,
    "Rank": 92042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12548,
    "Rank": 92051,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12549,
    "Rank": 92065,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12550,
    "Rank": 92066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12551,
    "Rank": 92070,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12552,
    "Rank": 92089,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12553,
    "Rank": 92092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12554,
    "Rank": 92111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12555,
    "Rank": 92187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12556,
    "Rank": 92331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12557,
    "Rank": 92334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12558,
    "Rank": 92360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12559,
    "Rank": 92374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12560,
    "Rank": 92416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12561,
    "Rank": 92504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12562,
    "Rank": 92551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12563,
    "Rank": 92581,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12564,
    "Rank": 92599,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12565,
    "Rank": 92622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12566,
    "Rank": 92639,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12567,
    "Rank": 92683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12568,
    "Rank": 92694,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12569,
    "Rank": 92695,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12570,
    "Rank": 92735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12571,
    "Rank": 92764,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12572,
    "Rank": 92769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12573,
    "Rank": 92787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12574,
    "Rank": 92797,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12575,
    "Rank": 92804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12576,
    "Rank": 92823,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12577,
    "Rank": 92846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12578,
    "Rank": 92888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12579,
    "Rank": 92931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12580,
    "Rank": 92932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12581,
    "Rank": 92953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12582,
    "Rank": 92974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12583,
    "Rank": 92985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12584,
    "Rank": 93012,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12585,
    "Rank": 93013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12586,
    "Rank": 93028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12587,
    "Rank": 93030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12588,
    "Rank": 93033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12589,
    "Rank": 93039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12590,
    "Rank": 93059,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12591,
    "Rank": 93061,
    "Allotted Quota": "All India",
    "Allotted Institute": "C. Institute of Medical Sciences, Chamarajanagar,SY NO.124, YEDAPURA VILLAGE , KASABA HOBLI, CHAMARAJANAGARA TALUK AND DISTRICT, Karnataka, 571313",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12592,
    "Rank": 93073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12593,
    "Rank": 93084,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12594,
    "Rank": 93115,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE,JAMSHEDPUR,Dimna Road, Mango, Jamshedpur, Jharkhand, 831020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12595,
    "Rank": 93148,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12596,
    "Rank": 93160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12597,
    "Rank": 93162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12598,
    "Rank": 93190,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12599,
    "Rank": 93248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12600,
    "Rank": 93252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12601,
    "Rank": 93282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12602,
    "Rank": 93431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12603,
    "Rank": 93439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12604,
    "Rank": 93471,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12605,
    "Rank": 93512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12606,
    "Rank": 93531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12607,
    "Rank": 93581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12608,
    "Rank": 93594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Barmer Rajasthan,NH-15, JAISALMER ROAD, VILLAGE-JALIPA, BARMER, Rajasthan, 344001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12609,
    "Rank": 93595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12610,
    "Rank": 93665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12611,
    "Rank": 93678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12612,
    "Rank": 93712,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12613,
    "Rank": 93762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12614,
    "Rank": 93819,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12615,
    "Rank": 93875,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12616,
    "Rank": 94003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12617,
    "Rank": 94040,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12618,
    "Rank": 94045,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12619,
    "Rank": 94063,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12620,
    "Rank": 94066,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12621,
    "Rank": 94142,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12622,
    "Rank": 94175,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12623,
    "Rank": 94179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12624,
    "Rank": 94185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12625,
    "Rank": 94202,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12626,
    "Rank": 94246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12627,
    "Rank": 94251,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12628,
    "Rank": 94307,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12629,
    "Rank": 94321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12630,
    "Rank": 94335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12631,
    "Rank": 94336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12632,
    "Rank": 94344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12633,
    "Rank": 94350,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12634,
    "Rank": 94365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12635,
    "Rank": 94368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12636,
    "Rank": 94388,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12637,
    "Rank": 94435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12638,
    "Rank": 94498,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12639,
    "Rank": 94534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12640,
    "Rank": 94557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,SAIFAI ETAWAH, Uttar Pradesh, 206130",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12641,
    "Rank": 94611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12642,
    "Rank": 94641,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12643,
    "Rank": 94659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12644,
    "Rank": 94665,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12645,
    "Rank": 94711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12646,
    "Rank": 94713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12647,
    "Rank": 94761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12648,
    "Rank": 94773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12649,
    "Rank": 94794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12650,
    "Rank": 94878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12651,
    "Rank": 94948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12652,
    "Rank": 94986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12653,
    "Rank": 95005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12654,
    "Rank": 95012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12655,
    "Rank": 95013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12656,
    "Rank": 95018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12657,
    "Rank": 95111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12658,
    "Rank": 95121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12659,
    "Rank": 95123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12660,
    "Rank": 95153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12661,
    "Rank": 95156,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12662,
    "Rank": 95159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12663,
    "Rank": 95180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12664,
    "Rank": 95206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12665,
    "Rank": 95227,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12666,
    "Rank": 95229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Doda,Government Medical College & Hospital Ghat Doda, Jammu And Kashmir, 182202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12667,
    "Rank": 95271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12668,
    "Rank": 95322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12669,
    "Rank": 95412,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12670,
    "Rank": 95415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12671,
    "Rank": 95416,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12672,
    "Rank": 95419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12673,
    "Rank": 95426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12674,
    "Rank": 95529,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12675,
    "Rank": 95538,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12676,
    "Rank": 95562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12677,
    "Rank": 95632,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12678,
    "Rank": 95648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12679,
    "Rank": 95661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12680,
    "Rank": 95669,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12681,
    "Rank": 95729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12682,
    "Rank": 95756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12683,
    "Rank": 95759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12684,
    "Rank": 95760,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12685,
    "Rank": 95809,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12686,
    "Rank": 95831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12687,
    "Rank": 95834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12688,
    "Rank": 95835,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12689,
    "Rank": 95860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12690,
    "Rank": 95893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12691,
    "Rank": 95901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12692,
    "Rank": 95921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chhindwara Institute of Medical Sciences,Teachers Colony, Chhindwara, Madhya Pradesh 480001, Madhya Pradesh, 480001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12693,
    "Rank": 95925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12694,
    "Rank": 95942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12695,
    "Rank": 95945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12696,
    "Rank": 95947,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12697,
    "Rank": 95973,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12698,
    "Rank": 95996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12699,
    "Rank": 96000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12700,
    "Rank": 96001,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12701,
    "Rank": 96009,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12702,
    "Rank": 96023,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12703,
    "Rank": 96030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12704,
    "Rank": 96056,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12705,
    "Rank": 96119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12706,
    "Rank": 96128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12707,
    "Rank": 96129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GURU GOVIND SINGH MED COLL,FARIDKOT,Sadiq Road, Faridkot., Punjab, 151203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12708,
    "Rank": 96138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12709,
    "Rank": 96170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12710,
    "Rank": 96175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12711,
    "Rank": 96199,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12712,
    "Rank": 96230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12713,
    "Rank": 96235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12714,
    "Rank": 96255,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12715,
    "Rank": 96279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12716,
    "Rank": 96293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12717,
    "Rank": 96317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12718,
    "Rank": 96411,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12719,
    "Rank": 96444,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12720,
    "Rank": 96463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12721,
    "Rank": 96498,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12722,
    "Rank": 96523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12723,
    "Rank": 96555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12724,
    "Rank": 96566,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12725,
    "Rank": 96598,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12726,
    "Rank": 96617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12727,
    "Rank": 96628,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12728,
    "Rank": 96668,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12729,
    "Rank": 96687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12730,
    "Rank": 96718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12731,
    "Rank": 96721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12732,
    "Rank": 96742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12733,
    "Rank": 96760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Ernakulam,HMT COLONY  P O, KALAMASSERY, ERNAKULAM DISTRICT, Kerala, 683503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12734,
    "Rank": 96858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt. Medical College, Karnal,Kalpana Chawla Govt. Medical College,Karnal, Haryana, Haryana, 132001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12735,
    "Rank": 96859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12736,
    "Rank": 96867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12737,
    "Rank": 96911,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12738,
    "Rank": 96915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12739,
    "Rank": 96933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12740,
    "Rank": 96946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12741,
    "Rank": 96973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12742,
    "Rank": 96987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12743,
    "Rank": 97061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12744,
    "Rank": 97083,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12745,
    "Rank": 97138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12746,
    "Rank": 97151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12747,
    "Rank": 97192,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12748,
    "Rank": 97199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diphu Medical College & Hospital, Assam,principaldiphu1@gmail.com, Assam, 782462",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12749,
    "Rank": 97251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12750,
    "Rank": 97257,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12751,
    "Rank": 97302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12752,
    "Rank": 97315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12753,
    "Rank": 97325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12754,
    "Rank": 97403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12755,
    "Rank": 97412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12756,
    "Rank": 97447,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12757,
    "Rank": 97449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12758,
    "Rank": 97489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12759,
    "Rank": 97509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12760,
    "Rank": 97535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12761,
    "Rank": 97559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12762,
    "Rank": 97635,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12763,
    "Rank": 97755,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12764,
    "Rank": 97815,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12765,
    "Rank": 97818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12766,
    "Rank": 97832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Kannur,P.O Pariyaram Medical College, Kannur, Kerala 670503, Kerala, 670503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12767,
    "Rank": 97837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12768,
    "Rank": 97869,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12769,
    "Rank": 97892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12770,
    "Rank": 97940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12771,
    "Rank": 97942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12772,
    "Rank": 97956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12773,
    "Rank": 97973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12774,
    "Rank": 98080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12775,
    "Rank": 98123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12776,
    "Rank": 98124,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12777,
    "Rank": 98129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12778,
    "Rank": 98136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12779,
    "Rank": 98152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12780,
    "Rank": 98183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12781,
    "Rank": 98189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12782,
    "Rank": 98201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12783,
    "Rank": 98206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12784,
    "Rank": 98209,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12785,
    "Rank": 98239,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12786,
    "Rank": 98302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12787,
    "Rank": 98306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12788,
    "Rank": 98336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12789,
    "Rank": 98338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12790,
    "Rank": 98344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12791,
    "Rank": 98353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12792,
    "Rank": 98362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12793,
    "Rank": 98364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12794,
    "Rank": 98372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12795,
    "Rank": 98381,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12796,
    "Rank": 98479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12797,
    "Rank": 98485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12798,
    "Rank": 98513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12799,
    "Rank": 98531,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12800,
    "Rank": 98532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12801,
    "Rank": 98545,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12802,
    "Rank": 98627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12803,
    "Rank": 98676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12804,
    "Rank": 98738,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12805,
    "Rank": 98754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12806,
    "Rank": 98784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12807,
    "Rank": 98798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12808,
    "Rank": 98804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Kodagu,Kodagu Institute of Medical Sciences, Madikeri., Karnataka, 571201",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12809,
    "Rank": 98814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12810,
    "Rank": 98918,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12811,
    "Rank": 98925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12812,
    "Rank": 99001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12813,
    "Rank": 99055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12814,
    "Rank": 99085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12815,
    "Rank": 99115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12816,
    "Rank": 99200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12817,
    "Rank": 99277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12818,
    "Rank": 99288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12819,
    "Rank": 99297,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12820,
    "Rank": 99342,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College 1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12821,
    "Rank": 99363,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12822,
    "Rank": 99370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Khandwa,Luv-Kush Nagar,Mundi Road,Khandwa (M.P.), Madhya Pradesh, 450001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12823,
    "Rank": 99401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12824,
    "Rank": 99409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12825,
    "Rank": 99415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12826,
    "Rank": 99440,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12827,
    "Rank": 99445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12828,
    "Rank": 99446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12829,
    "Rank": 99519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12830,
    "Rank": 99536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12831,
    "Rank": 99545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12832,
    "Rank": 99577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE TUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12833,
    "Rank": 99606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12834,
    "Rank": 99651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12835,
    "Rank": 99684,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12836,
    "Rank": 99706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12837,
    "Rank": 99708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12838,
    "Rank": 99738,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12839,
    "Rank": 99762,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12840,
    "Rank": 99771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12841,
    "Rank": 99774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12842,
    "Rank": 99782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12843,
    "Rank": 99820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12844,
    "Rank": 99840,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12845,
    "Rank": 99841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12846,
    "Rank": 99889,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12847,
    "Rank": 99902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12848,
    "Rank": 99906,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12849,
    "Rank": 99910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12850,
    "Rank": 99931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12851,
    "Rank": 99953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12852,
    "Rank": 100018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12853,
    "Rank": 100021,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12854,
    "Rank": 100056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12855,
    "Rank": 100087,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12856,
    "Rank": 100119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Firozabad,DAULATTAPUR JALESHAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12857,
    "Rank": 100137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12858,
    "Rank": 100141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Rajnandgaon,Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Pendri, Rajnandgaon. Chhattisg, Chhattisgarh, 491441",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12859,
    "Rank": 100188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12860,
    "Rank": 100239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12861,
    "Rank": 100243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12862,
    "Rank": 100297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Badaun,Gunera Wazidpur Ujhani Road Badaun U.P. 243601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12863,
    "Rank": 100325,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12864,
    "Rank": 100360,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12865,
    "Rank": 100367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12866,
    "Rank": 100369,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12867,
    "Rank": 100376,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12868,
    "Rank": 100382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12869,
    "Rank": 100385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12870,
    "Rank": 100386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12871,
    "Rank": 100415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12872,
    "Rank": 100432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12873,
    "Rank": 100438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12874,
    "Rank": 100450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College & RI, Puducherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12875,
    "Rank": 100604,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12876,
    "Rank": 100614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12877,
    "Rank": 100625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12878,
    "Rank": 100647,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12879,
    "Rank": 100653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12880,
    "Rank": 100705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12881,
    "Rank": 100729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12882,
    "Rank": 100743,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12883,
    "Rank": 100757,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12884,
    "Rank": 100765,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12885,
    "Rank": 100767,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12886,
    "Rank": 100801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12887,
    "Rank": 100853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12888,
    "Rank": 100860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12889,
    "Rank": 100870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12890,
    "Rank": 100892,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12891,
    "Rank": 100912,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 3 )"
  },
  {
    "SNo": 12892,
    "Rank": 100950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12893,
    "Rank": 100993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12894,
    "Rank": 101009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12895,
    "Rank": 101072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet,AMARAVADI NAGAR, TALLAGADDA, SURYAPET-508213\nSuraypet Dist, Telangana, 508213",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12896,
    "Rank": 101082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Palakkad,NH-544, EAST YAKKARA, KUNNATHURMEDU POST, PALAKKAD-678013, Kerala, 678013",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12897,
    "Rank": 101088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12898,
    "Rank": 101111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12899,
    "Rank": 101119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12900,
    "Rank": 101123,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12901,
    "Rank": 101126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12902,
    "Rank": 101158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12903,
    "Rank": 101159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chitradurga Medical College and Research Institute,P B Road Chitradurga, Karnataka, 577501",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12904,
    "Rank": 101178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12905,
    "Rank": 101205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12906,
    "Rank": 101206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Faizabad ,GANJA PARGANA- HAVELI AWADH, TAHSIL- SADAR AYODHYA (FAIZABAD), Uttar Pradesh, 224001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12907,
    "Rank": 101210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12908,
    "Rank": 101256,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12909,
    "Rank": 101315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12910,
    "Rank": 101316,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12911,
    "Rank": 101325,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12912,
    "Rank": 101357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12913,
    "Rank": 101448,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12914,
    "Rank": 101474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12915,
    "Rank": 101513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12916,
    "Rank": 101526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12917,
    "Rank": 101539,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. DENTAL COLLEGE,AGARTAL,IGM Hospital Complex, Agartala, West Tripura, PIN- 799001, Tripura, 799001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12918,
    "Rank": 101602,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12919,
    "Rank": 101611,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12920,
    "Rank": 101663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12921,
    "Rank": 101685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12922,
    "Rank": 101753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12923,
    "Rank": 101780,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12924,
    "Rank": 101819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12925,
    "Rank": 101866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12926,
    "Rank": 101873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12927,
    "Rank": 101902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12928,
    "Rank": 101942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12929,
    "Rank": 101949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12930,
    "Rank": 101960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12931,
    "Rank": 102107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12932,
    "Rank": 102137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12933,
    "Rank": 102190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12934,
    "Rank": 102208,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12935,
    "Rank": 102234,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12936,
    "Rank": 102294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12937,
    "Rank": 102297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12938,
    "Rank": 102349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12939,
    "Rank": 102358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12940,
    "Rank": 102360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12941,
    "Rank": 102378,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12942,
    "Rank": 102384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12943,
    "Rank": 102431,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12944,
    "Rank": 102446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12945,
    "Rank": 102586,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12946,
    "Rank": 102605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12947,
    "Rank": 102608,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12948,
    "Rank": 102609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12949,
    "Rank": 102628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12950,
    "Rank": 102671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12951,
    "Rank": 102672,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12952,
    "Rank": 102691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12953,
    "Rank": 102761,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12954,
    "Rank": 102778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12955,
    "Rank": 102780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12956,
    "Rank": 102860,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12957,
    "Rank": 102866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12958,
    "Rank": 102905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12959,
    "Rank": 102906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12960,
    "Rank": 102979,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12961,
    "Rank": 102981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12962,
    "Rank": 103021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12963,
    "Rank": 103074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12964,
    "Rank": 103078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12965,
    "Rank": 103081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12966,
    "Rank": 103097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12967,
    "Rank": 103102,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12968,
    "Rank": 103103,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12969,
    "Rank": 103113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12970,
    "Rank": 103130,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 24 )"
  },
  {
    "SNo": 12971,
    "Rank": 103173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12972,
    "Rank": 103234,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12973,
    "Rank": 103319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12974,
    "Rank": 103336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12975,
    "Rank": 103353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12976,
    "Rank": 103369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12977,
    "Rank": 103402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12978,
    "Rank": 103410,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Porbandar,Nr. Maharani Rupaliba Lady Hospital, ST bus station road, Porbandar, Gujarat, 360575",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12979,
    "Rank": 103415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12980,
    "Rank": 103483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12981,
    "Rank": 103487,
    "Allotted Quota": "All India",
    "Allotted Institute": "SUH Maulana Mahmood Hasan Medical College, Saharanpur,Ambala Road, Pilakhni, Saharanpur, Uttar Pradesh, 247001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12982,
    "Rank": 103520,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12983,
    "Rank": 103534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College Society , Mirzapur,MAA VINDHYAVASINI AUTONOMOUS STATE MEDICAL COLLEGE PIPARDARH, VISHUNDERPUR,\nMIRZAPUR, Uttar Pradesh, 231001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12984,
    "Rank": 103570,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12985,
    "Rank": 103589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12986,
    "Rank": 103595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12987,
    "Rank": 103621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12988,
    "Rank": 103674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12989,
    "Rank": 103684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12990,
    "Rank": 103743,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12991,
    "Rank": 103745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12992,
    "Rank": 103831,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12993,
    "Rank": 103845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12994,
    "Rank": 103886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12995,
    "Rank": 104025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12996,
    "Rank": 104031,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12997,
    "Rank": 104061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12998,
    "Rank": 104111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 12999,
    "Rank": 104114,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13000,
    "Rank": 104135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13001,
    "Rank": 104138,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13002,
    "Rank": 104241,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13003,
    "Rank": 104278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13004,
    "Rank": 104292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13005,
    "Rank": 104307,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13006,
    "Rank": 104313,
    "Allotted Quota": "All India",
    "Allotted Institute": "UTTARANCHAL F HOSP TRUST MC,HALDWANI,Government Medical College, Rampur Road Haldwani, Distt. Nainiatl, Uttarakhand, 263139",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13007,
    "Rank": 104322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13008,
    "Rank": 104348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13009,
    "Rank": 104353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13010,
    "Rank": 104376,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13011,
    "Rank": 104402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13012,
    "Rank": 104407,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13013,
    "Rank": 104465,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lakhimpur Medical College, North Lakhimpur, Assam,Chowkham Saboti North Lakhimpur\nLakhimpur, Assam, 787051",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13014,
    "Rank": 104471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13015,
    "Rank": 104489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Kanpur Dehat, Uttar Pradesh,Kumbhi, Akabarpur, Kanpur Dehat U.P., Uttar Pradesh, 209101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13016,
    "Rank": 104492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13017,
    "Rank": 104520,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13018,
    "Rank": 104589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13019,
    "Rank": 104608,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13020,
    "Rank": 104611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13021,
    "Rank": 104652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13022,
    "Rank": 104660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13023,
    "Rank": 104697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13024,
    "Rank": 104740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13025,
    "Rank": 104767,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13026,
    "Rank": 104786,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13027,
    "Rank": 104833,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13028,
    "Rank": 104843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13029,
    "Rank": 104884,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13030,
    "Rank": 104907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13031,
    "Rank": 104927,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13032,
    "Rank": 104929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13033,
    "Rank": 104978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government of Medical College and Hospital, Balasore,Remuna, Balasore, 756019, Odisha, 756019",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13034,
    "Rank": 105027,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHEIKH BHIKHARI MEDICAL COLLEGE & HOSPITAL, HAZARIBAG ( Formerly called\nas- Hazaribagh Medical College, Hazaribag),NEAR CENTRAL JAIL, HAZARIBAG, Jharkhand, 825301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13035,
    "Rank": 105042,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13036,
    "Rank": 105094,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13037,
    "Rank": 105120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13038,
    "Rank": 105159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Diamond Harbour Govt Medical College,New Town, Post-Diamond Harbour, PS- Diamond Harbour, District South 24 Parganas, West Bengal, 743331",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13039,
    "Rank": 105166,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13040,
    "Rank": 105187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. L A M Govt. Medical College, Raigarh,T.V.Tower road, Bendrachua, Raigarh, Chhattisgarh, 496001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13041,
    "Rank": 105192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13042,
    "Rank": 105203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13043,
    "Rank": 105229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Narayanpet, Telangana,Government Medical College Narayanpet Jajapur Narayanpet District Telangana  Pincode 509210, Telangana, 509210",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13044,
    "Rank": 105243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13045,
    "Rank": 105268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13046,
    "Rank": 105282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13047,
    "Rank": 105309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13048,
    "Rank": 105329,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13049,
    "Rank": 105333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13050,
    "Rank": 105346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13051,
    "Rank": 105350,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13052,
    "Rank": 105368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13053,
    "Rank": 105395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13054,
    "Rank": 105402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13055,
    "Rank": 105414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13056,
    "Rank": 105433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13057,
    "Rank": 105440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13058,
    "Rank": 105536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13059,
    "Rank": 105571,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13060,
    "Rank": 105636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13061,
    "Rank": 105638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13062,
    "Rank": 105654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13063,
    "Rank": 105676,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13064,
    "Rank": 105685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13065,
    "Rank": 105704,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13066,
    "Rank": 105709,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13067,
    "Rank": 105732,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13068,
    "Rank": 105743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13069,
    "Rank": 105751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13070,
    "Rank": 105814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Datia,NEAR 29TH BATTALION,  N.H.75, DATIA, Madhya Pradesh, 475661",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13071,
    "Rank": 105848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13072,
    "Rank": 105901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13073,
    "Rank": 105936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13074,
    "Rank": 105942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Siddharthnagar,Autonomous State Medical College Siddharthnagar\n71 Ashok Marg Village Mudila Naugarh Uttar Prade, Uttar Pradesh, 272207",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13075,
    "Rank": 105988,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13076,
    "Rank": 106003,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13077,
    "Rank": 106015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13078,
    "Rank": 106052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gmc, Bahraich,K.D.C Road Bahraich-271801, Uttar Pradesh, 271801",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13079,
    "Rank": 106057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13080,
    "Rank": 106068,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INST. OF MEDICAL SCI., RAICHUR,RAICHUR INSTITUTE OF MEDICAL SCIENCES\nINDUSTRIAL AREA,\nHYDERABAD ROAD, RAICHUR,  Karnataka 584102, Karnataka, 584102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13081,
    "Rank": 106104,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13082,
    "Rank": 106107,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13083,
    "Rank": 106120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13084,
    "Rank": 106151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13085,
    "Rank": 106157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13086,
    "Rank": 106170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13087,
    "Rank": 106177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13088,
    "Rank": 106216,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13089,
    "Rank": 106219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13090,
    "Rank": 106249,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13091,
    "Rank": 106298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13092,
    "Rank": 106322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Rajpipla,GMERS Medical College, Rajpipla Near Jakat Naka, Vadia Palace, Rajpipla\nDist Narmada, GUJARAT., Gujarat, 393145",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13093,
    "Rank": 106325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13094,
    "Rank": 106336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13095,
    "Rank": 106339,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13096,
    "Rank": 106468,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13097,
    "Rank": 106485,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13098,
    "Rank": 106501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13099,
    "Rank": 106570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13100,
    "Rank": 106597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13101,
    "Rank": 106622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13102,
    "Rank": 106633,
    "Allotted Quota": "All India",
    "Allotted Institute": "YADGIRI INSTITUTE OF MEDICAL SCIENCES, YADGIRI,Chittapur Main Road,\nYadgiri., Karnataka, 585202",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13103,
    "Rank": 106656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13104,
    "Rank": 106846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13105,
    "Rank": 106866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13106,
    "Rank": 106875,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13107,
    "Rank": 106947,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13108,
    "Rank": 106967,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13109,
    "Rank": 106980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13110,
    "Rank": 107033,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13111,
    "Rank": 107118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13112,
    "Rank": 107135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13113,
    "Rank": 107149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kokrajhar Medical College & Hospital Rangalikhata,Rangalikhata Pt.-1, Kokrajhar, Assam, 783370",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13114,
    "Rank": 107155,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13115,
    "Rank": 107159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13116,
    "Rank": 107182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13117,
    "Rank": 107200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13118,
    "Rank": 107215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13119,
    "Rank": 107309,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERMENT MEDICAL COLLEGE NANDURBAR MAHARARASTRA,DISTRICT CIVIL HOSPITAL CAMPUS SAKRI ROAD NANDURBAR, Maharashtra, 425412",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13120,
    "Rank": 107327,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13121,
    "Rank": 107349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13122,
    "Rank": 107366,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13123,
    "Rank": 107442,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13124,
    "Rank": 107570,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13125,
    "Rank": 107584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13126,
    "Rank": 107644,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13127,
    "Rank": 107650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13128,
    "Rank": 107673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13129,
    "Rank": 107701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13130,
    "Rank": 107726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13131,
    "Rank": 107809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13132,
    "Rank": 107819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13133,
    "Rank": 107821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13134,
    "Rank": 107834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13135,
    "Rank": 107838,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13136,
    "Rank": 107861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13137,
    "Rank": 107867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13138,
    "Rank": 107881,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13139,
    "Rank": 107962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13140,
    "Rank": 107972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13141,
    "Rank": 108009,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13142,
    "Rank": 108085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13143,
    "Rank": 108120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13144,
    "Rank": 108139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical Collage, Kushinagar, Uttar Pradesh,Autonomous State Medical Collage, Kubersthan Road, Harka, Kushinagar, Uttar Pradesh, 274304",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13145,
    "Rank": 108140,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13146,
    "Rank": 108147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13147,
    "Rank": 108175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13148,
    "Rank": 108186,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13149,
    "Rank": 108197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13150,
    "Rank": 108207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13151,
    "Rank": 108333,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13152,
    "Rank": 108350,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13153,
    "Rank": 108419,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13154,
    "Rank": 108498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13155,
    "Rank": 108538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13156,
    "Rank": 108560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13157,
    "Rank": 108606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13158,
    "Rank": 108611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13159,
    "Rank": 108654,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13160,
    "Rank": 108768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13161,
    "Rank": 108850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13162,
    "Rank": 108946,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13163,
    "Rank": 108949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13164,
    "Rank": 108950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13165,
    "Rank": 108961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13166,
    "Rank": 109133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13167,
    "Rank": 109229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13168,
    "Rank": 109287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13169,
    "Rank": 109309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13170,
    "Rank": 109324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13171,
    "Rank": 109331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13172,
    "Rank": 109379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13173,
    "Rank": 109431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13174,
    "Rank": 109458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13175,
    "Rank": 109487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13176,
    "Rank": 109592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13177,
    "Rank": 109598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13178,
    "Rank": 109634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13179,
    "Rank": 109718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13180,
    "Rank": 109758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13181,
    "Rank": 109779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13182,
    "Rank": 109821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13183,
    "Rank": 109900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13184,
    "Rank": 109921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13185,
    "Rank": 109930,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13186,
    "Rank": 109931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13187,
    "Rank": 109933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13188,
    "Rank": 109945,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13189,
    "Rank": 109967,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13190,
    "Rank": 109986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13191,
    "Rank": 110006,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13192,
    "Rank": 110077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13193,
    "Rank": 110112,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13194,
    "Rank": 110201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13195,
    "Rank": 110207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13196,
    "Rank": 110236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13197,
    "Rank": 110246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13198,
    "Rank": 110324,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13199,
    "Rank": 110331,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13200,
    "Rank": 110341,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13201,
    "Rank": 110429,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13202,
    "Rank": 110510,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,88, College Street, Kolkata-700073, West Bengal, 700073",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13203,
    "Rank": 110581,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13204,
    "Rank": 110667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13205,
    "Rank": 110762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13206,
    "Rank": 110849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13207,
    "Rank": 110879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13208,
    "Rank": 110899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13209,
    "Rank": 110932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13210,
    "Rank": 110966,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13211,
    "Rank": 111020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13212,
    "Rank": 111069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13213,
    "Rank": 111085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13214,
    "Rank": 111268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13215,
    "Rank": 111290,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13216,
    "Rank": 111299,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13217,
    "Rank": 111337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13218,
    "Rank": 111391,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13219,
    "Rank": 111436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13220,
    "Rank": 111452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13221,
    "Rank": 111457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13222,
    "Rank": 111461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13223,
    "Rank": 111478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13224,
    "Rank": 111480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13225,
    "Rank": 111494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13226,
    "Rank": 111519,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13227,
    "Rank": 111594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13228,
    "Rank": 111694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13229,
    "Rank": 111715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13230,
    "Rank": 111744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13231,
    "Rank": 111765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13232,
    "Rank": 111874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13233,
    "Rank": 111893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13234,
    "Rank": 111945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13235,
    "Rank": 112011,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13236,
    "Rank": 112019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13237,
    "Rank": 112086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13238,
    "Rank": 112115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gadag Institute of Medical Sciences,GADAG INSTITUTE OF MEDICAL SCIENCES GADAG Mallasamudra,Gadag-582103, Karnataka, 582103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13239,
    "Rank": 112183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13240,
    "Rank": 112350,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13241,
    "Rank": 112415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13242,
    "Rank": 112572,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13243,
    "Rank": 112628,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13244,
    "Rank": 112685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13245,
    "Rank": 112689,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13246,
    "Rank": 112708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13247,
    "Rank": 112722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13248,
    "Rank": 112730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13249,
    "Rank": 112751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13250,
    "Rank": 112826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13251,
    "Rank": 112834,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13252,
    "Rank": 112840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College Kolhapur,Rajarshee Chhatrapati Shahu Maharaj\nGovernment Medical College, R. K. Nagar Road, Shenda Park, Kol, Maharashtra, 416012",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13253,
    "Rank": 112923,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13254,
    "Rank": 112934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13255,
    "Rank": 112963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13256,
    "Rank": 113003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College ,Etah,NAGLA PADIHAR SIRAO MAHARARA LINK, PAC ROAD, SAKEET, ETAH,U.P.- 207001, Uttar Pradesh, 207001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13257,
    "Rank": 113129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13258,
    "Rank": 113134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13259,
    "Rank": 113260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13260,
    "Rank": 113272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13261,
    "Rank": 113316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13262,
    "Rank": 113322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13263,
    "Rank": 113399,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13264,
    "Rank": 113443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13265,
    "Rank": 113482,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13266,
    "Rank": 113490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13267,
    "Rank": 113493,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13268,
    "Rank": 113506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13269,
    "Rank": 113529,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13270,
    "Rank": 113556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13271,
    "Rank": 113576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13272,
    "Rank": 113648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13273,
    "Rank": 113659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13274,
    "Rank": 113664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13275,
    "Rank": 113672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13276,
    "Rank": 113710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13277,
    "Rank": 113732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13278,
    "Rank": 113736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13279,
    "Rank": 113791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13280,
    "Rank": 113857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13281,
    "Rank": 113871,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13282,
    "Rank": 113879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13283,
    "Rank": 113905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13284,
    "Rank": 113918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13285,
    "Rank": 113961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13286,
    "Rank": 113992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13287,
    "Rank": 114066,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13288,
    "Rank": 114101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13289,
    "Rank": 114123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13290,
    "Rank": 114140,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13291,
    "Rank": 114152,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13292,
    "Rank": 114187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13293,
    "Rank": 114270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13294,
    "Rank": 114271,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13295,
    "Rank": 114286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13296,
    "Rank": 114312,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13297,
    "Rank": 114414,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 16 )"
  },
  {
    "SNo": 13298,
    "Rank": 114478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13299,
    "Rank": 114558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13300,
    "Rank": 114564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13301,
    "Rank": 114585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13302,
    "Rank": 114593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13303,
    "Rank": 114624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13304,
    "Rank": 114816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13305,
    "Rank": 114886,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13306,
    "Rank": 115011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13307,
    "Rank": 115063,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13308,
    "Rank": 115097,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13309,
    "Rank": 115130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13310,
    "Rank": 115138,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Panchmahal Godhra,Government Engineering College Campus, Godhra Lunawada Road, Chabanpur, Godhra, Gujarat, 389001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13311,
    "Rank": 115150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13312,
    "Rank": 115244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13313,
    "Rank": 115301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13314,
    "Rank": 115340,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 10 )"
  },
  {
    "SNo": 13315,
    "Rank": 115372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13316,
    "Rank": 115393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Seoni, Madhya Pradesh,Government Medical College, Kandipaar Village, Seoni, Madhya Pradesh, 480661",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13317,
    "Rank": 115398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13318,
    "Rank": 115438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital , Balangir,PO - Rajendra College, District- Balangir, Odisha., Odisha, 767002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13319,
    "Rank": 115448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13320,
    "Rank": 115502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13321,
    "Rank": 115601,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13322,
    "Rank": 115622,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13323,
    "Rank": 115648,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13324,
    "Rank": 115716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13325,
    "Rank": 115718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13326,
    "Rank": 115771,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13327,
    "Rank": 115774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13328,
    "Rank": 115898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13329,
    "Rank": 115923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13330,
    "Rank": 116015,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13331,
    "Rank": 116032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13332,
    "Rank": 116053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13333,
    "Rank": 116072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13334,
    "Rank": 116114,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13335,
    "Rank": 116131,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13336,
    "Rank": 116133,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13337,
    "Rank": 116159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13338,
    "Rank": 116161,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13339,
    "Rank": 116165,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13340,
    "Rank": 116228,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13341,
    "Rank": 116270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13342,
    "Rank": 116292,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13343,
    "Rank": 116383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13344,
    "Rank": 116421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13345,
    "Rank": 116447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13346,
    "Rank": 116555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tomo Riba Institute Health and Medical Sciences, Naharlagun ,Tomo Riba Institute Health and Medical Sciences,\nOld Assembly Complex,\nNaharlagun, Itanagar Capita, Arunachal Pradesh, 791110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13347,
    "Rank": 116612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13348,
    "Rank": 116617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13349,
    "Rank": 116627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13350,
    "Rank": 116656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13351,
    "Rank": 116708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13352,
    "Rank": 116713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jagtial,Government Medical College, Jagtial, Jagtial Village, Jagtial Tehsil, Jagtial Dist, Telangana, 505327",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13353,
    "Rank": 116737,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13354,
    "Rank": 116744,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13355,
    "Rank": 116747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13356,
    "Rank": 116751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13357,
    "Rank": 116804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13358,
    "Rank": 116835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13359,
    "Rank": 116840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13360,
    "Rank": 116854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13361,
    "Rank": 116866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13362,
    "Rank": 116888,
    "Allotted Quota": "All India",
    "Allotted Institute": "SOBAN SINGH JEENA GOVERNMENT INSTITUTE OF MEDICAL SCIENCE &\nRESEARCH, ALMORA,Near Vikas Bhawan Pandeykhola Almora, Uttarakhand, 263601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13363,
    "Rank": 116898,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DEN COLL DENTAL WING PATIALA,Govt.Dental College and Hospital,Patiala, Punjab, 147001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13364,
    "Rank": 116942,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13365,
    "Rank": 116967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13366,
    "Rank": 116975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13367,
    "Rank": 116991,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13368,
    "Rank": 117016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13369,
    "Rank": 117028,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13370,
    "Rank": 117110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13371,
    "Rank": 117118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13372,
    "Rank": 117145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13373,
    "Rank": 117190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13374,
    "Rank": 117237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13375,
    "Rank": 117252,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13376,
    "Rank": 117261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13377,
    "Rank": 117279,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13378,
    "Rank": 117372,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13379,
    "Rank": 117432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13380,
    "Rank": 117487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13381,
    "Rank": 117597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13382,
    "Rank": 117673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13383,
    "Rank": 117685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13384,
    "Rank": 117687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13385,
    "Rank": 117767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13386,
    "Rank": 117774,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13387,
    "Rank": 117821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13388,
    "Rank": 117841,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13389,
    "Rank": 117851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13390,
    "Rank": 117856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13391,
    "Rank": 117857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13392,
    "Rank": 117909,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13393,
    "Rank": 117935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13394,
    "Rank": 117969,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13395,
    "Rank": 117972,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13396,
    "Rank": 117975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13397,
    "Rank": 117976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13398,
    "Rank": 117982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13399,
    "Rank": 118004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13400,
    "Rank": 118041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13401,
    "Rank": 118042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13402,
    "Rank": 118073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13403,
    "Rank": 118093,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13404,
    "Rank": 118181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13405,
    "Rank": 118200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13406,
    "Rank": 118204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13407,
    "Rank": 118234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13408,
    "Rank": 118274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13409,
    "Rank": 118305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13410,
    "Rank": 118307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13411,
    "Rank": 118459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13412,
    "Rank": 118460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13413,
    "Rank": 118490,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13414,
    "Rank": 118512,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13415,
    "Rank": 118571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13416,
    "Rank": 118617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13417,
    "Rank": 118657,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13418,
    "Rank": 118696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13419,
    "Rank": 118703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13420,
    "Rank": 118720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13421,
    "Rank": 118728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13422,
    "Rank": 118735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13423,
    "Rank": 118743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13424,
    "Rank": 118759,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13425,
    "Rank": 118811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13426,
    "Rank": 118832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13427,
    "Rank": 118996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13428,
    "Rank": 119002,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13429,
    "Rank": 119038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13430,
    "Rank": 119066,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. D.C. & RESEARCH INST,BANGALORE,FORT, VICTORIA HOSPITAL CAMPUS, BANGALORE, Karnataka, 560002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13431,
    "Rank": 119095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13432,
    "Rank": 119107,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL DENT.COLL,SUSHRUTNAGAR,P.O SHUSHRUTA NAGAR, DIST. DARJEELING,, West Bengal, 734012",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13433,
    "Rank": 119111,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13434,
    "Rank": 119114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13435,
    "Rank": 119116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13436,
    "Rank": 119146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13437,
    "Rank": 119157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13438,
    "Rank": 119162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13439,
    "Rank": 119171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13440,
    "Rank": 119196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13441,
    "Rank": 119242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13442,
    "Rank": 119249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13443,
    "Rank": 119284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13444,
    "Rank": 119290,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13445,
    "Rank": 119314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13446,
    "Rank": 119320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13447,
    "Rank": 119355,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13448,
    "Rank": 119386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College, Bettiah,Government Medical College Bettiah West Champaran\nBihar- 845438, Bihar, 845438",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13449,
    "Rank": 119441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13450,
    "Rank": 119449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13451,
    "Rank": 119491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13452,
    "Rank": 119519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13453,
    "Rank": 119543,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13454,
    "Rank": 119558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13455,
    "Rank": 119569,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13456,
    "Rank": 119590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13457,
    "Rank": 119619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13458,
    "Rank": 119621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13459,
    "Rank": 119634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13460,
    "Rank": 119645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13461,
    "Rank": 119674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13462,
    "Rank": 119677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13463,
    "Rank": 119700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13464,
    "Rank": 119707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13465,
    "Rank": 119712,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13466,
    "Rank": 119713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13467,
    "Rank": 119724,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13468,
    "Rank": 119791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13469,
    "Rank": 119818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13470,
    "Rank": 119840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13471,
    "Rank": 119925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13472,
    "Rank": 119988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Goverment Medical College And Hospital Jalgaon,GOVERNMENT MEDICAL COLLEGE, JILHA PETH, NEAR MAIN POST OFFICE, JALGAON, Maharashtra, 425001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13473,
    "Rank": 120020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13474,
    "Rank": 120028,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13475,
    "Rank": 120038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13476,
    "Rank": 120047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13477,
    "Rank": 120053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13478,
    "Rank": 120055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13479,
    "Rank": 120063,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13480,
    "Rank": 120107,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13481,
    "Rank": 120234,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13482,
    "Rank": 120304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13483,
    "Rank": 120422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13484,
    "Rank": 120455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13485,
    "Rank": 120494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13486,
    "Rank": 120529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13487,
    "Rank": 120548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13488,
    "Rank": 120562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13489,
    "Rank": 120674,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13490,
    "Rank": 120687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13491,
    "Rank": 120773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13492,
    "Rank": 120841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13493,
    "Rank": 120874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubangar,Government Medical College Mahabubnagar,Thirumala Hills,\nEdira Village.Mahabubnagar, Telangana, 509002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13494,
    "Rank": 121016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13495,
    "Rank": 121030,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13496,
    "Rank": 121052,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13497,
    "Rank": 121077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13498,
    "Rank": 121089,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13499,
    "Rank": 121122,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13500,
    "Rank": 121135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13501,
    "Rank": 121185,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13502,
    "Rank": 121365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13503,
    "Rank": 121403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13504,
    "Rank": 121414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13505,
    "Rank": 121458,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13506,
    "Rank": 121464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13507,
    "Rank": 121476,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13508,
    "Rank": 121496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13509,
    "Rank": 121507,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Laxman Nayak Med. College and Hos., Koraput,AT-JANIGUDA,KORAPUT, Odisha, 764020",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13510,
    "Rank": 121538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13511,
    "Rank": 121550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13512,
    "Rank": 121648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13513,
    "Rank": 121664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13514,
    "Rank": 121743,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13515,
    "Rank": 121794,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13516,
    "Rank": 121898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13517,
    "Rank": 121912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13518,
    "Rank": 121957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13519,
    "Rank": 122021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13520,
    "Rank": 122040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13521,
    "Rank": 122042,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13522,
    "Rank": 122058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13523,
    "Rank": 122071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13524,
    "Rank": 122098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13525,
    "Rank": 122212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13526,
    "Rank": 122228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13527,
    "Rank": 122239,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13528,
    "Rank": 122290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13529,
    "Rank": 122336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13530,
    "Rank": 122352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13531,
    "Rank": 122354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13532,
    "Rank": 122393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13533,
    "Rank": 122422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13534,
    "Rank": 122481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13535,
    "Rank": 122576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13536,
    "Rank": 122664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13537,
    "Rank": 122808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13538,
    "Rank": 122858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13539,
    "Rank": 122861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13540,
    "Rank": 122938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13541,
    "Rank": 122946,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13542,
    "Rank": 123005,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13543,
    "Rank": 123011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13544,
    "Rank": 123040,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13545,
    "Rank": 123041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13546,
    "Rank": 123043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13547,
    "Rank": 123167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13548,
    "Rank": 123214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13549,
    "Rank": 123215,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13550,
    "Rank": 123223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13551,
    "Rank": 123239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13552,
    "Rank": 123297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13553,
    "Rank": 123342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13554,
    "Rank": 123347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13555,
    "Rank": 123352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13556,
    "Rank": 123360,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13557,
    "Rank": 123468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13558,
    "Rank": 123513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13559,
    "Rank": 123516,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Rajanna Sircilla,GOVERNMENT MEDICAL COLLEGE NEAR KASTURBA GIRLS SCHOOL PEDDUR SIRCILLA DISTRICT RAJANNA SIRCILLA,\nTelangana, 505301",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13560,
    "Rank": 123567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13561,
    "Rank": 123635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13562,
    "Rank": 123638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13563,
    "Rank": 123696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13564,
    "Rank": 123704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13565,
    "Rank": 123707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13566,
    "Rank": 123715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13567,
    "Rank": 123730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13568,
    "Rank": 123736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13569,
    "Rank": 123745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13570,
    "Rank": 123798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13571,
    "Rank": 123835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13572,
    "Rank": 123899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13573,
    "Rank": 123943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13574,
    "Rank": 123962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13575,
    "Rank": 123964,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13576,
    "Rank": 124044,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13577,
    "Rank": 124068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13578,
    "Rank": 124096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13579,
    "Rank": 124105,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13580,
    "Rank": 124124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13581,
    "Rank": 124127,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13582,
    "Rank": 124132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13583,
    "Rank": 124137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13584,
    "Rank": 124152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13585,
    "Rank": 124160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13586,
    "Rank": 124188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13587,
    "Rank": 124232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13588,
    "Rank": 124237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13589,
    "Rank": 124246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13590,
    "Rank": 124263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13591,
    "Rank": 124287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13592,
    "Rank": 124307,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13593,
    "Rank": 124322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13594,
    "Rank": 124331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13595,
    "Rank": 124345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13596,
    "Rank": 124348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt dental college Pudukkottai,GOVT DENTAL COLLEGE MULLUR PUDUKKOTTAI, Tamil Nadu, 622003",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13597,
    "Rank": 124372,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DHARAMAPURI MED COLL,DHARMAPURI,NETHAJI BYPASS ROAD DHARMAPURI, Tamil Nadu, 636701",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13598,
    "Rank": 124486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13599,
    "Rank": 124538,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13600,
    "Rank": 124564,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13601,
    "Rank": 124595,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13602,
    "Rank": 124647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13603,
    "Rank": 124695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13604,
    "Rank": 124712,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13605,
    "Rank": 124809,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13606,
    "Rank": 124826,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13607,
    "Rank": 124868,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13608,
    "Rank": 124924,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Kamareddy,Office of the Principal, Government Medical College Devanpally, Kamareddy, Telangana, Telangana, 503111",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13609,
    "Rank": 125081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13610,
    "Rank": 125083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13611,
    "Rank": 125090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13612,
    "Rank": 125094,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13613,
    "Rank": 125106,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13614,
    "Rank": 125124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13615,
    "Rank": 125218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13616,
    "Rank": 125285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13617,
    "Rank": 125296,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13618,
    "Rank": 125302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13619,
    "Rank": 125331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13620,
    "Rank": 125418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13621,
    "Rank": 125510,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13622,
    "Rank": 125609,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13623,
    "Rank": 125622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13624,
    "Rank": 125778,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13625,
    "Rank": 125791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13626,
    "Rank": 125841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13627,
    "Rank": 125842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13628,
    "Rank": 125852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13629,
    "Rank": 125905,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13630,
    "Rank": 125914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13631,
    "Rank": 126000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13632,
    "Rank": 126047,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13633,
    "Rank": 126068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13634,
    "Rank": 126136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13635,
    "Rank": 126168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13636,
    "Rank": 126192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13637,
    "Rank": 126266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13638,
    "Rank": 126278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Idukki,Principal, Govt Medical College Idukki, Idukki colony P.O.,Idukki District, Kerala, 685602",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13639,
    "Rank": 126365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13640,
    "Rank": 126395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13641,
    "Rank": 126409,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13642,
    "Rank": 126456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jannayak Karpoori Thakur Medical college and Hospital, Madhepura,JAJHAT SABAILA SINGHESHWAR ROAD DISTRICT MADHEPURA, Bihar, 852128",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13643,
    "Rank": 126514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13644,
    "Rank": 126541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13645,
    "Rank": 126551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13646,
    "Rank": 126558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13647,
    "Rank": 126595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13648,
    "Rank": 126625,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13649,
    "Rank": 126634,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13650,
    "Rank": 126761,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES RIMS, PUTLAMPALLI, YSR KADAPA DIST.,\nAndhra Pradesh, 516002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13651,
    "Rank": 126764,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13652,
    "Rank": 126793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13653,
    "Rank": 126796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13654,
    "Rank": 126808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13655,
    "Rank": 126810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13656,
    "Rank": 126816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13657,
    "Rank": 126853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13658,
    "Rank": 126856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13659,
    "Rank": 126876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13660,
    "Rank": 126937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13661,
    "Rank": 126960,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13662,
    "Rank": 127030,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13663,
    "Rank": 127109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13664,
    "Rank": 127123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13665,
    "Rank": 127196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13666,
    "Rank": 127249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13667,
    "Rank": 127265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13668,
    "Rank": 127289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13669,
    "Rank": 127310,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13670,
    "Rank": 127321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13671,
    "Rank": 127375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13672,
    "Rank": 127460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13673,
    "Rank": 127509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13674,
    "Rank": 127549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13675,
    "Rank": 127563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13676,
    "Rank": 127597,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13677,
    "Rank": 127618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13678,
    "Rank": 127748,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE Karimnagar Telangana,The Principal, Government Medical College Karimnagar,\n127, Christian Colony, Karimnagar, Telangana, 505001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13679,
    "Rank": 127756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13680,
    "Rank": 127765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13681,
    "Rank": 127769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13682,
    "Rank": 127777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13683,
    "Rank": 127795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13684,
    "Rank": 127868,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MED. COLLEGE, MALDA,MaldaMedicalCollege, PS,EnglishBazar PO Dist Malda, West Bengal, 732101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13685,
    "Rank": 127983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13686,
    "Rank": 127989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13687,
    "Rank": 128008,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 18 )"
  },
  {
    "SNo": 13688,
    "Rank": 128015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13689,
    "Rank": 128049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13690,
    "Rank": 128059,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13691,
    "Rank": 128083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13692,
    "Rank": 128132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13693,
    "Rank": 128248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13694,
    "Rank": 128249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13695,
    "Rank": 128341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13696,
    "Rank": 128370,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13697,
    "Rank": 128431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13698,
    "Rank": 128435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13699,
    "Rank": 128502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13700,
    "Rank": 128599,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13701,
    "Rank": 128655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13702,
    "Rank": 128663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13703,
    "Rank": 128664,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13704,
    "Rank": 128692,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13705,
    "Rank": 128704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13706,
    "Rank": 128749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13707,
    "Rank": 128865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13708,
    "Rank": 128969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13709,
    "Rank": 128970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13710,
    "Rank": 128974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13711,
    "Rank": 128990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13712,
    "Rank": 128992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13713,
    "Rank": 129077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13714,
    "Rank": 129081,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13715,
    "Rank": 129191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13716,
    "Rank": 129251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13717,
    "Rank": 129297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13718,
    "Rank": 129325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13719,
    "Rank": 129432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13720,
    "Rank": 129456,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13721,
    "Rank": 129463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13722,
    "Rank": 129492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13723,
    "Rank": 129497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13724,
    "Rank": 129568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sindhudurg,Dean Government Medical College Sindhudurg, Maharashtra, 416812",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13725,
    "Rank": 129575,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13726,
    "Rank": 129594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13727,
    "Rank": 129615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Jalpaiguri,Hospital Road, Jalpaiguri, West Bengal- 735101, West Bengal, 735101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13728,
    "Rank": 129654,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13729,
    "Rank": 129663,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13730,
    "Rank": 129696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13731,
    "Rank": 129701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13732,
    "Rank": 129768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13733,
    "Rank": 129799,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13734,
    "Rank": 129934,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13735,
    "Rank": 129992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13736,
    "Rank": 130013,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13737,
    "Rank": 130025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13738,
    "Rank": 130038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13739,
    "Rank": 130143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13740,
    "Rank": 130161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13741,
    "Rank": 130243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13742,
    "Rank": 130276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13743,
    "Rank": 130281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13744,
    "Rank": 130350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13745,
    "Rank": 130392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13746,
    "Rank": 130465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13747,
    "Rank": 130482,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13748,
    "Rank": 130487,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13749,
    "Rank": 130504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13750,
    "Rank": 130527,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13751,
    "Rank": 130533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13752,
    "Rank": 130538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13753,
    "Rank": 130675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13754,
    "Rank": 130710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13755,
    "Rank": 130715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13756,
    "Rank": 130722,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13757,
    "Rank": 130747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Mulugu, Telangana,gmc.mulugu@gmail.com, Telangana, 506342",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13758,
    "Rank": 130774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13759,
    "Rank": 130777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13760,
    "Rank": 130814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13761,
    "Rank": 130835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13762,
    "Rank": 130921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13763,
    "Rank": 130934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13764,
    "Rank": 130952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi,Odisha,At - Bhangabari, P.O. - Uditnarayanpur, Bhawanipatna, Dist - Kalahandi, Odisha, 766002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13765,
    "Rank": 130968,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13766,
    "Rank": 131027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13767,
    "Rank": 131071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13768,
    "Rank": 131102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13769,
    "Rank": 131110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13770,
    "Rank": 131132,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, OSMANABAD,New Building, Civil Hospital Compound, Marwad Galli, Osmanabad, Maharashtra, 413501",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13771,
    "Rank": 131145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13772,
    "Rank": 131164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13773,
    "Rank": 131193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13774,
    "Rank": 131262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13775,
    "Rank": 131287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13776,
    "Rank": 131326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13777,
    "Rank": 131464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13778,
    "Rank": 131526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13779,
    "Rank": 131548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13780,
    "Rank": 131571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13781,
    "Rank": 131612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13782,
    "Rank": 131666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13783,
    "Rank": 131682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13784,
    "Rank": 131685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13785,
    "Rank": 131687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13786,
    "Rank": 131691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13787,
    "Rank": 131705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13788,
    "Rank": 131857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13789,
    "Rank": 131871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bhadradri, Kothagudem,Besides Sammakka Saarakka temple, Opp KSM Petrol bunk, end of 6th Battalion Rd, Palvancha, Telangana, Telangana, 507118",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13790,
    "Rank": 131888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13791,
    "Rank": 131893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13792,
    "Rank": 131895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13793,
    "Rank": 131908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13794,
    "Rank": 131913,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13795,
    "Rank": 131943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13796,
    "Rank": 131961,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13797,
    "Rank": 131962,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13798,
    "Rank": 131963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13799,
    "Rank": 131985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13800,
    "Rank": 131998,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13801,
    "Rank": 132005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13802,
    "Rank": 132018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13803,
    "Rank": 132035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13804,
    "Rank": 132037,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13805,
    "Rank": 132043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13806,
    "Rank": 132082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13807,
    "Rank": 132085,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SANGAREDDY,OPP. TOWN POLICE STATION, NETAJI NAGAR, SANGAREDDY DISTRICT, TELANGANA - 502001, Telangana, 502001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13808,
    "Rank": 132158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13809,
    "Rank": 132199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13810,
    "Rank": 132220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Khammam,Wyra Road, Khammam, Telangana, 507002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13811,
    "Rank": 132268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13812,
    "Rank": 132273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13813,
    "Rank": 132294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13814,
    "Rank": 132340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13815,
    "Rank": 132363,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13816,
    "Rank": 132379,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13817,
    "Rank": 132386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13818,
    "Rank": 132422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13819,
    "Rank": 132494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13820,
    "Rank": 132612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramanathapuram,No 1, Patinamkathan, Collectorate Backside,\nRamanathapuram., Tamil Nadu, 623503",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13821,
    "Rank": 132625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13822,
    "Rank": 132643,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13823,
    "Rank": 132712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13824,
    "Rank": 132727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13825,
    "Rank": 132742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13826,
    "Rank": 132840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13827,
    "Rank": 132881,
    "Allotted Quota": "All India",
    "Allotted Institute": "THENI GOVT. MEDICAL COLLEGE, THENI,G. Vilakku, Shanmugasundarapuram village, Aundipatti Taluk, Theni District, Tamil Nadu, 625512",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13828,
    "Rank": 132909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13829,
    "Rank": 132910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13830,
    "Rank": 132913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13831,
    "Rank": 132970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13832,
    "Rank": 132978,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13833,
    "Rank": 132998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13834,
    "Rank": 133007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13835,
    "Rank": 133008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13836,
    "Rank": 133051,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13837,
    "Rank": 133081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13838,
    "Rank": 133094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Narsampet, Telangana,Sarwapuram, Pakhal road, Land mark- Bharath petrol pump, Narsamapet, Telangana, 506132",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13839,
    "Rank": 133152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13840,
    "Rank": 133188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13841,
    "Rank": 133235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13842,
    "Rank": 133244,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13843,
    "Rank": 133270,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13844,
    "Rank": 133290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13845,
    "Rank": 133301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13846,
    "Rank": 133324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13847,
    "Rank": 133379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13848,
    "Rank": 133423,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13849,
    "Rank": 133455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13850,
    "Rank": 133485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13851,
    "Rank": 133490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13852,
    "Rank": 133511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13853,
    "Rank": 133550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13854,
    "Rank": 133601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13855,
    "Rank": 133614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13856,
    "Rank": 133663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13857,
    "Rank": 133678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13858,
    "Rank": 133763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13859,
    "Rank": 133769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13860,
    "Rank": 133772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13861,
    "Rank": 133782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13862,
    "Rank": 133789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13863,
    "Rank": 133891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13864,
    "Rank": 133940,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13865,
    "Rank": 133946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13866,
    "Rank": 134050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13867,
    "Rank": 134061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13868,
    "Rank": 134078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13869,
    "Rank": 134107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13870,
    "Rank": 134125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13871,
    "Rank": 134176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13872,
    "Rank": 134289,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13873,
    "Rank": 134303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13874,
    "Rank": 134320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13875,
    "Rank": 134359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13876,
    "Rank": 134364,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13877,
    "Rank": 134399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13878,
    "Rank": 134424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13879,
    "Rank": 134426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13880,
    "Rank": 134490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13881,
    "Rank": 134505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13882,
    "Rank": 134515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13883,
    "Rank": 134550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13884,
    "Rank": 134633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Prafulla Chandra Sen Government Medical College & Hospital, Arambagh,Arambagh, Hooghly, West Bengal, 712601",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13885,
    "Rank": 134649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13886,
    "Rank": 134684,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13887,
    "Rank": 134733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Sundargarh,At-Chitabhanga,Po-Sankara,Dist- Sundargarh,Odisha,Pin-770001, Odisha, 770001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13888,
    "Rank": 134864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13889,
    "Rank": 134878,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13890,
    "Rank": 134881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13891,
    "Rank": 134884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13892,
    "Rank": 135008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13893,
    "Rank": 135028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College and Hospital, Vijayawada,Government Dental College and Hospital, Machavaram Post, Vijayawada - 520004, Andhra Pradesh, 520004",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13894,
    "Rank": 135067,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13895,
    "Rank": 135069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13896,
    "Rank": 135107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13897,
    "Rank": 135192,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE VIZIANAGARAM,Opposite Central Tribal University , Near JNTU\nGajularega, Vizianagaram,\nAndhra Pradesh, 535003, Andhra Pradesh, 535003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13898,
    "Rank": 135295,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13899,
    "Rank": 135300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tamralipto Government Medical College & Hospital, West Bengal,227 Haldia Tamluk Mecheda Road, Tamluk, Purba Medinipur, West Bengal, 721636",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13900,
    "Rank": 135312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13901,
    "Rank": 135328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Thiruvallur,BLOCK 1 MASTER PLAN COMPLEX THIRUVALLUR COLLECTORATE PERUMBAKKAM VILLAGE THIRUVALLUR, Tamil Nadu, 602001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13902,
    "Rank": 135420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13903,
    "Rank": 135433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13904,
    "Rank": 135464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13905,
    "Rank": 135478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13906,
    "Rank": 135550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13907,
    "Rank": 135553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13908,
    "Rank": 135596,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13909,
    "Rank": 135642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13910,
    "Rank": 135649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13911,
    "Rank": 135652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13912,
    "Rank": 135676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13913,
    "Rank": 135680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13914,
    "Rank": 135756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13915,
    "Rank": 135784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13916,
    "Rank": 135806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nagaon Medical college,Dipholu, Mohkhuli Chariali, Laokhua Road, Nagaon, Assam, 782003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13917,
    "Rank": 135810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13918,
    "Rank": 135919,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13919,
    "Rank": 135943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13920,
    "Rank": 136026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13921,
    "Rank": 136121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13922,
    "Rank": 136232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13923,
    "Rank": 136240,
    "Allotted Quota": "All India",
    "Allotted Institute": "DC, RIMS, Imphal,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13924,
    "Rank": 136262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13925,
    "Rank": 136337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13926,
    "Rank": 136373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13927,
    "Rank": 136407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13928,
    "Rank": 136495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13929,
    "Rank": 136537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13930,
    "Rank": 136608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13931,
    "Rank": 136653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13932,
    "Rank": 136681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13933,
    "Rank": 136752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13934,
    "Rank": 136755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13935,
    "Rank": 136796,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13936,
    "Rank": 136807,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13937,
    "Rank": 136837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13938,
    "Rank": 136882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13939,
    "Rank": 136904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13940,
    "Rank": 136923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13941,
    "Rank": 136928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13942,
    "Rank": 136968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13943,
    "Rank": 137010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Silchar,Government Dental College,Silchar,Hailakandi road,Near Silchar Medical College,Ghungoor,Silchar, Assam, 788014",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13944,
    "Rank": 137032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13945,
    "Rank": 137069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13946,
    "Rank": 137190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13947,
    "Rank": 137218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13948,
    "Rank": 137229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13949,
    "Rank": 137235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13950,
    "Rank": 137236,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13951,
    "Rank": 137252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13952,
    "Rank": 137292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13953,
    "Rank": 137302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13954,
    "Rank": 137312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13955,
    "Rank": 137365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13956,
    "Rank": 137372,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13957,
    "Rank": 137384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13958,
    "Rank": 137401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13959,
    "Rank": 137443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13960,
    "Rank": 137472,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13961,
    "Rank": 137534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13962,
    "Rank": 137568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13963,
    "Rank": 137601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13964,
    "Rank": 137702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13965,
    "Rank": 137721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Churachandpur,Hiangtam Lamka, I.B. Road, Churachandpur District, Manipur, 795128",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13966,
    "Rank": 137789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13967,
    "Rank": 137963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13968,
    "Rank": 138038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13969,
    "Rank": 138044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13970,
    "Rank": 138110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13971,
    "Rank": 138178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13972,
    "Rank": 138189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13973,
    "Rank": 138190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13974,
    "Rank": 138192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13975,
    "Rank": 138239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13976,
    "Rank": 138262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13977,
    "Rank": 138335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13978,
    "Rank": 138367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13979,
    "Rank": 138385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13980,
    "Rank": 138436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13981,
    "Rank": 138452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13982,
    "Rank": 138465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13983,
    "Rank": 138477,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13984,
    "Rank": 138504,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13985,
    "Rank": 138525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13986,
    "Rank": 138554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kumuram Bheem Asifabad ,Government Medical College Ankushapur District Kumuram Bheem Asifabad Telangana, Telangana, 504293",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13987,
    "Rank": 138698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13988,
    "Rank": 138717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13989,
    "Rank": 138732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13990,
    "Rank": 138751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13991,
    "Rank": 138780,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DC & RESEARCH INSt., BELLARY,GOVT DENTAL COLLEGE AND RI, VIMS CAMPUS, CANTONMENT BELLARY, Karnataka, 583104",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13992,
    "Rank": 138793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13993,
    "Rank": 138835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13994,
    "Rank": 138893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13995,
    "Rank": 138913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13996,
    "Rank": 139105,
    "Allotted Quota": "All India",
    "Allotted Institute": "GTMC, THIRUVARUR,MASTER PLAN COMPLEX, VILAMAL VILLAGE,\nTHIRUVARUR, Tamil Nadu, 610004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13997,
    "Rank": 139111,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13998,
    "Rank": 139203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 13999,
    "Rank": 139236,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14000,
    "Rank": 139295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14001,
    "Rank": 139484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14002,
    "Rank": 139521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14003,
    "Rank": 139563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Tiruppur,18 DHARAPURAM ROAD TIRUPPUR TAMILNADU 641608, Tamil Nadu, 641608",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14004,
    "Rank": 139631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14005,
    "Rank": 139640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14006,
    "Rank": 139649,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14007,
    "Rank": 139678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14008,
    "Rank": 139729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14009,
    "Rank": 139960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14010,
    "Rank": 140019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14011,
    "Rank": 140026,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14012,
    "Rank": 140101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14013,
    "Rank": 140103,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14014,
    "Rank": 140137,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14015,
    "Rank": 140145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14016,
    "Rank": 140175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14017,
    "Rank": 140185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14018,
    "Rank": 140190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14019,
    "Rank": 140244,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14020,
    "Rank": 140270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14021,
    "Rank": 140298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14022,
    "Rank": 140319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14023,
    "Rank": 140348,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14024,
    "Rank": 140435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14025,
    "Rank": 140474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14026,
    "Rank": 140540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14027,
    "Rank": 140602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14028,
    "Rank": 140612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14029,
    "Rank": 140643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14030,
    "Rank": 140704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14031,
    "Rank": 140912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14032,
    "Rank": 140949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14033,
    "Rank": 140950,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14034,
    "Rank": 140960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14035,
    "Rank": 141053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14036,
    "Rank": 141081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14037,
    "Rank": 141138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14038,
    "Rank": 141178,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14039,
    "Rank": 141186,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14040,
    "Rank": 141252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14041,
    "Rank": 141271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14042,
    "Rank": 141386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14043,
    "Rank": 141404,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14044,
    "Rank": 141408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14045,
    "Rank": 141419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14046,
    "Rank": 141426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14047,
    "Rank": 141491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14048,
    "Rank": 141524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14049,
    "Rank": 141529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14050,
    "Rank": 141555,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, THRISSUR,Govt.Medical College,Thrissur,Medical College P.O, Pin -680596, Thrissur District., Kerala, 680596",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14051,
    "Rank": 141659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14052,
    "Rank": 141693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14053,
    "Rank": 141752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14054,
    "Rank": 141814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14055,
    "Rank": 141819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14056,
    "Rank": 141828,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Jayashankar Bhupalpally,Manzoor Nagar Road, Besides thousand quarters, Jayashankar, Bhupalpally, Telangana, 506169",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14057,
    "Rank": 141885,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14058,
    "Rank": 141914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14059,
    "Rank": 141919,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14060,
    "Rank": 141993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14061,
    "Rank": 142002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14062,
    "Rank": 142043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14063,
    "Rank": 142185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14064,
    "Rank": 142214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14065,
    "Rank": 142272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14066,
    "Rank": 142287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14067,
    "Rank": 142288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvannamalai MC, Thiruvannamalai, TN,District Collectorate, Master Plan Complex, Vengikkal, Thiruvannamalai, Tamil Nadu, 606604",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14068,
    "Rank": 142301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14069,
    "Rank": 142303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14070,
    "Rank": 142330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14071,
    "Rank": 142341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14072,
    "Rank": 142362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14073,
    "Rank": 142378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14074,
    "Rank": 142485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14075,
    "Rank": 142500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14076,
    "Rank": 142535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14077,
    "Rank": 142600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14078,
    "Rank": 142608,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14079,
    "Rank": 142631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14080,
    "Rank": 142637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14081,
    "Rank": 142659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14082,
    "Rank": 142665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14083,
    "Rank": 142669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14084,
    "Rank": 142678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14085,
    "Rank": 142680,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14086,
    "Rank": 142689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14087,
    "Rank": 142722,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14088,
    "Rank": 142752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14089,
    "Rank": 142756,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14090,
    "Rank": 142788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14091,
    "Rank": 142854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14092,
    "Rank": 142866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14093,
    "Rank": 142905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14094,
    "Rank": 142921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14095,
    "Rank": 142976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14096,
    "Rank": 143071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14097,
    "Rank": 143078,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14098,
    "Rank": 143090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14099,
    "Rank": 143174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14100,
    "Rank": 143186,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14101,
    "Rank": 143204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14102,
    "Rank": 143214,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14103,
    "Rank": 143239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14104,
    "Rank": 143240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14105,
    "Rank": 143253,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14106,
    "Rank": 143278,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14107,
    "Rank": 143282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14108,
    "Rank": 143356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14109,
    "Rank": 143365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14110,
    "Rank": 143418,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14111,
    "Rank": 143441,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14112,
    "Rank": 143444,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14113,
    "Rank": 143466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14114,
    "Rank": 143473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14115,
    "Rank": 143492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14116,
    "Rank": 143505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14117,
    "Rank": 143549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14118,
    "Rank": 143585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14119,
    "Rank": 143615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14120,
    "Rank": 143631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14121,
    "Rank": 143660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14122,
    "Rank": 143670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14123,
    "Rank": 143681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14124,
    "Rank": 143684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14125,
    "Rank": 143699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14126,
    "Rank": 143712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14127,
    "Rank": 143719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14128,
    "Rank": 143736,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14129,
    "Rank": 143754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14130,
    "Rank": 143783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14131,
    "Rank": 143918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14132,
    "Rank": 143973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14133,
    "Rank": 143993,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14134,
    "Rank": 144089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14135,
    "Rank": 144105,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14136,
    "Rank": 144129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14137,
    "Rank": 144150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14138,
    "Rank": 144226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14139,
    "Rank": 144329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14140,
    "Rank": 144402,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14141,
    "Rank": 144469,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14142,
    "Rank": 144543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14143,
    "Rank": 144573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14144,
    "Rank": 144579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14145,
    "Rank": 144600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Krishnagiri,Block-1, Polupalli, Krishnagiri, Tamil Nadu, 635115",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14146,
    "Rank": 144678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14147,
    "Rank": 144691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14148,
    "Rank": 144743,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14149,
    "Rank": 144775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14150,
    "Rank": 144776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14151,
    "Rank": 144840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14152,
    "Rank": 144847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14153,
    "Rank": 144865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14154,
    "Rank": 144880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14155,
    "Rank": 144898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14156,
    "Rank": 144908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical College, Namakkal,353 MASTER PLAN COMPLEX SILUVAMPATTY\nNAMAKKAL, Tamil Nadu, 637003",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14157,
    "Rank": 145036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14158,
    "Rank": 145112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14159,
    "Rank": 145207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Pudukkottai Medical College Hopt., Pudukkott,MULLUR PUDUKKOTTAI PO PUDUKKOTTAI, Tamil Nadu, 622004",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14160,
    "Rank": 145251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14161,
    "Rank": 145319,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14162,
    "Rank": 145392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14163,
    "Rank": 145445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14164,
    "Rank": 145571,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14165,
    "Rank": 145587,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14166,
    "Rank": 145601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14167,
    "Rank": 145633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14168,
    "Rank": 145684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14169,
    "Rank": 145772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14170,
    "Rank": 145779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14171,
    "Rank": 145782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14172,
    "Rank": 145810,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14173,
    "Rank": 145880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14174,
    "Rank": 145903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14175,
    "Rank": 145951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14176,
    "Rank": 146022,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14177,
    "Rank": 146044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14178,
    "Rank": 146146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14179,
    "Rank": 146148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14180,
    "Rank": 146227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14181,
    "Rank": 146246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14182,
    "Rank": 146252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14183,
    "Rank": 146289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14184,
    "Rank": 146357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14185,
    "Rank": 146511,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14186,
    "Rank": 146554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14187,
    "Rank": 146567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14188,
    "Rank": 146575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14189,
    "Rank": 146580,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14190,
    "Rank": 146593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14191,
    "Rank": 146599,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14192,
    "Rank": 146621,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14193,
    "Rank": 146666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14194,
    "Rank": 146684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14195,
    "Rank": 146710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14196,
    "Rank": 146716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14197,
    "Rank": 146718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14198,
    "Rank": 146737,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14199,
    "Rank": 146757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14200,
    "Rank": 146769,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14201,
    "Rank": 146789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14202,
    "Rank": 146790,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14203,
    "Rank": 146838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14204,
    "Rank": 146930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14205,
    "Rank": 147122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14206,
    "Rank": 147169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14207,
    "Rank": 147193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLL.& HOSP., JAMNAGAR,Opp. City Police Line,\nNavagam Ghed, Jamnagar, Gujarat, 361008",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14208,
    "Rank": 147196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14209,
    "Rank": 147237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14210,
    "Rank": 147358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14211,
    "Rank": 147384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14212,
    "Rank": 147425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14213,
    "Rank": 147462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14214,
    "Rank": 147484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14215,
    "Rank": 147522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14216,
    "Rank": 147617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14217,
    "Rank": 147619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14218,
    "Rank": 147628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14219,
    "Rank": 147635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14220,
    "Rank": 147747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14221,
    "Rank": 147755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14222,
    "Rank": 147823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14223,
    "Rank": 147865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14224,
    "Rank": 148040,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14225,
    "Rank": 148250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14226,
    "Rank": 148360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14227,
    "Rank": 148476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14228,
    "Rank": 148519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14229,
    "Rank": 148557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14230,
    "Rank": 148628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE TUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14231,
    "Rank": 148688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14232,
    "Rank": 148698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14233,
    "Rank": 148711,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14234,
    "Rank": 148741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14235,
    "Rank": 148785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14236,
    "Rank": 148918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14237,
    "Rank": 148975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14238,
    "Rank": 149003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14239,
    "Rank": 149010,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14240,
    "Rank": 149030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14241,
    "Rank": 149035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14242,
    "Rank": 149082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14243,
    "Rank": 149133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14244,
    "Rank": 149140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14245,
    "Rank": 149143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14246,
    "Rank": 149158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14247,
    "Rank": 149214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14248,
    "Rank": 149221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14249,
    "Rank": 149233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14250,
    "Rank": 149257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14251,
    "Rank": 149265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14252,
    "Rank": 149295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Govt. Dental College, Jammu,Indira Gandhi Govt. Dental College, Rehari Chungi, Jammu, Jammu And Kashmir, 180005",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14253,
    "Rank": 149317,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14254,
    "Rank": 149353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14255,
    "Rank": 149457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, SHIMLA,HP GOVERNMENT DENTAL COLLEGE AND\nHOSPITAL SHIMLA, Himachal Pradesh, 171001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14256,
    "Rank": 149514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14257,
    "Rank": 149540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14258,
    "Rank": 149558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14259,
    "Rank": 149594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14260,
    "Rank": 149639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14261,
    "Rank": 149670,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14262,
    "Rank": 149687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14263,
    "Rank": 149743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14264,
    "Rank": 149785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14265,
    "Rank": 149813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14266,
    "Rank": 149832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14267,
    "Rank": 149858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14268,
    "Rank": 149881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14269,
    "Rank": 149884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14270,
    "Rank": 149954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14271,
    "Rank": 150033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14272,
    "Rank": 150102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14273,
    "Rank": 150299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14274,
    "Rank": 150406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14275,
    "Rank": 150453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14276,
    "Rank": 150546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14277,
    "Rank": 150595,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14278,
    "Rank": 150623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14279,
    "Rank": 150655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14280,
    "Rank": 150801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14281,
    "Rank": 150829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14282,
    "Rank": 150864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14283,
    "Rank": 150967,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14284,
    "Rank": 150984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14285,
    "Rank": 150986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14286,
    "Rank": 151009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14287,
    "Rank": 151041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14288,
    "Rank": 151134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14289,
    "Rank": 151213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14290,
    "Rank": 151219,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14291,
    "Rank": 151264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14292,
    "Rank": 151279,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14293,
    "Rank": 151292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14294,
    "Rank": 151385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14295,
    "Rank": 151487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14296,
    "Rank": 151492,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14297,
    "Rank": 151498,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14298,
    "Rank": 151500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14299,
    "Rank": 151557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14300,
    "Rank": 151618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14301,
    "Rank": 151638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14302,
    "Rank": 151651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14303,
    "Rank": 151660,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14304,
    "Rank": 151666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14305,
    "Rank": 151672,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14306,
    "Rank": 151679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14307,
    "Rank": 151695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14308,
    "Rank": 151713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14309,
    "Rank": 151767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14310,
    "Rank": 151796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14311,
    "Rank": 151855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14312,
    "Rank": 151860,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14313,
    "Rank": 151999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14314,
    "Rank": 152068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14315,
    "Rank": 152230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14316,
    "Rank": 152274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14317,
    "Rank": 152359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14318,
    "Rank": 152405,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. COLLEGE OF DENTISTRY, INDORE,1 Sardar Patel Marg\nIndore, Madhya Pradesh, 452001",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14319,
    "Rank": 152411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14320,
    "Rank": 152433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14321,
    "Rank": 152481,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14322,
    "Rank": 152509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14323,
    "Rank": 152602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14324,
    "Rank": 152692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14325,
    "Rank": 152703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14326,
    "Rank": 152741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14327,
    "Rank": 152787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14328,
    "Rank": 152798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14329,
    "Rank": 152821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14330,
    "Rank": 152872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14331,
    "Rank": 152904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14332,
    "Rank": 152910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14333,
    "Rank": 152941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14334,
    "Rank": 152962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14335,
    "Rank": 152980,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14336,
    "Rank": 152991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14337,
    "Rank": 153008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14338,
    "Rank": 153016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14339,
    "Rank": 153056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14340,
    "Rank": 153142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14341,
    "Rank": 153230,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14342,
    "Rank": 153255,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14343,
    "Rank": 153300,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14344,
    "Rank": 153374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14345,
    "Rank": 153410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14346,
    "Rank": 153416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14347,
    "Rank": 153440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14348,
    "Rank": 153474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14349,
    "Rank": 153499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14350,
    "Rank": 153513,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14351,
    "Rank": 153560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14352,
    "Rank": 153561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14353,
    "Rank": 153613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14354,
    "Rank": 153625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14355,
    "Rank": 153673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14356,
    "Rank": 153697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14357,
    "Rank": 153741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14358,
    "Rank": 153764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14359,
    "Rank": 153819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14360,
    "Rank": 153971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14361,
    "Rank": 153999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14362,
    "Rank": 154088,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14363,
    "Rank": 154099,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14364,
    "Rank": 154136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14365,
    "Rank": 154231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14366,
    "Rank": 154283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14367,
    "Rank": 154394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14368,
    "Rank": 154415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14369,
    "Rank": 154580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14370,
    "Rank": 154696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14371,
    "Rank": 154710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14372,
    "Rank": 154752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14373,
    "Rank": 154797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14374,
    "Rank": 154816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14375,
    "Rank": 154857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14376,
    "Rank": 154948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14377,
    "Rank": 154970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14378,
    "Rank": 154975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14379,
    "Rank": 154986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14380,
    "Rank": 154991,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14381,
    "Rank": 155038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14382,
    "Rank": 155107,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14383,
    "Rank": 155189,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14384,
    "Rank": 155191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14385,
    "Rank": 155206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14386,
    "Rank": 155259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14387,
    "Rank": 155307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14388,
    "Rank": 155350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14389,
    "Rank": 155379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14390,
    "Rank": 155550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14391,
    "Rank": 155555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14392,
    "Rank": 155599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14393,
    "Rank": 155600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14394,
    "Rank": 155621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14395,
    "Rank": 155724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14396,
    "Rank": 155728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14397,
    "Rank": 155749,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14398,
    "Rank": 155761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14399,
    "Rank": 155832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14400,
    "Rank": 155902,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14401,
    "Rank": 155994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14402,
    "Rank": 156031,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14403,
    "Rank": 156119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14404,
    "Rank": 156122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14405,
    "Rank": 156127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14406,
    "Rank": 156143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14407,
    "Rank": 156147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14408,
    "Rank": 156227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14409,
    "Rank": 156239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14410,
    "Rank": 156249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14411,
    "Rank": 156299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14412,
    "Rank": 156313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14413,
    "Rank": 156383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14414,
    "Rank": 156407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14415,
    "Rank": 156454,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA DENTAL COLLEGE & HOSPITAL, PATNA,BANKIPUR,ASHOK RAJPATH,PATNA, Bihar, 800004",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14416,
    "Rank": 156497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE TUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14417,
    "Rank": 156504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14418,
    "Rank": 156506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14419,
    "Rank": 156575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14420,
    "Rank": 156605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14421,
    "Rank": 156615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14422,
    "Rank": 156651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14423,
    "Rank": 156664,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14424,
    "Rank": 156690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14425,
    "Rank": 156814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14426,
    "Rank": 156843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14427,
    "Rank": 156941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14428,
    "Rank": 156999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14429,
    "Rank": 157013,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14430,
    "Rank": 157024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14431,
    "Rank": 157072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14432,
    "Rank": 157116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14433,
    "Rank": 157298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14434,
    "Rank": 157307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14435,
    "Rank": 157363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14436,
    "Rank": 157502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14437,
    "Rank": 157521,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14438,
    "Rank": 157596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14439,
    "Rank": 157706,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14440,
    "Rank": 157759,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14441,
    "Rank": 157801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14442,
    "Rank": 157812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14443,
    "Rank": 157877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE TUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14444,
    "Rank": 157892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14445,
    "Rank": 157908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14446,
    "Rank": 157926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14447,
    "Rank": 157960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14448,
    "Rank": 157979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14449,
    "Rank": 158023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14450,
    "Rank": 158049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14451,
    "Rank": 158050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14452,
    "Rank": 158053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14453,
    "Rank": 158147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14454,
    "Rank": 158171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14455,
    "Rank": 158176,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14456,
    "Rank": 158235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14457,
    "Rank": 158281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14458,
    "Rank": 158380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14459,
    "Rank": 158421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14460,
    "Rank": 158451,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14461,
    "Rank": 158452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14462,
    "Rank": 158482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14463,
    "Rank": 158546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14464,
    "Rank": 158571,
    "Allotted Quota": "B.Sc Nursing Delhi NCR CW  Quota",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 104 )"
  },
  {
    "SNo": 14465,
    "Rank": 158704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14466,
    "Rank": 158723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14467,
    "Rank": 158750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14468,
    "Rank": 158760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14469,
    "Rank": 158767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14470,
    "Rank": 158867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14471,
    "Rank": 158893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14472,
    "Rank": 158906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14473,
    "Rank": 158909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14474,
    "Rank": 158934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14475,
    "Rank": 158958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14476,
    "Rank": 158960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14477,
    "Rank": 158969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14478,
    "Rank": 159130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14479,
    "Rank": 159150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14480,
    "Rank": 159210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14481,
    "Rank": 159375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14482,
    "Rank": 159417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14483,
    "Rank": 159445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14484,
    "Rank": 159452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14485,
    "Rank": 159482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14486,
    "Rank": 159574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14487,
    "Rank": 159591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14488,
    "Rank": 159726,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOZIKODE,MEDICAL COLLEGE CAMPUS, MEDICAL COLLEGE P.O., KOZHIKODE, Kerala, 673008",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14489,
    "Rank": 159810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14490,
    "Rank": 159816,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, New Delhi,AIIMS ANSARI NAGAR EAST AUROBINDO MARG NEW DELHI 110029, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14491,
    "Rank": 159833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14492,
    "Rank": 159837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14493,
    "Rank": 159886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14494,
    "Rank": 159913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14495,
    "Rank": 159938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14496,
    "Rank": 159969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14497,
    "Rank": 159971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14498,
    "Rank": 160015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14499,
    "Rank": 160133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14500,
    "Rank": 160164,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14501,
    "Rank": 160318,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14502,
    "Rank": 160332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14503,
    "Rank": 160363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14504,
    "Rank": 160488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14505,
    "Rank": 160498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14506,
    "Rank": 160527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14507,
    "Rank": 160529,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14508,
    "Rank": 160531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14509,
    "Rank": 160552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14510,
    "Rank": 160559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14511,
    "Rank": 160567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14512,
    "Rank": 160569,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14513,
    "Rank": 160647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14514,
    "Rank": 160650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14515,
    "Rank": 160665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14516,
    "Rank": 160673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14517,
    "Rank": 160680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,NAGPUR,Government Dental College And Hospital GMC Campus Nagpur, Maharashtra, 440003",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14518,
    "Rank": 160685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14519,
    "Rank": 160747,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14520,
    "Rank": 160818,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14521,
    "Rank": 160826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14522,
    "Rank": 160843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14523,
    "Rank": 160859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14524,
    "Rank": 160875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14525,
    "Rank": 160896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14526,
    "Rank": 160990,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14527,
    "Rank": 161069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14528,
    "Rank": 161210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14529,
    "Rank": 161213,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14530,
    "Rank": 161216,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14531,
    "Rank": 161245,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14532,
    "Rank": 161267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14533,
    "Rank": 161295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14534,
    "Rank": 161461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14535,
    "Rank": 161586,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14536,
    "Rank": 161598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14537,
    "Rank": 161615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14538,
    "Rank": 161640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14539,
    "Rank": 161670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14540,
    "Rank": 161713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14541,
    "Rank": 161774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14542,
    "Rank": 161783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14543,
    "Rank": 161794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14544,
    "Rank": 161816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14545,
    "Rank": 161817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14546,
    "Rank": 161820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14547,
    "Rank": 161842,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14548,
    "Rank": 161902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14549,
    "Rank": 161913,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14550,
    "Rank": 161916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14551,
    "Rank": 162012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14552,
    "Rank": 162029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14553,
    "Rank": 162047,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14554,
    "Rank": 162071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14555,
    "Rank": 162107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14556,
    "Rank": 162135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14557,
    "Rank": 162151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14558,
    "Rank": 162162,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14559,
    "Rank": 162252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14560,
    "Rank": 162298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14561,
    "Rank": 162306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14562,
    "Rank": 162314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14563,
    "Rank": 162317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14564,
    "Rank": 162342,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14565,
    "Rank": 162447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14566,
    "Rank": 162510,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14567,
    "Rank": 162522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14568,
    "Rank": 162530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14569,
    "Rank": 162542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14570,
    "Rank": 162700,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14571,
    "Rank": 162738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14572,
    "Rank": 162768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14573,
    "Rank": 162774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14574,
    "Rank": 162804,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14575,
    "Rank": 162843,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14576,
    "Rank": 162851,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Nagpur,PLOT NO 2 SECTOR 20 MIHAN NAGPUR, Maharashtra, 441108",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14577,
    "Rank": 162878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14578,
    "Rank": 162920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14579,
    "Rank": 162936,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14580,
    "Rank": 162947,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14581,
    "Rank": 162964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14582,
    "Rank": 162973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14583,
    "Rank": 163004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14584,
    "Rank": 163016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14585,
    "Rank": 163029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14586,
    "Rank": 163032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14587,
    "Rank": 163064,
    "Allotted Quota": "All India",
    "Allotted Institute": "GDC,PT.BDS UNIV.OF HEALTH SCI,ROHTAK,Post Graduate Institute of Dental Sciences, Rohtak, Haryana, 124001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14588,
    "Rank": 163080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14589,
    "Rank": 163101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14590,
    "Rank": 163104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14591,
    "Rank": 163142,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14592,
    "Rank": 163153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14593,
    "Rank": 163208,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14594,
    "Rank": 163212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14595,
    "Rank": 163226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14596,
    "Rank": 163275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14597,
    "Rank": 163324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14598,
    "Rank": 163326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14599,
    "Rank": 163443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14600,
    "Rank": 163467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14601,
    "Rank": 163538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14602,
    "Rank": 163619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14603,
    "Rank": 163634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14604,
    "Rank": 163729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14605,
    "Rank": 163798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14606,
    "Rank": 163997,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14607,
    "Rank": 164113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14608,
    "Rank": 164117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14609,
    "Rank": 164258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14610,
    "Rank": 164382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14611,
    "Rank": 164391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14612,
    "Rank": 164429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14613,
    "Rank": 164479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14614,
    "Rank": 164562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14615,
    "Rank": 164568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14616,
    "Rank": 164595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14617,
    "Rank": 164675,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14618,
    "Rank": 164681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14619,
    "Rank": 164703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14620,
    "Rank": 164753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14621,
    "Rank": 164826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14622,
    "Rank": 164862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14623,
    "Rank": 164897,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14624,
    "Rank": 164910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14625,
    "Rank": 164966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14626,
    "Rank": 164973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14627,
    "Rank": 164995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14628,
    "Rank": 165143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14629,
    "Rank": 165163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14630,
    "Rank": 165194,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14631,
    "Rank": 165241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14632,
    "Rank": 165258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14633,
    "Rank": 165270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14634,
    "Rank": 165304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14635,
    "Rank": 165337,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14636,
    "Rank": 165389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14637,
    "Rank": 165433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14638,
    "Rank": 165448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14639,
    "Rank": 165477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14640,
    "Rank": 165559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14641,
    "Rank": 165615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14642,
    "Rank": 165680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14643,
    "Rank": 165690,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14644,
    "Rank": 165749,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14645,
    "Rank": 165759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14646,
    "Rank": 165802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14647,
    "Rank": 165817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14648,
    "Rank": 165835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14649,
    "Rank": 165846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14650,
    "Rank": 165905,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14651,
    "Rank": 166019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14652,
    "Rank": 166042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14653,
    "Rank": 166100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14654,
    "Rank": 166161,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14655,
    "Rank": 166189,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14656,
    "Rank": 166274,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14657,
    "Rank": 166309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14658,
    "Rank": 166314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14659,
    "Rank": 166370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14660,
    "Rank": 166383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Alappuzha,Government Dental College\nT.D Medical College (PO) Alappuzha,Kerala, Kerala, 688005",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14661,
    "Rank": 166391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14662,
    "Rank": 166400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14663,
    "Rank": 166428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14664,
    "Rank": 166449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14665,
    "Rank": 166467,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14666,
    "Rank": 166493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14667,
    "Rank": 166501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14668,
    "Rank": 166645,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14669,
    "Rank": 166650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14670,
    "Rank": 166665,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14671,
    "Rank": 166682,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14672,
    "Rank": 166718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14673,
    "Rank": 166736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14674,
    "Rank": 166779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14675,
    "Rank": 166811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14676,
    "Rank": 166841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14677,
    "Rank": 166891,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14678,
    "Rank": 166902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14679,
    "Rank": 166920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14680,
    "Rank": 166956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14681,
    "Rank": 166990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14682,
    "Rank": 167124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14683,
    "Rank": 167160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14684,
    "Rank": 167217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14685,
    "Rank": 167238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14686,
    "Rank": 167262,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14687,
    "Rank": 167300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14688,
    "Rank": 167346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14689,
    "Rank": 167362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14690,
    "Rank": 167379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14691,
    "Rank": 167403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14692,
    "Rank": 167435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14693,
    "Rank": 167474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14694,
    "Rank": 167517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14695,
    "Rank": 167550,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14696,
    "Rank": 167630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14697,
    "Rank": 167693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14698,
    "Rank": 167837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14699,
    "Rank": 167868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14700,
    "Rank": 167891,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14701,
    "Rank": 167974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14702,
    "Rank": 168088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14703,
    "Rank": 168145,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 19 )"
  },
  {
    "SNo": 14704,
    "Rank": 168198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14705,
    "Rank": 168213,
    "Allotted Quota": "Muslim ST Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14706,
    "Rank": 168327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14707,
    "Rank": 168332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14708,
    "Rank": 168335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14709,
    "Rank": 168414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14710,
    "Rank": 168435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14711,
    "Rank": 168491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14712,
    "Rank": 168493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14713,
    "Rank": 168496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14714,
    "Rank": 168502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14715,
    "Rank": 168535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14716,
    "Rank": 168604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14717,
    "Rank": 168649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14718,
    "Rank": 168658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14719,
    "Rank": 168669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14720,
    "Rank": 168809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14721,
    "Rank": 168812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14722,
    "Rank": 168868,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14723,
    "Rank": 168898,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14724,
    "Rank": 168904,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14725,
    "Rank": 168911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14726,
    "Rank": 168917,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14727,
    "Rank": 168942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14728,
    "Rank": 169129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14729,
    "Rank": 169142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14730,
    "Rank": 169144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14731,
    "Rank": 169157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14732,
    "Rank": 169162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14733,
    "Rank": 169213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14734,
    "Rank": 169304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14735,
    "Rank": 169405,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14736,
    "Rank": 169438,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14737,
    "Rank": 169478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14738,
    "Rank": 169581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14739,
    "Rank": 169607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14740,
    "Rank": 169633,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14741,
    "Rank": 169712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14742,
    "Rank": 169730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14743,
    "Rank": 169772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14744,
    "Rank": 169788,
    "Allotted Quota": "All India",
    "Allotted Institute": "TAMILNADU GOVT D.C. & HOSP, CHENNAI,No. 1, TNPSC SALAI, PARK TOWN, CHENNAI   600003, Tamil Nadu, 600003",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14745,
    "Rank": 169809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14746,
    "Rank": 169844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14747,
    "Rank": 169882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14748,
    "Rank": 169960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14749,
    "Rank": 169994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14750,
    "Rank": 170029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14751,
    "Rank": 170050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14752,
    "Rank": 170066,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14753,
    "Rank": 170113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14754,
    "Rank": 170250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14755,
    "Rank": 170266,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14756,
    "Rank": 170274,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14757,
    "Rank": 170275,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14758,
    "Rank": 170401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14759,
    "Rank": 170402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14760,
    "Rank": 170420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14761,
    "Rank": 170492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14762,
    "Rank": 170669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14763,
    "Rank": 170670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14764,
    "Rank": 170712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14765,
    "Rank": 170776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14766,
    "Rank": 170828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14767,
    "Rank": 170858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14768,
    "Rank": 170893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14769,
    "Rank": 170983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14770,
    "Rank": 171054,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14771,
    "Rank": 171111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14772,
    "Rank": 171148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14773,
    "Rank": 171169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14774,
    "Rank": 171188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14775,
    "Rank": 171217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14776,
    "Rank": 171222,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14777,
    "Rank": 171257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14778,
    "Rank": 171337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14779,
    "Rank": 171368,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14780,
    "Rank": 171495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14781,
    "Rank": 171587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14782,
    "Rank": 171599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14783,
    "Rank": 171631,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14784,
    "Rank": 171648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14785,
    "Rank": 171754,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14786,
    "Rank": 171769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14787,
    "Rank": 171806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14788,
    "Rank": 171821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14789,
    "Rank": 171862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14790,
    "Rank": 171936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14791,
    "Rank": 171977,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,AURANGABAD,Government Medical College and Hospital Campus Dhanwantari Nagar Ghati Chha. Sambhajinagar Aurangaba, Maharashtra, 431001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14792,
    "Rank": 172052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14793,
    "Rank": 172119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14794,
    "Rank": 172143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14795,
    "Rank": 172148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14796,
    "Rank": 172151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14797,
    "Rank": 172215,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14798,
    "Rank": 172228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14799,
    "Rank": 172244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14800,
    "Rank": 172270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14801,
    "Rank": 172279,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14802,
    "Rank": 172365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14803,
    "Rank": 172401,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14804,
    "Rank": 172413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14805,
    "Rank": 172435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14806,
    "Rank": 172458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14807,
    "Rank": 172540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14808,
    "Rank": 172545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14809,
    "Rank": 172568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14810,
    "Rank": 172606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14811,
    "Rank": 172659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14812,
    "Rank": 172664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14813,
    "Rank": 172702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14814,
    "Rank": 172713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14815,
    "Rank": 172754,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14816,
    "Rank": 172805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14817,
    "Rank": 172807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14818,
    "Rank": 172828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14819,
    "Rank": 172905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14820,
    "Rank": 172915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14821,
    "Rank": 173003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14822,
    "Rank": 173014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14823,
    "Rank": 173032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14824,
    "Rank": 173044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14825,
    "Rank": 173212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14826,
    "Rank": 173319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14827,
    "Rank": 173365,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14828,
    "Rank": 173412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14829,
    "Rank": 173424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14830,
    "Rank": 173465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14831,
    "Rank": 173585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14832,
    "Rank": 173596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14833,
    "Rank": 173615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14834,
    "Rank": 173667,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14835,
    "Rank": 173670,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14836,
    "Rank": 173733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14837,
    "Rank": 173746,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14838,
    "Rank": 173763,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14839,
    "Rank": 173814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14840,
    "Rank": 173827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14841,
    "Rank": 173838,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14842,
    "Rank": 173853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14843,
    "Rank": 173879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14844,
    "Rank": 173886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14845,
    "Rank": 173898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14846,
    "Rank": 173948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dental Institue, RIMS, Ranchi,Dental Institute, Rajendra Institute of Medical Sciences, Bariatu, Ranchi, Jharkhand, 843009",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14847,
    "Rank": 173977,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14848,
    "Rank": 174005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14849,
    "Rank": 174065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14850,
    "Rank": 174129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14851,
    "Rank": 174138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14852,
    "Rank": 174154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14853,
    "Rank": 174166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14854,
    "Rank": 174273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14855,
    "Rank": 174305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14856,
    "Rank": 174329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14857,
    "Rank": 174351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14858,
    "Rank": 174426,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14859,
    "Rank": 174439,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14860,
    "Rank": 174449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14861,
    "Rank": 174484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14862,
    "Rank": 174496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14863,
    "Rank": 174498,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14864,
    "Rank": 174606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14865,
    "Rank": 174658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14866,
    "Rank": 174704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14867,
    "Rank": 174875,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14868,
    "Rank": 174924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14869,
    "Rank": 174939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14870,
    "Rank": 174957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14871,
    "Rank": 174958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14872,
    "Rank": 175143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14873,
    "Rank": 175195,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14874,
    "Rank": 175223,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14875,
    "Rank": 175239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14876,
    "Rank": 175281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14877,
    "Rank": 175342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14878,
    "Rank": 175408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14879,
    "Rank": 175434,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14880,
    "Rank": 175620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14881,
    "Rank": 175758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14882,
    "Rank": 175786,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14883,
    "Rank": 175790,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14884,
    "Rank": 175794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14885,
    "Rank": 175845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14886,
    "Rank": 175872,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14887,
    "Rank": 175924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14888,
    "Rank": 175933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14889,
    "Rank": 175934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14890,
    "Rank": 175965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14891,
    "Rank": 176031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14892,
    "Rank": 176033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14893,
    "Rank": 176068,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14894,
    "Rank": 176069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14895,
    "Rank": 176083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14896,
    "Rank": 176128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14897,
    "Rank": 176147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14898,
    "Rank": 176193,
    "Allotted Quota": "B.Sc Nursing Delhi NCR CW  Quota",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 105 )"
  },
  {
    "SNo": 14899,
    "Rank": 176232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14900,
    "Rank": 176411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14901,
    "Rank": 176413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14902,
    "Rank": 176430,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.D.C. & HOSPITAL, PUDUCHERRY,GOVT. OF PUDUCHERRY INSTITUTION, GORIMEDU, PONDICHERRY, Puducherry, 605006",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14903,
    "Rank": 176525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14904,
    "Rank": 176559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14905,
    "Rank": 176566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14906,
    "Rank": 176570,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14907,
    "Rank": 176592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14908,
    "Rank": 176652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14909,
    "Rank": 176784,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14910,
    "Rank": 176848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14911,
    "Rank": 176853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14912,
    "Rank": 176859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14913,
    "Rank": 177085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14914,
    "Rank": 177092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14915,
    "Rank": 177149,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14916,
    "Rank": 177218,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14917,
    "Rank": 177291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14918,
    "Rank": 177332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14919,
    "Rank": 177337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14920,
    "Rank": 177410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14921,
    "Rank": 177430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14922,
    "Rank": 177444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14923,
    "Rank": 177512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14924,
    "Rank": 177513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14925,
    "Rank": 177562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14926,
    "Rank": 177567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14927,
    "Rank": 177610,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14928,
    "Rank": 177676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14929,
    "Rank": 177694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14930,
    "Rank": 177717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14931,
    "Rank": 177727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14932,
    "Rank": 177733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14933,
    "Rank": 177750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14934,
    "Rank": 177814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14935,
    "Rank": 177891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14936,
    "Rank": 177934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14937,
    "Rank": 178081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14938,
    "Rank": 178124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14939,
    "Rank": 178165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14940,
    "Rank": 178229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14941,
    "Rank": 178270,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14942,
    "Rank": 178325,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14943,
    "Rank": 178424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14944,
    "Rank": 178477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14945,
    "Rank": 178506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14946,
    "Rank": 178538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14947,
    "Rank": 178539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14948,
    "Rank": 178584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14949,
    "Rank": 178605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14950,
    "Rank": 178684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14951,
    "Rank": 178744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Dental College RIMS Kadapa,PRINCIPAL GOVT DENTAL COLLEGE, RIMS, PUTLAMPALLI KADAPA  State ANDHRA PRADESH Pin 516004, Andhra Pradesh, 516004",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14952,
    "Rank": 178760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14953,
    "Rank": 178805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14954,
    "Rank": 178815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14955,
    "Rank": 178819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14956,
    "Rank": 178892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14957,
    "Rank": 178903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14958,
    "Rank": 178906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14959,
    "Rank": 178949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14960,
    "Rank": 179038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14961,
    "Rank": 179049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14962,
    "Rank": 179083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14963,
    "Rank": 179102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14964,
    "Rank": 179106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14965,
    "Rank": 179155,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14966,
    "Rank": 179156,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14967,
    "Rank": 179181,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14968,
    "Rank": 179188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14969,
    "Rank": 179301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14970,
    "Rank": 179318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14971,
    "Rank": 179349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14972,
    "Rank": 179459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14973,
    "Rank": 179484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14974,
    "Rank": 179666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14975,
    "Rank": 179806,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14976,
    "Rank": 179826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14977,
    "Rank": 179830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14978,
    "Rank": 179856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14979,
    "Rank": 179859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14980,
    "Rank": 179908,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14981,
    "Rank": 179925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14982,
    "Rank": 179944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14983,
    "Rank": 180087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14984,
    "Rank": 180231,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLL(DENTAL),CUTTACK,S.C.B. Dental College and Hospital, Mangalabag, Cuttack, Pin-753 007, Odisha, 753007",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14985,
    "Rank": 180270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14986,
    "Rank": 180424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14987,
    "Rank": 180441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14988,
    "Rank": 180490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14989,
    "Rank": 180524,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14990,
    "Rank": 180539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14991,
    "Rank": 180632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14992,
    "Rank": 180706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14993,
    "Rank": 180714,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL DENTAL COLLEGE, GUWAHATI,Regional Dental College, Bhangagarh, PO Indrapur, Dist.-Kamrup Metro, Assam, 781032",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14994,
    "Rank": 180822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14995,
    "Rank": 180841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14996,
    "Rank": 180888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14997,
    "Rank": 180896,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14998,
    "Rank": 180959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 14999,
    "Rank": 181053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15000,
    "Rank": 181063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15001,
    "Rank": 181209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15002,
    "Rank": 181220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15003,
    "Rank": 181231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15004,
    "Rank": 181284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15005,
    "Rank": 181372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15006,
    "Rank": 181391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15007,
    "Rank": 181399,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15008,
    "Rank": 181403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15009,
    "Rank": 181406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15010,
    "Rank": 181443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15011,
    "Rank": 181599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15012,
    "Rank": 181621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15013,
    "Rank": 181635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15014,
    "Rank": 181703,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15015,
    "Rank": 181743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15016,
    "Rank": 181867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15017,
    "Rank": 181933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15018,
    "Rank": 181965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15019,
    "Rank": 181969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15020,
    "Rank": 181994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15021,
    "Rank": 182010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15022,
    "Rank": 182046,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15023,
    "Rank": 182093,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15024,
    "Rank": 182127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15025,
    "Rank": 182257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15026,
    "Rank": 182300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15027,
    "Rank": 182331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15028,
    "Rank": 182349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15029,
    "Rank": 182404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15030,
    "Rank": 182407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15031,
    "Rank": 182419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15032,
    "Rank": 182424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15033,
    "Rank": 182428,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15034,
    "Rank": 182429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15035,
    "Rank": 182511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15036,
    "Rank": 182513,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15037,
    "Rank": 182515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15038,
    "Rank": 182755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15039,
    "Rank": 182885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15040,
    "Rank": 182934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15041,
    "Rank": 182952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15042,
    "Rank": 183041,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15043,
    "Rank": 183044,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15044,
    "Rank": 183065,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15045,
    "Rank": 183125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15046,
    "Rank": 183158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15047,
    "Rank": 183199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15048,
    "Rank": 183220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15049,
    "Rank": 183223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15050,
    "Rank": 183232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15051,
    "Rank": 183273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15052,
    "Rank": 183312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15053,
    "Rank": 183321,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15054,
    "Rank": 183357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15055,
    "Rank": 183382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15056,
    "Rank": 183386,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15057,
    "Rank": 183388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15058,
    "Rank": 183530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15059,
    "Rank": 183569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15060,
    "Rank": 183586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15061,
    "Rank": 183633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15062,
    "Rank": 183657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15063,
    "Rank": 183677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15064,
    "Rank": 183788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15065,
    "Rank": 183830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15066,
    "Rank": 183869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15067,
    "Rank": 183886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15068,
    "Rank": 183891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15069,
    "Rank": 183903,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15070,
    "Rank": 183920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15071,
    "Rank": 183948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15072,
    "Rank": 184093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15073,
    "Rank": 184116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15074,
    "Rank": 184123,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, TRIVANDRUM,Govt Dental College Thiruvananthapuram Kerala, Kerala, 695011",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15075,
    "Rank": 184132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15076,
    "Rank": 184204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15077,
    "Rank": 184265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15078,
    "Rank": 184351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15079,
    "Rank": 184377,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15080,
    "Rank": 184416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15081,
    "Rank": 184529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15082,
    "Rank": 184571,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15083,
    "Rank": 184647,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15084,
    "Rank": 184672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15085,
    "Rank": 184679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15086,
    "Rank": 184704,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15087,
    "Rank": 184735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15088,
    "Rank": 184922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15089,
    "Rank": 184943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15090,
    "Rank": 184953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15091,
    "Rank": 184993,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15092,
    "Rank": 185050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15093,
    "Rank": 185060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15094,
    "Rank": 185072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15095,
    "Rank": 185102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15096,
    "Rank": 185185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15097,
    "Rank": 185187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15098,
    "Rank": 185193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15099,
    "Rank": 185251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15100,
    "Rank": 185294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15101,
    "Rank": 185355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15102,
    "Rank": 185473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15103,
    "Rank": 185690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15104,
    "Rank": 185693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15105,
    "Rank": 185700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15106,
    "Rank": 185753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15107,
    "Rank": 185764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15108,
    "Rank": 185831,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted( CW Rank\n: 168 )"
  },
  {
    "SNo": 15109,
    "Rank": 185845,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15110,
    "Rank": 185917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15111,
    "Rank": 185965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15112,
    "Rank": 185968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15113,
    "Rank": 185971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15114,
    "Rank": 185999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15115,
    "Rank": 186022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15116,
    "Rank": 186044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15117,
    "Rank": 186123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15118,
    "Rank": 186124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15119,
    "Rank": 186150,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15120,
    "Rank": 186190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15121,
    "Rank": 186257,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15122,
    "Rank": 186275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15123,
    "Rank": 186280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15124,
    "Rank": 186306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15125,
    "Rank": 186328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15126,
    "Rank": 186345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15127,
    "Rank": 186387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15128,
    "Rank": 186406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15129,
    "Rank": 186513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15130,
    "Rank": 186571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15131,
    "Rank": 186611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15132,
    "Rank": 186673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15133,
    "Rank": 186700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15134,
    "Rank": 186701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15135,
    "Rank": 186702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15136,
    "Rank": 186803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15137,
    "Rank": 186886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15138,
    "Rank": 186889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15139,
    "Rank": 186903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15140,
    "Rank": 186907,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, RAIPUR,Near Marahi Mata Mandir, Rajbandha Maidan, Raipur (C.G.), Chhattisgarh, 492001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15141,
    "Rank": 187003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15142,
    "Rank": 187013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15143,
    "Rank": 187061,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15144,
    "Rank": 187092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15145,
    "Rank": 187145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15146,
    "Rank": 187165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15147,
    "Rank": 187225,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15148,
    "Rank": 187277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15149,
    "Rank": 187436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15150,
    "Rank": 187514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15151,
    "Rank": 187526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15152,
    "Rank": 187553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15153,
    "Rank": 187572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15154,
    "Rank": 187626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15155,
    "Rank": 187697,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15156,
    "Rank": 187741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15157,
    "Rank": 187838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Institute of Dental Sciences, Imphal,Porompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15158,
    "Rank": 187842,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15159,
    "Rank": 187888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15160,
    "Rank": 187911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15161,
    "Rank": 187935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15162,
    "Rank": 187964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15163,
    "Rank": 187982,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15164,
    "Rank": 188022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15165,
    "Rank": 188028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15166,
    "Rank": 188038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15167,
    "Rank": 188046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15168,
    "Rank": 188083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15169,
    "Rank": 188089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15170,
    "Rank": 188177,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15171,
    "Rank": 188347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15172,
    "Rank": 188374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15173,
    "Rank": 188409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15174,
    "Rank": 188429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15175,
    "Rank": 188436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15176,
    "Rank": 188454,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15177,
    "Rank": 188529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15178,
    "Rank": 188540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15179,
    "Rank": 188555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15180,
    "Rank": 188631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15181,
    "Rank": 188657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15182,
    "Rank": 188732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15183,
    "Rank": 188764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15184,
    "Rank": 188770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15185,
    "Rank": 188797,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15186,
    "Rank": 188876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15187,
    "Rank": 188879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15188,
    "Rank": 189018,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15189,
    "Rank": 189125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15190,
    "Rank": 189154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15191,
    "Rank": 189161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15192,
    "Rank": 189197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15193,
    "Rank": 189206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15194,
    "Rank": 189249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15195,
    "Rank": 189280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15196,
    "Rank": 189322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15197,
    "Rank": 189340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15198,
    "Rank": 189382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15199,
    "Rank": 189460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15200,
    "Rank": 189510,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15201,
    "Rank": 189528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15202,
    "Rank": 189529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15203,
    "Rank": 189530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15204,
    "Rank": 189563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15205,
    "Rank": 189630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15206,
    "Rank": 189656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15207,
    "Rank": 189768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15208,
    "Rank": 189792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15209,
    "Rank": 189875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15210,
    "Rank": 189885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15211,
    "Rank": 189894,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15212,
    "Rank": 189943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15213,
    "Rank": 189989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15214,
    "Rank": 190011,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15215,
    "Rank": 190154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15216,
    "Rank": 190169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15217,
    "Rank": 190184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15218,
    "Rank": 190228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15219,
    "Rank": 190287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15220,
    "Rank": 190344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15221,
    "Rank": 190363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15222,
    "Rank": 190522,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15223,
    "Rank": 190523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15224,
    "Rank": 190559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15225,
    "Rank": 190566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15226,
    "Rank": 190570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15227,
    "Rank": 190574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15228,
    "Rank": 190647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15229,
    "Rank": 190664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15230,
    "Rank": 190673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15231,
    "Rank": 190707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15232,
    "Rank": 190848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15233,
    "Rank": 190856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15234,
    "Rank": 190861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15235,
    "Rank": 190899,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15236,
    "Rank": 190901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College & Hospital, Srinagar,Shireen Bagh Karan Nagar Srinagar Jammu and Kashmir India, Jammu And Kashmir, 190010",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15237,
    "Rank": 190905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15238,
    "Rank": 190924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15239,
    "Rank": 191054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15240,
    "Rank": 191116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15241,
    "Rank": 191125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15242,
    "Rank": 191160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15243,
    "Rank": 191220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15244,
    "Rank": 191231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15245,
    "Rank": 191439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15246,
    "Rank": 191735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15247,
    "Rank": 191753,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15248,
    "Rank": 191761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15249,
    "Rank": 191765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15250,
    "Rank": 191782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15251,
    "Rank": 191831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15252,
    "Rank": 191881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15253,
    "Rank": 191911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15254,
    "Rank": 191936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15255,
    "Rank": 192037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15256,
    "Rank": 192050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15257,
    "Rank": 192063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15258,
    "Rank": 192110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15259,
    "Rank": 192123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15260,
    "Rank": 192155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15261,
    "Rank": 192162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15262,
    "Rank": 192165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15263,
    "Rank": 192181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15264,
    "Rank": 192321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15265,
    "Rank": 192379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15266,
    "Rank": 192411,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15267,
    "Rank": 192416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15268,
    "Rank": 192431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15269,
    "Rank": 192447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15270,
    "Rank": 192480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15271,
    "Rank": 192497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15272,
    "Rank": 192606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15273,
    "Rank": 192647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15274,
    "Rank": 192654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15275,
    "Rank": 192666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15276,
    "Rank": 192693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15277,
    "Rank": 192702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15278,
    "Rank": 192703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15279,
    "Rank": 192705,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15280,
    "Rank": 192720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15281,
    "Rank": 192736,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15282,
    "Rank": 192772,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15283,
    "Rank": 192800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15284,
    "Rank": 192895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15285,
    "Rank": 193042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15286,
    "Rank": 193043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15287,
    "Rank": 193096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15288,
    "Rank": 193113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15289,
    "Rank": 193159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15290,
    "Rank": 193161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15291,
    "Rank": 193192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15292,
    "Rank": 193209,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15293,
    "Rank": 193210,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15294,
    "Rank": 193288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15295,
    "Rank": 193300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15296,
    "Rank": 193324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15297,
    "Rank": 193364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15298,
    "Rank": 193396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15299,
    "Rank": 193466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15300,
    "Rank": 193500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15301,
    "Rank": 193507,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15302,
    "Rank": 193527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15303,
    "Rank": 193538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15304,
    "Rank": 193557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15305,
    "Rank": 193666,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15306,
    "Rank": 193719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15307,
    "Rank": 193883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15308,
    "Rank": 193918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15309,
    "Rank": 194000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15310,
    "Rank": 194099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15311,
    "Rank": 194172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15312,
    "Rank": 194278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15313,
    "Rank": 194310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15314,
    "Rank": 194352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15315,
    "Rank": 194374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15316,
    "Rank": 194402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15317,
    "Rank": 194428,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15318,
    "Rank": 194491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15319,
    "Rank": 194522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15320,
    "Rank": 194546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15321,
    "Rank": 194613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15322,
    "Rank": 194709,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15323,
    "Rank": 194720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15324,
    "Rank": 194721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15325,
    "Rank": 194743,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15326,
    "Rank": 194787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15327,
    "Rank": 194875,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15328,
    "Rank": 194879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15329,
    "Rank": 194938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15330,
    "Rank": 194942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15331,
    "Rank": 194959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15332,
    "Rank": 195008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15333,
    "Rank": 195036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15334,
    "Rank": 195045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15335,
    "Rank": 195052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15336,
    "Rank": 195071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15337,
    "Rank": 195076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15338,
    "Rank": 195098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15339,
    "Rank": 195178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15340,
    "Rank": 195183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15341,
    "Rank": 195184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15342,
    "Rank": 195303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15343,
    "Rank": 195359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15344,
    "Rank": 195404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15345,
    "Rank": 195419,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15346,
    "Rank": 195435,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15347,
    "Rank": 195485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15348,
    "Rank": 195564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15349,
    "Rank": 195588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15350,
    "Rank": 195597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15351,
    "Rank": 195605,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15352,
    "Rank": 195611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15353,
    "Rank": 195695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15354,
    "Rank": 195741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15355,
    "Rank": 195759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15356,
    "Rank": 195780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15357,
    "Rank": 195786,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15358,
    "Rank": 195806,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15359,
    "Rank": 195906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15360,
    "Rank": 195907,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15361,
    "Rank": 195928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15362,
    "Rank": 195946,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15363,
    "Rank": 195996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15364,
    "Rank": 196034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15365,
    "Rank": 196129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15366,
    "Rank": 196142,
    "Allotted Quota": "All India",
    "Allotted Institute": "SETH G.S. MEDICAL COLLEGE, MUMBAI,ACHARYA DONDE MARG PAREL\nMUMBAI, Maharashtra, 400012",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15367,
    "Rank": 196168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15368,
    "Rank": 196249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15369,
    "Rank": 196372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15370,
    "Rank": 196402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15371,
    "Rank": 196447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15372,
    "Rank": 196486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15373,
    "Rank": 196494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15374,
    "Rank": 196698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15375,
    "Rank": 196784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15376,
    "Rank": 196841,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15377,
    "Rank": 196849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15378,
    "Rank": 196861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15379,
    "Rank": 196894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15380,
    "Rank": 197016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15381,
    "Rank": 197063,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15382,
    "Rank": 197183,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15383,
    "Rank": 197243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15384,
    "Rank": 197282,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15385,
    "Rank": 197357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15386,
    "Rank": 197443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15387,
    "Rank": 197447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15388,
    "Rank": 197456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15389,
    "Rank": 197457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15390,
    "Rank": 197596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15391,
    "Rank": 197600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15392,
    "Rank": 197612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15393,
    "Rank": 197613,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15394,
    "Rank": 197667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15395,
    "Rank": 197669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15396,
    "Rank": 197732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15397,
    "Rank": 197809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15398,
    "Rank": 197843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15399,
    "Rank": 197847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15400,
    "Rank": 197877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15401,
    "Rank": 197895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15402,
    "Rank": 197914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15403,
    "Rank": 198045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15404,
    "Rank": 198069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15405,
    "Rank": 198082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15406,
    "Rank": 198231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15407,
    "Rank": 198246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15408,
    "Rank": 198270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15409,
    "Rank": 198299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15410,
    "Rank": 198332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15411,
    "Rank": 198447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15412,
    "Rank": 198460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15413,
    "Rank": 198599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15414,
    "Rank": 198602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15415,
    "Rank": 198626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15416,
    "Rank": 198629,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15417,
    "Rank": 198664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15418,
    "Rank": 198742,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15419,
    "Rank": 198864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15420,
    "Rank": 198905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15421,
    "Rank": 198917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15422,
    "Rank": 198986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15423,
    "Rank": 199001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15424,
    "Rank": 199047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15425,
    "Rank": 199050,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15426,
    "Rank": 199108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15427,
    "Rank": 199150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15428,
    "Rank": 199165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15429,
    "Rank": 199180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15430,
    "Rank": 199280,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15431,
    "Rank": 199294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15432,
    "Rank": 199345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15433,
    "Rank": 199359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15434,
    "Rank": 199388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15435,
    "Rank": 199409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15436,
    "Rank": 199467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15437,
    "Rank": 199558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15438,
    "Rank": 199630,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15439,
    "Rank": 199636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15440,
    "Rank": 199676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15441,
    "Rank": 199736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15442,
    "Rank": 199757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15443,
    "Rank": 199879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15444,
    "Rank": 199958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15445,
    "Rank": 200005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15446,
    "Rank": 200030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15447,
    "Rank": 200114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15448,
    "Rank": 200146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15449,
    "Rank": 200166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15450,
    "Rank": 200186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15451,
    "Rank": 200189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15452,
    "Rank": 200193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15453,
    "Rank": 200306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15454,
    "Rank": 200357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15455,
    "Rank": 200360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15456,
    "Rank": 200383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15457,
    "Rank": 200386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15458,
    "Rank": 200409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15459,
    "Rank": 200463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15460,
    "Rank": 200482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15461,
    "Rank": 200523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15462,
    "Rank": 200637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15463,
    "Rank": 200682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15464,
    "Rank": 200728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15465,
    "Rank": 200742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15466,
    "Rank": 200772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15467,
    "Rank": 200795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15468,
    "Rank": 200797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15469,
    "Rank": 200819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15470,
    "Rank": 200863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15471,
    "Rank": 200888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15472,
    "Rank": 201039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15473,
    "Rank": 201073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15474,
    "Rank": 201110,
    "Allotted Quota": "All India",
    "Allotted Institute": "FACULTY OF DEN SCI, KG MED UNIV,LUCKNOW,SHAHMINA ROAD,\nCHOWK,LUCKNOW, Uttar Pradesh, 226003",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15475,
    "Rank": 201223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15476,
    "Rank": 201241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15477,
    "Rank": 201258,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15478,
    "Rank": 201277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15479,
    "Rank": 201381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15480,
    "Rank": 201434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15481,
    "Rank": 201484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15482,
    "Rank": 201495,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15483,
    "Rank": 201513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15484,
    "Rank": 201544,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA DENTAL COLLEGE & HOSPITAL, GOA,Bambolim,Goa, Goa, 403202",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15485,
    "Rank": 201561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15486,
    "Rank": 201576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15487,
    "Rank": 201641,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15488,
    "Rank": 201688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15489,
    "Rank": 201689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15490,
    "Rank": 201701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Hyderabad,GOVERNMENT DENTAL COLLEGE AND HOSPITAL,\nAFZALGUNJ, HYDERABAD - 500012, Telangana, 500012",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15491,
    "Rank": 201751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15492,
    "Rank": 201752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15493,
    "Rank": 201781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15494,
    "Rank": 201844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15495,
    "Rank": 201888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15496,
    "Rank": 201924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15497,
    "Rank": 201980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15498,
    "Rank": 201981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15499,
    "Rank": 202008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15500,
    "Rank": 202094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15501,
    "Rank": 202247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15502,
    "Rank": 202380,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15503,
    "Rank": 202386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15504,
    "Rank": 202398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15505,
    "Rank": 202478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15506,
    "Rank": 202484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15507,
    "Rank": 202503,
    "Allotted Quota": "All India",
    "Allotted Institute": "PB. GOVT. DENTAL COLLEGE & HOSP,AMRITSAR,SSSS Chowk Majitha Road,\nAmritsar, Punjab, 143001",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15508,
    "Rank": 202545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15509,
    "Rank": 202595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15510,
    "Rank": 202640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15511,
    "Rank": 202667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15512,
    "Rank": 202679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15513,
    "Rank": 202716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15514,
    "Rank": 202755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15515,
    "Rank": 202769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15516,
    "Rank": 202860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15517,
    "Rank": 202865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15518,
    "Rank": 202867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15519,
    "Rank": 202879,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15520,
    "Rank": 202971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15521,
    "Rank": 202994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15522,
    "Rank": 203011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15523,
    "Rank": 203047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15524,
    "Rank": 203050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15525,
    "Rank": 203100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15526,
    "Rank": 203190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15527,
    "Rank": 203208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15528,
    "Rank": 203250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15529,
    "Rank": 203256,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15530,
    "Rank": 203338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15531,
    "Rank": 203443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15532,
    "Rank": 203475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15533,
    "Rank": 203771,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15534,
    "Rank": 203887,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15535,
    "Rank": 203965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15536,
    "Rank": 203995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15537,
    "Rank": 204054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15538,
    "Rank": 204107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15539,
    "Rank": 204133,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15540,
    "Rank": 204142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15541,
    "Rank": 204176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15542,
    "Rank": 204242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15543,
    "Rank": 204280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15544,
    "Rank": 204302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15545,
    "Rank": 204329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Dental College and Hos. , Annamalai ,GOVT DENTAL COLLEGE AND HOSPITAL, CUDDALORE DT- ANNAMALAINAGAR CHIDAMBARAM, Tamil Nadu, 608002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15546,
    "Rank": 204339,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15547,
    "Rank": 204352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15548,
    "Rank": 204382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15549,
    "Rank": 204404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15550,
    "Rank": 204412,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15551,
    "Rank": 204630,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15552,
    "Rank": 204670,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15553,
    "Rank": 204700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15554,
    "Rank": 204703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College Dibrugarh,I Lane, inside Assam Medical college campus, Barbari, Dibrugarh-786002, Assam, 786002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15555,
    "Rank": 204739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15556,
    "Rank": 204755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15557,
    "Rank": 204774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15558,
    "Rank": 204805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15559,
    "Rank": 204849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government DentalCollege and Hospital Paithna Bhaganbigha Rahui Nalanda,Paithna- Bhaganbigha, Rahui, Nalanda, Bihar, 803118",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15560,
    "Rank": 204870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15561,
    "Rank": 204935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15562,
    "Rank": 204950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15563,
    "Rank": 204998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15564,
    "Rank": 205024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15565,
    "Rank": 205026,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15566,
    "Rank": 205043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15567,
    "Rank": 205063,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15568,
    "Rank": 205136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15569,
    "Rank": 205154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15570,
    "Rank": 205225,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15571,
    "Rank": 205228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15572,
    "Rank": 205254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15573,
    "Rank": 205277,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15574,
    "Rank": 205278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15575,
    "Rank": 205315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15576,
    "Rank": 205351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15577,
    "Rank": 205388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15578,
    "Rank": 205521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15579,
    "Rank": 205546,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15580,
    "Rank": 205548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15581,
    "Rank": 205631,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15582,
    "Rank": 205717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15583,
    "Rank": 205738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15584,
    "Rank": 205744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15585,
    "Rank": 205762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15586,
    "Rank": 205767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15587,
    "Rank": 205818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15588,
    "Rank": 205831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15589,
    "Rank": 205879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15590,
    "Rank": 205883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15591,
    "Rank": 205932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15592,
    "Rank": 205957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15593,
    "Rank": 205973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15594,
    "Rank": 205977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15595,
    "Rank": 206134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15596,
    "Rank": 206208,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15597,
    "Rank": 206221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15598,
    "Rank": 206265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15599,
    "Rank": 206277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15600,
    "Rank": 206403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15601,
    "Rank": 206416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15602,
    "Rank": 206517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15603,
    "Rank": 206519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15604,
    "Rank": 206614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15605,
    "Rank": 206680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15606,
    "Rank": 206715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15607,
    "Rank": 206722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15608,
    "Rank": 206763,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15609,
    "Rank": 206794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15610,
    "Rank": 206828,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15611,
    "Rank": 206853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15612,
    "Rank": 206939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15613,
    "Rank": 207096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15614,
    "Rank": 207109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15615,
    "Rank": 207128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15616,
    "Rank": 207242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15617,
    "Rank": 207281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15618,
    "Rank": 207340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15619,
    "Rank": 207348,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15620,
    "Rank": 207517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15621,
    "Rank": 207522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15622,
    "Rank": 207594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15623,
    "Rank": 207603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15624,
    "Rank": 207619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15625,
    "Rank": 207628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15626,
    "Rank": 207668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15627,
    "Rank": 207691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15628,
    "Rank": 207883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15629,
    "Rank": 207885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15630,
    "Rank": 208117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15631,
    "Rank": 208152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15632,
    "Rank": 208276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15633,
    "Rank": 208304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15634,
    "Rank": 208329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15635,
    "Rank": 208600,
    "Allotted Quota": "All India",
    "Allotted Institute": "PT. B.D. SHARMA PGIMS, ROHTAK,Pt. B.D. Sharma PGIMS, Rohtak, Haryana, 124001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15636,
    "Rank": 208606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15637,
    "Rank": 208640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15638,
    "Rank": 208663,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15639,
    "Rank": 208732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15640,
    "Rank": 208768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15641,
    "Rank": 208774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15642,
    "Rank": 208867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15643,
    "Rank": 208881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15644,
    "Rank": 208907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15645,
    "Rank": 208925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15646,
    "Rank": 209038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15647,
    "Rank": 209044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15648,
    "Rank": 209054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15649,
    "Rank": 209107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15650,
    "Rank": 209170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15651,
    "Rank": 209177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15652,
    "Rank": 209192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15653,
    "Rank": 209195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15654,
    "Rank": 209199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15655,
    "Rank": 209222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15656,
    "Rank": 209249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15657,
    "Rank": 209256,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15658,
    "Rank": 209272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15659,
    "Rank": 209285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15660,
    "Rank": 209328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15661,
    "Rank": 209342,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15662,
    "Rank": 209374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15663,
    "Rank": 209467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15664,
    "Rank": 209475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15665,
    "Rank": 209478,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15666,
    "Rank": 209512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15667,
    "Rank": 209521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15668,
    "Rank": 209558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15669,
    "Rank": 209599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15670,
    "Rank": 209601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15671,
    "Rank": 209614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15672,
    "Rank": 209626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15673,
    "Rank": 209654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15674,
    "Rank": 209665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15675,
    "Rank": 209764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15676,
    "Rank": 209824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15677,
    "Rank": 209869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15678,
    "Rank": 209889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15679,
    "Rank": 209896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15680,
    "Rank": 209898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15681,
    "Rank": 210027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15682,
    "Rank": 210039,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15683,
    "Rank": 210042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15684,
    "Rank": 210053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15685,
    "Rank": 210086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15686,
    "Rank": 210142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15687,
    "Rank": 210164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15688,
    "Rank": 210185,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15689,
    "Rank": 210202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15690,
    "Rank": 210219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15691,
    "Rank": 210456,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE, KOTTAYAM,Government Dental College, P.O.Gandhinagar Kottayam- 686008, Kerala, 686008",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15692,
    "Rank": 210480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15693,
    "Rank": 210619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15694,
    "Rank": 210625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15695,
    "Rank": 210628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15696,
    "Rank": 210668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15697,
    "Rank": 210740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15698,
    "Rank": 210772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15699,
    "Rank": 210933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15700,
    "Rank": 210947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15701,
    "Rank": 211026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15702,
    "Rank": 211035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15703,
    "Rank": 211042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15704,
    "Rank": 211052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15705,
    "Rank": 211086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15706,
    "Rank": 211107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15707,
    "Rank": 211155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15708,
    "Rank": 211418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15709,
    "Rank": 211433,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15710,
    "Rank": 211478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15711,
    "Rank": 211578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15712,
    "Rank": 211590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15713,
    "Rank": 211635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15714,
    "Rank": 211642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15715,
    "Rank": 211646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15716,
    "Rank": 211653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15717,
    "Rank": 211741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15718,
    "Rank": 211783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15719,
    "Rank": 211784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15720,
    "Rank": 211836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15721,
    "Rank": 211875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15722,
    "Rank": 211880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15723,
    "Rank": 211892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15724,
    "Rank": 211943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15725,
    "Rank": 211981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15726,
    "Rank": 211986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15727,
    "Rank": 212078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15728,
    "Rank": 212227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15729,
    "Rank": 212238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15730,
    "Rank": 212244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15731,
    "Rank": 212258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15732,
    "Rank": 212352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15733,
    "Rank": 212379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15734,
    "Rank": 212464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15735,
    "Rank": 212539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15736,
    "Rank": 212655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15737,
    "Rank": 212704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15738,
    "Rank": 212723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15739,
    "Rank": 212801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15740,
    "Rank": 212823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15741,
    "Rank": 212838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15742,
    "Rank": 212964,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 11 )"
  },
  {
    "SNo": 15743,
    "Rank": 212969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15744,
    "Rank": 212975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15745,
    "Rank": 213033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15746,
    "Rank": 213067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15747,
    "Rank": 213081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15748,
    "Rank": 213134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15749,
    "Rank": 213190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15750,
    "Rank": 213191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15751,
    "Rank": 213209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15752,
    "Rank": 213214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15753,
    "Rank": 213256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15754,
    "Rank": 213318,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15755,
    "Rank": 213365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15756,
    "Rank": 213381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15757,
    "Rank": 213383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15758,
    "Rank": 213414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15759,
    "Rank": 213498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15760,
    "Rank": 213577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15761,
    "Rank": 213603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15762,
    "Rank": 213611,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15763,
    "Rank": 213620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15764,
    "Rank": 213657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15765,
    "Rank": 213670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15766,
    "Rank": 213719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15767,
    "Rank": 213741,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15768,
    "Rank": 213829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15769,
    "Rank": 213871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15770,
    "Rank": 213892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15771,
    "Rank": 213918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15772,
    "Rank": 214035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15773,
    "Rank": 214064,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15774,
    "Rank": 214073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15775,
    "Rank": 214102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15776,
    "Rank": 214130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15777,
    "Rank": 214147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15778,
    "Rank": 214217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15779,
    "Rank": 214286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15780,
    "Rank": 214314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15781,
    "Rank": 214327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15782,
    "Rank": 214390,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15783,
    "Rank": 214420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15784,
    "Rank": 214436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15785,
    "Rank": 214491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15786,
    "Rank": 214513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15787,
    "Rank": 214603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15788,
    "Rank": 214611,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15789,
    "Rank": 214632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15790,
    "Rank": 214692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15791,
    "Rank": 214697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15792,
    "Rank": 214725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15793,
    "Rank": 214749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15794,
    "Rank": 214837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15795,
    "Rank": 214857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15796,
    "Rank": 214882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15797,
    "Rank": 214886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15798,
    "Rank": 214912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15799,
    "Rank": 214914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15800,
    "Rank": 214970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15801,
    "Rank": 214985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15802,
    "Rank": 215038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15803,
    "Rank": 215039,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15804,
    "Rank": 215041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15805,
    "Rank": 215055,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15806,
    "Rank": 215065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15807,
    "Rank": 215127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15808,
    "Rank": 215152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15809,
    "Rank": 215160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15810,
    "Rank": 215173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15811,
    "Rank": 215227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15812,
    "Rank": 215234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15813,
    "Rank": 215257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15814,
    "Rank": 215269,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15815,
    "Rank": 215303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15816,
    "Rank": 215311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15817,
    "Rank": 215323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15818,
    "Rank": 215423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15819,
    "Rank": 215547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15820,
    "Rank": 215596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15821,
    "Rank": 215662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15822,
    "Rank": 215764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15823,
    "Rank": 215783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15824,
    "Rank": 215873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15825,
    "Rank": 215951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15826,
    "Rank": 216110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15827,
    "Rank": 216154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15828,
    "Rank": 216155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15829,
    "Rank": 216182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15830,
    "Rank": 216275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15831,
    "Rank": 216365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15832,
    "Rank": 216420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15833,
    "Rank": 216432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15834,
    "Rank": 216453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15835,
    "Rank": 216544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15836,
    "Rank": 216594,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15837,
    "Rank": 216607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15838,
    "Rank": 216670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15839,
    "Rank": 216758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15840,
    "Rank": 216804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15841,
    "Rank": 216845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15842,
    "Rank": 217076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15843,
    "Rank": 217098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15844,
    "Rank": 217163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15845,
    "Rank": 217169,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15846,
    "Rank": 217195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15847,
    "Rank": 217216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15848,
    "Rank": 217234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15849,
    "Rank": 217254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15850,
    "Rank": 217264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15851,
    "Rank": 217280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15852,
    "Rank": 217320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15853,
    "Rank": 217431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15854,
    "Rank": 217434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15855,
    "Rank": 217487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15856,
    "Rank": 217546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15857,
    "Rank": 217576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15858,
    "Rank": 217596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15859,
    "Rank": 217867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15860,
    "Rank": 217873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15861,
    "Rank": 217946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15862,
    "Rank": 217950,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15863,
    "Rank": 217961,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15864,
    "Rank": 218176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15865,
    "Rank": 218453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15866,
    "Rank": 218474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15867,
    "Rank": 218509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15868,
    "Rank": 218592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15869,
    "Rank": 218607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15870,
    "Rank": 218678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15871,
    "Rank": 218735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15872,
    "Rank": 218786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15873,
    "Rank": 218803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15874,
    "Rank": 218842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15875,
    "Rank": 218945,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15876,
    "Rank": 219001,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15877,
    "Rank": 219036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15878,
    "Rank": 219051,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15879,
    "Rank": 219195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15880,
    "Rank": 219258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15881,
    "Rank": 219289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15882,
    "Rank": 219313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15883,
    "Rank": 219355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15884,
    "Rank": 219450,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15885,
    "Rank": 219536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15886,
    "Rank": 219630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15887,
    "Rank": 219646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15888,
    "Rank": 219677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15889,
    "Rank": 219720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15890,
    "Rank": 219750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15891,
    "Rank": 219755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15892,
    "Rank": 219759,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15893,
    "Rank": 219960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15894,
    "Rank": 220115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15895,
    "Rank": 220143,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15896,
    "Rank": 220186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15897,
    "Rank": 220259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15898,
    "Rank": 220288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15899,
    "Rank": 220301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15900,
    "Rank": 220396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15901,
    "Rank": 220404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15902,
    "Rank": 220405,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15903,
    "Rank": 220460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15904,
    "Rank": 220465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15905,
    "Rank": 220503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15906,
    "Rank": 220663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15907,
    "Rank": 220721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15908,
    "Rank": 220724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15909,
    "Rank": 220750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15910,
    "Rank": 220779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15911,
    "Rank": 220793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15912,
    "Rank": 220947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15913,
    "Rank": 220995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15914,
    "Rank": 221043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15915,
    "Rank": 221127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15916,
    "Rank": 221143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15917,
    "Rank": 221145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15918,
    "Rank": 221171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15919,
    "Rank": 221175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15920,
    "Rank": 221255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15921,
    "Rank": 221264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15922,
    "Rank": 221271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15923,
    "Rank": 221504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15924,
    "Rank": 221555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15925,
    "Rank": 221578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15926,
    "Rank": 221585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15927,
    "Rank": 221637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15928,
    "Rank": 221676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15929,
    "Rank": 221738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15930,
    "Rank": 221971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15931,
    "Rank": 222036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15932,
    "Rank": 222088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15933,
    "Rank": 222140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15934,
    "Rank": 222177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15935,
    "Rank": 222224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15936,
    "Rank": 222238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15937,
    "Rank": 222263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15938,
    "Rank": 222273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15939,
    "Rank": 222458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15940,
    "Rank": 222509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15941,
    "Rank": 222586,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15942,
    "Rank": 222616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15943,
    "Rank": 222619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15944,
    "Rank": 222718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15945,
    "Rank": 222752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15946,
    "Rank": 222761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15947,
    "Rank": 222787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15948,
    "Rank": 222807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15949,
    "Rank": 222906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15950,
    "Rank": 222923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15951,
    "Rank": 222986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15952,
    "Rank": 223023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15953,
    "Rank": 223029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15954,
    "Rank": 223095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15955,
    "Rank": 223137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15956,
    "Rank": 223313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15957,
    "Rank": 223506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15958,
    "Rank": 223723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15959,
    "Rank": 223726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15960,
    "Rank": 223761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15961,
    "Rank": 223829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15962,
    "Rank": 223830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15963,
    "Rank": 223851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15964,
    "Rank": 223868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15965,
    "Rank": 223922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15966,
    "Rank": 224178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15967,
    "Rank": 224209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15968,
    "Rank": 224210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15969,
    "Rank": 224308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15970,
    "Rank": 224350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15971,
    "Rank": 224403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15972,
    "Rank": 224443,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 12 )"
  },
  {
    "SNo": 15973,
    "Rank": 224545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15974,
    "Rank": 224558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15975,
    "Rank": 224570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15976,
    "Rank": 224592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15977,
    "Rank": 224781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15978,
    "Rank": 224782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15979,
    "Rank": 224786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15980,
    "Rank": 224794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15981,
    "Rank": 224826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15982,
    "Rank": 224882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15983,
    "Rank": 224892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15984,
    "Rank": 224933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15985,
    "Rank": 224971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15986,
    "Rank": 224972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15987,
    "Rank": 224976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15988,
    "Rank": 224982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15989,
    "Rank": 225001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15990,
    "Rank": 225005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15991,
    "Rank": 225041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15992,
    "Rank": 225044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15993,
    "Rank": 225057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15994,
    "Rank": 225117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15995,
    "Rank": 225136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15996,
    "Rank": 225143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15997,
    "Rank": 225160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15998,
    "Rank": 225178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 15999,
    "Rank": 225180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16000,
    "Rank": 225201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16001,
    "Rank": 225243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16002,
    "Rank": 225255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16003,
    "Rank": 225257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16004,
    "Rank": 225274,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16005,
    "Rank": 225321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16006,
    "Rank": 225398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16007,
    "Rank": 225475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16008,
    "Rank": 225523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16009,
    "Rank": 225538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16010,
    "Rank": 225622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16011,
    "Rank": 225639,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16012,
    "Rank": 225710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16013,
    "Rank": 225767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16014,
    "Rank": 225780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16015,
    "Rank": 225868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16016,
    "Rank": 225907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16017,
    "Rank": 226045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16018,
    "Rank": 226171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16019,
    "Rank": 226184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16020,
    "Rank": 226192,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16021,
    "Rank": 226208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16022,
    "Rank": 226279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16023,
    "Rank": 226326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16024,
    "Rank": 226436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16025,
    "Rank": 226528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16026,
    "Rank": 226590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16027,
    "Rank": 226822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16028,
    "Rank": 226843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16029,
    "Rank": 226873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16030,
    "Rank": 226895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16031,
    "Rank": 226923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16032,
    "Rank": 226976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16033,
    "Rank": 226985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16034,
    "Rank": 227045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16035,
    "Rank": 227170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16036,
    "Rank": 227227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16037,
    "Rank": 227246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16038,
    "Rank": 227460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16039,
    "Rank": 227464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16040,
    "Rank": 227586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16041,
    "Rank": 227621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16042,
    "Rank": 227727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16043,
    "Rank": 227745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16044,
    "Rank": 227777,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16045,
    "Rank": 227798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16046,
    "Rank": 227824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16047,
    "Rank": 227842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16048,
    "Rank": 228013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16049,
    "Rank": 228084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16050,
    "Rank": 228167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16051,
    "Rank": 228258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16052,
    "Rank": 228282,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16053,
    "Rank": 228346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16054,
    "Rank": 228354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16055,
    "Rank": 228397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16056,
    "Rank": 228519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16057,
    "Rank": 228542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16058,
    "Rank": 228604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16059,
    "Rank": 228787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16060,
    "Rank": 228807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16061,
    "Rank": 228828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16062,
    "Rank": 228873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16063,
    "Rank": 228880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16064,
    "Rank": 228966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16065,
    "Rank": 228977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16066,
    "Rank": 229044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16067,
    "Rank": 229076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16068,
    "Rank": 229094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16069,
    "Rank": 229220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16070,
    "Rank": 229229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16071,
    "Rank": 229244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16072,
    "Rank": 229336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16073,
    "Rank": 229337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16074,
    "Rank": 229397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16075,
    "Rank": 229407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16076,
    "Rank": 229495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16077,
    "Rank": 229593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16078,
    "Rank": 229621,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16079,
    "Rank": 229643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16080,
    "Rank": 229649,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16081,
    "Rank": 229659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16082,
    "Rank": 229670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16083,
    "Rank": 229738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16084,
    "Rank": 229758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16085,
    "Rank": 229863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16086,
    "Rank": 229955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16087,
    "Rank": 229989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16088,
    "Rank": 229996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16089,
    "Rank": 230008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16090,
    "Rank": 230064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16091,
    "Rank": 230273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16092,
    "Rank": 230293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16093,
    "Rank": 230320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16094,
    "Rank": 230352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16095,
    "Rank": 230360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16096,
    "Rank": 230378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16097,
    "Rank": 230420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16098,
    "Rank": 230494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16099,
    "Rank": 230522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16100,
    "Rank": 230561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16101,
    "Rank": 230578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16102,
    "Rank": 230780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16103,
    "Rank": 230797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16104,
    "Rank": 230956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16105,
    "Rank": 231010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16106,
    "Rank": 231019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16107,
    "Rank": 231193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16108,
    "Rank": 231215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16109,
    "Rank": 231221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16110,
    "Rank": 231422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16111,
    "Rank": 231473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16112,
    "Rank": 231489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16113,
    "Rank": 231497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16114,
    "Rank": 231529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16115,
    "Rank": 231585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16116,
    "Rank": 231588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16117,
    "Rank": 231713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16118,
    "Rank": 231746,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16119,
    "Rank": 231865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16120,
    "Rank": 231997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16121,
    "Rank": 232002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16122,
    "Rank": 232053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16123,
    "Rank": 232084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16124,
    "Rank": 232112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16125,
    "Rank": 232207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16126,
    "Rank": 232208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16127,
    "Rank": 232272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16128,
    "Rank": 232293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16129,
    "Rank": 232297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16130,
    "Rank": 232377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16131,
    "Rank": 232466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16132,
    "Rank": 232526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16133,
    "Rank": 232552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16134,
    "Rank": 232636,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16135,
    "Rank": 232672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16136,
    "Rank": 232729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16137,
    "Rank": 232756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16138,
    "Rank": 232764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16139,
    "Rank": 232834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16140,
    "Rank": 232846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16141,
    "Rank": 232954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16142,
    "Rank": 232961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16143,
    "Rank": 232968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16144,
    "Rank": 233037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16145,
    "Rank": 233057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16146,
    "Rank": 233072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16147,
    "Rank": 233077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16148,
    "Rank": 233114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16149,
    "Rank": 233125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16150,
    "Rank": 233148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16151,
    "Rank": 233257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16152,
    "Rank": 233331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16153,
    "Rank": 233359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16154,
    "Rank": 233392,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16155,
    "Rank": 233431,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16156,
    "Rank": 233435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16157,
    "Rank": 233577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16158,
    "Rank": 233593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16159,
    "Rank": 233651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16160,
    "Rank": 233662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16161,
    "Rank": 233699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16162,
    "Rank": 233756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16163,
    "Rank": 233884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16164,
    "Rank": 233958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16165,
    "Rank": 234043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16166,
    "Rank": 234077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16167,
    "Rank": 234122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16168,
    "Rank": 234182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16169,
    "Rank": 234226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16170,
    "Rank": 234232,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Rajkot,Admission cell, Academic section, First floor, Academic block, Permanent Campus, AIIMS Rajkot, Khand, Gujarat, 360110",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16171,
    "Rank": 234252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16172,
    "Rank": 234327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16173,
    "Rank": 234341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16174,
    "Rank": 234346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16175,
    "Rank": 234449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16176,
    "Rank": 234457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16177,
    "Rank": 234489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16178,
    "Rank": 234561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16179,
    "Rank": 234691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16180,
    "Rank": 234693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16181,
    "Rank": 234717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16182,
    "Rank": 234732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16183,
    "Rank": 234738,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16184,
    "Rank": 234800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16185,
    "Rank": 234908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16186,
    "Rank": 234923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16187,
    "Rank": 234931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16188,
    "Rank": 234949,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16189,
    "Rank": 234997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16190,
    "Rank": 235070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16191,
    "Rank": 235079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16192,
    "Rank": 235087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16193,
    "Rank": 235090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16194,
    "Rank": 235198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16195,
    "Rank": 235202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16196,
    "Rank": 235300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16197,
    "Rank": 235326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16198,
    "Rank": 235356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16199,
    "Rank": 235386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16200,
    "Rank": 235387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16201,
    "Rank": 235407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16202,
    "Rank": 235596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16203,
    "Rank": 235666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16204,
    "Rank": 235671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16205,
    "Rank": 235674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16206,
    "Rank": 235696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16207,
    "Rank": 235728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16208,
    "Rank": 235790,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16209,
    "Rank": 235893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16210,
    "Rank": 235998,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16211,
    "Rank": 236028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16212,
    "Rank": 236159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16213,
    "Rank": 236199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16214,
    "Rank": 236212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16215,
    "Rank": 236236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16216,
    "Rank": 236260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16217,
    "Rank": 236296,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16218,
    "Rank": 236297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16219,
    "Rank": 236303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16220,
    "Rank": 236328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16221,
    "Rank": 236506,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16222,
    "Rank": 236633,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16223,
    "Rank": 236637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16224,
    "Rank": 236645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16225,
    "Rank": 236662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16226,
    "Rank": 236717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16227,
    "Rank": 236754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16228,
    "Rank": 237005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16229,
    "Rank": 237031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16230,
    "Rank": 237282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16231,
    "Rank": 237295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16232,
    "Rank": 237310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16233,
    "Rank": 237391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16234,
    "Rank": 237401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16235,
    "Rank": 237461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16236,
    "Rank": 237497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16237,
    "Rank": 237554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16238,
    "Rank": 237600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16239,
    "Rank": 237609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16240,
    "Rank": 237614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16241,
    "Rank": 237631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16242,
    "Rank": 237747,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16243,
    "Rank": 237798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16244,
    "Rank": 237845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16245,
    "Rank": 237885,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16246,
    "Rank": 238093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16247,
    "Rank": 238122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16248,
    "Rank": 238166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16249,
    "Rank": 238244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16250,
    "Rank": 238255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16251,
    "Rank": 238311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16252,
    "Rank": 238510,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16253,
    "Rank": 238526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16254,
    "Rank": 238667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16255,
    "Rank": 238745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16256,
    "Rank": 238851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16257,
    "Rank": 238925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16258,
    "Rank": 238955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16259,
    "Rank": 238976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16260,
    "Rank": 238977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16261,
    "Rank": 239107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16262,
    "Rank": 239246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16263,
    "Rank": 239357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16264,
    "Rank": 239457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16265,
    "Rank": 239498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16266,
    "Rank": 239500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16267,
    "Rank": 239564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16268,
    "Rank": 239694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16269,
    "Rank": 239812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16270,
    "Rank": 239835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16271,
    "Rank": 239846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16272,
    "Rank": 239894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16273,
    "Rank": 239914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16274,
    "Rank": 239926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16275,
    "Rank": 239927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16276,
    "Rank": 239949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16277,
    "Rank": 239964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16278,
    "Rank": 239966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16279,
    "Rank": 240007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16280,
    "Rank": 240036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16281,
    "Rank": 240056,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16282,
    "Rank": 240058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16283,
    "Rank": 240068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16284,
    "Rank": 240070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16285,
    "Rank": 240076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16286,
    "Rank": 240191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16287,
    "Rank": 240390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16288,
    "Rank": 240421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16289,
    "Rank": 240423,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16290,
    "Rank": 240441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16291,
    "Rank": 240513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16292,
    "Rank": 240545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16293,
    "Rank": 240558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16294,
    "Rank": 240607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16295,
    "Rank": 240646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16296,
    "Rank": 240679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16297,
    "Rank": 240696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16298,
    "Rank": 240698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16299,
    "Rank": 240783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16300,
    "Rank": 240795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16301,
    "Rank": 240830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16302,
    "Rank": 240836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16303,
    "Rank": 240889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16304,
    "Rank": 240909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16305,
    "Rank": 240917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16306,
    "Rank": 241079,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16307,
    "Rank": 241087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16308,
    "Rank": 241137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16309,
    "Rank": 241151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16310,
    "Rank": 241190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16311,
    "Rank": 241206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16312,
    "Rank": 241222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16313,
    "Rank": 241251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16314,
    "Rank": 241297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16315,
    "Rank": 241344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16316,
    "Rank": 241420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16317,
    "Rank": 241509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16318,
    "Rank": 241545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16319,
    "Rank": 241656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16320,
    "Rank": 241674,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16321,
    "Rank": 241748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16322,
    "Rank": 241802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16323,
    "Rank": 241879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16324,
    "Rank": 242097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16325,
    "Rank": 242140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16326,
    "Rank": 242155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16327,
    "Rank": 242206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16328,
    "Rank": 242220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16329,
    "Rank": 242221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16330,
    "Rank": 242317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16331,
    "Rank": 242352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16332,
    "Rank": 242398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16333,
    "Rank": 242410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16334,
    "Rank": 242500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16335,
    "Rank": 242504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16336,
    "Rank": 242526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16337,
    "Rank": 242535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16338,
    "Rank": 242646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16339,
    "Rank": 242674,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16340,
    "Rank": 242691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16341,
    "Rank": 242704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16342,
    "Rank": 242790,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16343,
    "Rank": 242847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16344,
    "Rank": 242889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16345,
    "Rank": 242950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16346,
    "Rank": 243043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16347,
    "Rank": 243132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16348,
    "Rank": 243152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16349,
    "Rank": 243169,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16350,
    "Rank": 243221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16351,
    "Rank": 243233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16352,
    "Rank": 243240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16353,
    "Rank": 243308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16354,
    "Rank": 243333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16355,
    "Rank": 243380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16356,
    "Rank": 243381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16357,
    "Rank": 243392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16358,
    "Rank": 243416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16359,
    "Rank": 243426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16360,
    "Rank": 243470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16361,
    "Rank": 243496,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16362,
    "Rank": 243508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16363,
    "Rank": 243530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16364,
    "Rank": 243538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16365,
    "Rank": 243544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16366,
    "Rank": 243548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16367,
    "Rank": 243568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16368,
    "Rank": 243576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16369,
    "Rank": 243756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16370,
    "Rank": 243775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16371,
    "Rank": 243816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16372,
    "Rank": 243858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16373,
    "Rank": 243890,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16374,
    "Rank": 243939,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16375,
    "Rank": 243991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16376,
    "Rank": 244090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16377,
    "Rank": 244110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16378,
    "Rank": 244166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16379,
    "Rank": 244258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16380,
    "Rank": 244279,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16381,
    "Rank": 244319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16382,
    "Rank": 244327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16383,
    "Rank": 244395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16384,
    "Rank": 244408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16385,
    "Rank": 244414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16386,
    "Rank": 244418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16387,
    "Rank": 244550,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16388,
    "Rank": 244555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16389,
    "Rank": 244678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16390,
    "Rank": 244697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16391,
    "Rank": 244781,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16392,
    "Rank": 244798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16393,
    "Rank": 244850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16394,
    "Rank": 244886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16395,
    "Rank": 244907,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16396,
    "Rank": 244994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16397,
    "Rank": 245033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16398,
    "Rank": 245042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16399,
    "Rank": 245067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16400,
    "Rank": 245128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16401,
    "Rank": 245141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16402,
    "Rank": 245287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16403,
    "Rank": 245360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16404,
    "Rank": 245393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16405,
    "Rank": 245455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16406,
    "Rank": 245510,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16407,
    "Rank": 245533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16408,
    "Rank": 245547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16409,
    "Rank": 245551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16410,
    "Rank": 245564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16411,
    "Rank": 245723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16412,
    "Rank": 245742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16413,
    "Rank": 245826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16414,
    "Rank": 245857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16415,
    "Rank": 245886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16416,
    "Rank": 245925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16417,
    "Rank": 246102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16418,
    "Rank": 246143,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16419,
    "Rank": 246151,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16420,
    "Rank": 246158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16421,
    "Rank": 246314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16422,
    "Rank": 246322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16423,
    "Rank": 246360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16424,
    "Rank": 246373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16425,
    "Rank": 246425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16426,
    "Rank": 246507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16427,
    "Rank": 246575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16428,
    "Rank": 246587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16429,
    "Rank": 246661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16430,
    "Rank": 246666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16431,
    "Rank": 246695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16432,
    "Rank": 246774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16433,
    "Rank": 246783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16434,
    "Rank": 246837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16435,
    "Rank": 246839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16436,
    "Rank": 246880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16437,
    "Rank": 246958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16438,
    "Rank": 247066,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16439,
    "Rank": 247152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16440,
    "Rank": 247264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16441,
    "Rank": 247278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16442,
    "Rank": 247294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16443,
    "Rank": 247315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16444,
    "Rank": 247389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16445,
    "Rank": 247406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16446,
    "Rank": 247562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16447,
    "Rank": 247671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16448,
    "Rank": 247775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16449,
    "Rank": 247871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16450,
    "Rank": 247883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16451,
    "Rank": 247929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16452,
    "Rank": 247948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16453,
    "Rank": 248009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16454,
    "Rank": 248038,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "EWS",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16455,
    "Rank": 248091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16456,
    "Rank": 248272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16457,
    "Rank": 248316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16458,
    "Rank": 248324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16459,
    "Rank": 248391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16460,
    "Rank": 248415,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16461,
    "Rank": 248479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16462,
    "Rank": 248485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16463,
    "Rank": 248487,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16464,
    "Rank": 248553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16465,
    "Rank": 248554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16466,
    "Rank": 248582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16467,
    "Rank": 248624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16468,
    "Rank": 248652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16469,
    "Rank": 248712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16470,
    "Rank": 248727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16471,
    "Rank": 248734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16472,
    "Rank": 248803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16473,
    "Rank": 248807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16474,
    "Rank": 248828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16475,
    "Rank": 248882,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16476,
    "Rank": 248888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16477,
    "Rank": 248918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16478,
    "Rank": 248925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16479,
    "Rank": 249062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16480,
    "Rank": 249136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16481,
    "Rank": 249222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16482,
    "Rank": 249230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16483,
    "Rank": 249278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16484,
    "Rank": 249303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16485,
    "Rank": 249381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16486,
    "Rank": 249426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16487,
    "Rank": 249430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16488,
    "Rank": 249435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16489,
    "Rank": 249485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16490,
    "Rank": 249532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16491,
    "Rank": 249542,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16492,
    "Rank": 249544,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16493,
    "Rank": 249637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16494,
    "Rank": 249709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16495,
    "Rank": 249811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16496,
    "Rank": 249826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16497,
    "Rank": 249883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16498,
    "Rank": 249923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16499,
    "Rank": 249934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16500,
    "Rank": 250023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16501,
    "Rank": 250028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16502,
    "Rank": 250084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16503,
    "Rank": 250179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16504,
    "Rank": 250194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16505,
    "Rank": 250234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16506,
    "Rank": 250264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16507,
    "Rank": 250386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16508,
    "Rank": 250396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16509,
    "Rank": 250413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16510,
    "Rank": 250461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16511,
    "Rank": 250503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16512,
    "Rank": 250551,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16513,
    "Rank": 250575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16514,
    "Rank": 250644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16515,
    "Rank": 250652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16516,
    "Rank": 250684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16517,
    "Rank": 250723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16518,
    "Rank": 250729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16519,
    "Rank": 250749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16520,
    "Rank": 250808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16521,
    "Rank": 250817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16522,
    "Rank": 250854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16523,
    "Rank": 250874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16524,
    "Rank": 250876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16525,
    "Rank": 250927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16526,
    "Rank": 250984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16527,
    "Rank": 250992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16528,
    "Rank": 251003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16529,
    "Rank": 251123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16530,
    "Rank": 251169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16531,
    "Rank": 251233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16532,
    "Rank": 251301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16533,
    "Rank": 251308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16534,
    "Rank": 251320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16535,
    "Rank": 251351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16536,
    "Rank": 251365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16537,
    "Rank": 251512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16538,
    "Rank": 251581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16539,
    "Rank": 251597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16540,
    "Rank": 251636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16541,
    "Rank": 251669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16542,
    "Rank": 251762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16543,
    "Rank": 251890,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rai Bareli ,All India Institute of Medical Sciences Raebareli, Uttar Pradesh, 229405",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16544,
    "Rank": 251937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16545,
    "Rank": 251940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16546,
    "Rank": 252075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16547,
    "Rank": 252076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16548,
    "Rank": 252090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16549,
    "Rank": 252097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16550,
    "Rank": 252228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16551,
    "Rank": 252298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16552,
    "Rank": 252318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16553,
    "Rank": 252344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16554,
    "Rank": 252366,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16555,
    "Rank": 252373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16556,
    "Rank": 252400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16557,
    "Rank": 252402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16558,
    "Rank": 252421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16559,
    "Rank": 252569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16560,
    "Rank": 252577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16561,
    "Rank": 252585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16562,
    "Rank": 252586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16563,
    "Rank": 252597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16564,
    "Rank": 252640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16565,
    "Rank": 252692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16566,
    "Rank": 252773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16567,
    "Rank": 252842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16568,
    "Rank": 252915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16569,
    "Rank": 253004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16570,
    "Rank": 253022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16571,
    "Rank": 253080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16572,
    "Rank": 253096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16573,
    "Rank": 253192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16574,
    "Rank": 253203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16575,
    "Rank": 253220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16576,
    "Rank": 253259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16577,
    "Rank": 253315,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16578,
    "Rank": 253329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16579,
    "Rank": 253460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16580,
    "Rank": 253490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16581,
    "Rank": 253530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16582,
    "Rank": 253565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16583,
    "Rank": 253608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16584,
    "Rank": 253748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16585,
    "Rank": 253826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16586,
    "Rank": 254090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16587,
    "Rank": 254125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16588,
    "Rank": 254157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16589,
    "Rank": 254201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16590,
    "Rank": 254275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16591,
    "Rank": 254284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16592,
    "Rank": 254287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16593,
    "Rank": 254291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16594,
    "Rank": 254297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16595,
    "Rank": 254322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16596,
    "Rank": 254348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16597,
    "Rank": 254439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16598,
    "Rank": 254481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16599,
    "Rank": 254505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16600,
    "Rank": 254577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16601,
    "Rank": 254782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16602,
    "Rank": 254784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16603,
    "Rank": 254809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16604,
    "Rank": 254847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16605,
    "Rank": 254909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16606,
    "Rank": 254989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16607,
    "Rank": 255047,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16608,
    "Rank": 255063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16609,
    "Rank": 255079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16610,
    "Rank": 255093,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 4 )"
  },
  {
    "SNo": 16611,
    "Rank": 255277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16612,
    "Rank": 255302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16613,
    "Rank": 255426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16614,
    "Rank": 255434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16615,
    "Rank": 255548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16616,
    "Rank": 255612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16617,
    "Rank": 255624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16618,
    "Rank": 255670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16619,
    "Rank": 255720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16620,
    "Rank": 255770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16621,
    "Rank": 255818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16622,
    "Rank": 255896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16623,
    "Rank": 255916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16624,
    "Rank": 255947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16625,
    "Rank": 255973,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16626,
    "Rank": 256066,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16627,
    "Rank": 256098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16628,
    "Rank": 256165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16629,
    "Rank": 256240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16630,
    "Rank": 256257,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16631,
    "Rank": 256301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16632,
    "Rank": 256384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16633,
    "Rank": 256395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16634,
    "Rank": 256559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16635,
    "Rank": 256562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16636,
    "Rank": 256632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16637,
    "Rank": 256693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16638,
    "Rank": 256706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16639,
    "Rank": 256737,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16640,
    "Rank": 256767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16641,
    "Rank": 256810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16642,
    "Rank": 256822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,JUHU, VILEPARLE WEST MUMBAI, Maharashtra, 400056",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16643,
    "Rank": 256879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16644,
    "Rank": 256928,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SURAT,Out Side Majura Gate, New Civil Hospital Campus, Opp. Income Tax office, Surat - 395001, Gujarat, 395001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16645,
    "Rank": 256966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16646,
    "Rank": 257004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16647,
    "Rank": 257013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16648,
    "Rank": 257027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16649,
    "Rank": 257058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16650,
    "Rank": 257087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16651,
    "Rank": 257150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16652,
    "Rank": 257187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16653,
    "Rank": 257347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16654,
    "Rank": 257348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16655,
    "Rank": 257471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16656,
    "Rank": 257585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16657,
    "Rank": 257676,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16658,
    "Rank": 257679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16659,
    "Rank": 257736,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16660,
    "Rank": 257767,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16661,
    "Rank": 257776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16662,
    "Rank": 257795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16663,
    "Rank": 257839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16664,
    "Rank": 257965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16665,
    "Rank": 258127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16666,
    "Rank": 258228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16667,
    "Rank": 258241,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16668,
    "Rank": 258275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16669,
    "Rank": 258389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16670,
    "Rank": 258414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16671,
    "Rank": 258421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16672,
    "Rank": 258459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16673,
    "Rank": 258479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16674,
    "Rank": 258535,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16675,
    "Rank": 258536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16676,
    "Rank": 258539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16677,
    "Rank": 258553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16678,
    "Rank": 258572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16679,
    "Rank": 258594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16680,
    "Rank": 258694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16681,
    "Rank": 258760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16682,
    "Rank": 258821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16683,
    "Rank": 258899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16684,
    "Rank": 258945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16685,
    "Rank": 259018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16686,
    "Rank": 259055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16687,
    "Rank": 259134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16688,
    "Rank": 259181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16689,
    "Rank": 259211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16690,
    "Rank": 259245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16691,
    "Rank": 259263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16692,
    "Rank": 259369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16693,
    "Rank": 259454,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16694,
    "Rank": 259537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16695,
    "Rank": 259559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16696,
    "Rank": 259765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16697,
    "Rank": 259770,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16698,
    "Rank": 259780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16699,
    "Rank": 259930,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16700,
    "Rank": 259933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16701,
    "Rank": 259976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16702,
    "Rank": 259993,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16703,
    "Rank": 260015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16704,
    "Rank": 260036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16705,
    "Rank": 260079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16706,
    "Rank": 260101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16707,
    "Rank": 260146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16708,
    "Rank": 260157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16709,
    "Rank": 260210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16710,
    "Rank": 260273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16711,
    "Rank": 260286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16712,
    "Rank": 260314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16713,
    "Rank": 260319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16714,
    "Rank": 260343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16715,
    "Rank": 260356,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16716,
    "Rank": 260374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16717,
    "Rank": 260661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16718,
    "Rank": 260754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16719,
    "Rank": 260771,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16720,
    "Rank": 260852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16721,
    "Rank": 260862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16722,
    "Rank": 260958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16723,
    "Rank": 260981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16724,
    "Rank": 261018,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16725,
    "Rank": 261081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16726,
    "Rank": 261149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16727,
    "Rank": 261154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16728,
    "Rank": 261200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16729,
    "Rank": 261221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16730,
    "Rank": 261256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16731,
    "Rank": 261331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16732,
    "Rank": 261333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16733,
    "Rank": 261377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16734,
    "Rank": 261434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16735,
    "Rank": 261558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16736,
    "Rank": 261681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16737,
    "Rank": 261734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16738,
    "Rank": 261752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16739,
    "Rank": 261815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16740,
    "Rank": 261900,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16741,
    "Rank": 261952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16742,
    "Rank": 262048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16743,
    "Rank": 262053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16744,
    "Rank": 262084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16745,
    "Rank": 262122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16746,
    "Rank": 262150,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16747,
    "Rank": 262200,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16748,
    "Rank": 262205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16749,
    "Rank": 262267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16750,
    "Rank": 262270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16751,
    "Rank": 262442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16752,
    "Rank": 262487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16753,
    "Rank": 262527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16754,
    "Rank": 262602,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16755,
    "Rank": 262676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16756,
    "Rank": 262681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16757,
    "Rank": 262779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16758,
    "Rank": 262822,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16759,
    "Rank": 262832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16760,
    "Rank": 262833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16761,
    "Rank": 262839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16762,
    "Rank": 262881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16763,
    "Rank": 263005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16764,
    "Rank": 263042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16765,
    "Rank": 263059,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16766,
    "Rank": 263215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16767,
    "Rank": 263231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16768,
    "Rank": 263249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16769,
    "Rank": 263251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16770,
    "Rank": 263283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16771,
    "Rank": 263296,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16772,
    "Rank": 263386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16773,
    "Rank": 263450,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16774,
    "Rank": 263458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16775,
    "Rank": 263497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16776,
    "Rank": 263548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16777,
    "Rank": 263630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16778,
    "Rank": 263677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16779,
    "Rank": 263719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16780,
    "Rank": 263744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16781,
    "Rank": 263780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16782,
    "Rank": 263845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16783,
    "Rank": 263897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16784,
    "Rank": 263908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16785,
    "Rank": 263941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16786,
    "Rank": 263942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16787,
    "Rank": 263955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16788,
    "Rank": 263998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16789,
    "Rank": 264004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16790,
    "Rank": 264086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16791,
    "Rank": 264088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16792,
    "Rank": 264192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16793,
    "Rank": 264203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16794,
    "Rank": 264308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16795,
    "Rank": 264380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16796,
    "Rank": 264525,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16797,
    "Rank": 264535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16798,
    "Rank": 264548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16799,
    "Rank": 264556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16800,
    "Rank": 264577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16801,
    "Rank": 264588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16802,
    "Rank": 264642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16803,
    "Rank": 264730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16804,
    "Rank": 264749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16805,
    "Rank": 264855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16806,
    "Rank": 264863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16807,
    "Rank": 264892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16808,
    "Rank": 264903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16809,
    "Rank": 264912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16810,
    "Rank": 264917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16811,
    "Rank": 265012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16812,
    "Rank": 265081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16813,
    "Rank": 265168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16814,
    "Rank": 265618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16815,
    "Rank": 265631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16816,
    "Rank": 265665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16817,
    "Rank": 265685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16818,
    "Rank": 265691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16819,
    "Rank": 265700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16820,
    "Rank": 265717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16821,
    "Rank": 265735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16822,
    "Rank": 265748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16823,
    "Rank": 265786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16824,
    "Rank": 265846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16825,
    "Rank": 265874,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16826,
    "Rank": 265891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16827,
    "Rank": 265978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16828,
    "Rank": 266043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16829,
    "Rank": 266058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16830,
    "Rank": 266090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16831,
    "Rank": 266108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16832,
    "Rank": 266147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16833,
    "Rank": 266184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16834,
    "Rank": 266206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16835,
    "Rank": 266240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16836,
    "Rank": 266294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16837,
    "Rank": 266326,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16838,
    "Rank": 266395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16839,
    "Rank": 266437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16840,
    "Rank": 266441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16841,
    "Rank": 266464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16842,
    "Rank": 266487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16843,
    "Rank": 266513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16844,
    "Rank": 266613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16845,
    "Rank": 266630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16846,
    "Rank": 266634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16847,
    "Rank": 266799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16848,
    "Rank": 266822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16849,
    "Rank": 266845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16850,
    "Rank": 266891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16851,
    "Rank": 266912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16852,
    "Rank": 266917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16853,
    "Rank": 266962,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16854,
    "Rank": 267001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16855,
    "Rank": 267081,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16856,
    "Rank": 267151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16857,
    "Rank": 267258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16858,
    "Rank": 267297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16859,
    "Rank": 267379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16860,
    "Rank": 267439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16861,
    "Rank": 267452,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16862,
    "Rank": 267466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16863,
    "Rank": 267698,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16864,
    "Rank": 267708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16865,
    "Rank": 267845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16866,
    "Rank": 267848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16867,
    "Rank": 267871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16868,
    "Rank": 268091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16869,
    "Rank": 268098,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16870,
    "Rank": 268134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16871,
    "Rank": 268146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16872,
    "Rank": 268197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16873,
    "Rank": 268258,
    "Allotted Quota": "All India",
    "Allotted Institute": "KARNATAK INST. OF MEDICAL SC.,HUBLI,VIDYANAGAR HUBBALLI, Karnataka, 580021",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16874,
    "Rank": 268331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16875,
    "Rank": 268397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16876,
    "Rank": 268427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16877,
    "Rank": 268447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16878,
    "Rank": 268492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16879,
    "Rank": 268585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16880,
    "Rank": 268683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16881,
    "Rank": 268685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16882,
    "Rank": 268764,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16883,
    "Rank": 268787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16884,
    "Rank": 268900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16885,
    "Rank": 268983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16886,
    "Rank": 269031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16887,
    "Rank": 269087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16888,
    "Rank": 269129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16889,
    "Rank": 269182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16890,
    "Rank": 269289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16891,
    "Rank": 269335,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16892,
    "Rank": 269345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16893,
    "Rank": 269354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16894,
    "Rank": 269394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16895,
    "Rank": 269435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16896,
    "Rank": 269439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16897,
    "Rank": 269459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16898,
    "Rank": 269482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16899,
    "Rank": 269532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16900,
    "Rank": 269557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16901,
    "Rank": 269668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16902,
    "Rank": 269669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16903,
    "Rank": 269719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16904,
    "Rank": 269777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16905,
    "Rank": 269820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16906,
    "Rank": 269825,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16907,
    "Rank": 269868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16908,
    "Rank": 269974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16909,
    "Rank": 269975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16910,
    "Rank": 270034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16911,
    "Rank": 270083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16912,
    "Rank": 270094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16913,
    "Rank": 270150,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Rishikesh ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES, RISHIKESH\nUttarakhand - 249203, Uttarakhand, 249203",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16914,
    "Rank": 270222,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16915,
    "Rank": 270271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16916,
    "Rank": 270366,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16917,
    "Rank": 270462,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16918,
    "Rank": 270530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16919,
    "Rank": 270542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16920,
    "Rank": 270850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16921,
    "Rank": 271064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16922,
    "Rank": 271182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16923,
    "Rank": 271184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16924,
    "Rank": 271208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16925,
    "Rank": 271327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16926,
    "Rank": 271445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16927,
    "Rank": 271502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16928,
    "Rank": 271567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16929,
    "Rank": 271609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16930,
    "Rank": 271775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16931,
    "Rank": 271787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16932,
    "Rank": 271788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16933,
    "Rank": 271934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16934,
    "Rank": 272037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16935,
    "Rank": 272073,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16936,
    "Rank": 272099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16937,
    "Rank": 272130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16938,
    "Rank": 272230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16939,
    "Rank": 272236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16940,
    "Rank": 272239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16941,
    "Rank": 272274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16942,
    "Rank": 272336,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16943,
    "Rank": 272484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16944,
    "Rank": 272501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16945,
    "Rank": 272514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16946,
    "Rank": 272600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16947,
    "Rank": 272715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16948,
    "Rank": 272761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16949,
    "Rank": 272814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16950,
    "Rank": 272880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16951,
    "Rank": 273102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16952,
    "Rank": 273123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16953,
    "Rank": 273293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16954,
    "Rank": 273327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16955,
    "Rank": 273349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16956,
    "Rank": 273436,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16957,
    "Rank": 273440,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16958,
    "Rank": 273444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16959,
    "Rank": 273472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16960,
    "Rank": 273508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16961,
    "Rank": 273535,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 22 )"
  },
  {
    "SNo": 16962,
    "Rank": 273537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16963,
    "Rank": 273587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16964,
    "Rank": 273645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16965,
    "Rank": 273825,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16966,
    "Rank": 273860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16967,
    "Rank": 273882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16968,
    "Rank": 273893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16969,
    "Rank": 274127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16970,
    "Rank": 274150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16971,
    "Rank": 274204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16972,
    "Rank": 274218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16973,
    "Rank": 274246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16974,
    "Rank": 274310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16975,
    "Rank": 274328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16976,
    "Rank": 274423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16977,
    "Rank": 274576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16978,
    "Rank": 274610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16979,
    "Rank": 274707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16980,
    "Rank": 274749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16981,
    "Rank": 274799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16982,
    "Rank": 274932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16983,
    "Rank": 274990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16984,
    "Rank": 275176,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16985,
    "Rank": 275177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16986,
    "Rank": 275200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16987,
    "Rank": 275218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16988,
    "Rank": 275255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16989,
    "Rank": 275305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16990,
    "Rank": 275310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16991,
    "Rank": 275348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16992,
    "Rank": 275411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16993,
    "Rank": 275558,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16994,
    "Rank": 275594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16995,
    "Rank": 275677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16996,
    "Rank": 275730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16997,
    "Rank": 275812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16998,
    "Rank": 275813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 16999,
    "Rank": 275850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17000,
    "Rank": 275915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17001,
    "Rank": 275925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17002,
    "Rank": 275932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17003,
    "Rank": 275961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17004,
    "Rank": 275996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17005,
    "Rank": 276049,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17006,
    "Rank": 276053,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17007,
    "Rank": 276155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17008,
    "Rank": 276321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17009,
    "Rank": 276349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17010,
    "Rank": 276554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17011,
    "Rank": 276616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17012,
    "Rank": 276682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17013,
    "Rank": 276700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17014,
    "Rank": 276747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17015,
    "Rank": 276882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17016,
    "Rank": 276897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17017,
    "Rank": 276909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17018,
    "Rank": 276976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17019,
    "Rank": 276994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17020,
    "Rank": 277040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17021,
    "Rank": 277059,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17022,
    "Rank": 277093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17023,
    "Rank": 277146,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17024,
    "Rank": 277172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17025,
    "Rank": 277205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17026,
    "Rank": 277219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17027,
    "Rank": 277365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17028,
    "Rank": 277382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17029,
    "Rank": 277420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17030,
    "Rank": 277453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17031,
    "Rank": 277460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17032,
    "Rank": 277472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17033,
    "Rank": 277491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17034,
    "Rank": 277541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17035,
    "Rank": 277641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17036,
    "Rank": 277704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17037,
    "Rank": 277791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17038,
    "Rank": 277806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt institute of Medcial Sciences, GREATER NOIDA,GOVERNMENT INSTITUTE OF MEDICAL SCIENCES GREATER NOIDA GAUTAM BUDH NAGAR UTTAR PRADESH,\nUttar Pradesh, 201310",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17039,
    "Rank": 277835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17040,
    "Rank": 277935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17041,
    "Rank": 277987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17042,
    "Rank": 277995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17043,
    "Rank": 278038,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17044,
    "Rank": 278051,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17045,
    "Rank": 278056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17046,
    "Rank": 278204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17047,
    "Rank": 278324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17048,
    "Rank": 278346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17049,
    "Rank": 278482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17050,
    "Rank": 278509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17051,
    "Rank": 278630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17052,
    "Rank": 278646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17053,
    "Rank": 278647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17054,
    "Rank": 278731,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing BHU, VARANASI,The Director Institute of Medical Sciences Banaras Hindu University, Uttar Pradesh, 221005",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17055,
    "Rank": 278868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17056,
    "Rank": 278908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17057,
    "Rank": 278912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17058,
    "Rank": 278965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17059,
    "Rank": 279021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17060,
    "Rank": 279041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17061,
    "Rank": 279049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17062,
    "Rank": 279058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17063,
    "Rank": 279098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17064,
    "Rank": 279128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17065,
    "Rank": 279169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17066,
    "Rank": 279205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17067,
    "Rank": 279263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17068,
    "Rank": 279360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17069,
    "Rank": 279364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17070,
    "Rank": 279410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17071,
    "Rank": 279431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17072,
    "Rank": 279526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17073,
    "Rank": 279696,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLL., NAGPUR,MAYO HOSPITAL CENTRAL AVENUE ROAD NEAR RAM ZULA NAGPUR, Maharashtra, 440018",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17074,
    "Rank": 279702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17075,
    "Rank": 279791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17076,
    "Rank": 279833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17077,
    "Rank": 279881,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17078,
    "Rank": 279966,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17079,
    "Rank": 280030,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17080,
    "Rank": 280415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17081,
    "Rank": 280468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17082,
    "Rank": 280475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17083,
    "Rank": 280543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17084,
    "Rank": 280557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17085,
    "Rank": 280573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17086,
    "Rank": 280639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17087,
    "Rank": 280722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17088,
    "Rank": 280798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17089,
    "Rank": 280799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17090,
    "Rank": 280804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17091,
    "Rank": 280853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17092,
    "Rank": 280874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17093,
    "Rank": 280918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17094,
    "Rank": 280933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17095,
    "Rank": 280939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17096,
    "Rank": 280948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17097,
    "Rank": 281091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17098,
    "Rank": 281142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17099,
    "Rank": 281159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17100,
    "Rank": 281182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17101,
    "Rank": 281221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17102,
    "Rank": 281251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17103,
    "Rank": 281267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17104,
    "Rank": 281323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17105,
    "Rank": 281337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17106,
    "Rank": 281341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17107,
    "Rank": 281382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17108,
    "Rank": 281387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17109,
    "Rank": 281490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17110,
    "Rank": 281604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17111,
    "Rank": 281636,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17112,
    "Rank": 281810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17113,
    "Rank": 281867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17114,
    "Rank": 281952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17115,
    "Rank": 282200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17116,
    "Rank": 282415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17117,
    "Rank": 282425,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17118,
    "Rank": 282531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17119,
    "Rank": 282545,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17120,
    "Rank": 282573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17121,
    "Rank": 282707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17122,
    "Rank": 282846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17123,
    "Rank": 282945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17124,
    "Rank": 283096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17125,
    "Rank": 283174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17126,
    "Rank": 283331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17127,
    "Rank": 283346,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17128,
    "Rank": 283350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17129,
    "Rank": 283470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17130,
    "Rank": 283506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17131,
    "Rank": 283637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17132,
    "Rank": 283663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17133,
    "Rank": 283807,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing LHMC, New Delhi,College of Nursing Lady Hardinge Medical College & Associated Hospitals New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17134,
    "Rank": 283943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17135,
    "Rank": 283976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17136,
    "Rank": 284016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17137,
    "Rank": 284033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17138,
    "Rank": 284045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17139,
    "Rank": 284060,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17140,
    "Rank": 284281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17141,
    "Rank": 284419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17142,
    "Rank": 284697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17143,
    "Rank": 284735,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17144,
    "Rank": 284759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17145,
    "Rank": 284819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17146,
    "Rank": 284850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17147,
    "Rank": 284902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17148,
    "Rank": 284912,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17149,
    "Rank": 284960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17150,
    "Rank": 285027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17151,
    "Rank": 285036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17152,
    "Rank": 285091,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17153,
    "Rank": 285114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17154,
    "Rank": 285294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17155,
    "Rank": 285312,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17156,
    "Rank": 285351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17157,
    "Rank": 285390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17158,
    "Rank": 285403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17159,
    "Rank": 285498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17160,
    "Rank": 285539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17161,
    "Rank": 285542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17162,
    "Rank": 285686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17163,
    "Rank": 285689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17164,
    "Rank": 285709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17165,
    "Rank": 285805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17166,
    "Rank": 285826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17167,
    "Rank": 285861,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17168,
    "Rank": 285897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17169,
    "Rank": 285917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17170,
    "Rank": 285969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17171,
    "Rank": 286218,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Dr. RML Hospital, New Delhi,Baba Kharak Singh Marg College of Nursing Dr RML Hospital, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17172,
    "Rank": 286223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17173,
    "Rank": 286254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17174,
    "Rank": 286286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17175,
    "Rank": 286318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17176,
    "Rank": 286324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17177,
    "Rank": 286346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17178,
    "Rank": 286361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17179,
    "Rank": 286497,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 13 )"
  },
  {
    "SNo": 17180,
    "Rank": 286560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17181,
    "Rank": 286682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17182,
    "Rank": 286699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17183,
    "Rank": 286729,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17184,
    "Rank": 286740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17185,
    "Rank": 286837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17186,
    "Rank": 286899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17187,
    "Rank": 286901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17188,
    "Rank": 287005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17189,
    "Rank": 287022,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17190,
    "Rank": 287170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17191,
    "Rank": 287192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17192,
    "Rank": 287213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17193,
    "Rank": 287289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17194,
    "Rank": 287322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17195,
    "Rank": 287326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17196,
    "Rank": 287387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17197,
    "Rank": 287456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17198,
    "Rank": 287519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17199,
    "Rank": 287545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17200,
    "Rank": 287571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17201,
    "Rank": 287577,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17202,
    "Rank": 287605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17203,
    "Rank": 287615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17204,
    "Rank": 287700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17205,
    "Rank": 287705,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17206,
    "Rank": 287716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17207,
    "Rank": 287731,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17208,
    "Rank": 287761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17209,
    "Rank": 287803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17210,
    "Rank": 287837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17211,
    "Rank": 287870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17212,
    "Rank": 287942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17213,
    "Rank": 287993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Parbhani,ITI CAMPUS, BEHIND DSM COLLEGE, JINTUR ROAD, PARBHANI - 431401, Maharashtra, 431401",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17214,
    "Rank": 288063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17215,
    "Rank": 288104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17216,
    "Rank": 288121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17217,
    "Rank": 288358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17218,
    "Rank": 288363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17219,
    "Rank": 288550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17220,
    "Rank": 288559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17221,
    "Rank": 288605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17222,
    "Rank": 288616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17223,
    "Rank": 288697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17224,
    "Rank": 288715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17225,
    "Rank": 288834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17226,
    "Rank": 288840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17227,
    "Rank": 288957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17228,
    "Rank": 289010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17229,
    "Rank": 289143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17230,
    "Rank": 289265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17231,
    "Rank": 289336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17232,
    "Rank": 289457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17233,
    "Rank": 289499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17234,
    "Rank": 289505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17235,
    "Rank": 289547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17236,
    "Rank": 289558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17237,
    "Rank": 289597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17238,
    "Rank": 289638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17239,
    "Rank": 289756,
    "Allotted Quota": "All India",
    "Allotted Institute": "KGMC, LUCKNOW,SHAHMINA ROAD, CHOWK, LUCKNOW, Uttar Pradesh, 226003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17240,
    "Rank": 289856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17241,
    "Rank": 289908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17242,
    "Rank": 289911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17243,
    "Rank": 289998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17244,
    "Rank": 290104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17245,
    "Rank": 290193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17246,
    "Rank": 290251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17247,
    "Rank": 290369,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17248,
    "Rank": 290442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17249,
    "Rank": 290457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17250,
    "Rank": 290482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17251,
    "Rank": 290486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17252,
    "Rank": 290649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17253,
    "Rank": 290706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17254,
    "Rank": 290731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17255,
    "Rank": 290773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17256,
    "Rank": 290867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17257,
    "Rank": 290891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17258,
    "Rank": 290911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17259,
    "Rank": 290966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17260,
    "Rank": 291017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17261,
    "Rank": 291038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17262,
    "Rank": 291050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17263,
    "Rank": 291124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17264,
    "Rank": 291258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17265,
    "Rank": 291279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17266,
    "Rank": 291304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17267,
    "Rank": 291348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17268,
    "Rank": 291444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17269,
    "Rank": 291462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17270,
    "Rank": 291525,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17271,
    "Rank": 291679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17272,
    "Rank": 291730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17273,
    "Rank": 291784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17274,
    "Rank": 291843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17275,
    "Rank": 292069,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17276,
    "Rank": 292191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17277,
    "Rank": 292208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17278,
    "Rank": 292219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17279,
    "Rank": 292244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17280,
    "Rank": 292262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17281,
    "Rank": 292290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17282,
    "Rank": 292389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17283,
    "Rank": 292421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17284,
    "Rank": 292446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17285,
    "Rank": 292589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17286,
    "Rank": 292596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17287,
    "Rank": 292651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17288,
    "Rank": 292710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17289,
    "Rank": 292715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17290,
    "Rank": 292721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17291,
    "Rank": 292738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17292,
    "Rank": 292758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17293,
    "Rank": 292798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17294,
    "Rank": 292820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17295,
    "Rank": 292840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17296,
    "Rank": 292872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17297,
    "Rank": 292904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17298,
    "Rank": 293068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17299,
    "Rank": 293128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17300,
    "Rank": 293138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17301,
    "Rank": 293176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17302,
    "Rank": 293210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17303,
    "Rank": 293221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17304,
    "Rank": 293256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17305,
    "Rank": 293266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17306,
    "Rank": 293336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17307,
    "Rank": 293355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17308,
    "Rank": 293405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17309,
    "Rank": 293409,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17310,
    "Rank": 293423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17311,
    "Rank": 293424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17312,
    "Rank": 293458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17313,
    "Rank": 293688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17314,
    "Rank": 293740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17315,
    "Rank": 293749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17316,
    "Rank": 293785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17317,
    "Rank": 293849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17318,
    "Rank": 293890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17319,
    "Rank": 293952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17320,
    "Rank": 293983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17321,
    "Rank": 294047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17322,
    "Rank": 294063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17323,
    "Rank": 294106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17324,
    "Rank": 294174,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17325,
    "Rank": 294280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17326,
    "Rank": 294286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17327,
    "Rank": 294437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17328,
    "Rank": 294472,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17329,
    "Rank": 294561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17330,
    "Rank": 294565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17331,
    "Rank": 294600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17332,
    "Rank": 294773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17333,
    "Rank": 294947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17334,
    "Rank": 295032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17335,
    "Rank": 295045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17336,
    "Rank": 295115,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17337,
    "Rank": 295144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17338,
    "Rank": 295149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17339,
    "Rank": 295188,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17340,
    "Rank": 295318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17341,
    "Rank": 295477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17342,
    "Rank": 295550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17343,
    "Rank": 295555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17344,
    "Rank": 295653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17345,
    "Rank": 295673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17346,
    "Rank": 295830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17347,
    "Rank": 295876,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17348,
    "Rank": 295966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17349,
    "Rank": 295975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17350,
    "Rank": 296042,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17351,
    "Rank": 296054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17352,
    "Rank": 296093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17353,
    "Rank": 296124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17354,
    "Rank": 296163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17355,
    "Rank": 296164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17356,
    "Rank": 296168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17357,
    "Rank": 296207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17358,
    "Rank": 296312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17359,
    "Rank": 296345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17360,
    "Rank": 296385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17361,
    "Rank": 296509,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17362,
    "Rank": 296544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17363,
    "Rank": 296590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17364,
    "Rank": 296591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17365,
    "Rank": 296592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17366,
    "Rank": 296707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17367,
    "Rank": 296738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17368,
    "Rank": 296801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17369,
    "Rank": 296876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17370,
    "Rank": 296958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17371,
    "Rank": 296987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17372,
    "Rank": 297082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17373,
    "Rank": 297099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17374,
    "Rank": 297128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17375,
    "Rank": 297150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17376,
    "Rank": 297158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17377,
    "Rank": 297182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17378,
    "Rank": 297236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17379,
    "Rank": 297291,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17380,
    "Rank": 297570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17381,
    "Rank": 297618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17382,
    "Rank": 297694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17383,
    "Rank": 297807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17384,
    "Rank": 297810,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17385,
    "Rank": 297832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17386,
    "Rank": 298002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17387,
    "Rank": 298018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17388,
    "Rank": 298074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17389,
    "Rank": 298129,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17390,
    "Rank": 298134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17391,
    "Rank": 298165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17392,
    "Rank": 298250,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17393,
    "Rank": 298251,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17394,
    "Rank": 298253,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17395,
    "Rank": 298291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17396,
    "Rank": 298404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17397,
    "Rank": 298466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17398,
    "Rank": 298734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17399,
    "Rank": 298740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17400,
    "Rank": 298806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17401,
    "Rank": 298821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17402,
    "Rank": 298840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17403,
    "Rank": 298848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17404,
    "Rank": 298903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17405,
    "Rank": 298941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17406,
    "Rank": 298981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17407,
    "Rank": 299056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17408,
    "Rank": 299089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17409,
    "Rank": 299109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17410,
    "Rank": 299119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17411,
    "Rank": 299190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17412,
    "Rank": 299298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17413,
    "Rank": 299326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17414,
    "Rank": 299332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17415,
    "Rank": 299354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17416,
    "Rank": 299421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17417,
    "Rank": 299471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17418,
    "Rank": 299516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17419,
    "Rank": 299554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17420,
    "Rank": 299564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17421,
    "Rank": 299580,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.RAJENDRA PRASAD MC, TANDA,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda, H.P., Himachal Pradesh, 176002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17422,
    "Rank": 299602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17423,
    "Rank": 299607,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17424,
    "Rank": 299682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17425,
    "Rank": 299733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17426,
    "Rank": 299781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17427,
    "Rank": 299833,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17428,
    "Rank": 299872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17429,
    "Rank": 299880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17430,
    "Rank": 299881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17431,
    "Rank": 300008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17432,
    "Rank": 300125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17433,
    "Rank": 300195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17434,
    "Rank": 300204,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17435,
    "Rank": 300239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17436,
    "Rank": 300299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17437,
    "Rank": 300337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17438,
    "Rank": 300434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17439,
    "Rank": 300609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17440,
    "Rank": 300657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17441,
    "Rank": 300664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17442,
    "Rank": 300666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17443,
    "Rank": 300692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17444,
    "Rank": 300702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17445,
    "Rank": 300765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17446,
    "Rank": 301056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17447,
    "Rank": 301067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17448,
    "Rank": 301132,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17449,
    "Rank": 301138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17450,
    "Rank": 301180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17451,
    "Rank": 301185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17452,
    "Rank": 301243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17453,
    "Rank": 301285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17454,
    "Rank": 301304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17455,
    "Rank": 301356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17456,
    "Rank": 301470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17457,
    "Rank": 301516,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17458,
    "Rank": 301569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17459,
    "Rank": 301591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17460,
    "Rank": 301593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17461,
    "Rank": 301601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17462,
    "Rank": 301708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17463,
    "Rank": 301710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17464,
    "Rank": 301717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17465,
    "Rank": 301725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17466,
    "Rank": 301738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17467,
    "Rank": 301792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17468,
    "Rank": 301925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17469,
    "Rank": 301950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17470,
    "Rank": 302016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17471,
    "Rank": 302121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17472,
    "Rank": 302204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17473,
    "Rank": 302267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17474,
    "Rank": 302303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17475,
    "Rank": 302347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17476,
    "Rank": 302374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17477,
    "Rank": 302400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17478,
    "Rank": 302435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17479,
    "Rank": 302436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17480,
    "Rank": 302488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17481,
    "Rank": 302496,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17482,
    "Rank": 302651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17483,
    "Rank": 302662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17484,
    "Rank": 302733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17485,
    "Rank": 302737,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17486,
    "Rank": 302832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17487,
    "Rank": 302955,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17488,
    "Rank": 303007,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17489,
    "Rank": 303019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17490,
    "Rank": 303118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17491,
    "Rank": 303132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17492,
    "Rank": 303142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17493,
    "Rank": 303169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17494,
    "Rank": 303177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17495,
    "Rank": 303377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17496,
    "Rank": 303617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17497,
    "Rank": 303642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17498,
    "Rank": 303671,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17499,
    "Rank": 303699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17500,
    "Rank": 303728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17501,
    "Rank": 303746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17502,
    "Rank": 303884,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17503,
    "Rank": 303893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17504,
    "Rank": 303962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17505,
    "Rank": 304010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17506,
    "Rank": 304193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17507,
    "Rank": 304203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17508,
    "Rank": 304226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17509,
    "Rank": 304343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17510,
    "Rank": 304429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17511,
    "Rank": 304494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17512,
    "Rank": 304547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17513,
    "Rank": 304548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17514,
    "Rank": 304567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17515,
    "Rank": 304649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17516,
    "Rank": 304651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17517,
    "Rank": 304788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17518,
    "Rank": 304843,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17519,
    "Rank": 304867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17520,
    "Rank": 304877,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17521,
    "Rank": 304879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17522,
    "Rank": 304896,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17523,
    "Rank": 304956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17524,
    "Rank": 305013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17525,
    "Rank": 305075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17526,
    "Rank": 305076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17527,
    "Rank": 305190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17528,
    "Rank": 305342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17529,
    "Rank": 305441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17530,
    "Rank": 305474,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17531,
    "Rank": 305683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17532,
    "Rank": 305688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17533,
    "Rank": 305702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17534,
    "Rank": 305836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17535,
    "Rank": 305941,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17536,
    "Rank": 306094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17537,
    "Rank": 306158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17538,
    "Rank": 306195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17539,
    "Rank": 306303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17540,
    "Rank": 306316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17541,
    "Rank": 306367,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17542,
    "Rank": 306452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17543,
    "Rank": 306508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17544,
    "Rank": 306743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17545,
    "Rank": 306920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17546,
    "Rank": 306925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17547,
    "Rank": 306956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17548,
    "Rank": 306976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17549,
    "Rank": 307107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17550,
    "Rank": 307149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17551,
    "Rank": 307332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17552,
    "Rank": 307444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17553,
    "Rank": 307537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17554,
    "Rank": 307542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17555,
    "Rank": 307586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17556,
    "Rank": 307590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17557,
    "Rank": 307594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17558,
    "Rank": 307655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17559,
    "Rank": 307662,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17560,
    "Rank": 307690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17561,
    "Rank": 307757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17562,
    "Rank": 307773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17563,
    "Rank": 307808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17564,
    "Rank": 307809,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17565,
    "Rank": 307815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17566,
    "Rank": 307832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17567,
    "Rank": 307982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17568,
    "Rank": 307984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17569,
    "Rank": 308003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17570,
    "Rank": 308054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17571,
    "Rank": 308086,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17572,
    "Rank": 308112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17573,
    "Rank": 308121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17574,
    "Rank": 308138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17575,
    "Rank": 308209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17576,
    "Rank": 308293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17577,
    "Rank": 308363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17578,
    "Rank": 308377,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17579,
    "Rank": 308626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17580,
    "Rank": 308635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17581,
    "Rank": 308662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17582,
    "Rank": 308810,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. Government Medical College, Pune,Sassoon Hospital Compound, Near Pune Railway station J.P. Narayan Road, Pune, Maharashtra, 411001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17583,
    "Rank": 308839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17584,
    "Rank": 308962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17585,
    "Rank": 309047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17586,
    "Rank": 309085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17587,
    "Rank": 309093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17588,
    "Rank": 309102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17589,
    "Rank": 309125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17590,
    "Rank": 309190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17591,
    "Rank": 309292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17592,
    "Rank": 309412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17593,
    "Rank": 309448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17594,
    "Rank": 309451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17595,
    "Rank": 309491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17596,
    "Rank": 309593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17597,
    "Rank": 309641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17598,
    "Rank": 309652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17599,
    "Rank": 309720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17600,
    "Rank": 309726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17601,
    "Rank": 309863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17602,
    "Rank": 309876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17603,
    "Rank": 309999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17604,
    "Rank": 310016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17605,
    "Rank": 310102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17606,
    "Rank": 310121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17607,
    "Rank": 310210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17608,
    "Rank": 310233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17609,
    "Rank": 310259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17610,
    "Rank": 310348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17611,
    "Rank": 310425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17612,
    "Rank": 310483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17613,
    "Rank": 310518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17614,
    "Rank": 310694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17615,
    "Rank": 310724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17616,
    "Rank": 310802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17617,
    "Rank": 310869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17618,
    "Rank": 310943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17619,
    "Rank": 310966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17620,
    "Rank": 311065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17621,
    "Rank": 311243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17622,
    "Rank": 311264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17623,
    "Rank": 311320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17624,
    "Rank": 311366,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17625,
    "Rank": 311400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17626,
    "Rank": 311411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17627,
    "Rank": 311603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17628,
    "Rank": 311642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17629,
    "Rank": 311697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17630,
    "Rank": 311732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17631,
    "Rank": 311911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17632,
    "Rank": 312003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17633,
    "Rank": 312063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17634,
    "Rank": 312218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17635,
    "Rank": 312257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17636,
    "Rank": 312298,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17637,
    "Rank": 312323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17638,
    "Rank": 312356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17639,
    "Rank": 312528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17640,
    "Rank": 312637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17641,
    "Rank": 312708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17642,
    "Rank": 312729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17643,
    "Rank": 312790,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17644,
    "Rank": 312867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17645,
    "Rank": 312958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17646,
    "Rank": 312971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17647,
    "Rank": 312990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17648,
    "Rank": 313120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17649,
    "Rank": 313202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17650,
    "Rank": 313287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17651,
    "Rank": 313364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17652,
    "Rank": 313535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17653,
    "Rank": 313584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17654,
    "Rank": 313592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17655,
    "Rank": 313595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17656,
    "Rank": 313812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17657,
    "Rank": 313829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17658,
    "Rank": 313841,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESIC Dental College and Hospital,ESIC Hospital, Sector 15, Rohini, Delhi (NCT), 110089",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17659,
    "Rank": 313875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17660,
    "Rank": 313879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17661,
    "Rank": 313884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17662,
    "Rank": 313907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17663,
    "Rank": 314228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17664,
    "Rank": 314241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17665,
    "Rank": 314416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17666,
    "Rank": 314430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17667,
    "Rank": 314456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17668,
    "Rank": 314481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17669,
    "Rank": 314484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17670,
    "Rank": 314536,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17671,
    "Rank": 314630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17672,
    "Rank": 314661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17673,
    "Rank": 314869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17674,
    "Rank": 314907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17675,
    "Rank": 314962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17676,
    "Rank": 314989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17677,
    "Rank": 315004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17678,
    "Rank": 315023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17679,
    "Rank": 315112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17680,
    "Rank": 315129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17681,
    "Rank": 315177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17682,
    "Rank": 315211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17683,
    "Rank": 315245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17684,
    "Rank": 315391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17685,
    "Rank": 315398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17686,
    "Rank": 315453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17687,
    "Rank": 315543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17688,
    "Rank": 315567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17689,
    "Rank": 315570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17690,
    "Rank": 315580,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17691,
    "Rank": 315652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17692,
    "Rank": 315695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17693,
    "Rank": 315698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17694,
    "Rank": 315757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17695,
    "Rank": 315765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17696,
    "Rank": 315800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17697,
    "Rank": 315812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17698,
    "Rank": 315837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17699,
    "Rank": 315838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17700,
    "Rank": 315881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17701,
    "Rank": 315892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17702,
    "Rank": 315978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17703,
    "Rank": 316061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17704,
    "Rank": 316071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17705,
    "Rank": 316186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17706,
    "Rank": 316229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17707,
    "Rank": 316275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17708,
    "Rank": 316415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17709,
    "Rank": 316420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17710,
    "Rank": 316504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17711,
    "Rank": 316542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17712,
    "Rank": 316726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17713,
    "Rank": 316860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17714,
    "Rank": 316893,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17715,
    "Rank": 316983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17716,
    "Rank": 317008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17717,
    "Rank": 317036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17718,
    "Rank": 317083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17719,
    "Rank": 317096,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing Safdarjung Hospital, New Delhi,College of Nursing VMMC and Safdarjang Hospital New Delhi, Delhi (NCT), 110029 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17720,
    "Rank": 317098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17721,
    "Rank": 317102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17722,
    "Rank": 317184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17723,
    "Rank": 317195,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17724,
    "Rank": 317214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17725,
    "Rank": 317227,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17726,
    "Rank": 317388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17727,
    "Rank": 317405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17728,
    "Rank": 317447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17729,
    "Rank": 317561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17730,
    "Rank": 317693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17731,
    "Rank": 317746,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17732,
    "Rank": 317862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17733,
    "Rank": 318029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17734,
    "Rank": 318132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17735,
    "Rank": 318202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17736,
    "Rank": 318234,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17737,
    "Rank": 318422,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17738,
    "Rank": 318454,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17739,
    "Rank": 318457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17740,
    "Rank": 318546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17741,
    "Rank": 318610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17742,
    "Rank": 318701,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17743,
    "Rank": 318716,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17744,
    "Rank": 318746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17745,
    "Rank": 318850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17746,
    "Rank": 318958,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17747,
    "Rank": 318966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17748,
    "Rank": 319072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17749,
    "Rank": 319229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17750,
    "Rank": 319285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17751,
    "Rank": 319357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17752,
    "Rank": 319363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17753,
    "Rank": 319391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17754,
    "Rank": 319422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17755,
    "Rank": 319476,
    "Allotted Quota": "B.Sc Nursing All India",
    "Allotted Institute": "College of Nursing RAK CON, New Delhi,Rajkumari Amrit Kaur College of Nursing Lajpat Nagar IV Near Moolchand Metro Station, Delhi (NCT), 110024 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17756,
    "Rank": 319491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17757,
    "Rank": 319533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17758,
    "Rank": 319558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17759,
    "Rank": 319569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17760,
    "Rank": 319570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17761,
    "Rank": 319644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17762,
    "Rank": 319651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17763,
    "Rank": 319675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17764,
    "Rank": 319699,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17765,
    "Rank": 319754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17766,
    "Rank": 319766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17767,
    "Rank": 319829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17768,
    "Rank": 319830,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17769,
    "Rank": 319972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17770,
    "Rank": 320042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17771,
    "Rank": 320126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17772,
    "Rank": 320160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17773,
    "Rank": 320163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17774,
    "Rank": 320198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17775,
    "Rank": 320240,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17776,
    "Rank": 320321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17777,
    "Rank": 320332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17778,
    "Rank": 320333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17779,
    "Rank": 320341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17780,
    "Rank": 320496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17781,
    "Rank": 320647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17782,
    "Rank": 320689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17783,
    "Rank": 320755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17784,
    "Rank": 320772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17785,
    "Rank": 320921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17786,
    "Rank": 321079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17787,
    "Rank": 321418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17788,
    "Rank": 321500,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17789,
    "Rank": 321591,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17790,
    "Rank": 321811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17791,
    "Rank": 321834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17792,
    "Rank": 321881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17793,
    "Rank": 321952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17794,
    "Rank": 321984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17795,
    "Rank": 322258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17796,
    "Rank": 322330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17797,
    "Rank": 322347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17798,
    "Rank": 322367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17799,
    "Rank": 322404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17800,
    "Rank": 322491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17801,
    "Rank": 322532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17802,
    "Rank": 322546,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17803,
    "Rank": 322556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17804,
    "Rank": 322572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17805,
    "Rank": 322621,
    "Allotted Quota": "All India",
    "Allotted Institute": "SH VASANT RAO NAIK GOVT.M.C.,YAVATMAL,CIVIL LINES YAVATMAL,\nMaharashtra, 445001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17806,
    "Rank": 322647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17807,
    "Rank": 322662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17808,
    "Rank": 322814,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17809,
    "Rank": 322907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17810,
    "Rank": 322980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17811,
    "Rank": 323070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17812,
    "Rank": 323099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17813,
    "Rank": 323151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17814,
    "Rank": 323248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17815,
    "Rank": 323260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17816,
    "Rank": 323262,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17817,
    "Rank": 323280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17818,
    "Rank": 323365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17819,
    "Rank": 323512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17820,
    "Rank": 323553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17821,
    "Rank": 323611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17822,
    "Rank": 323691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17823,
    "Rank": 323863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17824,
    "Rank": 323901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17825,
    "Rank": 323953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17826,
    "Rank": 323997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17827,
    "Rank": 324056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17828,
    "Rank": 324076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17829,
    "Rank": 324265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17830,
    "Rank": 324341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17831,
    "Rank": 324410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17832,
    "Rank": 324416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17833,
    "Rank": 324461,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17834,
    "Rank": 324463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17835,
    "Rank": 324525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17836,
    "Rank": 324565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17837,
    "Rank": 324627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17838,
    "Rank": 324711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17839,
    "Rank": 324739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17840,
    "Rank": 324828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17841,
    "Rank": 324902,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17842,
    "Rank": 325022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17843,
    "Rank": 325230,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17844,
    "Rank": 325237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17845,
    "Rank": 325409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17846,
    "Rank": 325424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17847,
    "Rank": 325451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17848,
    "Rank": 325455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17849,
    "Rank": 325496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17850,
    "Rank": 325521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17851,
    "Rank": 325562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17852,
    "Rank": 325659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17853,
    "Rank": 325669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17854,
    "Rank": 325838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17855,
    "Rank": 325895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17856,
    "Rank": 326015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17857,
    "Rank": 326022,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17858,
    "Rank": 326073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17859,
    "Rank": 326160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17860,
    "Rank": 326189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17861,
    "Rank": 326203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17862,
    "Rank": 326215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17863,
    "Rank": 326230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17864,
    "Rank": 326254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17865,
    "Rank": 326323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17866,
    "Rank": 326433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17867,
    "Rank": 326604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17868,
    "Rank": 326647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17869,
    "Rank": 326711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17870,
    "Rank": 326786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17871,
    "Rank": 326988,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17872,
    "Rank": 327031,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17873,
    "Rank": 327073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17874,
    "Rank": 327269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17875,
    "Rank": 327270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17876,
    "Rank": 327348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17877,
    "Rank": 327446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17878,
    "Rank": 327557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17879,
    "Rank": 327600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17880,
    "Rank": 327715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17881,
    "Rank": 327849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17882,
    "Rank": 327901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17883,
    "Rank": 327941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17884,
    "Rank": 327978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17885,
    "Rank": 328011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17886,
    "Rank": 328074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17887,
    "Rank": 328127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17888,
    "Rank": 328151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17889,
    "Rank": 328185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17890,
    "Rank": 328204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17891,
    "Rank": 328260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17892,
    "Rank": 328445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17893,
    "Rank": 328452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17894,
    "Rank": 328465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17895,
    "Rank": 328480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17896,
    "Rank": 328513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17897,
    "Rank": 328556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17898,
    "Rank": 328572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17899,
    "Rank": 328612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17900,
    "Rank": 328681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17901,
    "Rank": 328753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17902,
    "Rank": 328754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17903,
    "Rank": 328813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17904,
    "Rank": 328838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17905,
    "Rank": 328843,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, CHANDIGAR,GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, SECTOR 32, CHANDIGARH, Chandigarh, 160030",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17906,
    "Rank": 328859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17907,
    "Rank": 328966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17908,
    "Rank": 328967,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17909,
    "Rank": 329018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17910,
    "Rank": 329032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17911,
    "Rank": 329044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17912,
    "Rank": 329151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17913,
    "Rank": 329153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17914,
    "Rank": 329216,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17915,
    "Rank": 329316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17916,
    "Rank": 329320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17917,
    "Rank": 329347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17918,
    "Rank": 329432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17919,
    "Rank": 329451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17920,
    "Rank": 329597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17921,
    "Rank": 329599,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17922,
    "Rank": 329627,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17923,
    "Rank": 329703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17924,
    "Rank": 329785,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17925,
    "Rank": 329842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17926,
    "Rank": 329884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17927,
    "Rank": 329982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17928,
    "Rank": 329986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17929,
    "Rank": 330024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17930,
    "Rank": 330082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17931,
    "Rank": 330172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17932,
    "Rank": 330227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17933,
    "Rank": 330279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17934,
    "Rank": 330316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17935,
    "Rank": 330403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17936,
    "Rank": 330434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17937,
    "Rank": 330491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17938,
    "Rank": 330511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17939,
    "Rank": 330611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17940,
    "Rank": 330626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17941,
    "Rank": 330713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17942,
    "Rank": 330714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17943,
    "Rank": 330776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17944,
    "Rank": 330839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17945,
    "Rank": 330890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17946,
    "Rank": 331025,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17947,
    "Rank": 331034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17948,
    "Rank": 331217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17949,
    "Rank": 331352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17950,
    "Rank": 331409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17951,
    "Rank": 331439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17952,
    "Rank": 331486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17953,
    "Rank": 331599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17954,
    "Rank": 331605,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17955,
    "Rank": 331646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17956,
    "Rank": 331657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17957,
    "Rank": 331688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17958,
    "Rank": 331994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17959,
    "Rank": 332009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17960,
    "Rank": 332024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17961,
    "Rank": 332025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17962,
    "Rank": 332094,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17963,
    "Rank": 332132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17964,
    "Rank": 332147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17965,
    "Rank": 332225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17966,
    "Rank": 332235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17967,
    "Rank": 332311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17968,
    "Rank": 332428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17969,
    "Rank": 332429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17970,
    "Rank": 332445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17971,
    "Rank": 332452,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17972,
    "Rank": 332486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17973,
    "Rank": 332566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17974,
    "Rank": 332592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17975,
    "Rank": 332629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17976,
    "Rank": 332666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17977,
    "Rank": 332674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17978,
    "Rank": 332712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17979,
    "Rank": 332733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17980,
    "Rank": 332735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17981,
    "Rank": 332761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17982,
    "Rank": 332775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17983,
    "Rank": 332777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17984,
    "Rank": 332797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,K R ROAD FORT BENGALURU, Karnataka, 560002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17985,
    "Rank": 332899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17986,
    "Rank": 333191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17987,
    "Rank": 333245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17988,
    "Rank": 333310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17989,
    "Rank": 333311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17990,
    "Rank": 333335,
    "Allotted Quota": "All India",
    "Allotted Institute": "R.G. KAR MEDICAL COLLEGE,KOLKATA,R G Kar Medical College\n1 Khudiram bose sarani, Kolkata, West Bengal, 700004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17991,
    "Rank": 333420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17992,
    "Rank": 333432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17993,
    "Rank": 333504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17994,
    "Rank": 333517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17995,
    "Rank": 333621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17996,
    "Rank": 333755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17997,
    "Rank": 333779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17998,
    "Rank": 333817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 17999,
    "Rank": 333839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18000,
    "Rank": 333902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18001,
    "Rank": 333923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18002,
    "Rank": 333996,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18003,
    "Rank": 334002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18004,
    "Rank": 334006,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18005,
    "Rank": 334097,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18006,
    "Rank": 334190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18007,
    "Rank": 334193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18008,
    "Rank": 334213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18009,
    "Rank": 334248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18010,
    "Rank": 334266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18011,
    "Rank": 334330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18012,
    "Rank": 334387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18013,
    "Rank": 334446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18014,
    "Rank": 334505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18015,
    "Rank": 334825,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18016,
    "Rank": 334879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18017,
    "Rank": 334907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18018,
    "Rank": 334947,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18019,
    "Rank": 334985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18020,
    "Rank": 335023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18021,
    "Rank": 335091,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18022,
    "Rank": 335150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18023,
    "Rank": 335244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18024,
    "Rank": 335254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18025,
    "Rank": 335363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18026,
    "Rank": 335413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18027,
    "Rank": 335461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18028,
    "Rank": 335547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18029,
    "Rank": 335596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18030,
    "Rank": 335665,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18031,
    "Rank": 335668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18032,
    "Rank": 335683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18033,
    "Rank": 335703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18034,
    "Rank": 335877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18035,
    "Rank": 335952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18036,
    "Rank": 335954,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18037,
    "Rank": 336114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18038,
    "Rank": 336125,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18039,
    "Rank": 336237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18040,
    "Rank": 336239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18041,
    "Rank": 336312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18042,
    "Rank": 336350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18043,
    "Rank": 336509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18044,
    "Rank": 336519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18045,
    "Rank": 336530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18046,
    "Rank": 336640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18047,
    "Rank": 336855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18048,
    "Rank": 336926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18049,
    "Rank": 337009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18050,
    "Rank": 337078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18051,
    "Rank": 337102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18052,
    "Rank": 337163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18053,
    "Rank": 337292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18054,
    "Rank": 337348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18055,
    "Rank": 337351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18056,
    "Rank": 337371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18057,
    "Rank": 337464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18058,
    "Rank": 337540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18059,
    "Rank": 337556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18060,
    "Rank": 337626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18061,
    "Rank": 337632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18062,
    "Rank": 337723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18063,
    "Rank": 337772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18064,
    "Rank": 337786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18065,
    "Rank": 337818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18066,
    "Rank": 337835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18067,
    "Rank": 338151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18068,
    "Rank": 338178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18069,
    "Rank": 338249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18070,
    "Rank": 338380,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18071,
    "Rank": 338461,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18072,
    "Rank": 338516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18073,
    "Rank": 338538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18074,
    "Rank": 338541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18075,
    "Rank": 338571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18076,
    "Rank": 338572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18077,
    "Rank": 338589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18078,
    "Rank": 338824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18079,
    "Rank": 338853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18080,
    "Rank": 338922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18081,
    "Rank": 338935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18082,
    "Rank": 339030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18083,
    "Rank": 339102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18084,
    "Rank": 339197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18085,
    "Rank": 339200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18086,
    "Rank": 339287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18087,
    "Rank": 339369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18088,
    "Rank": 339447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18089,
    "Rank": 339577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18090,
    "Rank": 339587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18091,
    "Rank": 339612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18092,
    "Rank": 339742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18093,
    "Rank": 339806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18094,
    "Rank": 339810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18095,
    "Rank": 339827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18096,
    "Rank": 339927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18097,
    "Rank": 339930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18098,
    "Rank": 340016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18099,
    "Rank": 340052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18100,
    "Rank": 340092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18101,
    "Rank": 340219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18102,
    "Rank": 340227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18103,
    "Rank": 340262,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18104,
    "Rank": 340422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18105,
    "Rank": 340557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18106,
    "Rank": 340579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18107,
    "Rank": 340611,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18108,
    "Rank": 340732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18109,
    "Rank": 340753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18110,
    "Rank": 340816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18111,
    "Rank": 340920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18112,
    "Rank": 340968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18113,
    "Rank": 341066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18114,
    "Rank": 341118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18115,
    "Rank": 341183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18116,
    "Rank": 341187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18117,
    "Rank": 341263,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MED.& RESEARCH INST. MYSORE,Mysore Medical College and Research Institute,Irwin Road, Mysore, Karnataka, 570021",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18118,
    "Rank": 341285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18119,
    "Rank": 341342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18120,
    "Rank": 341349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18121,
    "Rank": 341409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18122,
    "Rank": 341501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18123,
    "Rank": 341631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18124,
    "Rank": 341661,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18125,
    "Rank": 341681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18126,
    "Rank": 341684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18127,
    "Rank": 341703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18128,
    "Rank": 341745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18129,
    "Rank": 341768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18130,
    "Rank": 341851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18131,
    "Rank": 341888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18132,
    "Rank": 341898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18133,
    "Rank": 342171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18134,
    "Rank": 342397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18135,
    "Rank": 342481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18136,
    "Rank": 342559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18137,
    "Rank": 342573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18138,
    "Rank": 342575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18139,
    "Rank": 342579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18140,
    "Rank": 342598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18141,
    "Rank": 342632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18142,
    "Rank": 342655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18143,
    "Rank": 342750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18144,
    "Rank": 342775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18145,
    "Rank": 342978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18146,
    "Rank": 343102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18147,
    "Rank": 343403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18148,
    "Rank": 343426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18149,
    "Rank": 343518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18150,
    "Rank": 343584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18151,
    "Rank": 343640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18152,
    "Rank": 343691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18153,
    "Rank": 343742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18154,
    "Rank": 343799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18155,
    "Rank": 343824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18156,
    "Rank": 344189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18157,
    "Rank": 344229,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18158,
    "Rank": 344496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18159,
    "Rank": 344600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18160,
    "Rank": 344737,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18161,
    "Rank": 344823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18162,
    "Rank": 344967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18163,
    "Rank": 345066,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18164,
    "Rank": 345189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18165,
    "Rank": 345437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18166,
    "Rank": 345453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18167,
    "Rank": 345462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18168,
    "Rank": 345492,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18169,
    "Rank": 345620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18170,
    "Rank": 345660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18171,
    "Rank": 345699,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18172,
    "Rank": 345715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18173,
    "Rank": 345757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18174,
    "Rank": 345826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18175,
    "Rank": 345866,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18176,
    "Rank": 345936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18177,
    "Rank": 345945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Inst. of Med. Sce., Lucknow,Vibhuti Khand Gomti Nagar\nLucknow, Uttar Pradesh, 226010",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18178,
    "Rank": 345957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18179,
    "Rank": 346142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18180,
    "Rank": 346166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18181,
    "Rank": 346256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18182,
    "Rank": 346257,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18183,
    "Rank": 346441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18184,
    "Rank": 346514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18185,
    "Rank": 346523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18186,
    "Rank": 346534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18187,
    "Rank": 346715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18188,
    "Rank": 346829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18189,
    "Rank": 346884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18190,
    "Rank": 347102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18191,
    "Rank": 347113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18192,
    "Rank": 347140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18193,
    "Rank": 347180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18194,
    "Rank": 347208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18195,
    "Rank": 347263,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18196,
    "Rank": 347290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18197,
    "Rank": 347299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18198,
    "Rank": 347374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18199,
    "Rank": 347423,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18200,
    "Rank": 347438,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18201,
    "Rank": 347457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18202,
    "Rank": 347459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18203,
    "Rank": 347536,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18204,
    "Rank": 347704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18205,
    "Rank": 347716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18206,
    "Rank": 347796,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18207,
    "Rank": 347833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18208,
    "Rank": 347872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18209,
    "Rank": 347936,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18210,
    "Rank": 347976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18211,
    "Rank": 348045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18212,
    "Rank": 348073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18213,
    "Rank": 348251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18214,
    "Rank": 348320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18215,
    "Rank": 348332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18216,
    "Rank": 348353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18217,
    "Rank": 348357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18218,
    "Rank": 348387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18219,
    "Rank": 348396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18220,
    "Rank": 348407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18221,
    "Rank": 348449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18222,
    "Rank": 348484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18223,
    "Rank": 348498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18224,
    "Rank": 348503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18225,
    "Rank": 348532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18226,
    "Rank": 348617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18227,
    "Rank": 348712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18228,
    "Rank": 348721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18229,
    "Rank": 348778,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18230,
    "Rank": 348805,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18231,
    "Rank": 348828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18232,
    "Rank": 349139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18233,
    "Rank": 349180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18234,
    "Rank": 349225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18235,
    "Rank": 349231,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18236,
    "Rank": 349245,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18237,
    "Rank": 349386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18238,
    "Rank": 349415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18239,
    "Rank": 349734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18240,
    "Rank": 349771,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Madurai,AIIMS MADURAI, Tamil Nadu, 625008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18241,
    "Rank": 349781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18242,
    "Rank": 349915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18243,
    "Rank": 350013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18244,
    "Rank": 350034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18245,
    "Rank": 350075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18246,
    "Rank": 350145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18247,
    "Rank": 350175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18248,
    "Rank": 350264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18249,
    "Rank": 350290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18250,
    "Rank": 350392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18251,
    "Rank": 350419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18252,
    "Rank": 350638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18253,
    "Rank": 350643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18254,
    "Rank": 350706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18255,
    "Rank": 350774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18256,
    "Rank": 350876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18257,
    "Rank": 350955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18258,
    "Rank": 350963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18259,
    "Rank": 351000,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18260,
    "Rank": 351023,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18261,
    "Rank": 351081,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18262,
    "Rank": 351104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18263,
    "Rank": 351187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18264,
    "Rank": 351254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18265,
    "Rank": 351257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18266,
    "Rank": 351350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18267,
    "Rank": 351355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18268,
    "Rank": 351587,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE, GUNTUR,Kannavarithoa Opp. NGOs Association Building, Andhra Pradesh, 522004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18269,
    "Rank": 351601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18270,
    "Rank": 351701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18271,
    "Rank": 351758,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18272,
    "Rank": 351782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18273,
    "Rank": 351801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18274,
    "Rank": 352057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18275,
    "Rank": 352139,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18276,
    "Rank": 352306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18277,
    "Rank": 352312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18278,
    "Rank": 352437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18279,
    "Rank": 352462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18280,
    "Rank": 352562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18281,
    "Rank": 352608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18282,
    "Rank": 352628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18283,
    "Rank": 352663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18284,
    "Rank": 352680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18285,
    "Rank": 352756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18286,
    "Rank": 352814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18287,
    "Rank": 352911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18288,
    "Rank": 352938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18289,
    "Rank": 352951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18290,
    "Rank": 352979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18291,
    "Rank": 353073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18292,
    "Rank": 353101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18293,
    "Rank": 353206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18294,
    "Rank": 353229,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Jodhpur,BASNI PHASE - II, JODHPUR-342005, Rajasthan, 342005",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18295,
    "Rank": 353395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18296,
    "Rank": 353633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18297,
    "Rank": 353650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18298,
    "Rank": 353749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18299,
    "Rank": 353767,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18300,
    "Rank": 353786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18301,
    "Rank": 353907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18302,
    "Rank": 353971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18303,
    "Rank": 354023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18304,
    "Rank": 354103,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18305,
    "Rank": 354312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18306,
    "Rank": 354316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18307,
    "Rank": 354450,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18308,
    "Rank": 354539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18309,
    "Rank": 354551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18310,
    "Rank": 354554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18311,
    "Rank": 354618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18312,
    "Rank": 354630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18313,
    "Rank": 354641,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18314,
    "Rank": 354939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18315,
    "Rank": 354989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18316,
    "Rank": 354993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18317,
    "Rank": 355161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18318,
    "Rank": 355290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18319,
    "Rank": 355384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18320,
    "Rank": 355446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18321,
    "Rank": 355471,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18322,
    "Rank": 355749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18323,
    "Rank": 355857,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18324,
    "Rank": 355916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18325,
    "Rank": 356005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18326,
    "Rank": 356098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18327,
    "Rank": 356133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18328,
    "Rank": 356134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18329,
    "Rank": 356250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18330,
    "Rank": 356448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18331,
    "Rank": 356469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18332,
    "Rank": 356638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18333,
    "Rank": 356709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18334,
    "Rank": 356840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18335,
    "Rank": 356930,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18336,
    "Rank": 356938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18337,
    "Rank": 356965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18338,
    "Rank": 357084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18339,
    "Rank": 357252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18340,
    "Rank": 357377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18341,
    "Rank": 357498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18342,
    "Rank": 357505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18343,
    "Rank": 357518,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18344,
    "Rank": 357537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18345,
    "Rank": 357564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18346,
    "Rank": 357738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18347,
    "Rank": 357742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18348,
    "Rank": 357794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18349,
    "Rank": 357810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18350,
    "Rank": 357839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18351,
    "Rank": 357844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18352,
    "Rank": 357881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18353,
    "Rank": 358277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18354,
    "Rank": 358287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18355,
    "Rank": 358385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18356,
    "Rank": 358413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18357,
    "Rank": 358458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18358,
    "Rank": 358479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18359,
    "Rank": 358604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18360,
    "Rank": 358718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18361,
    "Rank": 358726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18362,
    "Rank": 358925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18363,
    "Rank": 359027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18364,
    "Rank": 359146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18365,
    "Rank": 359196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18366,
    "Rank": 359201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18367,
    "Rank": 359387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18368,
    "Rank": 359431,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18369,
    "Rank": 359522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18370,
    "Rank": 359543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18371,
    "Rank": 359594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18372,
    "Rank": 359657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18373,
    "Rank": 359674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18374,
    "Rank": 359798,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18375,
    "Rank": 359946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18376,
    "Rank": 359990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18377,
    "Rank": 360090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18378,
    "Rank": 360130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18379,
    "Rank": 360165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18380,
    "Rank": 360263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18381,
    "Rank": 360405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18382,
    "Rank": 360613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18383,
    "Rank": 360779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18384,
    "Rank": 360928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18385,
    "Rank": 360948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18386,
    "Rank": 360991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18387,
    "Rank": 361020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18388,
    "Rank": 361041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18389,
    "Rank": 361042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18390,
    "Rank": 361148,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18391,
    "Rank": 361257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18392,
    "Rank": 361361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18393,
    "Rank": 361385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18394,
    "Rank": 361412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18395,
    "Rank": 361433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18396,
    "Rank": 361457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18397,
    "Rank": 361781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18398,
    "Rank": 361791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18399,
    "Rank": 361925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18400,
    "Rank": 362023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18401,
    "Rank": 362139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18402,
    "Rank": 362142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18403,
    "Rank": 362249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18404,
    "Rank": 362441,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18405,
    "Rank": 362501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18406,
    "Rank": 362535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18407,
    "Rank": 362590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18408,
    "Rank": 362614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18409,
    "Rank": 362674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18410,
    "Rank": 362761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18411,
    "Rank": 362837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18412,
    "Rank": 362917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18413,
    "Rank": 362924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18414,
    "Rank": 362996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18415,
    "Rank": 363098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18416,
    "Rank": 363169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18417,
    "Rank": 363209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18418,
    "Rank": 363233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18419,
    "Rank": 363278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18420,
    "Rank": 363312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18421,
    "Rank": 363350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18422,
    "Rank": 363387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18423,
    "Rank": 363419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18424,
    "Rank": 363447,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Indiranagar, Bangalore,70 APPAREDDY PALYA 7TH MAIN INDIRANAGAR BENGALURU, Karnataka, 560008",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18425,
    "Rank": 363474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18426,
    "Rank": 363550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18427,
    "Rank": 363557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18428,
    "Rank": 363560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18429,
    "Rank": 363616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18430,
    "Rank": 363679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18431,
    "Rank": 363702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18432,
    "Rank": 363715,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18433,
    "Rank": 363730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18434,
    "Rank": 363752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18435,
    "Rank": 363768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18436,
    "Rank": 363779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18437,
    "Rank": 363791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18438,
    "Rank": 363818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18439,
    "Rank": 363889,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18440,
    "Rank": 364002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18441,
    "Rank": 364046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18442,
    "Rank": 364097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18443,
    "Rank": 364165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18444,
    "Rank": 364226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18445,
    "Rank": 364306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18446,
    "Rank": 364418,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18447,
    "Rank": 364480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18448,
    "Rank": 364537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18449,
    "Rank": 364545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18450,
    "Rank": 364775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18451,
    "Rank": 364795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18452,
    "Rank": 364870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18453,
    "Rank": 365001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18454,
    "Rank": 365026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18455,
    "Rank": 365036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18456,
    "Rank": 365096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18457,
    "Rank": 365464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18458,
    "Rank": 365572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18459,
    "Rank": 365608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18460,
    "Rank": 365618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18461,
    "Rank": 365718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18462,
    "Rank": 365800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18463,
    "Rank": 365807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18464,
    "Rank": 365856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18465,
    "Rank": 365896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18466,
    "Rank": 365918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18467,
    "Rank": 365926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18468,
    "Rank": 365947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18469,
    "Rank": 365959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18470,
    "Rank": 365965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18471,
    "Rank": 366035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18472,
    "Rank": 366044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18473,
    "Rank": 366100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18474,
    "Rank": 366116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18475,
    "Rank": 366122,
    "Allotted Quota": "All India",
    "Allotted Institute": "G.S.V.M. MEDICAL COLLEGE, KANPUR,G.S.V.M Medical College Kanpur Swaroop Nagar Kanpur\nU.P 208002, Uttar Pradesh, 208002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18476,
    "Rank": 366134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18477,
    "Rank": 366292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18478,
    "Rank": 366349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18479,
    "Rank": 366371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18480,
    "Rank": 366373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18481,
    "Rank": 366509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18482,
    "Rank": 366617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18483,
    "Rank": 366700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18484,
    "Rank": 366756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18485,
    "Rank": 366927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18486,
    "Rank": 366962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18487,
    "Rank": 367074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18488,
    "Rank": 367107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18489,
    "Rank": 367148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18490,
    "Rank": 367205,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLL., SHIMLA,IGMC SHIMLA NEAR LAKKARBAZAR\nSHIMLA, Himachal Pradesh, 171001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18491,
    "Rank": 367292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18492,
    "Rank": 367456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18493,
    "Rank": 367459,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18494,
    "Rank": 367465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18495,
    "Rank": 367536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18496,
    "Rank": 367563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18497,
    "Rank": 367567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18498,
    "Rank": 367652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18499,
    "Rank": 367807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18500,
    "Rank": 367907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18501,
    "Rank": 367916,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18502,
    "Rank": 367978,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18503,
    "Rank": 367996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18504,
    "Rank": 368009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18505,
    "Rank": 368031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18506,
    "Rank": 368154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18507,
    "Rank": 368197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18508,
    "Rank": 368267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18509,
    "Rank": 368269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18510,
    "Rank": 368332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18511,
    "Rank": 368398,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18512,
    "Rank": 368462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18513,
    "Rank": 368465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18514,
    "Rank": 368476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18515,
    "Rank": 368607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18516,
    "Rank": 368698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18517,
    "Rank": 368730,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18518,
    "Rank": 368757,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18519,
    "Rank": 368848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18520,
    "Rank": 368900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18521,
    "Rank": 368960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18522,
    "Rank": 368970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18523,
    "Rank": 369073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18524,
    "Rank": 369160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18525,
    "Rank": 369228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18526,
    "Rank": 369326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18527,
    "Rank": 369427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18528,
    "Rank": 369522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18529,
    "Rank": 369525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18530,
    "Rank": 369610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18531,
    "Rank": 369643,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18532,
    "Rank": 369700,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18533,
    "Rank": 369789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18534,
    "Rank": 369793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18535,
    "Rank": 369803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18536,
    "Rank": 369890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18537,
    "Rank": 369910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18538,
    "Rank": 369923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18539,
    "Rank": 369978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18540,
    "Rank": 369980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18541,
    "Rank": 370001,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18542,
    "Rank": 370146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18543,
    "Rank": 370177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18544,
    "Rank": 370182,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18545,
    "Rank": 370183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18546,
    "Rank": 370187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18547,
    "Rank": 370218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18548,
    "Rank": 370228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18549,
    "Rank": 370311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18550,
    "Rank": 370372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18551,
    "Rank": 370459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18552,
    "Rank": 370521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18553,
    "Rank": 370662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18554,
    "Rank": 370789,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18555,
    "Rank": 370842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18556,
    "Rank": 370892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18557,
    "Rank": 370967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18558,
    "Rank": 371077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18559,
    "Rank": 371168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18560,
    "Rank": 371238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18561,
    "Rank": 371251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18562,
    "Rank": 371256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18563,
    "Rank": 371418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18564,
    "Rank": 371622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18565,
    "Rank": 371623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18566,
    "Rank": 371655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18567,
    "Rank": 371659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18568,
    "Rank": 371669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18569,
    "Rank": 371836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18570,
    "Rank": 371843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18571,
    "Rank": 371898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18572,
    "Rank": 372021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18573,
    "Rank": 372294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18574,
    "Rank": 372756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18575,
    "Rank": 372773,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "SLBS Govt. Medical College, Mandi,Mandi at Nerchowk Tehsil Balh PO Bhangrotu, Himachal Pradesh, 175021",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18576,
    "Rank": 372777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18577,
    "Rank": 372794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18578,
    "Rank": 372852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18579,
    "Rank": 373034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18580,
    "Rank": 373202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18581,
    "Rank": 373298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18582,
    "Rank": 373308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18583,
    "Rank": 373496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18584,
    "Rank": 373595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18585,
    "Rank": 373807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18586,
    "Rank": 373828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18587,
    "Rank": 373921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18588,
    "Rank": 373946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18589,
    "Rank": 374198,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, MIRAJ,Pandharpur Road Miraj tal Miraj Dist sangli, Maharashtra, 416410",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18590,
    "Rank": 374370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18591,
    "Rank": 374395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18592,
    "Rank": 374400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18593,
    "Rank": 374612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18594,
    "Rank": 374632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18595,
    "Rank": 374749,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18596,
    "Rank": 374819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18597,
    "Rank": 375026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18598,
    "Rank": 375055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18599,
    "Rank": 375161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18600,
    "Rank": 375283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18601,
    "Rank": 375389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18602,
    "Rank": 375401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18603,
    "Rank": 375435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18604,
    "Rank": 375518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18605,
    "Rank": 375574,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18606,
    "Rank": 375594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18607,
    "Rank": 375616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18608,
    "Rank": 375623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18609,
    "Rank": 375826,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18610,
    "Rank": 375836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18611,
    "Rank": 375881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18612,
    "Rank": 375897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18613,
    "Rank": 375979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18614,
    "Rank": 375984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18615,
    "Rank": 376004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18616,
    "Rank": 376133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18617,
    "Rank": 376141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18618,
    "Rank": 376169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18619,
    "Rank": 376228,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18620,
    "Rank": 376278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18621,
    "Rank": 376309,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18622,
    "Rank": 376320,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18623,
    "Rank": 376376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18624,
    "Rank": 376384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18625,
    "Rank": 376386,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18626,
    "Rank": 376497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18627,
    "Rank": 376508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18628,
    "Rank": 376544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18629,
    "Rank": 376551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18630,
    "Rank": 376564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18631,
    "Rank": 376701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18632,
    "Rank": 376734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18633,
    "Rank": 376754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18634,
    "Rank": 376963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18635,
    "Rank": 377019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18636,
    "Rank": 377235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18637,
    "Rank": 377362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18638,
    "Rank": 377458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18639,
    "Rank": 377460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18640,
    "Rank": 377493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18641,
    "Rank": 377519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18642,
    "Rank": 377521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18643,
    "Rank": 377569,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18644,
    "Rank": 377665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18645,
    "Rank": 377718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18646,
    "Rank": 377744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18647,
    "Rank": 377948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18648,
    "Rank": 378003,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18649,
    "Rank": 378109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18650,
    "Rank": 378258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18651,
    "Rank": 378278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18652,
    "Rank": 378313,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18653,
    "Rank": 378367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18654,
    "Rank": 378376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18655,
    "Rank": 378461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18656,
    "Rank": 378467,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18657,
    "Rank": 378599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18658,
    "Rank": 378653,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18659,
    "Rank": 378771,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18660,
    "Rank": 378781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18661,
    "Rank": 378983,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18662,
    "Rank": 379185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18663,
    "Rank": 379212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18664,
    "Rank": 379342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18665,
    "Rank": 379402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18666,
    "Rank": 379475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18667,
    "Rank": 379488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18668,
    "Rank": 379595,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18669,
    "Rank": 379631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18670,
    "Rank": 379725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18671,
    "Rank": 379914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18672,
    "Rank": 379920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18673,
    "Rank": 380027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18674,
    "Rank": 380177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18675,
    "Rank": 380216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18676,
    "Rank": 380251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18677,
    "Rank": 380291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18678,
    "Rank": 380328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18679,
    "Rank": 380364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18680,
    "Rank": 380367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18681,
    "Rank": 380427,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18682,
    "Rank": 380507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18683,
    "Rank": 380539,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18684,
    "Rank": 380567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18685,
    "Rank": 380775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18686,
    "Rank": 380852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18687,
    "Rank": 380853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18688,
    "Rank": 381030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18689,
    "Rank": 381151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18690,
    "Rank": 381308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18691,
    "Rank": 381353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18692,
    "Rank": 381619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18693,
    "Rank": 381732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18694,
    "Rank": 381794,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18695,
    "Rank": 381805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18696,
    "Rank": 381848,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18697,
    "Rank": 381882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18698,
    "Rank": 381892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18699,
    "Rank": 381955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18700,
    "Rank": 382106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18701,
    "Rank": 382150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18702,
    "Rank": 382200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18703,
    "Rank": 382223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18704,
    "Rank": 382253,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18705,
    "Rank": 382295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18706,
    "Rank": 382297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18707,
    "Rank": 382469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18708,
    "Rank": 382615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18709,
    "Rank": 382697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18710,
    "Rank": 382847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18711,
    "Rank": 382922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18712,
    "Rank": 382941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18713,
    "Rank": 382963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18714,
    "Rank": 383232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18715,
    "Rank": 383307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18716,
    "Rank": 383336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18717,
    "Rank": 383392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18718,
    "Rank": 383436,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18719,
    "Rank": 383580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18720,
    "Rank": 383733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18721,
    "Rank": 383771,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18722,
    "Rank": 383807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18723,
    "Rank": 383837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18724,
    "Rank": 384015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18725,
    "Rank": 384077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18726,
    "Rank": 384118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18727,
    "Rank": 384134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18728,
    "Rank": 384162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18729,
    "Rank": 384209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18730,
    "Rank": 384521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18731,
    "Rank": 384524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18732,
    "Rank": 384665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18733,
    "Rank": 384768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18734,
    "Rank": 384885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18735,
    "Rank": 384957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18736,
    "Rank": 385028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18737,
    "Rank": 385075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18738,
    "Rank": 385332,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18739,
    "Rank": 385453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18740,
    "Rank": 385480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18741,
    "Rank": 385606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18742,
    "Rank": 385649,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18743,
    "Rank": 385728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18744,
    "Rank": 385832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18745,
    "Rank": 385913,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Mangalagiri ,ALL INDIA INSTITUTE OF MEDICAL SCIENCES NEAR TADEPALLI\nMANGALAGIRI GUNTUR (Dt)\nANDHRA PRADESH, Andhra Pradesh, 522503",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18746,
    "Rank": 385995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18747,
    "Rank": 386066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18748,
    "Rank": 386077,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18749,
    "Rank": 386119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18750,
    "Rank": 386280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18751,
    "Rank": 386301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18752,
    "Rank": 386312,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18753,
    "Rank": 386369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18754,
    "Rank": 386410,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18755,
    "Rank": 386510,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18756,
    "Rank": 386564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18757,
    "Rank": 386586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18758,
    "Rank": 386711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18759,
    "Rank": 386798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18760,
    "Rank": 386922,
    "Allotted Quota": "All India",
    "Allotted Institute": "GRANT MEDICAL COLL & SIR J.J.HOSP,MUMBAI,Sir JJ Group of HospitalCompound, Sir JJ Road, Byculla Mumbai, Maharashtra, 400008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18761,
    "Rank": 386948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18762,
    "Rank": 387029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18763,
    "Rank": 387042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18764,
    "Rank": 387060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18765,
    "Rank": 387191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18766,
    "Rank": 387227,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18767,
    "Rank": 387266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18768,
    "Rank": 387291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18769,
    "Rank": 387343,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18770,
    "Rank": 387463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18771,
    "Rank": 387476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18772,
    "Rank": 387559,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18773,
    "Rank": 387805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18774,
    "Rank": 387808,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 23 )"
  },
  {
    "SNo": 18775,
    "Rank": 387820,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18776,
    "Rank": 387871,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18777,
    "Rank": 387877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18778,
    "Rank": 387958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18779,
    "Rank": 388134,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18780,
    "Rank": 388149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18781,
    "Rank": 388211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18782,
    "Rank": 388227,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18783,
    "Rank": 388356,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18784,
    "Rank": 388438,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18785,
    "Rank": 388448,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bilaspur Changar Palasiyan, Himachal Pradesh,All India Institute of Medical Sciences AIIMS\nBilaspur Kothipura BILASPUR Himachal Pradesh 174001, Himachal Pradesh, 174001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18786,
    "Rank": 388487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18787,
    "Rank": 388636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18788,
    "Rank": 388899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18789,
    "Rank": 388913,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18790,
    "Rank": 388937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18791,
    "Rank": 389109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18792,
    "Rank": 389123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18793,
    "Rank": 389132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18794,
    "Rank": 389368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18795,
    "Rank": 389405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18796,
    "Rank": 389446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18797,
    "Rank": 389469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18798,
    "Rank": 389499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18799,
    "Rank": 389626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18800,
    "Rank": 389724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18801,
    "Rank": 389731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18802,
    "Rank": 389762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18803,
    "Rank": 389857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18804,
    "Rank": 389931,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18805,
    "Rank": 390131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18806,
    "Rank": 390305,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18807,
    "Rank": 390428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18808,
    "Rank": 390465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18809,
    "Rank": 390471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18810,
    "Rank": 390484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18811,
    "Rank": 390485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18812,
    "Rank": 390535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18813,
    "Rank": 390630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18814,
    "Rank": 390925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18815,
    "Rank": 390993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18816,
    "Rank": 391048,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18817,
    "Rank": 391171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18818,
    "Rank": 391197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18819,
    "Rank": 391319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18820,
    "Rank": 391383,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18821,
    "Rank": 391430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18822,
    "Rank": 391472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18823,
    "Rank": 391496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18824,
    "Rank": 391592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18825,
    "Rank": 391638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18826,
    "Rank": 391647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18827,
    "Rank": 391676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18828,
    "Rank": 391833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18829,
    "Rank": 391895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18830,
    "Rank": 391929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18831,
    "Rank": 392049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18832,
    "Rank": 392056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18833,
    "Rank": 392060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18834,
    "Rank": 392067,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18835,
    "Rank": 392106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18836,
    "Rank": 392139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18837,
    "Rank": 392196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18838,
    "Rank": 392212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18839,
    "Rank": 392384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18840,
    "Rank": 392529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18841,
    "Rank": 392569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18842,
    "Rank": 392667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18843,
    "Rank": 392809,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18844,
    "Rank": 392857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18845,
    "Rank": 392862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18846,
    "Rank": 392871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18847,
    "Rank": 392882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18848,
    "Rank": 392956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18849,
    "Rank": 393179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18850,
    "Rank": 393200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18851,
    "Rank": 393445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18852,
    "Rank": 393462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18853,
    "Rank": 393538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18854,
    "Rank": 393582,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18855,
    "Rank": 393823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18856,
    "Rank": 393982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18857,
    "Rank": 394249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18858,
    "Rank": 394299,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18859,
    "Rank": 394336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18860,
    "Rank": 394678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18861,
    "Rank": 394729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18862,
    "Rank": 394836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18863,
    "Rank": 394900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18864,
    "Rank": 395046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18865,
    "Rank": 395071,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,AURANGABAD,Government Medical College, Aurangabad, Jubli Park, Panchakki Road, Aurangabad (M.S.)., Maharashtra, 431001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18866,
    "Rank": 395088,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18867,
    "Rank": 395124,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18868,
    "Rank": 395192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18869,
    "Rank": 395206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18870,
    "Rank": 395338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18871,
    "Rank": 395415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18872,
    "Rank": 395583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18873,
    "Rank": 395639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18874,
    "Rank": 395641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18875,
    "Rank": 395652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18876,
    "Rank": 395701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18877,
    "Rank": 395767,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18878,
    "Rank": 395776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18879,
    "Rank": 395990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18880,
    "Rank": 396191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18881,
    "Rank": 396193,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18882,
    "Rank": 396263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18883,
    "Rank": 396269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18884,
    "Rank": 396396,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18885,
    "Rank": 396422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18886,
    "Rank": 396432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18887,
    "Rank": 396533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18888,
    "Rank": 396555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18889,
    "Rank": 396592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18890,
    "Rank": 396672,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18891,
    "Rank": 396786,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18892,
    "Rank": 396993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18893,
    "Rank": 397026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18894,
    "Rank": 397032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18895,
    "Rank": 397149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18896,
    "Rank": 397343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18897,
    "Rank": 397459,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18898,
    "Rank": 397717,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18899,
    "Rank": 397734,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "SC",
    "Candidate Category": "SC",
    "Remarks": "Allotted( CW Rank\n: 175 )"
  },
  {
    "SNo": 18900,
    "Rank": 397755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18901,
    "Rank": 397768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18902,
    "Rank": 397804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18903,
    "Rank": 397857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18904,
    "Rank": 397950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18905,
    "Rank": 397967,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18906,
    "Rank": 398036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18907,
    "Rank": 398115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18908,
    "Rank": 398418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18909,
    "Rank": 398515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18910,
    "Rank": 398557,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18911,
    "Rank": 398725,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18912,
    "Rank": 398967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18913,
    "Rank": 399071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18914,
    "Rank": 399169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18915,
    "Rank": 399182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18916,
    "Rank": 399204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18917,
    "Rank": 399272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18918,
    "Rank": 399425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18919,
    "Rank": 399549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18920,
    "Rank": 399632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18921,
    "Rank": 399641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18922,
    "Rank": 399644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18923,
    "Rank": 399678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18924,
    "Rank": 399698,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Ahilya Bai College of Nursing, Maulana Azad Medical College,Ahilya Bai College of Nursing\nLok Nayak Hospital\nJawaharlal Nehru Marg, Delhi (NCT), 110002 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18925,
    "Rank": 399746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18926,
    "Rank": 399821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18927,
    "Rank": 399829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18928,
    "Rank": 399850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18929,
    "Rank": 400056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18930,
    "Rank": 400085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18931,
    "Rank": 400118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18932,
    "Rank": 400198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18933,
    "Rank": 400204,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18934,
    "Rank": 400247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18935,
    "Rank": 400255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18936,
    "Rank": 400322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18937,
    "Rank": 400412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18938,
    "Rank": 400503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18939,
    "Rank": 400525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18940,
    "Rank": 400533,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18941,
    "Rank": 400836,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18942,
    "Rank": 400928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18943,
    "Rank": 400966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18944,
    "Rank": 400992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18945,
    "Rank": 401127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18946,
    "Rank": 401221,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18947,
    "Rank": 401273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18948,
    "Rank": 401340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18949,
    "Rank": 401398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18950,
    "Rank": 401607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18951,
    "Rank": 401755,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18952,
    "Rank": 401779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18953,
    "Rank": 401814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18954,
    "Rank": 401917,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18955,
    "Rank": 401928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18956,
    "Rank": 401978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18957,
    "Rank": 402098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18958,
    "Rank": 402140,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18959,
    "Rank": 402346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18960,
    "Rank": 402392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18961,
    "Rank": 402407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18962,
    "Rank": 402499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18963,
    "Rank": 402526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18964,
    "Rank": 402529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18965,
    "Rank": 402530,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J. MEDICAL COLLEGE, AHMEDABAD,B J Medical College, New Civil Hospital Campus, Asarwa, Ahmedabad 380016, Gujarat, 380016",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18966,
    "Rank": 402577,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18967,
    "Rank": 402693,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18968,
    "Rank": 402699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18969,
    "Rank": 402742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18970,
    "Rank": 402803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18971,
    "Rank": 402848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18972,
    "Rank": 402854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18973,
    "Rank": 402881,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18974,
    "Rank": 402911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18975,
    "Rank": 402971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18976,
    "Rank": 403359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18977,
    "Rank": 403423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18978,
    "Rank": 403432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18979,
    "Rank": 403473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18980,
    "Rank": 403563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18981,
    "Rank": 403598,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18982,
    "Rank": 403885,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18983,
    "Rank": 403909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18984,
    "Rank": 403920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18985,
    "Rank": 404195,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18986,
    "Rank": 404244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18987,
    "Rank": 404328,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18988,
    "Rank": 404628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18989,
    "Rank": 404660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18990,
    "Rank": 404892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18991,
    "Rank": 404932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18992,
    "Rank": 405154,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18993,
    "Rank": 405209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18994,
    "Rank": 405286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18995,
    "Rank": 405347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18996,
    "Rank": 405406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18997,
    "Rank": 405520,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18998,
    "Rank": 405579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 18999,
    "Rank": 405593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19000,
    "Rank": 405839,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19001,
    "Rank": 405880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19002,
    "Rank": 406030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19003,
    "Rank": 406153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Sce., Manipal,Madhav Nagar, Manipal  India, Karnataka, 576104",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19004,
    "Rank": 406222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19005,
    "Rank": 406358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19006,
    "Rank": 406495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19007,
    "Rank": 406551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19008,
    "Rank": 406753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19009,
    "Rank": 406765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19010,
    "Rank": 406930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19011,
    "Rank": 406957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19012,
    "Rank": 407002,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19013,
    "Rank": 407009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19014,
    "Rank": 407023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19015,
    "Rank": 407028,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19016,
    "Rank": 407070,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19017,
    "Rank": 407264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19018,
    "Rank": 407273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19019,
    "Rank": 407346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19020,
    "Rank": 407462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19021,
    "Rank": 407531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19022,
    "Rank": 407608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19023,
    "Rank": 407616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19024,
    "Rank": 407673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19025,
    "Rank": 408192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19026,
    "Rank": 408244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19027,
    "Rank": 408313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19028,
    "Rank": 408389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19029,
    "Rank": 408398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19030,
    "Rank": 408599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19031,
    "Rank": 408605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19032,
    "Rank": 408745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19033,
    "Rank": 408746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19034,
    "Rank": 408923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19035,
    "Rank": 409001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19036,
    "Rank": 409143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19037,
    "Rank": 409153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19038,
    "Rank": 409165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19039,
    "Rank": 409248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19040,
    "Rank": 409312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19041,
    "Rank": 409326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19042,
    "Rank": 409388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19043,
    "Rank": 409446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19044,
    "Rank": 409529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19045,
    "Rank": 409567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19046,
    "Rank": 409658,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19047,
    "Rank": 409791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19048,
    "Rank": 409812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19049,
    "Rank": 409933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19050,
    "Rank": 409935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19051,
    "Rank": 410028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19052,
    "Rank": 410055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19053,
    "Rank": 410112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19054,
    "Rank": 410183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19055,
    "Rank": 410224,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19056,
    "Rank": 410310,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19057,
    "Rank": 410334,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19058,
    "Rank": 410341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19059,
    "Rank": 410356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19060,
    "Rank": 410415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19061,
    "Rank": 410431,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER KARAIKAL,JIPMER Academic Campus, FCI Link Road, Kovilpathu, Karaikal - 609602, Puducherry, 609602",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19062,
    "Rank": 410681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19063,
    "Rank": 410694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19064,
    "Rank": 410799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19065,
    "Rank": 410855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19066,
    "Rank": 410881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19067,
    "Rank": 410885,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19068,
    "Rank": 410910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19069,
    "Rank": 410966,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19070,
    "Rank": 410981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19071,
    "Rank": 411010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19072,
    "Rank": 411067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19073,
    "Rank": 411120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19074,
    "Rank": 411214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19075,
    "Rank": 411223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19076,
    "Rank": 411282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19077,
    "Rank": 411308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19078,
    "Rank": 411343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19079,
    "Rank": 411351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19080,
    "Rank": 411376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19081,
    "Rank": 411406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19082,
    "Rank": 411550,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19083,
    "Rank": 411729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19084,
    "Rank": 411851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19085,
    "Rank": 411868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19086,
    "Rank": 411914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19087,
    "Rank": 411930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19088,
    "Rank": 411972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19089,
    "Rank": 411987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19090,
    "Rank": 412043,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19091,
    "Rank": 412173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19092,
    "Rank": 412187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19093,
    "Rank": 412361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19094,
    "Rank": 412387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19095,
    "Rank": 412389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19096,
    "Rank": 412472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19097,
    "Rank": 412486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19098,
    "Rank": 412523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19099,
    "Rank": 412577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19100,
    "Rank": 412646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19101,
    "Rank": 412838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19102,
    "Rank": 412903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19103,
    "Rank": 412977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19104,
    "Rank": 413023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19105,
    "Rank": 413272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19106,
    "Rank": 413702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19107,
    "Rank": 413820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19108,
    "Rank": 413847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19109,
    "Rank": 413901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19110,
    "Rank": 414007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19111,
    "Rank": 414024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19112,
    "Rank": 414086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19113,
    "Rank": 414117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19114,
    "Rank": 414125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19115,
    "Rank": 414147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19116,
    "Rank": 414191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19117,
    "Rank": 414217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19118,
    "Rank": 414455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19119,
    "Rank": 414568,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 1 )"
  },
  {
    "SNo": 19120,
    "Rank": 414614,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Gorakhpur ,AIIMS Gorakhpur, Medical College Building, Kunraghat, Gorakhpur, Uttar Pradesh, 273008",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19121,
    "Rank": 414710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19122,
    "Rank": 414823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19123,
    "Rank": 414869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19124,
    "Rank": 414917,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19125,
    "Rank": 414925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19126,
    "Rank": 414937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19127,
    "Rank": 415018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19128,
    "Rank": 415028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19129,
    "Rank": 415267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19130,
    "Rank": 415356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19131,
    "Rank": 415442,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19132,
    "Rank": 415458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19133,
    "Rank": 415644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19134,
    "Rank": 415668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19135,
    "Rank": 415834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19136,
    "Rank": 415869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19137,
    "Rank": 415969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19138,
    "Rank": 416208,
    "Allotted Quota": "All India",
    "Allotted Institute": "MOTI LAL NEHRU MEDICAL COLL, ALLAHABAD,Principal Office Moti Lal Nehru Medical College, Lowther Road George Town Prayagraj, Uttar Pradesh, 211001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19139,
    "Rank": 416211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19140,
    "Rank": 416266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19141,
    "Rank": 416333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19142,
    "Rank": 416441,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19143,
    "Rank": 416464,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19144,
    "Rank": 416505,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19145,
    "Rank": 416565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19146,
    "Rank": 416574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19147,
    "Rank": 416611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19148,
    "Rank": 416629,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.M.S. MEDICAL COLLEGE, JAIPUR,JLN Marg, Jaipur, Rajasthan, 302004",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19149,
    "Rank": 416671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19150,
    "Rank": 417057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19151,
    "Rank": 417068,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19152,
    "Rank": 417171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19153,
    "Rank": 417300,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19154,
    "Rank": 417319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19155,
    "Rank": 417350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19156,
    "Rank": 417556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19157,
    "Rank": 417611,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19158,
    "Rank": 417631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19159,
    "Rank": 417820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19160,
    "Rank": 417871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19161,
    "Rank": 417886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19162,
    "Rank": 417905,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19163,
    "Rank": 417993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19164,
    "Rank": 418008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19165,
    "Rank": 418083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19166,
    "Rank": 418113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19167,
    "Rank": 418130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19168,
    "Rank": 418256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19169,
    "Rank": 418303,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.N. MEDICAL COLLEGE, AGRA,HOSPITAL ROAD AGRA, Uttar Pradesh, 282002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19170,
    "Rank": 418397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19171,
    "Rank": 418497,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOTTAYAM,GANDHINAGAR P O KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19172,
    "Rank": 418511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19173,
    "Rank": 418538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19174,
    "Rank": 418593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19175,
    "Rank": 418611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19176,
    "Rank": 418661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19177,
    "Rank": 418726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19178,
    "Rank": 418748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19179,
    "Rank": 418802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19180,
    "Rank": 418984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19181,
    "Rank": 419041,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19182,
    "Rank": 419100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19183,
    "Rank": 419257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19184,
    "Rank": 419405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19185,
    "Rank": 419430,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19186,
    "Rank": 419492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19187,
    "Rank": 419594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19188,
    "Rank": 419797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19189,
    "Rank": 419904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19190,
    "Rank": 419948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19191,
    "Rank": 419969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19192,
    "Rank": 420048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19193,
    "Rank": 420073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19194,
    "Rank": 420076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19195,
    "Rank": 420459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19196,
    "Rank": 420586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19197,
    "Rank": 420674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19198,
    "Rank": 420702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19199,
    "Rank": 420703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19200,
    "Rank": 420808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19201,
    "Rank": 421029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19202,
    "Rank": 421038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19203,
    "Rank": 421040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19204,
    "Rank": 421073,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19205,
    "Rank": 421091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19206,
    "Rank": 421100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19207,
    "Rank": 421145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19208,
    "Rank": 421291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19209,
    "Rank": 421419,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,TIRUNELVELI,DEAN, TIRUNELVELI MEDICAL COLLEGE,\nHIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19210,
    "Rank": 421445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19211,
    "Rank": 421491,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS-Bhopal,,SAKET NAGAR BHOPAL, Madhya Pradesh, 462020",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19212,
    "Rank": 421611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19213,
    "Rank": 421628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19214,
    "Rank": 421701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19215,
    "Rank": 421730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19216,
    "Rank": 421756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19217,
    "Rank": 421768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19218,
    "Rank": 421933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19219,
    "Rank": 421990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19220,
    "Rank": 422204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19221,
    "Rank": 422208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19222,
    "Rank": 422242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19223,
    "Rank": 422255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19224,
    "Rank": 422260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19225,
    "Rank": 422440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19226,
    "Rank": 422491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19227,
    "Rank": 422499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19228,
    "Rank": 422628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19229,
    "Rank": 422640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19230,
    "Rank": 422852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19231,
    "Rank": 422940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19232,
    "Rank": 422964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19233,
    "Rank": 422987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19234,
    "Rank": 423015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19235,
    "Rank": 423047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19236,
    "Rank": 423159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19237,
    "Rank": 423203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19238,
    "Rank": 423240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19239,
    "Rank": 423415,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLL,JHANSI,M.L.B. MEDICAL COLLEGE, KANPUR ROAD, JHANSI, Uttar Pradesh, 284128",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19240,
    "Rank": 423416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19241,
    "Rank": 423453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19242,
    "Rank": 423534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19243,
    "Rank": 423578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19244,
    "Rank": 423645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19245,
    "Rank": 423695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19246,
    "Rank": 423737,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19247,
    "Rank": 423802,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19248,
    "Rank": 423809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19249,
    "Rank": 423923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19250,
    "Rank": 423964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19251,
    "Rank": 424072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19252,
    "Rank": 424099,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19253,
    "Rank": 424113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19254,
    "Rank": 424146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19255,
    "Rank": 424265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19256,
    "Rank": 424331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19257,
    "Rank": 424340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19258,
    "Rank": 424512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19259,
    "Rank": 424585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19260,
    "Rank": 424634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19261,
    "Rank": 424735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19262,
    "Rank": 424750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19263,
    "Rank": 424791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19264,
    "Rank": 424915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19265,
    "Rank": 424931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19266,
    "Rank": 424994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19267,
    "Rank": 425072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19268,
    "Rank": 425244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19269,
    "Rank": 425251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19270,
    "Rank": 425287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19271,
    "Rank": 425313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19272,
    "Rank": 425358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19273,
    "Rank": 425399,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19274,
    "Rank": 425484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19275,
    "Rank": 425605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19276,
    "Rank": 425640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19277,
    "Rank": 425662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19278,
    "Rank": 425863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19279,
    "Rank": 425879,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19280,
    "Rank": 425927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19281,
    "Rank": 426129,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 5 )"
  },
  {
    "SNo": 19282,
    "Rank": 426160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19283,
    "Rank": 426227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19284,
    "Rank": 426239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19285,
    "Rank": 426409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19286,
    "Rank": 426512,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19287,
    "Rank": 426614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19288,
    "Rank": 426750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19289,
    "Rank": 426864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19290,
    "Rank": 427004,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19291,
    "Rank": 427262,
    "Allotted Quota": "All India",
    "Allotted Institute": "INST OF PG MED EDU & RESEARCH,KOLKATA,244 AJC Bose Road, Kolkata, West\nBengal, 700020",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19292,
    "Rank": 427361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19293,
    "Rank": 427456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19294,
    "Rank": 427488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19295,
    "Rank": 427492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19296,
    "Rank": 427507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19297,
    "Rank": 427623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19298,
    "Rank": 427767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19299,
    "Rank": 427909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19300,
    "Rank": 428049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19301,
    "Rank": 428108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19302,
    "Rank": 428110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19303,
    "Rank": 428158,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19304,
    "Rank": 428233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19305,
    "Rank": 428273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19306,
    "Rank": 428280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19307,
    "Rank": 428472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19308,
    "Rank": 428554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19309,
    "Rank": 428596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19310,
    "Rank": 428681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19311,
    "Rank": 428739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19312,
    "Rank": 428799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19313,
    "Rank": 428808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19314,
    "Rank": 428870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19315,
    "Rank": 428876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19316,
    "Rank": 428896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19317,
    "Rank": 429046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19318,
    "Rank": 429077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19319,
    "Rank": 429308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19320,
    "Rank": 429372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19321,
    "Rank": 429474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19322,
    "Rank": 429500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19323,
    "Rank": 429529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19324,
    "Rank": 429550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19325,
    "Rank": 429688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19326,
    "Rank": 429725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19327,
    "Rank": 429745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19328,
    "Rank": 429769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19329,
    "Rank": 429870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19330,
    "Rank": 429977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19331,
    "Rank": 430049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19332,
    "Rank": 430165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19333,
    "Rank": 430174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19334,
    "Rank": 430379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19335,
    "Rank": 430402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19336,
    "Rank": 430450,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19337,
    "Rank": 430459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19338,
    "Rank": 430465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19339,
    "Rank": 430478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19340,
    "Rank": 430671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19341,
    "Rank": 430783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19342,
    "Rank": 430834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19343,
    "Rank": 430944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19344,
    "Rank": 430959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19345,
    "Rank": 430977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19346,
    "Rank": 431215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19347,
    "Rank": 431475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19348,
    "Rank": 431541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19349,
    "Rank": 431896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19350,
    "Rank": 431937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19351,
    "Rank": 431945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19352,
    "Rank": 432056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19353,
    "Rank": 432111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19354,
    "Rank": 432123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19355,
    "Rank": 432131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19356,
    "Rank": 432132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19357,
    "Rank": 432192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19358,
    "Rank": 432214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19359,
    "Rank": 432303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19360,
    "Rank": 432415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19361,
    "Rank": 432591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19362,
    "Rank": 432643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19363,
    "Rank": 432667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19364,
    "Rank": 432801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19365,
    "Rank": 432808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19366,
    "Rank": 432920,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19367,
    "Rank": 433029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19368,
    "Rank": 433116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19369,
    "Rank": 433502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19370,
    "Rank": 433525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19371,
    "Rank": 433582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19372,
    "Rank": 433703,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19373,
    "Rank": 433870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19374,
    "Rank": 433932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19375,
    "Rank": 433953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19376,
    "Rank": 433961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19377,
    "Rank": 434124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19378,
    "Rank": 434128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19379,
    "Rank": 434149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19380,
    "Rank": 434301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19381,
    "Rank": 434439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19382,
    "Rank": 434484,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19383,
    "Rank": 434667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19384,
    "Rank": 434693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19385,
    "Rank": 434791,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Manipal,Madhav Nagar, Manipal, Karnataka State India, Karnataka, 576104",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19386,
    "Rank": 434859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19387,
    "Rank": 434890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19388,
    "Rank": 434898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19389,
    "Rank": 434900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19390,
    "Rank": 434952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19391,
    "Rank": 435086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19392,
    "Rank": 435207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AKOLA,Collector Office Road, Near Ashok Watika, Akola. State Maharashtra. Pin 444001, Maharashtra, 444001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19393,
    "Rank": 435217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19394,
    "Rank": 435438,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19395,
    "Rank": 435452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19396,
    "Rank": 435511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19397,
    "Rank": 435519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Doon Medcial College, Dehradun,Government Doon Medical College, Dehrakhas Patel Nagar, Dehradun 248001, Uttarakhand, 248001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19398,
    "Rank": 435530,
    "Allotted Quota": "All India",
    "Allotted Institute": "ATAL BIHARI VAJPAYEE GOVERNMENT MEDICAL COLLEGE, VIDISHA,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA, Madhya Pradesh, 464001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19399,
    "Rank": 435620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19400,
    "Rank": 435639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19401,
    "Rank": 435678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19402,
    "Rank": 435748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19403,
    "Rank": 435792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19404,
    "Rank": 435926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19405,
    "Rank": 436056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19406,
    "Rank": 436064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19407,
    "Rank": 436166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19408,
    "Rank": 436180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19409,
    "Rank": 436322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19410,
    "Rank": 436570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19411,
    "Rank": 436781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19412,
    "Rank": 436968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19413,
    "Rank": 437058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19414,
    "Rank": 437075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19415,
    "Rank": 437128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19416,
    "Rank": 437158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19417,
    "Rank": 437194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19418,
    "Rank": 437271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19419,
    "Rank": 437394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19420,
    "Rank": 437396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19421,
    "Rank": 437397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19422,
    "Rank": 437462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19423,
    "Rank": 437474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19424,
    "Rank": 437694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19425,
    "Rank": 437748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19426,
    "Rank": 437760,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Raipur,Tatibandh, G E Road, Raipur Chhattisgarh, Pin - 492099, Chhattisgarh, 492099",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19427,
    "Rank": 437775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19428,
    "Rank": 437855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19429,
    "Rank": 437901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19430,
    "Rank": 437987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19431,
    "Rank": 438079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19432,
    "Rank": 438137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19433,
    "Rank": 438196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19434,
    "Rank": 438305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19435,
    "Rank": 438365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19436,
    "Rank": 438571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19437,
    "Rank": 438573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19438,
    "Rank": 438699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19439,
    "Rank": 438703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19440,
    "Rank": 438758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19441,
    "Rank": 438773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19442,
    "Rank": 438817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19443,
    "Rank": 438833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19444,
    "Rank": 438985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19445,
    "Rank": 439060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19446,
    "Rank": 439062,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19447,
    "Rank": 439076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19448,
    "Rank": 439099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19449,
    "Rank": 439313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19450,
    "Rank": 439323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19451,
    "Rank": 439428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19452,
    "Rank": 439463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19453,
    "Rank": 439490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19454,
    "Rank": 439512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19455,
    "Rank": 439542,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19456,
    "Rank": 439630,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19457,
    "Rank": 439643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19458,
    "Rank": 439713,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19459,
    "Rank": 439910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19460,
    "Rank": 440020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19461,
    "Rank": 440044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19462,
    "Rank": 440091,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19463,
    "Rank": 440159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19464,
    "Rank": 440174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19465,
    "Rank": 440282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19466,
    "Rank": 440333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19467,
    "Rank": 440605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19468,
    "Rank": 440620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19469,
    "Rank": 440802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19470,
    "Rank": 440909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19471,
    "Rank": 440922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19472,
    "Rank": 440964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19473,
    "Rank": 440975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19474,
    "Rank": 441006,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19475,
    "Rank": 441110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19476,
    "Rank": 441171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19477,
    "Rank": 441350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19478,
    "Rank": 441472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19479,
    "Rank": 441635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19480,
    "Rank": 441690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19481,
    "Rank": 441850,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19482,
    "Rank": 441894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19483,
    "Rank": 441943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19484,
    "Rank": 441994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19485,
    "Rank": 442069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19486,
    "Rank": 442104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19487,
    "Rank": 442137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19488,
    "Rank": 442179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19489,
    "Rank": 442385,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19490,
    "Rank": 442477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19491,
    "Rank": 442490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19492,
    "Rank": 442577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19493,
    "Rank": 442648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19494,
    "Rank": 442650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19495,
    "Rank": 442678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19496,
    "Rank": 442793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19497,
    "Rank": 442951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19498,
    "Rank": 443185,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19499,
    "Rank": 443205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19500,
    "Rank": 443231,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19501,
    "Rank": 443310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19502,
    "Rank": 443349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19503,
    "Rank": 443356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19504,
    "Rank": 443512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19505,
    "Rank": 443680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19506,
    "Rank": 443734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19507,
    "Rank": 443761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19508,
    "Rank": 443873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19509,
    "Rank": 443879,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19510,
    "Rank": 443882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19511,
    "Rank": 443895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19512,
    "Rank": 444029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19513,
    "Rank": 444196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19514,
    "Rank": 444366,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19515,
    "Rank": 444514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19516,
    "Rank": 444684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19517,
    "Rank": 444842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19518,
    "Rank": 444885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19519,
    "Rank": 444941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19520,
    "Rank": 445141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19521,
    "Rank": 445142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19522,
    "Rank": 445164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19523,
    "Rank": 445326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19524,
    "Rank": 445327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19525,
    "Rank": 445386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19526,
    "Rank": 445404,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19527,
    "Rank": 445412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19528,
    "Rank": 445545,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19529,
    "Rank": 445581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19530,
    "Rank": 445815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19531,
    "Rank": 445941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19532,
    "Rank": 445967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19533,
    "Rank": 445975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19534,
    "Rank": 446074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19535,
    "Rank": 446186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19536,
    "Rank": 446211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19537,
    "Rank": 446395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19538,
    "Rank": 446412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19539,
    "Rank": 446455,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19540,
    "Rank": 446508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19541,
    "Rank": 446535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19542,
    "Rank": 446583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19543,
    "Rank": 446608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19544,
    "Rank": 446612,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19545,
    "Rank": 446675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19546,
    "Rank": 447180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19547,
    "Rank": 447209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College Musheerabad Secunderabad,MUSHEERABAD, SECUNDERABAD, Telangana, 500003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19548,
    "Rank": 447255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19549,
    "Rank": 447263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19550,
    "Rank": 447275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19551,
    "Rank": 447575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19552,
    "Rank": 447654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical College Koti ,HYDERABAD, Telangana, 500095",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19553,
    "Rank": 447760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19554,
    "Rank": 447853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19555,
    "Rank": 447872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19556,
    "Rank": 447942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19557,
    "Rank": 447970,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19558,
    "Rank": 447990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19559,
    "Rank": 448111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19560,
    "Rank": 448167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19561,
    "Rank": 448360,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19562,
    "Rank": 448428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19563,
    "Rank": 448449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19564,
    "Rank": 448699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19565,
    "Rank": 448704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19566,
    "Rank": 448758,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19567,
    "Rank": 449074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19568,
    "Rank": 449164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19569,
    "Rank": 449273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19570,
    "Rank": 449365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19571,
    "Rank": 449597,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19572,
    "Rank": 449626,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19573,
    "Rank": 449632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19574,
    "Rank": 449735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19575,
    "Rank": 449796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19576,
    "Rank": 449931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19577,
    "Rank": 449939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19578,
    "Rank": 449940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19579,
    "Rank": 449959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19580,
    "Rank": 449993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19581,
    "Rank": 449997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19582,
    "Rank": 450179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19583,
    "Rank": 450186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19584,
    "Rank": 450223,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19585,
    "Rank": 450306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19586,
    "Rank": 450370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19587,
    "Rank": 450375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19588,
    "Rank": 450384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19589,
    "Rank": 450511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19590,
    "Rank": 450532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19591,
    "Rank": 450608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19592,
    "Rank": 450629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19593,
    "Rank": 450745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College and Hospital, JORHAT,Kushal Konwar Path, Barbheta, Jorhat, Assam, 785001, Assam, 785001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19594,
    "Rank": 450783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19595,
    "Rank": 450904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19596,
    "Rank": 450941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19597,
    "Rank": 451022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19598,
    "Rank": 451036,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19599,
    "Rank": 451076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19600,
    "Rank": 451381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19601,
    "Rank": 451397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Darbhanga Medical College, Laheriasarai,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, 846003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19602,
    "Rank": 451506,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19603,
    "Rank": 451544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19604,
    "Rank": 451844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19605,
    "Rank": 451849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19606,
    "Rank": 451877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19607,
    "Rank": 451941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19608,
    "Rank": 451979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19609,
    "Rank": 452012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19610,
    "Rank": 452313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19611,
    "Rank": 452351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19612,
    "Rank": 452377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19613,
    "Rank": 452489,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19614,
    "Rank": 452626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19615,
    "Rank": 452636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19616,
    "Rank": 452638,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,ASHOK RAJPATH, PATNA, Bihar, 800004",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19617,
    "Rank": 452659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19618,
    "Rank": 452664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19619,
    "Rank": 452666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19620,
    "Rank": 452675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19621,
    "Rank": 452704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19622,
    "Rank": 452783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19623,
    "Rank": 452809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19624,
    "Rank": 452971,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19625,
    "Rank": 453010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19626,
    "Rank": 453051,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19627,
    "Rank": 453156,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19628,
    "Rank": 453250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19629,
    "Rank": 453260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19630,
    "Rank": 453264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19631,
    "Rank": 453414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19632,
    "Rank": 453590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19633,
    "Rank": 453858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19634,
    "Rank": 453926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19635,
    "Rank": 454064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19636,
    "Rank": 454105,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19637,
    "Rank": 454181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19638,
    "Rank": 454301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19639,
    "Rank": 454369,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19640,
    "Rank": 454376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19641,
    "Rank": 454438,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19642,
    "Rank": 454466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19643,
    "Rank": 454524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College, Kakinada,PITHAPURAM ROAD, KAKINADA, Andhra Pradesh, 533003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19644,
    "Rank": 454571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19645,
    "Rank": 454611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19646,
    "Rank": 454693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19647,
    "Rank": 454740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19648,
    "Rank": 454779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19649,
    "Rank": 454786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19650,
    "Rank": 454826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19651,
    "Rank": 454836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19652,
    "Rank": 454861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19653,
    "Rank": 454885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19654,
    "Rank": 454928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19655,
    "Rank": 454929,
    "Allotted Quota": "All India",
    "Allotted Institute": "T.D. MEDICAL COLLEGE, ALLAPPUZHA,GOVERNMENT T.D.MEDICAL COLLEGE, VANDANAM.PO, ALAPPUZHA, Kerala, 688005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19656,
    "Rank": 455183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19657,
    "Rank": 455196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19658,
    "Rank": 455272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19659,
    "Rank": 455287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19660,
    "Rank": 455305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19661,
    "Rank": 455315,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19662,
    "Rank": 455363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19663,
    "Rank": 455368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19664,
    "Rank": 455435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19665,
    "Rank": 455496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19666,
    "Rank": 455552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19667,
    "Rank": 455627,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19668,
    "Rank": 455736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19669,
    "Rank": 456014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19670,
    "Rank": 456057,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19671,
    "Rank": 456159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19672,
    "Rank": 456189,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19673,
    "Rank": 456222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19674,
    "Rank": 456233,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19675,
    "Rank": 456244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19676,
    "Rank": 456255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19677,
    "Rank": 456276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19678,
    "Rank": 456307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19679,
    "Rank": 456475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19680,
    "Rank": 456476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19681,
    "Rank": 456498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19682,
    "Rank": 456574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19683,
    "Rank": 456696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19684,
    "Rank": 456715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19685,
    "Rank": 456723,
    "Allotted Quota": "B.Sc Nursing Delhi NCR",
    "Allotted Institute": "College of Nursing Florence Nightingale CON, New Delhi,collegeofnursinggtbh@gmail.com, Delhi (NCT), 110095 (Female Seat only )",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19686,
    "Rank": 456809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19687,
    "Rank": 456846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19688,
    "Rank": 456875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19689,
    "Rank": 456900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19690,
    "Rank": 456901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19691,
    "Rank": 456962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19692,
    "Rank": 456999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19693,
    "Rank": 457003,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19694,
    "Rank": 457116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19695,
    "Rank": 457133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19696,
    "Rank": 457160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19697,
    "Rank": 457190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19698,
    "Rank": 457402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19699,
    "Rank": 457508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19700,
    "Rank": 457658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19701,
    "Rank": 457896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19702,
    "Rank": 458028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19703,
    "Rank": 458167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19704,
    "Rank": 458233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19705,
    "Rank": 458321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19706,
    "Rank": 458338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19707,
    "Rank": 458446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19708,
    "Rank": 458525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19709,
    "Rank": 458565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19710,
    "Rank": 458648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19711,
    "Rank": 458660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19712,
    "Rank": 458665,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19713,
    "Rank": 458710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19714,
    "Rank": 458821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19715,
    "Rank": 458869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19716,
    "Rank": 458890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19717,
    "Rank": 458968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19718,
    "Rank": 459099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19719,
    "Rank": 459220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19720,
    "Rank": 459279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19721,
    "Rank": 459289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19722,
    "Rank": 459304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19723,
    "Rank": 459387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19724,
    "Rank": 459493,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19725,
    "Rank": 459558,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19726,
    "Rank": 459611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19727,
    "Rank": 459694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19728,
    "Rank": 459716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19729,
    "Rank": 459762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19730,
    "Rank": 459900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19731,
    "Rank": 459954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19732,
    "Rank": 460011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19733,
    "Rank": 460082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19734,
    "Rank": 460227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19735,
    "Rank": 460255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19736,
    "Rank": 460295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19737,
    "Rank": 460481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19738,
    "Rank": 460491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19739,
    "Rank": 460602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19740,
    "Rank": 460627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19741,
    "Rank": 460684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19742,
    "Rank": 460981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19743,
    "Rank": 461067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19744,
    "Rank": 461101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19745,
    "Rank": 461220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19746,
    "Rank": 461260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19747,
    "Rank": 461302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19748,
    "Rank": 461368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19749,
    "Rank": 461379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19750,
    "Rank": 461413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19751,
    "Rank": 461554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19752,
    "Rank": 461580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19753,
    "Rank": 461593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19754,
    "Rank": 461764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19755,
    "Rank": 461770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19756,
    "Rank": 461801,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19757,
    "Rank": 461826,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19758,
    "Rank": 461912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19759,
    "Rank": 461947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19760,
    "Rank": 462004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19761,
    "Rank": 462034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19762,
    "Rank": 462037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19763,
    "Rank": 462045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19764,
    "Rank": 462082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19765,
    "Rank": 462217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19766,
    "Rank": 462248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19767,
    "Rank": 462349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19768,
    "Rank": 462396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19769,
    "Rank": 462433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19770,
    "Rank": 462661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19771,
    "Rank": 462749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19772,
    "Rank": 462870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19773,
    "Rank": 462925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19774,
    "Rank": 462946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19775,
    "Rank": 463088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19776,
    "Rank": 463094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19777,
    "Rank": 463145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19778,
    "Rank": 463244,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19779,
    "Rank": 463473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19780,
    "Rank": 463583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19781,
    "Rank": 463682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19782,
    "Rank": 463713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19783,
    "Rank": 463745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19784,
    "Rank": 463887,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19785,
    "Rank": 463910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19786,
    "Rank": 463925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19787,
    "Rank": 463936,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19788,
    "Rank": 464084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19789,
    "Rank": 464191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19790,
    "Rank": 464262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19791,
    "Rank": 464298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19792,
    "Rank": 464348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19793,
    "Rank": 464358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19794,
    "Rank": 464368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19795,
    "Rank": 464384,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19796,
    "Rank": 464515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19797,
    "Rank": 464566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19798,
    "Rank": 464608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19799,
    "Rank": 464739,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19800,
    "Rank": 464755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19801,
    "Rank": 464766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramulla ,Kanth Bagh Baramulla, Jammu and Kashmir, Jammu And Kashmir, 193101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19802,
    "Rank": 464848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19803,
    "Rank": 464915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19804,
    "Rank": 464983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19805,
    "Rank": 465111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19806,
    "Rank": 465296,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19807,
    "Rank": 465422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19808,
    "Rank": 465457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19809,
    "Rank": 465512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19810,
    "Rank": 465660,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19811,
    "Rank": 465694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19812,
    "Rank": 465704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19813,
    "Rank": 465720,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19814,
    "Rank": 465741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19815,
    "Rank": 465795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19816,
    "Rank": 465803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19817,
    "Rank": 465871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19818,
    "Rank": 466000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19819,
    "Rank": 466012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19820,
    "Rank": 466019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19821,
    "Rank": 466026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19822,
    "Rank": 466039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19823,
    "Rank": 466081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19824,
    "Rank": 466124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19825,
    "Rank": 466204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19826,
    "Rank": 466306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19827,
    "Rank": 466389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19828,
    "Rank": 466402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19829,
    "Rank": 466412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19830,
    "Rank": 466519,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College, Manipal Univ., Mangalore,Light house Hill Road, Mangalore, India, Karnataka, 575001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19831,
    "Rank": 466596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19832,
    "Rank": 466601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19833,
    "Rank": 466602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19834,
    "Rank": 466638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19835,
    "Rank": 466811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19836,
    "Rank": 467053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19837,
    "Rank": 467071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19838,
    "Rank": 467128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19839,
    "Rank": 467231,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19840,
    "Rank": 467484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19841,
    "Rank": 467821,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19842,
    "Rank": 467867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19843,
    "Rank": 467977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19844,
    "Rank": 467990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19845,
    "Rank": 468088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19846,
    "Rank": 468121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19847,
    "Rank": 468178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19848,
    "Rank": 468181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19849,
    "Rank": 468203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19850,
    "Rank": 468267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19851,
    "Rank": 468293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19852,
    "Rank": 468323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19853,
    "Rank": 468358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19854,
    "Rank": 468372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19855,
    "Rank": 468585,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19856,
    "Rank": 468690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19857,
    "Rank": 468802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19858,
    "Rank": 468821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19859,
    "Rank": 468966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19860,
    "Rank": 468982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19861,
    "Rank": 469021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19862,
    "Rank": 469056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19863,
    "Rank": 469120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19864,
    "Rank": 469362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19865,
    "Rank": 469580,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19866,
    "Rank": 469682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19867,
    "Rank": 469692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19868,
    "Rank": 469696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19869,
    "Rank": 469767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19870,
    "Rank": 469775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19871,
    "Rank": 469788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19872,
    "Rank": 469794,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19873,
    "Rank": 469817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19874,
    "Rank": 469973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19875,
    "Rank": 469977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19876,
    "Rank": 470068,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19877,
    "Rank": 470083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19878,
    "Rank": 470114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19879,
    "Rank": 470124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19880,
    "Rank": 470144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19881,
    "Rank": 470171,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "IMS BHU Dental, Varanasi,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 221105",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19882,
    "Rank": 470245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19883,
    "Rank": 470433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19884,
    "Rank": 470454,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19885,
    "Rank": 470822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19886,
    "Rank": 471015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19887,
    "Rank": 471021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19888,
    "Rank": 471075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19889,
    "Rank": 471231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19890,
    "Rank": 471282,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19891,
    "Rank": 471316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19892,
    "Rank": 471367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19893,
    "Rank": 471396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19894,
    "Rank": 471400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19895,
    "Rank": 471407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19896,
    "Rank": 471619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19897,
    "Rank": 471646,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARAJA K.C. GAJAPATI M.C.,BRAHMAPUR,MKCG MEDICAL COLLEGE CAMPUS, BERHAMPUR, GANJAM, Odisha, 760004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19898,
    "Rank": 471651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19899,
    "Rank": 471863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19900,
    "Rank": 471911,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19901,
    "Rank": 472039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19902,
    "Rank": 472367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19903,
    "Rank": 472372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19904,
    "Rank": 472451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19905,
    "Rank": 472494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19906,
    "Rank": 472545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19907,
    "Rank": 472571,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19908,
    "Rank": 472596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19909,
    "Rank": 472610,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19910,
    "Rank": 472666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19911,
    "Rank": 472687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19912,
    "Rank": 472766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19913,
    "Rank": 472803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19914,
    "Rank": 472934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19915,
    "Rank": 473024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19916,
    "Rank": 473030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19917,
    "Rank": 473043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19918,
    "Rank": 473084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19919,
    "Rank": 473343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19920,
    "Rank": 473635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19921,
    "Rank": 473784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19922,
    "Rank": 473794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19923,
    "Rank": 473798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19924,
    "Rank": 473869,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19925,
    "Rank": 474063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19926,
    "Rank": 474065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19927,
    "Rank": 474160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19928,
    "Rank": 474406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19929,
    "Rank": 474413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19930,
    "Rank": 474420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19931,
    "Rank": 474586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19932,
    "Rank": 474762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19933,
    "Rank": 474899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19934,
    "Rank": 475042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19935,
    "Rank": 475049,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19936,
    "Rank": 475064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19937,
    "Rank": 475087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19938,
    "Rank": 475387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19939,
    "Rank": 475507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19940,
    "Rank": 475577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19941,
    "Rank": 475592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19942,
    "Rank": 475945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19943,
    "Rank": 476013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19944,
    "Rank": 476064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19945,
    "Rank": 476116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19946,
    "Rank": 476220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19947,
    "Rank": 476302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19948,
    "Rank": 476640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19949,
    "Rank": 476650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19950,
    "Rank": 476840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19951,
    "Rank": 476870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19952,
    "Rank": 476967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19953,
    "Rank": 477011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19954,
    "Rank": 477129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19955,
    "Rank": 477215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19956,
    "Rank": 477251,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19957,
    "Rank": 477287,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.C.GOVT MEDICAL COLLEGE,,NANDED,VISHNUPURI NANDED MAHARASHTRA 431606, Maharashtra, 431606",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19958,
    "Rank": 477295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19959,
    "Rank": 477319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19960,
    "Rank": 477502,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19961,
    "Rank": 477504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19962,
    "Rank": 477884,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19963,
    "Rank": 477919,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19964,
    "Rank": 477930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19965,
    "Rank": 477934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19966,
    "Rank": 477989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19967,
    "Rank": 477995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19968,
    "Rank": 478015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19969,
    "Rank": 478062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19970,
    "Rank": 478086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19971,
    "Rank": 478091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19972,
    "Rank": 478098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19973,
    "Rank": 478145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19974,
    "Rank": 478179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19975,
    "Rank": 478194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19976,
    "Rank": 478207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19977,
    "Rank": 478287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19978,
    "Rank": 478416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19979,
    "Rank": 478418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19980,
    "Rank": 478488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19981,
    "Rank": 478491,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19982,
    "Rank": 478556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19983,
    "Rank": 478583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19984,
    "Rank": 478584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19985,
    "Rank": 478654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19986,
    "Rank": 478666,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19987,
    "Rank": 478731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19988,
    "Rank": 478867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19989,
    "Rank": 479032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19990,
    "Rank": 479065,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19991,
    "Rank": 479197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19992,
    "Rank": 479239,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19993,
    "Rank": 479486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19994,
    "Rank": 479542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19995,
    "Rank": 479573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19996,
    "Rank": 479664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19997,
    "Rank": 479820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19998,
    "Rank": 479886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 19999,
    "Rank": 479901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20000,
    "Rank": 479933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20001,
    "Rank": 480001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20002,
    "Rank": 480030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20003,
    "Rank": 480154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20004,
    "Rank": 480197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20005,
    "Rank": 480225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20006,
    "Rank": 480237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20007,
    "Rank": 480263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20008,
    "Rank": 480368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20009,
    "Rank": 480448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20010,
    "Rank": 480471,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20011,
    "Rank": 480492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20012,
    "Rank": 480780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20013,
    "Rank": 480920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20014,
    "Rank": 480997,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20015,
    "Rank": 481353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20016,
    "Rank": 481470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20017,
    "Rank": 481521,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20018,
    "Rank": 481542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20019,
    "Rank": 481659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20020,
    "Rank": 481690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20021,
    "Rank": 481870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20022,
    "Rank": 482025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20023,
    "Rank": 482047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20024,
    "Rank": 482049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20025,
    "Rank": 482087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20026,
    "Rank": 482092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20027,
    "Rank": 482131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20028,
    "Rank": 482166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20029,
    "Rank": 482211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20030,
    "Rank": 482236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20031,
    "Rank": 482263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20032,
    "Rank": 482325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20033,
    "Rank": 482351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20034,
    "Rank": 482375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20035,
    "Rank": 482456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20036,
    "Rank": 482507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20037,
    "Rank": 482514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20038,
    "Rank": 482524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20039,
    "Rank": 482605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20040,
    "Rank": 482681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20041,
    "Rank": 482702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20042,
    "Rank": 482729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20043,
    "Rank": 482841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Govt. Med. College, Chamba,Pt. Jawaharlal Nehru Govt. Medical College Chamba, Himachal Pradesh, 176310",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20044,
    "Rank": 482880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20045,
    "Rank": 483139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20046,
    "Rank": 483163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20047,
    "Rank": 483217,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20048,
    "Rank": 483291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20049,
    "Rank": 483299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20050,
    "Rank": 483341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20051,
    "Rank": 483379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20052,
    "Rank": 483401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20053,
    "Rank": 483525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20054,
    "Rank": 483688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20055,
    "Rank": 483708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20056,
    "Rank": 483720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20057,
    "Rank": 483786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20058,
    "Rank": 483826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20059,
    "Rank": 483843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20060,
    "Rank": 483854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20061,
    "Rank": 484038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20062,
    "Rank": 484050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20063,
    "Rank": 484277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20064,
    "Rank": 484310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20065,
    "Rank": 484380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20066,
    "Rank": 484398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20067,
    "Rank": 484421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20068,
    "Rank": 484492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20069,
    "Rank": 484532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20070,
    "Rank": 484588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20071,
    "Rank": 484622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20072,
    "Rank": 484707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20073,
    "Rank": 484746,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20074,
    "Rank": 484995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20075,
    "Rank": 485041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20076,
    "Rank": 485074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20077,
    "Rank": 485193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20078,
    "Rank": 485341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20079,
    "Rank": 485788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20080,
    "Rank": 485981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20081,
    "Rank": 486213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20082,
    "Rank": 486279,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20083,
    "Rank": 486421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20084,
    "Rank": 486535,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20085,
    "Rank": 486559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20086,
    "Rank": 486573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20087,
    "Rank": 486579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20088,
    "Rank": 486580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20089,
    "Rank": 486585,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College AND PGIMSR, Chennai,ASHOK PILLAR ROAD KK NAGAR CHENNAI, Tamil Nadu, 600078",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20090,
    "Rank": 486655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20091,
    "Rank": 486818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20092,
    "Rank": 486821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20093,
    "Rank": 486862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20094,
    "Rank": 486895,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Medical Sciences, Jaipur,RUHS COLLEGE OF MEDICAL SCIENCES, SECTOR -11, PRATAP NAGAR, SANGANER, JAIPUR 302033, Rajasthan, 302033",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20095,
    "Rank": 486913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20096,
    "Rank": 486936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20097,
    "Rank": 486949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20098,
    "Rank": 487145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20099,
    "Rank": 487228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20100,
    "Rank": 487275,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Med. College, Sonepat,BPS GOVT MEDICAL COLLEGE FOR WOMEN\nKHANPUR KALAN SONEPAT, Haryana, 131305 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20101,
    "Rank": 487650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20102,
    "Rank": 487713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20103,
    "Rank": 487740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20104,
    "Rank": 487890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20105,
    "Rank": 487930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20106,
    "Rank": 488001,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20107,
    "Rank": 488017,
    "Allotted Quota": "All India",
    "Allotted Institute": "SARDAR PATEL MEDICAL COLLEGE, BIKANER,MEDICAL COLLEGE ROAD,\nBIKANER, Rajasthan, 334003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20108,
    "Rank": 488143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20109,
    "Rank": 488196,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20110,
    "Rank": 488302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20111,
    "Rank": 488322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20112,
    "Rank": 488393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20113,
    "Rank": 488460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20114,
    "Rank": 488534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20115,
    "Rank": 488546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20116,
    "Rank": 488650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20117,
    "Rank": 488767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20118,
    "Rank": 488848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20119,
    "Rank": 488912,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. VAISHAMPAYAM MEMORIAL M.C.,SHOLAPUR,In Front of District Civil Court, Solapur., Maharashtra, 413003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20120,
    "Rank": 488979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20121,
    "Rank": 489078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20122,
    "Rank": 489185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20123,
    "Rank": 489197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20124,
    "Rank": 489266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20125,
    "Rank": 489307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20126,
    "Rank": 489456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20127,
    "Rank": 489480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20128,
    "Rank": 489485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20129,
    "Rank": 489563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20130,
    "Rank": 489743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Konni ,GOVERNMENT MEDICAL COLLEGE KONNI KUMMANNOOR PO KONNI PATHANAMTHITTA 689691, Kerala, 689691",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20131,
    "Rank": 489769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20132,
    "Rank": 489975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20133,
    "Rank": 490004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20134,
    "Rank": 490018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20135,
    "Rank": 490042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20136,
    "Rank": 490097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20137,
    "Rank": 490181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20138,
    "Rank": 490217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20139,
    "Rank": 490337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20140,
    "Rank": 490367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20141,
    "Rank": 490408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20142,
    "Rank": 490579,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20143,
    "Rank": 490816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20144,
    "Rank": 490863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20145,
    "Rank": 491011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20146,
    "Rank": 491014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20147,
    "Rank": 491070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20148,
    "Rank": 491183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20149,
    "Rank": 491197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20150,
    "Rank": 491216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20151,
    "Rank": 491263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20152,
    "Rank": 491372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20153,
    "Rank": 491530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20154,
    "Rank": 491559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20155,
    "Rank": 491614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20156,
    "Rank": 491676,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20157,
    "Rank": 491736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20158,
    "Rank": 491738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20159,
    "Rank": 491758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20160,
    "Rank": 491820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20161,
    "Rank": 491945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20162,
    "Rank": 492020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20163,
    "Rank": 492032,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20164,
    "Rank": 492065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20165,
    "Rank": 492163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20166,
    "Rank": 492181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20167,
    "Rank": 492189,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20168,
    "Rank": 492191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20169,
    "Rank": 492205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20170,
    "Rank": 492275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20171,
    "Rank": 492575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20172,
    "Rank": 492582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20173,
    "Rank": 492766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20174,
    "Rank": 493064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20175,
    "Rank": 493083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20176,
    "Rank": 493092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20177,
    "Rank": 493118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20178,
    "Rank": 493136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20179,
    "Rank": 493186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20180,
    "Rank": 493187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20181,
    "Rank": 493257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20182,
    "Rank": 493447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20183,
    "Rank": 493580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20184,
    "Rank": 493593,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20185,
    "Rank": 493809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20186,
    "Rank": 493849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20187,
    "Rank": 493923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20188,
    "Rank": 494020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20189,
    "Rank": 494097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20190,
    "Rank": 494214,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20191,
    "Rank": 494270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20192,
    "Rank": 494313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20193,
    "Rank": 494507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20194,
    "Rank": 494617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20195,
    "Rank": 494628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20196,
    "Rank": 494635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20197,
    "Rank": 494747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20198,
    "Rank": 494764,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20199,
    "Rank": 494782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20200,
    "Rank": 494797,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20201,
    "Rank": 494848,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20202,
    "Rank": 494912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20203,
    "Rank": 494951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20204,
    "Rank": 494994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20205,
    "Rank": 495088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20206,
    "Rank": 495144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20207,
    "Rank": 495148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20208,
    "Rank": 495208,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20209,
    "Rank": 495317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20210,
    "Rank": 495443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20211,
    "Rank": 495561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20212,
    "Rank": 495666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20213,
    "Rank": 495781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20214,
    "Rank": 495793,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20215,
    "Rank": 495883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20216,
    "Rank": 495914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20217,
    "Rank": 495957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20218,
    "Rank": 496071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20219,
    "Rank": 496129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20220,
    "Rank": 496165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20221,
    "Rank": 496174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20222,
    "Rank": 496368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20223,
    "Rank": 496379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20224,
    "Rank": 496550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20225,
    "Rank": 496672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20226,
    "Rank": 496977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20227,
    "Rank": 497011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20228,
    "Rank": 497080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20229,
    "Rank": 497147,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20230,
    "Rank": 497231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20231,
    "Rank": 497453,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20232,
    "Rank": 497599,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20233,
    "Rank": 497782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20234,
    "Rank": 497935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20235,
    "Rank": 497969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20236,
    "Rank": 497992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20237,
    "Rank": 498081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20238,
    "Rank": 498237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20239,
    "Rank": 498446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20240,
    "Rank": 498594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20241,
    "Rank": 498610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20242,
    "Rank": 498764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20243,
    "Rank": 498834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20244,
    "Rank": 499028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20245,
    "Rank": 499037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20246,
    "Rank": 499162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20247,
    "Rank": 499187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20248,
    "Rank": 499198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20249,
    "Rank": 499244,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20250,
    "Rank": 499254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20251,
    "Rank": 499260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20252,
    "Rank": 499272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20253,
    "Rank": 499302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20254,
    "Rank": 499306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20255,
    "Rank": 499307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20256,
    "Rank": 499377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20257,
    "Rank": 499415,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20258,
    "Rank": 499450,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20259,
    "Rank": 499636,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20260,
    "Rank": 499682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20261,
    "Rank": 499759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20262,
    "Rank": 499760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20263,
    "Rank": 499771,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20264,
    "Rank": 499821,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20265,
    "Rank": 499956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20266,
    "Rank": 500075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20267,
    "Rank": 500140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20268,
    "Rank": 500171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20269,
    "Rank": 500279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20270,
    "Rank": 500331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20271,
    "Rank": 500583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20272,
    "Rank": 500719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20273,
    "Rank": 500903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees State Insurance Corporation Medical College, Alwar,ESIC Medical College and Hospital Desula MIA Alwar Rajasthan India 301030, Rajasthan, 301030",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20274,
    "Rank": 500934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20275,
    "Rank": 500998,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20276,
    "Rank": 501095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20277,
    "Rank": 501117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20278,
    "Rank": 501199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20279,
    "Rank": 501237,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20280,
    "Rank": 501290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20281,
    "Rank": 501301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20282,
    "Rank": 501380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20283,
    "Rank": 501430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20284,
    "Rank": 501433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20285,
    "Rank": 501436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20286,
    "Rank": 501482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20287,
    "Rank": 501511,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20288,
    "Rank": 501550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20289,
    "Rank": 501600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20290,
    "Rank": 501788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20291,
    "Rank": 501878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20292,
    "Rank": 501943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20293,
    "Rank": 501946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20294,
    "Rank": 502014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20295,
    "Rank": 502063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20296,
    "Rank": 502187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and ESIC Hospital, Coimbatore,KAMARAJAR ROAD, VARADHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20297,
    "Rank": 502221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20298,
    "Rank": 502511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20299,
    "Rank": 502550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20300,
    "Rank": 502576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20301,
    "Rank": 502588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20302,
    "Rank": 503088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20303,
    "Rank": 503097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20304,
    "Rank": 503249,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20305,
    "Rank": 503285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20306,
    "Rank": 503868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20307,
    "Rank": 503900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20308,
    "Rank": 504151,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20309,
    "Rank": 504164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20310,
    "Rank": 504316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20311,
    "Rank": 504472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20312,
    "Rank": 504522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20313,
    "Rank": 504671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20314,
    "Rank": 504689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20315,
    "Rank": 504740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20316,
    "Rank": 504846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20317,
    "Rank": 504869,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20318,
    "Rank": 504871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20319,
    "Rank": 504905,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20320,
    "Rank": 504945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20321,
    "Rank": 505076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20322,
    "Rank": 505097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20323,
    "Rank": 505178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20324,
    "Rank": 505279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20325,
    "Rank": 505458,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20326,
    "Rank": 505658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20327,
    "Rank": 505664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20328,
    "Rank": 505718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20329,
    "Rank": 505743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20330,
    "Rank": 505785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20331,
    "Rank": 505837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20332,
    "Rank": 506007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20333,
    "Rank": 506025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20334,
    "Rank": 506115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20335,
    "Rank": 506189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20336,
    "Rank": 506261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20337,
    "Rank": 506583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20338,
    "Rank": 506655,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20339,
    "Rank": 506672,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20340,
    "Rank": 506816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20341,
    "Rank": 506817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20342,
    "Rank": 506909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20343,
    "Rank": 506967,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20344,
    "Rank": 506984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20345,
    "Rank": 507030,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 14 )"
  },
  {
    "SNo": 20346,
    "Rank": 507102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20347,
    "Rank": 507260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20348,
    "Rank": 507310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20349,
    "Rank": 507316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20350,
    "Rank": 507317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20351,
    "Rank": 507664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20352,
    "Rank": 507671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20353,
    "Rank": 507703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20354,
    "Rank": 507799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20355,
    "Rank": 507838,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20356,
    "Rank": 507896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20357,
    "Rank": 508045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20358,
    "Rank": 508060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20359,
    "Rank": 508450,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20360,
    "Rank": 508460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20361,
    "Rank": 508519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20362,
    "Rank": 508548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20363,
    "Rank": 508609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20364,
    "Rank": 508651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20365,
    "Rank": 508675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda,RANI DURGAVATI MEDICAL COLLEGE, NARAINI ROAD, BANDA-210001, Uttar Pradesh, 210001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20366,
    "Rank": 508679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20367,
    "Rank": 508865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20368,
    "Rank": 508869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20369,
    "Rank": 508958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20370,
    "Rank": 509018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20371,
    "Rank": 509294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20372,
    "Rank": 509314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20373,
    "Rank": 509478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20374,
    "Rank": 509674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20375,
    "Rank": 509872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20376,
    "Rank": 509938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Barasat Government Medical College & Hospital, West Bengal,80 Jessore Road Banamalipur JL NO 80and Uttorhat JL no 78 Barasat North Twenty Four Parganas 700124, West Bengal, 700124",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20377,
    "Rank": 509997,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR.S.N. MEDICAL COLLEGE, JODHPUR,RESIDENCY ROAD SHASTRI NAGAR JODHPUR RAJASTHAN 342 003, Rajasthan, 342003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20378,
    "Rank": 510014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20379,
    "Rank": 510178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20380,
    "Rank": 510212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20381,
    "Rank": 510316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20382,
    "Rank": 510459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20383,
    "Rank": 510474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20384,
    "Rank": 510555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20385,
    "Rank": 510755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20386,
    "Rank": 510913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20387,
    "Rank": 510988,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20388,
    "Rank": 511026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20389,
    "Rank": 511076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20390,
    "Rank": 511088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20391,
    "Rank": 511164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20392,
    "Rank": 511311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20393,
    "Rank": 511396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20394,
    "Rank": 511478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20395,
    "Rank": 511535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20396,
    "Rank": 511865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20397,
    "Rank": 511973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20398,
    "Rank": 512009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20399,
    "Rank": 512142,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, NAGPUR,Hanuman Nagar,Nagpur, Maharashtra, 440003",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20400,
    "Rank": 512194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20401,
    "Rank": 512197,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20402,
    "Rank": 512451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20403,
    "Rank": 512668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20404,
    "Rank": 512682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20405,
    "Rank": 512758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20406,
    "Rank": 512906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20407,
    "Rank": 512940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20408,
    "Rank": 513149,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20409,
    "Rank": 513233,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20410,
    "Rank": 513238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20411,
    "Rank": 513633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20412,
    "Rank": 513634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20413,
    "Rank": 513671,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE, GWALIOR,VEER SAVARKAR ROAD, GWALIOR,\nMadhya Pradesh, 474009",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20414,
    "Rank": 513796,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20415,
    "Rank": 513933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20416,
    "Rank": 513974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20417,
    "Rank": 514104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20418,
    "Rank": 514120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20419,
    "Rank": 514147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20420,
    "Rank": 514163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20421,
    "Rank": 514254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20422,
    "Rank": 514524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20423,
    "Rank": 514567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20424,
    "Rank": 514592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20425,
    "Rank": 514616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20426,
    "Rank": 514618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20427,
    "Rank": 514784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20428,
    "Rank": 514788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20429,
    "Rank": 514800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20430,
    "Rank": 514942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20431,
    "Rank": 515009,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20432,
    "Rank": 515082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20433,
    "Rank": 515098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20434,
    "Rank": 515191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20435,
    "Rank": 515249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20436,
    "Rank": 515314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20437,
    "Rank": 515412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20438,
    "Rank": 515466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20439,
    "Rank": 515479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20440,
    "Rank": 515571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20441,
    "Rank": 515589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20442,
    "Rank": 515634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20443,
    "Rank": 515658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20444,
    "Rank": 515741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20445,
    "Rank": 515817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20446,
    "Rank": 515822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20447,
    "Rank": 515898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20448,
    "Rank": 515906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20449,
    "Rank": 515943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20450,
    "Rank": 515990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20451,
    "Rank": 515991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20452,
    "Rank": 516166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20453,
    "Rank": 516185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20454,
    "Rank": 516236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20455,
    "Rank": 516306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20456,
    "Rank": 516331,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20457,
    "Rank": 516336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20458,
    "Rank": 516416,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20459,
    "Rank": 516479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20460,
    "Rank": 516494,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20461,
    "Rank": 516593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20462,
    "Rank": 516684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20463,
    "Rank": 516727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20464,
    "Rank": 516769,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20465,
    "Rank": 516787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20466,
    "Rank": 516846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20467,
    "Rank": 516960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20468,
    "Rank": 517013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20469,
    "Rank": 517020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20470,
    "Rank": 517023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20471,
    "Rank": 517134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20472,
    "Rank": 517147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20473,
    "Rank": 517261,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20474,
    "Rank": 517278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20475,
    "Rank": 517411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20476,
    "Rank": 517593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20477,
    "Rank": 517654,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MED COLL,KOLKATA,32, GORACHAND ROAD, PARK CIRCUS, KOLKATA - 700014, West Bengal, 700014",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20478,
    "Rank": 517694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20479,
    "Rank": 517929,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, HANDWARA,MAIDAN CHOGAL, HANDWARA,\nKUPWARA, Jammu And Kashmir, 193221",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20480,
    "Rank": 518051,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20481,
    "Rank": 518076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20482,
    "Rank": 518105,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20483,
    "Rank": 518177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20484,
    "Rank": 518201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20485,
    "Rank": 518303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20486,
    "Rank": 518306,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20487,
    "Rank": 518431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20488,
    "Rank": 518465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20489,
    "Rank": 518481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20490,
    "Rank": 518484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20491,
    "Rank": 518520,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20492,
    "Rank": 518523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20493,
    "Rank": 518570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20494,
    "Rank": 518597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20495,
    "Rank": 518670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20496,
    "Rank": 518792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20497,
    "Rank": 518974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20498,
    "Rank": 519005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20499,
    "Rank": 519034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20500,
    "Rank": 519073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20501,
    "Rank": 519079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20502,
    "Rank": 519251,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20503,
    "Rank": 519303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20504,
    "Rank": 519392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20505,
    "Rank": 519443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20506,
    "Rank": 519448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20507,
    "Rank": 519529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20508,
    "Rank": 519574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20509,
    "Rank": 519620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20510,
    "Rank": 519688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20511,
    "Rank": 519728,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20512,
    "Rank": 519893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20513,
    "Rank": 520197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20514,
    "Rank": 520372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20515,
    "Rank": 520552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20516,
    "Rank": 520844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20517,
    "Rank": 520859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20518,
    "Rank": 520885,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20519,
    "Rank": 520954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20520,
    "Rank": 521010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20521,
    "Rank": 521133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20522,
    "Rank": 521185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20523,
    "Rank": 521278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20524,
    "Rank": 521325,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20525,
    "Rank": 521372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20526,
    "Rank": 521392,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20527,
    "Rank": 521396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20528,
    "Rank": 521764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20529,
    "Rank": 521854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20530,
    "Rank": 521894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20531,
    "Rank": 521972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20532,
    "Rank": 522456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20533,
    "Rank": 522497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20534,
    "Rank": 522528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20535,
    "Rank": 522538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20536,
    "Rank": 522679,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20537,
    "Rank": 522694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20538,
    "Rank": 522776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20539,
    "Rank": 522812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20540,
    "Rank": 522855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20541,
    "Rank": 522890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20542,
    "Rank": 522946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20543,
    "Rank": 523116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20544,
    "Rank": 523145,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20545,
    "Rank": 523222,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20546,
    "Rank": 523229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20547,
    "Rank": 523280,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20548,
    "Rank": 523457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20549,
    "Rank": 523470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20550,
    "Rank": 523585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20551,
    "Rank": 523711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20552,
    "Rank": 523753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20553,
    "Rank": 524073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20554,
    "Rank": 524226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20555,
    "Rank": 524270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20556,
    "Rank": 524345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20557,
    "Rank": 524398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20558,
    "Rank": 524408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20559,
    "Rank": 524452,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20560,
    "Rank": 524577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20561,
    "Rank": 524718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20562,
    "Rank": 524739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20563,
    "Rank": 524982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20564,
    "Rank": 524991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20565,
    "Rank": 525020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20566,
    "Rank": 525054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20567,
    "Rank": 525090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20568,
    "Rank": 525286,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20569,
    "Rank": 525498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20570,
    "Rank": 525503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20571,
    "Rank": 525512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20572,
    "Rank": 525547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20573,
    "Rank": 525637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20574,
    "Rank": 525821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20575,
    "Rank": 525910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20576,
    "Rank": 526066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20577,
    "Rank": 526093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20578,
    "Rank": 526282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20579,
    "Rank": 526295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20580,
    "Rank": 526349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20581,
    "Rank": 526357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20582,
    "Rank": 526475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20583,
    "Rank": 526496,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20584,
    "Rank": 526526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20585,
    "Rank": 526543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20586,
    "Rank": 526610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20587,
    "Rank": 526618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20588,
    "Rank": 526661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20589,
    "Rank": 526768,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20590,
    "Rank": 526776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20591,
    "Rank": 526892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20592,
    "Rank": 526900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20593,
    "Rank": 527041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20594,
    "Rank": 527197,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20595,
    "Rank": 527341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20596,
    "Rank": 527413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20597,
    "Rank": 527430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20598,
    "Rank": 527481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20599,
    "Rank": 527622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20600,
    "Rank": 527678,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20601,
    "Rank": 528131,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20602,
    "Rank": 528249,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20603,
    "Rank": 528265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20604,
    "Rank": 528337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20605,
    "Rank": 528410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20606,
    "Rank": 528439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20607,
    "Rank": 528493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20608,
    "Rank": 528516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20609,
    "Rank": 528527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20610,
    "Rank": 528582,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20611,
    "Rank": 528597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20612,
    "Rank": 528644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20613,
    "Rank": 528652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20614,
    "Rank": 528875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20615,
    "Rank": 528896,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20616,
    "Rank": 528945,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20617,
    "Rank": 528966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20618,
    "Rank": 528969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20619,
    "Rank": 528976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20620,
    "Rank": 528991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20621,
    "Rank": 529061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali,NH-62,SumerpurRoad,Ramasia.Hemawas,Pali, Rajasthan, 306401",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20622,
    "Rank": 529077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20623,
    "Rank": 529097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20624,
    "Rank": 529099,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20625,
    "Rank": 529138,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20626,
    "Rank": 529152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20627,
    "Rank": 529368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20628,
    "Rank": 529648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20629,
    "Rank": 529694,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20630,
    "Rank": 529715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20631,
    "Rank": 529813,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20632,
    "Rank": 529913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20633,
    "Rank": 529961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20634,
    "Rank": 530077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJA ROAD, CHENNAI - 600 002., Tamil Nadu, 600002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20635,
    "Rank": 530573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20636,
    "Rank": 530711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20637,
    "Rank": 530785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20638,
    "Rank": 530817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20639,
    "Rank": 530824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20640,
    "Rank": 530828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20641,
    "Rank": 530896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20642,
    "Rank": 530926,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20643,
    "Rank": 531020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20644,
    "Rank": 531116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20645,
    "Rank": 531163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20646,
    "Rank": 531281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20647,
    "Rank": 531313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20648,
    "Rank": 531424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20649,
    "Rank": 531454,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20650,
    "Rank": 531490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20651,
    "Rank": 531659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20652,
    "Rank": 531831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20653,
    "Rank": 531857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20654,
    "Rank": 531875,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20655,
    "Rank": 531891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20656,
    "Rank": 531918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20657,
    "Rank": 531925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20658,
    "Rank": 531926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20659,
    "Rank": 532039,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20660,
    "Rank": 532149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20661,
    "Rank": 532184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20662,
    "Rank": 532189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20663,
    "Rank": 532215,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20664,
    "Rank": 532467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20665,
    "Rank": 532471,
    "Allotted Quota": "Non-Resident Indian(AMU)Quota",
    "Allotted Institute": "Dr. Ziauddin Ahmed Dental College and Hospital,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20666,
    "Rank": 532591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20667,
    "Rank": 532611,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE, KURNOOL,BUDHAWARPET, KURNOOL., Andhra\nPradesh, 518002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20668,
    "Rank": 532617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20669,
    "Rank": 532655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20670,
    "Rank": 532709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20671,
    "Rank": 532759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20672,
    "Rank": 532935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20673,
    "Rank": 533017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20674,
    "Rank": 533276,
    "Allotted Quota": "All India",
    "Allotted Institute": "L.L.R.M. MEDICAL COLLEGE, MEERUT,LALA LAJPAT RAI MEMORIAL, MEDICAL COLLEGE, GARH ROAD, MEERUT, Uttar Pradesh, 250004",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20675,
    "Rank": 533287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20676,
    "Rank": 533294,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20677,
    "Rank": 533345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20678,
    "Rank": 533455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20679,
    "Rank": 533506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20680,
    "Rank": 533513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20681,
    "Rank": 533663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20682,
    "Rank": 533673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20683,
    "Rank": 533686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20684,
    "Rank": 533723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20685,
    "Rank": 533814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20686,
    "Rank": 533926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20687,
    "Rank": 533970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20688,
    "Rank": 533982,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20689,
    "Rank": 534077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20690,
    "Rank": 534160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20691,
    "Rank": 534164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20692,
    "Rank": 534184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20693,
    "Rank": 534212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20694,
    "Rank": 534326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20695,
    "Rank": 534362,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20696,
    "Rank": 534482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20697,
    "Rank": 534487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20698,
    "Rank": 534590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20699,
    "Rank": 534633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20700,
    "Rank": 534686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20701,
    "Rank": 534703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20702,
    "Rank": 534832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20703,
    "Rank": 534886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20704,
    "Rank": 535062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20705,
    "Rank": 535145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20706,
    "Rank": 535975,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20707,
    "Rank": 536184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20708,
    "Rank": 536258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20709,
    "Rank": 536266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20710,
    "Rank": 536391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20711,
    "Rank": 536440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20712,
    "Rank": 536601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20713,
    "Rank": 536603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20714,
    "Rank": 536629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20715,
    "Rank": 536677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20716,
    "Rank": 536706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20717,
    "Rank": 536826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20718,
    "Rank": 536861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20719,
    "Rank": 536885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20720,
    "Rank": 537088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20721,
    "Rank": 537197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20722,
    "Rank": 537284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20723,
    "Rank": 537286,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20724,
    "Rank": 537402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20725,
    "Rank": 537516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20726,
    "Rank": 537534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20727,
    "Rank": 537657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20728,
    "Rank": 537807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20729,
    "Rank": 537891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20730,
    "Rank": 537916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20731,
    "Rank": 538085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20732,
    "Rank": 538096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20733,
    "Rank": 538451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20734,
    "Rank": 538498,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20735,
    "Rank": 538580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20736,
    "Rank": 538967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20737,
    "Rank": 538993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20738,
    "Rank": 539191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20739,
    "Rank": 539195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20740,
    "Rank": 539226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20741,
    "Rank": 539290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20742,
    "Rank": 539297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20743,
    "Rank": 539418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20744,
    "Rank": 539472,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20745,
    "Rank": 539511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20746,
    "Rank": 539536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20747,
    "Rank": 539657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20748,
    "Rank": 539681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20749,
    "Rank": 539698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20750,
    "Rank": 539814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20751,
    "Rank": 539843,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20752,
    "Rank": 540145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20753,
    "Rank": 540282,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20754,
    "Rank": 540295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20755,
    "Rank": 540326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20756,
    "Rank": 540388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20757,
    "Rank": 540415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20758,
    "Rank": 540459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20759,
    "Rank": 540561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20760,
    "Rank": 540654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20761,
    "Rank": 540660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20762,
    "Rank": 540714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20763,
    "Rank": 540789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20764,
    "Rank": 540820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20765,
    "Rank": 540841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20766,
    "Rank": 540847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20767,
    "Rank": 540851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20768,
    "Rank": 540862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20769,
    "Rank": 540955,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20770,
    "Rank": 540974,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20771,
    "Rank": 541192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20772,
    "Rank": 541222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20773,
    "Rank": 541295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20774,
    "Rank": 541407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20775,
    "Rank": 541634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20776,
    "Rank": 541897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20777,
    "Rank": 541940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20778,
    "Rank": 541978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20779,
    "Rank": 542195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20780,
    "Rank": 542214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20781,
    "Rank": 542287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20782,
    "Rank": 542600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20783,
    "Rank": 542625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20784,
    "Rank": 542745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20785,
    "Rank": 542790,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20786,
    "Rank": 542919,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20787,
    "Rank": 542952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20788,
    "Rank": 543020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20789,
    "Rank": 543169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20790,
    "Rank": 543171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20791,
    "Rank": 543280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20792,
    "Rank": 543369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20793,
    "Rank": 543379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20794,
    "Rank": 543462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20795,
    "Rank": 543504,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20796,
    "Rank": 543515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20797,
    "Rank": 543580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20798,
    "Rank": 543817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20799,
    "Rank": 543834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20800,
    "Rank": 543934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20801,
    "Rank": 544101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20802,
    "Rank": 544136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20803,
    "Rank": 544341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20804,
    "Rank": 544361,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20805,
    "Rank": 544465,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20806,
    "Rank": 544477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20807,
    "Rank": 544503,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20808,
    "Rank": 544520,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20809,
    "Rank": 544545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20810,
    "Rank": 544556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20811,
    "Rank": 544627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20812,
    "Rank": 544655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20813,
    "Rank": 544676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20814,
    "Rank": 544745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20815,
    "Rank": 544787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20816,
    "Rank": 544819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20817,
    "Rank": 544894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20818,
    "Rank": 545024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20819,
    "Rank": 545065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20820,
    "Rank": 545077,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20821,
    "Rank": 545272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20822,
    "Rank": 545277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20823,
    "Rank": 545482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20824,
    "Rank": 545607,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20825,
    "Rank": 545639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20826,
    "Rank": 545715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20827,
    "Rank": 545860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20828,
    "Rank": 545861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20829,
    "Rank": 545867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20830,
    "Rank": 545876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20831,
    "Rank": 546244,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20832,
    "Rank": 546337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20833,
    "Rank": 546380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20834,
    "Rank": 546410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20835,
    "Rank": 546562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20836,
    "Rank": 546603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20837,
    "Rank": 546635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20838,
    "Rank": 546762,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20839,
    "Rank": 546814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20840,
    "Rank": 546842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20841,
    "Rank": 547263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20842,
    "Rank": 547336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20843,
    "Rank": 547346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20844,
    "Rank": 547372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20845,
    "Rank": 547390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20846,
    "Rank": 547432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20847,
    "Rank": 547440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20848,
    "Rank": 548043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20849,
    "Rank": 548219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20850,
    "Rank": 548358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20851,
    "Rank": 548447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20852,
    "Rank": 548527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20853,
    "Rank": 548533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20854,
    "Rank": 548747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20855,
    "Rank": 548935,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20856,
    "Rank": 549014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20857,
    "Rank": 549029,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20858,
    "Rank": 549212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20859,
    "Rank": 549241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20860,
    "Rank": 549255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20861,
    "Rank": 549356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20862,
    "Rank": 549424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20863,
    "Rank": 549511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20864,
    "Rank": 549632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20865,
    "Rank": 549811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20866,
    "Rank": 549822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20867,
    "Rank": 549849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20868,
    "Rank": 550031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20869,
    "Rank": 550087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20870,
    "Rank": 550129,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20871,
    "Rank": 550257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20872,
    "Rank": 550374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20873,
    "Rank": 550550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20874,
    "Rank": 550596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20875,
    "Rank": 550619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20876,
    "Rank": 550700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20877,
    "Rank": 550718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20878,
    "Rank": 550801,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20879,
    "Rank": 550823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20880,
    "Rank": 550871,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20881,
    "Rank": 550926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20882,
    "Rank": 551245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mursidabad M C & Hospital, Mursidabad,STATION ROAD, P.O. AND P.S. BERHAMPORE,\nDIST. MURSHIDABAD, West Bengal, 742101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20883,
    "Rank": 551359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20884,
    "Rank": 551388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20885,
    "Rank": 551468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20886,
    "Rank": 551554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20887,
    "Rank": 551591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20888,
    "Rank": 551780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20889,
    "Rank": 551831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20890,
    "Rank": 551937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20891,
    "Rank": 551948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20892,
    "Rank": 551998,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20893,
    "Rank": 552028,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20894,
    "Rank": 552258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20895,
    "Rank": 552298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20896,
    "Rank": 552326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20897,
    "Rank": 552363,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20898,
    "Rank": 552376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20899,
    "Rank": 552416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20900,
    "Rank": 552457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20901,
    "Rank": 552463,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20902,
    "Rank": 552507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20903,
    "Rank": 552551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20904,
    "Rank": 552608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20905,
    "Rank": 552799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20906,
    "Rank": 552824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20907,
    "Rank": 552970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20908,
    "Rank": 553315,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20909,
    "Rank": 553433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20910,
    "Rank": 553471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20911,
    "Rank": 553624,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20912,
    "Rank": 553930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20913,
    "Rank": 553983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20914,
    "Rank": 553985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20915,
    "Rank": 554057,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20916,
    "Rank": 554073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20917,
    "Rank": 554078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20918,
    "Rank": 554235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20919,
    "Rank": 554266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20920,
    "Rank": 554493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20921,
    "Rank": 554616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20922,
    "Rank": 554745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20923,
    "Rank": 554821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20924,
    "Rank": 554967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20925,
    "Rank": 555042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20926,
    "Rank": 555254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20927,
    "Rank": 555285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20928,
    "Rank": 555308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20929,
    "Rank": 555531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20930,
    "Rank": 555639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20931,
    "Rank": 555645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20932,
    "Rank": 556047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20933,
    "Rank": 556163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20934,
    "Rank": 556206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20935,
    "Rank": 556401,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20936,
    "Rank": 556443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20937,
    "Rank": 556512,
    "Allotted Quota": "All India",
    "Allotted Institute": "IRT Perundurai Medical College, Perundurai,GOVERNMENT ERODE MEDICAL COLLEGE PERUNDURAI,\nPERUNDURAI SANATORIUM POST ERODE DISTRICT, Tamil Nadu, 638053",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20938,
    "Rank": 556519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20939,
    "Rank": 556544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20940,
    "Rank": 556651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20941,
    "Rank": 556674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20942,
    "Rank": 556677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20943,
    "Rank": 556686,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20944,
    "Rank": 556751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20945,
    "Rank": 556808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20946,
    "Rank": 556822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20947,
    "Rank": 557118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20948,
    "Rank": 557161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20949,
    "Rank": 557265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20950,
    "Rank": 557266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20951,
    "Rank": 557564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20952,
    "Rank": 557681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20953,
    "Rank": 557788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20954,
    "Rank": 557849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20955,
    "Rank": 557860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20956,
    "Rank": 557865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20957,
    "Rank": 558038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20958,
    "Rank": 558093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20959,
    "Rank": 558170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20960,
    "Rank": 558444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20961,
    "Rank": 558480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20962,
    "Rank": 558523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20963,
    "Rank": 558585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20964,
    "Rank": 558588,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20965,
    "Rank": 558742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20966,
    "Rank": 558795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20967,
    "Rank": 558879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20968,
    "Rank": 558986,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20969,
    "Rank": 559003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20970,
    "Rank": 559139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20971,
    "Rank": 559218,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE, BHOPAL,Royal Market Sultania Road Bhopal, Madhya Pradesh, 462001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20972,
    "Rank": 559279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20973,
    "Rank": 559313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20974,
    "Rank": 559321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20975,
    "Rank": 559326,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCI.,  BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES BIDAR\nUDGIR ROAD\nBIDAR-585401, Karnataka, 585401",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20976,
    "Rank": 559500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20977,
    "Rank": 559705,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20978,
    "Rank": 559890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20979,
    "Rank": 559960,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20980,
    "Rank": 560058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20981,
    "Rank": 560269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20982,
    "Rank": 560378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20983,
    "Rank": 560447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20984,
    "Rank": 560455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20985,
    "Rank": 560508,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20986,
    "Rank": 560559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20987,
    "Rank": 560601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20988,
    "Rank": 560710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20989,
    "Rank": 560722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20990,
    "Rank": 560944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20991,
    "Rank": 560971,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20992,
    "Rank": 561142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20993,
    "Rank": 561330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20994,
    "Rank": 561395,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20995,
    "Rank": 561494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20996,
    "Rank": 561568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20997,
    "Rank": 561768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20998,
    "Rank": 561787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 20999,
    "Rank": 561852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21000,
    "Rank": 561864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21001,
    "Rank": 561920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21002,
    "Rank": 561937,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21003,
    "Rank": 562050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21004,
    "Rank": 562149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21005,
    "Rank": 562312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21006,
    "Rank": 562362,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21007,
    "Rank": 562406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21008,
    "Rank": 562584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21009,
    "Rank": 562652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21010,
    "Rank": 562819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21011,
    "Rank": 562830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21012,
    "Rank": 562872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21013,
    "Rank": 562882,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21014,
    "Rank": 562889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21015,
    "Rank": 563154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21016,
    "Rank": 563370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21017,
    "Rank": 563374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21018,
    "Rank": 563441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21019,
    "Rank": 563561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21020,
    "Rank": 563605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21021,
    "Rank": 563724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21022,
    "Rank": 563727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21023,
    "Rank": 563796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21024,
    "Rank": 563797,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21025,
    "Rank": 563881,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21026,
    "Rank": 564091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21027,
    "Rank": 564232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21028,
    "Rank": 564291,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21029,
    "Rank": 564508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21030,
    "Rank": 564513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21031,
    "Rank": 564523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21032,
    "Rank": 564596,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Deogarh ,AIIMS Deoghar Devipur, Jharkhand India, PIN - 814152, Jharkhand, 814152",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21033,
    "Rank": 564676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21034,
    "Rank": 564679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21035,
    "Rank": 564745,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21036,
    "Rank": 564838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21037,
    "Rank": 565064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21038,
    "Rank": 565122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21039,
    "Rank": 565123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21040,
    "Rank": 565129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21041,
    "Rank": 565170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21042,
    "Rank": 565233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21043,
    "Rank": 565280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21044,
    "Rank": 565294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21045,
    "Rank": 565297,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21046,
    "Rank": 565471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21047,
    "Rank": 565476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21048,
    "Rank": 565504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21049,
    "Rank": 565517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21050,
    "Rank": 565550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21051,
    "Rank": 565774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21052,
    "Rank": 565843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21053,
    "Rank": 565861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21054,
    "Rank": 565991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21055,
    "Rank": 565999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21056,
    "Rank": 566060,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21057,
    "Rank": 566089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21058,
    "Rank": 566113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21059,
    "Rank": 566116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21060,
    "Rank": 566118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21061,
    "Rank": 566159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21062,
    "Rank": 566295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21063,
    "Rank": 566418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21064,
    "Rank": 566420,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21065,
    "Rank": 566600,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21066,
    "Rank": 566622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21067,
    "Rank": 566753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21068,
    "Rank": 566983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21069,
    "Rank": 567018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21070,
    "Rank": 567148,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21071,
    "Rank": 567188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21072,
    "Rank": 567200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Nirmal Mahto Medical College & Hospital, Dhanbad,PO BCCL TOWNSHIP KOYLANAGAR DHANBAD, Jharkhand, 826005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21073,
    "Rank": 567419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21074,
    "Rank": 567546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21075,
    "Rank": 567580,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21076,
    "Rank": 567620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21077,
    "Rank": 567628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21078,
    "Rank": 567660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21079,
    "Rank": 567684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21080,
    "Rank": 567691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21081,
    "Rank": 567718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21082,
    "Rank": 567819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21083,
    "Rank": 567913,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21084,
    "Rank": 567957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21085,
    "Rank": 568030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21086,
    "Rank": 568054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College, Chennai,Saveetha Nagar, Thandalam, Chennai, Tamil Nadu, 602105",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21087,
    "Rank": 568064,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21088,
    "Rank": 568065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21089,
    "Rank": 568091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21090,
    "Rank": 568130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21091,
    "Rank": 568132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21092,
    "Rank": 568195,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21093,
    "Rank": 568412,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21094,
    "Rank": 568637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21095,
    "Rank": 568804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21096,
    "Rank": 568807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21097,
    "Rank": 568970,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21098,
    "Rank": 568984,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21099,
    "Rank": 569126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21100,
    "Rank": 569918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21101,
    "Rank": 569943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21102,
    "Rank": 570008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21103,
    "Rank": 570044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21104,
    "Rank": 570056,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21105,
    "Rank": 570099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21106,
    "Rank": 570175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21107,
    "Rank": 570461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21108,
    "Rank": 570528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21109,
    "Rank": 570541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21110,
    "Rank": 570799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21111,
    "Rank": 570833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21112,
    "Rank": 570854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21113,
    "Rank": 570858,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21114,
    "Rank": 570867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21115,
    "Rank": 570873,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21116,
    "Rank": 570998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21117,
    "Rank": 571224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21118,
    "Rank": 571342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21119,
    "Rank": 571369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21120,
    "Rank": 571575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21121,
    "Rank": 571675,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21122,
    "Rank": 571704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21123,
    "Rank": 571787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Karur,SANAPIRATTI VILLAGE NORTH GANDHIGRAMAM KARUR TAMILNADU, Tamil Nadu, 639004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21124,
    "Rank": 572024,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 28 )"
  },
  {
    "SNo": 21125,
    "Rank": 572076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21126,
    "Rank": 572228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21127,
    "Rank": 572411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21128,
    "Rank": 572416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21129,
    "Rank": 572443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21130,
    "Rank": 572488,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21131,
    "Rank": 572632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21132,
    "Rank": 572652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21133,
    "Rank": 572741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21134,
    "Rank": 572752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21135,
    "Rank": 572935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21136,
    "Rank": 572938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21137,
    "Rank": 573005,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21138,
    "Rank": 573046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21139,
    "Rank": 573073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21140,
    "Rank": 573076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21141,
    "Rank": 573135,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21142,
    "Rank": 573168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21143,
    "Rank": 573325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21144,
    "Rank": 573339,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21145,
    "Rank": 573424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Jagannath Medical College & Hospital, Puri,BALIGUALI SAMANGARA PURI PIN 752004 (ODISHA), Odisha, 752004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21146,
    "Rank": 573532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21147,
    "Rank": 573711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21148,
    "Rank": 573955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21149,
    "Rank": 574085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21150,
    "Rank": 574136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21151,
    "Rank": 574276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21152,
    "Rank": 574477,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21153,
    "Rank": 574610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21154,
    "Rank": 574748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21155,
    "Rank": 574901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21156,
    "Rank": 575018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21157,
    "Rank": 575206,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21158,
    "Rank": 575322,
    "Allotted Quota": "Aligarh Muslim University (AMU) Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, AMU,ALIGARH,ALIGARH MUSLIM UNIVERSITY, ALIGARH, Uttar Pradesh, 202002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21159,
    "Rank": 575326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21160,
    "Rank": 575447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21161,
    "Rank": 575532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21162,
    "Rank": 575574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21163,
    "Rank": 575610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21164,
    "Rank": 575760,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21165,
    "Rank": 575770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21166,
    "Rank": 575779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21167,
    "Rank": 575786,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21168,
    "Rank": 576034,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21169,
    "Rank": 576069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21170,
    "Rank": 576178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21171,
    "Rank": 576608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21172,
    "Rank": 576648,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21173,
    "Rank": 576752,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLL.,THANJAVUR,Thanjavur Medical College,Thanjavur, Tamil Nadu, 613004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21174,
    "Rank": 577202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21175,
    "Rank": 577215,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21176,
    "Rank": 577294,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21177,
    "Rank": 577349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21178,
    "Rank": 577425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21179,
    "Rank": 577435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21180,
    "Rank": 577462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21181,
    "Rank": 577498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21182,
    "Rank": 577537,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21183,
    "Rank": 577598,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21184,
    "Rank": 577611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21185,
    "Rank": 577831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21186,
    "Rank": 578027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21187,
    "Rank": 578132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21188,
    "Rank": 578162,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21189,
    "Rank": 578212,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21190,
    "Rank": 578247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21191,
    "Rank": 578378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21192,
    "Rank": 578445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21193,
    "Rank": 578492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21194,
    "Rank": 578525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21195,
    "Rank": 578560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21196,
    "Rank": 578578,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21197,
    "Rank": 578758,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE, CHENNAI,No.1, Old Jail Road\nChennai, Tamil Nadu, 600001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21198,
    "Rank": 578802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21199,
    "Rank": 578899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21200,
    "Rank": 578908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21201,
    "Rank": 579067,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21202,
    "Rank": 579201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21203,
    "Rank": 579279,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21204,
    "Rank": 579324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21205,
    "Rank": 579374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21206,
    "Rank": 579494,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21207,
    "Rank": 579509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21208,
    "Rank": 579998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21209,
    "Rank": 580038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21210,
    "Rank": 580077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21211,
    "Rank": 580256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21212,
    "Rank": 580285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21213,
    "Rank": 580455,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21214,
    "Rank": 580489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21215,
    "Rank": 580531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21216,
    "Rank": 580612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21217,
    "Rank": 580714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21218,
    "Rank": 580724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21219,
    "Rank": 580739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21220,
    "Rank": 580838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21221,
    "Rank": 580846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21222,
    "Rank": 580868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21223,
    "Rank": 580974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21224,
    "Rank": 580979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21225,
    "Rank": 581032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21226,
    "Rank": 581154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21227,
    "Rank": 581227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21228,
    "Rank": 581249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21229,
    "Rank": 581266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21230,
    "Rank": 581337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21231,
    "Rank": 581419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21232,
    "Rank": 581437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21233,
    "Rank": 581453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21234,
    "Rank": 581456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21235,
    "Rank": 581472,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21236,
    "Rank": 581564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21237,
    "Rank": 581595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21238,
    "Rank": 581624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21239,
    "Rank": 581640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21240,
    "Rank": 581815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21241,
    "Rank": 582081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21242,
    "Rank": 582405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21243,
    "Rank": 582469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21244,
    "Rank": 582485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21245,
    "Rank": 582577,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, KOTA,Rangbari Road Kota, Rajasthan, 324005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21246,
    "Rank": 582910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21247,
    "Rank": 583018,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21248,
    "Rank": 583090,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21249,
    "Rank": 583202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21250,
    "Rank": 583370,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 29 )"
  },
  {
    "SNo": 21251,
    "Rank": 583435,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21252,
    "Rank": 583455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21253,
    "Rank": 583636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21254,
    "Rank": 583656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21255,
    "Rank": 583682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21256,
    "Rank": 583724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21257,
    "Rank": 583799,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21258,
    "Rank": 583860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21259,
    "Rank": 583884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21260,
    "Rank": 583931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21261,
    "Rank": 583967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21262,
    "Rank": 583975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21263,
    "Rank": 584103,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21264,
    "Rank": 584146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21265,
    "Rank": 584187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21266,
    "Rank": 584347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21267,
    "Rank": 584589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21268,
    "Rank": 584592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21269,
    "Rank": 584599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21270,
    "Rank": 584668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21271,
    "Rank": 584690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21272,
    "Rank": 584700,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21273,
    "Rank": 584882,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21274,
    "Rank": 584978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21275,
    "Rank": 585201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21276,
    "Rank": 585341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21277,
    "Rank": 585395,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21278,
    "Rank": 585615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21279,
    "Rank": 586104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21280,
    "Rank": 586473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21281,
    "Rank": 586485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21282,
    "Rank": 586568,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21283,
    "Rank": 586839,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC PGIMSR, Joka, Kolkata, WB,DIAMOND HARBOUR ROAD POST OFFICE JOKA\nKOLKATA 700104, West Bengal, 700104",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21284,
    "Rank": 586869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21285,
    "Rank": 586933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21286,
    "Rank": 587088,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21287,
    "Rank": 587162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21288,
    "Rank": 587273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21289,
    "Rank": 587463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21290,
    "Rank": 587824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21291,
    "Rank": 587902,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21292,
    "Rank": 587991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21293,
    "Rank": 587998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21294,
    "Rank": 588038,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21295,
    "Rank": 588114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "J  R MEDICAL COLLEGE AND HOSPITAL, TAMIL NADU,Chennai-Trichy NH-\n45,Kiledaiyalam,\nTindivanam Taluk,Villupuram District., Tamil Nadu, 604302",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21296,
    "Rank": 588247,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21297,
    "Rank": 588292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21298,
    "Rank": 588500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21299,
    "Rank": 588629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21300,
    "Rank": 588650,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21301,
    "Rank": 588834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21302,
    "Rank": 588863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21303,
    "Rank": 589013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21304,
    "Rank": 589179,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21305,
    "Rank": 589229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21306,
    "Rank": 589246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21307,
    "Rank": 589294,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJAYNAGAR INST OF MED. SC,BELLARY,Cantonment, Ballari, Karnataka, 583104",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21308,
    "Rank": 589325,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21309,
    "Rank": 589348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21310,
    "Rank": 589368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21311,
    "Rank": 589485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21312,
    "Rank": 589603,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21313,
    "Rank": 589619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21314,
    "Rank": 589815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21315,
    "Rank": 589822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21316,
    "Rank": 590054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21317,
    "Rank": 590156,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21318,
    "Rank": 590195,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21319,
    "Rank": 590342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21320,
    "Rank": 590536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21321,
    "Rank": 590551,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21322,
    "Rank": 590568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21323,
    "Rank": 590607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21324,
    "Rank": 590610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21325,
    "Rank": 590658,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21326,
    "Rank": 590786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21327,
    "Rank": 590936,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21328,
    "Rank": 591009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21329,
    "Rank": 591084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21330,
    "Rank": 591125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21331,
    "Rank": 591170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21332,
    "Rank": 591231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21333,
    "Rank": 591327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21334,
    "Rank": 591477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21335,
    "Rank": 591519,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21336,
    "Rank": 591603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21337,
    "Rank": 591733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21338,
    "Rank": 591909,
    "Allotted Quota": "All India",
    "Allotted Institute": "SRI KRISHNA MEDICAL COLLEGE, MUZAFFARPUR,UMANAGAR MUZAFFARPUR, Bihar, 842004",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21339,
    "Rank": 592075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21340,
    "Rank": 592224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21341,
    "Rank": 592282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21342,
    "Rank": 592492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21343,
    "Rank": 592495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21344,
    "Rank": 592500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21345,
    "Rank": 592675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21346,
    "Rank": 592694,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21347,
    "Rank": 592806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21348,
    "Rank": 593022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21349,
    "Rank": 593024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21350,
    "Rank": 593062,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21351,
    "Rank": 593356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21352,
    "Rank": 593564,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Medical College & Hospital, Bihta,ESIC Medical College and Hospital, Bihta, Patna- 801103, Bihar, 801103",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21353,
    "Rank": 593667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21354,
    "Rank": 593695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21355,
    "Rank": 593802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21356,
    "Rank": 593884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21357,
    "Rank": 593893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21358,
    "Rank": 594030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21359,
    "Rank": 594106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21360,
    "Rank": 594201,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21361,
    "Rank": 594482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21362,
    "Rank": 594564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21363,
    "Rank": 594594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21364,
    "Rank": 594660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21365,
    "Rank": 594944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21366,
    "Rank": 594974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21367,
    "Rank": 595044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21368,
    "Rank": 595151,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, GAYA,SHERGHATIROAD, GAYA, Bihar, 823001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21369,
    "Rank": 595160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21370,
    "Rank": 595235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21371,
    "Rank": 595259,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21372,
    "Rank": 595369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21373,
    "Rank": 595446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21374,
    "Rank": 595464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21375,
    "Rank": 595522,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21376,
    "Rank": 595545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21377,
    "Rank": 595556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21378,
    "Rank": 595576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21379,
    "Rank": 596034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21380,
    "Rank": 596113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21381,
    "Rank": 596155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21382,
    "Rank": 596196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21383,
    "Rank": 596197,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21384,
    "Rank": 596316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21385,
    "Rank": 596407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21386,
    "Rank": 596504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21387,
    "Rank": 596525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21388,
    "Rank": 596625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21389,
    "Rank": 596747,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21390,
    "Rank": 596813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21391,
    "Rank": 596984,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21392,
    "Rank": 597279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21393,
    "Rank": 597292,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. VELLORE MEDICAL COLLEGE, VELLORE,Government Vellore Medical College and Hospital,\nAdukkamparai, Vellore., Tamil Nadu, 632011",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21394,
    "Rank": 597418,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21395,
    "Rank": 597446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21396,
    "Rank": 597459,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21397,
    "Rank": 597616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21398,
    "Rank": 597673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21399,
    "Rank": 597744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21400,
    "Rank": 597966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21401,
    "Rank": 598077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21402,
    "Rank": 598194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21403,
    "Rank": 598202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21404,
    "Rank": 598346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21405,
    "Rank": 598594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21406,
    "Rank": 598673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21407,
    "Rank": 599063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21408,
    "Rank": 599143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21409,
    "Rank": 599168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21410,
    "Rank": 599280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21411,
    "Rank": 599332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21412,
    "Rank": 599573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21413,
    "Rank": 599610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21414,
    "Rank": 599642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21415,
    "Rank": 599802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21416,
    "Rank": 599830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21417,
    "Rank": 600007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21418,
    "Rank": 600364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21419,
    "Rank": 600375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21420,
    "Rank": 600376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21421,
    "Rank": 600382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21422,
    "Rank": 600436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21423,
    "Rank": 600513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. J N M MEDICAL COLLEGE, RAIPUR,In front of Central Jail, Jail Road Raipur C.G., Chhattisgarh, 492001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21424,
    "Rank": 600523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21425,
    "Rank": 600683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21426,
    "Rank": 600862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21427,
    "Rank": 600934,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Jammu,AIIMS, Vijaypur, Jammu, Jammu and Kashmir, Jammu And Kashmir, 184120",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21428,
    "Rank": 601050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21429,
    "Rank": 601112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21430,
    "Rank": 601211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21431,
    "Rank": 601241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21432,
    "Rank": 601261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21433,
    "Rank": 601300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21434,
    "Rank": 601359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21435,
    "Rank": 601405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21436,
    "Rank": 601506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21437,
    "Rank": 601602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21438,
    "Rank": 601669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21439,
    "Rank": 601723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21440,
    "Rank": 601839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21441,
    "Rank": 601912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21442,
    "Rank": 601985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21443,
    "Rank": 601987,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21444,
    "Rank": 602072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21445,
    "Rank": 602112,
    "Allotted Quota": "All India",
    "Allotted Institute": "V.S.S. MEDICAL COLLEGE, BURLA,AYURVIHAR,BURLA, SAMBALPUR, Odisha, 768017",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21446,
    "Rank": 602243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21447,
    "Rank": 602757,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21448,
    "Rank": 603113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21449,
    "Rank": 603213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College & Hospital, Alibag-Raigad,Government Medical College of Alibag, Alibag beach limaye wadi Alibagh Maharashtra- 402201, Maharashtra, 402201",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21450,
    "Rank": 603262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21451,
    "Rank": 603270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21452,
    "Rank": 603321,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21453,
    "Rank": 603502,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21454,
    "Rank": 603777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21455,
    "Rank": 603904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21456,
    "Rank": 603910,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLL,CHENGALPATTU,GST ROAD, CHENGALPATTU- 603 001\nCHENGALPATTU DISTRICT, Tamil Nadu, 603001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21457,
    "Rank": 604125,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21458,
    "Rank": 604145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21459,
    "Rank": 604166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21460,
    "Rank": 604232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21461,
    "Rank": 604356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21462,
    "Rank": 604379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21463,
    "Rank": 604413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21464,
    "Rank": 604435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21465,
    "Rank": 604468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21466,
    "Rank": 604541,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD\nGULBARGA, Karnataka, 585106",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21467,
    "Rank": 604583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21468,
    "Rank": 604626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21469,
    "Rank": 604667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21470,
    "Rank": 604733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21471,
    "Rank": 604766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21472,
    "Rank": 604953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21473,
    "Rank": 605020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21474,
    "Rank": 605133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21475,
    "Rank": 605331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21476,
    "Rank": 605373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21477,
    "Rank": 605399,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21478,
    "Rank": 605501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21479,
    "Rank": 605560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21480,
    "Rank": 605862,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21481,
    "Rank": 605997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21482,
    "Rank": 606028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21483,
    "Rank": 606069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21484,
    "Rank": 606210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21485,
    "Rank": 606583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21486,
    "Rank": 606892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21487,
    "Rank": 606933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21488,
    "Rank": 606980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21489,
    "Rank": 606993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21490,
    "Rank": 607204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21491,
    "Rank": 607350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21492,
    "Rank": 607401,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21493,
    "Rank": 607473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21494,
    "Rank": 607590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21495,
    "Rank": 607659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21496,
    "Rank": 607787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21497,
    "Rank": 607827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21498,
    "Rank": 608046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21499,
    "Rank": 608191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21500,
    "Rank": 608295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical college Society Hardoi,Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21501,
    "Rank": 608485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21502,
    "Rank": 608542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21503,
    "Rank": 608559,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21504,
    "Rank": 608613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21505,
    "Rank": 608700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21506,
    "Rank": 608952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21507,
    "Rank": 609000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21508,
    "Rank": 609005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21509,
    "Rank": 609109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences,DIRECTOR, KIMS,KOPPAL. GAGNAVATI ROAD, KIDDIDAL GATE,KOPPAL-583231, Karnataka, 583231",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21510,
    "Rank": 609151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21511,
    "Rank": 609197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21512,
    "Rank": 609270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21513,
    "Rank": 609311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21514,
    "Rank": 609343,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21515,
    "Rank": 609418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21516,
    "Rank": 609571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21517,
    "Rank": 609657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21518,
    "Rank": 609922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21519,
    "Rank": 609957,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21520,
    "Rank": 610028,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21521,
    "Rank": 610098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21522,
    "Rank": 610189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21523,
    "Rank": 610192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Manipal College of Dental Science, Mangalore,Light House Hill Road, Mangalore India, Karnataka, 575001",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21524,
    "Rank": 610197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21525,
    "Rank": 610245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21526,
    "Rank": 610253,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21527,
    "Rank": 610388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21528,
    "Rank": 610801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21529,
    "Rank": 610806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21530,
    "Rank": 610830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21531,
    "Rank": 611150,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Guwahati,PO-CHANGSARI, DISTRICT-KAMRUP, Assam, 781101",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21532,
    "Rank": 611378,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21533,
    "Rank": 611387,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21534,
    "Rank": 611499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21535,
    "Rank": 611614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21536,
    "Rank": 611641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21537,
    "Rank": 611916,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21538,
    "Rank": 611935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21539,
    "Rank": 611945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21540,
    "Rank": 612076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21541,
    "Rank": 612081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21542,
    "Rank": 612162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21543,
    "Rank": 612238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21544,
    "Rank": 612346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21545,
    "Rank": 612373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21546,
    "Rank": 612432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21547,
    "Rank": 612453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21548,
    "Rank": 612535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21549,
    "Rank": 612718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21550,
    "Rank": 612834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21551,
    "Rank": 612880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21552,
    "Rank": 612916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21553,
    "Rank": 612947,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21554,
    "Rank": 612965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21555,
    "Rank": 612981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21556,
    "Rank": 613062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21557,
    "Rank": 613072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21558,
    "Rank": 613710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21559,
    "Rank": 613805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21560,
    "Rank": 614192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21561,
    "Rank": 614526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21562,
    "Rank": 614651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21563,
    "Rank": 614672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21564,
    "Rank": 614784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21565,
    "Rank": 614802,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21566,
    "Rank": 615065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21567,
    "Rank": 615074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21568,
    "Rank": 615092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21569,
    "Rank": 615263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21570,
    "Rank": 615312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21571,
    "Rank": 615433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21572,
    "Rank": 615506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21573,
    "Rank": 615541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21574,
    "Rank": 615625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21575,
    "Rank": 615667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21576,
    "Rank": 615798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21577,
    "Rank": 615802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21578,
    "Rank": 615806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21579,
    "Rank": 615915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21580,
    "Rank": 616125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21581,
    "Rank": 616309,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21582,
    "Rank": 616326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21583,
    "Rank": 616538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21584,
    "Rank": 616789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21585,
    "Rank": 616959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21586,
    "Rank": 617086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21587,
    "Rank": 617144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21588,
    "Rank": 617173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21589,
    "Rank": 617372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21590,
    "Rank": 617479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21591,
    "Rank": 617585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21592,
    "Rank": 617590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21593,
    "Rank": 617725,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21594,
    "Rank": 617812,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21595,
    "Rank": 617827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21596,
    "Rank": 618007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21597,
    "Rank": 618073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21598,
    "Rank": 618092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21599,
    "Rank": 618264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21600,
    "Rank": 618323,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21601,
    "Rank": 618384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21602,
    "Rank": 618521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21603,
    "Rank": 618524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21604,
    "Rank": 618537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21605,
    "Rank": 618555,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21606,
    "Rank": 618578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21607,
    "Rank": 618658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21608,
    "Rank": 618842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21609,
    "Rank": 618920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21610,
    "Rank": 619014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21611,
    "Rank": 619050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21612,
    "Rank": 619078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21613,
    "Rank": 619117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21614,
    "Rank": 619279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21615,
    "Rank": 619358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,RAZA BAZAR, SHEIKHPURA, PATNA, Bihar, 800014",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21616,
    "Rank": 619370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21617,
    "Rank": 619398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21618,
    "Rank": 619410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21619,
    "Rank": 619527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21620,
    "Rank": 619618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21621,
    "Rank": 619629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21622,
    "Rank": 619714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21623,
    "Rank": 619832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21624,
    "Rank": 620145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21625,
    "Rank": 620288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21626,
    "Rank": 620436,
    "Allotted Quota": "All India",
    "Allotted Institute": "COLLEGE OF MEDICINE and JNM HOSPITAL, KALYANI,A Block\nPS- Kalyani\nDistrict - Nadia,West Bengal,Pin 741235, West Bengal, 741235",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21627,
    "Rank": 620449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21628,
    "Rank": 620473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21629,
    "Rank": 620491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21630,
    "Rank": 620501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21631,
    "Rank": 620620,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21632,
    "Rank": 620710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21633,
    "Rank": 620724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21634,
    "Rank": 620770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21635,
    "Rank": 620776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21636,
    "Rank": 620989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21637,
    "Rank": 621057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21638,
    "Rank": 621164,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21639,
    "Rank": 621181,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21640,
    "Rank": 621187,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21641,
    "Rank": 621255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21642,
    "Rank": 621274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21643,
    "Rank": 621289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21644,
    "Rank": 621477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21645,
    "Rank": 621512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21646,
    "Rank": 621527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21647,
    "Rank": 621626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21648,
    "Rank": 621859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21649,
    "Rank": 621929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21650,
    "Rank": 621930,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21651,
    "Rank": 622058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21652,
    "Rank": 622191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21653,
    "Rank": 622200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21654,
    "Rank": 622276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21655,
    "Rank": 622286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21656,
    "Rank": 622603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21657,
    "Rank": 622615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21658,
    "Rank": 622713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21659,
    "Rank": 622979,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21660,
    "Rank": 623247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21661,
    "Rank": 623308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21662,
    "Rank": 623538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21663,
    "Rank": 623670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21664,
    "Rank": 623789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21665,
    "Rank": 623802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21666,
    "Rank": 623886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21667,
    "Rank": 623947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21668,
    "Rank": 624021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21669,
    "Rank": 624067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21670,
    "Rank": 624313,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21671,
    "Rank": 624314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21672,
    "Rank": 624381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21673,
    "Rank": 624421,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21674,
    "Rank": 624462,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 30 )"
  },
  {
    "SNo": 21675,
    "Rank": 624480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21676,
    "Rank": 624506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21677,
    "Rank": 624602,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21678,
    "Rank": 624655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21679,
    "Rank": 625067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21680,
    "Rank": 625080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21681,
    "Rank": 625318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21682,
    "Rank": 625607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21683,
    "Rank": 625748,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21684,
    "Rank": 625768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21685,
    "Rank": 625784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21686,
    "Rank": 625908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21687,
    "Rank": 625917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21688,
    "Rank": 625991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21689,
    "Rank": 625997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21690,
    "Rank": 626086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21691,
    "Rank": 626341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21692,
    "Rank": 626354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21693,
    "Rank": 626422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21694,
    "Rank": 626677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21695,
    "Rank": 626732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21696,
    "Rank": 626743,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21697,
    "Rank": 626830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21698,
    "Rank": 626864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21699,
    "Rank": 627048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21700,
    "Rank": 627082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21701,
    "Rank": 627130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21702,
    "Rank": 627166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21703,
    "Rank": 627392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21704,
    "Rank": 627402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21705,
    "Rank": 627444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21706,
    "Rank": 627487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21707,
    "Rank": 627583,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21708,
    "Rank": 627731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21709,
    "Rank": 627925,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21710,
    "Rank": 627974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21711,
    "Rank": 628055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21712,
    "Rank": 628061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21713,
    "Rank": 628157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21714,
    "Rank": 628530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21715,
    "Rank": 628824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21716,
    "Rank": 628863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21717,
    "Rank": 628900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21718,
    "Rank": 629150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21719,
    "Rank": 629235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21720,
    "Rank": 629435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21721,
    "Rank": 629495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21722,
    "Rank": 629501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21723,
    "Rank": 629858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21724,
    "Rank": 629933,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21725,
    "Rank": 630156,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21726,
    "Rank": 630166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21727,
    "Rank": 630193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21728,
    "Rank": 630300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21729,
    "Rank": 630330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21730,
    "Rank": 630624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21731,
    "Rank": 630647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21732,
    "Rank": 630651,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21733,
    "Rank": 630754,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21734,
    "Rank": 630772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21735,
    "Rank": 630821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21736,
    "Rank": 631074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21737,
    "Rank": 631213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21738,
    "Rank": 631277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21739,
    "Rank": 631284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21740,
    "Rank": 631349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21741,
    "Rank": 631578,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21742,
    "Rank": 631610,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21743,
    "Rank": 631671,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21744,
    "Rank": 631770,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21745,
    "Rank": 631869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21746,
    "Rank": 631875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21747,
    "Rank": 631927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,Kalpi Road Orai Jalaun, Uttar Pradesh, 285001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21748,
    "Rank": 631938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Dungarpur,VILLAGE- THANA DUNGARPUR, Rajasthan, 314001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21749,
    "Rank": 631966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21750,
    "Rank": 631972,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21751,
    "Rank": 631977,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS, PAWAPURI NALNADA, Bihar, 803115",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21752,
    "Rank": 631995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21753,
    "Rank": 632027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21754,
    "Rank": 632032,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21755,
    "Rank": 632169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddartha Medical College, Vijayawada,Beside New Government General Hospital Gunadala Vijayawada, Andhra Pradesh, 520008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21756,
    "Rank": 632206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21757,
    "Rank": 632212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21758,
    "Rank": 632224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21759,
    "Rank": 632253,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21760,
    "Rank": 632350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21761,
    "Rank": 632383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21762,
    "Rank": 632492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21763,
    "Rank": 632536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21764,
    "Rank": 632549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21765,
    "Rank": 632650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21766,
    "Rank": 632954,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21767,
    "Rank": 633341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21768,
    "Rank": 633587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21769,
    "Rank": 633717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21770,
    "Rank": 633846,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21771,
    "Rank": 633867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21772,
    "Rank": 634011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21773,
    "Rank": 634119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21774,
    "Rank": 634260,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21775,
    "Rank": 634355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21776,
    "Rank": 634408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21777,
    "Rank": 634763,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21778,
    "Rank": 634777,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21779,
    "Rank": 634851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21780,
    "Rank": 634860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21781,
    "Rank": 634909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21782,
    "Rank": 634966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21783,
    "Rank": 634989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21784,
    "Rank": 635069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute Medical Sce of Adilabad,MAIN ROAD, OLD NH 07, ADILABAD TOWN\nADILABAD DISTRICT, TELANGANA STATE-504001, Telangana, 504001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21785,
    "Rank": 635161,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21786,
    "Rank": 635181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21787,
    "Rank": 635298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21788,
    "Rank": 635385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21789,
    "Rank": 635430,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21790,
    "Rank": 635779,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21791,
    "Rank": 635812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21792,
    "Rank": 635859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21793,
    "Rank": 635925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21794,
    "Rank": 636033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21795,
    "Rank": 636197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21796,
    "Rank": 636271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21797,
    "Rank": 636439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21798,
    "Rank": 636502,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21799,
    "Rank": 636508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21800,
    "Rank": 636580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21801,
    "Rank": 636602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21802,
    "Rank": 636655,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21803,
    "Rank": 636680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21804,
    "Rank": 636769,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21805,
    "Rank": 636929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21806,
    "Rank": 637150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21807,
    "Rank": 637216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21808,
    "Rank": 637302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21809,
    "Rank": 637352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21810,
    "Rank": 637441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21811,
    "Rank": 637582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21812,
    "Rank": 637677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21813,
    "Rank": 637691,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21814,
    "Rank": 637755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21815,
    "Rank": 637975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21816,
    "Rank": 638113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21817,
    "Rank": 638119,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARSHI DEVRAHA BABA AUTONOMOUS STATE. MEDICAL COLLEGE,\nDEORIA,207 Hospital Road Saket nagar Deoria, Uttar Pradesh, 274001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21818,
    "Rank": 638190,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21819,
    "Rank": 638220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21820,
    "Rank": 638393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21821,
    "Rank": 638594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21822,
    "Rank": 638628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21823,
    "Rank": 638633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21824,
    "Rank": 638695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21825,
    "Rank": 638774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21826,
    "Rank": 638792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21827,
    "Rank": 638809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21828,
    "Rank": 638866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21829,
    "Rank": 638892,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21830,
    "Rank": 639007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21831,
    "Rank": 639092,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21832,
    "Rank": 639116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21833,
    "Rank": 639173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21834,
    "Rank": 639212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21835,
    "Rank": 639272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21836,
    "Rank": 639549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21837,
    "Rank": 639558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21838,
    "Rank": 639870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21839,
    "Rank": 639975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21840,
    "Rank": 640137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21841,
    "Rank": 640468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21842,
    "Rank": 640632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21843,
    "Rank": 640674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21844,
    "Rank": 640682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21845,
    "Rank": 640709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21846,
    "Rank": 640816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21847,
    "Rank": 640964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21848,
    "Rank": 641096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21849,
    "Rank": 641113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21850,
    "Rank": 641141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21851,
    "Rank": 641226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21852,
    "Rank": 641279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21853,
    "Rank": 641360,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21854,
    "Rank": 641490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21855,
    "Rank": 641802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21856,
    "Rank": 641838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21857,
    "Rank": 641890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21858,
    "Rank": 641920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21859,
    "Rank": 641951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21860,
    "Rank": 641956,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21861,
    "Rank": 642121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21862,
    "Rank": 642195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21863,
    "Rank": 642362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College , Fatehpur,Autonomous State Medical College Village chittaura near Allipur GT road fatehpur, Uttar Pradesh, 212601",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21864,
    "Rank": 642437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21865,
    "Rank": 642844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21866,
    "Rank": 642861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21867,
    "Rank": 642953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21868,
    "Rank": 643180,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21869,
    "Rank": 643184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21870,
    "Rank": 643255,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21871,
    "Rank": 643299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21872,
    "Rank": 643343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21873,
    "Rank": 643729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21874,
    "Rank": 643793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21875,
    "Rank": 643866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21876,
    "Rank": 644235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21877,
    "Rank": 644261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21878,
    "Rank": 644299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21879,
    "Rank": 644379,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21880,
    "Rank": 644436,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21881,
    "Rank": 644527,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21882,
    "Rank": 644528,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21883,
    "Rank": 644578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21884,
    "Rank": 644590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21885,
    "Rank": 644613,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21886,
    "Rank": 644638,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21887,
    "Rank": 644718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21888,
    "Rank": 644843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21889,
    "Rank": 644945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Nalgonda,OFFICE OF THE PRINCIPAL, GOVERNMENT MEDICAL COLLEGE NALGONDA, NEAR BUS STAND, NALGONDA, Telangana, 508001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21890,
    "Rank": 645168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21891,
    "Rank": 645171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21892,
    "Rank": 645203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21893,
    "Rank": 645246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21894,
    "Rank": 645249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21895,
    "Rank": 645302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21896,
    "Rank": 645358,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21897,
    "Rank": 645435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21898,
    "Rank": 645473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21899,
    "Rank": 645545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21900,
    "Rank": 645756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21901,
    "Rank": 645796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21902,
    "Rank": 645813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21903,
    "Rank": 646005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21904,
    "Rank": 646017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21905,
    "Rank": 646070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21906,
    "Rank": 646106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21907,
    "Rank": 646143,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21908,
    "Rank": 646161,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Atal Bihari Vajpayee Institute of Medical Sciences & DR RML HOSPITAL, NEW DELHI,ABVIMS, Dr. RML Hospital, B.K.S. Marg, New Delhi, Delhi (NCT), 110001",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 31 )"
  },
  {
    "SNo": 21909,
    "Rank": 646319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21910,
    "Rank": 646333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21911,
    "Rank": 646418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21912,
    "Rank": 646429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21913,
    "Rank": 646533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21914,
    "Rank": 646713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21915,
    "Rank": 646849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21916,
    "Rank": 646866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21917,
    "Rank": 647027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagarkurnool,Sy no. 237, Uyyalawada village, Nanagarkurnool, Telangana, 509209",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21918,
    "Rank": 647227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21919,
    "Rank": 647390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21920,
    "Rank": 647435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21921,
    "Rank": 647483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21922,
    "Rank": 647491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21923,
    "Rank": 647575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21924,
    "Rank": 647628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21925,
    "Rank": 647738,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21926,
    "Rank": 647815,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI KALYAN GOVT MEDICAL COLLEGE SIKAR,Shri Kalyan Govt. Medical College, Bajaj Gram, Sanwali, NH 8 A, Sikar 332001, Rajasthan, 332001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21927,
    "Rank": 647823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21928,
    "Rank": 647909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21929,
    "Rank": 648067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21930,
    "Rank": 648240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21931,
    "Rank": 648412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21932,
    "Rank": 648609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21933,
    "Rank": 648620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21934,
    "Rank": 648658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21935,
    "Rank": 648690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21936,
    "Rank": 648865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21937,
    "Rank": 648868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21938,
    "Rank": 649411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21939,
    "Rank": 649433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21940,
    "Rank": 649517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21941,
    "Rank": 649524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21942,
    "Rank": 649666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21943,
    "Rank": 649715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21944,
    "Rank": 649733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21945,
    "Rank": 649789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21946,
    "Rank": 649873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21947,
    "Rank": 649928,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21948,
    "Rank": 650028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21949,
    "Rank": 650153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21950,
    "Rank": 650307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21951,
    "Rank": 650456,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21952,
    "Rank": 650601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21953,
    "Rank": 650686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21954,
    "Rank": 650798,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21955,
    "Rank": 651023,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21956,
    "Rank": 651025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21957,
    "Rank": 651026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21958,
    "Rank": 651071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21959,
    "Rank": 651179,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21960,
    "Rank": 651302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21961,
    "Rank": 651364,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21962,
    "Rank": 651431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21963,
    "Rank": 651732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21964,
    "Rank": 651760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21965,
    "Rank": 651946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21966,
    "Rank": 651974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21967,
    "Rank": 652008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21968,
    "Rank": 652016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21969,
    "Rank": 652039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21970,
    "Rank": 652071,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21971,
    "Rank": 652217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21972,
    "Rank": 652414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21973,
    "Rank": 652440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21974,
    "Rank": 652449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21975,
    "Rank": 652544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21976,
    "Rank": 652641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21977,
    "Rank": 652724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21978,
    "Rank": 652834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21979,
    "Rank": 652914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21980,
    "Rank": 652989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21981,
    "Rank": 653102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21982,
    "Rank": 653378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21983,
    "Rank": 653595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21984,
    "Rank": 653794,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21985,
    "Rank": 653804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21986,
    "Rank": 653974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21987,
    "Rank": 654016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21988,
    "Rank": 654034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21989,
    "Rank": 654188,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21990,
    "Rank": 654321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21991,
    "Rank": 654390,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21992,
    "Rank": 654451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21993,
    "Rank": 654481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21994,
    "Rank": 654620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21995,
    "Rank": 654731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21996,
    "Rank": 655110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21997,
    "Rank": 655173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21998,
    "Rank": 655270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 21999,
    "Rank": 655428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22000,
    "Rank": 655505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22001,
    "Rank": 655546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22002,
    "Rank": 655580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22003,
    "Rank": 655596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Saveetha Dental College, Chennai,Saveetha Dental College and Hospitals,162, Poonamallee high road, Velapanchavadi, Chennai, Tamil Nadu, 600077",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22004,
    "Rank": 656017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22005,
    "Rank": 656167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22006,
    "Rank": 656221,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22007,
    "Rank": 656268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22008,
    "Rank": 656279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22009,
    "Rank": 656364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22010,
    "Rank": 656419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22011,
    "Rank": 656432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22012,
    "Rank": 656440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22013,
    "Rank": 656602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22014,
    "Rank": 656728,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22015,
    "Rank": 657013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22016,
    "Rank": 657018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22017,
    "Rank": 657035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22018,
    "Rank": 657082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22019,
    "Rank": 657129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22020,
    "Rank": 657190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22021,
    "Rank": 657243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22022,
    "Rank": 657461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22023,
    "Rank": 657879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22024,
    "Rank": 657946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22025,
    "Rank": 658109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22026,
    "Rank": 658306,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22027,
    "Rank": 658451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22028,
    "Rank": 658600,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22029,
    "Rank": 658628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22030,
    "Rank": 658813,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22031,
    "Rank": 658870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22032,
    "Rank": 658873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22033,
    "Rank": 658929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22034,
    "Rank": 658981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22035,
    "Rank": 659009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22036,
    "Rank": 659031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22037,
    "Rank": 659055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22038,
    "Rank": 659214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22039,
    "Rank": 659277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22040,
    "Rank": 659405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22041,
    "Rank": 659445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22042,
    "Rank": 659447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22043,
    "Rank": 659474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22044,
    "Rank": 659503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22045,
    "Rank": 659641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22046,
    "Rank": 659710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22047,
    "Rank": 659830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22048,
    "Rank": 660007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22049,
    "Rank": 660027,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22050,
    "Rank": 660078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22051,
    "Rank": 660143,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Shahjhanpur,AUTONOMOUS STATE MEDICAL COLLEGE ALLIED PANDIT RAM PRASAD BISMIL HOSPITAL SHAHJAHANPUR UTTAR PRADESH, Uttar\nPradesh, 242001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22052,
    "Rank": 660157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22053,
    "Rank": 660331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22054,
    "Rank": 660357,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22055,
    "Rank": 660475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22056,
    "Rank": 660526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22057,
    "Rank": 660537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22058,
    "Rank": 660574,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22059,
    "Rank": 660875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22060,
    "Rank": 660993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22061,
    "Rank": 661008,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22062,
    "Rank": 661128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22063,
    "Rank": 661231,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22064,
    "Rank": 661350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22065,
    "Rank": 661500,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22066,
    "Rank": 661596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22067,
    "Rank": 661689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22068,
    "Rank": 661795,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22069,
    "Rank": 661799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22070,
    "Rank": 661827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22071,
    "Rank": 661844,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22072,
    "Rank": 661853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22073,
    "Rank": 661902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22074,
    "Rank": 662060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22075,
    "Rank": 662096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22076,
    "Rank": 662116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22077,
    "Rank": 662199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22078,
    "Rank": 662258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22079,
    "Rank": 662805,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22080,
    "Rank": 662936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalyan Singh Government Medical College, Bulandshahr , Uttar Prades,339, CHANDPUR ROAD,\nNEAR SADAR TEHSIL,\nBULANDSHAHR, Uttar Pradesh, 203001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22081,
    "Rank": 663111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22082,
    "Rank": 663122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22083,
    "Rank": 663159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22084,
    "Rank": 663211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22085,
    "Rank": 663220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22086,
    "Rank": 663356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22087,
    "Rank": 663436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22088,
    "Rank": 663766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22089,
    "Rank": 663879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22090,
    "Rank": 663921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22091,
    "Rank": 663972,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22092,
    "Rank": 664001,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SYMBIOSIS MEDICAL COLLEGE FOR WOMEN PUNE,Gram Lavale Tal Mulshi Pune, Maharashtra, 412115 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22093,
    "Rank": 664040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22094,
    "Rank": 664054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22095,
    "Rank": 664056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22096,
    "Rank": 664336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22097,
    "Rank": 664457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22098,
    "Rank": 664560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22099,
    "Rank": 664697,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Plot No. 1-2, Sector 1, Kamothe, Navi Mumbai - 410 209, Maharashtra, 410209",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22100,
    "Rank": 664722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22101,
    "Rank": 664767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22102,
    "Rank": 664832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22103,
    "Rank": 665234,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22104,
    "Rank": 665322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22105,
    "Rank": 665432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22106,
    "Rank": 665464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22107,
    "Rank": 665509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22108,
    "Rank": 665564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22109,
    "Rank": 665632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22110,
    "Rank": 665860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22111,
    "Rank": 665935,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22112,
    "Rank": 665966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22113,
    "Rank": 666100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22114,
    "Rank": 666435,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22115,
    "Rank": 666447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22116,
    "Rank": 666481,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE PURNEA,govt.mcpurnea@gmail.com, Bihar, 854301",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22117,
    "Rank": 666537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pratapgarh,Pure Keshav Rai Gay Ghat Road Pratapgarh, Uttar Pradesh, 230001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22118,
    "Rank": 666581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22119,
    "Rank": 666589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22120,
    "Rank": 666695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22121,
    "Rank": 666704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22122,
    "Rank": 666916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22123,
    "Rank": 666929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22124,
    "Rank": 666931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22125,
    "Rank": 667092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22126,
    "Rank": 667135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22127,
    "Rank": 667362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22128,
    "Rank": 667646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22129,
    "Rank": 667663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22130,
    "Rank": 667709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22131,
    "Rank": 667886,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22132,
    "Rank": 667979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22133,
    "Rank": 667995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22134,
    "Rank": 668004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22135,
    "Rank": 668021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Bhagalpur,SUKHRAJ RAI PATH, BHAGALPUR, Bihar, 812001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22136,
    "Rank": 668095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22137,
    "Rank": 668099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22138,
    "Rank": 668406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22139,
    "Rank": 668419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22140,
    "Rank": 668500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22141,
    "Rank": 668555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22142,
    "Rank": 668565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22143,
    "Rank": 668787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22144,
    "Rank": 668913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College and  Hospital, Chandrapur,GOVT. MEDICAL COLLEGE CHANDRAPUR, RAM NAGAR, CHANDRAPUR, Maharashtra, 442401",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22145,
    "Rank": 668923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22146,
    "Rank": 669027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22147,
    "Rank": 669157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22148,
    "Rank": 669206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22149,
    "Rank": 669298,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22150,
    "Rank": 669332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22151,
    "Rank": 669456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22152,
    "Rank": 669546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22153,
    "Rank": 669748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22154,
    "Rank": 669795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22155,
    "Rank": 670020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sagar Dutta Medical College & Hospital, Kolkata,578, B.T. Road, Kamarhati, Kolkata, West Bengal, 700058",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22156,
    "Rank": 670298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22157,
    "Rank": 670356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22158,
    "Rank": 670410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22159,
    "Rank": 670444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22160,
    "Rank": 670704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22161,
    "Rank": 670778,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22162,
    "Rank": 670827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22163,
    "Rank": 670965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22164,
    "Rank": 671088,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22165,
    "Rank": 671126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22166,
    "Rank": 671138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22167,
    "Rank": 671220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22168,
    "Rank": 671238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22169,
    "Rank": 671324,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22170,
    "Rank": 671408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22171,
    "Rank": 671504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22172,
    "Rank": 671717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22173,
    "Rank": 671925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22174,
    "Rank": 671945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22175,
    "Rank": 671959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22176,
    "Rank": 672001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22177,
    "Rank": 672034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22178,
    "Rank": 672170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22179,
    "Rank": 672219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22180,
    "Rank": 672251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22181,
    "Rank": 672276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22182,
    "Rank": 672423,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22183,
    "Rank": 672540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22184,
    "Rank": 672762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22185,
    "Rank": 672925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22186,
    "Rank": 673115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22187,
    "Rank": 673388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22188,
    "Rank": 673412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22189,
    "Rank": 673703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22190,
    "Rank": 673735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22191,
    "Rank": 674107,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22192,
    "Rank": 674122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22193,
    "Rank": 674152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22194,
    "Rank": 674163,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22195,
    "Rank": 674221,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22196,
    "Rank": 674467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22197,
    "Rank": 674534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22198,
    "Rank": 674705,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22199,
    "Rank": 674884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22200,
    "Rank": 674923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22201,
    "Rank": 675089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22202,
    "Rank": 675140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22203,
    "Rank": 675415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22204,
    "Rank": 675544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Phulo Jhano Medical College , Dumka,PHULO JHANO MEDICAL COLLEGE DUMKA, Jharkhand, 814110",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22205,
    "Rank": 675563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22206,
    "Rank": 675564,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22207,
    "Rank": 675639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22208,
    "Rank": 675682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22209,
    "Rank": 675723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22210,
    "Rank": 675832,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22211,
    "Rank": 675855,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22212,
    "Rank": 675858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22213,
    "Rank": 675916,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22214,
    "Rank": 675951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22215,
    "Rank": 675966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22216,
    "Rank": 676012,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22217,
    "Rank": 676045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22218,
    "Rank": 676236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22219,
    "Rank": 676493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22220,
    "Rank": 676504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22221,
    "Rank": 676640,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bibi Nagar, Hyderabad,AIIMS Bibinagar (Hyderabad Metropolitan Region) Telangana\n508126, Telangana, 508126",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22222,
    "Rank": 676926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22223,
    "Rank": 676959,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22224,
    "Rank": 677171,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22225,
    "Rank": 677178,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22226,
    "Rank": 677196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22227,
    "Rank": 677339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22228,
    "Rank": 677488,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22229,
    "Rank": 677642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22230,
    "Rank": 677736,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22231,
    "Rank": 677829,
    "Allotted Quota": "Employees State Insurance Scheme(ESI)",
    "Allotted Institute": "ESIC Dental College, Gulbarga,ESIC MEDICAL COLLEGE & HOSPITAL CAMPUS, Sedam Road, Gulbarga, Karnataka, 585106",
    "Course": "BDS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22232,
    "Rank": 677836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22233,
    "Rank": 677852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22234,
    "Rank": 677890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22235,
    "Rank": 677917,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22236,
    "Rank": 677935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22237,
    "Rank": 678053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22238,
    "Rank": 678057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22239,
    "Rank": 678088,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22240,
    "Rank": 678121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22241,
    "Rank": 678419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22242,
    "Rank": 678611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22243,
    "Rank": 678847,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22244,
    "Rank": 678856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22245,
    "Rank": 678968,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22246,
    "Rank": 679035,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22247,
    "Rank": 679099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22248,
    "Rank": 679337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22249,
    "Rank": 679433,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22250,
    "Rank": 679677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22251,
    "Rank": 679881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22252,
    "Rank": 680079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22253,
    "Rank": 680153,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22254,
    "Rank": 680178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22255,
    "Rank": 680199,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22256,
    "Rank": 680227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22257,
    "Rank": 680362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22258,
    "Rank": 680390,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22259,
    "Rank": 680535,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22260,
    "Rank": 680571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22261,
    "Rank": 680614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22262,
    "Rank": 680690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22263,
    "Rank": 680717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22264,
    "Rank": 680746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22265,
    "Rank": 680995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22266,
    "Rank": 681108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22267,
    "Rank": 681317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22268,
    "Rank": 681411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22269,
    "Rank": 681425,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22270,
    "Rank": 681554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22271,
    "Rank": 681557,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22272,
    "Rank": 681824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22273,
    "Rank": 681930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22274,
    "Rank": 681959,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Vashi, Navi Mumbai,Sector 30, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22275,
    "Rank": 682275,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22276,
    "Rank": 682308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22277,
    "Rank": 682381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22278,
    "Rank": 682442,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22279,
    "Rank": 682635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22280,
    "Rank": 682687,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22281,
    "Rank": 682928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22282,
    "Rank": 682935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22283,
    "Rank": 683058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22284,
    "Rank": 683085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22285,
    "Rank": 683127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22286,
    "Rank": 683203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22287,
    "Rank": 683249,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22288,
    "Rank": 683386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Vidur Autonomous State Medical College,Bijnor Uttar Pradesh,Mahatma Vidur Autonomous State Medical College, Village Madhusudanpur Devidas, Post Kiratpur, Naziba, Uttar Pradesh, 246731",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22289,
    "Rank": 683445,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22290,
    "Rank": 683457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22291,
    "Rank": 683541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22292,
    "Rank": 683660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22293,
    "Rank": 683667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22294,
    "Rank": 683684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22295,
    "Rank": 683702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22296,
    "Rank": 684264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ananthapuram,OPP EE R AND B OFFICE SAI NAGAR ANANTAPURAM, Andhra Pradesh, 515001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22297,
    "Rank": 684308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22298,
    "Rank": 684325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22299,
    "Rank": 684356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22300,
    "Rank": 684390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22301,
    "Rank": 684973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22302,
    "Rank": 685031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22303,
    "Rank": 685037,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22304,
    "Rank": 685202,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22305,
    "Rank": 685254,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22306,
    "Rank": 685369,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22307,
    "Rank": 685375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22308,
    "Rank": 685392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22309,
    "Rank": 685443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22310,
    "Rank": 685486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22311,
    "Rank": 685552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22312,
    "Rank": 685562,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22313,
    "Rank": 685623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22314,
    "Rank": 685822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22315,
    "Rank": 685890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22316,
    "Rank": 685899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22317,
    "Rank": 686005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22318,
    "Rank": 686029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22319,
    "Rank": 686226,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22320,
    "Rank": 686271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22321,
    "Rank": 686311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22322,
    "Rank": 686339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22323,
    "Rank": 686341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22324,
    "Rank": 686580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22325,
    "Rank": 686679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22326,
    "Rank": 686856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22327,
    "Rank": 687239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22328,
    "Rank": 687280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22329,
    "Rank": 687416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22330,
    "Rank": 687475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Satna ,Near Kendriya Vidyalaya No. 2, Kripalpur Satna, Madhya Pradesh, 485001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22331,
    "Rank": 687483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22332,
    "Rank": 687624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22333,
    "Rank": 687929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22334,
    "Rank": 687949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College, Visakhapatnam,MAHARANI PETA NEAR COLLECTORATE\nKGH CAMPUS\nVISAKHAPATNAM, Andhra Pradesh, 530002",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22335,
    "Rank": 688586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22336,
    "Rank": 688612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22337,
    "Rank": 689160,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22338,
    "Rank": 689321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22339,
    "Rank": 689417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22340,
    "Rank": 689878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22341,
    "Rank": 689910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22342,
    "Rank": 689970,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22343,
    "Rank": 690010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22344,
    "Rank": 690012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22345,
    "Rank": 690115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22346,
    "Rank": 690119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22347,
    "Rank": 690298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22348,
    "Rank": 690299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22349,
    "Rank": 690427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22350,
    "Rank": 690431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22351,
    "Rank": 690434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22352,
    "Rank": 690541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22353,
    "Rank": 690785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22354,
    "Rank": 690805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22355,
    "Rank": 691000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22356,
    "Rank": 691039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22357,
    "Rank": 691212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22358,
    "Rank": 691286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22359,
    "Rank": 691700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22360,
    "Rank": 691715,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22361,
    "Rank": 691732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22362,
    "Rank": 691786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22363,
    "Rank": 691875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22364,
    "Rank": 691880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22365,
    "Rank": 692525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22366,
    "Rank": 692773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22367,
    "Rank": 692842,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUWAHATI MEDICAL COLLEGE, GUWAHATI,Narakasur Hill top , Bhangagarh, Guwahati, Assam, 781032",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22368,
    "Rank": 693002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22369,
    "Rank": 693029,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22370,
    "Rank": 693048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22371,
    "Rank": 693329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22372,
    "Rank": 693349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22373,
    "Rank": 693438,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22374,
    "Rank": 693573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22375,
    "Rank": 693871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22376,
    "Rank": 693874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22377,
    "Rank": 693895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22378,
    "Rank": 693947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22379,
    "Rank": 693996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22380,
    "Rank": 694025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22381,
    "Rank": 694221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22382,
    "Rank": 694251,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22383,
    "Rank": 694299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22384,
    "Rank": 694420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22385,
    "Rank": 694524,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22386,
    "Rank": 694670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22387,
    "Rank": 694831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22388,
    "Rank": 694987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22389,
    "Rank": 695154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22390,
    "Rank": 695397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22391,
    "Rank": 695993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22392,
    "Rank": 695994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22393,
    "Rank": 696036,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, BUNDI,GOVT MEDICAL COLLEGE BUNDI,\nRajasthan, 323001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22394,
    "Rank": 696067,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22395,
    "Rank": 696133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22396,
    "Rank": 696159,
    "Allotted Quota": "All India",
    "Allotted Institute": "ASSAM MEDICAL COLLEGE, DIBRUGARH,Borbari, Dibrugarh, Assam, 786002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22397,
    "Rank": 696338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22398,
    "Rank": 696523,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVT. MED. COLL.,ASARIPALLAM,ASARIPALLAM, KANYAKUMARI DISTRICT-629201, Tamil Nadu, 629201",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22399,
    "Rank": 696711,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22400,
    "Rank": 696753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22401,
    "Rank": 696771,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22402,
    "Rank": 696813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22403,
    "Rank": 696869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22404,
    "Rank": 696974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22405,
    "Rank": 697266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22406,
    "Rank": 697302,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22407,
    "Rank": 697356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22408,
    "Rank": 697482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22409,
    "Rank": 697489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22410,
    "Rank": 697516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22411,
    "Rank": 697518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22412,
    "Rank": 697580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22413,
    "Rank": 697618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22414,
    "Rank": 697662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22415,
    "Rank": 697813,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22416,
    "Rank": 698178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22417,
    "Rank": 698248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22418,
    "Rank": 698423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22419,
    "Rank": 698431,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22420,
    "Rank": 698440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22421,
    "Rank": 698799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22422,
    "Rank": 698997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22423,
    "Rank": 699013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22424,
    "Rank": 699271,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22425,
    "Rank": 699482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22426,
    "Rank": 699527,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22427,
    "Rank": 699603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22428,
    "Rank": 699708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22429,
    "Rank": 699715,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22430,
    "Rank": 699770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22431,
    "Rank": 699808,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22432,
    "Rank": 699882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22433,
    "Rank": 700210,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA,\nMadhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22434,
    "Rank": 700219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22435,
    "Rank": 700244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22436,
    "Rank": 700308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22437,
    "Rank": 700404,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22438,
    "Rank": 700443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22439,
    "Rank": 700532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22440,
    "Rank": 700689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22441,
    "Rank": 700762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22442,
    "Rank": 701017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22443,
    "Rank": 701359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22444,
    "Rank": 701420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22445,
    "Rank": 701492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22446,
    "Rank": 701505,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22447,
    "Rank": 701770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22448,
    "Rank": 701820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22449,
    "Rank": 701912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22450,
    "Rank": 701932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22451,
    "Rank": 701967,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22452,
    "Rank": 702278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22453,
    "Rank": 702363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22454,
    "Rank": 702405,
    "Allotted Quota": "Internal -Puducherry UT Domicile",
    "Allotted Institute": "JIPMER PUDUCHERRY,Dhanvantari Nagar Gorimedu Puducherry, Puducherry, 605006",
    "Course": "MBBS",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22455,
    "Rank": 702459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22456,
    "Rank": 702508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22457,
    "Rank": 702805,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22458,
    "Rank": 702812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22459,
    "Rank": 702889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22460,
    "Rank": 703002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22461,
    "Rank": 703049,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22462,
    "Rank": 703088,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22463,
    "Rank": 703131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22464,
    "Rank": 703169,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22465,
    "Rank": 703719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22466,
    "Rank": 703847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22467,
    "Rank": 704022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22468,
    "Rank": 704223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22469,
    "Rank": 704238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22470,
    "Rank": 704301,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22471,
    "Rank": 704325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22472,
    "Rank": 704486,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22473,
    "Rank": 704543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22474,
    "Rank": 704712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22475,
    "Rank": 704826,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22476,
    "Rank": 704959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22477,
    "Rank": 705014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22478,
    "Rank": 705023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22479,
    "Rank": 705035,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22480,
    "Rank": 705058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22481,
    "Rank": 705101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22482,
    "Rank": 705413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22483,
    "Rank": 705444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22484,
    "Rank": 705928,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22485,
    "Rank": 706054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22486,
    "Rank": 706068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22487,
    "Rank": 706084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22488,
    "Rank": 706107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22489,
    "Rank": 706212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22490,
    "Rank": 706233,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22491,
    "Rank": 706353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22492,
    "Rank": 706442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22493,
    "Rank": 706474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22494,
    "Rank": 706647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22495,
    "Rank": 706727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22496,
    "Rank": 706748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22497,
    "Rank": 706844,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22498,
    "Rank": 706912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22499,
    "Rank": 706932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22500,
    "Rank": 707107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22501,
    "Rank": 707212,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Bhubaneswar,AT - Sijua, POST - DUMUDUMA, BHUBANESWAR-751019,\nOdisha, 751019",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22502,
    "Rank": 707257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22503,
    "Rank": 707374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22504,
    "Rank": 707390,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22505,
    "Rank": 707584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22506,
    "Rank": 707637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22507,
    "Rank": 707718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22508,
    "Rank": 707821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22509,
    "Rank": 707874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22510,
    "Rank": 707955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22511,
    "Rank": 708036,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22512,
    "Rank": 708132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22513,
    "Rank": 708276,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22514,
    "Rank": 708310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22515,
    "Rank": 708323,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22516,
    "Rank": 708482,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22517,
    "Rank": 709085,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22518,
    "Rank": 709144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22519,
    "Rank": 709369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22520,
    "Rank": 709412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22521,
    "Rank": 709469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22522,
    "Rank": 709541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22523,
    "Rank": 709611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22524,
    "Rank": 709694,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22525,
    "Rank": 709699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22526,
    "Rank": 709756,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAMO Medical Education & Research Institute, Govt Medical College Silvassa,NAMO Medical Education and Research Institute, next to Sayli cricket stadium, Sayli road, Silvassa., Dadra And Nagar Haveli, 396230",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22527,
    "Rank": 710066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22528,
    "Rank": 710426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22529,
    "Rank": 710490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22530,
    "Rank": 710570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22531,
    "Rank": 710627,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22532,
    "Rank": 710672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22533,
    "Rank": 710771,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22534,
    "Rank": 710851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22535,
    "Rank": 710882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Sirohi (rajasthan),SIROHI SHIVGANJ HIGHWAY,NEAR AMBESHWAR ,VILLAGE- KOLAR, TEHSIL- SIVGANJ,DIST- SIROHI, Rajasthan, 307030",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22536,
    "Rank": 710927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22537,
    "Rank": 710989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22538,
    "Rank": 711098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22539,
    "Rank": 711121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22540,
    "Rank": 711203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22541,
    "Rank": 711277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22542,
    "Rank": 711304,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22543,
    "Rank": 711307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22544,
    "Rank": 711430,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22545,
    "Rank": 711491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22546,
    "Rank": 711609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22547,
    "Rank": 711623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22548,
    "Rank": 711752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22549,
    "Rank": 711800,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22550,
    "Rank": 711994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22551,
    "Rank": 712138,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22552,
    "Rank": 712139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22553,
    "Rank": 712240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22554,
    "Rank": 712367,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22555,
    "Rank": 712382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22556,
    "Rank": 712392,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22557,
    "Rank": 712405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22558,
    "Rank": 712423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22559,
    "Rank": 712490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22560,
    "Rank": 712821,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MC,JABALPUR,NAGPUR ROAD, JABALPUR, STATE-M.P.PIN-482003, Madhya Pradesh, 482003",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22561,
    "Rank": 712903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22562,
    "Rank": 712948,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22563,
    "Rank": 713009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22564,
    "Rank": 713240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22565,
    "Rank": 713271,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22566,
    "Rank": 713358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22567,
    "Rank": 713435,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS Srikakulam,Balaga Srikakulam, Andhra Pradesh, 532001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22568,
    "Rank": 713577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22569,
    "Rank": 713979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22570,
    "Rank": 714017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22571,
    "Rank": 714055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22572,
    "Rank": 714289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22573,
    "Rank": 714338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22574,
    "Rank": 714424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22575,
    "Rank": 714518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22576,
    "Rank": 714567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22577,
    "Rank": 714768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22578,
    "Rank": 715106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22579,
    "Rank": 715222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22580,
    "Rank": 715426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22581,
    "Rank": 715553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22582,
    "Rank": 715568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22583,
    "Rank": 715637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22584,
    "Rank": 715749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22585,
    "Rank": 715958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Baramati,Plot No P107 MIDC area Opposite Women Hospital Baramati Taluka Baramati District Pune, Maharashtra, 413133",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22586,
    "Rank": 715989,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22587,
    "Rank": 716093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22588,
    "Rank": 716131,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Kalyani,NH-34 Connector, Basantapur, Saguna, Kalyani, 741245, West Bengal, India, West Bengal, 741245",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22589,
    "Rank": 716247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22590,
    "Rank": 716383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22591,
    "Rank": 716647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22592,
    "Rank": 716651,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22593,
    "Rank": 716820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22594,
    "Rank": 716890,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22595,
    "Rank": 717295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22596,
    "Rank": 717432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22597,
    "Rank": 717446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22598,
    "Rank": 717509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22599,
    "Rank": 717556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22600,
    "Rank": 717884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22601,
    "Rank": 717949,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22602,
    "Rank": 718300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22603,
    "Rank": 718606,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22604,
    "Rank": 718669,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22605,
    "Rank": 718851,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22606,
    "Rank": 718853,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22607,
    "Rank": 718880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22608,
    "Rank": 718979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22609,
    "Rank": 719002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22610,
    "Rank": 719028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22611,
    "Rank": 719029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22612,
    "Rank": 719113,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22613,
    "Rank": 719154,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22614,
    "Rank": 719190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22615,
    "Rank": 719191,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22616,
    "Rank": 719437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Raghunath Murmu Med. College, Baripada,Rangamatia , p.o-Sankhabhanga , Via- Laxmiposi, Odisha, 757107",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22617,
    "Rank": 719488,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22618,
    "Rank": 719514,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22619,
    "Rank": 719545,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22620,
    "Rank": 719623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22621,
    "Rank": 719727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22622,
    "Rank": 719786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22623,
    "Rank": 719816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Amrita School of Dentistry, Kochi,Amrita School of Dentistry, Amrita Institute of Medical Sciences (AIMS), AIMS Ponekkara P.O, Kochi, Kerala, 682041",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22624,
    "Rank": 719821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22625,
    "Rank": 719991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22626,
    "Rank": 720436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22627,
    "Rank": 720529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22628,
    "Rank": 720548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22629,
    "Rank": 720969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22630,
    "Rank": 721079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22631,
    "Rank": 721125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22632,
    "Rank": 721132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22633,
    "Rank": 721231,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22634,
    "Rank": 721279,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22635,
    "Rank": 721428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22636,
    "Rank": 721623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22637,
    "Rank": 721657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22638,
    "Rank": 721692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22639,
    "Rank": 722120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22640,
    "Rank": 722597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22641,
    "Rank": 722668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22642,
    "Rank": 722687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22643,
    "Rank": 722803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22644,
    "Rank": 723017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22645,
    "Rank": 723029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22646,
    "Rank": 723092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22647,
    "Rank": 723291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22648,
    "Rank": 723367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22649,
    "Rank": 723386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22650,
    "Rank": 723394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22651,
    "Rank": 723671,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE,PATIALA,Sangrur road Government Medical College, Patiala, Punjab, 147001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22652,
    "Rank": 723750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22653,
    "Rank": 723781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22654,
    "Rank": 723855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22655,
    "Rank": 723893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22656,
    "Rank": 723898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22657,
    "Rank": 724008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22658,
    "Rank": 724062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22659,
    "Rank": 724079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22660,
    "Rank": 724081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22661,
    "Rank": 724130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22662,
    "Rank": 724287,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22663,
    "Rank": 724332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22664,
    "Rank": 724362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22665,
    "Rank": 724462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22666,
    "Rank": 724582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22667,
    "Rank": 724690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22668,
    "Rank": 724763,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22669,
    "Rank": 724826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22670,
    "Rank": 724955,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22671,
    "Rank": 724976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22672,
    "Rank": 725209,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22673,
    "Rank": 725252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22674,
    "Rank": 725352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22675,
    "Rank": 725692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22676,
    "Rank": 725761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22677,
    "Rank": 725770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22678,
    "Rank": 725797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22679,
    "Rank": 725946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22680,
    "Rank": 725965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22681,
    "Rank": 726039,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22682,
    "Rank": 726113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22683,
    "Rank": 726192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22684,
    "Rank": 726558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22685,
    "Rank": 726718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22686,
    "Rank": 726809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22687,
    "Rank": 726840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22688,
    "Rank": 727055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22689,
    "Rank": 727113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22690,
    "Rank": 727273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22691,
    "Rank": 727445,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22692,
    "Rank": 727533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22693,
    "Rank": 727549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22694,
    "Rank": 727575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22695,
    "Rank": 727717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22696,
    "Rank": 728140,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22697,
    "Rank": 728609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22698,
    "Rank": 728617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22699,
    "Rank": 728620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22700,
    "Rank": 728734,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22701,
    "Rank": 728964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22702,
    "Rank": 729078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22703,
    "Rank": 729139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22704,
    "Rank": 729195,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22705,
    "Rank": 729205,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22706,
    "Rank": 729265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22707,
    "Rank": 729330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22708,
    "Rank": 729365,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22709,
    "Rank": 729407,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE, PANAJI,RAJIV GANDHI MEDICAL COMPLEX, BAMBOLIM, GOA, Goa, 403202",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22710,
    "Rank": 729639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22711,
    "Rank": 729677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22712,
    "Rank": 729692,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22713,
    "Rank": 729834,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22714,
    "Rank": 729894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22715,
    "Rank": 729971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22716,
    "Rank": 730104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22717,
    "Rank": 730125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22718,
    "Rank": 730337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22719,
    "Rank": 730422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22720,
    "Rank": 730485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22721,
    "Rank": 730855,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22722,
    "Rank": 730885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22723,
    "Rank": 731007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22724,
    "Rank": 731083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22725,
    "Rank": 731154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22726,
    "Rank": 731206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jhargram Government Medical College and Hospital, West Bengal,Jhargram Government Medical College and Hospital, Vidyasagarpally, Jhargram, West Bengal., West Bengal, 721507",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22727,
    "Rank": 731654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22728,
    "Rank": 731786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22729,
    "Rank": 731899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22730,
    "Rank": 732309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22731,
    "Rank": 732370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22732,
    "Rank": 732448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22733,
    "Rank": 732487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22734,
    "Rank": 732741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Jitendra Narayan Medical College and Hospital Coochbehar,Vivekananda Street, Pilkhana, Beside Panchanan Barma University, Coochbehar, West Bengal, 736101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22735,
    "Rank": 732762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22736,
    "Rank": 732879,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22737,
    "Rank": 732931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22738,
    "Rank": 732952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22739,
    "Rank": 732969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22740,
    "Rank": 732973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22741,
    "Rank": 733116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22742,
    "Rank": 733122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22743,
    "Rank": 733250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22744,
    "Rank": 733441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22745,
    "Rank": 733594,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22746,
    "Rank": 733619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22747,
    "Rank": 733656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22748,
    "Rank": 733668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22749,
    "Rank": 733851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22750,
    "Rank": 733937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22751,
    "Rank": 734264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22752,
    "Rank": 734273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22753,
    "Rank": 734375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22754,
    "Rank": 734480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22755,
    "Rank": 734533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22756,
    "Rank": 734669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22757,
    "Rank": 734755,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22758,
    "Rank": 734966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22759,
    "Rank": 735001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22760,
    "Rank": 735051,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22761,
    "Rank": 735272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22762,
    "Rank": 735279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22763,
    "Rank": 735341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22764,
    "Rank": 735404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Jajpur, Odisha (Renamed as Jajati Keshari Medical College and Hospital, Jajpur),Dean and Principal, Government Medical College and Hospital, Jajpur, Odisha Renamed as Jajati Keshar, Odisha, 755001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22765,
    "Rank": 735432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22766,
    "Rank": 735490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22767,
    "Rank": 735823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22768,
    "Rank": 735831,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BHAVNAGAR,Near S.T. Bus Stand, Jail road, Bhavnagar., Gujarat, 364001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22769,
    "Rank": 735849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22770,
    "Rank": 735858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22771,
    "Rank": 735927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22772,
    "Rank": 735965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22773,
    "Rank": 735987,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22774,
    "Rank": 736014,
    "Allotted Quota": "Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)",
    "Allotted Institute": "ESIC College of Nursing, Gulbarga, Karnataka,ESIC COLLEGE OF NURSING ESIC MEDICAL COLLEGE AND HOSPITAL\nSEDAM ROAD KALABURIGI, Karnataka, 585106",
    "Course": "B.Sc. Nursing",
    "Alloted Category": "ST",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22775,
    "Rank": 736018,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22776,
    "Rank": 736137,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22777,
    "Rank": 736172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22778,
    "Rank": 736236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22779,
    "Rank": 736656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22780,
    "Rank": 736814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22781,
    "Rank": 736821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22782,
    "Rank": 736850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22783,
    "Rank": 736983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22784,
    "Rank": 737003,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Jangaon,gmc.jangaon@gmail.com, Telangana, 506167",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22785,
    "Rank": 737394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22786,
    "Rank": 737413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22787,
    "Rank": 737546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22788,
    "Rank": 737562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22789,
    "Rank": 737626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22790,
    "Rank": 737794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22791,
    "Rank": 737803,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22792,
    "Rank": 738251,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22793,
    "Rank": 738376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22794,
    "Rank": 738416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22795,
    "Rank": 738684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22796,
    "Rank": 738985,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22797,
    "Rank": 739215,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22798,
    "Rank": 739417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and District General Hospital,Ratnagiri,Hodekar Road, Udyamnagar, Patwardhanwadi, Ratnagiri, Maharashtra-415612, Maharashtra, 415612",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22799,
    "Rank": 739564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22800,
    "Rank": 739666,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22801,
    "Rank": 739688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22802,
    "Rank": 739707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22803,
    "Rank": 739849,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22804,
    "Rank": 739930,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22805,
    "Rank": 740207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22806,
    "Rank": 740235,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22807,
    "Rank": 740397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22808,
    "Rank": 740441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22809,
    "Rank": 740860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22810,
    "Rank": 741079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22811,
    "Rank": 741092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22812,
    "Rank": 741237,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22813,
    "Rank": 741378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22814,
    "Rank": 741498,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22815,
    "Rank": 741628,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22816,
    "Rank": 741863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22817,
    "Rank": 741876,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22818,
    "Rank": 742263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22819,
    "Rank": 742448,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22820,
    "Rank": 742497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22821,
    "Rank": 742534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22822,
    "Rank": 742546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22823,
    "Rank": 742581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22824,
    "Rank": 742598,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22825,
    "Rank": 742689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22826,
    "Rank": 742848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22827,
    "Rank": 742879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22828,
    "Rank": 742957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22829,
    "Rank": 743107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22830,
    "Rank": 743242,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22831,
    "Rank": 743412,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.C.B. MEDICAL COLLEGE, CUTTACK,Mangalabag, Cuttack, Odisha, 753007",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22832,
    "Rank": 743416,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, LATUR,Government Medical College, Latur, Behind Old Railway Station, Near Marwadi Rajasthan Vidyalaya, Lat, Maharashtra, 413512",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22833,
    "Rank": 743570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22834,
    "Rank": 743609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22835,
    "Rank": 743821,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22836,
    "Rank": 743938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22837,
    "Rank": 744287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22838,
    "Rank": 744367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22839,
    "Rank": 744505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22840,
    "Rank": 744592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22841,
    "Rank": 744864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22842,
    "Rank": 745012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22843,
    "Rank": 745139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22844,
    "Rank": 745334,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22845,
    "Rank": 745481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22846,
    "Rank": 745542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22847,
    "Rank": 745743,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22848,
    "Rank": 745857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22849,
    "Rank": 745870,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22850,
    "Rank": 746000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22851,
    "Rank": 746123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22852,
    "Rank": 746240,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22853,
    "Rank": 746371,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22854,
    "Rank": 746441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22855,
    "Rank": 746493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22856,
    "Rank": 746888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22857,
    "Rank": 746905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22858,
    "Rank": 746935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22859,
    "Rank": 746979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22860,
    "Rank": 747087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22861,
    "Rank": 747110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22862,
    "Rank": 747204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22863,
    "Rank": 747259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22864,
    "Rank": 747283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22865,
    "Rank": 747397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22866,
    "Rank": 747639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22867,
    "Rank": 747722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22868,
    "Rank": 747732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22869,
    "Rank": 747808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22870,
    "Rank": 747819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22871,
    "Rank": 748016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22872,
    "Rank": 748081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22873,
    "Rank": 748411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22874,
    "Rank": 748471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22875,
    "Rank": 748656,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22876,
    "Rank": 748808,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22877,
    "Rank": 749027,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22878,
    "Rank": 749044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22879,
    "Rank": 749090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22880,
    "Rank": 749250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22881,
    "Rank": 749343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22882,
    "Rank": 749440,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22883,
    "Rank": 749501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22884,
    "Rank": 749629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22885,
    "Rank": 749861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22886,
    "Rank": 749935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22887,
    "Rank": 749976,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22888,
    "Rank": 750005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22889,
    "Rank": 750166,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22890,
    "Rank": 750200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22891,
    "Rank": 750207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22892,
    "Rank": 750278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22893,
    "Rank": 750306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22894,
    "Rank": 750447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22895,
    "Rank": 750663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22896,
    "Rank": 750727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22897,
    "Rank": 751198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22898,
    "Rank": 751289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22899,
    "Rank": 751381,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHKM GMC, Nalhar, Mewat, Haryana,THE DIRECTOR, SHAHEED HASAN KHAN,MEWATI GOVT. MEDICAL COLLEGE, NALHAR, NUH - HARYANA - 122107,\nHaryana, 122107",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22900,
    "Rank": 751648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22901,
    "Rank": 751975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22902,
    "Rank": 752017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22903,
    "Rank": 752085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22904,
    "Rank": 752151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22905,
    "Rank": 752157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22906,
    "Rank": 752226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22907,
    "Rank": 752361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22908,
    "Rank": 752402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22909,
    "Rank": 752414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22910,
    "Rank": 752428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22911,
    "Rank": 752573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22912,
    "Rank": 752616,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22913,
    "Rank": 752676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22914,
    "Rank": 752749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22915,
    "Rank": 752770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22916,
    "Rank": 752816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22917,
    "Rank": 752949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22918,
    "Rank": 753090,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22919,
    "Rank": 753244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22920,
    "Rank": 753254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22921,
    "Rank": 753279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22922,
    "Rank": 753389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22923,
    "Rank": 753470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22924,
    "Rank": 753480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22925,
    "Rank": 753511,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Faridabad,NH 3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22926,
    "Rank": 753545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22927,
    "Rank": 753546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22928,
    "Rank": 753740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22929,
    "Rank": 753799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22930,
    "Rank": 753868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22931,
    "Rank": 753950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22932,
    "Rank": 754055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22933,
    "Rank": 754145,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22934,
    "Rank": 754208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22935,
    "Rank": 754761,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22936,
    "Rank": 754957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22937,
    "Rank": 755076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22938,
    "Rank": 755092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22939,
    "Rank": 755175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22940,
    "Rank": 755224,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22941,
    "Rank": 755411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22942,
    "Rank": 755543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22943,
    "Rank": 755753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22944,
    "Rank": 755868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22945,
    "Rank": 755917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22946,
    "Rank": 756225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22947,
    "Rank": 756245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22948,
    "Rank": 756263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22949,
    "Rank": 756340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22950,
    "Rank": 756347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22951,
    "Rank": 756692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22952,
    "Rank": 756758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22953,
    "Rank": 756927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Korba(C.G.),Govt. Medical College, Village Jhagrha, Balco-urga Road, City Korba C.G., Chhattisgarh, 495683",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22954,
    "Rank": 757036,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22955,
    "Rank": 757358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22956,
    "Rank": 757448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22957,
    "Rank": 757456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22958,
    "Rank": 757481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22959,
    "Rank": 757589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22960,
    "Rank": 757641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22961,
    "Rank": 757677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22962,
    "Rank": 758338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22963,
    "Rank": 758346,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Mission Medical College, Nerul,Sector-8, Nerul West Navi Mumbai, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22964,
    "Rank": 758361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22965,
    "Rank": 758469,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22966,
    "Rank": 758577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22967,
    "Rank": 758667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22968,
    "Rank": 758890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22969,
    "Rank": 759138,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22970,
    "Rank": 759558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22971,
    "Rank": 759615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22972,
    "Rank": 759622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22973,
    "Rank": 759633,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22974,
    "Rank": 759709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22975,
    "Rank": 759811,
    "Allotted Quota": "All India",
    "Allotted Institute": "UNS AUTONOMOUS STATE MEDICAL COLLEGES, Jaunpur,UNS AUTONOMOUS STATE MEDICAL COLLEGE SIDDIQUEPUR SHAHGANJ ROAD JAUNPUR UP, Uttar\nPradesh, 222003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22976,
    "Rank": 759895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22977,
    "Rank": 759974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22978,
    "Rank": 760072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22979,
    "Rank": 760153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22980,
    "Rank": 760155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22981,
    "Rank": 760428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22982,
    "Rank": 760435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22983,
    "Rank": 760455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22984,
    "Rank": 760591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22985,
    "Rank": 760658,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22986,
    "Rank": 760729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22987,
    "Rank": 760757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22988,
    "Rank": 760877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22989,
    "Rank": 761016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22990,
    "Rank": 761061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22991,
    "Rank": 761071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22992,
    "Rank": 761140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22993,
    "Rank": 761238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22994,
    "Rank": 761314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "KLE VK Inst. of Dental Scie. Belagavi,KLE V.K. Institute of Dental Sciences, J.N. Medical College Campus, Nehru Nagar,Belagavi-590010.Karn, Karnataka, 590010",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22995,
    "Rank": 761482,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22996,
    "Rank": 761654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22997,
    "Rank": 761738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22998,
    "Rank": 761840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 22999,
    "Rank": 761904,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,N-6, Cidco, Aurangabad,, Maharashtra, 431003",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23000,
    "Rank": 761966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23001,
    "Rank": 762017,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23002,
    "Rank": 762389,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23003,
    "Rank": 762464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23004,
    "Rank": 762733,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23005,
    "Rank": 762831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23006,
    "Rank": 762860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Sivgangai M. C. Sivagangai,MANAMADURAI MAIN ROAD, KEELA VANIYANKUDI, SIVAGANGAI, Tamil Nadu, 630561",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23007,
    "Rank": 762917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23008,
    "Rank": 763015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23009,
    "Rank": 763083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23010,
    "Rank": 763285,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23011,
    "Rank": 763419,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23012,
    "Rank": 763548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23013,
    "Rank": 763683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23014,
    "Rank": 763728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23015,
    "Rank": 763795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23016,
    "Rank": 763818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23017,
    "Rank": 763886,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23018,
    "Rank": 763949,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23019,
    "Rank": 763985,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23020,
    "Rank": 764020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23021,
    "Rank": 764086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23022,
    "Rank": 764106,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23023,
    "Rank": 764147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23024,
    "Rank": 764207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,BADRINATH MARG, SRIKOT, SRINAGAR - PAURI GARHWAL, Uttarakhand, 246178",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23025,
    "Rank": 764258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23026,
    "Rank": 764471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23027,
    "Rank": 764550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23028,
    "Rank": 764555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23029,
    "Rank": 764580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23030,
    "Rank": 764584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23031,
    "Rank": 764757,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23032,
    "Rank": 764900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23033,
    "Rank": 764924,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23034,
    "Rank": 764936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23035,
    "Rank": 764981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23036,
    "Rank": 765061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23037,
    "Rank": 765189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23038,
    "Rank": 765975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23039,
    "Rank": 766238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23040,
    "Rank": 766441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23041,
    "Rank": 766494,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23042,
    "Rank": 766524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23043,
    "Rank": 766663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23044,
    "Rank": 766751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23045,
    "Rank": 766848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23046,
    "Rank": 767040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23047,
    "Rank": 767050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23048,
    "Rank": 767211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23049,
    "Rank": 767456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23050,
    "Rank": 767499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23051,
    "Rank": 767764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23052,
    "Rank": 767998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23053,
    "Rank": 768151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23054,
    "Rank": 768317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23055,
    "Rank": 768372,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23056,
    "Rank": 768595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23057,
    "Rank": 769098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23058,
    "Rank": 769293,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23059,
    "Rank": 769466,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23060,
    "Rank": 769724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23061,
    "Rank": 769730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23062,
    "Rank": 769827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23063,
    "Rank": 769833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23064,
    "Rank": 769871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23065,
    "Rank": 769897,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23066,
    "Rank": 769990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23067,
    "Rank": 770105,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23068,
    "Rank": 770200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23069,
    "Rank": 770577,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23070,
    "Rank": 770721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23071,
    "Rank": 771054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23072,
    "Rank": 771180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23073,
    "Rank": 771257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23074,
    "Rank": 771261,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23075,
    "Rank": 771412,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23076,
    "Rank": 771517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23077,
    "Rank": 771761,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23078,
    "Rank": 771919,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23079,
    "Rank": 772179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23080,
    "Rank": 772362,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23081,
    "Rank": 772515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Eluru,SURVEY NO 60, BEHIND OLD BUS STAND, ELURU, Andhra Pradesh, 534005",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23082,
    "Rank": 772558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23083,
    "Rank": 772567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23084,
    "Rank": 772779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23085,
    "Rank": 773710,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23086,
    "Rank": 773776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23087,
    "Rank": 773824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23088,
    "Rank": 773912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23089,
    "Rank": 774037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23090,
    "Rank": 774102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23091,
    "Rank": 774146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23092,
    "Rank": 774156,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23093,
    "Rank": 774210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23094,
    "Rank": 774536,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23095,
    "Rank": 774616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23096,
    "Rank": 774695,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23097,
    "Rank": 774701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23098,
    "Rank": 774793,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23099,
    "Rank": 774906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23100,
    "Rank": 774982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23101,
    "Rank": 775064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23102,
    "Rank": 775326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23103,
    "Rank": 775460,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23104,
    "Rank": 775476,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23105,
    "Rank": 775561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23106,
    "Rank": 775657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23107,
    "Rank": 775773,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23108,
    "Rank": 775776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23109,
    "Rank": 775803,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23110,
    "Rank": 775877,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23111,
    "Rank": 775975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23112,
    "Rank": 776031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23113,
    "Rank": 776038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23114,
    "Rank": 776058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23115,
    "Rank": 776401,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23116,
    "Rank": 776553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23117,
    "Rank": 776593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23118,
    "Rank": 776842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23119,
    "Rank": 776910,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23120,
    "Rank": 777079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23121,
    "Rank": 777089,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23122,
    "Rank": 777094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23123,
    "Rank": 777250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23124,
    "Rank": 777291,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23125,
    "Rank": 777298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23126,
    "Rank": 777299,
    "Allotted Quota": "All India",
    "Allotted Institute": "BANKURA SAMMILANI MED COLL,BANKURA,Principal, Bankura Sammilani Medical College, Lokepur, PO- Kenduadihi, Bankura, Pin- 722102, West Bengal, 722102",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23127,
    "Rank": 777385,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.P. SHAH MEDICAL COLLEGE, JAMNAGAR,PANDIT NEHRU MARG, JAMNAGAR 361008, Gujarat, 361008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23128,
    "Rank": 777484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23129,
    "Rank": 777570,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23130,
    "Rank": 777600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23131,
    "Rank": 777622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23132,
    "Rank": 777631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23133,
    "Rank": 777643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23134,
    "Rank": 777647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23135,
    "Rank": 777670,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23136,
    "Rank": 777752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23137,
    "Rank": 777876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23138,
    "Rank": 777884,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23139,
    "Rank": 777966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23140,
    "Rank": 778084,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23141,
    "Rank": 778122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23142,
    "Rank": 778283,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23143,
    "Rank": 778344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23144,
    "Rank": 778414,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23145,
    "Rank": 778435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23146,
    "Rank": 778467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23147,
    "Rank": 778539,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23148,
    "Rank": 778602,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23149,
    "Rank": 779535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23150,
    "Rank": 779774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23151,
    "Rank": 779830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23152,
    "Rank": 779865,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23153,
    "Rank": 779902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23154,
    "Rank": 780230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23155,
    "Rank": 780559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23156,
    "Rank": 780570,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23157,
    "Rank": 780574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23158,
    "Rank": 780575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23159,
    "Rank": 780693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23160,
    "Rank": 780701,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23161,
    "Rank": 780716,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23162,
    "Rank": 780731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23163,
    "Rank": 780784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23164,
    "Rank": 780786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23165,
    "Rank": 780794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23166,
    "Rank": 780816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23167,
    "Rank": 780824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23168,
    "Rank": 780826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lt. B R K Government Medical College, Jagdalpur,LATE BALIRAM KASHYAP MEMORIAL GOVT. MEDICAL COLLEGE, DIMRAPAL GEEDAM ROAD , JAGDALPUR\nDIST-BASTAR, Chhattisgarh, 494001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23169,
    "Rank": 780856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23170,
    "Rank": 780866,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23171,
    "Rank": 781048,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23172,
    "Rank": 781240,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23173,
    "Rank": 781339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23174,
    "Rank": 781397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23175,
    "Rank": 781416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23176,
    "Rank": 781469,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, RAJOURI,Government Medical College, Kheora, Mehra, Rajouri, Jammu and Kashmir., Jammu And Kashmir, 185131",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23177,
    "Rank": 781669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23178,
    "Rank": 781682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23179,
    "Rank": 781745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23180,
    "Rank": 781784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23181,
    "Rank": 781831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23182,
    "Rank": 782001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23183,
    "Rank": 782170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23184,
    "Rank": 782189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23185,
    "Rank": 782299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23186,
    "Rank": 782312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23187,
    "Rank": 782417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23188,
    "Rank": 782544,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23189,
    "Rank": 782547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23190,
    "Rank": 782592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23191,
    "Rank": 782664,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23192,
    "Rank": 782677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23193,
    "Rank": 782892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23194,
    "Rank": 783040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23195,
    "Rank": 783205,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23196,
    "Rank": 783217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23197,
    "Rank": 783295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23198,
    "Rank": 783321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23199,
    "Rank": 783405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23200,
    "Rank": 783417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23201,
    "Rank": 783439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23202,
    "Rank": 783791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23203,
    "Rank": 783980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23204,
    "Rank": 784078,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23205,
    "Rank": 784092,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23206,
    "Rank": 784266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23207,
    "Rank": 784278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23208,
    "Rank": 784291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23209,
    "Rank": 784314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23210,
    "Rank": 784374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23211,
    "Rank": 784414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23212,
    "Rank": 784425,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23213,
    "Rank": 784429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23214,
    "Rank": 784569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23215,
    "Rank": 784572,
    "Allotted Quota": "All India",
    "Allotted Institute": "SVIMS - Sri Padmavathi Medical College for Women, Tirupati,SVIMS, ALIPIRI ROAD, TIRUPATI, Andhra Pradesh, 517507 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23216,
    "Rank": 784643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23217,
    "Rank": 784708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23218,
    "Rank": 784795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23219,
    "Rank": 784811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23220,
    "Rank": 784883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23221,
    "Rank": 784902,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23222,
    "Rank": 785093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kanker, Chhattisgarh,Late Smt. Indira Gandhi Memorial Government Medical College, Kanker, Chhattisgarh, Chhattisgarh, 494334",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23223,
    "Rank": 785169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23224,
    "Rank": 785174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23225,
    "Rank": 785194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23226,
    "Rank": 785203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23227,
    "Rank": 785382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23228,
    "Rank": 785383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23229,
    "Rank": 785444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23230,
    "Rank": 785463,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23231,
    "Rank": 785596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23232,
    "Rank": 785609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23233,
    "Rank": 785672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23234,
    "Rank": 785769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23235,
    "Rank": 785802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23236,
    "Rank": 785986,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.S. MEDICAL COLLEGE, REWA,NEAR DHOBIYA TANKI, JAIL ROAD, REWA,\nMadhya Pradesh, 486001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23237,
    "Rank": 786089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23238,
    "Rank": 786273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23239,
    "Rank": 786290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23240,
    "Rank": 786380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23241,
    "Rank": 786509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23242,
    "Rank": 786606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23243,
    "Rank": 786685,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Manipal Tata Medical College,,Kadani Road, Baridih, Jamshedpur,\nEast Singbhum District, Jharkhand, 831017",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23244,
    "Rank": 786704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23245,
    "Rank": 787009,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23246,
    "Rank": 787538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23247,
    "Rank": 787556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23248,
    "Rank": 787772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23249,
    "Rank": 788011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23250,
    "Rank": 788035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23251,
    "Rank": 788308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23252,
    "Rank": 788373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23253,
    "Rank": 788562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23254,
    "Rank": 788757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23255,
    "Rank": 788791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23256,
    "Rank": 788809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23257,
    "Rank": 788949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23258,
    "Rank": 789038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23259,
    "Rank": 789057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23260,
    "Rank": 789073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23261,
    "Rank": 789127,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23262,
    "Rank": 789260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23263,
    "Rank": 789467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23264,
    "Rank": 789519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23265,
    "Rank": 789776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23266,
    "Rank": 789796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23267,
    "Rank": 789960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23268,
    "Rank": 790046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23269,
    "Rank": 790103,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23270,
    "Rank": 790268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23271,
    "Rank": 790305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23272,
    "Rank": 790309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23273,
    "Rank": 790433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23274,
    "Rank": 790449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23275,
    "Rank": 790461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23276,
    "Rank": 791001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23277,
    "Rank": 791168,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23278,
    "Rank": 791371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23279,
    "Rank": 791593,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23280,
    "Rank": 791669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23281,
    "Rank": 791681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23282,
    "Rank": 791773,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23283,
    "Rank": 791851,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23284,
    "Rank": 791908,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23285,
    "Rank": 791941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23286,
    "Rank": 792005,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23287,
    "Rank": 792106,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23288,
    "Rank": 792184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23289,
    "Rank": 792320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23290,
    "Rank": 792327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23291,
    "Rank": 792371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23292,
    "Rank": 792508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23293,
    "Rank": 792788,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23294,
    "Rank": 792880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23295,
    "Rank": 793018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ramagundam,SURVEY NUMBER 92 95 96 MALKAPUR VILLAGE RAMAGUNDAM, Telangana, 505209",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23296,
    "Rank": 793019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23297,
    "Rank": 793079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23298,
    "Rank": 793081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23299,
    "Rank": 793196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23300,
    "Rank": 793568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23301,
    "Rank": 793612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23302,
    "Rank": 793664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23303,
    "Rank": 793776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23304,
    "Rank": 793804,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23305,
    "Rank": 793900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23306,
    "Rank": 793928,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23307,
    "Rank": 794109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23308,
    "Rank": 794466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23309,
    "Rank": 794482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23310,
    "Rank": 794746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23311,
    "Rank": 794839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23312,
    "Rank": 794979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23313,
    "Rank": 795005,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23314,
    "Rank": 795038,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23315,
    "Rank": 795091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23316,
    "Rank": 795117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23317,
    "Rank": 795119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23318,
    "Rank": 795126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23319,
    "Rank": 795172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23320,
    "Rank": 795173,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23321,
    "Rank": 795188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23322,
    "Rank": 795389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23323,
    "Rank": 795403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23324,
    "Rank": 795605,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23325,
    "Rank": 795652,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23326,
    "Rank": 795667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23327,
    "Rank": 795685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23328,
    "Rank": 795686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23329,
    "Rank": 795689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23330,
    "Rank": 795863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23331,
    "Rank": 795885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23332,
    "Rank": 795940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23333,
    "Rank": 796131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23334,
    "Rank": 796180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23335,
    "Rank": 796272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23336,
    "Rank": 796359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23337,
    "Rank": 796492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23338,
    "Rank": 796903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23339,
    "Rank": 796951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23340,
    "Rank": 797096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23341,
    "Rank": 797125,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23342,
    "Rank": 797267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23343,
    "Rank": 797318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23344,
    "Rank": 797385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23345,
    "Rank": 797542,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23346,
    "Rank": 797582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23347,
    "Rank": 797614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23348,
    "Rank": 797638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23349,
    "Rank": 797730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23350,
    "Rank": 797976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23351,
    "Rank": 798031,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23352,
    "Rank": 798314,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23353,
    "Rank": 798407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23354,
    "Rank": 798432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23355,
    "Rank": 798651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23356,
    "Rank": 798719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23357,
    "Rank": 798839,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23358,
    "Rank": 799014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23359,
    "Rank": 799075,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23360,
    "Rank": 799101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23361,
    "Rank": 799111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23362,
    "Rank": 799132,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23363,
    "Rank": 799212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23364,
    "Rank": 799281,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23365,
    "Rank": 799636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23366,
    "Rank": 799675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23367,
    "Rank": 799709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23368,
    "Rank": 799742,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23369,
    "Rank": 799798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23370,
    "Rank": 799922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23371,
    "Rank": 799963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23372,
    "Rank": 800054,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23373,
    "Rank": 800346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23374,
    "Rank": 800350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23375,
    "Rank": 800469,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23376,
    "Rank": 800574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23377,
    "Rank": 800596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23378,
    "Rank": 800620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23379,
    "Rank": 800732,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23380,
    "Rank": 801172,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23381,
    "Rank": 801319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23382,
    "Rank": 801386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23383,
    "Rank": 801938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23384,
    "Rank": 801959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23385,
    "Rank": 802024,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23386,
    "Rank": 802043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23387,
    "Rank": 802203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23388,
    "Rank": 802244,
    "Allotted Quota": "All India",
    "Allotted Institute": "THOOTHUKUDI MEDICAL COLLEGE,THOOTHUKUDI,KAMARAJ NAGAR, 3rd MILE,\nTHOOTHUKUDI, Tamil Nadu, 628008",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23389,
    "Rank": 802339,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23390,
    "Rank": 802348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23391,
    "Rank": 802352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23392,
    "Rank": 802361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23393,
    "Rank": 802394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23394,
    "Rank": 802431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23395,
    "Rank": 802467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23396,
    "Rank": 802511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23397,
    "Rank": 802667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23398,
    "Rank": 802809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23399,
    "Rank": 802906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23400,
    "Rank": 803042,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23401,
    "Rank": 803044,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23402,
    "Rank": 803085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23403,
    "Rank": 803201,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23404,
    "Rank": 803365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23405,
    "Rank": 803544,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23406,
    "Rank": 803551,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23407,
    "Rank": 803563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23408,
    "Rank": 803612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23409,
    "Rank": 803755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23410,
    "Rank": 803802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23411,
    "Rank": 803844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23412,
    "Rank": 803927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23413,
    "Rank": 804073,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23414,
    "Rank": 804190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23415,
    "Rank": 804345,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23416,
    "Rank": 804477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23417,
    "Rank": 804638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23418,
    "Rank": 804835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23419,
    "Rank": 804973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23420,
    "Rank": 804998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23421,
    "Rank": 805162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23422,
    "Rank": 805202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23423,
    "Rank": 805591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23424,
    "Rank": 805627,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23425,
    "Rank": 805660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23426,
    "Rank": 805735,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23427,
    "Rank": 805765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23428,
    "Rank": 805931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23429,
    "Rank": 805952,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23430,
    "Rank": 805960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23431,
    "Rank": 805974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23432,
    "Rank": 805982,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23433,
    "Rank": 806097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23434,
    "Rank": 806236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23435,
    "Rank": 806384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23436,
    "Rank": 806659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23437,
    "Rank": 806661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23438,
    "Rank": 806720,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23439,
    "Rank": 806871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23440,
    "Rank": 807164,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23441,
    "Rank": 807261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23442,
    "Rank": 807392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23443,
    "Rank": 807744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23444,
    "Rank": 807907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23445,
    "Rank": 807990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23446,
    "Rank": 808048,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23447,
    "Rank": 808079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23448,
    "Rank": 808264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23449,
    "Rank": 808317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23450,
    "Rank": 808548,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23451,
    "Rank": 808597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23452,
    "Rank": 808732,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23453,
    "Rank": 808784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23454,
    "Rank": 809348,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23455,
    "Rank": 809352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23456,
    "Rank": 809471,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23457,
    "Rank": 809709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23458,
    "Rank": 809817,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23459,
    "Rank": 809897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23460,
    "Rank": 809933,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23461,
    "Rank": 810107,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23462,
    "Rank": 810138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23463,
    "Rank": 810177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23464,
    "Rank": 810254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23465,
    "Rank": 810266,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23466,
    "Rank": 810307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23467,
    "Rank": 810310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23468,
    "Rank": 810331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23469,
    "Rank": 810385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23470,
    "Rank": 810440,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23471,
    "Rank": 810600,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23472,
    "Rank": 810622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23473,
    "Rank": 810653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23474,
    "Rank": 810768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23475,
    "Rank": 811004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23476,
    "Rank": 811039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23477,
    "Rank": 811053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23478,
    "Rank": 811082,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23479,
    "Rank": 811418,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23480,
    "Rank": 811444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23481,
    "Rank": 811600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23482,
    "Rank": 811601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23483,
    "Rank": 811680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23484,
    "Rank": 811819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23485,
    "Rank": 811988,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23486,
    "Rank": 812024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23487,
    "Rank": 812109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23488,
    "Rank": 812174,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23489,
    "Rank": 812259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23490,
    "Rank": 812336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23491,
    "Rank": 812483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23492,
    "Rank": 812789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23493,
    "Rank": 812893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23494,
    "Rank": 813123,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23495,
    "Rank": 813168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23496,
    "Rank": 813178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23497,
    "Rank": 813214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23498,
    "Rank": 813273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23499,
    "Rank": 813327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23500,
    "Rank": 813483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23501,
    "Rank": 813511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23502,
    "Rank": 813537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23503,
    "Rank": 813560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23504,
    "Rank": 813617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23505,
    "Rank": 813721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23506,
    "Rank": 813759,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23507,
    "Rank": 813803,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23508,
    "Rank": 813999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23509,
    "Rank": 814094,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23510,
    "Rank": 814421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23511,
    "Rank": 814468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23512,
    "Rank": 814485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23513,
    "Rank": 814539,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23514,
    "Rank": 814551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23515,
    "Rank": 814700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23516,
    "Rank": 814798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23517,
    "Rank": 814939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23518,
    "Rank": 814962,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23519,
    "Rank": 814976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23520,
    "Rank": 815077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23521,
    "Rank": 815228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23522,
    "Rank": 815303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23523,
    "Rank": 815473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23524,
    "Rank": 815626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23525,
    "Rank": 815756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23526,
    "Rank": 815817,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23527,
    "Rank": 816114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23528,
    "Rank": 816262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23529,
    "Rank": 816556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23530,
    "Rank": 816672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23531,
    "Rank": 816863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23532,
    "Rank": 817114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23533,
    "Rank": 817684,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23534,
    "Rank": 817788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23535,
    "Rank": 817875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23536,
    "Rank": 818065,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23537,
    "Rank": 818108,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23538,
    "Rank": 818150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23539,
    "Rank": 818174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23540,
    "Rank": 818309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23541,
    "Rank": 818351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23542,
    "Rank": 818468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23543,
    "Rank": 818493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23544,
    "Rank": 818552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23545,
    "Rank": 818616,
    "Allotted Quota": "All India",
    "Allotted Institute": "BUNDELKHAND MEDICAL COLLEGE, SAGAR,Shivaji ward, tili Road, Sagar, Madhya Pradesh, 470001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23546,
    "Rank": 818626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23547,
    "Rank": 818685,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23548,
    "Rank": 818692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23549,
    "Rank": 818697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23550,
    "Rank": 818814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23551,
    "Rank": 819011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23552,
    "Rank": 819080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23553,
    "Rank": 819190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23554,
    "Rank": 819355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23555,
    "Rank": 819408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23556,
    "Rank": 819456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23557,
    "Rank": 819558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23558,
    "Rank": 819729,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23559,
    "Rank": 819872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23560,
    "Rank": 819951,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23561,
    "Rank": 820061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23562,
    "Rank": 820137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23563,
    "Rank": 820153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23564,
    "Rank": 820200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23565,
    "Rank": 820228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23566,
    "Rank": 820305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23567,
    "Rank": 820355,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23568,
    "Rank": 820582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23569,
    "Rank": 820704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23570,
    "Rank": 820708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23571,
    "Rank": 820816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23572,
    "Rank": 821140,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23573,
    "Rank": 821147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23574,
    "Rank": 821533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23575,
    "Rank": 821550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23576,
    "Rank": 821944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23577,
    "Rank": 822115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23578,
    "Rank": 822303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23579,
    "Rank": 822353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23580,
    "Rank": 822457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23581,
    "Rank": 822485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23582,
    "Rank": 822641,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23583,
    "Rank": 822654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23584,
    "Rank": 822744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23585,
    "Rank": 822826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23586,
    "Rank": 822854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23587,
    "Rank": 822897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Basti,RAMPUR TAHSIL SADAR BASTI BASTI, Uttar Pradesh, 272124",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23588,
    "Rank": 823724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23589,
    "Rank": 823892,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23590,
    "Rank": 823946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23591,
    "Rank": 823972,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23592,
    "Rank": 824070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23593,
    "Rank": 824077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23594,
    "Rank": 824090,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23595,
    "Rank": 824546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23596,
    "Rank": 824549,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23597,
    "Rank": 824563,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS Bathinda ,Jodhpur Romana near Giani Zail Singh College Mandi Dabwali Road Bathinda, Punjab, 151001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23598,
    "Rank": 824567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23599,
    "Rank": 824646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23600,
    "Rank": 824735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23601,
    "Rank": 824929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23602,
    "Rank": 825168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hos. and Res. Inst., Kancheepuram,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23603,
    "Rank": 825371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23604,
    "Rank": 825441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23605,
    "Rank": 825449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23606,
    "Rank": 825486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23607,
    "Rank": 825622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23608,
    "Rank": 825638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23609,
    "Rank": 825837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23610,
    "Rank": 825848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23611,
    "Rank": 825852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23612,
    "Rank": 825943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23613,
    "Rank": 825988,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23614,
    "Rank": 826115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23615,
    "Rank": 826135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23616,
    "Rank": 826567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23617,
    "Rank": 826620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23618,
    "Rank": 826708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23619,
    "Rank": 826831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23620,
    "Rank": 826937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23621,
    "Rank": 827091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23622,
    "Rank": 827302,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23623,
    "Rank": 827412,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23624,
    "Rank": 827434,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23625,
    "Rank": 827768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23626,
    "Rank": 828123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23627,
    "Rank": 828224,
    "Allotted Quota": "All India",
    "Allotted Institute": "MG Inst. of Medical Sciences, Sevagram Wardha,VILLAGE AND PO SEVAGRAM DISTRICT WARDHA MAHARASHTRA 442102, Maharashtra, 442102",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23628,
    "Rank": 828318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23629,
    "Rank": 828400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23630,
    "Rank": 828466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23631,
    "Rank": 828785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23632,
    "Rank": 828906,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23633,
    "Rank": 828925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23634,
    "Rank": 829224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23635,
    "Rank": 829336,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23636,
    "Rank": 829429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23637,
    "Rank": 829668,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23638,
    "Rank": 829915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23639,
    "Rank": 830186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23640,
    "Rank": 830265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23641,
    "Rank": 830681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23642,
    "Rank": 830707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23643,
    "Rank": 830917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23644,
    "Rank": 831031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23645,
    "Rank": 831218,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23646,
    "Rank": 831272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23647,
    "Rank": 831645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23648,
    "Rank": 831868,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23649,
    "Rank": 831980,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23650,
    "Rank": 831998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23651,
    "Rank": 832019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23652,
    "Rank": 832175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23653,
    "Rank": 832205,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23654,
    "Rank": 832318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23655,
    "Rank": 832325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23656,
    "Rank": 832356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23657,
    "Rank": 832545,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23658,
    "Rank": 832547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23659,
    "Rank": 832888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23660,
    "Rank": 832983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23661,
    "Rank": 833004,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23662,
    "Rank": 833018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23663,
    "Rank": 833184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23664,
    "Rank": 833453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23665,
    "Rank": 833470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23666,
    "Rank": 833533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23667,
    "Rank": 833639,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23668,
    "Rank": 833659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23669,
    "Rank": 833934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23670,
    "Rank": 834106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23671,
    "Rank": 834179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23672,
    "Rank": 834307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23673,
    "Rank": 834537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23674,
    "Rank": 834565,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23675,
    "Rank": 834709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23676,
    "Rank": 835103,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23677,
    "Rank": 835489,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23678,
    "Rank": 835496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23679,
    "Rank": 835580,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23680,
    "Rank": 835721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23681,
    "Rank": 835730,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23682,
    "Rank": 835824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata shrimati devendra kumari singhdeo government medical college, ambikapur,Surguja,Kanyaparisar Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23683,
    "Rank": 835845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23684,
    "Rank": 835898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College, Barpeta,Jania Road, Jotigaon, Barpeta, Assam, Assam, 781301",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23685,
    "Rank": 835997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23686,
    "Rank": 836006,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23687,
    "Rank": 836015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23688,
    "Rank": 836161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23689,
    "Rank": 836381,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23690,
    "Rank": 836496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23691,
    "Rank": 836634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23692,
    "Rank": 836691,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23693,
    "Rank": 836770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23694,
    "Rank": 836933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23695,
    "Rank": 837083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23696,
    "Rank": 837185,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23697,
    "Rank": 837257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23698,
    "Rank": 837521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23699,
    "Rank": 837635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23700,
    "Rank": 837712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23701,
    "Rank": 837791,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23702,
    "Rank": 837810,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23703,
    "Rank": 837812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23704,
    "Rank": 837873,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23705,
    "Rank": 837917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23706,
    "Rank": 837943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23707,
    "Rank": 837947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23708,
    "Rank": 838020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23709,
    "Rank": 838111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23710,
    "Rank": 838389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23711,
    "Rank": 838713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23712,
    "Rank": 838750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23713,
    "Rank": 838754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23714,
    "Rank": 838992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23715,
    "Rank": 839117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23716,
    "Rank": 839137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23717,
    "Rank": 839140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23718,
    "Rank": 839170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23719,
    "Rank": 839244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23720,
    "Rank": 839269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23721,
    "Rank": 839277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23722,
    "Rank": 839360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23723,
    "Rank": 839394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23724,
    "Rank": 839408,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23725,
    "Rank": 839551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23726,
    "Rank": 839603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23727,
    "Rank": 839611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23728,
    "Rank": 839644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23729,
    "Rank": 839683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23730,
    "Rank": 839931,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23731,
    "Rank": 839944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23732,
    "Rank": 840122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23733,
    "Rank": 840497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23734,
    "Rank": 840571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23735,
    "Rank": 840677,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23736,
    "Rank": 840945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23737,
    "Rank": 841106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23738,
    "Rank": 841202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23739,
    "Rank": 841255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23740,
    "Rank": 841432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23741,
    "Rank": 841462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23742,
    "Rank": 841512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23743,
    "Rank": 841554,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23744,
    "Rank": 841836,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23745,
    "Rank": 841851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23746,
    "Rank": 841863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23747,
    "Rank": 842033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23748,
    "Rank": 842044,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23749,
    "Rank": 842134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23750,
    "Rank": 842164,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23751,
    "Rank": 842356,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23752,
    "Rank": 842460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23753,
    "Rank": 842626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23754,
    "Rank": 842812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23755,
    "Rank": 842896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23756,
    "Rank": 842979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23757,
    "Rank": 842985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23758,
    "Rank": 843002,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK  MUNICIPAL M C,MUMBAI,Dr. Babasaheb Ambedkar Road, Sion, Mumbai, Maharashtra, 400022",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23759,
    "Rank": 843057,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23760,
    "Rank": 843070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23761,
    "Rank": 843122,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23762,
    "Rank": 843341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23763,
    "Rank": 843417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23764,
    "Rank": 843421,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23765,
    "Rank": 843535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23766,
    "Rank": 843856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23767,
    "Rank": 843966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23768,
    "Rank": 844417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23769,
    "Rank": 844611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23770,
    "Rank": 844639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23771,
    "Rank": 844657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23772,
    "Rank": 844782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23773,
    "Rank": 844844,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23774,
    "Rank": 844848,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23775,
    "Rank": 844968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23776,
    "Rank": 845052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23777,
    "Rank": 845243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23778,
    "Rank": 845327,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23779,
    "Rank": 845811,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23780,
    "Rank": 846000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23781,
    "Rank": 846485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23782,
    "Rank": 846522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23783,
    "Rank": 846556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23784,
    "Rank": 846614,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23785,
    "Rank": 846675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23786,
    "Rank": 846710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23787,
    "Rank": 846797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23788,
    "Rank": 846956,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23789,
    "Rank": 847088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23790,
    "Rank": 847352,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23791,
    "Rank": 847370,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23792,
    "Rank": 847394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23793,
    "Rank": 847483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23794,
    "Rank": 847486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23795,
    "Rank": 847506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23796,
    "Rank": 847801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23797,
    "Rank": 847934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23798,
    "Rank": 847946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23799,
    "Rank": 847970,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23800,
    "Rank": 848023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23801,
    "Rank": 848089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23802,
    "Rank": 848138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23803,
    "Rank": 848288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23804,
    "Rank": 848406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23805,
    "Rank": 848550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23806,
    "Rank": 848821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23807,
    "Rank": 848842,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TiRTH RURAL M.C,AMBAJOGAI,SWAMI RAMNAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI DIST BEED MAHARASHTRA PIN-431517, Maharashtra, 431517",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23808,
    "Rank": 848895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23809,
    "Rank": 849116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23810,
    "Rank": 849441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23811,
    "Rank": 849810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23812,
    "Rank": 850168,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23813,
    "Rank": 850176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23814,
    "Rank": 850202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23815,
    "Rank": 850261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23816,
    "Rank": 850453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23817,
    "Rank": 850461,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23818,
    "Rank": 850657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23819,
    "Rank": 850667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23820,
    "Rank": 850822,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23821,
    "Rank": 851096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23822,
    "Rank": 851120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23823,
    "Rank": 851137,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23824,
    "Rank": 851162,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23825,
    "Rank": 851509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23826,
    "Rank": 851577,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23827,
    "Rank": 851601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23828,
    "Rank": 851638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23829,
    "Rank": 851816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23830,
    "Rank": 851913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Deben Mahata Government Medical College & Hospital,Vill  Hatuara PO  Vivekananda Nagar  PS  Purulia Muffasil Dist  Purulia  Pin 723147, West Bengal, 723147",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23831,
    "Rank": 852072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23832,
    "Rank": 852178,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23833,
    "Rank": 852180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23834,
    "Rank": 852464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23835,
    "Rank": 852471,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23836,
    "Rank": 852670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23837,
    "Rank": 852705,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23838,
    "Rank": 852719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23839,
    "Rank": 852726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23840,
    "Rank": 852840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23841,
    "Rank": 853027,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23842,
    "Rank": 853194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23843,
    "Rank": 853504,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23844,
    "Rank": 853515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23845,
    "Rank": 853567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23846,
    "Rank": 853632,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23847,
    "Rank": 853694,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23848,
    "Rank": 853762,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23849,
    "Rank": 853944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23850,
    "Rank": 854069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23851,
    "Rank": 854099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Udhampur,Dhar Road near Sallain Tallab opp Head Post Office Udhampur, Jammu And Kashmir, 182101",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23852,
    "Rank": 854236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23853,
    "Rank": 854300,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23854,
    "Rank": 854727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23855,
    "Rank": 854874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23856,
    "Rank": 854915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23857,
    "Rank": 855008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23858,
    "Rank": 855036,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23859,
    "Rank": 855113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23860,
    "Rank": 855233,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23861,
    "Rank": 855378,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23862,
    "Rank": 855460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23863,
    "Rank": 855534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23864,
    "Rank": 855574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23865,
    "Rank": 855612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23866,
    "Rank": 855653,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23867,
    "Rank": 855927,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23868,
    "Rank": 855948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23869,
    "Rank": 856015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23870,
    "Rank": 856030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23871,
    "Rank": 856105,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23872,
    "Rank": 856377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23873,
    "Rank": 856432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23874,
    "Rank": 856524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23875,
    "Rank": 856547,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23876,
    "Rank": 856598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23877,
    "Rank": 856772,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23878,
    "Rank": 856937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23879,
    "Rank": 856951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23880,
    "Rank": 856958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23881,
    "Rank": 857525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23882,
    "Rank": 857604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23883,
    "Rank": 857708,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23884,
    "Rank": 857794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23885,
    "Rank": 858013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23886,
    "Rank": 858070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23887,
    "Rank": 858215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23888,
    "Rank": 858243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23889,
    "Rank": 858619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23890,
    "Rank": 858635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,GOVERNMENT MEDICAL COLLEGE, BAKSHI NAGAR, JAMMU 180001, Jammu And Kashmir, 180001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23891,
    "Rank": 858675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23892,
    "Rank": 858693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23893,
    "Rank": 858711,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23894,
    "Rank": 858726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23895,
    "Rank": 858866,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23896,
    "Rank": 858916,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23897,
    "Rank": 858974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23898,
    "Rank": 859140,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23899,
    "Rank": 859177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23900,
    "Rank": 859245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23901,
    "Rank": 859283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23902,
    "Rank": 859393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23903,
    "Rank": 859593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23904,
    "Rank": 859712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23905,
    "Rank": 859793,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23906,
    "Rank": 859923,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23907,
    "Rank": 860066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23908,
    "Rank": 860515,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23909,
    "Rank": 860560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23910,
    "Rank": 860622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23911,
    "Rank": 860751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23912,
    "Rank": 860883,
    "Allotted Quota": "All India",
    "Allotted Institute": "NORTH BENGAL MED.COLL,DARJEELING,Sushruta Nagar, Darjeeling., West Bengal, 734012",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23913,
    "Rank": 861338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23914,
    "Rank": 861564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23915,
    "Rank": 861686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23916,
    "Rank": 861693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23917,
    "Rank": 862230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23918,
    "Rank": 862412,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23919,
    "Rank": 862420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23920,
    "Rank": 862491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23921,
    "Rank": 862554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23922,
    "Rank": 862601,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "AIIMS, Patna,Phulwarisharif, Patna, Bihar-801507, Bihar, 801507",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23923,
    "Rank": 862704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23924,
    "Rank": 862731,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23925,
    "Rank": 862753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23926,
    "Rank": 862897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23927,
    "Rank": 862933,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23928,
    "Rank": 862940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "JSS Dental College, Mysuru,The Principal JSS Dental College and Hospital Medical Institutions Campus Bannimantap S S Nagar Mysu, Karnataka, 570015",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23929,
    "Rank": 862996,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23930,
    "Rank": 863726,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23931,
    "Rank": 863816,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23932,
    "Rank": 863926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23933,
    "Rank": 864069,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23934,
    "Rank": 864172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23935,
    "Rank": 864211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23936,
    "Rank": 864414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andaman and Nicobar Islands Institute of Medical S,Director ANIIMS, DHS Annexe Building, Atlanta Point, Port Blair-744104, Andaman And Nicobar Islands, 744104",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23937,
    "Rank": 864678,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23938,
    "Rank": 864974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23939,
    "Rank": 865053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23940,
    "Rank": 865435,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23941,
    "Rank": 866352,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23942,
    "Rank": 866663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23943,
    "Rank": 866919,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23944,
    "Rank": 867011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College, Tezpur,Tezpur Medical College and Hospital Bihaguri Tezpur 784010 Dist Sonitpur Assam, Assam, 784010",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23945,
    "Rank": 867078,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23946,
    "Rank": 867131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23947,
    "Rank": 867229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23948,
    "Rank": 867277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23949,
    "Rank": 867434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23950,
    "Rank": 867447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23951,
    "Rank": 867475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23952,
    "Rank": 867486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23953,
    "Rank": 867607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23954,
    "Rank": 867645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23955,
    "Rank": 867681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23956,
    "Rank": 867799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23957,
    "Rank": 868054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23958,
    "Rank": 868280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23959,
    "Rank": 868391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23960,
    "Rank": 868479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23961,
    "Rank": 868507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23962,
    "Rank": 868920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23963,
    "Rank": 868926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23964,
    "Rank": 868986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23965,
    "Rank": 869030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23966,
    "Rank": 869216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23967,
    "Rank": 869448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23968,
    "Rank": 869746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23969,
    "Rank": 869781,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23970,
    "Rank": 869920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23971,
    "Rank": 869938,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawahar Lal Nehru Medical College, Belagavi,J.N. Medical College Campus, Nehru Nagar,\nBelagavi-590010. Karnataka, India., Karnataka, 590010",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23972,
    "Rank": 869944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23973,
    "Rank": 870305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23974,
    "Rank": 870311,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,The Principal JSS Medical College and Hospital Medical Institutions Campus S S Nagar Mysuru, Karnataka, 570015",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23975,
    "Rank": 870494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Vikarabad ,ANANTHAGIRI HILLS VIKARABAD VIKARABAD DISTRICT TELANGANA 501101, Telangana, 501101",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23976,
    "Rank": 870670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23977,
    "Rank": 870904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23978,
    "Rank": 870926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23979,
    "Rank": 870935,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23980,
    "Rank": 870999,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23981,
    "Rank": 871332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23982,
    "Rank": 871422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23983,
    "Rank": 871427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23984,
    "Rank": 871552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23985,
    "Rank": 871786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Rural Dental College, Loni,At - Loni  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shirdi), Maharashtra, 413736",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23986,
    "Rank": 871943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23987,
    "Rank": 872111,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23988,
    "Rank": 872298,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23989,
    "Rank": 872319,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23990,
    "Rank": 872338,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23991,
    "Rank": 872373,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23992,
    "Rank": 872391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23993,
    "Rank": 872505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23994,
    "Rank": 872584,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23995,
    "Rank": 872716,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23996,
    "Rank": 872723,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23997,
    "Rank": 872750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23998,
    "Rank": 872915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 23999,
    "Rank": 873122,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24000,
    "Rank": 873196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24001,
    "Rank": 873208,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24002,
    "Rank": 873277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24003,
    "Rank": 873325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24004,
    "Rank": 873459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24005,
    "Rank": 873477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Nalbari Medical College & Hospital Dakhingaon, Nalbari,PO Dakhingaon Dist Nalbari\nPS Ghograpar, Assam, 781350",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24006,
    "Rank": 873601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24007,
    "Rank": 873647,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24008,
    "Rank": 873792,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24009,
    "Rank": 874195,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24010,
    "Rank": 874207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24011,
    "Rank": 874208,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24012,
    "Rank": 874257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24013,
    "Rank": 874296,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24014,
    "Rank": 874433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24015,
    "Rank": 874453,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24016,
    "Rank": 874519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24017,
    "Rank": 874540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24018,
    "Rank": 874678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Dental and Hospt., Chennai,No. 1 Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24019,
    "Rank": 874781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24020,
    "Rank": 874826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24021,
    "Rank": 874834,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24022,
    "Rank": 874883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "GITAM Institue of Med. Sce. and Res., Visakhapatnam,Gandhi Institute of Technology and Management (GITAM), Rushikonda, Visakhapatnam, Andhra Pradesh, 530045",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24023,
    "Rank": 874885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24024,
    "Rank": 874891,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24025,
    "Rank": 875083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24026,
    "Rank": 875217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24027,
    "Rank": 875223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24028,
    "Rank": 875353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24029,
    "Rank": 875372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24030,
    "Rank": 875489,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24031,
    "Rank": 875603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24032,
    "Rank": 875832,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24033,
    "Rank": 875877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24034,
    "Rank": 875903,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24035,
    "Rank": 876047,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P. VISWANATHAM Govt Medical College,TIRUCHIRAPALLI,PERIYAMILAGUPARAI,\nTIRUCHIRAPALLI-620001, Tamil Nadu, 620001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24036,
    "Rank": 876172,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24037,
    "Rank": 876358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24038,
    "Rank": 876859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24039,
    "Rank": 876955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24040,
    "Rank": 877058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24041,
    "Rank": 877175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24042,
    "Rank": 877547,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24043,
    "Rank": 877686,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24044,
    "Rank": 877739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24045,
    "Rank": 877925,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24046,
    "Rank": 878113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Pilibhit, Uttar Pradesh,SH26 Bithaura Kalan Puranpur Road Pilibhit, Uttar Pradesh, 262001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24047,
    "Rank": 878161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24048,
    "Rank": 878262,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24049,
    "Rank": 878482,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24050,
    "Rank": 878516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24051,
    "Rank": 878604,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24052,
    "Rank": 878708,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24053,
    "Rank": 878754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24054,
    "Rank": 878860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24055,
    "Rank": 878875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24056,
    "Rank": 878942,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24057,
    "Rank": 879106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24058,
    "Rank": 879284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24059,
    "Rank": 879346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24060,
    "Rank": 879405,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24061,
    "Rank": 879446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24062,
    "Rank": 879825,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24063,
    "Rank": 879838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24064,
    "Rank": 879850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24065,
    "Rank": 879868,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24066,
    "Rank": 880054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24067,
    "Rank": 880131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagapattinam,NO.1,MANALMEDU, ORATHUR VILLAGE, NAGAPATTINAM TK, NAGAPATTINAM DISTRICT, Tamil Nadu, 611108",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24068,
    "Rank": 880177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24069,
    "Rank": 880183,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24070,
    "Rank": 880291,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24071,
    "Rank": 880444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24072,
    "Rank": 880497,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24073,
    "Rank": 880702,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24074,
    "Rank": 880835,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24075,
    "Rank": 881217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24076,
    "Rank": 881268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24077,
    "Rank": 881408,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24078,
    "Rank": 881447,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24079,
    "Rank": 881636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24080,
    "Rank": 881770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24081,
    "Rank": 882101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24082,
    "Rank": 882288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24083,
    "Rank": 882387,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24084,
    "Rank": 882597,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL, SATARA,GOVERNMENT MEDICAL COLLEGE AND GENERAL HOSPITAL SATARA DISTRICT CIVIL HOSPITAL CAMPUS SADAR BAZAR CA, Maharashtra, 415001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24085,
    "Rank": 882612,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24086,
    "Rank": 882643,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24087,
    "Rank": 882670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24088,
    "Rank": 882766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24089,
    "Rank": 882914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24090,
    "Rank": 882932,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24091,
    "Rank": 883186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24092,
    "Rank": 883250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Dental College, Chennai,SRM DENTAL COLLEGE, BHARATHI SALAI, RAMAPURAM, CHENNAI - 89, Tamil Nadu, 600089",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24093,
    "Rank": 883583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24094,
    "Rank": 883816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24095,
    "Rank": 883845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24096,
    "Rank": 883927,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24097,
    "Rank": 883930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24098,
    "Rank": 883950,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24099,
    "Rank": 884041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24100,
    "Rank": 884102,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24101,
    "Rank": 884402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24102,
    "Rank": 884564,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24103,
    "Rank": 884571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24104,
    "Rank": 884699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24105,
    "Rank": 884745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24106,
    "Rank": 884795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24107,
    "Rank": 884917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24108,
    "Rank": 884980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24109,
    "Rank": 884989,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24110,
    "Rank": 885120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24111,
    "Rank": 885146,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24112,
    "Rank": 885240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical college Shivpuri,Near Katha Mill, Gwalior Bypass Highway, Shivpuri, Madhya Pradesh, 473638",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24113,
    "Rank": 885349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24114,
    "Rank": 885397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24115,
    "Rank": 885428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24116,
    "Rank": 885567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24117,
    "Rank": 886313,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24118,
    "Rank": 886683,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24119,
    "Rank": 886838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24120,
    "Rank": 886840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24121,
    "Rank": 886879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24122,
    "Rank": 886983,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24123,
    "Rank": 887069,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24124,
    "Rank": 887070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24125,
    "Rank": 887100,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24126,
    "Rank": 887243,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24127,
    "Rank": 887739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24128,
    "Rank": 887806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24129,
    "Rank": 887807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24130,
    "Rank": 888109,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24131,
    "Rank": 888162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24132,
    "Rank": 888208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24133,
    "Rank": 888733,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE,CIVIL AERODROME POST, AVINASHI ROAD, PEELAMEDU, COIMBATORE, Tamil Nadu, 641014",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24134,
    "Rank": 888890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24135,
    "Rank": 888918,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24136,
    "Rank": 888979,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24137,
    "Rank": 889038,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24138,
    "Rank": 889163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24139,
    "Rank": 889635,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24140,
    "Rank": 889704,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24141,
    "Rank": 889847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24142,
    "Rank": 890045,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24143,
    "Rank": 890064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24144,
    "Rank": 890110,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24145,
    "Rank": 890160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24146,
    "Rank": 890443,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24147,
    "Rank": 890554,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24148,
    "Rank": 890654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24149,
    "Rank": 890704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24150,
    "Rank": 890783,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24151,
    "Rank": 891189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24152,
    "Rank": 891307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24153,
    "Rank": 891515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24154,
    "Rank": 891719,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24155,
    "Rank": 891754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24156,
    "Rank": 892276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24157,
    "Rank": 892491,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24158,
    "Rank": 892733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24159,
    "Rank": 892776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24160,
    "Rank": 892905,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24161,
    "Rank": 892997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24162,
    "Rank": 893069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Siddipet,Survey No 54, Ensanpalli Village, Siddipet Mandal, Siddipet, Telangana, 502114",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24163,
    "Rank": 893753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24164,
    "Rank": 893922,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24165,
    "Rank": 893941,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24166,
    "Rank": 894012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24167,
    "Rank": 894031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24168,
    "Rank": 894391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24169,
    "Rank": 894559,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHIKKABALLAPURA INSTITUTE OF MEDICAL SCIENCES, KARNATAKA,AROORU VILLAGE,PERESANDRA POST, CHIKKABALLAPUR KARANATAKA, Karnataka, 562104",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24170,
    "Rank": 894577,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24171,
    "Rank": 894648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24172,
    "Rank": 894927,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24173,
    "Rank": 894980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24174,
    "Rank": 895101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24175,
    "Rank": 895552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24176,
    "Rank": 895575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24177,
    "Rank": 896182,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24178,
    "Rank": 896272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24179,
    "Rank": 896347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24180,
    "Rank": 896498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24181,
    "Rank": 896503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24182,
    "Rank": 896607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24183,
    "Rank": 896751,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24184,
    "Rank": 896807,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24185,
    "Rank": 897229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24186,
    "Rank": 897326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24187,
    "Rank": 897375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24188,
    "Rank": 897473,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24189,
    "Rank": 897614,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24190,
    "Rank": 897680,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24191,
    "Rank": 898058,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24192,
    "Rank": 898301,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24193,
    "Rank": 898545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24194,
    "Rank": 898561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24195,
    "Rank": 898600,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24196,
    "Rank": 898658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24197,
    "Rank": 898672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24198,
    "Rank": 898782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24199,
    "Rank": 898811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24200,
    "Rank": 898826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24201,
    "Rank": 898944,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24202,
    "Rank": 898957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24203,
    "Rank": 898968,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24204,
    "Rank": 899112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Enathur, Karaipettai Post, Kanchipuram, Tamil Nadu, 631552",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24205,
    "Rank": 899119,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24206,
    "Rank": 899120,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24207,
    "Rank": 899204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24208,
    "Rank": 899430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24209,
    "Rank": 899580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24210,
    "Rank": 899637,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24211,
    "Rank": 899789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24212,
    "Rank": 899818,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24213,
    "Rank": 900028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24214,
    "Rank": 900099,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24215,
    "Rank": 900514,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24216,
    "Rank": 900567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24217,
    "Rank": 900718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24218,
    "Rank": 901077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24219,
    "Rank": 901131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24220,
    "Rank": 901139,
    "Allotted Quota": "All India",
    "Allotted Institute": "MIDNAPORE MEDICAL COLLEGE, MIDNAPUR,5 Vidyasagar Road Midnapore 721101 Paschim Medinipur, West Bengal, 721101",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24221,
    "Rank": 901194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24222,
    "Rank": 901507,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INST. OF MEDICAL SCI., SHIMOGA,SAGAR ROAD, MCGANN HOSPITAL CAMPUS, SHIVAMOGGA, Karnataka, 577201",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24223,
    "Rank": 901593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24224,
    "Rank": 901985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24225,
    "Rank": 902069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24226,
    "Rank": 902140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24227,
    "Rank": 902183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24228,
    "Rank": 902204,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24229,
    "Rank": 902578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24230,
    "Rank": 902611,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24231,
    "Rank": 902797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajah Muthiah Medical College, Annamalai Universit,Government Medical College and Hospital, Cuddalore District, Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24232,
    "Rank": 902899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24233,
    "Rank": 902941,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Medical College and Hospital, Karaikal,KEEZHAKASAKUDIMEDU, KOTTUCHERRY(P.O),\nKARAIKAL - 609 609, U.T. OF PUDUCHERRY, Puducherry, 609609",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24234,
    "Rank": 903007,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24235,
    "Rank": 903011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24236,
    "Rank": 903149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24237,
    "Rank": 903268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24238,
    "Rank": 903591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24239,
    "Rank": 903746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24240,
    "Rank": 904101,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences & SUM Hospital, Campus II,At-Nakhara, Po-Phulnakhara, Bhubaneswar, Khordha, Odisha, 754001",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24241,
    "Rank": 904138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24242,
    "Rank": 904309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24243,
    "Rank": 904462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24244,
    "Rank": 904477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24245,
    "Rank": 904891,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24246,
    "Rank": 904937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24247,
    "Rank": 905212,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24248,
    "Rank": 905303,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24249,
    "Rank": 905397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24250,
    "Rank": 905864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24251,
    "Rank": 906170,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24252,
    "Rank": 906245,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24253,
    "Rank": 906498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24254,
    "Rank": 906597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24255,
    "Rank": 906958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24256,
    "Rank": 906997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24257,
    "Rank": 907008,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24258,
    "Rank": 907321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24259,
    "Rank": 907428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24260,
    "Rank": 907466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24261,
    "Rank": 907475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24262,
    "Rank": 907545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24263,
    "Rank": 907571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24264,
    "Rank": 907579,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24265,
    "Rank": 907589,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Navsari,Adarsh Nivasi Shala Campus, At- Khambhlav, Po- Sultanpur, Via- Abrama, Ta - Jalalpore, Dist - Navsar, Gujarat, 396406",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24266,
    "Rank": 907626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24267,
    "Rank": 907662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24268,
    "Rank": 907745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24269,
    "Rank": 907748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24270,
    "Rank": 907776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24271,
    "Rank": 907936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24272,
    "Rank": 907986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24273,
    "Rank": 908747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24274,
    "Rank": 908784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24275,
    "Rank": 908937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24276,
    "Rank": 909212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24277,
    "Rank": 909513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24278,
    "Rank": 909532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24279,
    "Rank": 910157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24280,
    "Rank": 910562,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24281,
    "Rank": 910644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24282,
    "Rank": 911133,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24283,
    "Rank": 911335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24284,
    "Rank": 911392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24285,
    "Rank": 911493,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24286,
    "Rank": 911526,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24287,
    "Rank": 911923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24288,
    "Rank": 911967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24289,
    "Rank": 911985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24290,
    "Rank": 912513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24291,
    "Rank": 912572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24292,
    "Rank": 912718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24293,
    "Rank": 912783,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24294,
    "Rank": 912860,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24295,
    "Rank": 913136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24296,
    "Rank": 913270,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24297,
    "Rank": 913394,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24298,
    "Rank": 913519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24299,
    "Rank": 913586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24300,
    "Rank": 913637,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24301,
    "Rank": 913703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24302,
    "Rank": 913742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24303,
    "Rank": 913749,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24304,
    "Rank": 913824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24305,
    "Rank": 913949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24306,
    "Rank": 914152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24307,
    "Rank": 914282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24308,
    "Rank": 914406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24309,
    "Rank": 914551,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24310,
    "Rank": 915046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24311,
    "Rank": 915134,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24312,
    "Rank": 915224,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24313,
    "Rank": 915338,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL INST OF MEDICAL SCI, IMPHAL,PO Lamphelpat, Imphal West District, Manipur, 795004",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24314,
    "Rank": 915516,
    "Allotted Quota": "All India",
    "Allotted Institute": "MRA MEDICAL COLLEGE AMBEDKAR NAGAR, UP,VILLAGE SADDARPUR POST SURAPUR DISTRICT AMBEDKAR NAGAR, Uttar Pradesh, 224227",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24315,
    "Rank": 915873,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24316,
    "Rank": 916133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24317,
    "Rank": 916225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24318,
    "Rank": 916325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24319,
    "Rank": 916434,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24320,
    "Rank": 916501,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24321,
    "Rank": 916571,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24322,
    "Rank": 917282,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24323,
    "Rank": 917447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24324,
    "Rank": 917529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24325,
    "Rank": 917615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24326,
    "Rank": 917769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24327,
    "Rank": 917883,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24328,
    "Rank": 918128,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24329,
    "Rank": 918155,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24330,
    "Rank": 918157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24331,
    "Rank": 918274,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24332,
    "Rank": 918428,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24333,
    "Rank": 918703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24334,
    "Rank": 918923,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24335,
    "Rank": 919175,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24336,
    "Rank": 919213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24337,
    "Rank": 919229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24338,
    "Rank": 919363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24339,
    "Rank": 919394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24340,
    "Rank": 919455,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24341,
    "Rank": 919465,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "INST.OF MED.SCIENCES, BHU,VARANASI,Institute of Medical Sciences, Banaras Hindu University, Uttar Pradesh, 211005",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24342,
    "Rank": 919524,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24343,
    "Rank": 919550,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC KARAULI,mandrayal road karauli, Rajasthan, 322241",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24344,
    "Rank": 919842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24345,
    "Rank": 919901,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24346,
    "Rank": 920257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24347,
    "Rank": 920271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24348,
    "Rank": 920290,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24349,
    "Rank": 920477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24350,
    "Rank": 920587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24351,
    "Rank": 920622,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24352,
    "Rank": 920663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24353,
    "Rank": 920677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24354,
    "Rank": 920774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24355,
    "Rank": 920791,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24356,
    "Rank": 920795,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24357,
    "Rank": 920844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24358,
    "Rank": 921001,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24359,
    "Rank": 921004,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24360,
    "Rank": 921078,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24361,
    "Rank": 921198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24362,
    "Rank": 921228,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24363,
    "Rank": 921424,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24364,
    "Rank": 921474,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24365,
    "Rank": 921478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24366,
    "Rank": 921615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24367,
    "Rank": 922246,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24368,
    "Rank": 922368,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24369,
    "Rank": 922391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24370,
    "Rank": 922470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24371,
    "Rank": 922689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24372,
    "Rank": 922768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24373,
    "Rank": 923265,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24374,
    "Rank": 923305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24375,
    "Rank": 923340,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24376,
    "Rank": 923494,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24377,
    "Rank": 923545,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24378,
    "Rank": 923653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24379,
    "Rank": 923748,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24380,
    "Rank": 924025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24381,
    "Rank": 924087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24382,
    "Rank": 924098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24383,
    "Rank": 924115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24384,
    "Rank": 924355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24385,
    "Rank": 924381,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24386,
    "Rank": 924411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24387,
    "Rank": 924416,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24388,
    "Rank": 924561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24389,
    "Rank": 925014,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24390,
    "Rank": 925292,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24391,
    "Rank": 925320,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24392,
    "Rank": 925368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24393,
    "Rank": 925396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24394,
    "Rank": 925411,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24395,
    "Rank": 925495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24396,
    "Rank": 925580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24397,
    "Rank": 925636,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24398,
    "Rank": 925739,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24399,
    "Rank": 925900,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24400,
    "Rank": 926026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24401,
    "Rank": 926112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24402,
    "Rank": 926196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24403,
    "Rank": 926220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24404,
    "Rank": 926463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24405,
    "Rank": 926533,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24406,
    "Rank": 926783,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24407,
    "Rank": 927266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24408,
    "Rank": 927356,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24409,
    "Rank": 927470,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K.S Hegde Medical Academy, Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada Dist,, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24410,
    "Rank": 927595,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24411,
    "Rank": 927707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24412,
    "Rank": 928016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24413,
    "Rank": 928039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24414,
    "Rank": 928043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24415,
    "Rank": 928219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24416,
    "Rank": 928261,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24417,
    "Rank": 928542,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24418,
    "Rank": 928584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24419,
    "Rank": 928735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24420,
    "Rank": 928992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24421,
    "Rank": 929053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24422,
    "Rank": 929057,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24423,
    "Rank": 929322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24424,
    "Rank": 929324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24425,
    "Rank": 929327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24426,
    "Rank": 929410,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24427,
    "Rank": 929576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24428,
    "Rank": 929644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24429,
    "Rank": 929658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24430,
    "Rank": 929926,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24431,
    "Rank": 929963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24432,
    "Rank": 930124,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24433,
    "Rank": 930177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24434,
    "Rank": 930257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24435,
    "Rank": 930280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24436,
    "Rank": 930290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24437,
    "Rank": 930299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24438,
    "Rank": 930335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24439,
    "Rank": 930787,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24440,
    "Rank": 930863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24441,
    "Rank": 931167,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24442,
    "Rank": 931210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24443,
    "Rank": 931213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24444,
    "Rank": 931645,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24445,
    "Rank": 931924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24446,
    "Rank": 931960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24447,
    "Rank": 931997,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24448,
    "Rank": 932086,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24449,
    "Rank": 932102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24450,
    "Rank": 932173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24451,
    "Rank": 932190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24452,
    "Rank": 932397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24453,
    "Rank": 932615,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24454,
    "Rank": 932709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24455,
    "Rank": 932756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24456,
    "Rank": 933061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24457,
    "Rank": 933104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24458,
    "Rank": 933112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24459,
    "Rank": 933225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24460,
    "Rank": 933229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24461,
    "Rank": 933860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24462,
    "Rank": 934207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24463,
    "Rank": 934371,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24464,
    "Rank": 935106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24465,
    "Rank": 935156,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24466,
    "Rank": 935358,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL, AJMER,NEAR PATEL STADIUM AJMER,\nRajasthan, 305001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24467,
    "Rank": 935618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24468,
    "Rank": 935659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24469,
    "Rank": 935719,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24470,
    "Rank": 936634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24471,
    "Rank": 936675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24472,
    "Rank": 936747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24473,
    "Rank": 936824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24474,
    "Rank": 936826,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24475,
    "Rank": 937212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24476,
    "Rank": 937300,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24477,
    "Rank": 937422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24478,
    "Rank": 937705,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24479,
    "Rank": 937733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24480,
    "Rank": 937752,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24481,
    "Rank": 938047,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24482,
    "Rank": 938394,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24483,
    "Rank": 938405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24484,
    "Rank": 938624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24485,
    "Rank": 938755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24486,
    "Rank": 938810,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24487,
    "Rank": 938824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24488,
    "Rank": 938831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24489,
    "Rank": 938977,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24490,
    "Rank": 939180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24491,
    "Rank": 939345,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24492,
    "Rank": 939397,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24493,
    "Rank": 939662,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24494,
    "Rank": 939672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24495,
    "Rank": 940027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24496,
    "Rank": 940400,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24497,
    "Rank": 940479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24498,
    "Rank": 940522,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24499,
    "Rank": 940792,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24500,
    "Rank": 940814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24501,
    "Rank": 940874,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24502,
    "Rank": 940888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24503,
    "Rank": 940912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24504,
    "Rank": 940915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24505,
    "Rank": 941021,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24506,
    "Rank": 941024,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24507,
    "Rank": 941034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24508,
    "Rank": 941190,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24509,
    "Rank": 941191,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Medical College,Dhankawadi, Pune-Satara Road, Pune, Maharashtra, 411043",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24510,
    "Rank": 941286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24511,
    "Rank": 941429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24512,
    "Rank": 941578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24513,
    "Rank": 941853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24514,
    "Rank": 941927,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24515,
    "Rank": 941981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24516,
    "Rank": 942169,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24517,
    "Rank": 942215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24518,
    "Rank": 942487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24519,
    "Rank": 942690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24520,
    "Rank": 942841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24521,
    "Rank": 943021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24522,
    "Rank": 943097,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24523,
    "Rank": 943288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24524,
    "Rank": 943394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24525,
    "Rank": 943643,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, SRIGANGANAGAR, RAJASTHAN,Government\nMedical college sriganganagar rajasthan, Rajasthan, 335001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24526,
    "Rank": 943971,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24527,
    "Rank": 944239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24528,
    "Rank": 944394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24529,
    "Rank": 944498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24530,
    "Rank": 944643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24531,
    "Rank": 945029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24532,
    "Rank": 945072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24533,
    "Rank": 945076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24534,
    "Rank": 945142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24535,
    "Rank": 945161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24536,
    "Rank": 945177,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24537,
    "Rank": 945199,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Navi Mumbai,Dr. D. Y. Patil Vidyanagar, Sector 7, Nerul, Navi Mumbai, Maharashtra, 400706",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24538,
    "Rank": 945311,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24539,
    "Rank": 945316,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24540,
    "Rank": 945744,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24541,
    "Rank": 945768,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24542,
    "Rank": 945775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24543,
    "Rank": 945778,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences, New Delhi,Dilshad Garden, Delhi (NCT), 110095",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24544,
    "Rank": 945927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24545,
    "Rank": 946010,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24546,
    "Rank": 946605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24547,
    "Rank": 946699,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24548,
    "Rank": 946881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24549,
    "Rank": 946988,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24550,
    "Rank": 946991,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24551,
    "Rank": 946995,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24552,
    "Rank": 947069,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24553,
    "Rank": 947243,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24554,
    "Rank": 947361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24555,
    "Rank": 947413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24556,
    "Rank": 947422,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24557,
    "Rank": 947561,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24558,
    "Rank": 947945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24559,
    "Rank": 948007,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24560,
    "Rank": 948020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24561,
    "Rank": 948188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24562,
    "Rank": 948189,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24563,
    "Rank": 948200,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24564,
    "Rank": 948213,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24565,
    "Rank": 948217,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24566,
    "Rank": 948231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24567,
    "Rank": 948368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24568,
    "Rank": 948535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24569,
    "Rank": 948873,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24570,
    "Rank": 949396,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24571,
    "Rank": 950161,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24572,
    "Rank": 950176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24573,
    "Rank": 950181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24574,
    "Rank": 950300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24575,
    "Rank": 950358,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24576,
    "Rank": 950511,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24577,
    "Rank": 950553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24578,
    "Rank": 950555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24579,
    "Rank": 950575,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24580,
    "Rank": 950785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24581,
    "Rank": 950794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24582,
    "Rank": 951155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24583,
    "Rank": 951242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24584,
    "Rank": 951312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24585,
    "Rank": 951443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24586,
    "Rank": 951724,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24587,
    "Rank": 951788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24588,
    "Rank": 951845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24589,
    "Rank": 952055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karwar,DISTRICT HOSPITAL PREMISES, MG ROAD, KARWAR, Karnataka, 581301",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24590,
    "Rank": 952101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24591,
    "Rank": 952242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24592,
    "Rank": 952697,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24593,
    "Rank": 952704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24594,
    "Rank": 952882,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24595,
    "Rank": 953056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24596,
    "Rank": 953093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24597,
    "Rank": 953264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24598,
    "Rank": 953295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24599,
    "Rank": 954050,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24600,
    "Rank": 954304,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24601,
    "Rank": 954307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24602,
    "Rank": 954318,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24603,
    "Rank": 954353,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24604,
    "Rank": 954372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24605,
    "Rank": 954377,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24606,
    "Rank": 954475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24607,
    "Rank": 954476,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24608,
    "Rank": 954693,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24609,
    "Rank": 954749,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24610,
    "Rank": 954828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24611,
    "Rank": 954855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24612,
    "Rank": 954972,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24613,
    "Rank": 955027,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24614,
    "Rank": 955155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24615,
    "Rank": 955360,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24616,
    "Rank": 955398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24617,
    "Rank": 955492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24618,
    "Rank": 955518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24619,
    "Rank": 955553,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24620,
    "Rank": 955698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24621,
    "Rank": 955747,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24622,
    "Rank": 955770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24623,
    "Rank": 955810,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24624,
    "Rank": 956241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24625,
    "Rank": 956262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kallakurichi ,S.NO 251, AGARAKOTTALAM ROAD, SIRUVANGUR, KALLAKURICHI, Tamil Nadu, 606213",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24626,
    "Rank": 956535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24627,
    "Rank": 956663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24628,
    "Rank": 956897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24629,
    "Rank": 956970,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24630,
    "Rank": 957025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24631,
    "Rank": 957204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24632,
    "Rank": 957493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24633,
    "Rank": 957508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24634,
    "Rank": 957583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24635,
    "Rank": 957735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24636,
    "Rank": 958314,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24637,
    "Rank": 958376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24638,
    "Rank": 958390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24639,
    "Rank": 958505,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24640,
    "Rank": 958525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24641,
    "Rank": 958631,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24642,
    "Rank": 958961,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24643,
    "Rank": 959021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24644,
    "Rank": 959140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24645,
    "Rank": 959183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24646,
    "Rank": 959257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24647,
    "Rank": 959289,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24648,
    "Rank": 959466,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24649,
    "Rank": 959523,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24650,
    "Rank": 959554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24651,
    "Rank": 959796,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24652,
    "Rank": 959850,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24653,
    "Rank": 959880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24654,
    "Rank": 959926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24655,
    "Rank": 960054,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24656,
    "Rank": 960071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24657,
    "Rank": 960506,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24658,
    "Rank": 960659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24659,
    "Rank": 960823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24660,
    "Rank": 960921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24661,
    "Rank": 961026,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24662,
    "Rank": 961064,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24663,
    "Rank": 961608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24664,
    "Rank": 961879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Dindigul,1 Nallampatti Road Adiyanoothu village Dindigul, Tamil Nadu, 624003",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24665,
    "Rank": 962294,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24666,
    "Rank": 962606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24667,
    "Rank": 962651,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24668,
    "Rank": 962802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24669,
    "Rank": 962816,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24670,
    "Rank": 962899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Anantnag,verinag Road Dialgam Anantnag 192210, Jammu And Kashmir, 192210",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24671,
    "Rank": 962999,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24672,
    "Rank": 963179,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24673,
    "Rank": 963303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24674,
    "Rank": 963326,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24675,
    "Rank": 963540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24676,
    "Rank": 963546,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24677,
    "Rank": 963667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24678,
    "Rank": 963670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24679,
    "Rank": 963753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24680,
    "Rank": 963784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24681,
    "Rank": 963813,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE MACHILIPATNAM ,KARA AGRAHARAM NEAR RADAR STATION MACHILIPATNAM, Andhra Pradesh, 521001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24682,
    "Rank": 963937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24683,
    "Rank": 964090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24684,
    "Rank": 964186,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24685,
    "Rank": 964276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24686,
    "Rank": 964328,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24687,
    "Rank": 964428,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24688,
    "Rank": 964512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24689,
    "Rank": 964833,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24690,
    "Rank": 965764,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24691,
    "Rank": 965774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24692,
    "Rank": 965917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24693,
    "Rank": 965965,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24694,
    "Rank": 966239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24695,
    "Rank": 966327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24696,
    "Rank": 966330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24697,
    "Rank": 966457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24698,
    "Rank": 966597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24699,
    "Rank": 966986,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24700,
    "Rank": 967171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24701,
    "Rank": 967175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarat Chandra Chattopadhyay Govt. Medical College & Hospital, Uluberia,ULUBERIA, DIST-HOWRAH, West Bengal, 711315",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24702,
    "Rank": 967224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24703,
    "Rank": 967383,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24704,
    "Rank": 967441,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24705,
    "Rank": 967592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24706,
    "Rank": 967804,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24707,
    "Rank": 967823,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24708,
    "Rank": 967954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24709,
    "Rank": 968342,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAGALAND INSTITUTE OF MEDICAL SCIENCE AND RESEARCH PHIREBAGIE,\nKOHIMA - 797001,Phreibagei, Kohima, Nagaland, 797001",
    "Course": "MBBS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24710,
    "Rank": 968530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24711,
    "Rank": 968570,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24712,
    "Rank": 968710,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24713,
    "Rank": 968940,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24714,
    "Rank": 969224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24715,
    "Rank": 969490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24716,
    "Rank": 969525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24717,
    "Rank": 969676,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24718,
    "Rank": 969880,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24719,
    "Rank": 970217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24720,
    "Rank": 970272,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24721,
    "Rank": 970598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24722,
    "Rank": 970758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24723,
    "Rank": 970975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24724,
    "Rank": 971593,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24725,
    "Rank": 972051,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24726,
    "Rank": 972093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24727,
    "Rank": 972391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24728,
    "Rank": 972603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24729,
    "Rank": 972667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24730,
    "Rank": 972798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24731,
    "Rank": 972838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24732,
    "Rank": 972870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24733,
    "Rank": 972890,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24734,
    "Rank": 973374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24735,
    "Rank": 973381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24736,
    "Rank": 973606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24737,
    "Rank": 973680,
    "Allotted Quota": "All India",
    "Allotted Institute": "BURDWAN MEDICAL COLLEGE,BURDWAN,BURDWAN MEDICAL COLLEGE, BABURBAG, POST- RAJBATI, DIST- PURBA BARDHAMAN, PIN-713104,WEST\nBENGAL, West Bengal, 713104",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24738,
    "Rank": 973741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24739,
    "Rank": 973752,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24740,
    "Rank": 973853,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24741,
    "Rank": 974181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24742,
    "Rank": 974269,
    "Allotted Quota": "All India",
    "Allotted Institute": "ZORAM MEDICAL COLLEGE Falkawn,Falkawn, Aizawl District, Mizoram, 796005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24743,
    "Rank": 974456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24744,
    "Rank": 974587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24745,
    "Rank": 974654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24746,
    "Rank": 974679,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-MC&PGIMS&R, Banglore,ESICMC AND PGIMSR, Rajajinagar, Bengaluru, Karnataka, 560010",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24747,
    "Rank": 975089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24748,
    "Rank": 975171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24749,
    "Rank": 975438,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24750,
    "Rank": 975448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24751,
    "Rank": 975620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24752,
    "Rank": 975770,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24753,
    "Rank": 975999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24754,
    "Rank": 976112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24755,
    "Rank": 976232,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24756,
    "Rank": 976585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24757,
    "Rank": 976609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24758,
    "Rank": 976844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24759,
    "Rank": 976865,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24760,
    "Rank": 976993,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24761,
    "Rank": 977165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24762,
    "Rank": 977197,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24763,
    "Rank": 977260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24764,
    "Rank": 977280,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24765,
    "Rank": 977340,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE, MADURAI,PANAGAL ROAD MADURAI, Tamil Nadu, 625020",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24766,
    "Rank": 977478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24767,
    "Rank": 977508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24768,
    "Rank": 977564,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24769,
    "Rank": 977608,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24770,
    "Rank": 977742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24771,
    "Rank": 977812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24772,
    "Rank": 977893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24773,
    "Rank": 977920,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24774,
    "Rank": 978182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24775,
    "Rank": 978370,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24776,
    "Rank": 978840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24777,
    "Rank": 979226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24778,
    "Rank": 979232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College and Hospital, Keonjhar,AT-KABITRA, NEAR DD COLLEGE, KEONJHARGARH, PS- TOWN POLICE STATION, DIST-KEONJHAR, ODISHA, Odisha, 758001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24779,
    "Rank": 979239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24780,
    "Rank": 979361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24781,
    "Rank": 979578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24782,
    "Rank": 979667,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24783,
    "Rank": 979730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24784,
    "Rank": 979860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24785,
    "Rank": 979909,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24786,
    "Rank": 980273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SRM Katt. Dental College and Hospt. Chennai,SRM NAGAR POTHERI KATTANKULATHUR KANCHIPURAM, Tamil Nadu, 603203",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24787,
    "Rank": 981173,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24788,
    "Rank": 981228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24789,
    "Rank": 981459,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24790,
    "Rank": 981694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24791,
    "Rank": 981802,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24792,
    "Rank": 981863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Haveri Institute of Medical Sciences, Karnataka,Devagiri-Yellapura, Haveri-581110, Karnataka, 581110",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24793,
    "Rank": 981947,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24794,
    "Rank": 982332,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24795,
    "Rank": 982352,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24796,
    "Rank": 982443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24797,
    "Rank": 982625,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24798,
    "Rank": 982694,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24799,
    "Rank": 982822,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24800,
    "Rank": 983262,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24801,
    "Rank": 983329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24802,
    "Rank": 983782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24803,
    "Rank": 983814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24804,
    "Rank": 983831,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24805,
    "Rank": 983906,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24806,
    "Rank": 984231,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24807,
    "Rank": 984457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24808,
    "Rank": 984500,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24809,
    "Rank": 984530,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24810,
    "Rank": 984561,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24811,
    "Rank": 985096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24812,
    "Rank": 985157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24813,
    "Rank": 985212,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24814,
    "Rank": 985580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24815,
    "Rank": 985642,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24816,
    "Rank": 985774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24817,
    "Rank": 985806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24818,
    "Rank": 986029,
    "Allotted Quota": "All India",
    "Allotted Institute": "RIMS, Ongole,BHAGYANAGAR 5TH LANE, RIMS, ONGOLE, PRAKASAM DISTRICT,\nANDHRA PRADESH, Andhra Pradesh, 523001",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24819,
    "Rank": 986104,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24820,
    "Rank": 986179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24821,
    "Rank": 986432,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24822,
    "Rank": 986470,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24823,
    "Rank": 986551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24824,
    "Rank": 986556,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24825,
    "Rank": 986746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24826,
    "Rank": 986918,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24827,
    "Rank": 986973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24828,
    "Rank": 987001,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24829,
    "Rank": 987290,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24830,
    "Rank": 987457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24831,
    "Rank": 987623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24832,
    "Rank": 987631,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Puducherry, 607402",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24833,
    "Rank": 987955,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24834,
    "Rank": 988003,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24835,
    "Rank": 988093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24836,
    "Rank": 988688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24837,
    "Rank": 988790,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24838,
    "Rank": 988815,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24839,
    "Rank": 988870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24840,
    "Rank": 989566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24841,
    "Rank": 989618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24842,
    "Rank": 989900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24843,
    "Rank": 989915,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24844,
    "Rank": 989958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24845,
    "Rank": 990080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Navi Mumbai,Bharati Vidyapeeth Dental College and Hospital, Sec.7, Belapur Navi Mumbai., Maharashtra, 400614",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24846,
    "Rank": 990126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24847,
    "Rank": 990130,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24848,
    "Rank": 990135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24849,
    "Rank": 990215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sundarlal patwa Govt medical College mandsaur,Dean Office, Sunderlal Patwa Government Medical College, Mhow Neemuch Bypass, Mandsaur M.P., Madhya Pradesh, 458001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24850,
    "Rank": 990365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24851,
    "Rank": 990484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24852,
    "Rank": 990829,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24853,
    "Rank": 990855,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24854,
    "Rank": 991083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24855,
    "Rank": 991170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24856,
    "Rank": 991179,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24857,
    "Rank": 991286,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24858,
    "Rank": 991315,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24859,
    "Rank": 991386,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24860,
    "Rank": 991799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24861,
    "Rank": 991807,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24862,
    "Rank": 992041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24863,
    "Rank": 992056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24864,
    "Rank": 992354,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24865,
    "Rank": 992516,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24866,
    "Rank": 992657,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24867,
    "Rank": 992863,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24868,
    "Rank": 993871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24869,
    "Rank": 993964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24870,
    "Rank": 994057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Virudhunagar,1 COLLECTORATE MASTER PLAN COMPLEX\nKOORAIKUNDU VILLAGE VIRUDHUNAGAR, Tamil Nadu, 626002",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24871,
    "Rank": 994359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24872,
    "Rank": 994650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24873,
    "Rank": 994883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24874,
    "Rank": 994885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24875,
    "Rank": 995223,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24876,
    "Rank": 995263,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24877,
    "Rank": 995330,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24878,
    "Rank": 995393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24879,
    "Rank": 995637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24880,
    "Rank": 995659,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24881,
    "Rank": 995776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24882,
    "Rank": 995926,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24883,
    "Rank": 995945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24884,
    "Rank": 995951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24885,
    "Rank": 996106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24886,
    "Rank": 996141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24887,
    "Rank": 996165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24888,
    "Rank": 996247,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24889,
    "Rank": 996269,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24890,
    "Rank": 996431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24891,
    "Rank": 996476,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24892,
    "Rank": 996523,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24893,
    "Rank": 996860,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24894,
    "Rank": 996930,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24895,
    "Rank": 997158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24896,
    "Rank": 997997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24897,
    "Rank": 998033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24898,
    "Rank": 998162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24899,
    "Rank": 998447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24900,
    "Rank": 998555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24901,
    "Rank": 999295,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24902,
    "Rank": 999343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24903,
    "Rank": 999443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24904,
    "Rank": 999591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24905,
    "Rank": 999597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24906,
    "Rank": 999644,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24907,
    "Rank": 999949,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24908,
    "Rank": 999953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24909,
    "Rank": 999959,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24910,
    "Rank": 1000042,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24911,
    "Rank": 1000082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24912,
    "Rank": 1000203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24913,
    "Rank": 1000309,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24914,
    "Rank": 1000339,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24915,
    "Rank": 1000381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24916,
    "Rank": 1000462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24917,
    "Rank": 1000685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dhubri Medical College, Assam,P.O. Jhagrarpar SPO, Dhubri, Assam, PIN - 783325, Assam, 783325",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24918,
    "Rank": 1000692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24919,
    "Rank": 1000767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24920,
    "Rank": 1000784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24921,
    "Rank": 1001023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24922,
    "Rank": 1001111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nilgiris,No. 1, INDU  NAGAR, MYSORE ROAD, UDHAGAMANDALAM, NILGIRIS DISTRICT, Tamil Nadu, 643005",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24923,
    "Rank": 1001140,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMKV Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24924,
    "Rank": 1001478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24925,
    "Rank": 1002106,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24926,
    "Rank": 1002112,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24927,
    "Rank": 1002170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24928,
    "Rank": 1002634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24929,
    "Rank": 1002729,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24930,
    "Rank": 1002789,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24931,
    "Rank": 1002867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24932,
    "Rank": 1003673,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24933,
    "Rank": 1004188,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24934,
    "Rank": 1004467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24935,
    "Rank": 1004504,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24936,
    "Rank": 1004885,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24937,
    "Rank": 1004887,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24938,
    "Rank": 1005033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24939,
    "Rank": 1005191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24940,
    "Rank": 1005348,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24941,
    "Rank": 1005437,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24942,
    "Rank": 1005499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24943,
    "Rank": 1005600,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24944,
    "Rank": 1005751,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24945,
    "Rank": 1005756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24946,
    "Rank": 1005935,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24947,
    "Rank": 1006023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24948,
    "Rank": 1006448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24949,
    "Rank": 1006663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24950,
    "Rank": 1006929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24951,
    "Rank": 1006974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24952,
    "Rank": 1007098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24953,
    "Rank": 1007363,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24954,
    "Rank": 1007560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24955,
    "Rank": 1008165,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24956,
    "Rank": 1008495,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24957,
    "Rank": 1008776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24958,
    "Rank": 1008959,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24959,
    "Rank": 1009006,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24960,
    "Rank": 1009118,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24961,
    "Rank": 1009153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24962,
    "Rank": 1009162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24963,
    "Rank": 1009268,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24964,
    "Rank": 1009363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24965,
    "Rank": 1009458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24966,
    "Rank": 1009474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24967,
    "Rank": 1009533,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24968,
    "Rank": 1009559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24969,
    "Rank": 1009616,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24970,
    "Rank": 1009723,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24971,
    "Rank": 1010037,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24972,
    "Rank": 1010054,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24973,
    "Rank": 1010170,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24974,
    "Rank": 1010325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24975,
    "Rank": 1010914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24976,
    "Rank": 1010932,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Dental College and Hospital  Rohini,ESIC DENTAL COLLEGE AND HOSPITAL SECTOR 15 ROHINI, Delhi (NCT), 110085",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24977,
    "Rank": 1011002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24978,
    "Rank": 1011150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Institute of Dental Sciences, Bhubaneswar,K8, Kalinga Nagar, PO-Mahalaxmi Vihar, Bhubaneswar, Odisha, 751003",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24979,
    "Rank": 1011436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24980,
    "Rank": 1011499,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24981,
    "Rank": 1011706,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24982,
    "Rank": 1012282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24983,
    "Rank": 1012442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24984,
    "Rank": 1013025,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24985,
    "Rank": 1013252,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24986,
    "Rank": 1013509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24987,
    "Rank": 1013608,
    "Allotted Quota": "All India",
    "Allotted Institute": "M.G.M. MEDICAL COLLEGE, INDORE,AB ROAD INDORE, Madhya Pradesh, 452001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24988,
    "Rank": 1013704,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24989,
    "Rank": 1013806,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24990,
    "Rank": 1013854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24991,
    "Rank": 1013960,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24992,
    "Rank": 1014031,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24993,
    "Rank": 1014033,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24994,
    "Rank": 1014056,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24995,
    "Rank": 1014060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24996,
    "Rank": 1014229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24997,
    "Rank": 1014258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24998,
    "Rank": 1014443,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 24999,
    "Rank": 1014460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25000,
    "Rank": 1014535,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25001,
    "Rank": 1014840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25002,
    "Rank": 1014911,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25003,
    "Rank": 1015369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25004,
    "Rank": 1015597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25005,
    "Rank": 1015722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25006,
    "Rank": 1015783,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25007,
    "Rank": 1015929,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25008,
    "Rank": 1015968,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25009,
    "Rank": 1016141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25010,
    "Rank": 1016144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25011,
    "Rank": 1016162,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25012,
    "Rank": 1016238,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25013,
    "Rank": 1016398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25014,
    "Rank": 1016509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25015,
    "Rank": 1016634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25016,
    "Rank": 1016730,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25017,
    "Rank": 1016762,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25018,
    "Rank": 1017225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25019,
    "Rank": 1017350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25020,
    "Rank": 1017390,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "ACS Medical College and Hospital, Chennai,Periyar EVR High Raod, Velappanchavadi, Chennai-77, Tamil Nadu, 600077",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25021,
    "Rank": 1017509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25022,
    "Rank": 1017620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25023,
    "Rank": 1018341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubabad,Government Medical College, Mahabubabad, 551-1-P Mahabubabad Village Mahabubabad, Telangana, 506101",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25024,
    "Rank": 1018492,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25025,
    "Rank": 1018682,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25026,
    "Rank": 1018864,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25027,
    "Rank": 1019367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25028,
    "Rank": 1019494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25029,
    "Rank": 1019660,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25030,
    "Rank": 1019776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25031,
    "Rank": 1019824,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25032,
    "Rank": 1019851,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25033,
    "Rank": 1019871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Nirmal,530 PRIYADARSHINI NAGAR NIRMAL, Telangana, 504106",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25034,
    "Rank": 1020030,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25035,
    "Rank": 1020144,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25036,
    "Rank": 1020215,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25037,
    "Rank": 1020335,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25038,
    "Rank": 1020457,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JLN Medical College, Datta Meghe, Wardha,Sawangi (Meghe), Wardha, Maharashtra State,India, Maharashtra, 442107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25039,
    "Rank": 1020752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25040,
    "Rank": 1020963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25041,
    "Rank": 1021044,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25042,
    "Rank": 1021212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25043,
    "Rank": 1021241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25044,
    "Rank": 1022060,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25045,
    "Rank": 1022319,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25046,
    "Rank": 1022330,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25047,
    "Rank": 1023650,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25048,
    "Rank": 1023761,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25049,
    "Rank": 1023837,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25050,
    "Rank": 1023946,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25051,
    "Rank": 1024283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College Warangal,SVP Road, Warangal, Telangana, 506007",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25052,
    "Rank": 1024543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25053,
    "Rank": 1024710,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25054,
    "Rank": 1024871,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25055,
    "Rank": 1025139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25056,
    "Rank": 1025409,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25057,
    "Rank": 1025597,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25058,
    "Rank": 1025727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25059,
    "Rank": 1025878,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Ammal Dental College and Host., Chennai1,ALAPAKKAM MAIN ROAD MADURAVOYAL\nCHENNAI, Tamil Nadu, 600095",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25060,
    "Rank": 1026288,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25061,
    "Rank": 1026541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25062,
    "Rank": 1026617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25063,
    "Rank": 1026818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25064,
    "Rank": 1027288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25065,
    "Rank": 1027344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25066,
    "Rank": 1027508,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25067,
    "Rank": 1027704,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25068,
    "Rank": 1028039,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25069,
    "Rank": 1028217,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25070,
    "Rank": 1028277,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25071,
    "Rank": 1028453,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHRI BHAUSAHEB HIRE GOVT. M.C., DHULE,Shri.Bhusaheb Hire Government Medical College Malegaon Road Chakkarbardi Area Dhule, Maharashtra, 424002",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25072,
    "Rank": 1028464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25073,
    "Rank": 1028582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25074,
    "Rank": 1028695,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25075,
    "Rank": 1028702,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Inst of Med Sciences and Research, New Delhi,HIMSR, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25076,
    "Rank": 1028882,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25077,
    "Rank": 1029015,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25078,
    "Rank": 1029040,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25079,
    "Rank": 1029211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25080,
    "Rank": 1029413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25081,
    "Rank": 1029696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25082,
    "Rank": 1030076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25083,
    "Rank": 1030120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25084,
    "Rank": 1030150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25085,
    "Rank": 1030221,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25086,
    "Rank": 1030265,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25087,
    "Rank": 1030375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25088,
    "Rank": 1030446,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25089,
    "Rank": 1030507,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25090,
    "Rank": 1030707,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25091,
    "Rank": 1030889,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25092,
    "Rank": 1031181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25093,
    "Rank": 1031284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25094,
    "Rank": 1031399,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25095,
    "Rank": 1031458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25096,
    "Rank": 1031487,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25097,
    "Rank": 1031820,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25098,
    "Rank": 1032052,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25099,
    "Rank": 1032283,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25100,
    "Rank": 1032926,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25101,
    "Rank": 1033120,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DATTA MEGHE MEDICAL COLLEGE WANADONGRI HINGNA NAGPUR,Hinaga Road\nWanadongri Nagpur Maharashtra India, Maharashtra, 441110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25102,
    "Rank": 1033159,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25103,
    "Rank": 1033227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25104,
    "Rank": 1033232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ariyalur ,1 COLLEGE ROAD GOVERNMENT MEDICAL COLLEGE ARIYALUR TAMIL NADU PIN 621713, Tamil Nadu, 621713",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25105,
    "Rank": 1033267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25106,
    "Rank": 1033363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25107,
    "Rank": 1033423,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25108,
    "Rank": 1033883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Raiganj Government Medical College,ABDULGHATA CAMPASS, KARNAJORA, PO- KARNAJORA,PS- RAIGANJ, DISTRICT- UTTAR DINAJPUR,PIN-733130, STATE, West\nBengal, 733130",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25109,
    "Rank": 1033928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25110,
    "Rank": 1034006,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25111,
    "Rank": 1034540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25112,
    "Rank": 1034554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25113,
    "Rank": 1034575,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25114,
    "Rank": 1034896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25115,
    "Rank": 1034921,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25116,
    "Rank": 1034978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25117,
    "Rank": 1035040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25118,
    "Rank": 1035138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25119,
    "Rank": 1035225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25120,
    "Rank": 1035478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25121,
    "Rank": 1035559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25122,
    "Rank": 1035679,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25123,
    "Rank": 1035689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25124,
    "Rank": 1035938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Aarupadai Veedu Medical College and Hospt., Puducherry,PONDY - CUDDALORE MAIN ROAD, KIRUMAMPAKKAM, PUDUCHERRY, Puducherry, 607403",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25125,
    "Rank": 1036198,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Siddhartha DentalCollege, Tumkur,AGALAKOTE B H ROAD TUMKUR, Karnataka, 572107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25126,
    "Rank": 1036218,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25127,
    "Rank": 1036382,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25128,
    "Rank": 1036838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25129,
    "Rank": 1037085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25130,
    "Rank": 1037529,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25131,
    "Rank": 1037537,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25132,
    "Rank": 1037555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25133,
    "Rank": 1037576,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25134,
    "Rank": 1037604,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25135,
    "Rank": 1037738,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25136,
    "Rank": 1037765,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25137,
    "Rank": 1037778,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25138,
    "Rank": 1037852,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25139,
    "Rank": 1038092,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25140,
    "Rank": 1038181,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25141,
    "Rank": 1038324,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25142,
    "Rank": 1038349,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25143,
    "Rank": 1038398,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25144,
    "Rank": 1038508,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25145,
    "Rank": 1038535,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25146,
    "Rank": 1038607,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25147,
    "Rank": 1038721,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25148,
    "Rank": 1039068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25149,
    "Rank": 1039266,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25150,
    "Rank": 1039377,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25151,
    "Rank": 1039403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25152,
    "Rank": 1039620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25153,
    "Rank": 1039811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25154,
    "Rank": 1039868,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25155,
    "Rank": 1039891,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25156,
    "Rank": 1039964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25157,
    "Rank": 1039992,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25158,
    "Rank": 1040023,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25159,
    "Rank": 1040222,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25160,
    "Rank": 1040422,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25161,
    "Rank": 1040462,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25162,
    "Rank": 1040704,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25163,
    "Rank": 1040883,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25164,
    "Rank": 1040912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25165,
    "Rank": 1041679,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25166,
    "Rank": 1042186,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25167,
    "Rank": 1042375,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25168,
    "Rank": 1042974,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25169,
    "Rank": 1043703,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25170,
    "Rank": 1043751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25171,
    "Rank": 1043787,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25172,
    "Rank": 1043836,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25173,
    "Rank": 1043869,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25174,
    "Rank": 1043927,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25175,
    "Rank": 1044018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25176,
    "Rank": 1044196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25177,
    "Rank": 1044346,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25178,
    "Rank": 1044506,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25179,
    "Rank": 1044758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Medical College, Tirupati,NEAR VIVEKANDANDA CIRCLE, ALIPIRI ROAD TIRUPATI, Andhra Pradesh, 517507",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25180,
    "Rank": 1044971,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25181,
    "Rank": 1044974,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita Institute of Medical Science, Kochi,Amrita School of Medicine, Amrita Institute of Medical Sciences\nAIMS Ponekkara P.O Kochi 682041, Kerala, 682041",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25182,
    "Rank": 1045486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "School of Dental Sciences and KIMSDU, Karad,KARAD, DIST SATARA (MAHARASHTRA STATE) - 415110, Maharashtra, 415110",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25183,
    "Rank": 1045700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25184,
    "Rank": 1045738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25185,
    "Rank": 1046047,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25186,
    "Rank": 1046288,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25187,
    "Rank": 1046569,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DYP Edu. Soc. Deemed Uni., Kolhapur,869, E Ward, D. Y. Patil Vidyanagar, Kasaba Bavada, Kolhapur 416006, Maharashtra, 416006",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25188,
    "Rank": 1046589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25189,
    "Rank": 1046779,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25190,
    "Rank": 1046938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25191,
    "Rank": 1046956,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25192,
    "Rank": 1047063,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25193,
    "Rank": 1047183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Kannauj,Tirwa Road, Kannauj, Uttar Pradesh, 209732",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25194,
    "Rank": 1048166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25195,
    "Rank": 1048265,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25196,
    "Rank": 1048267,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25197,
    "Rank": 1048453,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25198,
    "Rank": 1048549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25199,
    "Rank": 1048754,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25200,
    "Rank": 1049101,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25201,
    "Rank": 1049286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25202,
    "Rank": 1049457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25203,
    "Rank": 1049621,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25204,
    "Rank": 1049727,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25205,
    "Rank": 1049854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25206,
    "Rank": 1049947,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25207,
    "Rank": 1050149,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25208,
    "Rank": 1050681,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25209,
    "Rank": 1050767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25210,
    "Rank": 1051196,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25211,
    "Rank": 1051286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25212,
    "Rank": 1051480,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25213,
    "Rank": 1051483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College Shahdol,GRAM CHAMPA KUDRI ROAD NEAR NEW BUS STAND SHAHDOL MP, Madhya Pradesh, 484001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25214,
    "Rank": 1051538,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25215,
    "Rank": 1052456,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25216,
    "Rank": 1052483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25217,
    "Rank": 1052624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25218,
    "Rank": 1052673,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25219,
    "Rank": 1052857,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25220,
    "Rank": 1053011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25221,
    "Rank": 1053042,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25222,
    "Rank": 1053114,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25223,
    "Rank": 1053188,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25224,
    "Rank": 1053310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25225,
    "Rank": 1053433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25226,
    "Rank": 1053515,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25227,
    "Rank": 1053532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25228,
    "Rank": 1053619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. D.D.U MEDICAL COLLEGE, RAJKOT,Civil Hospital Campus, Jamnagar Road Rajkot 360001, Gujarat, 360001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25229,
    "Rank": 1053623,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25230,
    "Rank": 1053700,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Institute of Dental Sciences, New Delhi,MAMC Complex, B.S. Zafar Marg, New Delhi, Delhi (NCT), 110002",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25231,
    "Rank": 1053837,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25232,
    "Rank": 1053946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25233,
    "Rank": 1054325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25234,
    "Rank": 1054753,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25235,
    "Rank": 1054781,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25236,
    "Rank": 1054792,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25237,
    "Rank": 1054895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25238,
    "Rank": 1055131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25239,
    "Rank": 1055241,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25240,
    "Rank": 1055358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25241,
    "Rank": 1055893,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25242,
    "Rank": 1056626,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25243,
    "Rank": 1056653,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25244,
    "Rank": 1056746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25245,
    "Rank": 1056831,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25246,
    "Rank": 1056961,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25247,
    "Rank": 1057077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25248,
    "Rank": 1057093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25249,
    "Rank": 1057792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Sultanpur, Uttar Pradesh,Autonomous State Medical College Dubepur Sultanpur, Uttar Pradesh, 227815",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25250,
    "Rank": 1057824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Autonomous State Medical College, Ghazipur,Principal Prashasnik Bhawan\nMV Autonomous State Medical College RTI Chauraha\nGhazipur, Uttar Pradesh, 233001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25251,
    "Rank": 1057902,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25252,
    "Rank": 1058000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25253,
    "Rank": 1058006,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25254,
    "Rank": 1058021,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25255,
    "Rank": 1058211,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25256,
    "Rank": 1058220,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25257,
    "Rank": 1058227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25258,
    "Rank": 1058281,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25259,
    "Rank": 1058437,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25260,
    "Rank": 1058515,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25261,
    "Rank": 1058761,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25262,
    "Rank": 1059521,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25263,
    "Rank": 1059794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25264,
    "Rank": 1059938,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25265,
    "Rank": 1059978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25266,
    "Rank": 1060000,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25267,
    "Rank": 1060248,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25268,
    "Rank": 1060282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25269,
    "Rank": 1060327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25270,
    "Rank": 1060420,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25271,
    "Rank": 1060578,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25272,
    "Rank": 1061098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25273,
    "Rank": 1061136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25274,
    "Rank": 1061224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25275,
    "Rank": 1061327,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25276,
    "Rank": 1061394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25277,
    "Rank": 1061600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt Medical College, Jogulamba, Gadwal,GMC Jogulamba Gadwal, Near Doulath peer Dargah, Doudharpally, Ring Road, Sangala Cheruvu, Gadwal Tow, Telangana, 509125",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25278,
    "Rank": 1061702,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25279,
    "Rank": 1061999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25280,
    "Rank": 1062528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25281,
    "Rank": 1062809,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, KOZHIKODE,Principal, Govt. Medical College, Medical College (P.O)- Kozhikode, Kerala, 673008",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25282,
    "Rank": 1062838,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BHAARATH MEDICAL COLLEGE AND HOSPITAL,173, AGARAM MAIN ROAD , SELAIYUR, TAMBARAM CHENNAI - 600073, Tamil Nadu, 600073",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25283,
    "Rank": 1063028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mancherial,Government Medical College 708 Garmilla Village , Mancherial Tehsil\nMancherial., Telangana, 504208",
    "Course": "MBBS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25284,
    "Rank": 1063062,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25285,
    "Rank": 1063182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25286,
    "Rank": 1063478,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25287,
    "Rank": 1063574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25288,
    "Rank": 1063623,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25289,
    "Rank": 1063742,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kalinga Institute of Medical Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR, Odisha, 751024",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25290,
    "Rank": 1063833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25291,
    "Rank": 1063844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25292,
    "Rank": 1063874,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25293,
    "Rank": 1063881,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25294,
    "Rank": 1064013,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25295,
    "Rank": 1064337,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25296,
    "Rank": 1064385,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25297,
    "Rank": 1064475,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25298,
    "Rank": 1064833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25299,
    "Rank": 1064914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25300,
    "Rank": 1065126,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25301,
    "Rank": 1065799,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM Medical College and Hospital, Chennai,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, POTHERI, KATTANKULATHUR 603203, CHENGALPATTU DIST,, Tamil Nadu, 603203",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25302,
    "Rank": 1065964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25303,
    "Rank": 1066019,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25304,
    "Rank": 1066071,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25305,
    "Rank": 1066088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25306,
    "Rank": 1066115,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25307,
    "Rank": 1066358,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25308,
    "Rank": 1066407,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25309,
    "Rank": 1066481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25310,
    "Rank": 1066485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25311,
    "Rank": 1067082,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25312,
    "Rank": 1067171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25313,
    "Rank": 1068652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25314,
    "Rank": 1068875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25315,
    "Rank": 1068941,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25316,
    "Rank": 1069279,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25317,
    "Rank": 1069303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25318,
    "Rank": 1069310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25319,
    "Rank": 1069409,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25320,
    "Rank": 1069896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25321,
    "Rank": 1070153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25322,
    "Rank": 1070234,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25323,
    "Rank": 1070276,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25324,
    "Rank": 1070451,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25325,
    "Rank": 1070757,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25326,
    "Rank": 1070767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25327,
    "Rank": 1070784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25328,
    "Rank": 1071123,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25329,
    "Rank": 1071131,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25330,
    "Rank": 1071182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25331,
    "Rank": 1071518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25332,
    "Rank": 1071840,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25333,
    "Rank": 1072250,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25334,
    "Rank": 1072449,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25335,
    "Rank": 1073022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25336,
    "Rank": 1073554,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25337,
    "Rank": 1073797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25338,
    "Rank": 1074040,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25339,
    "Rank": 1074043,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25340,
    "Rank": 1074172,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25341,
    "Rank": 1074299,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25342,
    "Rank": 1075002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25343,
    "Rank": 1075171,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Krishna Inst. of Med. Scie., Karad,KARAD, DIST. SATARA (MAHARASHTRA STATE).,\nMaharashtra, 415110",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25344,
    "Rank": 1075214,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25345,
    "Rank": 1075321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25346,
    "Rank": 1075389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25347,
    "Rank": 1075496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25348,
    "Rank": 1075661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25349,
    "Rank": 1075689,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25350,
    "Rank": 1075875,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25351,
    "Rank": 1075899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25352,
    "Rank": 1076076,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25353,
    "Rank": 1076270,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Manjeri, Kerala,GOVERNMENT MEDICAL COLLEGE, MANJERI, MALAPPURAM, Kerala, 676121",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25354,
    "Rank": 1076341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25355,
    "Rank": 1076569,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25356,
    "Rank": 1076714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25357,
    "Rank": 1077001,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25358,
    "Rank": 1077135,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25359,
    "Rank": 1077281,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25360,
    "Rank": 1077282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25361,
    "Rank": 1077467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25362,
    "Rank": 1077833,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25363,
    "Rank": 1078733,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25364,
    "Rank": 1078762,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25365,
    "Rank": 1078767,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25366,
    "Rank": 1078784,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25367,
    "Rank": 1078820,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25368,
    "Rank": 1079086,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25369,
    "Rank": 1079120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25370,
    "Rank": 1079300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25371,
    "Rank": 1079306,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25372,
    "Rank": 1079394,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25373,
    "Rank": 1079401,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25374,
    "Rank": 1079415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25375,
    "Rank": 1079429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25376,
    "Rank": 1079484,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25377,
    "Rank": 1079485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25378,
    "Rank": 1079593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25379,
    "Rank": 1079928,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25380,
    "Rank": 1080089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25381,
    "Rank": 1080138,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25382,
    "Rank": 1080354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25383,
    "Rank": 1080559,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25384,
    "Rank": 1080606,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25385,
    "Rank": 1080667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25386,
    "Rank": 1081227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25387,
    "Rank": 1081431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25388,
    "Rank": 1081470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25389,
    "Rank": 1081700,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25390,
    "Rank": 1081784,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25391,
    "Rank": 1081841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25392,
    "Rank": 1082541,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25393,
    "Rank": 1082712,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25394,
    "Rank": 1083099,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25395,
    "Rank": 1083605,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25396,
    "Rank": 1083617,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25397,
    "Rank": 1083667,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25398,
    "Rank": 1083718,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25399,
    "Rank": 1083928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chandulal Chandrakar Memorial Government Medical College, Durg,Chandulal Chandrakar Memorial Government Medical College, Kurud Road, Near Rungta College, Kachandur, Chhattisgarh, 490024",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25400,
    "Rank": 1083968,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25401,
    "Rank": 1084180,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25402,
    "Rank": 1084333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25403,
    "Rank": 1084397,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25404,
    "Rank": 1084427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25405,
    "Rank": 1084493,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25406,
    "Rank": 1084847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25407,
    "Rank": 1085077,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25408,
    "Rank": 1085785,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25409,
    "Rank": 1086460,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25410,
    "Rank": 1086586,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25411,
    "Rank": 1086709,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25412,
    "Rank": 1086848,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MM Inst. Med. and Research, Mullana,M.M. INSTITUTE OF MEDICAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA., Haryana, 133207",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25413,
    "Rank": 1087151,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25414,
    "Rank": 1087284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25415,
    "Rank": 1087433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25416,
    "Rank": 1088321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25417,
    "Rank": 1088687,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25418,
    "Rank": 1088778,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25419,
    "Rank": 1089034,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Dental College, Yenepoya,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25420,
    "Rank": 1089085,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AB Shetty Memorial Inst. of Dental Sce., Mangaluru,Deralakatte, Mangaluru, Dakshina Kannada, Karnataka, 575018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25421,
    "Rank": 1089129,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25422,
    "Rank": 1089183,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25423,
    "Rank": 1089278,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25424,
    "Rank": 1089391,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25425,
    "Rank": 1089403,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25426,
    "Rank": 1089797,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25427,
    "Rank": 1089856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25428,
    "Rank": 1090122,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25429,
    "Rank": 1090262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25430,
    "Rank": 1090388,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25431,
    "Rank": 1090583,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25432,
    "Rank": 1090809,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25433,
    "Rank": 1090896,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25434,
    "Rank": 1091018,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. R. AHMED DENT.COLL & HOSP,KOLKATA,114 AJC BOSE ROAD, West Bengal, 700014",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25435,
    "Rank": 1091075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25436,
    "Rank": 1091257,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25437,
    "Rank": 1091736,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25438,
    "Rank": 1092193,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25439,
    "Rank": 1092283,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25440,
    "Rank": 1092372,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25441,
    "Rank": 1092395,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, BARODA,Government Medical College Baroda, Anandpura, Vadodara Pin No 390001, Gujarat, 390001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25442,
    "Rank": 1092837,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25443,
    "Rank": 1093447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25444,
    "Rank": 1094266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25445,
    "Rank": 1094534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25446,
    "Rank": 1094758,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25447,
    "Rank": 1095408,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25448,
    "Rank": 1095513,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25449,
    "Rank": 1095705,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25450,
    "Rank": 1095839,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25451,
    "Rank": 1095996,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25452,
    "Rank": 1096204,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25453,
    "Rank": 1096226,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25454,
    "Rank": 1096259,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25455,
    "Rank": 1096333,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25456,
    "Rank": 1096377,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25457,
    "Rank": 1096653,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25458,
    "Rank": 1096659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25459,
    "Rank": 1097103,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Raja Rajeswari Medical College Bengaluru,202, Kambipura, Bengaluru  Mysuru High Way,Kengeri Hobli, Bangalore, Karnataka, Karnataka, 560074",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25460,
    "Rank": 1097121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25461,
    "Rank": 1097147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25462,
    "Rank": 1097894,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25463,
    "Rank": 1097970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25464,
    "Rank": 1098368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25465,
    "Rank": 1098717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25466,
    "Rank": 1098877,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25467,
    "Rank": 1099333,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25468,
    "Rank": 1099393,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25469,
    "Rank": 1099620,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25470,
    "Rank": 1100210,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25471,
    "Rank": 1100219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25472,
    "Rank": 1100258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25473,
    "Rank": 1100405,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25474,
    "Rank": 1100448,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25475,
    "Rank": 1100483,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25476,
    "Rank": 1100519,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25477,
    "Rank": 1100567,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25478,
    "Rank": 1100978,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25479,
    "Rank": 1101002,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25480,
    "Rank": 1101153,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25481,
    "Rank": 1101344,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25482,
    "Rank": 1101566,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25483,
    "Rank": 1101741,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25484,
    "Rank": 1101963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25485,
    "Rank": 1102158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25486,
    "Rank": 1102787,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAMPURHAT GOVT MEDICAL COLLEGE RAMPURHAT ,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL PO RAMPURHAT PS RAMPURHAT PIN 731224 DIST BIRBHUM, West Bengal, 731224",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25487,
    "Rank": 1103028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Medical College and Hospital, Chennai,NO 7 WORKS ROAD CHROMEPET CHENNAI, Tamil Nadu, 600044",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25488,
    "Rank": 1103211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25489,
    "Rank": 1103465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25490,
    "Rank": 1103871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25491,
    "Rank": 1104075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25492,
    "Rank": 1104166,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25493,
    "Rank": 1104744,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25494,
    "Rank": 1104755,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25495,
    "Rank": 1105012,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25496,
    "Rank": 1105091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25497,
    "Rank": 1105152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25498,
    "Rank": 1105163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25499,
    "Rank": 1105301,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25500,
    "Rank": 1105325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25501,
    "Rank": 1105329,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC, Rajamahendravaram,Government Medical College,D.No.55-4-1,Central Jail Road\n,Near CTRI, Rajamahendravaram., Andhra Pradesh, 533105",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25502,
    "Rank": 1105402,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25503,
    "Rank": 1105740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25504,
    "Rank": 1106155,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25505,
    "Rank": 1106160,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25506,
    "Rank": 1106389,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25507,
    "Rank": 1106391,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25508,
    "Rank": 1106638,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25509,
    "Rank": 1106639,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25510,
    "Rank": 1106722,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25511,
    "Rank": 1107690,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25512,
    "Rank": 1108206,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25513,
    "Rank": 1108300,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25514,
    "Rank": 1108325,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25515,
    "Rank": 1108629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25516,
    "Rank": 1108717,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25517,
    "Rank": 1108778,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25518,
    "Rank": 1109457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25519,
    "Rank": 1109580,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25520,
    "Rank": 1109675,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25521,
    "Rank": 1110111,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted( CW Rank\n: 6 )"
  },
  {
    "SNo": 25522,
    "Rank": 1110113,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25523,
    "Rank": 1110321,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25524,
    "Rank": 1110355,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25525,
    "Rank": 1110585,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25526,
    "Rank": 1110769,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25527,
    "Rank": 1110936,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25528,
    "Rank": 1111133,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25529,
    "Rank": 1111230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25530,
    "Rank": 1111241,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth DU Dental College and Hospt., Pune,Bharati Vidyapeeth (Deemed to be University) Dental College and Hospital, Pune, Maharashtra, 411043",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25531,
    "Rank": 1111452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25532,
    "Rank": 1111490,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25533,
    "Rank": 1111799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25534,
    "Rank": 1112016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25535,
    "Rank": 1112192,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25536,
    "Rank": 1112209,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25537,
    "Rank": 1112826,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25538,
    "Rank": 1113147,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25539,
    "Rank": 1113282,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25540,
    "Rank": 1113624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25541,
    "Rank": 1114035,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25542,
    "Rank": 1114360,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25543,
    "Rank": 1114945,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25544,
    "Rank": 1115392,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25545,
    "Rank": 1115406,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25546,
    "Rank": 1115439,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25547,
    "Rank": 1115442,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25548,
    "Rank": 1115517,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25549,
    "Rank": 1115659,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25550,
    "Rank": 1115730,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25551,
    "Rank": 1115775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "BVDU Dental College and Hospital, Sangli,Sangli-Miraj Road, Wanlesswadi Sangli., Maharashtra, 416414",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25552,
    "Rank": 1115953,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25553,
    "Rank": 1116366,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25554,
    "Rank": 1116427,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25555,
    "Rank": 1116599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25556,
    "Rank": 1116818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25557,
    "Rank": 1117414,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25558,
    "Rank": 1117573,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25559,
    "Rank": 1118070,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25560,
    "Rank": 1118634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25561,
    "Rank": 1119463,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25562,
    "Rank": 1119750,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25563,
    "Rank": 1119827,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25564,
    "Rank": 1120646,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INST. MEDICAL SCIENCES, HASSAN,K R PURAM, BEHIND DC OFFICE,HASSAN, Karnataka, 573201",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25565,
    "Rank": 1121764,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25566,
    "Rank": 1121781,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Med. Inst. and Res. Centre, Sumandeep Vidyapeeth,Sumandeep Vidyapeeth Deemed to be University Campus, At. Po.Piparia, Tal.Waghodia,\nDist.Vadodara, Gujarat, 391760",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25567,
    "Rank": 1122591,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25568,
    "Rank": 1122619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25569,
    "Rank": 1122904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25570,
    "Rank": 1122951,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25571,
    "Rank": 1123083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25572,
    "Rank": 1123219,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25573,
    "Rank": 1123317,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25574,
    "Rank": 1123563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25575,
    "Rank": 1123688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25576,
    "Rank": 1123714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25577,
    "Rank": 1123745,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25578,
    "Rank": 1123861,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25579,
    "Rank": 1123897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25580,
    "Rank": 1124037,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25581,
    "Rank": 1124444,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25582,
    "Rank": 1124456,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25583,
    "Rank": 1124457,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25584,
    "Rank": 1125351,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25585,
    "Rank": 1125543,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25586,
    "Rank": 1125560,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25587,
    "Rank": 1125603,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25588,
    "Rank": 1125637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25589,
    "Rank": 1125794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25590,
    "Rank": 1125798,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25591,
    "Rank": 1125887,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25592,
    "Rank": 1127150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25593,
    "Rank": 1127230,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25594,
    "Rank": 1127426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25595,
    "Rank": 1127664,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25596,
    "Rank": 1127787,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25597,
    "Rank": 1127867,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25598,
    "Rank": 1127963,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25599,
    "Rank": 1128096,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25600,
    "Rank": 1128116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25601,
    "Rank": 1128358,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25602,
    "Rank": 1128509,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25603,
    "Rank": 1128811,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25604,
    "Rank": 1129011,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25605,
    "Rank": 1129017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25606,
    "Rank": 1129089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25607,
    "Rank": 1129540,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25608,
    "Rank": 1129814,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25609,
    "Rank": 1129852,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25610,
    "Rank": 1129948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25611,
    "Rank": 1129981,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25612,
    "Rank": 1130598,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25613,
    "Rank": 1130720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25614,
    "Rank": 1130985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25615,
    "Rank": 1131503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25616,
    "Rank": 1131568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25617,
    "Rank": 1131939,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25618,
    "Rank": 1132048,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25619,
    "Rank": 1132139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25620,
    "Rank": 1132182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25621,
    "Rank": 1132727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25622,
    "Rank": 1132801,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25623,
    "Rank": 1132897,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25624,
    "Rank": 1132975,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25625,
    "Rank": 1133055,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25626,
    "Rank": 1133465,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25627,
    "Rank": 1133483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25628,
    "Rank": 1134453,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25629,
    "Rank": 1134494,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25630,
    "Rank": 1134525,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25631,
    "Rank": 1134534,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25632,
    "Rank": 1134613,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25633,
    "Rank": 1134685,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25634,
    "Rank": 1135679,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25635,
    "Rank": 1136499,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25636,
    "Rank": 1137216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25637,
    "Rank": 1137271,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25638,
    "Rank": 1137299,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25639,
    "Rank": 1137498,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25640,
    "Rank": 1137604,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Dental College and Hosp. Pune,Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, 411018",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25641,
    "Rank": 1137640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25642,
    "Rank": 1138051,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25643,
    "Rank": 1138090,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25644,
    "Rank": 1138447,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25645,
    "Rank": 1138599,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25646,
    "Rank": 1138648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25647,
    "Rank": 1138696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25648,
    "Rank": 1138727,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25649,
    "Rank": 1138739,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25650,
    "Rank": 1138946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25651,
    "Rank": 1139019,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25652,
    "Rank": 1139208,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25653,
    "Rank": 1139258,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MANAV RACHNA DENTAL COLLEGE FARIDABAD,SECTOR - 43, DELHI SURAJKUND ROAD FARIDABAD, Haryana, 121004",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25654,
    "Rank": 1139677,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25655,
    "Rank": 1139783,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25656,
    "Rank": 1139801,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25657,
    "Rank": 1139914,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25658,
    "Rank": 1140182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25659,
    "Rank": 1140489,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25660,
    "Rank": 1140549,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25661,
    "Rank": 1140818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25662,
    "Rank": 1141423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25663,
    "Rank": 1141590,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25664,
    "Rank": 1141612,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25665,
    "Rank": 1141686,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25666,
    "Rank": 1141824,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25667,
    "Rank": 1142256,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25668,
    "Rank": 1142794,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25669,
    "Rank": 1142859,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25670,
    "Rank": 1142934,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25671,
    "Rank": 1143081,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25672,
    "Rank": 1143609,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25673,
    "Rank": 1144159,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Host., Bhubaneswar,K 8 Kalinga Nagar Po Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25674,
    "Rank": 1144292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25675,
    "Rank": 1144430,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25676,
    "Rank": 1144551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25677,
    "Rank": 1144761,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25678,
    "Rank": 1144966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25679,
    "Rank": 1145102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25680,
    "Rank": 1145211,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25681,
    "Rank": 1145331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25682,
    "Rank": 1145482,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25683,
    "Rank": 1145858,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25684,
    "Rank": 1145870,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25685,
    "Rank": 1146369,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25686,
    "Rank": 1146916,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25687,
    "Rank": 1147433,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25688,
    "Rank": 1147550,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25689,
    "Rank": 1147907,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25690,
    "Rank": 1148532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25691,
    "Rank": 1149893,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP,AHMEDABAD,NEW CIVIL HOSPITAL CAMPUS, ASARWA, AHMEDABAD, Gujarat, 380016",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25692,
    "Rank": 1149901,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25693,
    "Rank": 1149924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25694,
    "Rank": 1149931,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25695,
    "Rank": 1149938,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "BV Deemed Uni. Med. College and Hos., Sangli,Sangli Miraj Road Wanlesswadi Sangli Maharashtra, Maharashtra, 416416",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25696,
    "Rank": 1150117,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25697,
    "Rank": 1150156,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25698,
    "Rank": 1150347,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25699,
    "Rank": 1150560,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25700,
    "Rank": 1150720,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25701,
    "Rank": 1150819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25702,
    "Rank": 1151191,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25703,
    "Rank": 1151212,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25704,
    "Rank": 1151589,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25705,
    "Rank": 1151661,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25706,
    "Rank": 1151967,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25707,
    "Rank": 1152835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25708,
    "Rank": 1153150,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25709,
    "Rank": 1153191,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25710,
    "Rank": 1154413,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25711,
    "Rank": 1154493,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25712,
    "Rank": 1154944,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25713,
    "Rank": 1154964,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25714,
    "Rank": 1155244,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25715,
    "Rank": 1155688,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25716,
    "Rank": 1156056,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25717,
    "Rank": 1156224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25718,
    "Rank": 1156273,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25719,
    "Rank": 1156307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25720,
    "Rank": 1156436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25721,
    "Rank": 1157735,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25722,
    "Rank": 1158239,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25723,
    "Rank": 1158555,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25724,
    "Rank": 1158618,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25725,
    "Rank": 1158970,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25726,
    "Rank": 1159142,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25727,
    "Rank": 1159420,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25728,
    "Rank": 1159873,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25729,
    "Rank": 1160132,
    "Allotted Quota": "Open Seat Quota",
    "Allotted Institute": "Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Faculty of Dentistry Jamia Millia Islamia A Central University Maulana Mohammad Ali Jauhar Marg, Delhi (NCT), 110025",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25730,
    "Rank": 1160149,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25731,
    "Rank": 1160343,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25732,
    "Rank": 1160368,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25733,
    "Rank": 1160426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25734,
    "Rank": 1160643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25735,
    "Rank": 1160654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25736,
    "Rank": 1160669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25737,
    "Rank": 1160743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "EWS",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25738,
    "Rank": 1160760,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25739,
    "Rank": 1160833,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25740,
    "Rank": 1160871,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25741,
    "Rank": 1161087,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25742,
    "Rank": 1161224,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25743,
    "Rank": 1161322,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25744,
    "Rank": 1161581,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25745,
    "Rank": 1161584,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25746,
    "Rank": 1161587,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25747,
    "Rank": 1161726,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25748,
    "Rank": 1161774,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25749,
    "Rank": 1161920,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, BILASP,GOND PARA, SADAR BAZAAR BILASPUR - 495001 (CG), Chhattisgarh, 495001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25750,
    "Rank": 1162062,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Medical College DU, Tumkur,SRI SIDDHARTHA MEDICAL COLLEGE\nB.H. ROAD, AGALAKOTE\nTUMKUR, Karnataka, 572107",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25751,
    "Rank": 1162095,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25752,
    "Rank": 1162789,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, WANAPARTHY ,Marrikunta, Pebbair Road, Wanaparthy District, Telangana state., Telangana, 509103",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25753,
    "Rank": 1163943,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25754,
    "Rank": 1164022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25755,
    "Rank": 1164066,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25756,
    "Rank": 1164135,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25757,
    "Rank": 1164658,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25758,
    "Rank": 1165157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "General",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25759,
    "Rank": 1165728,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25760,
    "Rank": 1165851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Medinirai Medical College (previously Known as Palamu Medical College), Palamu,Pokhraha Khurd, P.O- Rajwadih, P.S- Medininagar, Dist. Palamu, Jharkhand, 822118",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25761,
    "Rank": 1166236,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25762,
    "Rank": 1166284,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25763,
    "Rank": 1166359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25764,
    "Rank": 1166553,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25765,
    "Rank": 1166629,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25766,
    "Rank": 1166721,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE, Jhalawar ,NH-52, KOTA ROAD,JHALAWAR,RAJASTHAN, Rajasthan, 326001",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25767,
    "Rank": 1166812,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25768,
    "Rank": 1166867,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25769,
    "Rank": 1167331,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25770,
    "Rank": 1167431,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25771,
    "Rank": 1167646,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25772,
    "Rank": 1167776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25773,
    "Rank": 1168384,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25774,
    "Rank": 1169378,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25775,
    "Rank": 1170980,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25776,
    "Rank": 1171138,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25777,
    "Rank": 1171380,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25778,
    "Rank": 1171506,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,Rampura, NH-21,Sever Road,Bharatpur,Rajasthan, Rajasthan, 321001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25779,
    "Rank": 1171534,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25780,
    "Rank": 1171601,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25781,
    "Rank": 1172843,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25782,
    "Rank": 1173080,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25783,
    "Rank": 1173332,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25784,
    "Rank": 1173717,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25785,
    "Rank": 1174091,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25786,
    "Rank": 1174207,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25787,
    "Rank": 1174336,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25788,
    "Rank": 1175074,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VELS MEDICAL COLLEGE & HOSPITAL,12-123, Velan Nagar, Manjankaranai Village, Periyapalayam Road, Uthukottai Taluk, Tiruvallur Dist., Tamil Nadu, 601102",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25789,
    "Rank": 1176081,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25790,
    "Rank": 1176634,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25791,
    "Rank": 1176643,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25792,
    "Rank": 1176990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25793,
    "Rank": 1177404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chikkamagaluru Institute of Medical Sciences,DIRECTOR CHIKKAMAGALURU INSTITUTE OF MEDICAL SCIENCES ARALAGUPPE MALLEGOWDA DISTRICT HOSPITAL\nTEG, Karnataka, 577101",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25794,
    "Rank": 1177657,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25795,
    "Rank": 1177904,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25796,
    "Rank": 1178630,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25797,
    "Rank": 1178703,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "MM College of Dental Scie. and Res., Mullana,M.M. COLLEGE OF DENTAL SCIENCES AND RESEARCH, MULLANA, AMBALA, HARYANA, Haryana, 133207",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25798,
    "Rank": 1178776,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25799,
    "Rank": 1179415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25800,
    "Rank": 1179627,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25801,
    "Rank": 1180842,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25802,
    "Rank": 1181120,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25803,
    "Rank": 1181741,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25804,
    "Rank": 1182786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25805,
    "Rank": 1183139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25806,
    "Rank": 1183786,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25807,
    "Rank": 1183900,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25808,
    "Rank": 1184341,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25809,
    "Rank": 1184497,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25810,
    "Rank": 1184766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Dental College, Thrissur,MEDICAL COLLEGE(P O) THRISSUR, Kerala, 680596",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25811,
    "Rank": 1185483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25812,
    "Rank": 1185669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25813,
    "Rank": 1186089,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25814,
    "Rank": 1187350,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25815,
    "Rank": 1187496,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25816,
    "Rank": 1188067,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Lady Hardinge Medical College, New Delhi,Lady Hardinge Medical College, Shaheed Bhagat Singh Marg, New Delhi, Delhi (NCT), 110001 (Female Seat only )",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 15 )"
  },
  {
    "SNo": 25817,
    "Rank": 1189305,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25818,
    "Rank": 1189312,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25819,
    "Rank": 1189336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tinsukia Medical College & Hospital, Tinsukia,Lohari Bangali Gaon, Tinsukia, Assam, 786146",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25820,
    "Rank": 1189359,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25821,
    "Rank": 1189449,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25822,
    "Rank": 1189485,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25823,
    "Rank": 1189669,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25824,
    "Rank": 1190354,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25825,
    "Rank": 1190658,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE NANDYAL,principalgmcnandyala@gmail.com, Andhra Pradesh, 518501",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25826,
    "Rank": 1191483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25827,
    "Rank": 1191847,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Santosh Dental College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25828,
    "Rank": 1192098,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25829,
    "Rank": 1192163,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25830,
    "Rank": 1193552,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25831,
    "Rank": 1193957,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25832,
    "Rank": 1194203,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25833,
    "Rank": 1194624,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25834,
    "Rank": 1194828,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25835,
    "Rank": 1194898,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. DENTAL COLLEGE & HOSP.,MUMBAI,St Georges Hospital Compound P D Mellow road Fort Mumbai, Maharashtra, 400001",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25836,
    "Rank": 1195072,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25837,
    "Rank": 1195503,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25838,
    "Rank": 1196171,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25839,
    "Rank": 1198255,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25840,
    "Rank": 1198436,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25841,
    "Rank": 1200174,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25842,
    "Rank": 1202017,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25843,
    "Rank": 1202163,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25844,
    "Rank": 1203085,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25845,
    "Rank": 1203670,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25846,
    "Rank": 1204093,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25847,
    "Rank": 1204329,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25848,
    "Rank": 1204663,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25849,
    "Rank": 1206261,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25850,
    "Rank": 1206518,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25851,
    "Rank": 1207121,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25852,
    "Rank": 1208672,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25853,
    "Rank": 1208687,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, ESIC, Kollam,Parippally Kollam 691574, Kerala, 691574",
    "Course": "MBBS",
    "Alloted Category": "EWS\nPwD",
    "Candidate Category": "EWS PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25854,
    "Rank": 1208818,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25855,
    "Rank": 1208954,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25856,
    "Rank": 1209939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medcial College, Gondia,NEAR NEHRU CHOWK, K.T.S. HOSPITAL CAMPUS, GONDIA., Maharashtra, 441601",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25857,
    "Rank": 1210270,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25858,
    "Rank": 1210415,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25859,
    "Rank": 1210547,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS College of Dental Science, Jaipur,Ruhs College of dental science , johtwara road jaipur, Rajasthan, 302016",
    "Course": "BDS",
    "Alloted Category": "Open PwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25860,
    "Rank": 1210924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25861,
    "Rank": 1211254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25862,
    "Rank": 1211285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25863,
    "Rank": 1211468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25864,
    "Rank": 1212287,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25865,
    "Rank": 1212423,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25866,
    "Rank": 1213651,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25867,
    "Rank": 1214262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25868,
    "Rank": 1214363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25869,
    "Rank": 1214535,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25870,
    "Rank": 1215596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25871,
    "Rank": 1215788,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25872,
    "Rank": 1216596,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25873,
    "Rank": 1218016,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25874,
    "Rank": 1219592,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "General PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25875,
    "Rank": 1219782,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25876,
    "Rank": 1219805,
    "Allotted Quota": "All India",
    "Allotted Institute": "SILCHAR MEDICAL COLLEGE, SILCHER,PO Silchar Medical College, Silchar, Assam, 788014",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25877,
    "Rank": 1220266,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25878,
    "Rank": 1220595,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25879,
    "Rank": 1221894,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25880,
    "Rank": 1223262,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25881,
    "Rank": 1223803,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25882,
    "Rank": 1224736,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25883,
    "Rank": 1224840,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25884,
    "Rank": 1225817,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25885,
    "Rank": 1226468,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25886,
    "Rank": 1226937,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25887,
    "Rank": 1227990,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25888,
    "Rank": 1229146,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25889,
    "Rank": 1229467,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25890,
    "Rank": 1229585,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25891,
    "Rank": 1231308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25892,
    "Rank": 1231799,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25893,
    "Rank": 1232132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25894,
    "Rank": 1233740,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25895,
    "Rank": 1234282,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25896,
    "Rank": 1234779,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College and Safdarjung Hospital New Delhi, VMMC,Vardhman Mahavir Medical College And Safdarjung Hospital, New Delhi, Delhi (NCT), 110029",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25897,
    "Rank": 1235023,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25898,
    "Rank": 1235029,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25899,
    "Rank": 1236452,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25900,
    "Rank": 1236532,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25901,
    "Rank": 1239531,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25902,
    "Rank": 1239714,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25903,
    "Rank": 1239821,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25904,
    "Rank": 1240075,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25905,
    "Rank": 1240254,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25906,
    "Rank": 1240326,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25907,
    "Rank": 1240341,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25908,
    "Rank": 1240692,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25909,
    "Rank": 1240917,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25910,
    "Rank": 1240985,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25911,
    "Rank": 1241831,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25912,
    "Rank": 1242286,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25913,
    "Rank": 1242426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25914,
    "Rank": 1243376,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25915,
    "Rank": 1243849,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25916,
    "Rank": 1244061,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25917,
    "Rank": 1244363,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25918,
    "Rank": 1245486,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25919,
    "Rank": 1245516,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25920,
    "Rank": 1246643,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25921,
    "Rank": 1246718,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 2 )"
  },
  {
    "SNo": 25922,
    "Rank": 1247068,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25923,
    "Rank": 1247202,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25924,
    "Rank": 1247637,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25925,
    "Rank": 1248374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25926,
    "Rank": 1248912,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25927,
    "Rank": 1249136,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25928,
    "Rank": 1250292,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25929,
    "Rank": 1251342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25930,
    "Rank": 1251442,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25931,
    "Rank": 1252674,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25932,
    "Rank": 1253260,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25933,
    "Rank": 1253815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi Narayana Inst. of Med. Scien., Puducherry,OSUDU, AGARAM VILLAGE, KOODAPAKKAM POST, VILLIANUR COMMUNE, PUDUCHERRY, Puducherry, 605502",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25934,
    "Rank": 1255079,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25935,
    "Rank": 1255474,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25936,
    "Rank": 1255807,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25937,
    "Rank": 1257006,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25938,
    "Rank": 1257214,
    "Allotted Quota": "All India",
    "Allotted Institute": "JLN IMS, IMPHAL,Prompat, Imphal East, Manipur, Manipur, 795005",
    "Course": "MBBS",
    "Alloted Category": "ST PwD",
    "Candidate Category": "ST PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25939,
    "Rank": 1257483,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25940,
    "Rank": 1258194,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25941,
    "Rank": 1258743,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25942,
    "Rank": 1258746,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25943,
    "Rank": 1260053,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25944,
    "Rank": 1260101,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rural Medical College and PIMS, Loni,At - Loni BK  Tal - Rahata Dist - Ahmednagar\nMaharashtra State, India PIN-413736\n(Near Shird, Maharashtra, 413736",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25945,
    "Rank": 1260558,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25946,
    "Rank": 1260619,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25947,
    "Rank": 1260830,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25948,
    "Rank": 1261284,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR Govt Medical College, Nellore,OPP. TO AC SUBBA REDDY STADIUM DARGAMITTA\nNELLORE\nSPSR NELLORE DISTRICT, ANDHRA PRADESH, Andhra Pradesh, 524004",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25949,
    "Rank": 1262984,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25950,
    "Rank": 1263217,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25951,
    "Rank": 1263364,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25952,
    "Rank": 1263666,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25953,
    "Rank": 1263810,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College, Navi Mumbai,Plot No. 2, Sector 7, Nerul, Navi Mumbai, Maharashtra, Maharashtra, 400706",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25954,
    "Rank": 1264458,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25955,
    "Rank": 1264999,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25956,
    "Rank": 1265895,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25957,
    "Rank": 1266028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25958,
    "Rank": 1267685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Dental College & Hospital, Burdwan,POWER HOUSE PARA, BURDWAN TOWN.\nPURBA BARDHAMAN. W.B- 713101, West Bengal, 713101",
    "Course": "BDS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25959,
    "Rank": 1268157,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25960,
    "Rank": 1268277,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25961,
    "Rank": 1269342,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25962,
    "Rank": 1270116,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25963,
    "Rank": 1271429,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25964,
    "Rank": 1272551,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25965,
    "Rank": 1272815,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25966,
    "Rank": 1274178,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25967,
    "Rank": 1275899,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25968,
    "Rank": 1276351,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25969,
    "Rank": 1277775,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25970,
    "Rank": 1277998,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25971,
    "Rank": 1278052,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25972,
    "Rank": 1278394,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25973,
    "Rank": 1278648,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25974,
    "Rank": 1280374,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25975,
    "Rank": 1281472,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25976,
    "Rank": 1281481,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25977,
    "Rank": 1281678,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25978,
    "Rank": 1282216,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25979,
    "Rank": 1283652,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25980,
    "Rank": 1283692,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25981,
    "Rank": 1284014,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25982,
    "Rank": 1284582,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25983,
    "Rank": 1285845,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25984,
    "Rank": 1286512,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25985,
    "Rank": 1288132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25986,
    "Rank": 1289015,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25987,
    "Rank": 1289484,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25988,
    "Rank": 1290640,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25989,
    "Rank": 1290969,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25990,
    "Rank": 1291022,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25991,
    "Rank": 1291152,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25992,
    "Rank": 1291334,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25993,
    "Rank": 1291426,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25994,
    "Rank": 1291464,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25995,
    "Rank": 1293225,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25996,
    "Rank": 1293310,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25997,
    "Rank": 1294819,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25998,
    "Rank": 1295528,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 25999,
    "Rank": 1297196,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26000,
    "Rank": 1297492,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT DENTAL COLLEGE Kannur,Pariyaram Medical College P O, Kannur, Kerala- 670503., Kerala, 670503",
    "Course": "BDS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26001,
    "Rank": 1298359,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26002,
    "Rank": 1299746,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26003,
    "Rank": 1301132,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26004,
    "Rank": 1303308,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26005,
    "Rank": 1305872,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26006,
    "Rank": 1305888,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26007,
    "Rank": 1305890,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26008,
    "Rank": 1307028,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26009,
    "Rank": 1307030,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26010,
    "Rank": 1307141,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26011,
    "Rank": 1308088,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26012,
    "Rank": 1308841,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26013,
    "Rank": 1309574,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26014,
    "Rank": 1309701,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26015,
    "Rank": 1309752,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26016,
    "Rank": 1310568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26017,
    "Rank": 1311447,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26018,
    "Rank": 1311696,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26019,
    "Rank": 1312500,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26020,
    "Rank": 1313307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26021,
    "Rank": 1313517,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26022,
    "Rank": 1313621,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26023,
    "Rank": 1316976,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26024,
    "Rank": 1317661,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MOHAN KUMARAMANGALAM M.C.,SALEM,MAJEERA KOLLAPPATTI, Salem\nsteel Plant Road Salem 636030 TamilNadu, Tamil Nadu, 636030",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26025,
    "Rank": 1317994,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26026,
    "Rank": 1318946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "K M Shah Dental Collegel, SumanDeep Vidyapeeth Vadodara,Sumandeep Vidyapeeth Piparia Waghodia Vadodara Gujarat, Gujarat, 391760",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26027,
    "Rank": 1319654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26028,
    "Rank": 1319856,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26029,
    "Rank": 1319924,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26030,
    "Rank": 1319984,
    "Allotted Quota": "Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota",
    "Allotted Institute": "Maulana Azad Medical College, New Delhi,2 Bahadur Shah Zafar Marg New Delhi, Delhi (NCT), 110002",
    "Course": "MBBS",
    "Alloted Category": "OBC",
    "Candidate Category": "OBC",
    "Remarks": "Allotted( CW Rank\n: 7 )"
  },
  {
    "SNo": 26031,
    "Rank": 1320083,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26032,
    "Rank": 1320358,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Med. College and Res. Inst., Chennai,No. One Ramachandra Nagar, Porur, Chennai., Tamil Nadu, 600116",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26033,
    "Rank": 1322158,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26034,
    "Rank": 1322225,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College, Mangalore,University Road Deralakatte Mangaluru, Karnataka, 575018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26035,
    "Rank": 1323381,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26036,
    "Rank": 1324182,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26037,
    "Rank": 1324713,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26038,
    "Rank": 1324844,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26039,
    "Rank": 1325176,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26040,
    "Rank": 1325879,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26041,
    "Rank": 1327417,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26042,
    "Rank": 1328919,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26043,
    "Rank": 1330036,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26044,
    "Rank": 1330568,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26045,
    "Rank": 1330714,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sree Balaji Dental College and Hospital, Chennai,VELACHERY MAIN ROAD, NARAYANAPURAM, PALLIKARANAI, CHENNAI - 600 100, Tamil Nadu, 600100",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26046,
    "Rank": 1330977,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "ST",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26047,
    "Rank": 1332034,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26048,
    "Rank": 1332229,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26049,
    "Rank": 1332973,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and RI, Chennai,SBV Chennai Campus, Shri Sathya Sai Nagar, Ammapettai, Chennai, Tamil Nadu, 603108",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26050,
    "Rank": 1333041,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26051,
    "Rank": 1333303,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26052,
    "Rank": 1338958,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26053,
    "Rank": 1340742,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26054,
    "Rank": 1340898,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26055,
    "Rank": 1340946,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26056,
    "Rank": 1341139,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26057,
    "Rank": 1341228,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26058,
    "Rank": 1341358,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26059,
    "Rank": 1342780,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26060,
    "Rank": 1343351,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Graphic Era Institute Of Medical Science,Graphic Era Institute of Medical Sciences 16th Milestone Chakrata Road\nDehradun Uttarakhand\n2480, Uttarakhand, 248007",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26061,
    "Rank": 1343361,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26062,
    "Rank": 1343948,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26063,
    "Rank": 1345018,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26064,
    "Rank": 1345545,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26065,
    "Rank": 1346762,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMERS Medical College, Morbi,Gibson middle school, Opp railway station, Morbi, Gujarat, 363641",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26066,
    "Rank": 1349270,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College, Hubballi,KLE Jagadguru Gangadhar\nMahaswamigalu Moorusavirmath Medical College  Gabbur Cross Hubballi, Karnataka, 580028",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26067,
    "Rank": 1352854,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26068,
    "Rank": 1353214,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26069,
    "Rank": 1353253,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "B.L.D.E University, Bijapur,SMT BANGARAMMA SAJJAN CAMPUS B M PATIL ROAD VIJAYAPURA KARNATAKA, Karnataka, 586103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26070,
    "Rank": 1353609,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT.MEDICAL COLLEGE, AMRITSAR,CIRCULAR ROAD, AMRITSAR-143001,\nPunjab, 143001",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26071,
    "Rank": 1353915,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26072,
    "Rank": 1354766,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26073,
    "Rank": 1355184,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26074,
    "Rank": 1355563,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Indira Gandhi Institute of Dental Sciences, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam,, Puducherry, 607402",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26075,
    "Rank": 1356102,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26076,
    "Rank": 1357285,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26077,
    "Rank": 1359308,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26078,
    "Rank": 1359572,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26079,
    "Rank": 1360562,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26080,
    "Rank": 1361154,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26081,
    "Rank": 1361654,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26082,
    "Rank": 1361966,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26083,
    "Rank": 1362046,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26084,
    "Rank": 1365365,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26085,
    "Rank": 1367264,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26086,
    "Rank": 1367367,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26087,
    "Rank": 1368473,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26088,
    "Rank": 1368477,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26089,
    "Rank": 1374262,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26090,
    "Rank": 1374479,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26091,
    "Rank": 1375242,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26092,
    "Rank": 1376698,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26093,
    "Rank": 1377061,
    "Allotted Quota": "All India",
    "Allotted Institute": "AGARTALA GOVT. MEDICAL COLLEGE,AGARTALA,Kunjaban, Agartala, Tripura West, Tripura, 799006",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26094,
    "Rank": 1377307,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26095,
    "Rank": 1381227,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26096,
    "Rank": 1382887,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Siddhartha Academy T Begur,SRI SIDDHARTHA INSTITUTE OF MEDICAL SCIENCES AND  RESEARCH CENTRE T BEGUR NELAMANGALA TALUK\nBANGALOR, Karnataka, 562123",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26097,
    "Rank": 1383020,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26098,
    "Rank": 1383470,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "SATHYABAMA UNIV. Dental College and Hospt. Chennai,JEPPIAAR NAGAR RAJIV GANDHI SALAI, CHENNAI, Tamil Nadu, 600119",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26099,
    "Rank": 1383471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Mahasamund Chhattisgarh,In front of Sai temple raipur road village kharora Mahasamund, Chhattisgarh, 493445",
    "Course": "MBBS",
    "Alloted Category": "SC PwD",
    "Candidate Category": "SC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26100,
    "Rank": 1385738,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Thaimoogambigai Dental College and Hsopt. Chennai,Golden George Nagar, Mogappair, Chennai 600 107, Tamil Nadu, 600107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26101,
    "Rank": 1386421,
    "Allotted Quota": "All India",
    "Allotted Institute": "NAIR HOSP DENTAL COLLEGE & HOSP,MUMBAI,Dr. A.L. Nair Road, Mumbai Central,\nMumbai - 400 008, Maharashtra, 400008",
    "Course": "BDS",
    "Alloted Category": "OBC\nPwD",
    "Candidate Category": "OBC PwD",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26102,
    "Rank": 1387310,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SDU Medical College, Kolar,SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,\nKOLAR, Karnataka, 563103",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26103,
    "Rank": 1388235,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Kalinga Institute of Dental Sciences, Bhubaneswar,CAMPUS-5 KUSHABHADRA CAMPUS\nP.O-KIIT BHUBANESWAR ODISHA, Odisha, 751024",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26104,
    "Rank": 1389388,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad,No.1, Santosh Nagar, Ghaziabad, NCR Delhi, Uttar Pradesh, 201009",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "SC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26105,
    "Rank": 1389425,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Amrita School of Medicine Faridabad,Mata Amritanandmayi Marg, Sector-88, Faridabad, Haryana, 121002",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26106,
    "Rank": 1390814,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Dr. DY Patil Medical College and Hospt., Pune,Dr. D. Y. Patil Medical College, Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26107,
    "Rank": 1391199,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI LALITHAMBIGAI MEDICAL COLLEGE & HOSPITAL,Faculty of Medicine - Sri\nLalithambigai Medical College and Hospital,\n1, Periyar EVR High Road, Ada, Tamil Nadu, 600095",
    "Course": "MBBS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26108,
    "Rank": 1392756,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "VMS Dental College, Salem,Sankari Main Road NH-47  Ariyanoor,  Salem., Tamil Nadu, 636308",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  },
  {
    "SNo": 26109,
    "Rank": 1394835,
    "Allotted Quota": "Deemed/Paid Seats Quota",
    "Allotted Institute": "Sharad Pawar Dental College and Hospital, Wardha,Sawangi (Meghe), Wardha, Maharashtra State, INDIA, Maharashtra, 442107",
    "Course": "BDS",
    "Alloted Category": "Open",
    "Candidate Category": "OBC",
    "Remarks": "Allotted"
  }
]