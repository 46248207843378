import React from 'react';
import Sidebar from './sidebar';

const CourseandFee = (props) => {
  return (
    <div className="overflow-x-auto mt-4 bg-gray-200">
      {/* Table Section */}
      <div className="w-2/10 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-xl font-semibold mb-4">{props.CollegeName ? `${props.CollegeName} Fees & Eligibility` : 'College Fees & Eligibility'}</h2>

        <div className="overflow-x-auto">
          {props.Course1 || props.Course2 || props.Course3 || props.Course4 ? (
            <table className="min-w-full table-auto text-left border-collapse border border-gray-300">
              <thead>
                <tr className="bg-blue-100">
                  <th className="border px-4 py-2">Course</th>
                  <th className="border px-4 py-2">Fees</th>
                  <th className="border px-4 py-2">Eligibility</th>
                  <th className="border px-4 py-2">Application Date</th>
                </tr>
              </thead>
              <tbody>
                {props.Course1 && (
                  <tr>
                    <td className="border px-4 py-2">{props.Course1}</td>
                    <td className="border px-4 py-2">{props.Course1Fee}</td>
                    <td className="border px-4 py-2">{props.Course1Eligibility}</td>
                    <td className="border px-4 py-2">{props.Course1ApplicationDeadline}</td>
                  </tr>
                )}
                {props.Course2 && (
                  <tr>
                    <td className="border px-4 py-2">{props.Course2}</td>
                    <td className="border px-4 py-2">{props.Course2Fee}</td>
                    <td className="border px-4 py-2">{props.Course2Eligibility}</td>
                    <td className="border px-4 py-2">{props.Course2ApplicationDeadline}</td>
                  </tr>
                )}
                {props.Course3 && (
                  <tr>
                    <td className="border px-4 py-2">{props.Course3}</td>
                    <td className="border px-4 py-2">{props.Course3Fee}</td>
                    <td className="border px-4 py-2">{props.Course3Eligibility}</td>
                    <td className="border px-4 py-2">{props.Course3ApplicationDeadline}</td>
                  </tr>
                )}
                {props.Course4 && (
                  <tr>
                    <td className="border px-4 py-2">{props.Course4}</td>
                    <td className="border px-4 py-2">{props.Course4Fee}</td>
                    <td className="border px-4 py-2">{props.Course4Eligibility}</td>
                    <td className="border px-4 py-2">{props.Course4ApplicationDeadline}</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-600">No course fee data is available.</p>
          )}
        </div>
      </div>

      {/* Advertisement Section */}
      {/* <Sidebar/> */}
    </div>
  );
};

export default CourseandFee;
