import React from 'react';
import { Link } from 'react-router-dom';

const EngineeringBranch = ({
  isDropdownOpen,
  handleDropdownToggle,
  activeSubmenu,
  handleMouseEnter,
  handleMouseLeave
}) => {
  // Function to close dropdown after selecting an option
  const handleOptionSelect = () => {
    handleDropdownToggle(''); // Closing the dropdown by resetting the open state
  };

  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle('Engineering')}
        className="text-white"
      >
        ENGINEERING {isDropdownOpen === 'Engineering' ? '▲' : '▼'}
      </button>

      {isDropdownOpen === 'Engineering' && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter('TopRankedColleges')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Top Ranked Colleges
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('Popular Courses')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Popular Courses
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('Exams')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Exams
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('Colleges By Location')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Colleges By Location
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('College Predictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked Colleges */}
          {activeSubmenu === 'TopRankedColleges' && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter('TopRankedColleges')}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs">
                  <Link to="/TopEngineering" onClick={handleOptionSelect}>Top Engineering Colleges in India</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopPrivate" onClick={handleOptionSelect}>Top Private Engineering Colleges in India</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopIIT" onClick={handleOptionSelect}>Top IITs in India</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopNIT" onClick={handleOptionSelect}>Top NITs in India</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopKarnataka" onClick={handleOptionSelect}>Top Engineering Colleges in Karnataka</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopHyderabad" onClick={handleOptionSelect}>Top Engineering Colleges in Hyderabad</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopPune" onClick={handleOptionSelect}>Top Engineering Colleges in Pune</Link>
                </li>
              </ul>
            </div>
          )}

          <div className="w-[200px] ml-auto">
            <h4 className="text-xm font-bold mb-2">Featured Colleges</h4>
            <ul className="space-y-2">
              <li className="text-xs hover:bg-gray-200 py-2 transition">
                NHCE Bangalore - New Horizon College of Engineering
              </li>
              <div className="flex justify-center items-center p-4 bg-gray-100 rounded-lg shadow">
                <img src=""  className="max-w-full h-auto rounded-md" />
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default EngineeringBranch;
