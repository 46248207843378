import React from "react";

const AdmissionChips = () => {
  // const admissions = [
  //   "B Ed Admission 2024",
  //   "MBA Admission 2024",
  //   "MBBS Admission 2024",
  //   "BA Admission 2024",
  //   "M Tech Admission 2024",
  //   "PhD Admission 2024",
  //   "LLB Admission 2024",
  //   "D El Ed Admission 2024",
  //   "BSc Admission 2024",
  //   "B Pharmacy Admission 2024",
  // ];

  // return (
  //   <div className="bg-gray-50  flex items-start justify-center py-6 sm:hidden">
  //     <div className="text-center">
  //       <h2 className="text-lg font-semibold mb-2">Admission 2024</h2>
  //       <div className="grid grid-cols-2 gap-2 px-2">
  //         {admissions.map((item, index) => (
  //           <div
  //             key={index}
  //             className="border rounded-full py-1 px-2 text-center text-xs font-medium text-gray-700"
  //           >
  //             {item}
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AdmissionChips;
