import React from 'react';

const LatestNotificationSidebar = (props) => {
  return (
    <aside className="  p-4">
    {/* Advertisement Box 1 */}
{props.advertisment3heading && props.advertisment3photo && (
  <div className="bg-white p-4 shadow-md rounded-md mb-10">
    <p className="text-gray-700">{props.advertisment3heading}</p>
    <img src={props.advertisment3photo} className="w-full h-auto rounded-md" />
  </div>
)}

{/* Advertisement Box 2 */}
{props.advertisment4heading && props.advertisment4photo && (
  <div className="bg-gray-200 p-4 text-center mb-10 rounded-md">
    <p className="text-gray-700 p-2">{props.advertisment4heading}</p>
    <img src={props.advertisment4photo} className="w-full h-auto rounded-md" />
  </div>
)}

{/* Related News Section */}
{(props.relatednews1 || props.relatednews2 || props.relatednews3) && (
  <div className="bg-white p-4 shadow rounded-md mb-10">
    <h3 className="font-bold text-lg mb-4">Related News</h3>
    <ul className="list-disc list-inside text-gray-700">
      {props.relatednews1 && <li><a href="#" className="text-blue-500">{props.relatednews1}</a></li>}
      {props.relatednews2 && <li><a href="#" className="text-blue-500">{props.relatednews2}</a></li>}
      {props.relatednews3 && <li><a href="#" className="text-blue-500">{props.relatednews3}</a></li>}
    </ul>
  </div>
)}

{/* Live Updates Section */}
{props.liveupdatepara && (
  <div className="bg-white p-4 shadow rounded-lg mb-10">
    <h3 className="font-bold text-lg mb-4">Live Updates</h3>
    <p className="text-gray-700">{props.liveupdatepara}</p>
  </div>
)}

      {/* Video 1 */}
      <div className="mb-10">
          <iframe
            width="100%"
            height="200"
            src={props.videoembedsrc1}
            title="YouTube video"
            className="rounded-md"
          ></iframe>
        </div>

        {/* Video 2 */}
        <div className="mb-10">
          <iframe
            width="100%"
            height="200"
            src={props.videoembedsrc2}
            title="YouTube video"
            className="rounded-md"
          ></iframe>
        </div>
        {props.advertisment3heading && props.advertisment3photo && (
  <div className="bg-white p-4 shadow-md rounded-md mb-10">
    <p className="text-gray-700">{props.advertisment3heading}</p>
    <img src={props.advertisment3photo} className="w-full h-auto rounded-md" />
  </div>
)}

{/* Advertisement Box 2 */}
{props.advertisment4heading && props.advertisment4photo && (
  <div className="bg-gray-200 p-4 text-center mb-10 rounded-md">
    <p className="text-gray-700 p-2">{props.advertisment4heading}</p>
    <img src={props.advertisment4photo} className="w-full h-auto rounded-md" />
  </div>
)}

     

    
    </aside>
  );
}



export default LatestNotificationSidebar;


