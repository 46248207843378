// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate

// const ExamResult = (props) => {
//   const [openFaq, setOpenFaq] = useState(null);
//   const navigate = useNavigate(); // Initialize useNavigate
//   const {examName}= useParams();

//   const toggleFaq = (index) => {
//     setOpenFaq(openFaq === index ? null : index);
//   };

//   // FAQ content
//   const faqs = [
//     {
//       question: "How much percentile is a good score in CAT exam?",
//       answer: "A good score typically falls above 90 percentile.",
//     },
//     {
//       question: "Is only CAT score a factor for admission in IIMs?",
//       answer: "No, other factors include academic record, work experience, etc.",
//     },
//     {
//       question: "From where can I download CAT result?",
//       answer: "You can download the CAT result from the official website.",
//     },
//     {
//       question: "I appeared for CAT exam in the second slot. Will my result be declared on the same day as that of the first slot?",
//       answer: "Yes, results for all slots are declared on the same day.",
//     },
//     {
//       question: "What are the qualifying CAT cutoffs for all the IIMs?",
//       answer: "Cutoffs vary by IIM and are usually above 90 percentile.",
//     },
//     {
//       question: "What is the process of CAT result download?",
//       answer: "Visit the official website, log in, and download your scorecard.",
//     },
//   ];

//   // Steps for downloading the result
//   const downloadSteps = [
//     "Visit the official website (iimcat.ac.in)",
//     "Click on 'CAT 2024 scorecard download'",
//     "Enter CAT 2024 ID and password",
//     "Click on the 'scorecard' tab",
//     "Download the CAT result 2024 PDF",
//   ];

//   // Steps for mobile download
//   const mobileSteps = [
//     "Open a web browser on the smart phone",
//     "Type in the URL of CAT exam: iimcat.ac.in",
//     "Click on 'CAT 2024 scorecard'",
//     "Enter your application number and password",
//     "Click on the scorecard tab to open the document",
//   ];

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.ExamResultHeaderHeading1} {props.ExamResultyear} : {props.ExamResulthheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.ExamResultHeaderadmitcardname}: {props.ExamResultHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.ExamResultHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.ExamResultHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)} // Ensure navigation works
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="p-6 bg-gray-50 text-gray-800">
//         {/* Notification Section */}
//         <div className="bg-white p-6 rounded-lg shadow-md mb-6">
//           <h2 className="text-2xl font-bold text-gray-800 mb-4">
//           {props.ExamResultHeadingName1}
//           </h2>
//           <p className="text-gray-700 mb-4">
//           {props.ExamResultHeadingName1Description1}
//           </p>
//           <p className="text-gray-700 mb-4">
//           {props.ExamResultHeadingName1Description2}
//           </p>
//         </div>

//         {/* How to Download Section */}
//         <div className="bg-white p-6 rounded-lg shadow-md mb-6">
//           <h4 className="text-lg font-bold text-gray-800 mb-4">
//             {props.ExamResultHowtoDownloadresultquestion1}
//           </h4>
//           <p className="text-gray-700 mb-2">
//           {props.ExamResultHowtoDownloadresultquestion1Ans1}
//           </p>
//           <ol className="list-decimal ml-6 text-gray-600 space-y-1">
//             {downloadSteps.map((step, index) => (
//               <li key={index}>{step}</li>
//             ))}
//           </ol>
//         </div>

//         {/* Download on Mobile Section */}
//         <div className="bg-white p-6 rounded-lg shadow-md">
//           <h4 className="text-lg font-bold text-gray-800 mb-4">
//           {props.ExamResultHowtocheckresultquestion2}
//           </h4>
//           <p className="text-gray-700 mb-2">
//           {props.ExamResultHowtoDownloadresultquestion2Ans2}
//           </p>
//           <ol className="list-decimal ml-6 text-gray-600 space-y-1">
//             {mobileSteps.map((step, index) => (
//               <li key={index}>{step}</li>
//             ))}
//           </ol>
//         </div>

//          {/* FAQ Section */}
//          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
//           <h3 className="text-xl font-bold text-gray-800 mb-4">
//             Frequently Asked Questions
//           </h3>
//           {faqs.map((faq, index) => (
//             <div key={index} className="border-b border-gray-200 py-4">
//               <button
//                 onClick={() => toggleFaq(index)}
//                 className="w-full text-left flex justify-between items-center"
//               >
//                 <span className="text-gray-700 font-semibold">
//                   {faq.question}
//                 </span>
//                 <svg
//                   className={`w-6 h-6 transform transition-transform duration-200 ${
//                     openFaq === index ? "rotate-180" : "rotate-0"
//                   }`}
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M19 9l-7 7-7-7"
//                   />
//                 </svg>
//               </button>
//               {openFaq === index && (
//                 <p className="mt-2 text-gray-600">{faq.answer}</p>
//               )}
//             </div>
//           ))}
//         </div>

//       </div>
//     </div>
//   );
// };

// export default ExamResult;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ExamResult = () => {
  const [examData, setExamData] = useState(null);
  const [openFaq, setOpenFaq] = useState(null);
  const navigate = useNavigate();
  const { examName } = useParams();

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  if (!examData) return <div>Loading...</div>;

  return (
    <div className="bg-gray-100 p-8">
      <div className="p-6 rounded-lg shadow-md bg-white">
        <h1 className="text-2xl font-bold text-gray-800 text-left">
          {examData.headerHeading} {examData.year} : {examData.heading1}
        </h1>
        <div className="flex items-center space-x-2 mt-4">
          <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {examData.admitCardName}:{" "}
            {new Date(examData.admitCardDate).toLocaleDateString()}
          </div>
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Papers
            </button> */}
          </div>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {examData.tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-6 bg-gray-50 text-gray-800">
        {/* How to Download Result */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h4 className="text-lg font-bold text-gray-800 mb-4">
            How to Download the Result
          </h4>
          <p className="text-gray-700 mb-2">Follow these steps:</p>
          <ol className="list-decimal ml-6 text-gray-600 space-y-1">
            {examData.downloadSteps.map((step, index) => (
              <li key={index}>{step.step}</li>
            ))}
          </ol>
        </div>

        {/* Download on Mobile Section */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h4 className="text-lg font-bold text-gray-800 mb-4">
            How to Check Result on Mobile
          </h4>
          <p className="text-gray-700 mb-2">Use these steps:</p>
          <ol className="list-decimal ml-6 text-gray-600 space-y-1">
            {examData.mobileSteps.map((step, index) => (
              <li key={index}>{step.step}</li>
            ))}
          </ol>
        </div>

        {/* FAQ Section */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-xl font-bold text-gray-800 mb-4">
            Frequently Asked Questions
          </h3>
          {examData.faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 py-4">
              <button
                onClick={() => toggleFaq(index)}
                className="w-full text-left flex justify-between items-center"
              >
                <span className="text-gray-700 font-semibold">
                  {faq.question}
                </span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 ${
                    openFaq === index ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openFaq === index && (
                <p className="mt-2 text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamResult;
