import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearchClick = () => {
    navigate("/search"); // Navigate to /search without any query
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  return (
    <div>
      {/* For larger screens */}
      <div className="search-container-large" onClick={handleSearchClick}>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search Colleges, Courses, Exams, QnA, & Articles"
          className="search-input"
        />
        <button onClick={handleSearchClick} className="search-button">
          Search
        </button>
      </div>

      {/* For smaller screens, search icon initially */}
      <div className="search-container-small">
        {!showSearchBar ? (
          <FaSearch className="search-icon" onClick={toggleSearchBar} />
        ) : (
          <div className="small-search-bar" onClick={handleSearchClick}>
            <input
              type="text"
              value={query}
              onChange={handleInputChange}
              placeholder="Search..."
              className="small-search-input"
            />
            <button onClick={handleSearchClick} className="small-search-button">
              <FaSearch />
            </button>
          </div>
        )}
      </div>

      <style jsx>{`
        /* Large screen search bar */
        .search-container-large {
          margin: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .search-input {
          padding: 10px;
          width: 700px;
          height: 40px;
          border-radius: 7px 0 0 7px;
          border: 1px solid #ccc;
          pointer-events: none;
        }

        .search-button {
          padding: 10px;
          background-color: black;
          color: white;
          height: 42px;
          width: 100px;
          border-radius: 0 7px 7px 0;
          border: none;
          pointer-events: none;
        }

        /* Small screen - search icon and small search bar */
        .search-container-small {
          display: none;
          text-align: center;
        }

        .search-icon {
          font-size: 24px;
          cursor: pointer;
        }

        .small-search-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .small-search-input {
          padding: 5px;
          width: 150px;
          height: 30px;
          border-radius: 7px 0 0 7px;
          border: 1px solid #ccc;
          pointer-events: none;
        }

        .small-search-button {
          padding: 5px;
          background-color: black;
          color: white;
          height: 32px;
          width: 40px;
          border-radius: 0 7px 7px 0;
          border: none;
          pointer-events: none;
        }

        /* Media Query for smaller screens (710px and below) */
        @media (max-width: 710px) {
          .search-container-large {
            display: none;
          }

          .search-container-small {
            display: block;
          }
        }
      `}</style>
    </div>
  );
};

// Example usage of the SearchBar component
const SearchNav = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <SearchBar />
    </div>
  );
};

export default SearchNav;
