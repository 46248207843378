import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const CollegeRanking = () => {
  const [collegesData, setCollegesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch data from the backend API
    const fetchColleges = async () => {
      try {
        const response = await fetch(
          "https://caderaedu.com/api/college/all"
        );
        const data = await response.json();
        setCollegesData(data.colleges);
        // console.log(data.colleges); // Debugging: check fetched data
        setLoading(false);
      } catch (err) {
        setError("Failed to load college data.");
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };

  return (
    <div className="container mx-auto p-4 mb-3">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h1 className="text-lg md:text-xl font-bold text-center mb-2 md:mb-0">
          Caderaedu Ranking
        </h1>

        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
          {/* Dropdown for ranking year */}
          <select className="bg-gray-200 text-xs md:text-sm text-gray-700 font-semibold py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200">
            <option>Ranking: 2024</option>
          </select>

          {/* "View All Colleges" Link */}
          {/* <a
            href="/all-colleges"
            className="text-blue-500 hover:underline font-semibold text-xs md:text-sm"
          >
            View all Colleges
          </a> */}
        </div>
      </div>

      {/* Agencies buttons */}
      <div className="mb-4 overflow-x-auto whitespace-nowrap">
        {[
          "caderaedu",
          "The Week",
          "NIRF",
          "Outlook",
          "IIRF",
          "TOI",
          "NIRF Innovation",
        ].map((agency, index) => (
          <button
            key={index}
            className="inline-block bg-gray-200 text-xs md:text-sm text-gray-700 font-semibold py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200 mr-2"
          >
            {agency}
          </button>
        ))}
      </div>

      {/* College ranking table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-4 px-2 text-left text-xs md:text-sm">
                College
              </th>
              <th className="py-4 px-2 text-left text-xs md:text-sm">
                Ranking
              </th>
              <th className="py-4 px-2 text-left text-xs md:text-sm">
                Rank Basis
              </th>
            </tr>
          </thead>
          <tbody>
            {collegesData.map((college, index) => (
              <tr
                key={index}
                className={`border-t ${index % 2 != 0 ? "bg-blue-50" : ""}`}
              >
                <td className="py-4 px-2 flex items-left text-xs md:text-sm">
                  {/* College symbol */}
                  <img
                    src={college.logo_link || "N/A"}
                    alt={`${college.name} logo`}
                    className="w-6 h-6 mr-2 md:w-8 md:h-8"
                  />
                  {/* College name with link */}
                  <button
                    className="font-semibold text-left text-blue-400"
                    onClick={() => handleCollegeClick(college.name)}
                  >
                    {college.name || "N/A"}
                  </button>
                </td>
                <td className="py-4 px-2 text-xs md:text-sm">
                  {college.college_rank || "N/A"}
                </td>
                <td className="py-4 px-2 text-xs md:text-sm">
                  {college.rank_basis || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CollegeRanking;
