import React, { useEffect, useState } from "react";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAccept = () => {
    sessionStorage.setItem("cookieConsent", "true");
    setVisible(false);
  };

  const handleReject = () => {
    sessionStorage.setItem("cookieConsent", "false");
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 shadow-lg p-4 z-50 w-full">
      <p className="text-gray-700 text-center">
        We use cookies to enhance your experience. By continuing to visit this
        site, you accept our use of cookies.
      </p>
      <div className="flex flex-col md:flex-row justify-center mt-4">
        <button
          onClick={handleAccept}
          className="w-full md:w-auto bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-700 mb-2 md:mb-0 md:mr-2"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="w-full md:w-auto bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-700"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
