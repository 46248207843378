import React from 'react';

const AdvertiseSection = () => {
  return (
    <a href="https://ecadera.com" target="_blank" rel="noopener noreferrer" className="block w-full">
      <div className="flex flex-col md:flex-row items-center bg-gray-200 p-5 space-y-4 md:space-y-0 md:space-x-4">
        {/* Left Side Image */}
        <div className="flex-shrink-0 w-full md:w-1/3">
          <img
            src="https://th.bing.com/th/id/OIP.3s1197PsCvK0rjfSkXmQRQHaFj?w=222&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
            className="w-full h-32 max-h-32 object-contain" // Ensures whole image fits in the box
            alt="Left Advertisement"
          />
        </div>

        {/* Middle Content */}
        <div className="flex-grow text-center md:text-left">
        <p className="">
  Worried about getting the job? Ecadera is here to take the stress out of job hunting with a 100% Job Guarantee in top MNCs. 
  Our goal is to get you hired, fast and for real.
</p>
<a href="https://ecadera.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 font-bold hover:underline">
  Ab no fikr! Take the first step towards your dream career Join Ecadera.
</a>
        </div>

        {/* Right Side Image */}
        <div className="flex-shrink-0 w-full md:w-1/3">
          <img
            src="https://del1.vultrobjects.com/caderaedu/ECADERA%20%281%29.png"
            className="w-full h-32 max-h-32 object-contain" // Ensures whole image fits in the box
            alt="Right Advertisement"
          />
        </div>
      </div>
    </a>
  );
};

export default AdvertiseSection;
