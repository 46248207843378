import React, { useState, useEffect } from "react"; // Added useEffect import
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// BTech Exams array
const btechExams = [
  {
    id: 1,
    name: "IIMC Entrance Test",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/mass%20media%20exams/iimc.jpeg",
  },
  {
    id: 2,
    name: "JMI Entrance Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/mass%20media%20exams/jmi.jpg",
  },
  {
    id: 3,
    name: "ACJ Entrance Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/mass%20media%20exams/acj%20logo.webp",
  },
];

const MassMediaExams = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExams, setSelectedExams] = useState([]);
  const itemsPerPage = 15;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = btechExams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(btechExams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    if (selectedExams.includes(exam.name)) {
      setSelectedExams(selectedExams.filter((name) => name !== exam.name));
    } else {
      setSelectedExams([...selectedExams, exam.name]);
    }
  };

  const handleNextClick = () => {
    if (selectedExams.length > 0) {
      const selectedExam = selectedExams[0];

      // Update navigation based on selected BTech exam
      if (selectedExam === "IIMC Entrance Test") {
        navigate("/"); // Replace with actual route
      } else if (selectedExam === "JMI Entrance Exam") {
        navigate("/"); // Replace with actual route
      } else if (selectedExam === "ACJ Entrance Exam") {
        navigate("/"); // Replace with actual route
      } else {
        console.log("No valid exam selected for navigation");
      }
    }
  };

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="md:hidden bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        Top Mass Media Exams
      </h2>
      <h3 className="text-left font-bold mb-4 text-black">Select exam :</h3>
      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              <input
                type="checkbox"
                checked={selectedExams.includes(exam.name)}
                className="absolute inset-0 w-full h-full cursor-pointer opacity-0"
              />
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1 text-center">
              {exam.name}
            </p>
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          disabled={selectedExams.length === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MassMediaExams;
