import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LatestNotificationSidebar from './LatestNotificationSidebar';

const LatestNotificationPage = () => {
  const { slug } = useParams(); // Get the slug from URL parameters
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    window.scrollTo(0, 0);
    const fetchNotification = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/latestnotifications/get/${slug}`); // Use slug in the URL
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setNotification(data); // Set the fetched data (object) to state
        console.log(slug);
        console.log(data);
      } catch (error) {
        setError(error.message); // Set error message if fetching fails
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchNotification();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message
  }

  if (!notification) {
    return <div>No notification found</div>; // Handle case when no notification is found
  }

  return (
    <div className="bg-gray-100 min-h-screen p-5">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6 p-4">
        <main className="lg:col-span-2">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">{notification.mainheading}</h1>
            <p className="text-sm text-gray-500 my-2">{notification.author} {notification.datetime}</p>
            <img src={notification.photo} className="w-full h-auto rounded-md my-4" alt="Notification" />
            <section>
              <p className="text-gray-700 mb-4">{notification.paragraph1}</p>
              {notification.advertisement1heading && notification.advertisement1photo && (
                <div className="bg-gray-200 p-6 text-center my-6">
                  <p className="text-gray-700">{notification.advertisement1heading}</p>
                  <img src={notification.advertisement1photo} className="w-full h-auto rounded-md" alt="Advertisement" />
                </div>
              )}
              <h2 className="text-2xl font-bold my-4">{notification.subheading1}</h2>
              <p className="text-gray-700">{notification.paragraph2}</p>
            </section>

            {/* Additional sections based on your object data */}
            <section>
              <h2 className="text-2xl font-bold my-4">{notification.subheading2}</h2>
              <p className="text-gray-700">{notification.paragraph3}</p>
              <h2 className="text-2xl font-bold my-4">{notification.subheading3}</h2>
              <p className="text-gray-700">{notification.paragraph4}</p>
              <h2 className="text-2xl font-bold my-4">{notification.subheading4}</h2>
              <p className="text-gray-700">{notification.paragraph5}</p>
            </section>
          </div>
        </main>

        {/* Sidebar Area */}
        <LatestNotificationSidebar
          advertisment3heading={notification.advertisement3heading}
          advertisment3photo={notification.advertisement3photo}
          advertisment4heading={notification.advertisement4heading}
          advertisment4photo={notification.advertisement4photo}
          relatednews1={notification.relatednews1}
          relatednews2={notification.relatednews2}
          relatednews3={notification.relatednews3}
          liveupdatepara={notification.liveupdatepara}
          videoembedsrc1={notification.videoembedsrc1}
          videoembedsrc2={notification.videoembedsrc2}
        />
      </div>
    </div>
  );
};

export default LatestNotificationPage;
