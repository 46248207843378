import React, { useState } from "react";
import { Blacklogo } from "../../images/index";
import { updateProfile } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  auth,
  sendEmailVerification,
} from "../firebase";

const SignUpPage = ({ isOpen, closePopup, openLoginPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState("");
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false); // New state

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Step 2: Update user profile with full name
      await updateProfile(user, { displayName: fullName });

      // Step 3: Send email verification
      await sendEmailVerification(user);
      setIsVerificationEmailSent(true); // Set state to show the success message
    } catch (error) {
      setError("Signup failed. Please try again.");
      console.log("Error during signup:", error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-blue-200 bg-opacity-20 flex items-center justify-center z-50 ">
      <div className="bg-gray-200 rounded-lg shadow-lg p-6 max-w-md w-full text-center relative">
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <img src={Blacklogo} alt="Logo" className="block mx-auto mb-6 w-24" />
        {!isVerificationEmailSent ? (
          <>
            <h2 className="text-[#68a4a5] text-2xl mb-4">
              Create Your Account
            </h2>
            <p className="text-gray-700 mb-4">Sign up to enjoy our services</p>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <form onSubmit={handleSignUp}>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
                className="w-full p-3 mb-3 border border-gray-300 rounded-xl"
                required
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full p-3 mb-3 border border-gray-300 rounded-xl"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full p-3 mb-4 border border-gray-300 rounded-xl"
                required
              />
              <button className="bg-[#68a4a5] text-white py-2 px-4 rounded-full w-1/2 hover:bg-teal-700 transition-colors">
                Sign Up
              </button>
            </form>
            <p className="mt-4 text-gray-600">
              Already have an account?{" "}
              <a
                href="#"
                className="text-[#68a4a5] hover:underline"
                onClick={openLoginPage}
              >
                Log In
              </a>
            </p>
          </>
        ) : (
          <div>
            <h2 className="text-[#68a4a5] text-2xl mb-4">Verify Your Email</h2>
            <p className="text-gray-700 mb-4">
              A verification email has been sent to <strong>{email}</strong>.
              Please check your inbox and verify your email address before
              logging in.
            </p>
            <button
              className="bg-[#68a4a5] text-white py-2 px-4 rounded-full w-1/2 hover:bg-teal-700 transition-colors"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
