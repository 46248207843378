import React from "react";

const NotFound = () => {
  return (
    <main className="bg-[#d9dad9] text-center py-10">
      <h1 className="text-4xl font-bold text-[#68A4A5]">404 - Page Not Found</h1>
      <p className="text-lg text-[#555] mt-4">
        Oops! The page you are looking for does not exist.
      </p>
    </main>
  );
};

export default NotFound;
