import React, { useState, useEffect } from "react";
import Review from "./Review";
import CourseandFee from "./CourseandFee";
import Admissions from "./Admissions";
import Placement from "./Placement";
// import DistanceEducation from "./DistanceEducation";
import Department from "./Department";
import { ImBookmarks } from "react-icons/im";
import Ranking from "./Ranking";

import Scholarship from "./Scholarship";
import { useParams } from "react-router-dom"; // Import useParams to get the slug
import Facilities from "./Facilities";

import Cutoff from "./Cutoff";
import { PiNumpadFill } from "react-icons/pi";
import FAQ from "./FAQ";
import SeatIntakeTable from "./SeatIntake";

const ClickCollege = () => {
  const [activeTab, setActiveTab] = useState("info"); // Default tab
  const [isExpanded, setIsExpanded] = useState(false);
  const [props, setProps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams(); // Get the slug from the URL
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchColleges = async () => {
  //     try {
  //       const response = await fetch("https://caderaedu.com/api/college/all"); // Adjust the URL if needed

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const data = await response.json();
  //       console.log("Fetched College Data:", data.colleges[0]);
        
  //       // Use collegeData instead of props to avoid accessing state directly
  //       const collegeData = data.colleges[0];
  //       setProps({
  //         LogoLink: collegeData.logo_link,
  //         collegeName: collegeData.name,
  //         collegeLocation: collegeData.location,
  //         collegeRank: collegeData.college_rank,
  //         rankBasis: collegeData.rank_basis,
  //         collegeInfoNewThings: collegeData.clg_info,
  //         aboutCollege: collegeData.abt_clg,
  //         aboutCollegeMore: collegeData.abt_clg_more,
  //         course1: collegeData.course1,
  //         course1Fee: collegeData.course1Fee,
  //         course1Eligibility: collegeData.course1Eligibility,
  //         course1ApplicationDeadline: collegeData.course1ApplicationDeadline,
  //         course2: collegeData.course2,
  //         course2Fee: collegeData.course2Fee,
  //         course2Eligibility: collegeData.course2Eligibility,
  //         course2ApplicationDeadline: collegeData.course2ApplicationDeadline,
  //         course3: collegeData.course3,
  //         course3Fee: collegeData.course3Fee,
  //         course3Eligibility: collegeData.course3Eligibility,
  //         course3ApplicationDeadline: collegeData.course3ApplicationDeadline,
  //         // Add more fields as necessary based on your structure
  //       });
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchColleges();
  // }, []);

  // // Use another useEffect to log props after they are set
  // const { LogoLink, collegeName, collegeLocation, collegeRank } = props || {};
  // useEffect(() => {
  //   if (props) {
  //     console.log("Props:", props);
  //   }
  // }, [props]);
  console.log("Slug:", slug);
  // useEffect(() => {
  //   // Replace with the slug of the college you want to fetch
  //   // Fetch the college data by slug from the API
  //   fetch(`https://caderaedu.com/api/college/get/${slug}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.college) {
  //         setProps(data.college);
  //       } else {
  //         console.error('College data not found');
  //       }
  //     })
  //     .catch(error => console.error('Error fetching college data:', error));
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []); // This effect runs only once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await fetch(`https://caderaedu.com/api/college/get/${slug}`); // Adjust endpoint as necessary
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const backend_data = data.college; // Adjust as necessary
        // Map backend data to frontend variables
        const mappedData = {
          LogoLink: backend_data.logo_link,
          CollegeName: backend_data.name,
          CollegeLocation: backend_data.location,
          collegeRank: backend_data.college_rank,
          rankBasis: backend_data.rank_basis,
          CollegeInfoNewThings: backend_data.clg_info,
          AboutCollege: backend_data.abt_clg,
          AboutCollegeMore: backend_data.abt_clg_more,
          Course1: backend_data.course1,
          Course1Fee: backend_data.course1Fee,
          Course1Eligibility: backend_data.course1Eligibility,
          Course1ApplicationDeadline: backend_data.course1ApplicationDeadline,
          Course2: backend_data.course2,
          Course2Fee: backend_data.course2Fee,
          Course2Eligibility: backend_data.course2Eligibility,
          Course2ApplicationDeadline: backend_data.course2ApplicationDeadline,
          Course3: backend_data.course3,
          Course3Fee: backend_data.course3Fee,
          Course3Eligibility: backend_data.course3Eligibility,
          Course3ApplicationDeadline: backend_data.course3ApplicationDeadline,
          Course4: backend_data.course4,
          Course4Fee: backend_data.course4Fee,
          Course4Eligibility: backend_data.course4Eligibility,
          Course4ApplicationDeadline: backend_data.course4ApplicationDeadline,
          
          DistanceCourseName1: backend_data.distanceCourseName1,
          DistanceCourseFee1: backend_data.distanceCourseFee1,
          DistanceEligiblity1: backend_data.distanceEligibility1,
          DistanceApllicationdate1: backend_data.distanceApplicationDate1,
          DistanceCourseName2: backend_data.distanceCourseName2,
          DistanceCourseFee2: backend_data.distanceCourseFee2,
          DistanceEligiblity2: backend_data.distanceEligibility2,
          DistanceApllicationdate2: backend_data.distanceApplicationDate2,
          DistanceCourseName3: backend_data.distanceCourseName3,
          DistanceCourseFee3: backend_data.distanceCourseFee3,
          DistanceEligiblity3: backend_data.distanceEligibility3,
          DistanceApllicationdate3: backend_data.distanceApplicationDate3,
      
          Placementyear: backend_data.Placementyear,
          PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1: backend_data.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1,
          PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2: backend_data.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2,
          Placementhighestpackagelist: backend_data.Placementhighestpackagelist,
          PlacementAveragepackage: backend_data.PlacementAveragepackage,
          PlacementTopRecruiters: backend_data.PlacementTopRecruiters,
          
          PlacementCompanyName1: backend_data.PlacementCompanyName1,
          PlacementHighestPackage1: backend_data.PlacementHighestPackage1,
          PlacementSector1: backend_data.PlacementSector1,
          PlacementDesignation1: backend_data.PlacementDesignation1,
          PlacementCompanyName2: backend_data.PlacementCompanyName2,
          PlacementHighestPackage2: backend_data.PlacementHighestPackage2,
          PlacementSector2: backend_data.PlacementSector2,
          PlacementDesignation2: backend_data.PlacementDesignation2,
          
           PlacementCompanyName3: backend_data.PlacementCompanyName3,
          PlacementHighestPackage3: backend_data.PlacementHighestPackage3,
          PlacementSector3: backend_data.PlacementSector3,
          PlacementDesignation3: backend_data.PlacementDesignation3,
          PlacementCompanyName4: backend_data.PlacementCompanyName4,
          PlacementHighestPackage4: backend_data.PlacementHighestPackage4,
          PlacementSector4: backend_data.PlacementSector4,
          PlacementDesignation4: backend_data.PlacementDesignation4,
          PlacementCompanyName5: backend_data.PlacementCompanyName5,
          PlacementHighestPackage5: backend_data.PlacementHighestPackage5,
          PlacementSector5: backend_data.PlacementSector5,
          PlacementDesignation5: backend_data.PlacementDesignation5,
          PlacementCompanyName6: backend_data.PlacementCompanyName6,
          PlacementHighestPackage6: backend_data.PlacementHighestPackage6,
          PlacementSector6: backend_data.PlacementSector6,
          PlacementDesignation6: backend_data.PlacementDesignation6,
      
          ReviewLike1: backend_data.reviewLike1,
          ReviewLike2: backend_data.reviewLike2,
          ReviewLike3: backend_data.reviewLike3,
          ReviewLike4: backend_data.reviewLike4,
          ReviewDilike1: backend_data.reviewDislike1,
          ReviewDilike2: backend_data.reviewDislike2,
          ReviewDilike3: backend_data.reviewDislike3,
          ReviewDilike4: backend_data.reviewDislike4,
      
          CutoffTopic: backend_data.CutoffTopic,
          CutoffAboutInfo: backend_data.CutoffAboutInfo,
          CutoffAboutExam: backend_data.CutoffAboutExam,
          CutoffAcademicProgramName1: backend_data.CutoffAcademicProgramName1,
          CutoffAcademicOpeningranke1: backend_data.CutoffAcademicOpeningranke1,
          cutoffAcademicClossingrank1: backend_data.cutoffAcademicClossingrank1,
          CutoffAcademicProgramName2: backend_data.CutoffAcademicProgramName2,
          CutoffAcademicOpeningranke2: backend_data.CutoffAcademicOpeningranke2,
          cutoffAcademicClossingrank2: backend_data.cutoffAcademicClossingrank2,
          CutoffAcademicProgramName3: backend_data.CutoffAcademicProgramName3,
          CutoffAcademicOpeningranke3: backend_data.CutoffAcademicOpeningranke3,
          cutoffAcademicClossingrank3: backend_data.cutoffAcademicClossingrank3,
          CutoffAcademicProgramName4: backend_data.CutoffAcademicProgramName4,
          CutoffAcademicOpeningranke4: backend_data.CutoffAcademicOpeningranke4,
          cutoffAcademicClossingrank4: backend_data.cutoffAcademicClossingrank4,
          CutoffExamyear1: backend_data.CutoffExamyear1,
          CutoffExamyear2: backend_data.CutoffExamyear2,

          CutoffCourses1:backend_data.CutoffCourses1,
          CutoffCourcesRank1:backend_data.CutoffCourcesRank1,
          CutoffCourses2:backend_data.CutoffCourses2,
          CutoffCourcesRank2:backend_data.CutoffCourcesRank2,
          CutoffCourses3:backend_data.CutoffCourses3,
          CutoffCourcesRank3:backend_data.CutoffCourcesRank3,
          CutoffCourses4:backend_data.CutoffCourses4,
          CutoffCourcesRank4:backend_data.CutoffCourcesRank4,
          CutoffCourses5:backend_data.CutoffCourses5,
          CutoffCourcesRank5:backend_data.CutoffCourcesRank5,
          CutoffCoursesName1:backend_data.cutoffCoursesName1,
          CutoffCourses1Round1:backend_data.cutoffCourses1Round1,
          CutoffCourses1Round2:backend_data.cutoffCourses1Round2,
          CutoffCourses1Round3:backend_data.cutoffCourses1Round3,
          CutoffCourses1Round4:backend_data.cutoffCourses1Round4,
          CutoffCourses1Round5:backend_data.cutoffCourses1Round5,
          CutoffCourses1Round6:backend_data.cutoffCourses1Round6,
          CutoffCoursesName2:backend_data.cutoffCoursesName2,
          CutoffCourses2Round1:backend_data.cutoffCourses2Round1,
          CutoffCourses2Round2:backend_data.cutoffCourses2Round2,
          CutoffCourses2Round3:backend_data.cutoffCourses2Round3,
          CutoffCourses2Round4:backend_data.cutoffCourses2Round4,
          CutoffCourses2Round5:backend_data.cutoffCourses2Round5,
          CutoffCourses2Round6:backend_data.cutoffCourses2Round6,
          CutoffCoursesName3:backend_data.cutoffCoursesName3,
          CutoffCourses3Round1:backend_data.cutoffCourses3Round1,
          CutoffCourses3Round2:backend_data.cutoffCourses3Round2,
          CutoffCourses3Round3:backend_data.cutoffCourses3Round3,
          CutoffCourses3Round4:backend_data.cutoffCourses3Round4,
          CutoffCourses3Round5:backend_data.cutoffCourses3Round5,
          CutoffCourses3Round6:backend_data.cutoffCourses3Round6,







          
          RankingYear: backend_data.rankingYear,
          RankingInfo: backend_data.rankingInfo,
          RankingDescriptionHeader: backend_data.rankingDescriptionHeader,
          RankingWeekRankingName: backend_data.rankingWeekRankingName,
          RankingWeekDescription1: backend_data.rankingWeekDescription1,
          RankingDescription2: backend_data.rankingDescription2,
          RankingWeekStream1: backend_data.rankingWeekStream1,
          Ranking1Week2024: backend_data.ranking1Week2024,
          Ranking1Week2023: backend_data.ranking1Week2023,
          Ranking1Week2022: backend_data.ranking1Week2022,
          RankingWeekStream2: backend_data.rankingWeekStream2,
          Ranking2Week2024: backend_data.ranking2Week2024,
          Ranking2Week2023: backend_data.ranking2Week2023,
          Ranking2Week2022: backend_data.ranking2Week2022,
          RankingNIRFRankingName: backend_data.rankingNIRFRankingName,
          RankingNIRFStream1: backend_data.rankingNIRFStream1,
          Ranking1NIRF2024: backend_data.ranking1NIRF2024,
          Ranking1NIRF2023: backend_data.ranking1NIRF2023,
          Ranking1NIRF2022: backend_data.ranking1NIRF2022,
          RankingNIRFStream2: backend_data.rankingNIRFStream2,
          Ranking2NIRF2024: backend_data.ranking2NIRF2024,
          Ranking2NIRF2023:backend_data.ranking2NIRF2023,
          Ranking2NIRF2022:backend_data.ranking2NIRF2022,
          
          CollegeFacilitiesInfo: backend_data.CollegeFacilitiesInfo,
          collegeHighlights: backend_data.collegeHighlights,
          CampusFacility1: backend_data.CampusFacility1,
          CampusFacility1PhotoURL: backend_data.CampusFacility1PhotoURL,
          CampusFacility1Paragraph: backend_data.CampusFacility1Paragraph,
          CampusFacility2: backend_data.CampusFacility2,
          CampusFacility2PhotoURL: backend_data.CampusFacility2PhotoURL,
          CampusFacility2Paragraph: backend_data.CampusFacility2Paragraph,
          CampusFacility3: backend_data.CampusFacility3,
          CampusFacility3PhotoURL: backend_data.CampusFacility3PhotoURL,
          CampusFacility3Paragraph: backend_data.CampusFacility3Paragraph,
          CampusFacility4: backend_data.CampusFacility4,
          CampusFacility4PhotoURL: backend_data.CampusFacility4PhotoURL,
          CampusFacility4Paragraph: backend_data.CampusFacility4Paragraph,
          CampusFacility5: backend_data.CampusFacility5,
          CampusFacility5PhotoURL: backend_data.CampusFacility5PhotoURL,
          CampusFacility5Paragraph: backend_data.CampusFacility5Paragraph,
          CampusFacility6: backend_data.CampusFacility6,
          CampusFacility6PhotoURL: backend_data.CampusFacility6PhotoURL,
          CampusFacility6Paragraph: backend_data.CampusFacility6Paragraph,
          CampusFacility7: backend_data.CampusFacility7,
          CampusFacility7PhotoURL: backend_data.CampusFacility7PhotoURL,
          CampusFacility7Paragraph: backend_data.CampusFacility7Paragraph,
          
          HostelParagraph: backend_data.HostelParagraph,
          CollegeHostelFacility1: backend_data.CollegeHostelFacility1,
          CollegeHostelFacility2: backend_data.CollegeHostelFacility2,
          CollegeHostelFacility3: backend_data.CollegeHostelFacility3,
          CollegeHostelFacility4: backend_data.CollegeHostelFacility4,
          CollegeCautionFees: backend_data.CollegeCautionFees,
          totalhostelfee: backend_data.totalhostelfee,
          hosteldislikereview1: backend_data.hosteldislikereview1,
          hosteldislikereview2: backend_data.hosteldislikereview2,
          hosteldislikereview3: backend_data.hosteldislikereview3,
          hosteldislikereview4: backend_data.hosteldislikereview4,
          hostellikereview1: backend_data.hostellikereview1,
          hostellikereview2: backend_data.hostellikereview2,
          hostellikereview3: backend_data.hostellikereview3,
          hostellikereview4: backend_data.hostellikereview4,
          
          DepartmentName1: backend_data.DepartmentName1,
          DepartmentName1Location: backend_data.DepartmentName1Location,
          DepartmentName1Description: backend_data.DepartmentName1Description,
          DepartmentName1StaffName1: backend_data.DepartmentName1StaffName1,
          DepartmentName1StaffDesignation1: backend_data.DepartmentName1StaffDesignation1,
          DepartmentName1StaffDescription1: backend_data.DepartmentName1StaffDescription1,
          DepartmentName1StaffName2: backend_data.DepartmentName1StaffName2,
          DepartmentName1StaffDesignation2: backend_data.DepartmentName1StaffDesignation2,
          DepartmentName1StaffDescription2: backend_data.DepartmentName1StaffDescription2,
          DepartmentName2: backend_data.DepartmentName2,
          DepartmentName2Location: backend_data.DepartmentName2Location,
          DepartmentName2Description: backend_data.DepartmentName2Description,
          DepartmentName2StaffName1: backend_data.DepartmentName2StaffName1,
          DepartmentName2StaffDesignation1: backend_data.DepartmentName2StaffDesignation1,
          DepartmentName2StaffDescription1: backend_data.DepartmentName2StaffDescription1,
          DepartmentName2StaffName2: backend_data.DepartmentName2StaffName2,
          DepartmentName2StaffDesignation2: backend_data.DepartmentName2StaffDesignation2,
          DepartmentName2StaffDescription2: backend_data.DepartmentName2StaffDescription2,
          CoursesinDepartment1:backend_data.CoursesinDepartment1,
          DepartmentFees1:backend_data.DepartmentFees1,
          Question1:backend_data.Question1,
          Answer1:backend_data.Answer1,
          Question2:backend_data.Question2,
          Answer2:backend_data.Answer2,
          Question3:backend_data.Question3,
          Answer3:backend_data.Answer3,
          Question4:backend_data.Question4,
          Answer4:backend_data.Answer4,
          ScholarshipDescription: backend_data.scholarshipDescription,

          ScholarshipName1: backend_data.scholarshipName1,
          ScholarshipEligibilCriteria1: backend_data.scholarshipEligibilCriteria1,
          ScholarshipAward1: backend_data.scholarshipAward1,

          ScholarshipName2: backend_data.scholarshipName2,
          ScholarshipEligibilCriteria2: backend_data.scholarshipEligibilCriteria2,
          ScholarshipAward2: backend_data.scholarshipAward2,

          ScholarshipName3: backend_data.scholarshipName3,
          ScholarshipEligibilCriteria3: backend_data.scholarshipEligibilCriteria3,
          ScholarshipAward3: backend_data.scholarshipAward3,

          ScholarshipName4: backend_data.scholarshipName4,
          ScholarshipEligibilCriteria4: backend_data.scholarshipEligibilCriteria4,
          ScholarshipAward4: backend_data.scholarshipAward4,

          ScholarshipName5: backend_data.scholarshipName5,
          ScholarshipEligibilCriteria5: backend_data.scholarshipEligibilCriteria5,
          ScholarshipAward5: backend_data.scholarshipAward5,

          ScholarshipName6: backend_data.scholarshipName6,
          ScholarshipEligibilCriteria6: backend_data.scholarshipEligibilCriteria6,
          ScholarshipAward6: backend_data.scholarshipAward6,

          ScholarshipName7: backend_data.scholarshipName7,
          ScholarshipEligibilCriteria7: backend_data.scholarshipEligibilCriteria7,
          ScholarshipAward7: backend_data.scholarshipAward7,

          ScholarshipName8: backend_data.scholarshipName8,
          ScholarshipEligibilCriteria8: backend_data.scholarshipEligibilCriteria8,
          ScholarshipAward8: backend_data.scholarshipAward8,

          ScholarshipName9: backend_data.scholarshipName9,
          ScholarshipEligibilCriteria9: backend_data.scholarshipEligibilCriteria9,
          ScholarshipAward9: backend_data.scholarshipAward9,

          ScholarshipName10: backend_data.scholarshipName10,
          ScholarshipEligibilCriteria10: backend_data.scholarshipEligibilCriteria10,
          ScholarshipAward10: backend_data.scholarshipAward10,

          FAQquestion1: backend_data.FAQquestion1,
  FAQAns1: backend_data.FAQAns1,
  FAQquestion2: backend_data.FAQquestion2,
  FAQAns2: backend_data.FAQAns2,
  FAQquestion3: backend_data.FAQquestion3,
  FAQAns3: backend_data.FAQAns3,
  FAQquestion4: backend_data.FAQquestion4,
  FAQAns4: backend_data.FAQAns4,
  FAQquestion5: backend_data.FAQquestion5,
  FAQAns5: backend_data.FAQAns5,
  FAQquestion6: backend_data.FAQquestion6,
  FAQAns6: backend_data.FAQAns6,
  FAQquestion7: backend_data.FAQquestion7,
  FAQAns7: backend_data.FAQAns7,
  FAQquestion8: backend_data.FAQquestion8,
  FAQAns8: backend_data.FAQAns8,
  FAQquestion9: backend_data.FAQquestion9,
  FAQAns9: backend_data.FAQAns9,
  FAQquestion10: backend_data.FAQquestion10,
  FAQAns10: backend_data.FAQAns10,
  FAQquestion11: backend_data.FAQquestion11,
  FAQAns11: backend_data.FAQAns11,
  FAQquestion12: backend_data.FAQquestion12,
  FAQAns12: backend_data.FAQAns12,
  applynowlink: backend_data.applynowlink,
  brochurelink: backend_data.brochurelink,
  SidebarAdvertiseNameVideos1: backend_data.SidebarAdvertiseNameVideos1,
  SidebarAdvertiseVideosSction1: backend_data.SidebarAdvertiseVideosSction1,
  SidebarAdvertiseVideosSction2: backend_data.SidebarAdvertiseVideosSction2,
  SidebarAdvertiseNameVideos2: backend_data.SidebarAdvertiseNameVideos2,
  ManagementCourseName: backend_data.ManagementCourseName,
managementHeaderName1: backend_data.managementHeaderName1,
managementheadernameCutoff1: backend_data.managementheadernameCutoff1,
MBADepartmenetName1: backend_data.MBADepartmenetName1,
MBAPersentage1: backend_data.MBAPersentage1,
MBADepartmenetName2: backend_data.MBADepartmenetName2,
MBAPersentage2: backend_data.MBAPersentage2,
MBADepartmenetName3: backend_data.MBADepartmenetName3,
MBAPersentage3: backend_data.MBAPersentage3,
MBADepartmenetName4: backend_data.MBADepartmenetName4,
MBAPersentage4: backend_data.MBAPersentage4,
MBADepartmenetName5: backend_data.MBADepartmenetName5,
MBAPersentage5: backend_data.MBAPersentage5,
MBADepartmenetName6: backend_data.MBADepartmenetName6,
MBAPersentage6: backend_data.MBAPersentage6,
MBADepartmenetName7: backend_data.MBADepartmenetName7,
MBAPersentage7: backend_data.MBAPersentage7,
type: backend_data.type,
Dummy1: backend_data.Dummy1,
Dummy2: backend_data.Dummy2,
Dummy3: backend_data.Dummy3,
Dummy4: backend_data.Dummy4,
Dummy5: backend_data.Dummy5,
Dummy6: backend_data.Dummy6,
Dummy7: backend_data.Dummy7,
Dummy8: backend_data.Dummy8,
Dummy9: backend_data.Dummy9,
Dummy10: backend_data.Dummy10,
department1: backend_data.department1,
department2: backend_data.department2,
department3: backend_data.department3,
department4: backend_data.department4,
department5: backend_data.department5,
department6: backend_data.department6,
department7: backend_data.department7,
department8: backend_data.department8,
department9: backend_data.department9,
department10: backend_data.department10,
seat1: backend_data.seat1,
seat2: backend_data.seat2,
seat3: backend_data.seat3,
seat4: backend_data.seat4,
seat5: backend_data.seat5,
seat6: backend_data.seat6,
seat7: backend_data.seat7,
seat8: backend_data.seat8,
seat9: backend_data.seat9,
seat10: backend_data.seat10





      };

        setProps(mappedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    fetchData();
  },[]); // Include slug in the dependency array

  console.log("Props:", props);
  if (!props) {
    return <div>Loading...</div>; // Display a loading message while fetching the data
  }
 
  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  // Handlers to toggle between sections
  const handleShowInfo = () => setActiveTab("info");
  const handleShowCoursesFees = () => setActiveTab("coursesFees");
  // const handleShowDistanceEducation = () => setActiveTab("distanceEducation");
  // const handleShowAdmission = () => setActiveTab('admission');
  const handleShowReviews = () => setActiveTab("reviews");
  const handleShowDepartment = () => setActiveTab("department");
  const handleShowPlacement = () => setActiveTab("placement");

  const handleShowCutoff = () => setActiveTab("cutoff");

  const handleShowRanking = () => setActiveTab("Ranking");

  const handleShowScholarship = () => setActiveTab("scholarship");
  const handleShowFacilities = () => setActiveTab("Facilities");
  const handleShowFAQ = () => setActiveTab("FAQ");
  const handleShowSeatIntakeTable = () => setActiveTab("SeatIntakeTable");
  
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {/* Logo and Title Section */}
      <div className="flex items-center mt-4">
        <img
          src={props.LogoLink}
          alt="Logo"
          className="max-w-xmrounded-full w-12 h-12"
        />
        <div className="ml-4">
          <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
            {props.CollegeName}: CutOff, Course, Fees, Ranking, Placement
          </h1>

          <p className="text-gray-600">{props.CollegeLocation}</p>
        </div>
      </div>

      {/* Ranking Section */}
      <div className="flex items-center mt-4">
        <div className="bg-orange-400 text-white py-1 px-3 rounded-full text-sm font-bold">
          #{props.collegeRank}
        </div>
        <span className="ml-2 text-sm text-gray-600">{props.rankBasis}</span>
        {/*<a href="#" className="ml-4 text-blue-500 underline">
          Read 299 New Questions & Answers
        </a>*/}
        <div className="ml-auto flex items-center">
          {/* <span className="text-xl font-semibold">4.3</span>
          <span className="ml-2 text-yellow-400">★★★★☆</span> */}
        </div>
      </div>

      {/* Bookmark Button Section */}
      {/* <div className="flex justify-end mt-4 space-x-4">
        <button className="bg-blue-500 text-white py-2 px-4 rounded flex items-center">
          <span>Bookmark</span>
          <ImBookmarks className="ml-2" />
        </button>
      </div> */}

      {/* Tabs Section */}
      <div className="mt-6 border-b border-gray-300">
        <nav className="flex space-x-6 overflow-x-auto whitespace-nowrap md:space-x-6">
          <a
            href="#"
            className={`py-2 ${
              activeTab === "info"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowInfo}
          >
            Info
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "coursesFees"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowCoursesFees}
          >
            Courses & Fees
          </a>
          {/* <a
      href="#"
      className={`py-2 ${
        activeTab === "distanceEducation"
          ? "text-blue-500 border-b-2 border-blue-500"
          : "text-gray-600"
      }`}
      onClick={handleShowDistanceEducation}
    >
      Distance Education
    </a> */}
          <a
            href="#"
            className={`py-2 ${
              activeTab === "SeatIntakeTable"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowSeatIntakeTable}
          >
            Seat Intake
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "reviews"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowReviews}
          >
            Reviews
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "department"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowDepartment}
          >
            Department
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "placement"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowPlacement}
          >
            Placement
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "cutoff"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowCutoff}
          >
            CutOff
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "Ranking"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowRanking}
          >
            Ranking
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "scholarship"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowScholarship}
          >
            Scholarship
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "Facilities"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowFacilities}
          >
            Facilities
          </a>
          <a
            href="#"
            className={`py-2 ${
              activeTab === "FAQ"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-600"
            }`}
            onClick={handleShowFAQ}
          >
            FAQ
          </a>
        </nav>
      </div>

      {/* Main Section */}
      <div className="mt-6 bg-white shadow-md p-6 rounded-md">
        {/* Conditionally Render Sections */}
        {activeTab === "info" && (
          <section>
            <h2 className="text-lg font-semibold">{props.CollegeName}</h2>
            <p className="mt-2 text-gray-600">{props.AboutCollege}</p>

            <h2 className="text-lg font-semibold mt-6">
              What's new in {props.CollegeName}
            </h2>
            <p className="mt-2 text-gray-600">{props.CollegeInfoNewThings}</p>
            <div className="mt-4">
              <button
                onClick={handleReadMore}
                className="mt-2 text-blue-500 hover:underline"
              >
                {isExpanded ? "Show Less" : "Read More"}
              </button>
              {isExpanded && (
                <div className="mt-4">
                  <p className="text-gray-600">{props.AboutCollegeMore}</p>
                </div>
              )}
            </div>

            <CourseandFee
              CollegeName={props.CollegeName}
              Course1={props.Course1}
              Course1Fee={props.Course1Fee}
              Course1Eligibility={props.Course1Eligibility}
              Course1ApplicationDeadline={props.Course1ApplicationDeadline}
              Course2={props.Course2}
              Course2Fee={props.Course2Fee}
              Course2Eligibility={props.Course2Eligibility}
              Course2ApplicationDeadline={props.Course2ApplicationDeadline}
              Course3={props.Course3}
              Course3Fee={props.Course3Fee}
              Course3Eligibility={props.Course3Eligibility}
              Course3ApplicationDeadline={props.Course3ApplicationDeadline}
              Course4={props.Course4}
              Course4Fee={props.Course4Fee}
              Course4Eligibility={props.Course4Eligibility}
              Course4ApplicationDeadline={props.Course4ApplicationDeadline}
            />
            {/* <Admissions /> */}
            <Review
              CollegeName={props.CollegeName}
              ReviewLike1={props.ReviewLike1}
              ReviewLike2={props.ReviewLike2}
              ReviewLike3={props.ReviewLike3}
              ReviewLike4={props.ReviewLike4}
              ReviewDilike1={props.ReviewDilike1}
              ReviewDilike2={props.ReviewDilike2}
              ReviewDilike3={props.ReviewDilike3}
              ReviewDilike4={props.ReviewDilike4}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />

            <Placement
              CollegeName={props.CollegeName}
              Placementyear={props.Placementyear}
              PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1={
                props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1
              }
              PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2={
                props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2
              }
              Placementhighestpackagelist={props.Placementhighestpackagelist}
              PlacementAveragepackage={props.PlacementAveragepackage}
              PlacementTopRecruiters={props.PlacementTopRecruiters}
              PlacementCompanyName1={props.PlacementCompanyName1}
              PlacementHighestPackage1={props.PlacementHighestPackage1}
              PlacementSector1={props.PlacementSector1}
              PlacementDesignation1={props.PlacementDesignation1}
              PlacementCompanyName2={props.PlacementCompanyName2}
              PlacementHighestPackage2={props.PlacementHighestPackage2}
              PlacementSector2={props.PlacementSector2}
              PlacementDesignation2={props.PlacementDesignation2}
              PlacementCompanyName3={props.PlacementCompanyName3}
              PlacementHighestPackage3={props.PlacementHighestPackage3}
              PlacementSector3={props.PlacementSector3}
              PlacementDesignation3={props.PlacementDesignation3}
              PlacementCompanyName4={props.PlacementCompanyName4}
              PlacementHighestPackage4={props.PlacementHighestPackage4}
              PlacementSector4={props.PlacementSector4}
              PlacementDesignation4={props.PlacementDesignation4}
              PlacementCompanyName5={props.PlacementCompanyName5}
              PlacementHighestPackage5={props.PlacementHighestPackage5}
              PlacementSector5={props.PlacementSector5}
              PlacementDesignation5={props.PlacementDesignation5}
              PlacementCompanyName6={props.PlacementCompanyName6}
              PlacementHighestPackage6={props.PlacementHighestPackage6}
              PlacementSector6={props.PlacementSector6}
              PlacementDesignation6={props.PlacementDesignation6}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "coursesFees" && (
          <section>
            <CourseandFee
              CollegeName={props.CollegeName}
              Course1={props.Course1}
              Course1Fee={props.Course1Fee}
              Course1Eligibility={props.Course1Eligibility}
              Course1ApplicationDeadline={props.Course1ApplicationDeadline}
              Course2={props.Course2}
              Course2Fee={props.Course2Fee}
              Course2Eligibility={props.Course2Eligibility}
              Course2ApplicationDeadline={props.Course2ApplicationDeadline}
              Course3={props.Course3}
              Course3Fee={props.Course3Fee}
              Course3Eligibility={props.Course3Eligibility}
              Course3ApplicationDeadline={props.Course3ApplicationDeadline}
              Course4={props.Course4}
              Course4Fee={props.Course4Fee}
              Course4Eligibility={props.Course4Eligibility}
              Course4ApplicationDeadline={props.Course4ApplicationDeadline}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}
        {activeTab === "SeatIntakeTable" && (
          <section>
            <SeatIntakeTable CollegeName={props.CollegeName}
            department1={props.department1} 
            seat1={props.seat1}
            department2={props.department2} 
            seat2={props.seat2}
            department3={props.department3} 
            seat3={props.seat3}
            department4={props.department4} 
            seat4={props.seat4}
            department5={props.department5} 
            seat5={props.seat5}
            department6={props.department6} 
            seat6={props.seat6}
            department7={props.department7} 
            seat7={props.seat7}
            department8={props.department8} 
            seat8={props.seat8}
            department9={props.department9} 
            seat9={props.seat9}
            department10={props.department10} 
            seat10={props.seat10}
            
            
            
            
            
            
            
            
            
            
            
            />
          </section>
        )}

        {activeTab === "distanceEducation" && (
          <section>
            <DistanceEducation
              CollegeName={props.CollegeName}
              DistanceCourseName1={props.DistanceCourseName1}
              DistanceCourseFee1={props.DistanceCourseFee1}
              DistanceEligiblity1={props.DistanceEligiblity1}
              DistanceApllicationdate1={props.DistanceApllicationdate1}
              DistanceCourseName2={props.DistanceCourseName2}
              DistanceCourseFee2={props.DistanceCourseFee2}
              DistanceEligiblity2={props.DistanceEligiblity2}
              DistanceApllicationdate2={props.DistanceApllicationdate2}
              DistanceCourseName3={props.DistanceCourseName3}
              DistanceCourseFee3={props.DistanceCourseFee3}
              DistanceEligiblity3={props.DistanceEligiblity3}
              DistanceApllicationdate3={props.DistanceApllicationdate3}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {/* {activeTab === 'admission' && (
          <section>
            <Admissions />
          </section>
        )} */}

        {activeTab === "reviews" && (
          <section>
            <Review
              CollegeName={props.CollegeName}
              ReviewLike1={props.ReviewLike1}
              ReviewLike2={props.ReviewLike2}
              ReviewLike3={props.ReviewLike3}
              ReviewLike4={props.ReviewLike4}
              ReviewDilike1={props.ReviewDilike1}
              ReviewDilike2={props.ReviewDilike2}
              ReviewDilike3={props.ReviewDilike3}
              ReviewDilike4={props.ReviewDilike4}
            />
          </section>
        )}

        {activeTab === "department" && (
          <section>
            <Department
              CollegeName={props.CollegeName}
              DepartmentName1={props.DepartmentName1}
              DepartmentName1Location={props.DepartmentName1Location}
              CoursesinDepartment1={props.CoursesinDepartment1}
              DepartmentFees1={props.DepartmentFees1}
              Question1={props.Question1}
              Answer1={props.Answer1}
              Question2={props.Question2}
              Answer2={props.Answer2}
              Question3={props.Question3}
              Answer3={props.Answer3}
              Question4={props.Question4}
              Answer4={props.Answer4}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "placement" && (
          <section>
            <Placement
              CollegeName={props.CollegeName}
              Placementyear={props.Placementyear}
              PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1={
                props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1
              }
              PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2={
                props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2
              }
              Placementhighestpackagelist={props.Placementhighestpackagelist}
              PlacementAveragepackage={props.PlacementAveragepackage}
              PlacementTopRecruiters={props.PlacementTopRecruiters}
              PlacementCompanyName1={props.PlacementCompanyName1}
              PlacementHighestPackage1={props.PlacementHighestPackage1}
              PlacementSector1={props.PlacementSector1}
              PlacementDesignation1={props.PlacementDesignation1}
              PlacementCompanyName2={props.PlacementCompanyName2}
              PlacementHighestPackage2={props.PlacementHighestPackage2}
              PlacementSector2={props.PlacementSector2}
              PlacementDesignation2={props.PlacementDesignation2}
              PlacementCompanyName3={props.PlacementCompanyName3}
              PlacementHighestPackage3={props.PlacementHighestPackage3}
              PlacementSector3={props.PlacementSector3}
              PlacementDesignation3={props.PlacementDesignation3}
              PlacementCompanyName4={props.PlacementCompanyName4}
              PlacementHighestPackage4={props.PlacementHighestPackage4}
              PlacementSector4={props.PlacementSector4}
              PlacementDesignation4={props.PlacementDesignation4}
              PlacementCompanyName5={props.PlacementCompanyName5}
              PlacementHighestPackage5={props.PlacementHighestPackage5}
              PlacementSector5={props.PlacementSector5}
              PlacementDesignation5={props.PlacementDesignation5}
              PlacementCompanyName6={props.PlacementCompanyName6}
              PlacementHighestPackage6={props.PlacementHighestPackage6}
              PlacementSector6={props.PlacementSector6}
              PlacementDesignation6={props.PlacementDesignation6}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "cutoff" && (
          <section>
            <Cutoff
              ManagementCourseName={props.ManagementCourseName}
              managementHeaderName1={props.managementHeaderName1}
              managementheadernameCutoff1={props.managementheadernameCutoff1}
              MBADepartmenetName1={props.MBADepartmenetName1}
              MBAPersentage1={props.MBAPersentage1}
              MBADepartmenetName2={props.MBADepartmenetName2}
              MBAPersentage2={props.MBAPersentage2}
              MBADepartmenetName3={props.MBADepartmenetName3}
              MBAPersentage3={props.MBAPersentage3}
              MBADepartmenetName4={props.MBADepartmenetName4}
              MBAPersentage4={props.MBAPersentage4}
              MBADepartmenetName5={props.MBADepartmenetName5}
              MBAPersentage5={props.MBAPersentage5}
              MBADepartmenetName6={props.MBADepartmenetName6}
              MBAPersentage6={props.MBAPersentage6}
              MBADepartmenetName7={props.MBADepartmenetName7}
              MBAPersentage7={props.MBAPersentage7}
              CollegeName={props.CollegeName}
              CutoffTopic={props.CutoffTopic}
              CutoffAboutInfo={props.CutoffAboutInfo}
              CutoffAboutExam={props.CutoffAboutExam}
              CutoffAcademicProgramName1={props.CutoffAcademicProgramName1}
              CutoffAcademicOpeningranke1={props.CutoffAcademicOpeningranke1}
              cutoffAcademicClossingrank1={props.cutoffAcademicClossingrank1}
              CutoffAcademicProgramName2={props.CutoffAcademicProgramName2}
              CutoffAcademicOpeningranke2={props.CutoffAcademicOpeningranke2}
              cutoffAcademicClossingrank2={props.cutoffAcademicClossingrank2}
              CutoffAcademicProgramName3={props.CutoffAcademicProgramName3}
              CutoffAcademicOpeningranke3={props.CutoffAcademicOpeningranke3}
              cutoffAcademicClossingrank3={props.cutoffAcademicClossingrank3}
              CutoffAcademicProgramName4={props.CutoffAcademicProgramName4}
              CutoffAcademicOpeningranke4={props.CutoffAcademicOpeningranke4}
              cutoffAcademicClossingrank4={props.cutoffAcademicClossingrank4}
              CutoffExamyear1={props.CutoffExamyear1}
              CutoffExamyear2={props.CutoffExamyear2}
              CutoffCourses1={props.CutoffCourses1}
              CutoffCourcesRank1={props.CutoffCourcesRank1}
              CutoffCourses2={props.CutoffCourses2}
              CutoffCourcesRank2={props.CutoffCourcesRank2}
              CutoffCourses3={props.CutoffCourses3}
              CutoffCourcesRank3={props.CutoffCourcesRank3}
              CutoffCourses4={props.CutoffCourses4}
              CutoffCourcesRank4={props.CutoffCourcesRank4}
              CutoffCourses5={props.CutoffCourses5}
              CutoffCourcesRank5={props.CutoffCourcesRank5}
              CutoffCoursesName1={props.CutoffCoursesName1}
              CutoffCourses1Round1={props.CutoffCourses1Round1}
              CutoffCourses1Round2={props.CutoffCourses1Round2}
              CutoffCourses1Round3={props.CutoffCourses1Round3}
              CutoffCourses1Round4={props.CutoffCourses1Round4}
              CutoffCourses1Round5={props.CutoffCourses1Round5}
              CutoffCourses1Round6={props.CutoffCourses1Round6}
              CutoffCoursesName2={props.CutoffCoursesName2}
              CutoffCourses2Round1={props.CutoffCourses2Round1}
              CutoffCourses2Round2={props.CutoffCourses2Round2}
              CutoffCourses2Round3={props.CutoffCourses2Round3}
              CutoffCourses2Round4={props.CutoffCourses2Round4}
              CutoffCourses2Round5={props.CutoffCourses2Round5}
              CutoffCourses2Round6={props.CutoffCourses2Round6}
              CutoffCoursesName3={props.CutoffCoursesName3}
              CutoffCourses3Round1={props.CutoffCourses3Round1}
              CutoffCourses3Round2={props.CutoffCourses3Round2}
              CutoffCourses3Round3={props.CutoffCourses3Round3}
              CutoffCourses3Round4={props.CutoffCourses3Round4}
              CutoffCourses3Round5={props.CutoffCourses3Round5}
              CutoffCourses3Round6={props.CutoffCourses3Round6}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "Ranking" && (
          <section>
            <Ranking
              CollegeName={props.CollegeName}
              RankingYear={props.RankingYear}
              RankingInfo={props.RankingInfo}
              RankingWeekRankingName={props.RankingWeekRankingName}
              RankingDescriptionHeader={props.RankingDescriptionHeader}
              RankingWeekDescription1={props.RankingWeekDescription1}
              RankingDescription2={props.RankingDescription2}
              RankingWeekStream1={props.RankingWeekStream1}
              Ranking1Week2024={props.Ranking1Week2024}
              Ranking1Week2023={props.Ranking1Week2023}
              Ranking1Week2022={props.Ranking1Week2022}
              RankingWeekStream2={props.RankingWeekStream2}
              Ranking2Week2024={props.Ranking2Week2024}
              Ranking2Week2023={props.Ranking2Week2023}
              Ranking2Week2022={props.Ranking2Week2022}
              RankingNIRFRankingName={props.RankingNIRFRankingName}
              RankingNIRFStream1={props.RankingNIRFStream1}
              Ranking1NIRF2024={props.Ranking1NIRF2024}
              Ranking1NIRF2023={props.Ranking1NIRF2023}
              Ranking1NIRF2022={props.Ranking1NIRF2022}
              RankingNIRFStream2={props.RankingNIRFStream2}
              Ranking2NIRF2024={props.Ranking2NIRF2024}
              Ranking2NIRF2023={props.Ranking2NIRF2023}
              Ranking2NIRF2022={props.Ranking2NIRF2022}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "scholarship" && (
          <section>
            <Scholarship
              CollegeName={props.CollegeName}
              ScholarshipDescription={props.ScholarshipDescription}
              ScholarshipName1={props.ScholarshipName1}
              ScholarshipEligibilCriteria1={props.ScholarshipEligibilCriteria1}
              ScholarshipAward1={props.ScholarshipAward1}
              ScholarshipName2={props.ScholarshipName2}
              ScholarshipEligibilCriteria2={props.ScholarshipEligibilCriteria2}
              ScholarshipAward2={props.ScholarshipAward2}
              ScholarshipName3={props.ScholarshipName3}
              ScholarshipEligibilCriteria3={props.ScholarshipEligibilCriteria3}
              ScholarshipAward3={props.ScholarshipAward3}
              ScholarshipName4={props.ScholarshipName4}
              ScholarshipEligibilCriteria4={props.ScholarshipEligibilCriteria4}
              ScholarshipAward4={props.ScholarshipAward4}
              ScholarshipName5={props.ScholarshipName5}
              ScholarshipEligibilCriteria5={props.ScholarshipEligibilCriteria5}
              ScholarshipAward5={props.ScholarshipAward5}
              ScholarshipName6={props.ScholarshipName6}
              ScholarshipEligibilCriteria6={props.ScholarshipEligibilCriteria6}
              ScholarshipAward6={props.ScholarshipAward6}
              ScholarshipName7={props.ScholarshipName7}
              ScholarshipEligibilCriteria7={props.ScholarshipEligibilCriteria7}
              ScholarshipAward7={props.ScholarshipAward7}
              ScholarshipName8={props.ScholarshipName8}
              ScholarshipEligibilCriteria8={props.ScholarshipEligibilCriteria8}
              ScholarshipAward8={props.ScholarshipAward8}
              ScholarshipName9={props.ScholarshipName9}
              ScholarshipEligibilCriteria9={props.ScholarshipEligibilCriteria9}
              ScholarshipAward9={props.ScholarshipAward9}
              ScholarshipName10={props.ScholarshipName10}
              ScholarshipEligibilCriteria10={
                props.ScholarshipEligibilCriteria10
              }
              ScholarshipAward10={props.ScholarshipAward10}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}

        {activeTab === "Facilities" && (
          <section>
            <Facilities
              CollegeName={props.CollegeName}
              CollegeFacilitiesInfo={props.CollegeFacilitiesInfo}
              collegeHighlights={props.collegeHighlights}
              CampusFacility1={props.CampusFacility1}
              CampusFacility1PhotoURL={props.CampusFacility1PhotoURL}
              CampusFacility1Paragraph={props.CampusFacility1Paragraph}
              CampusFacility2={props.CampusFacility2}
              CampusFacility2PhotoURL={props.CampusFacility2PhotoURL}
              CampusFacility2Paragraph={props.CampusFacility2Paragraph}
              CampusFacility3={props.CampusFacility3}
              CampusFacility3PhotoURL={props.CampusFacility3PhotoURL}
              CampusFacility3Paragraph={props.CampusFacility3Paragraph}
              CampusFacility4={props.CampusFacility4}
              CampusFacility4PhotoURL={props.CampusFacility4PhotoURL}
              CampusFacility4Paragraph={props.CampusFacility4Paragraph}
              CampusFacility5={props.CampusFacility5}
              CampusFacility5PhotoURL={props.CampusFacility5PhotoURL}
              CampusFacility5Paragraph={props.CampusFacility5Paragraph}
              CampusFacility6={props.CampusFacility6}
              CampusFacility6PhotoURL={props.CampusFacility6PhotoURL}
              CampusFacility6Paragraph={props.CampusFacility6Paragraph}
              CampusFacility7={props.CampusFacility7}
              CampusFacility7PhotoURL={props.CampusFacility7PhotoURL}
              CampusFacility7Paragraph={props.CampusFacility7Paragraph}
              HostelParagraph={props.HostelParagraph}
              CollegeHostelFacility1={props.CollegeHostelFacility1}
              CollegeHostelFacility2={props.CollegeHostelFacility2}
              CollegeHostelFacility3={props.CollegeHostelFacility3}
              CollegeHostelFacility4={props.CollegeHostelFacility4}
              CollegeCautionFees={props.CollegeCautionFees}
              totalhostelfee={props.totalhostelfee}
              hosteldislikereview1={props.hosteldislikereview1}
              hosteldislikereview2={props.hosteldislikereview2}
              hosteldislikereview3={props.hosteldislikereview3}
              hosteldislikereview4={props.hosteldislikereview4}
              hostellikereview1={props.hostellikereview1}
              hostellikereview2={props.hostellikereview2}
              hostellikereview3={props.hostellikereview3}
              hostellikereview4={props.hostellikereview4}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}
        {activeTab === "FAQ" && (
          <section>
            <FAQ
              FAQquestion1={props.FAQquestion1}
              FAQAns1={props.FAQAns1}
              FAQquestion2={props.FAQquestion2}
              FAQAns2={props.FAQAns2}
              FAQquestion3={props.FAQquestion3}
              FAQAns3={props.FAQAns3}
              FAQquestion4={props.FAQquestion4}
              FAQAns4={props.FAQAns4}
              FAQquestion5={props.FAQquestion5}
              FAQAns5={props.FAQAns5}
              FAQquestion6={props.FAQquestion6}
              FAQAns6={props.FAQAns6}
              FAQquestion7={props.FAQquestion7}
              FAQAns7={props.FAQAns7}
              FAQquestion8={props.FAQquestion8}
              FAQAns8={props.FAQAns8}
              FAQquestion9={props.FAQquestion9}
              FAQAns9={props.FAQAns9}
              FAQquestion10={props.FAQquestion10}
              FAQAns10={props.FAQAns10}
              FAQquestion11={props.FAQquestion11}
              FAQAns11={props.FAQAns11}
              FAQquestion12={props.FAQquestion12}
              FAQAns12={props.FAQAns12}
              applynowlink={props.applynowlink}
              brochurelink={props.brochurelink}
              SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
              SidebarAdvertiseVideosSction1={
                props.SidebarAdvertiseVideosSction1
              }
              SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
              SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
            />
          </section>
        )}
      </div>

      {/* Footer */}
      <footer className="mt-12 text-center text-sm text-gray-500">
        Do you think the data is wrong?{" "}
        <button className="text-blue-500 underline">Report Here</button>
      </footer>
    </div>
  );
};

export default ClickCollege;
