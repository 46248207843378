import React, { useState, useEffect } from "react";
import { logo } from "../../images/index";
import ContactPopup from "./ContactPopup";
import LoginPopup from "./LoginPopup";
import SignUpPage from "./SignUpPage";
import { Link } from "react-router-dom"; // Use Link for routing
import SearchNav from "./SearchNav";
import MegaDropdown from "./Navbar/MegaDropdown";
import { FaWhatsapp, FaUserCircle } from "react-icons/fa"; // Added FaUserCircle for profile icon
import { auth, signUserOut } from "../firebase"; // Import auth and signUserOut
import { onAuthStateChanged } from "firebase/auth"; // Import Firebase auth state change listener

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle mobile navigation
  const [isContactPopupOpen, setContactPopupOpen] = useState(false); // State for ContactPopup
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // State for LoginPopup
  const [isSignUpPopupOpen, setSignUpPopupOpen] = useState(false); // State for SignUpPopup
  const [user, setUser] = useState(null); // State to track logged-in user
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false); // State for profile dropdown

  // Track user login state with Firebase auth
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Set user state when logged in or logged out
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Toggles ContactPopup visibility
  const toggleContactPopup = () => {
    setContactPopupOpen(!isContactPopupOpen);
  };

  // Toggles LoginPopup visibility
  const toggleLoginPopup = () => {
    setLoginPopupOpen(!isLoginPopupOpen);
  };

  // Toggles SignUpPopup visibility
  const toggleSignUpPopup = () => {
    setSignUpPopupOpen(!isSignUpPopupOpen);
  };

  // Open SignUpPage and close LoginPopup
  const openSignUpPage = () => {
    setSignUpPopupOpen(true);
    setLoginPopupOpen(false);
  };

  // Open LoginPopup and close SignUpPage
  const openLoginPage = () => {
    setLoginPopupOpen(true);
    setSignUpPopupOpen(false);
  };

  // Handle user sign-out
  const handleSignOut = async () => {
    await signUserOut();
  };

  // Toggle profile dropdown
  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  return (
    <header>
      <div>
        <nav className="bg-[#68a4a5]">
          <div
            className="logo"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a href="/">
              <img
                src={logo}
                alt="Cadera Edu Logo"
                className="h-[70px] w-[250px]"
              />
            </a>
            <div>
              <div style={{ marginLeft: "30px" }}>
                <SearchNav />
              </div>
              <div style={{ marginLeft: "30px" }}>
                <MegaDropdown />
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <a
                style={{ display: "flex", justifyContent: "flex-end" }}
                href="https://api.whatsapp.com/send?phone=919711630487"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 text-white"
              >
                <FaWhatsapp style={{ color: "#00008B", fontSize: "24px" }} />
                <p className="text-white">Ask a query on 9711630487</p>
              </a>

              <div>
                {/* Show Login/Sign Up buttons if user is not logged in */}
                {!user ? (
                  <div className="flex space-x-4">
                    <a
                      href="#"
                      className="text-white"
                      onClick={toggleLoginPopup}
                    >
                      Login
                    </a>
                    <a
                      href="#"
                      className="text-white"
                      onClick={toggleSignUpPopup}
                    >
                      Sign Up
                    </a>
                  </div>
                ) : (
                  /* Show Profile icon and dropdown if user is logged in */
                  <div className="relative">
                    <FaUserCircle
                      style={{marginRight:'10px'}}
                      className="text-white cursor-pointer text-3xl"
                      onClick={toggleProfileDropdown}
                    />
                    {isProfileDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
                        <a
                          href="/profile"
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                          Profile
                        </a>
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                          onClick={handleSignOut}
                        >
                          Sign Out
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Contact Popup */}
      <ContactPopup
        isOpen={isContactPopupOpen}
        closePopup={toggleContactPopup}
      />

      {/* Login Popup */}
      {isLoginPopupOpen && (
        <LoginPopup
          closePopup={toggleLoginPopup}
          openSignUpPage={openSignUpPage} // Pass handler to open SignUpPage
        />
      )}

      {/* Sign Up Popup */}
      {isSignUpPopupOpen && (
        <SignUpPage
          isOpen={isSignUpPopupOpen}
          closePopup={toggleSignUpPopup}
          openLoginPage={openLoginPage} // Pass handler to open LoginPopup
        />
      )}
    </header>
  );
};

export default Header;
