import React from 'react';
import Sidebar from './sidebar';

const Ranking = (props) => {
  return (
    <div className="bg-white min-h-screen">
      <div className="bg-white shadow-md p-6">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
          {props.CollegeName} Ranking {props.RankingYear}: {props.RankingInfo}
        </h2>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2">
          {props.RankingDescriptionHeader}
        </p>
      </div>

      {/* Main container to hold the content and sidebar */}
      <div className="container mx-auto mt-6 flex flex-col md:flex-row">
        {/* Main Ranking Table Section */}
        <div className="w-full md:w-3/4 pr-4">
          {/* The Week Ranking */}
          <div className="mt-8">
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
              {props.RankingWeekRankingName}
            </h3>
            <p className="text-xs sm:text-sm md:text-base lg:text-lg mt-1">
              {props.RankingWeekDescription1}
            </p>
            {props.RankingWeekStream1 && props.RankingWeekStream2 ? (
              <div className="overflow-x-auto mt-4 bg-gray-200">
                <table className="min-w-full table-auto text-left border-collapse border border-gray-300">
                  <thead>
                    <tr>
                      <th className="border p-2">Stream</th>
                      <th className="border p-2">2024 Ranking</th>
                      <th className="border p-2">2023 Ranking</th>
                      <th className="border p-2">2022 Ranking</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-t">
                      <td className="p-2 border">{props.RankingWeekStream1}</td>
                      <td className="p-2 border">{props.Ranking1Week2024}</td>
                      <td className="p-2 border">{props.Ranking1Week2023}</td>
                      <td className="p-2 border">{props.Ranking1Week2022}</td>
                    </tr>
                    <tr className="bg-white border-t">
                      <td className="p-2 border">{props.RankingWeekStream2}</td>
                      <td className="p-2 border">{props.Ranking2Week2024}</td>
                      <td className="p-2 border">{props.Ranking2Week2023}</td>
                      <td className="p-2 border">{props.Ranking2Week2022}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="mt-4 text-red-600">
                No ranking data available for the week.
              </p>
            )}
          </div>

          {/* NIRF Ranking */}
          <div className="mt-8">
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
              {props.RankingNIRFRankingName}
            </h3>
            <p className="text-xs sm:text-sm md:text-base lg:text-lg mt-1">
              {props.RankingDescription2}
            </p>
            {props.RankingNIRFStream1 && props.RankingNIRFStream2 ? (
              <div className="overflow-x-auto mt-4 bg-gray-200">
                <table className="min-w-full table-auto text-left border-collapse border border-gray-300">
                  <thead>
                    <tr>
                      <th className="border p-2">Stream</th>
                      <th className="border p-2">2024 Ranking</th>
                      <th className="border p-2">2023 Ranking</th>
                      <th className="border p-2">2022 Ranking</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-t">
                      <td className="p-2 border">{props.RankingNIRFStream1}</td>
                      <td className="p-2 border">{props.Ranking1NIRF2024}</td>
                      <td className="p-2 border">{props.Ranking1NIRF2023}</td>
                      <td className="p-2 border">{props.Ranking1NIRF2022}</td>
                    </tr>
                    <tr className="bg-white border-t">
                      <td className="p-2 border">{props.RankingNIRFStream2}</td>
                      <td className="p-2 border">{props.Ranking2NIRF2024}</td>
                      <td className="p-2 border">{props.Ranking2NIRF2023}</td>
                      <td className="p-2 border">{props.Ranking2NIRF2022}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="mt-4 text-red-600">
                No NIRF ranking data available.
              </p>
            )}
          </div>
        </div>

        {/* Right Sidebar */}
        {/* <div className=" md:w-1/4   bg-gray-100 p-4"> */}
        <Sidebar
          applynowlink={props.applynowlink}
          brochurelink={props.brochurelink}
          SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
          SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
          SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
          SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Ranking;
