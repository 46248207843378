// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate

// const ExamNotification = (props) => {
//   const [openFaq, setOpenFaq] = useState(null);
//   const navigate = useNavigate(); // Get the navigate function
//   const {examName} = useParams();

//   const toggleFaq = (index) => {
//     setOpenFaq(openFaq === index ? null : index);
//   };

//   const faqs = [
//     {
//       question: "What's new in CAT exam?",
//       answer: "Details about new changes in CAT exam go here.",
//     },
//     {
//       question: "How can I apply for the CAT exam 2024?",
//       answer: "Application process details go here.",
//     },
//     {
//       question: "Which IIM will conduct CAT 2024 exam?",
//       answer: "Details about the conducting IIM go here.",
//     },
//     {
//       question: "What is the marking scheme of CAT?",
//       answer: "Marking scheme details go here.",
//     },
//     {
//       question: "What is the CAT application fee?",
//       answer: "Application fee details go here.",
//     },
//     {
//       question: "What is the sectional time limit in CAT?",
//       answer: "Sectional time limit details go here.",
//     },
//   ];

//   const schedule = [
//     { date: "05 Nov '24 - 24 Nov '24", event: "CAT 2024 admit card download" },
//     { date: "Nov '24", event: "CAT 2024 exam" },
//     { date: "Dec '24", event: "CAT 2024 answer key challenge (Tentative)" },
//     { date: "Dec '24", event: "CAT 2024 result announcement (Tentative)" },
//   ];

//   const pastSchedule = [
//     {
//       date: "27 Sep '24 - 30 Sep '24",
//       event: "CAT 2024 form correction process",
//     },
//     { date: "01 Aug '24 - 20 Sep '24", event: "CAT 2024 registration" },
//   ];

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.ExamNotificationHeaderHeading1} {props.ExamNotificationYear} : {props.ExamNotificationaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.ExamNotificationHeaderadmitcardname}: {props.ExamNotificationHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//               {props.ExamNotificationHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//               {props.ExamNotificationHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="p-6 bg-gray-50 text-gray-800">
//         {/* Notification Section */}
//         <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-6">
//           <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
//           {props.ExamNotificationcatexamHeadingName1}
//           </h2>

//           <p className="text-gray-700 mb-4">
//           {props.ExamNotificationcatexamHeadingName1Description1}
//           </p>
//         </div>

//         {/* Exam Schedule Section */}
//         <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
//           <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
//             {props.ExamNotificationTable1headingtitleName1}
//           </h3>
//           <table className="min-w-full bg-white border border-gray-200 mb-6">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
//                  {props.ExamNotificationEventTable1headingName1}
//                 </th>
//                 <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
//                   {props.ExamNotificationUpcommingdatesTable1headingName2}
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {schedule.map((item, index) => (
//                 <tr key={index} className="border-b">
//                   <td className="px-4 py-2 text-gray-700">{item.date}</td>
//                   <td className="px-4 py-2 text-gray-600">{item.event}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>

//           <table className="min-w-full bg-white border border-gray-200">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
//                   {props.ExamNotificationEventTable2headingName1}
//                 </th>
//                 <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
//                  {props.ExamNotificationUpdateddatesTable2headingName2}
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {pastSchedule.map((item, index) => (
//                 <tr key={index} className="border-b">
//                   <td className="px-4 py-2 text-gray-700">{item.date}</td>
//                   <td className="px-4 py-2 text-gray-600">{item.event}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         {/* FAQ Section */}
//         <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-6">
//           <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
//             Frequently Asked Questions
//           </h3>
//           {faqs.map((faq, index) => (
//             <div key={index} className="border-b border-gray-200 py-4">
//               <button
//                 onClick={() => toggleFaq(index)}
//                 className="w-full text-left flex justify-between items-center"
//               >
//                 <span className="text-gray-700 font-semibold">
//                   {faq.question}
//                 </span>
//                 <svg
//                   className={`w-6 h-6 transform ${
//                     openFaq === index ? "rotate-180" : "rotate-0"
//                   }`}
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M19 9l-7 7-7-7"
//                   />
//                 </svg>
//               </button>
//               {openFaq === index && (
//                 <p className="mt-2 text-gray-600">{faq.answer}</p>
//               )}
//             </div>
//           ))}
//         </div>

//       </div>
//     </div>
//   );
// };

// export default ExamNotification;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ExamNotification = () => {
  const [examData, setExamData] = useState(null);
  const [openFaq, setOpenFaq] = useState(null);
  const { examName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  if (!examData) return <div className="text-center py-6">Loading...</div>;

  const {
    year,
    admitCardName,
    admitCardDate,
    tabs,
    schedule,
    pastSchedule,
    faqs,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
  } = examData;

  return (
    <div className="bg-gray-100 p-8 min-h-screen">
      <div className="p-6 rounded-lg shadow-md bg-white">
        <h1 className="text-2xl font-bold text-gray-800 text-left">
          {headerHeading} {year} : {addHeading}
        </h1>
        <div className="flex items-center space-x-2 mt-4">
          <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {admitCardName}: {new Date(admitCardDate).toLocaleDateString()}
          </div>
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Paper
            </button> */}
          </div>
        </div>
      </div>

      {/* Navigation Section */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-6 bg-gray-50 text-gray-800 mt-6">
        {/* Exam Schedule Section */}
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            Upcoming Exam Schedule
          </h3>
          <table className="min-w-full bg-white border border-gray-200 mb-6">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Date
                </th>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Event
                </th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 text-gray-700">{item.timeline}</td>
                  <td className="px-4 py-2 text-gray-600">{item.activity}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            Past Schedule
          </h3>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Date
                </th>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Event
                </th>
              </tr>
            </thead>
            <tbody>
              {pastSchedule.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 text-gray-700">{item.date}</td>
                  <td className="px-4 py-2 text-gray-600">{item.event}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* FAQ Section */}
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            Frequently Asked Questions
          </h3>
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 py-4">
              <button
                onClick={() => toggleFaq(index)}
                className="w-full text-left flex justify-between items-center"
              >
                <span className="text-gray-700 font-semibold">
                  {faq.question}
                </span>
                <svg
                  className={`w-6 h-6 transform ${
                    openFaq === index ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openFaq === index && (
                <p className="mt-2 text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamNotification;
