// Importing and exporting all images from the images folder in one line
export { default as colleges } from "./colleges.png";
export { default as counselling } from "./counselling.png";
export { default as courses } from "./courses.png";
export { default as exams } from "./exams.png";
export { default as predictor } from "./predictor.png";
export { default as logo } from "./logo.png";
export { default as heroBackground } from "./hero-background.png";
export { default as signUpBlock } from "./signup-block.png";
export { default as contactBackground } from "./contactus.png";
export { default as Blacklogo } from "./Black-logo.jpg";
export { default as Scholarship } from "./Scholarship.png";
export { default as Counsellingupdates} from "./Counsellingupdates.png";
export { default as collegebg} from "./collegebg.jpeg";
export { default as collegefinder} from "./collegefinder.png";
export { default as comparecollege} from "./comparecollege.png";
export { default as coursefinder} from "./coursefinder.png";
export { default as examscard} from "./examscard.png";
export  { default as ranking} from "./ranking.png";
export { default as bookmark } from "./bookmark.png";
export { default as pricing } from "./pricing.png";
export {default as nidlogo} from "./nidlogo.jpg";
export {default as niftwhite} from "./niftwhite.jpg";
export {default as uceed} from "./wceed.webp";
export {default as niftblack} from "./niftblack.jpeg";
export {default as clat} from "./clat.jpeg";
export {default as ailet} from "./ailet.jpg";
export {default as slat} from "./slat.webp";
export {default as dullb} from "./dullb.png";

export {default as Jeemainlogo} from "./Jeemainlogo.jpg";
export { default as jeeadvancedlogo} from "./jeeadvancedlogo.png";
export { default as gatelogo} from "./gatelogo.jpg";
export { default as wbjeelogo} from "./wbjeelogo.jpeg";
export { default as bitsatlogo} from "./bitsatlogo.jpg";
export { default as viteee} from "./viteee.jpg";
export { default as catlogo} from "./catlogo.jpg";
export { default as xatlogo} from "./xatlogo.png";
export { default as matlogo} from "./matlogo.jpeg";
export { default as Gmatlogo} from "./Gmatlogo.jpg";
export { default as cmatlogo} from "./cmatlogo.jpg";
export { default as snaplogo} from "./snaplogo.jpg";
export { default as ibsatlogo} from "./ibsatlogo.jpg";
export { default as NMATlogo} from "./NMATlogo.png";
export { default as tisnetlogo} from "./tisnetlogo.png";
export { default as neetuglogo} from "./neetuglogo.jpg";
export { default as aiimslogo} from "./aiimslogo.jpg";
export { default as neetpglogo} from "./neetpglogo.jpg";
export { default as NEETMDSlogo} from "./NEETMDSlogo.png";
export { default as pucetlogo} from "./pucetlogo.jpg";
export { default as UPPGlogo} from "./UPPGlogo.jpeg";
export { default as pgecetlogo} from "./pgecetlogo.jpeg";
export { default as tancetlogo} from "./tancetlogo.jpeg";
export { default as bitshdlogo} from "./bitshdlogo.jpeg";
export { default as upseelogo} from "./upseelogo.jpg";
export { default as vitmeelogo} from "./vitmeelogo.jpg";
export { default as nimcetlogo} from "./nimcetlogo.jpeg";
export { default as jamlogo} from "./jamlogo.png";
export { default as mhtcetlogo} from "./mhtcetlogo.jpg";
export { default as gatelogo1} from "./gatelogo1.jpg";








export {default as NCHMCT} from "./NCHMCT.jpeg";
export {default as upcat} from "./upcat.jpg";
export {default as mhtcet} from "./mhtcet.jpg";
export {default as ipucet} from "./ipucet.png";
export {default as mumbaiuni} from "./mumbaiuni.jpeg";
export {default as delhiuni} from "./delhiuni.avif";
export {default as iitjam} from "./iitjam.jpeg";
export {default as gujaratuni} from "./gujaratuni.jpeg";
export {default as pu} from "./pu.jpeg";
export {default as rajasthanuni} from "./rajasthanuni.jpeg";

