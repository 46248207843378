import React from "react";
// import {
//   Btechimg,
//   designimg,
//   hospilatityimg,
//   lawimg,
//   managementimg,
//   medicalimg,
//   mtechimg,
//   scienceimg,
// } from "../../images";

const ExamMobile = () => {
  const Managementimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/management.jpg";
  const Medicalimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/medical.jpg";
  const Engineeringimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/engineering.jpg";
  const Lawimg = "https://del1.vultrobjects.com/caderaedu/Exams/law.jpg";
  const MassMediaimg = "https://del1.vultrobjects.com/caderaedu/Exams/massmedia.jpg";
  const Humanitiesimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/humanities.jpg";
  const Architectureimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/architecture.jpg";
  const Accountingimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/accounting.jpg";
  const Scienceimg =
    "https://del1.vultrobjects.com/caderaedu/Exams/science.jpg";


  return (
    <div className="sm:hidden p-6 bg-gradient-to-r from-customTeal to-yellow-500 rounded-xl shadow-lg">
      <h3 className="text-2xl font-bold text-black mb-6 text-center">
        Top Exams In India
      </h3>
      <div className="space-y-6">
        <a
          href="/ManagementExams" // Replace with actual URL for B. Tech.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Managementimg}
            alt="Management"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Management</h3>
          </div>
        </a>

        <a
          href="/MedicalExams" // Replace with actual URL for MBA.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Medicalimg}
            alt="Medical"
            className="w-40 h-14 object-cover mr-4 border-2"
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Medical</h3>
          </div>
        </a>

        <a
          href="/EngineeringExams" // Replace with actual URL for Medical.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Engineeringimg}
            alt="Engineering"
            className="w-40 h-14 object-cover mr-4 border-2"
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Engineering</h3>
          </div>
        </a>

        <a
          href="/Lawexams" // Replace with actual URL for M. Tech.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Lawimg}
            alt="Law"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Law</h3>
          </div>
        </a>

        <a
          href="/MassMediaExams" // Replace with actual URL for Design.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={MassMediaimg}
            alt="Mass Media"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Mass Media</h3>
          </div>
        </a>

        <a
          href="/HumanitiesExams" // Replace with actual URL for Hospitality.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Humanitiesimg}
            alt="Humanities"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Humanities</h3>
          </div>
        </a>

        <a
          href="/ScienceExams" // Replace with actual URL for Science.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Scienceimg}
            alt="Science"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Science</h3>
          </div>
        </a>

        <a
          href="/ArchitectureExams" // Replace with actual URL for Science.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Architectureimg}
            alt="Architecture"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Architecture</h3>
          </div>
        </a>

        <a
          href="/AccountingExams" // Replace with actual URL for Science.
          className="flex items-center bg-white p-4 rounded-lg shadow-md transform transition duration-300 hover:scale-105 hover:shadow-xl"
        >
          <img
            src={Accountingimg}
            alt="Accounting"
            className="w-40 h-14 object-cover mr-4 border-2 "
          />
          <div className="flex flex-col justify-center">
            <h3 className="text-lg font-bold text-gray-800">Accounting</h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ExamMobile;
