import React from "react";

const SeatIntakeTable = (props) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-semibold mb-6">
        {props.CollegeName ? `${props.CollegeName} Seat Intake` : "Seat Intake"}
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse bg-white">
          <thead>
            <tr className="bg-blue-100">
              <th className="px-4 py-2 text-left">Department</th>
              <th className="px-4 py-2 text-left">Seat Intake</th>
            </tr>
          </thead>
          <tbody>
            {props.department1 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department1}</td>
                <td className="px-4 py-2">{props.seat1}</td>
              </tr>
            )}
            {props.department2 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department2}</td>
                <td className="px-4 py-2">{props.seat2}</td>
              </tr>
            )}
            {props.department3 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department3}</td>
                <td className="px-4 py-2">{props.seat3}</td>
              </tr>
            )}
            {props.department4 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department4}</td>
                <td className="px-4 py-2">{props.seat4}</td>
              </tr>
            )}
            {props.department5 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department5}</td>
                <td className="px-4 py-2">{props.seat5}</td>
              </tr>
            )}
            {props.department6 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department6}</td>
                <td className="px-4 py-2">{props.seat6}</td>
              </tr>
            )}
            {props.department7 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department7}</td>
                <td className="px-4 py-2">{props.seat7}</td>
              </tr>
            )}
            {props.department8 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department8}</td>
                <td className="px-4 py-2">{props.seat8}</td>
              </tr>
            )}
            {props.department9 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department9}</td>
                <td className="px-4 py-2">{props.seat9}</td>
              </tr>
            )}
            {props.department10 && (
              <tr className="border-b">
                <td className="px-4 py-2">{props.department10}</td>
                <td className="px-4 py-2">{props.seat10}</td>
              </tr>
            )}
            {/* Fallback for no data */}
            {!props.department1 &&
              !props.department2 &&
              !props.department3 &&
              !props.department4 &&
              !props.department5 &&
              !props.department6 &&
              !props.department7 &&
              !props.department8 &&
              !props.department9 &&
              !props.department10 && (
                <tr>
                  <td colSpan="2" className="px-4 py-2 text-center">
                    No data available
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeatIntakeTable;
