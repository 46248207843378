import React from 'react';
import { Link } from 'react-router-dom';
const More = ({ isDropdownOpen, handleDropdownToggle, activeSubmenu, handleMouseEnter, handleMouseLeave }) => {
 
  const handleOptionSelect = () => {
    handleDropdownToggle(''); // Closing the dropdown by resetting the open state
  };
  
 
 
 
 
 
  return (
    <div className="relative text-xs">
   <button className="text-white">
  <a href="https://www.ecadera.com" target="_blank" rel="noopener noreferrer">
    ECADERA
  </a>
</button>

      {isDropdownOpen === 'More' && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4   overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="">
              <li>
                Top Ranked More
              </li>
              <li>
                Popular More
              </li>
              <li>
                More Exams
              </li>
              {/* <li>
                Colleges By Location
              </li> */}
              <li>
                Rank Predictors
              </li>
              {/* <li>College Predictors </li> */}
              {/* <li>
              
                 More Reviews
              </li> */}
              <li>
                Resources
              </li>
            </ul>
          </div>

          
          {/* {activeSubmenu === 'TopRankedMore' && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter('TopRankedMore')}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-sm">Top More in India</li>
                <li className="text-sm">Top Private More</li>
                <li className="text-sm">Top More in Delhi</li>
                <li className="text-sm">Top More in Mumbai</li>
                <li className="text-sm">Top More in Bangalore</li>
              </ul>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default More;
