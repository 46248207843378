import React,{useState, useEffect} from "react";
import { CollegeCard } from "./index";
import { collegeData } from "../data/collageData";

const CollageGrid = () => {
  const [collegesData, setCollegesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the backend API
    const fetchColleges = async () => {
      try {
        const response = await fetch('https://caderaedu.com/api/college/all');
        const data = await response.json();
        setCollegesData(data.colleges);
        // console.log(data.colleges); // Debugging: check fetched data
        setLoading(false);
      } catch (err) {
        setError('Failed to load college data.');
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="bg-gray-100 py-6 sm:py-5 md:py-10">
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 sm:mb-6 md:mb-8">
        Top Colleges
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 sm:gap-6 px-4 sm:px-6 md:px-10">
        {collegesData.map((college, index) => (
          <CollegeCard key={index} college={college} />
        ))}
      </div>
    </div>
  );
};

export default CollageGrid;
