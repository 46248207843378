import React from "react";

const HeroMob = () => {
  return (
    <div className="bg-teal-600 text-white py-8 sm:hidden block">
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">
          Find Colleges, Courses & Exams that are Best for You
        </h2>
        <div className="flex justify-around mt-6 text-lg">
          <div className="text-center">
            <p className="font-bold">62k</p>
            <p>Colleges</p>
          </div>
          <div className="border-l border-white pl-4 text-center">
            <p className="font-bold">432k</p>
            <p>Courses</p>
          </div>
          <div className="border-l border-white pl-4 text-center">
            <p className="font-bold">590k</p>
            <p>Reviews</p>
          </div>
          <div className="border-l border-white pl-4 text-center">
            <p className="font-bold">958</p>
            <p>Exams</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroMob;
