import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [searched, setSearched] = useState(false);  // Track if a search has been performed
  const navigate = useNavigate();

  const handleSearch = async () => {
  
    try {
      const response = await fetch(`https://caderaedu.com/api/college/search?name=${query}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const coll = data.colleges;
      setResults(coll);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  setSearched(true);  // Set to true after a search attempt
  };

  const handleCollegeClick = (slug) => {
    navigate(`/college/${slug}`);
  };

  return (
    <div className="flex flex-col items-center p-4 md:p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Search Colleges</h1>
      
      <div className="w-full md:w-1/2 flex flex-col md:flex-row items-center gap-2">
        <input
          type="text"
          placeholder="Type to search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
        />
        <button
          onClick={handleSearch}
          className="w-full md:w-auto px-4 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
        >
          Search
        </button>
      </div>

      {searched && (  // Show results section only after search
        <div className="w-full md:w-1/2 mt-6 bg-white shadow-lg rounded-lg p-4">
          {results.length === 0 ? (
            <p className="text-center text-gray-500">No results found</p>
          ) : (
            results.map((college) => (
              <div
                key={college.slug}
                onClick={() => handleCollegeClick(college.slug)}
                className="p-3 text-gray-800 cursor-pointer border-b border-gray-200 hover:bg-gray-100 transition"
              >
                {college.name}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;

