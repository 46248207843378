[
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 56944
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 51114
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD MEDICINE",
    "Rank": 50310
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS ENT",
    "Rank": 74341
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS SURGERY",
    "Rank": 50232
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 54954
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD TB & CHEST",
    "Rank": 57792
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 30132
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD ANATHESIA",
    "Rank": 98986
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 121160
  },
  {
    "college_name": "MAYO MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 27332
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD MEDICINE",
    "Rank": 59108
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 53392
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 61287
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 53634
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS SURGERY",
    "Rank": 72123
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 38833
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD TB & CHEST",
    "Rank": 59186
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 14563
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD ANATHESIA",
    "Rank": 80446
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 110236
  },
  {
    "college_name": "RAJSHREE MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PHARMACOLOGY",
    "Rank": 106860
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MS SURGERY",
    "Rank": 62275
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD MEDICINE",
    "Rank": 35853
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 63352
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 34787
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD TB & CHEST",
    "Rank": 59357
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 27007
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MS ENT",
    "Rank": 77681
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 46673
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 9820
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 57077
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD ANATHESIA",
    "Rank": 72751
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 128525
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 88684
  },
  {
    "college_name": "SUBHARTI MEDICAL COLLEGE,",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 40095
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS SURGERY",
    "Rank": 56066
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD MEDICINE",
    "Rank": 31892
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 46700
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 28427
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD TB & CHEST",
    "Rank": 52157
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 23108
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS ENT",
    "Rank": 77621
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 40856
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 19354
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 37719
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD RADIOTHERAPY",
    "Rank": 60077
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 41710
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 112185
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD ANATHESIA",
    "Rank": 63692
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 114724
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD MICROBIOLOGY",
    "Rank": 101798
  },
  {
    "college_name": "SHRI RAM MURTI MEDICAL COLLEGE, BAREILLY",
    "Branch_name": "MD PHARMACOLOGY",
    "Rank": 97896
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 46390
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 42436
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 22252
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 60172
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD MEDICINE",
    "Rank": 43300
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MS SURGERY",
    "Rank": 83712
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 62232
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 25119
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD TB & CHEST",
    "Rank": 55713
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 62597
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MS ENT",
    "Rank": 69306
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD ANATHESIA",
    "Rank": 71015
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 95072
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 99643
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD MICROBIOLOGY",
    "Rank": 105559
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD PHARMACOLOGY",
    "Rank": 83110
  },
  {
    "college_name": "SCHOOL OF MEDICAL SCIENCE,SHARDA UNIVERSITY",
    "Branch_name": "MD BIOCHEMISTRY",
    "Rank": 75092
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 43455
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD MEDICINE",
    "Rank": 48991
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 15710
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MS SURGERY",
    "Rank": 72624
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 50432
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 24239
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD TB & CHEST",
    "Rank": 61898
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MS ENT",
    "Rank": 71067
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD ANATHESIA",
    "Rank": 80605
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 111395
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 104026
  },
  {
    "college_name": "SARASWATI MEDICAL COLLEGE, HAPUR",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 44280
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 50339
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD MEDICINE",
    "Rank": 45146
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 42237
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MS SURGERY",
    "Rank": 65670
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 63749
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 26237
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD TB & CHEST",
    "Rank": 59744
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 64612
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MS ENT",
    "Rank": 85589
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD ANATHESIA",
    "Rank": 86031
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 109286
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 89319
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 47582
  },
  {
    "college_name": "MUZAFFARNAGAR MEDICAL, MUZAFFARNAGAR COLLEGE",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 68691
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 58617
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 31642
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MS SURGERY",
    "Rank": 77287
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD MEDICINE",
    "Rank": 46526
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 53235
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 77246
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD TB & CHEST",
    "Rank": 54754
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 71330
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MS ENT",
    "Rank": 86062
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 78326
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD ANATHESIA",
    "Rank": 78901
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 112217
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 112230
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD RESPIRATORY MEDICINE",
    "Rank": 63789
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 58617
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD GENERAL MEDICINE",
    "Rank": 46526
  },
  {
    "college_name": "ROHILKHAND MEDICAL COLLEGE,BAREILLY",
    "Branch_name": "MD EMERGENCY MEDICINE",
    "Rank": 81509
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 66963
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 43643
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 143350
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MS SURGERY",
    "Rank": 70966
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MD MEDICINE",
    "Rank": 39248
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 73989
  },
  {
    "college_name": "HIND MEDICAL COLLEGE SITAPUR",
    "Branch_name": "MD ANATHESIA",
    "Rank": 151302
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS SURGERY",
    "Rank": 73863
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 37678
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 32926
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 71349
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 68540
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 29020
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MS ENT",
    "Rank": 87165
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 108196
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 107957
  },
  {
    "college_name": "HIND MEDICAL COLLEGE, BARABANKI",
    "Branch_name": "MD PHARMACOLOGY",
    "Rank": 99942
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 50391
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MD TB & CHEST",
    "Rank": 59788
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MS SURGERY",
    "Rank": 38662
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 70144
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 29781
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 39154
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MS ENT",
    "Rank": 54007
  },
  {
    "college_name": "HERITAGE MEDICAL COLLEGE",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 119505
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD MEDICINE",
    "Rank": 41776
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MS SURGERY",
    "Rank": 81039
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD ANATHESIA",
    "Rank": 114246
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 174824
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 180547
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD PHARMACOLOGY",
    "Rank": 111459
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD MICROBIOLOGY",
    "Rank": 105353
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD PULMONARY",
    "Rank": 65502
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD DERMATOLOGY/VENEROLOGY/LEPROSY",
    "Rank": 35441
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD PSYCHIATRY",
    "Rank": 93480
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "RADIOLOGY",
    "Rank": 24123
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MD PAEDIACTRICS",
    "Rank": 51480
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE, KANPUR",
    "Branch_name": "MS OBS &GYN",
    "Rank": 48988
  },
  {
    "college_name": "T.S. MISHRA MEDICAL COLLEGE, LUCKNOW",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 112173
  },
  {
    "college_name": "T.S. MISHRA MEDICAL COLLEGE, LUCKNOW",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 112237
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 45343
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 43061
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 23869
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 60946
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD TB & CHEST",
    "Rank": 66419
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD MEDICINE",
    "Rank": 56264
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MS ENT",
    "Rank": 79898
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 43915
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 57265
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MS SURGERY",
    "Rank": 54689
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD ANATHESIA",
    "Rank": 66863
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 82852
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD COMMUNITY MEDICINE",
    "Rank": 101490
  },
  {
    "college_name": "ERA MEDICAL COLLEGE",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 74173
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 55849
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 47676
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD MEDICINE",
    "Rank": 53985
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 70623
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 51700
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MS SURGERY",
    "Rank": 69473
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MS OBS AND GYN",
    "Rank": 50369
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 50287
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD TB & CHEST",
    "Rank": 66420
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD ANATHESIA",
    "Rank": 78780
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 78140
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MS ENT",
    "Rank": 60853
  },
  {
    "college_name": "TMU MEDICAL COLLEGE, MORADABAD",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 94288
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 48793
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD MEDICINE",
    "Rank": 58229
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 43098
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 65760
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 99019
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 76380
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MS OPTHAMOLOGY",
    "Rank": 85075
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MS SURGERY",
    "Rank": 83387
  },
  {
    "college_name": "F.H. MEDICAL COLLEGE",
    "Branch_name": "MD ANATHESIA",
    "Rank": 91159
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD TB & CHEST",
    "Rank": 68395
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 38618
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 64809
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD MEDICINE",
    "Rank": 60150
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 63364
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD PSYCHOLOGY",
    "Rank": 77559
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD ANATHESIA",
    "Rank": 93198
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MS ENT",
    "Rank": 93272
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 105534
  },
  {
    "college_name": "CAREER MEDICAL COLLEGE",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 50272
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 61629
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD MEDICINE",
    "Rank": 39897
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 26182
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD ANATHESIA",
    "Rank": 114190
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "OTORHINORYNGOLOGY",
    "Rank": 74972
  },
  {
    "college_name": "RAMA MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD OBS & GYN",
    "Rank": 43446
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MD PAEDITRICS",
    "Rank": 60118
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MD ANAESTHESIA",
    "Rank": 126725
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MD MEDICINE",
    "Rank": 47546
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "PSYCHIATRY",
    "Rank": 103797
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MD SKIN",
    "Rank": 40181
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "TB& CHEST",
    "Rank": 74030
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MS ENT",
    "Rank": 126621
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MS OPTHALMOLOGY",
    "Rank": 96889
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "ORTHOPAEDICS",
    "Rank": 76368
  },
  {
    "college_name": "PRASAD INSTITUTE OF MEDICAL SCIENCES, LUCKNOW",
    "Branch_name": "MS GENERAL SURGERY",
    "Rank": 86697
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD GENRAL MEDICINE",
    "Rank": 38255
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MS OPTHALMOLOGY",
    "Rank": 94557
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD PAEDIATRICS",
    "Rank": 52664
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD PSYCHIATRY",
    "Rank": 89828
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD DERMATOLOGY , VENEREOLOGY, LEPROSY",
    "Rank": 31893
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MS GENERAL SURGERY",
    "Rank": 84804
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD RESPIRATORY",
    "Rank": 68332
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MS OBS & GYN",
    "Rank": 47009
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MD RADIO DIAGNOSIS",
    "Rank": 23327
  },
  {
    "college_name": "G.S. MEDICAL COLLEGE AND HOSPITAL , HAPUR",
    "Branch_name": "MS ORTHOPADICS",
    "Rank": 69484
  },
  {
    "college_name": "NATIONAL CAPITAL REGION INSTITUTE OF MEDICAL SCIENCES , MEERUT (S.H.Y MEDICAL COLLEGE , MEERUT",
    "Branch_name": "MD PATHOLOGY",
    "Rank": 149025
  },
  {
    "college_name": "NATIONAL CAPITAL REGION INSTITUTE OF MEDICAL SCIENCES , MEERUT (S.H.Y MEDICAL COLLEGE , MEERUT",
    "Branch_name": "COMMUNITY MED",
    "Rank": 1822621
  },
  {
    "college_name": "KRISHNA MOHAN MEDICAL COLLEGE AND  HOSPITAL , MATHURA",
    "Branch_name": "MD GENRAL SURGERY",
    "Rank": 89053
  },
  {
    "college_name": "KRISHNA MOHAN MEDICAL COLLEGE AND  HOSPITAL , MATHURA",
    "Branch_name": "MD SKIN(DERMATOLOGY)",
    "Rank": 43460
  },
  {
    "college_name": "KRISHNA MOHAN MEDICAL COLLEGE AND  HOSPITAL , MATHURA",
    "Branch_name": "OBS & GYN",
    "Rank": 57079
  },
  {
    "college_name": "KRISHNA MOHAN MEDICAL COLLEGE AND  HOSPITAL , MATHURA",
    "Branch_name": "MD MEDICINE",
    "Rank": 50272
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MD OBS AND GYN",
    "Rank": 381
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MD MEDICINE",
    "Rank": 402
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MS ORTHOPAEDICS",
    "Rank": 326
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MD PAEDIATRICS",
    "Rank": 374
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "RADIODIAOGNOSIS",
    "Rank": 451
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "SKIN",
    "Rank": 426
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MS SURGERY",
    "Rank": 313
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "TB &CH/ RESPIRATORY MEDICINE",
    "Rank": 337
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MS ANAESTHSIA",
    "Rank": 249
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "ORTHOPADICS",
    "Rank": 355
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "OPTHALMOLOGY",
    "Rank": 308
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "PSYCHIATRY",
    "Rank": 316
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "RADIODIGNOSIS",
    "Rank": 461
  },
  {
    "college_name": "VARUN ARJUN  MEDICAL COLLEGE AND ROHILKHAND HOSPITAL , SHAHJHANPUR",
    "Branch_name": "MS ENT",
    "Rank": 277
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "MD PSYCHIATRY",
    "Rank": 74966
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "MEDICINE",
    "Rank": 50991
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "PAEDIATRICS",
    "Rank": 66931
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "OBS & GYN",
    "Rank": 58571
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "MD RADIOLOGY(RADIODIAGONOSIS)",
    "Rank": 28500
  },
  {
    "college_name": "SHREE VENKATESHWAR INSTITUTE OF MEDICAL SCIENCE, GAJROLA , AMROHA",
    "Branch_name": "MD ANATHESIA",
    "Rank": 124295
  },
  {
    "college_name": "UNITED INSTITUTE OF MEDICAL SCIENCES , PRYAGRAJ",
    "Branch_name": "PATHOLOGY",
    "Rank": 167159
  }
]