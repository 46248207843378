import React from 'react';
import { Link } from 'react-router-dom';
const Law = ({ isDropdownOpen, handleDropdownToggle, activeSubmenu, handleMouseEnter, handleMouseLeave }) => {
  const handleOptionSelect = () => {
    handleDropdownToggle(''); // Closing the dropdown by resetting the open state
  };
  
  
  
  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle('Law')}
        className="text-white"
      >
        LAW {isDropdownOpen === 'Law' ? '▲' : '▼'}
      </button>
      {isDropdownOpen === 'Law' && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter('TopRankedLawColleges')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Top Ranked Colleges
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('PopularLawCourses')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Popular Courses
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawExams')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Exams
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawCollegesByLocation')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Colleges By Location
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawRankPredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Rank Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawCollegePredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawCollegeReviews')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Reviews
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('LawResources')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Resources
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked Law Colleges */}
          {activeSubmenu === 'TopRankedLawColleges' && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter('TopRankedLawColleges')}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs"><Link to="/TopLawCollegesinIndia" onClick={handleOptionSelect}>Top Law Colleges in India</Link></li>
                <li className="text-xs"><Link to="/TopNationalLawUniversities" onClick={handleOptionSelect}>Top National Law Universities</Link></li>
                <li className="text-xs"><Link to="/TopPrivateLawColleges" onClick={handleOptionSelect}>Top Private Law Colleges</Link></li>
                <li className="text-xs"><Link to="/TopLawCollegesinDelhi" onClick={handleOptionSelect}>Top Law Colleges in Delhi</Link></li>
                <li className="text-xs"><Link to="/TopLawCollegesinMumbai" onClick={handleOptionSelect}>Top Law Colleges in Mumbai</Link></li>
              </ul>
            </div>
          )}

          <div className="w-[200px] ml-auto">
            <h4 className="text-sm font-bold mb-2">Featured Colleges</h4>
            <ul className="space-y-2">
              <li className="text-xs hover:bg-gray-200 py-2 transition">
                DEF Law College
              </li>
              <div className="flex justify-center items-center p-4 bg-gray-100 rounded-lg shadow">
                <img src=""  className="max-w-full h-auto rounded-md" />
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Law;
