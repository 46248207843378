import React, { useState } from "react";
import { contactBackground } from "../../images/index";

const ContactPopup = ({ isOpen, closePopup }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const contactRef = ref(database, "contacts/" + new Date().getTime());

    set(contactRef, formData)
      .then(() => {
        alert("Message sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" }); // Reset form
      })
      .catch((error) => {
        alert("Error sending message: " + error.message);
      });
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex backgroundImage: justify-center items-center z-50 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div
        className="bg-none p-6 rounded-md shadow-lg relative w-3/4 md:w-1/2"
        style={{
          backgroundImage: `url(${contactBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <button
          className="absolute top-4 right-6 text-2xl font-bold text-gray-600 hover:text-black-500"
          onClick={closePopup}
        >
          &times;
        </button>
        <div className="flex flex-col md:flex-row">
          <div className="md:border-none border-[#d9dad9] pr-6 w-full md:w-1/2 h-1/2 md:h-full">
            <h2 className="text-lg font-bold text-[#525a57] text-center">
              Contact Details
            </h2>
            <p className="mt-2 text-[#525a57]">
              <strong>
                Address: 5th Floor, JS Arcade, Sector 18, Noida, Uttar Pradesh,
                Pin Code – 201301
              </strong>
            </p>
            <p className="mt-2 text-[#525a57]">
              <strong>Phone: (+91) 123456789</strong>
            </p>
            <p className="mt-2 text-[#525a57]">
              <strong>Email: Caderaedu6@gmail.com</strong>
            </p>
          </div>
          <div className="hidden md:block border-l border-[#d9dad9] h-auto mx-6"></div>
          <div className="mt-6 md:mt-0 md:ml-6 w-full md:w-1/2 h-1/2 md:h-full">
            <h2 className="text-lg font-bold text-[#525a57] text-center">
              Send Us a Message
            </h2>
            <form
              className="mt-4 flex flex-col space-y-4"
              onSubmit={handleSubmit}
            >
              <label className="text-[#525a57]">
                Name:
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="border-none rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#68bebf] focus:border-transparent"
                />
              </label>
              <label className="text-[#525a57]">
                Phone No.:
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  className="border-none rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#68bebf] focus:border-transparent"
                />
              </label>
              <label className="text-[#525a57]">
                Email:
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="border-none rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-[#68bebf] focus:border-transparent"
                />
              </label>
              <label className="text-[#525a57]">
                Message:
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="border-none rounded p-2 w-full resize-none focus:outline-none focus:ring-2 focus:ring-[#68bebf] focus:border-transparent"
                />
              </label>

              <button
                type="submit"
                className="bg-[#68bebf] text-white p-2 rounded hover:bg-[#5a9293] transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;
