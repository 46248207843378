import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle, signInWithEmailAndPassword, auth } from "../firebase";
import { Blacklogo } from "../../images";
import { Link } from "react-router-dom";
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      await user.reload();
      
      if (user.emailVerified) {
        const token = await user.getIdToken();
        const response = await fetch("https://caderaedu.com/api/user/verify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          // Save user data in sessionStorage
          sessionStorage.setItem("user", JSON.stringify({
            email: user.email,
            displayName: user.displayName,
           
            
          }));
        console.log(user);
        
          navigate("/profile");
        } else {
          setError("Error verifying user account. Please try again.");
        }
      } else {
        setError("Please verify your email before logging in.");
      }
    } catch (error) {
      setError("Login failed. Please check your credentials.");
      console.error("Error during login:", error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      const user = auth.currentUser;
      const token = await user.getIdToken();

      const response = await fetch("https://caderaedu.com/api/user/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Save user data in sessionStorage
        sessionStorage.setItem("user", JSON.stringify({
          email: user.email,
          displayName: user.displayName,
        }));

        navigate("/profile");
      } else {
        setError("Google login failed.");
      }
    } catch (error) {
      setError("Google login failed.");
      console.error("Google sign-in error:", error.message);
    }
  };



  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src={Blacklogo} // Replace with your logo URL
            alt="Logo"
            className="h-12"
          />
        </div>
        <h2 className="text-xl font-semibold text-center mb-4">
          Login to get started!
        </h2>
        <p className="text-center text-gray-600 mb-4">
          Login using social networks
        </p>

        {/* Google Login Button */}
        <div className="flex justify-center mb-4">
          <button
            onClick={handleGoogleLogin}
            className="bg-red-500 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
          >
            G
          </button>
        </div>

        <div className="text-center text-gray-600 mb-4">
          <p className="flex items-center mb-6 text-gray-600">
            <span className="flex-1 border-t border-gray-400"></span>
            <span className="px-2">or</span>
            <span className="flex-1 border-t border-gray-400"></span>
          </p>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {/* Email and Password Fields */}
        <form onSubmit={handleLogin} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />

          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-teal-500 text-white w-full py-2 rounded-lg hover:bg-teal-600 transition duration-200"
            >
              Sign In
            </button>
          </div>

          <button
            // onClick={handleAdminLogin}
            className="bg-teal-500 mt-2 text-center text-white w-full py-2 rounded-lg border border-teal-500 hover:bg-teal-600 transition duration-200"
          >
            Sign in as Admin
          </button>
        </form>

        <div className="text-center mt-4">
          <Link
            to="/forgotpasswordpage"
            className="block mt-4 text-teal-500 text-sm hover:underline"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="text-center mt-4">
          <Link
            to="/signup"
            className="block mt-4 text-teal-500 text-sm hover:underline"
          >
            New Here? Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
