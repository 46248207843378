import React from "react";
import { heroBackground } from "../../images/index";

const Hero = () => {
  return (
    <section
      className="hero bg-cover bg-center bg-no-repeat bg-fixed text-white text-center py-[100px] mt-[1px] hidden md:block" // Hidden on mobile screens
      style={{ backgroundImage: `url(${heroBackground})` }}
    >
      <div className="hero-content">
        <h1 className="text-[3em]">
          We provide you expert counselling and career guidance
        </h1>
        <p className="text-[1.5em]">
          Explore the courses, colleges, exams and more with Cadera Edu
        </p>
        <button className="cta-btn bg-[#d9dad9] text-[#68a4a5] px-5 py-2 mt-4 transition-colors duration-300 hover:bg-white hover:text-[#68a4a5]">
          Discover Everything
        </button>
      </div>
    </section>
  );
};

export default Hero;
