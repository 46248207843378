// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";

// const CatExam = (props) => {
//   const navigate = useNavigate();
//   const { examName } = useParams(); // Capture dynamic exam type from URL
//   const faqs = [
//     {
//       question: "How much percentile is a good score in CAT exam?",
//       answer: "A score above 90 percentile is generally considered good.",
//     },
//     {
//       question: "What is the CAT 2024 admit card download date?",
//       answer: "November 5, 2024, from 4:00 pm onwards.",
//     },
//     {
//       question: "What is CAT exam pattern 2024?",
//       answer: "CAT 2024 will follow a pattern of 3 sections: VARC, DILR, and QA.",
//     },
//     {
//       question: "What is CAT exam pattern 2024?",
//       answer: "CAT 2024 will follow a pattern of 3 sections: VARC, DILR, and QA.",
//     },
    
//   ];

//   const iimCourses = [
//     { iim: "IIM Ahmedabad", courses: "PGP, PGP-FABM, ePGP, PhD" },
//     { iim: "IIM Bangalore", courses: "PGP, PGPEM, PGPBA, PhD" },
//     { iim: "IIM Calcutta", courses: "MBA, PhD" },
//   ];

//   // const tabs = [

//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" },
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-4 md:p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white mb-6">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//           {props.CatExamHeaderHeading1} {props.CatExamYear} : {props.CatExamaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//             {props.CatExamHeaderadmitcardname}: {props.CatExamHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.CatExamHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.CatExamHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => tab.path && navigate(tab.path)} // Ensure path exists
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       {/* Content Section */}
//       <div className="bg-gray-100  p-6 rounded-lg mb-6">
//         <h1 className="text-xl font-bold mb-4 text-left">{props.CatExamheading1}</h1>
//         <p className="text-gray-600 mt-2">
//           {props.CatExamheading1description1}
//                   </p>
//       </div>

//       <div className="bg-white shadow p-4 rounded-lg mb-4">
//         <h2 className="text-lg font-semibold">{props.CatExamheading2}</h2>
//         <p className="text-gray-600 mb-4">
//         {props.CatExamheading2description2}
//         </p>
//         <div className="overflow-auto">
//           <table className="min-w-full bg-white text-left text-gray-600 border rounded-lg">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2 border-b">{props.CatExamcoursetableHeadername1}</th>
//                 <th className="px-4 py-2 border-b">{props.CatExamcoursetableHeadernameCoursesOffered1}</th>
//               </tr>
//             </thead>
//             <tbody>

//               {iimCourses.map((row, index) => (
//                 <tr key={index}>
//                   <td className="px-4 py-2 border-b">{row.iim}</td>
//                   <td className="px-4 py-2 border-b">{row.courses}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       <div className="bg-white shadow p-4 rounded-lg mb-4">
//         <h2 className="text-lg font-semibold">Frequently Asked Questions</h2>
//         {faqs.map((faq, index) => (
//           <FAQItem key={index} question={faq.question} answer={faq.answer} />
//         ))}
//       </div>
//     </div>
//   );
// };

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <div className="border-b border-gray-200 py-2">
//       <button onClick={() => setIsOpen(!isOpen)} className="text-left w-full flex justify-between items-center">
//         <span className="font-semibold">{question}</span>
//         <span>{isOpen ? "-" : "+"}</span>
//       </button>
//       {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
//     </div>
//   );
// };

// export default CatExam;

// // CSS for Mobile Responsiveness
// <style jsx>{`
//   @media (max-width: 768px) {
//     .flex {
//       flex-direction: column;
//     }

//     .bg-white {
//       padding: 1rem;
//     }

//     .text-2xl {
//       font-size: 1.75rem; /* Slightly smaller font size for headers */
//     }

//     .text-lg {
//       font-size: 1.25rem; /* Smaller font size for subheaders */
//     }

//     .overflow-x-auto {
//       overflow-x: auto; /* Enable horizontal scrolling */
//       padding-bottom: 1rem; /* Add padding to the bottom for better visibility */
//     }

//     /* Style for the table */
//     table {
//       border-collapse: collapse;
//       width: 100%;
//       overflow: auto; /* Allow the table to scroll horizontally */
//     }

//     th,
//     td {
//       padding: 8px; /* Padding for table cells */
//       text-align: left;
//       white-space: nowrap; /* Prevent text from wrapping */
//     }

//     /* Enhance button styling on mobile */
//     button {
//       padding: 12px 16px; /* Increase button padding */
//       border-radius: 5px; /* Rounded corners for buttons */
//       font-weight: bold; /* Bold font for better visibility */
//     }

//     /* Adjust FAQ item styles */
//     .border-b {
//       border-bottom-width: 1px; /* Ensure bottom border is clear */
//     }

//     /* Add some spacing for the FAQ items */
//     .py-2 {
//       padding-top: 0.5rem;
//       padding-bottom: 0.5rem;
//     }
//   }
// `}</style>
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CatExam = () => {
  const navigate = useNavigate();
  const { examName } = useParams(); // Capture dynamic exam type from URL
  const [examData, setExamData] = useState(null);
  
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        console.log(examName);
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (response.ok) {
          const data = await response.json();
          setExamData(data);
        } else {
          console.error("Error fetching exam data");
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData) return <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    animation: 'fadeIn 1.5s ease-in-out'
}}>
    {!examData && (
        <>
            <p style={{ fontSize: '30px', color: '#ff4d4f', marginBottom: '10px', animation: 'bounce 2s infinite' }}>
                OOPS !!! This time NO DATA FOUND....
            </p>
            <p style={{ fontSize: '20px', color: '#555', animation: 'fadeIn 2s 1s forwards', opacity: 0 }}>
                CaderaEdu is working hard to update things. Please check back soon!
            </p>
        </>
    )}
    <style>
        {`
            @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
            }

            @keyframes bounce {
                0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
                40% { transform: translateY(-20px); }
                60% { transform: translateY(-10px); }
            }
        `}
    </style>
</div>;


  const { faqs, institutionCourses, tabs, examName: name, year, admitCardName, admitCardDate } = examData;

  return (
    <div className="bg-gray-100 p-4 md:p-8">
      <div className="p-6 rounded-lg shadow-md bg-white mb-6">
        <h1 className="text-2xl font-bold text-gray-800 text-left">
          {name} {year} : Exam Information
        </h1>
        <div className="flex items-center space-x-2 mt-4">
          <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {admitCardName}: {new Date(admitCardDate).toLocaleDateString()}
          </div>
        </div>
      </div>

      {/* Navigation Section with Buttons */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Institution Courses Section */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-semibold">Institution Courses</h2>
        <div className="overflow-auto">
          <table className="min-w-full bg-white text-left text-gray-600 border rounded-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Institution</th>
                <th className="px-4 py-2 border-b">Courses Offered</th>
              </tr>
            </thead>
            <tbody>
              {institutionCourses.map((course, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b">{course.institution}</td>
                  <td className="px-4 py-2 border-b">{course.coursesOffered}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* FAQs Section */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-semibold">Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-gray-200 py-2">
      <button onClick={() => setIsOpen(!isOpen)} className="text-left w-full flex justify-between items-center">
        <span className="font-semibold">{question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

export default CatExam;
