import React from "react";

const Counselling = () => {
  return (
    <>
      <main className="bg-[#d9dad9] text-center py-10">
        <h1 className="text-4xl font-bold text-[#68A4A5] mb-6">Counselling</h1>
        <p className="text-lg text-[#555] px-10">
          Get expert guidance and counselling for your educational journey.
        </p>
      </main>
    </>
  );
};

export default Counselling;
