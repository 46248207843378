export { default as About } from "./About";
export { default as CollegeCard } from "./CollegeCard";
export { default as CollegeGrid } from "./CollegeGrid";
export { default as CollegePredictor } from "./CollegePredictor";
export { default as Colleges } from "./Colleges";
export { default as ContactPopup } from "./ContactPopup";
export { default as Counselling } from "./Counselling";
export { default as Courses } from "./Courses";
export { default as Exams } from "./Exams";
export { default as Footer } from "./Footer";
export { default as Header } from "./Header";
export { default as Hero } from "./Hero";
export { default as StudyGoals } from "./StudyGoals";
export { default as Home } from "./Home";
export { default as LoginPopup } from "./LoginPopup";
export { default as MainContent } from "./MainContent";
export { default as NotFound } from "./NotFound";
export { default as Timesignup } from "./Timesignup";
export { default as CollegeRanking } from "./CollegeRanking";
export { default as NewsletterSubscription } from "./NewsletterSubscription";
export {default as CookiesConsent } from  "./CookiesConsent";
export {default as LoginPage} from "./loginpage";
export {default as ForgotPasswordPage} from "./forgotpasswordpage";
export {default as Signup} from "./signup";
export {default as HeroMob } from "./HeroMob";
export {default as NewsSection } from "./LatestNotificationMob";
export { default as AdmissionChips } from "./admissionchips";
export {default as LawPredictormobile} from "./lawpredictormobile";

export {default as HospitalityPredictormobile} from "./HospitalityPredictor";
export {default as SciencePredictormobile} from "./sciencepredictormobile";
