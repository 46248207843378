import React from "react";

const studyGoals = [
 
  {
    title: "Management",
    colleges: "7652 Colleges",
    courses: [
      { name: "MBA/PGDM", url: "/MBA/PGDM" },
      { name: "BBA/BMS", url: "/BBA/BMS" },
      { name: "Executive MBA", url: "/ExecutiveMBAData" },
    ],
    icon: "📈",
  },
  {
    title: "Medical",
    colleges: "4917 Colleges",
    courses: [
      { name: "MBBS", url: "/MBBS" },
      { name: "PG Medical", url: "/PGMedical" },
    ],
    icon: "🧬",
  },
  {
    title: "Engineering",
    colleges: "6226 Colleges",
    courses: [
      { name: "BE/B.Tech", url: "/Btech" },
      { name: "Diploma in Engineering", url: "/DiplomainEngineering" },
      { name: "ME/M.Tech", url: "/MTech" },
    ],
    icon: "👷‍♂️",
  },
  {
    title: "Commerce",
    colleges: "4917 Colleges",
    courses: [
      { name: "B.Com", url: "/Bcom" },
      { name: "M.Com", url: "/Mcom" },
    ],
    icon: "🛒",
  },
  {
    title: "Arts",
    colleges: "5542 Colleges",
    courses: [
      { name: "BA", url: "/BA" },
      { name: "MA", url: "/MA" },
      { name: "BFA", url: "/BFA" },
      { name: "BSW", url: "/BSW" },
    ],
    icon: "🎨",
  },
  {
    title: "Science",
    colleges: "3986 Colleges",
    courses: [
      { name: "B.Sc", url: "/BSc" },
      { name: "M.Sc", url: "/MSc" },
      { name: "Ph.D", url: "/PhD" },
    ],
    icon: "🧬",
  },
];

const StudyGoals = () => {
  return (
    <div className="container mx-auto my-10 flex flex-col items-center bg-white hidden md:block">
      <h2 className="text-3xl font-bold text-center mb-8">Select Your Study Goal</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-6">
        {studyGoals.map((goal, index) => (
          <div
            key={index}
            className="bg-gray-100 p-6 rounded-lg shadow-md text-center transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl"
          >
            <div className="text-4xl mb-4">{goal.icon}</div>
            <h3 className="text-xl font-semibold mb-2">{goal.title}</h3>
            <p className="text-gray-500 mb-4">{goal.colleges}</p>
            <ul className="text-gray-700">
              {goal.courses.map((course, idx) => (
                <li
                  key={idx}
                  className={`py-1 ${idx !== 0 ? "border-t border-gray-300" : ""}`}
                >
                  <a href={course.url} className="text-blue-500 hover:underline">
                    {course.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudyGoals;
