import React from 'react';
import { Link } from 'react-router-dom';
const Architecture = ({ isDropdownOpen, handleDropdownToggle, activeSubmenu, handleMouseEnter, handleMouseLeave }) => {
 
  const handleOptionSelect = () => {
    handleDropdownToggle(''); // Closing the dropdown by resetting the open state
  };
  
 
 
  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle('Architecture')}
        className="text-white"
      >
        ARCHITECTURE {isDropdownOpen === 'Architecture' ? '▲' : '▼'}
      </button>
      {isDropdownOpen === 'Architecture' && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter('TopRankedArchitectureColleges')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Top Ranked Colleges
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('PopularArchitectureCourses')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Popular Courses
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureExams')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Exams
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureCollegesByLocation')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Colleges By Location
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureRankPredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Rank Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureCollegePredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureCollegeReviews')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Reviews
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('ArchitectureResources')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Resources
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked Architecture Colleges */}
          {activeSubmenu === 'TopRankedArchitectureColleges' && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter('TopRankedArchitectureColleges')}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs"><Link to="/TopArchitectureCollegesinindia" onClick={handleOptionSelect}>Top Architecture Colleges in India</Link></li>
                <li className="text-xs"><Link to="/TopPrivateArchitectureColleges" onClick={handleOptionSelect}>Top Private Architecture Colleges</Link></li>
                <li className="text-xs"><Link to="/TopArchitectureCollegesinDelhi"onClick={handleOptionSelect}>Top Architecture Colleges in Delhi</Link></li>
                <li className="text-xs"><Link to="TopArchitectureCollegesinMumbai"onClick={handleOptionSelect}>Top Architecture Colleges in Mumbai</Link></li>
                <li className="text-xs"><Link to="TopArchitectureCollegesinBangalore"onClick={handleOptionSelect}>Top Architecture Colleges in Bangalore</Link></li>
              </ul>
            </div>
          )}

          <div className="w-[200px] ml-auto">
            <h4 className="text-sm font-bold mb-2">Featured Colleges</h4>
            <ul className="space-y-2">
              <li className="text-xs hover:bg-gray-200 py-2 transition">
                MNO Architecture College
              </li>
              <div className="flex justify-center items-center p-4 bg-gray-100 rounded-lg shadow">
                <img src=""  className="max-w-full h-auto rounded-md" />
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Architecture;
