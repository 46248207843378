import React from 'react';
import { Link } from 'react-router-dom';

const Medical = ({ isDropdownOpen, handleDropdownToggle, activeSubmenu, handleMouseEnter, handleMouseLeave }) => {
  
  // Function to close dropdown after selecting an option
  const handleOptionSelect = () => {
    handleDropdownToggle(''); // Closing the dropdown by resetting the open state
  };

  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle('Medical')}
        className="text-white"
      >
        MEDICAL {isDropdownOpen === 'Medical' ? '▲' : '▼'}
      </button>

      {isDropdownOpen === 'Medical' && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter('TopRankedMedColleges')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Top Ranked Colleges
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('PopularMedCourses')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Popular Courses
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('Exams')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Exams
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('MedCollegesByLocation')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Colleges By Location
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('MedRankPredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Rank Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('MedCollegePredictors')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('MedCollegeReviews')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Reviews
              </li>
              <li
                onMouseEnter={() => handleMouseEnter('MedResources')}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Resources
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked Medical Colleges */}
          {activeSubmenu === 'TopRankedMedColleges' && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter('TopRankedMedColleges')}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs">
                  <Link to="/TopMedicalIndia" onClick={handleOptionSelect}>Top Medical Colleges in India</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopGovernmentMedical" onClick={handleOptionSelect}>Top Government Medical Colleges</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopPrivateMedical" onClick={handleOptionSelect}>Top Private Medical Colleges</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopBangaloreMedical" onClick={handleOptionSelect}>Top Medical Colleges in Bangalore</Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopMumbaiMedical" onClick={handleOptionSelect}>Top Medical Colleges in Mumbai</Link>
                </li>
              </ul>
            </div>
          )}
{activeSubmenu === "Exams" && (
  <div
    className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
    onMouseEnter={() => handleMouseEnter("Exams")}
    onMouseLeave={handleMouseLeave}
  >
    <ul className="space-y-2">
      {/* Medical exams */}
      <li className="text-xs">
        <Link to="/exam/NEET-UG" onClick={handleOptionSelect}>
          NEET-UG Exam
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/exam/AIIMS" onClick={handleOptionSelect}>
          AIIMS Exam
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/exam/JIPMER" onClick={handleOptionSelect}>
          JIPMER Exam
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/exam/PGIMER" onClick={handleOptionSelect}>
          PGIMER Exam
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/exam/FMGE" onClick={handleOptionSelect}>
          FMGE Exam
        </Link>
      </li>
    </ul>
  </div>
)}

          <div className="w-[200px] ml-auto">
            <h4 className="text-sm font-bold mb-2">Featured Colleges</h4>
            <ul className="space-y-2">
              <li className="text-xs hover:bg-gray-200 py-2 transition">
                XYZ Medical College
              </li>
              <div className="flex justify-center items-center p-4 bg-gray-100 rounded-lg shadow">
                <img src=""  className="max-w-full h-auto rounded-md" />
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Medical;
