import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {catlogo,xatlogo,matlogo,Gmatlogo,cmatlogo,snaplogo,ibsatlogo,NMATlogo,tisnetlogo } from "../../images/index";
// MBA Exams array
const mbaExams = [
    { id: 1, 
      name: "CAT", 
      icon:catlogo ,
    },
    { id: 2, 
      name: "XAT", 
      icon: xatlogo,
    },
    { id: 3, 
      name: "MAT", 
      icon:  matlogo,
    },
    { id: 4, 
      name: "GMAT",
      icon: Gmatlogo,
    },
    { id: 5, 
      name: "CMAT", 
      icon:  cmatlogo,
    },
    { id: 6, 
      name: "SNAP", 
      icon: snaplogo,
    },
    { id: 7, 
      name: "IBSAT",
      icon: ibsatlogo,
    },
    { id: 8, 
      name: "NMAT", 
      icon: NMATlogo,
    },
    { id: 9, 
      name: "TISSNET", 
      icon:  tisnetlogo,
    },
    // { id: 10, 
    //   name: "PGDBA", 
    //   icon: ,
    // },
    // { id: 11, 
    //   name: "MICA", 
    //   icon: , 
    // },
    // { id: 12, 
    //   name: "WAT", 
    //   icon: , 
    // },
    // { id: 13, 
    //   name: "UPSC", 
    //   icon: , 
    // },
    // { id: 14, 
    //   name: "KMAT", 
    //   icon: , 
    // },
    // { id: 15, 
    //   name: "ATMA", 
    //   icon: , 
    // },
  
  // Add more exams as needed
];

const MBAPredictormobileview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExams, setSelectedExams] = useState([]);
  const itemsPerPage = 15;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = mbaExams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(mbaExams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    if (selectedExams.includes(exam.name)) {
      setSelectedExams(selectedExams.filter((name) => name !== exam.name));
    } else {
      setSelectedExams([...selectedExams, exam.name]);
    }
  };

  const handleNextClick = () => {
    if (selectedExams.length > 0) {
      const selectedExam = selectedExams[0];

      // Update navigation based on selected MBA exam
      if (selectedExam === "CAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "XAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "MAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "GMAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "CMAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "SNAP") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "IBSAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "NMAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "TISSNET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "PGDBA") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "MICA") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "WAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "UPSC") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "KMAT") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "ATMA") {
        navigate("/#"); // Replace with actual route
      } else {
        console.log("No valid exam selected for navigation");
      }
    }
  };

  return (
    <div className="md:hidden bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        MBA Course Predictor 2024
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on MBA exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
                 Select exam you have taken:
            </h3>
      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
              <img
                                src={exam.icon}
                                alt={exam.name}
                                className="w-full h-full object-contain"
                            /> 
              </div>
              <input
                type="checkbox"
                checked={selectedExams.includes(exam.name)}
                className="absolute inset-0 w-full h-full cursor-pointer opacity-0"
              />
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">{exam.name}</p>
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          disabled={selectedExams.length === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MBAPredictormobileview;
