import React, { useState } from "react";
import data from "./Data/data.json"; // Your data.json file
import CompulsoryTimesignup from "./CompulsoryTimesignup"; // Import the modal component

const NeetUGAllIndiaPredictor = () => {
  const [quota, setQuota] = useState("");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const [minRank, setMinRank] = useState("");
  const [error, setError] = useState("");
  const [showData, setShowData] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission
  const [showSignupModal, setShowSignupModal] = useState(false); // State to control modal visibility

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10); // Show 10 results per page

  const handleQuotaChange = (e) => {
    setQuota(e.target.value);
    setShowSignupModal(true); // Show modal on interaction
  };

  const handleCourseChange = (e) => {
    setCourse(e.target.value);
    setShowSignupModal(true); // Show modal on interaction
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setShowSignupModal(true); // Show modal on interaction
  };

  const handleMinRankChange = (e) => {
    setMinRank(e.target.value);
    setShowSignupModal(true); // Show modal on interaction
  };

  const handleShowData = () => {
    if (!quota || !course || !category || !minRank) {
      setError("We cannot predict without all data !!!!!!!");
      setShowData(false);
    } else {
      setError("");
      setShowData(true);
      setCurrentPage(1); // Reset to the first page when showing data
    }
  };

  const filteredData = data.filter((row) => {
    return (
      (!quota || row["Allotted Quota"].includes(quota)) &&
      (!course || row.Course.includes(course)) &&
      (!category || row["Candidate Category"] === category) &&
      (!minRank || row.Rank >= Number(minRank))
    );
  });

  // Pagination logic: slice the filtered data based on current page
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentData = filteredData.slice(indexOfFirstResult, indexOfLastResult);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / resultsPerPage);

  // Handle changing pages
  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center">
        NEET UG All India Counselling Predictor
      </h2>

      {error && (
        <div className="bg-red-100 text-red-700 p-4 mb-6 rounded-lg border border-red-300">
          {error}
        </div>
      )}

      {/* Compulsory Sign-Up Modal */}
      <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true); // Set form submitted to true once form is completed
          setShowSignupModal(false); // Close modal after successful submission
        }}
        showSignupModal={showSignupModal} // Pass down the state for modal visibility
        onCloseModal={() => setShowSignupModal(false)} // Function to close the modal
      />

      {/* Filters */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {/* Quota Dropdown */}
        <label className="flex flex-col">
          <span className="mb-2 font-bold">Quota:</span>
          <select
            value={quota}
            onChange={handleQuotaChange}
            className="p-2 border border-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="">Select Quota</option>
            <option value="Open Seat Quota">Open Seat Quota</option>
            <option value="All India">All India</option>
            <option value="Delhi University Quota">
              Delhi University Quota
            </option>
            <option value="Deemed/Paid Seats Quota">
              Deemed/Paid Seats Quota
            </option>
            <option value="IP University Quota">IP University Quota</option>
            <option value="Foreign Country Quota">Foreign Country Quota</option>
            <option value="Aligarh Muslim University (AMU) Quota">
              Aligarh Muslim University (AMU) Quota
            </option>
            <option value="Internal - Puducherry UT Domicile">
              Internal - Puducherry UT Domicile
            </option>
            <option value="Employees State Insurance Scheme(ESI)">
              Employees State Insurance Scheme(ESI)
            </option>
            <option value="Delhi NCR Children/Widows of Personnel of the Armed Forces (CW) Quota">
              Delhi NCR Children/Widows of Personnel of the Armed Forces (CW)
              Quota
            </option>
            <option value="Non-Resident Indian(AMU) Quota">
              Non-Resident Indian(AMU) Quota
            </option>
            <option value="Muslim OBC Quota">Muslim OBC Quota</option>
            <option value="B.Sc Nursing All India">
              B.Sc Nursing All India
            </option>
            <option value="Muslim Quota">Muslim Quota</option>
            <option value="Muslim Women Quota">Muslim Women Quota</option>
            <option value="Muslim Minority Quota">Muslim Minority Quota</option>
            <option value="Non-Resident Indian">Non-Resident Indian</option>
            <option value="B.Sc Nursing Delhi NCR">
              B.Sc Nursing Delhi NCR
            </option>
            <option value="Employees State Insurance Scheme Nursing Quota (ESI- IP Quota Nursing)">
              Employees State Insurance Scheme Nursing Quota (ESI- IP Quota
              Nursing)
            </option>
            <option value="Jamia Internal Quota">Jamia Internal Quota</option>
            <option value="Jain Minority Quota">Jain Minority Quota</option>
            <option value="B.Sc Nursing Delhi NCR CW Quota">
              B.Sc Nursing Delhi NCR CW Quota
            </option>
            <option value="Muslim ST Quota">Muslim ST Quota</option>
          </select>
        </label>

        {/* Course Dropdown */}
        <label className="flex flex-col">
          <span className="mb-2 font-bold">Course:</span>
          <select
            value={course}
            onChange={handleCourseChange}
            className="p-2 border border-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="">Select Course</option>
            <option value="MBBS">MBBS</option>
            <option value="BDS">BDS</option>
            <option value="B.Sc. Nursing">B.Sc. Nursing</option>
          </select>
        </label>

        {/* Category Dropdown */}
        <label className="flex flex-col">
          <span className="mb-2 font-bold">Category:</span>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="p-2 border border-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="">Select Category</option>
            <option value="General">General</option>
            <option value="OBC">OBC</option>
            <option value="SC">SC</option>
            <option value="EWS">EWS</option>
            <option value="ST">ST</option>
            <option value="General PwD">General PwD</option>
            <option value="OBC PwD">OBC PwD</option>
            <option value="EWS PwD">EWS PwD</option>
            <option value="ST PwD">ST PwD</option>
            <option value="SC PwD">SC PwD</option>
          </select>
        </label>

        {/* Minimum Rank Input */}
        <label className="flex flex-col">
          <span className="mb-2 font-bold">Rank:</span>
          <input
            type="number"
            value={minRank}
            onChange={handleMinRankChange}
            placeholder="e.g. 1000"
            className="p-2 border border-black rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </label>
      </div>

      {/* Button to Show Data */}
      <button
        onClick={handleShowData}
        disabled={!isFormSubmitted} // Button only enabled after form submission
        className={`${
          isFormSubmitted ? "bg-teal-500" : "bg-gray-400 cursor-not-allowed"
        } text-white px-4 py-2 rounded-lg shadow-md transition-all`}
      >
        Predict Institute
      </button>

      {showData && (
        <div className="mt-6 overflow-x-auto">
          {currentData.length > 0 ? (
            <>
              <table className="min-w-full text-left border border-gray-300">
                <thead>
                  <tr className="bg-blue-50">
                    <th className="px-4 py-2 border">Rank</th>
                    <th className="px-4 py-2 border">Quota</th>
                    <th className="px-4 py-2 border">
                      Expected Allotted Institute
                    </th>
                    <th className="px-4 py-2 border">Course</th>
                    <th className="px-4 py-2 border">Allotted Category</th>
                    <th className="px-4 py-2 border">Candidate Category</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      } hover:bg-blue-100 transition-colors`}
                    >
                      <td className="px-4 py-2 border">{row.Rank}</td>
                      <td className="px-4 py-2 border">
                        {row["Allotted Quota"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {row["Allotted Institute"]}
                      </td>
                      <td className="px-4 py-2 border">{row.Course}</td>
                      <td className="px-4 py-2 border">
                        {row["Alloted Category"]}
                      </td>
                      <td className="px-4 py-2 border">
                        {row["Candidate Category"]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="flex justify-between mt-4">
                <button
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                  className={`${
                    currentPage === 1
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-teal-500"
                  } text-white px-4 py-2 rounded-lg shadow-md`}
                >
                  Previous
                </button>
                <span className="text-lg font-semibold">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                  className={`${
                    currentPage === totalPages
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-teal-500"
                  } text-white px-4 py-2 rounded-lg shadow-md`}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <div className="bg-yellow-100 text-yellow-700 p-4 rounded-lg border border-yellow-300 mt-4">
              Oops! You are not able to get any college with this rank.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NeetUGAllIndiaPredictor;
