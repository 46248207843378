import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// BTech Exams array
const btechExams = [
  {
    id: 1,
    name: "CAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/Catimg.jpg",
    route: "/exam/CAT" // Replace with actual route
  },
  {
    id: 2,
    name: "CMAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/cmat.webp",
    route: "/exam/CMAT" // Replace with actual route
  },
  {
    id: 3,
    name: "SNAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/snat.webp",
    route: "/exam/SNAT" // Replace with actual route
  },
  {
    id: 4,
    name: "XAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/XAT.png",
    route: "/exam/XAT" // Replace with actual route
  },
  {
    id: 5,
    name: "MAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/MAT.jpeg",
    route: "/exam/MAT" // Replace with actual route
  },
  {
    id: 6,
    name: "ATMA Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/ATMA.webp",
    route: "/exam/ATMA" // Replace with actual route
  },
  {
    id: 7,
    name: "NMAT",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/NMAT.avif",
    route: "/exam/NMAT" // Replace with actual route
  },
  {
    id: 8,
    name: "IBSAT Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/IBSAT.webp",
    route: "/exam/IBSAT" // Replace with actual route
  },
  { 
    id: 9,
    name: "UPCET Exam",
    icon: "https://del1.vultrobjects.com/caderaedu/Exams/exam%20imgs/upcet.jpeg",
    route: "/" // Replace with actual route
  },
];

const ManagementExams = () => {
  const navigate = useNavigate();
  const [selectedExams, setSelectedExams] = useState([]);

  const handleExamSelect = (exam) => {
    setSelectedExams((prevSelectedExams) =>
      prevSelectedExams.includes(exam.name)
        ? prevSelectedExams.filter((name) => name !== exam.name)
        : [...prevSelectedExams, exam.name]
    );
    // Navigate to the specific exam route
    navigate(exam.route);
  };

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="md:hidden bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        Top Management Exams
      </h2>
      <h3 className="text-left font-bold mb-4 text-black">
        Select exam:
      </h3>
      <div className="grid grid-cols-3 gap-6">
        {btechExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              <input
                type="checkbox"
                checked={selectedExams.includes(exam.name)}
                className="absolute inset-0 w-full h-full cursor-pointer opacity-0"
              />
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1 text-center">
              {exam.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagementExams;
