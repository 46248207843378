// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate

// const ExamPattern = (props) => {
//   const navigate = useNavigate(); // Initialize navigate function
//   const {examName} = useParams();
//   const [activeIndex, setActiveIndex] = useState(null);

//   // Sample data for FAQ items
//   const faqs = [
//     {
//       question: "What’s new in CAT exam?",
//       answer: "This is the answer to the question 'What’s new in CAT exam?'.",
//     },
//     {
//       question: "What is the sectional time limit in CAT?",
//       answer: "This is the answer to the question 'What is the sectional time limit in CAT?'.",
//     },
//     {
//       question: "Which CAT slot has the toughest question paper?",
//       answer: "This is the answer to the question 'Which CAT slot has the toughest question paper?'.",
//     },
//     {
//       question: "Will CAT exam 2024 also be conducted in three shifts?",
//       answer: "This is the answer to the question 'Will CAT exam 2024 also be conducted in three shifts?'.",
//     },
//     {
//       question: "How many questions should I solve to score 99 percentile in CAT exam?",
//       answer: "This is the answer to the question 'How many questions should I solve to score 99 percentile in CAT exam?'.",
//     },
//     {
//       question: "What makes CAT tougher than other MBA entrance exams?",
//       answer: "This is the answer to the question 'What makes CAT tougher than other MBA entrance exams?'.",
//     },
//     {
//       question: "Where can I get official CAT mock test?",
//       answer: "This is the answer to the question 'Where can I get official CAT mock test?'.",
//     },
//     {
//       question: "What is the CAT exam duration? Can I change my exam slot?",
//       answer: "This is the answer to the question 'What is the CAT exam duration? Can I change my exam slot?'.",
//     },
//   ];

//   // Sample data for CAT Pattern Table
//   const catPatternData = [
//     { feature: "Mode of CAT exam", details: "Computer Based Test" },
//     { feature: "Duration of exam", details: "120 minutes (40 minutes per section)" },
//     {
//       feature: "CAT exam slots",
//       details: "Morning: 8:30 AM to 10:30 AM\nAfternoon: 12:30 PM to 2:30 PM\nEvening: 4:30 PM to 6:30 PM",
//     },
//     { feature: "Number of questions", details: "66" },
//     { feature: "CAT total marks", details: "198" },
//     { feature: "Language of question paper", details: "English" },
//     { feature: "Number of answer choices", details: "4" },
//     { feature: "Question Type", details: "MCQ and non-MCQ" },
//   ];

//   // Function to toggle FAQ accordion
//   const toggleAccordion = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.ExamPatternHeaderHeading1} {props.ExamPatternYear} : {props.ExamPatternaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.ExamPatternHeaderadmitcardname}: {props.ExamPatternHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.CatExamHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.ExamPatternHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="p-6 bg-gray-50 text-gray-800">
//         {/* Exam Pattern Overview */}
//         <div className="mb-6">
//           <h1 className="text-2xl font-bold">{props.ExamPatterncatexamHeadingName1}</h1>
//           {props.ExamPatterncatexamHeadingName1Description1}
//         </div>

//        {/* CAT Pattern Table */}
//        <div className="my-6">
//           <h2 className="text-lg font-bold mb-2">{props.ExamPatternTable1headingtitleName1}</h2>
//           <table className="w-full bg-white text-gray-700 border border-gray-300 text-sm">
//             <thead>
//               <tr className="bg-gray-100">
//                 <th className="p-3 border border-gray-300 text-left">{props.ExamSyllabusTable1headingName1}</th>
//                 <th className="p-3 border border-gray-300 text-left">{props.ExamSyllabusTable1headingName2}</th>
//               </tr>
//             </thead>
//             <tbody>
//               {catPatternData.map((item, index) => (
//                 <tr key={index}>
//                   <td className="p-3 border border-gray-300">{item.feature}</td>
//                   <td className="p-3 border border-gray-300">{item.details}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {/* FAQ Accordion */}
//         <div className="my-6">
//           <h2 className="text-lg font-bold mb-2">Frequently Asked Questions</h2>
//           {faqs.map((faq, index) => (
//             <div key={index} className="border-b border-gray-300">
//               <button
//                 onClick={() => toggleAccordion(index)}
//                 className="w-full flex justify-between py-3 text-left text-sm font-medium text-gray-700"
//               >
//                 <span>Q: {faq.question}</span>
//                 <span>{activeIndex === index ? "-" : "+"}</span>
//               </button>
//               {activeIndex === index && (
//                 <div className="pl-4 pb-3 text-gray-600 text-sm">
//                   {faq.answer}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>

//       </div>
//     </div>
//   );
// };

// export default ExamPattern;
// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";

// const ExamPattern = () => {
//   const navigate = useNavigate();
//   const { examName } = useParams();
//   const [examData, setExamData] = useState(null);
//   const [activeIndex, setActiveIndex] = useState(null);
  // const toggleAccordion = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };
//   useEffect(() => {
//     const fetchExamData = async () => {
//       try {
//         const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
//         if (!response.ok) throw new Error("Failed to fetch data");
//         const data = await response.json();
//         setExamData(data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchExamData();
//   }, [examName]);

//   if (!examData) {
//     return <p>Loading...</p>; // Display loading text while data is being fetched
//   }

//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800">
//           {examData.headerHeading} {examData.year} : {examData.addHeading}
//         </h1>

//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//             {examData.admitCardName}: {new Date(examData.admitCardDate).toLocaleDateString()}
//           </div>
//         </div>

//         {/* Exam Pattern Table */}
//         <h2 className="text-xl font-semibold mt-8">CAT Exam Pattern</h2>
//         <table className="w-full mt-4 bg-gray-50">
//           <tbody>
//             {examData.examPattern.map((pattern, index) => (
//               <tr key={index}>
//                 <td className="p-4 border">{pattern.section}</td>
//                 <td className="p-4 border">{pattern.questions} questions</td>
//                 <td className="p-4 border">{pattern.duration}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>

//         {/* FAQ Section */}
//         <h2 className="text-xl font-semibold mt-8">FAQs</h2>
//         {examData.faqs.map((faq, index) => (
//           <div key={index}>
//             <h3 onClick={() => toggleAccordion(index)} className="cursor-pointer text-lg font-medium">
//               {faq.question}
//             </h3>
//             {activeIndex === index && <p className="text-gray-600">{faq.answer}</p>}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ExamPattern;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ExamPattern = () => {
  const navigate = useNavigate();
  const { examName } = useParams();
  const [examData, setExamData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData) return <div>Loading...</div>;

  // Dynamic data mappings based on fetched data
  const {
    year,
    admitCardName,
    admitCardDate,
    examPattern,
    faqs,
    tabs,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
  } = examData;

  return (
    <div className="bg-gray-100 p-8">
      <div className="p-6 rounded-lg shadow-md bg-white">
        <h1 className="text-2xl font-bold text-gray-800 text-left">
          {headerHeading} {year} : {addHeading}
        </h1>

        <div className="flex items-center space-x-2 mt-4">
          <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {admitCardName}: {new Date(admitCardDate).toLocaleDateString()}
          </div>
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Paper
            </button> */}
          </div>
        </div>
      </div>

      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-6 bg-gray-50 text-gray-800">

        <div className="my-6">
          <h2 className="text-lg font-bold mb-2">CAT Exam Pattern</h2>
          <table className="w-full bg-white shadow-lg border border-gray-300 text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-4 border border-gray-300 text-left text-gray-800 font-semibold">
                  Section
                </th>
                <th className="p-4 border border-gray-300 text-center text-gray-800 font-semibold">
                  Questions
                </th>
                <th className="p-4 border border-gray-300 text-center text-gray-800 font-semibold">
                  Duration
                </th>
              </tr>
            </thead>
            <tbody>
              {examPattern.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-4 border border-gray-300 text-gray-700 font-medium">
                    {item.section}
                  </td>
                  <td className="p-4 border border-gray-300 text-center text-gray-600">
                    {item.questions}
                  </td>
                  <td className="p-4 border border-gray-300 text-center text-gray-600">
                    {item.duration}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="my-6">
          <h2 className="text-lg font-bold mb-2">Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-300">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between py-3 text-left text-sm font-medium text-gray-700"
              >
                <span>Q: {faq.question}</span>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </button>
              {activeIndex === index && (
                <div className="pl-4 pb-3 text-gray-600 text-sm">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamPattern;
