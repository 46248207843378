import React from 'react';
import { useNavigate } from 'react-router-dom';

const MainContent = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleTabClick = (tab) => {
    console.log(`Selected Tab: ${tab}`);
    
    // Check for specific tabs and navigate with smooth scroll
    switch(tab) {
      case 'All':
        navigate('/all');
        break;
      case 'BE/B.Tech':
        navigate('/btech');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100); // Smooth scroll to the top
        break;
      case 'MBA/PGDM':
        navigate('/MBA/PGDM');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'MBBS':
        navigate('/MBBS');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'ME/M.Tech':
        navigate('/MTech');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'B.Sc':
        navigate('/BSc');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'BA':
        navigate('/BA');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'B.Com':
        navigate('/Bcom');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'Mcom':
        navigate('/Mcom');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'BBA/BMS':
        navigate('/BBA/BMS');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      case 'M.Sc':
        navigate('/MSc');
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
        break;
      default:
        break;
    }
  };

  return (
    <div className=" mb-5 my-8">
      <h2 className="text-3xl font-bold text-center mb-3">Explore Programs</h2>

      <div className="p-10">
        <div className="tab-container text-center p-4 mb-6 overflow-x-auto whitespace-nowrap">
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("All")}
          >
            All
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("BE/B.Tech")}
          >
            BE/B.Tech
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("MBA/PGDM")}
          >
            MBA/PGDM
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("MBBS")}
          >
            MBBS
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("ME/M.Tech")}
          >
            ME/M.Tech
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("B.Sc")}
          >
            B.Sc
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("BA")}
          >
            BA
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("B.Com")}
          >
            B.Com
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("Mcom")}
          >
            M.Com
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("BBA/BMS")}
          >
            BBA/BMS
          </button>
          <button
            className="py-2 px-7 bg-gray-100 text-gray-600 rounded-full hover:bg-gray-200 transition inline-block mb-2 md:mb-0"
            onClick={() => handleTabClick("M.Sc")}
          >
            M.Sc
          </button>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Card 1: Ranking */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Ranking</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">College ranked based on real data</p>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-wrap gap-2 mb-4">
                {[
                  "Indiatoday - 1726",
                  "IIRF - 1444",
                  "Outlook - 1287",
                  "NIRF - 1293",
                ].map((ranking) => (
                  <span
                    key={ranking}
                    className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                  >
                    {ranking}
                  </span>
                ))}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Top Ranked Colleges in India &rarr;
              </a>
            </div>
          </div>

          {/* Card 2: Find Colleges */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Find Colleges</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">
                Discover 19000+ colleges via preferences
              </p>
              <hr className="my-4 border-gray-200" />
              <div className=" gap-2 mb-4">
                {[
                  "Best MBA colleges in India",
                  "Best BTech colleges in India",
                ].map((college) => (
                  <span
                    key={college}
                    className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                  >
                    {college}
                  </span>
                ))}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Discover Top Colleges in India &rarr;
              </a>
            </div>
          </div>

          {/* Card 3: Compare Colleges */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Compare Colleges</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">
                Compare on the basis of rank, fees, etc.
              </p>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-wrap gap-2 mb-4">
                {[
                  "IIT Madras BE/B.Tech",
                  "IIT Delhi BE/B.Tech",
                  "IIT Bombay BE/B.Tech",
                ].map((college) => (
                  <span
                    key={college}
                    className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                  >
                    {college}
                  </span>
                ))}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Compare Colleges in India &rarr;
              </a>
            </div>
          </div>

          {/* Card 4: Scholarships */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Scholarships</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">
                Discover various scholarship opportunities
              </p>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-wrap gap-2 mb-4">
                {["Merit-based Scholarships", "Need-based Scholarships"].map(
                  (scholarship) => (
                    <span
                      key={scholarship}
                      className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                    >
                      {scholarship}
                    </span>
                  )
                )}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Explore Scholarships &rarr;
              </a>
            </div>
          </div>

          {/* Card 5: Student Reviews */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Student Reviews</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">
                Read authentic reviews from students
              </p>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-wrap gap-2 mb-4">
                {["Read Reviews", "Write a Review"].map((action) => (
                  <span
                    key={action}
                    className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                  >
                    {action}
                  </span>
                ))}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Discover Student Reviews &rarr;
              </a>
            </div>
          </div>

          {/* Card 6: Admissions */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl">
            <div className="bg-[#68a4a5] p-4 flex items-center justify-between">
              <h2 className="text-lg font-bold">Admissions</h2>
            </div>
            <div className="p-6">
              <p className="text-gray-600">
                Find out the admission process for colleges
              </p>
              <hr className="my-4 border-gray-200" />
              <div className="flex flex-wrap gap-2 mb-4">
                {["Undergraduate Admissions", "Postgraduate Admissions"].map(
                  (admission) => (
                    <span
                      key={admission}
                      className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600"
                    >
                      {admission}
                    </span>
                  )
                )}
              </div>
              <hr className="my-4 border-gray-200" />
              <a href="#" className="text-[#68a4a5] hover:underline">
                Learn About Admissions &rarr;
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
