import React,{useState} from "react";
// import { Blacklogo } from "../../images";
import { Blacklogo } from "../../images";
import { Link } from "react-router-dom";
import { updateProfile } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  auth,
  sendEmailVerification,
} from "../firebase";

const Signup = ({}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState("");
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false); // New state

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Step 2: Update user profile with full name
      await updateProfile(user, { displayName: fullName });

      // Step 3: Send email verification
      await sendEmailVerification(user);
      setIsVerificationEmailSent(true); // Set state to show the success message
    } catch (error) {
      setError("Signup failed. Please try again.");
      console.log("Error during signup:", error.message);
    }
  };

  // if (!isOpen) return null;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg w-full max-w-md relative">
        {/* Close Button */}
        {/* <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button> */}

        {/* Logo */}
        <div className="flex justify-center mb-4">
          {/* <img src={Blacklogo} alt="Logo" className="h-8" />{" "} */}
          <img src={Blacklogo} alt="Logo" className="h-8"/>
        </div>
        {!isVerificationEmailSent ? (
          <>
            {/* Title */}
            <h2 className="text-2xl font-semibold text-center mb-2">
              Create Your Account
            </h2>
            <p className="text-center text-gray-600 mb-6">
              Sign up to enjoy our services
            </p>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {/* Form */}
            <form onSubmit={handleSignUp} className="space-y-4">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <button
                type="submit"
                className="bg-teal-500 text-white w-full py-3 rounded-lg hover:bg-teal-600 transition-all duration-200"
              >
                Sign Up
              </button>
            </form>

            {/* Log In Link */}
            <p className="text-center text-gray-600 mt-4">
              Already have an account?{" "}
              <Link
                to="/login"
                className="block mt-4 text-teal-500 text-sm hover:underline"
              >
                Log In
              </Link>
            </p>
          </>
        ) : (
          <div>
            <h2 className="text-[#68a4a5] text-2xl mb-4">Verify Your Email</h2>
            <p className="text-gray-700 mb-4">
              A verification email has been sent to <strong>{email}</strong>.
              Please check your inbox and verify your email address before
              logging in.
            </p>
            <button className="bg-[#68a4a5] text-white py-2 px-4 rounded-full w-1/2 hover:bg-teal-700 transition-colors">
              <Link to="/" className="block text-white">
                Close
              </Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;


//  import React, { useState } from "react";
//   const Signup = () => {
// return (
//   <h1>signup page</h1>
// );


//   }
//   export default Signup;

