import React,{useState} from "react";
import { Blacklogo } from "../../images";
import { sendPasswordResetEmail, auth } from "../firebase"; // Assuming you have Firebase setup

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous error message
    setMessage(""); // Clear any previous success message
    try {
      const response = await fetch("https://caderaedu.com/api/user/checkprovider", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        await sendPasswordResetEmail(auth, email);
        setMessage("Password reset email sent successfully!");
      } else {
        setError("User not found");
      }
      //setError("");
      //setMessage(""); // Clear any previous error message
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
      console.error("Error during password reset:", error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg w-full max-w-md relative">
        {/* Close Button */}
        {/* <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button> */}

        {/* Logo */}
        <div className="flex justify-center mb-4">
          <img src={Blacklogo} alt="Logo" className="h-8" />{" "}
          {/* Use your logo */}
        </div>

        {/* Title */}
        <h2 className="text-2xl font-semibold text-center mb-4">
          Forgot Password
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Enter your email to reset your password
        </p>

        {message && <p className="text-green-500 mb-4">{message}</p>}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Form */}
        <form onSubmit={handlePasswordReset} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
          <button
            type="submit"
            className="bg-teal-500 text-white w-full py-3 rounded-lg hover:bg-teal-600 transition-all duration-200"
          >
            Send Reset Email
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
