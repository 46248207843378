import React from "react";
import Sidebar from "./sidebar";

const Cutoff = (props) => {
  // Check if any data is available for the tables
  const isDataAvailable =
    props.CutoffAcademicProgramName1 ||
    props.CutoffCourses1 ||
    props.CutoffCoursesName1;

  // Fallback management table data
  const managementTable = (
    <div className="overflow-x-auto">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
        {props.ManagementCourseName}
      </h2>
      <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 md:p-4 text-left">
              {props.managementHeaderName1}
            </th>
            <th className="p-2 md:p-4 text-left">
              {props.managementheadernameCutoff1}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.MBADepartmenetName1 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName1}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage1}</td>
            </tr>
          )}
          {props.MBADepartmenetName2 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName2}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage2}</td>
            </tr>
          )}
          {props.MBADepartmenetName3 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName3}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage3}</td>
            </tr>
          )}
          {props.MBADepartmenetName4 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName4}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage4}</td>
            </tr>
          )}
          {props.MBADepartmenetName5 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName5}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage5}</td>
            </tr>
          )}
          {props.MBADepartmenetName6 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName6}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage6}</td>
            </tr>
          )}
          {props.MBADepartmenetName7 && (
            <tr className="border-b">
              <td className="p-2 md:p-4">{props.MBADepartmenetName7}</td>
              <td className="p-2 md:p-4">{props.MBAPersentage7}</td>
            </tr>
          )}
          {/* Fallback for no data */}
          {!props.MBADepartmenetName1 &&
            !props.MBADepartmenetName2 &&
            !props.MBADepartmenetName3 &&
            !props.MBADepartmenetName4 &&
            !props.MBADepartmenetName5 &&
            !props.MBADepartmenetName6 &&
            !props.MBADepartmenetName7 && (
              <tr>
                <td colSpan="2" className="p-2 md:p-4 text-center">
                  No data available
                </td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );


  return (
    <div className="max-w-7xl mx-auto p-6 flex flex-col lg:flex-row">
      <div className="lg:w-3/4 w-full lg:pr-6">
        <div className="mb-6">
          <p className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
            {props.CutoffParagraph1}
          </p>
        </div>

        {/* Table of Contents */}
        <div className="bg-gray-100 p-4 rounded-lg mb-6">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            {props.CutoffTopic}
          </h2>
          <p className="text-sm md:text-base">{props.CutoffAboutInfo}</p>
        </div>

        {/* Render JEE Advanced, GATE, and JAM tables only if data is available */}
        {isDataAvailable ? (
          <>
            {/* IIT Madras JEE Advanced Cutoff 2024 */}
            <div id="cutoff-2024" className="mb-8">
              <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
                {props.CollegeName} {props.CutoffAboutExam}
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 md:p-4 text-left">
                        Academic Program Name
                      </th>
                      <th className="p-2 md:p-4 text-left">
                        Round 1 Opening Rank
                      </th>
                      <th className="p-2 md:p-4 text-left">
                        Round 5 Closing Rank
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4].map((index) => (
                      <tr className="border-b" key={index}>
                        <td className="p-2 md:p-4">
                          {props[`CutoffAcademicProgramName${index}`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffAcademicOpeningranke${index}`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`cutoffAcademicClossingrank${index}`]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* IIT Madras GATE Cutoff 2024 */}
            <div id="gate-2024" className="mb-8">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">
                {props.CollegeName} {props.CutoffExamyear1}
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 md:p-4 text-left">Courses</th>
                      <th className="p-2 md:p-4 text-left">Round 1</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5].map((index) => (
                      <tr className="border-b" key={index}>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourcesRank${index}`]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* IIT Madras IIT JAM Cutoff 2024 */}
            <div id="jam-2024" className="mb-8">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">
                {props.CollegeName} {props.CutoffExamyear2}
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 md:p-4 text-left">Courses</th>
                      <th className="p-2 md:p-4 text-left">Round 1</th>
                      <th className="p-2 md:p-4 text-left">Round 2</th>
                      <th className="p-2 md:p-4 text-left">Round 3</th>
                      <th className="p-2 md:p-4 text-left">Round 4</th>
                      <th className="p-2 md:p-4 text-left">Round 5</th>
                      <th className="p-2 md:p-4 text-left">Round 6</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3].map((index) => (
                      <tr className="border-b" key={index}>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCoursesName${index}`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round1`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round2`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round3`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round4`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round5`]}
                        </td>
                        <td className="p-2 md:p-4">
                          {props[`CutoffCourses${index}Round6`]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          // Show management table if no data for cutoff is available
          managementTable
        )}
      </div>

      {/* Sidebar */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Cutoff;
