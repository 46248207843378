import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const Btech = (props) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const collegesPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await fetch(
          `https://caderaedu.com/api/college/filter?category=${encodeURIComponent(
            props.title
          )}`
        );
        const colleges = await response.json();
        console.log(colleges); // Debugging: check fetched data
        setData(colleges);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchColleges();
  }, [props.title]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [data]);

  const indexOfLastCollege = currentPage * collegesPerPage;
  const indexOfFirstCollege = indexOfLastCollege - collegesPerPage;
  const currentColleges = filteredData.slice(
    indexOfFirstCollege,
    indexOfLastCollege
  );

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / collegesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <section className="bg-white shadow-sm py-6">
        <div className="container mx-auto px-4 py-6">
          <h1 className="text-2xl font-semibold text-left">{props.title}</h1>
        </div>

        {/* Advertise */}
        <a href="#">
          <div className="flex flex-row justify-center items-center space-x-4 p-2 ">
            <img
              src="https://del1.vultrobjects.com/caderaedu/expo%20ad%20%282%29.jpg"
              className="w-1/4 h-auto object-cover" // Use width of 1/3 for three images in a row
            />
            <img
              src="https://del1.vultrobjects.com/caderaedu/Blank%20Company%20Profile%20Business%20Presentation%20in%20Red%20Maroon%20White%20Geometric%20Style.png"
              className="w-1/4 h-auto object-cover" // Use width of 1/3 for three images in a row
            />
            <img
              src="https://del1.vultrobjects.com/caderaedu/expo%20ad%20%282%29.jpg"
              className="w-1/4 h-auto object-cover" // Use width of 1/3 for three images in a row
            />
          </div>
        </a>

        {/* Writer Section */}
        <div className="border p-4 rounded-lg shadow-lg my-4 mx-4 sm:mx-auto">
          <div className="flex items-center">
            <img
              src="https://th.bing.com/th/id/OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa?w=176&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
              className="rounded-full w-12 h-12 mr-4"
            />
            <div>
              <h1 className="text-base font-bold">{props.name}</h1>
              <h1 className="text-xs">{props.designation}</h1>
            </div>
          </div>
          <div className="mt-4">
            {/* <p className="text-gray-700">{props.writerInfo}</p> */}
            <button
              onClick={handleReadMore}
              className="mt-2 text-blue-500 hover:underline"
            >
              {/* {isExpanded ? "Show Less" : "Read More"} */}
            </button>

            <div className="">
              <p className="text-gray-600">{props.contentAboutTitle}</p>
              <br />
              <br />
              <p className="text-gray-600">{props.contentAboutTitle1}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Colleges List */}
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-xl sm:text-2xl font-semibold mb-4">
          Found {filteredData.length} Colleges
        </h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="text-left py-2 px-2 sm:px-4">#</th>
                <th className="text-left py-2 px-2 sm:px-4">College Name</th>
                <th className="text-left py-2 px-2 sm:px-4">Location</th>
                <th className="text-left py-2 px-2 sm:px-4">Courses Offered</th>
                <th className="text-left py-2 px-2 sm:px-4">Fees</th>
                <th className="text-left py-2 px-2 sm:px-4">Ranking</th>
              </tr>
            </thead>
            <tbody>
              {currentColleges.map((college, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-2 sm:px-4">
                    {indexOfFirstCollege + index + 1}
                  </td>
                  <td className="py-2 px-2 sm:px-4">
                    <h3
                      className="text-lg font-semibold text-blue-600 cursor-pointer"
                      onClick={() => handleCollegeClick(college.name)}
                    >
                      {college.name}
                    </h3>
                  </td>
                  <td className="py-2 px-2 sm:px-4">
                    {college.location || "N/A"}
                  </td>
                  <td className="py-2 px-2 sm:px-4">
                    {college.course1 || "N/A"}
                  </td>
                  <td className="py-2 px-2 sm:px-4">
                    {college.course1Fee || "N/A"}
                  </td>
                  <td className="py-2 px-2 sm:px-4">
                    {college.college_rank || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <section className="container mx-auto mt-6 text-center p-4 sm:p-10">
        <button
          className={`px-3 sm:px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="mx-2 text-sm sm:text-base">
          Page {currentPage} of{" "}
          {filteredData.length === 0
            ? 1
            : Math.ceil(filteredData.length / collegesPerPage)}
        </span>
        <button
          className={`px-3 sm:px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 ${
            currentPage === Math.ceil(filteredData.length / collegesPerPage)
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={nextPage}
          disabled={
            currentPage === Math.ceil(filteredData.length / collegesPerPage)
          }
        >
          Next
        </button>
      </section>
    </div>
  );
};

export default Btech;
