import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword"; // Import the ForgotPassword component
import { Blacklogo, signUpBlock } from "../../images/index"; // Update the paths based on your project structure
import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  auth,
} from "../firebase"; // Update the import paths according to your Firebase configuration
import { useNavigate } from "react-router-dom";
const LoginPopup = ({ closePopup, openSignUpPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false); // State to toggle Forgot Password
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      await user.reload();
      if (user.emailVerified) {
        const token = await user.getIdToken();
        const response = await fetch("https://caderaedu.com/api/user/verify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          sessionStorage.setItem("user", JSON.stringify({
            email: user.email,
            displayName: user.displayName,
          }));

          console.log("User account created successfully.");
          closePopup();
          navigate("/profile");
        } else {
          setError("Error creating user account. Please try again.");
        }
      } else {
        setError("Please verify your email before logging in.");
      }
    } catch (error) {
      setError("Login failed. Please check your credentials.");
      console.error("Error during login:", error.message);
    }
  };

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      const response = await fetch("https://caderaedu.com/api/user/isAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        window.location.href = "http://localhost:3001/#/dashboard";
      } else {
        setError(data.message || "Login failed.");
      }
    } catch (error) {
      console.error("Error during admin login:", error);
      setError("Login failed. Check your credentials.");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      const user = auth.currentUser;
      const token = await auth.currentUser.getIdToken();

      const response = await fetch("https://caderaedu.com/api/user/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        sessionStorage.setItem("user", JSON.stringify({
          email: user.email,
          displayName: user.displayName,
        }));

        console.log("Google login successful");
        closePopup();
        navigate("/profile");
      } else {
        setError("Google login failed.");
      }
    } catch (error) {
      setError("Google login failed.");
      console.error("Google sign-in error:", error.message);
    }
  };

  return (
    <>
      {!showForgotPassword ? (
        <div className="flex justify-center items-center h-screen bg-blue-200 bg-opacity-20 fixed inset-0 z-50">
          <div className="relative flex w-full max-w-5xl bg-gray-100 rounded-lg shadow-lg overflow-hidden z-50">
            {/* Close Button */}
            <button
              onClick={closePopup}
              className="absolute top-4 right-4 z-1000 text-gray-1000 hover:text-gray-700 text-3xl p-2 rounded-full focus:outline-none"
            >
              &times;
            </button>

            {/* Left Side - Login Form */}
            <div className="w-full lg:w-3/5 p-10 bg-[#d9dad9] text-center">
              <img src={Blacklogo} alt="Logo1" className="mx-auto w-25 h-10" />
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Login to get started!
              </h2>
              <p className="text-gray-600 mb-6">Login using social networks</p>
              <div className="flex justify-center gap-4 mb-6">
                <button
                  onClick={handleGoogleLogin}
                  className="w-10 h-10 bg-red-600 text-white rounded-full text-lg"
                >
                  G
                </button>
              </div>
              <p className="flex items-center mb-6 text-gray-600">
                <span className="flex-1 border-t border-gray-400"></span>
                <span className="px-2">or</span>
                <span className="flex-1 border-t border-gray-400"></span>
              </p>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <form onSubmit={handleLogin}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
                  required
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full p-3 mb-6 border border-gray-300 rounded-lg"
                  required
                />
                <div className="flex justify-between">
                  <button className="w-5/12 py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition duration-200">
                    Sign In
                  </button>
                  <button
                    onClick={handleAdminLogin}
                    className="w-5/12 py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition duration-200"
                  >
                    Sign in as Admin
                  </button>
                </div>
              </form>
              <button
                onClick={() => setShowForgotPassword(true)}
                className="block mt-4 text-teal-500 text-sm hover:underline"
              >
                Forgot Password?
              </button>
              <div className="lg:hidden mt-4">
                <button
                  onClick={openSignUpPage}
                  className="text-teal-500 text-sm hover:underline"
                >
                  Don't have an account? Sign up
                </button>
              </div>
            </div>

            {/* Right Side - Signup Section (Hidden in mobile) */}
            <div
              className="hidden lg:flex lg:w-2/5 bg-teal-500 text-white text-center p-10 flex-col justify-center"
              style={{
                backgroundImage: `url(${signUpBlock})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h3 className="text-xl font-semibold mb-4">New Here?</h3>
              <p className="text-sm mb-6">
                Sign up and discover a great amount of new opportunities!
              </p>
              <button
                onClick={openSignUpPage}
                className="py-2 px-6 bg-white text-teal-500 rounded-full hover:bg-gray-100 transition duration-200"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      ) : (
        <ForgotPassword closePopup={() => setShowForgotPassword(false)} />
      )}
    </>
  );
};

export default LoginPopup;
