import React from "react";
import { Link } from "react-router-dom";

const ManagementBranch = ({
  isDropdownOpen,
  handleDropdownToggle,
  activeSubmenu,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const handleOptionSelect = () => {
    handleDropdownToggle(""); // Closing the dropdown by resetting the open state
  };

  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle("Management")}
        className="text-white"
      >
        MANAGEMENT {isDropdownOpen === "Management" ? "▲" : "▼"}
      </button>
      {isDropdownOpen === "Management" && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter("TopRankedMgmtColleges")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Top Ranked Colleges
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("PopularMgmtCourses")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Popular Courses
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("Exams")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Exams
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("MgmtCollegesByLocation")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Colleges By Location
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("MgmtRankPredictors")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Rank Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("MgmtCollegePredictors")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("MgmtCollegeReviews")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Reviews
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("MgmtResources")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Resources
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked Management Colleges */}
          {activeSubmenu === "TopRankedMgmtColleges" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter("TopRankedMgmtColleges")}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs">
                  <Link
                    to="/TopManagementCollegesinIndia"
                    onClick={handleOptionSelect}
                  >
                    Top Management Colleges in India
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopIIMsinIndia" onClick={handleOptionSelect}>
                    Top IIMs in India
                  </Link>
                </li>
                <li className="text-xs">
                  <Link
                    to="/TopManagementCollegesinBangalore"
                    onClick={handleOptionSelect}
                  >
                    Top Management Colleges in Bangalore
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/TopMBACollegesinPune" onClick={handleOptionSelect}>
                    Top Management Colleges in Pune
                  </Link>
                </li>
                <li className="text-xs">
                  <Link
                    to="/TopPrivateManagementColleges"
                    onClick={handleOptionSelect}
                  >
                    Top Private Management Colleges
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {/* Submenu for CAT Exam */}
          {activeSubmenu === "Exams" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter("Exams")}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-2">
                <li className="text-xs">
                  <Link to="/exam/CAT" onClick={handleOptionSelect}>
                    CAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/CMAT" onClick={handleOptionSelect}>
                    CMAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/SNAT" onClick={handleOptionSelect}>
                    SNAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/XAT" onClick={handleOptionSelect}>
                    XAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/MAT" onClick={handleOptionSelect}>
                    MAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/ATMA" onClick={handleOptionSelect}>
                    ATMA Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/NIMAT" onClick={handleOptionSelect}>
                    NIMAT by GMAC Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/IBSAT" onClick={handleOptionSelect}>
                    IBSAT Exam
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/exam/UPCET" onClick={handleOptionSelect}>
                    UPCET Exam
                  </Link>
                </li>
              </ul>
            </div>
          )}

          <div className="w-[200px] ml-auto">
            <h4 className="text-sm font-bold mb-2">Featured Colleges</h4>
            <ul className="space-y-2">
              <li className="text-xs hover:bg-gray-200 py-2 transition">
                ABC Management College
              </li>
              <div className="flex justify-center items-center p-4 bg-gray-100 rounded-lg shadow">
                <img
                  src=""
                 
                  className="max-w-full h-auto rounded-md"
                />
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagementBranch;
