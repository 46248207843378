import React, { useState } from "react";

const NewsletterSubscription = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [course, setCourse] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email, phone, course };

    try {
      const response = await fetch("https://caderaedu.com/api/newsletter/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.status === 201) {
        setResponseMessage(result.message);
      } else {
        setResponseMessage(result.message || "Something went wrong.");
      }
    } catch (error) {
      setResponseMessage("Failed to subscribe.");
    }
  };

  return (
    <div className="bg-gray-200 py-12">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-2xl font-bold mb-2">Subscribe To Our Newsletter</h2>
        <p className="text-gray-600 mb-6">
          Get College Notifications, Exam Notifications, and News Updates
        </p>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col md:flex-row justify-center items-center gap-4"
        >
          {/* Email Input */}
          <div className="relative w-full md:w-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full py-3 px-4 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Phone Input */}
          <div className="relative w-full md:w-auto">
            <input
              type="text"
              placeholder="Enter your mobile number"
              className="w-full py-3 px-4 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          {/* Course Selection Dropdown */}
          <div className="relative w-full md:w-auto">
            <select
              className="w-fit py-3 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 text-sm md:text-base appearance-none bg-white bg-no-repeat"
              value={course}
              onChange={(e) => setCourse(e.target.value)}
              required
              style={{ backgroundImage: 'url(data:image/svg+xml;base64,YOUR_ICON)' }} // Optional for custom arrow
            >
              <option value="" disabled>
                Choose your course
              </option>
              <option value="engineering">Engineering</option>
              <option value="management">Management</option>
              <option value="science">Science</option>
            </select>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-teal-600 text-white py-3 px-6 rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            Submit
          </button>
        </form>

        {responseMessage && <p className="mt-4">{responseMessage}</p>}
      </div>
    </div>
  );
};

export default NewsletterSubscription;
