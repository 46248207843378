import React, { useState } from 'react';
import './neetpg.css'; // Include the custom CSS file for styles
import data from './Neetpg-data.json'; // Your data
import CompulsoryTimesignup from '../CompulsoryTimesignup';

function App() {
  const [allottedQuota, setAllottedQuota] = useState('');
  const [candidateCategory, setCandidateCategory] = useState('');
  const [course, setCourse] = useState('');
  const [rank, setRank] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false); // Track if filter is applied
  const rowsPerPage = 10;
  const [showResults, setShowResults] = useState(false); // Control if the results are shown

  // Get unique values for dropdowns
  const uniqueAllottedQuotas = [...new Set(data.map(item => item['Allotted Quota']))];
  const uniqueCandidateCategories = [...new Set(data.map(item => item['Candidate Category']))];
  const uniqueCourses = [...new Set(data.map(item => item['Course']))];
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission
  const [showSignupModal, setShowSignupModal] = useState(false); // State to control modal visibility

  const handleFilter = () => {
    // Check if all fields are filled
    if (!allottedQuota || !candidateCategory || !course || !rank) {
      alert('Please fill all the fields to predict institutes.');
      return; // Do not proceed if any field is empty
    }

    const filtered = data
      .filter(item => 
        (allottedQuota ? item['Allotted Quota'] === allottedQuota : true) &&
        (candidateCategory ? item['Candidate Category'] === candidateCategory : true) &&
        (course ? item['Course'] === course : true) &&
        (rank ? item['Rank'] >= Number(rank) : true)
      )
      .sort((a, b) => a['Rank'] - b['Rank']); // Sort by rank

    // Get unique institutes by name
    const uniqueInstitutes = [];
    const uniqueInstituteNames = new Set();
    filtered.forEach(item => {
      if (!uniqueInstituteNames.has(item['Allotted Institute'])) {
        uniqueInstitutes.push(item);
        uniqueInstituteNames.add(item['Allotted Institute']);
      }
    });

    setFilteredData(uniqueInstitutes);
    setCurrentPage(1); // Reset to first page after applying filter
    setShowResults(true); // Show the results
  };

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div className="app-container">
      <h1 className="title">NEET-PG Predictor</h1>
      {/* Centered Heading */}
      <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true); // Set form submitted to true once form is completed
          setShowSignupModal(false); // Close modal after successful submission
        }}
        showSignupModal={showSignupModal} // Pass down the state for modal visibility
        onCloseModal={() => setShowSignupModal(false)} // Function to close the modal
      />

      <div className="filter-container">
        <div className="filter-item">
          <label>Allotted Quota:</label>
          <select 
            value={allottedQuota} 
            onChange={e => setAllottedQuota(e.target.value)}
          >
            <option value="">Select Allotted Quota</option>
            {uniqueAllottedQuotas.map((quota, index) => (
              <option key={index} value={quota}>{quota}</option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Candidate Category:</label>
          <select 
            value={candidateCategory} 
            onChange={e => setCandidateCategory(e.target.value)}
          >
            <option value="">Select Candidate Category</option>
            {uniqueCandidateCategories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Course:</label>
          <select 
            value={course} 
            onChange={e => setCourse(e.target.value)}
          >
            <option value="">Select Course</option>
            {uniqueCourses.map((course, index) => (
              <option key={index} value={course}>{course}</option>
            ))}
          </select>
        </div>

        <div className="filter-item">
          <label>Rank:</label>
          <input 
            type="number" 
            value={rank} 
            onChange={e => setRank(e.target.value)} 
            placeholder="Enter Rank"
          />
        </div>

        <button className="filter-btn" onClick={handleFilter}>Predict Institutes</button>
      </div>

      {showResults && (
        <>
          <h2 className="results-title">Predict Institutes</h2>

          {filteredData.length === 0 ? (
            <p className="no-results-message">You are not able to get the college on this rank or course not available on this quota</p>
          ) : (
            <>
              <table className="results-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Allotted Institute</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{item['Allotted Institute']}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <button 
                  className="pagination-btn" 
                  onClick={handlePreviousPage} 
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="pagination-info">
                  Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}
                </span>
                <button 
                  className="pagination-btn" 
                  onClick={handleNextPage} 
                  disabled={currentPage * rowsPerPage >= filteredData.length}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;
