import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faBuilding, faGraduationCap, faCheck } from '@fortawesome/free-solid-svg-icons';
import {gatelogo1,pgecetlogo,tancetlogo,bitshdlogo,upseelogo,vitmeelogo,nimcetlogo,jamlogo,mhtcetlogo} from "../../images/index";
// M.Tech Exams array
const mtechExams = [
    { id: 1, 
      name: "GATE", 
      icon: gatelogo1,
    },
    { id: 2, 
      name: "PGECET", 
      icon: pgecetlogo,
    },
    { id: 3, 
      name: "TANCET", 
      icon: tancetlogo ,
    },
    { id: 4, 
      name: "BITS HD", 
      icon: bitshdlogo,
    },
    { id: 5, 
      name: "UPSEE", 
      icon: upseelogo ,
    },
    { id: 6, 
      name: "VITMEE", 
      icon: vitmeelogo,
    },
    { id: 7, 
      name: "NIMCET", 
      icon: nimcetlogo,
    },
    { id: 8, 
      name: "JAM", 
      icon:jamlogo ,
    },
    { id: 9, 
      name: "MHT CET", 
      icon: mhtcetlogo,
    },
    // { id: 10, 
    //   name: "Karnataka PGCET", 
    //   icon: ,
    // },
    // { id: 11, 
    //   name: "SRMGEET", 
    //   icon: ,
    // },
    // { id: 12, 
    //   name: "AP PGECET", 
    //   icon: ,
    // },
    // { id: 13, 
    //   name: "UP PG", 
    //   icon: ,
    // },
    // { id: 14, 
    //   name: "NPTEL", 
    //   icon: ,
    // },
    // { id: 15, 
    //   name: "DTU M.Tech", 
    //   icon: ,
    // },
    // Add more exams as needed
];

const MTechPredictormobileview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExams, setSelectedExams] = useState([]);
  const itemsPerPage = 15; // Total items to show per page
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = mtechExams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(mtechExams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    if (selectedExams.includes(exam.name)) {
      setSelectedExams(selectedExams.filter((name) => name !== exam.name));
    } else {
      setSelectedExams([...selectedExams, exam.name]);
    }
  };
 
  const handleNextClick = () => {
    if (selectedExams.length > 0) {
      const selectedExam = selectedExams[0];

      // Update navigation based on selected M.Tech exam
      if (selectedExam === "GATE") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "PGECET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "TANCET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "BITS HD") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "UPSEE") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "VITMEE") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "NIMCET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "JAM") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "MHT CET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "Karnataka PGCET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "SRMGEET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "AP PGECET") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "UP PG") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "NPTEL") {
        navigate("/#"); // Replace with actual route
      } else if (selectedExam === "DTU M.Tech") {
        navigate("/#"); // Replace with actual route
      } else {
        console.log("No valid exam selected for navigation");
      }
    }
  };

  return (
    <div className="md:hidden bg-gray-100 flex flex-col items-center p-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">
        M.Tech Course Predictor 2024
      </h2>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on M.Tech exams you have taken.
      </p>
      <h3 className="text-left font-bold mb-4 text-black">
                 Select exam you have taken:
            </h3>
      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative"
              onClick={() => handleExamSelect(exam)}
            >
              <div className="flex justify-center items-center w-16 h-16 mx-auto mb-2">
              <img
                                src={exam.icon}
                                alt={exam.name}
                                className="w-full h-full object-contain"
                            />
              </div>
              <input
                type="checkbox"
                checked={selectedExams.includes(exam.name)}
                className="absolute inset-0 w-full h-full cursor-pointer opacity-0"
              />
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-black">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className="text-sm font-semibold text-gray-800 mt-1">{exam.name}</p>
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          disabled={selectedExams.length === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MTechPredictormobileview;
