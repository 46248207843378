import React from 'react';
import Sidebar from './sidebar';

const Scholarship = (props) => {
  // Check if scholarship data is available
  const hasScholarshipData = props.ScholarshipName1 || props.ScholarshipName2 || props.ScholarshipName3 || 
                             props.ScholarshipName4 || props.ScholarshipName5 || props.ScholarshipName6 || 
                             props.ScholarshipName7 || props.ScholarshipName8 || props.ScholarshipName9 || 
                             props.ScholarshipName10;

  return (
    <div className="flex flex-col lg:flex-row p-5 lg:space-x-4">
      {/* Main Content */}
      <main className="lg:w-2/3 w-full py-8">
        <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2">
          {props.ScholarshipDescription}
        </p>
        <h2 className="text-xl sm:text-2xl p-4 md:text-3xl lg:text-4xl font-bold">
          Institute's Scholarships:
        </h2>

        {/* Conditional rendering based on scholarship data availability */}
        {hasScholarshipData ? (
          // Scholarship Table
          <div className="overflow-x-auto p-4">
            {" "}
            {/* Added this div for horizontal scrolling */}
            <table className="table-auto w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-100 border-b border-gray-300">
                  <th className="p-4 text-gray-700">Name of the Scholarship</th>
                  <th className="p-4 text-gray-700">Eligibility Criteria</th>
                  <th className="p-4 text-gray-700">Award Scholarship</th>
                </tr>
              </thead>
              <tbody>
                {/* Map through the scholarships */}
                {Array.from({ length: 10 }, (_, index) => {
                  const name = props[`ScholarshipName${index + 1}`];
                  const criteria =
                    props[`ScholarshipEligibilCriteria${index + 1}`];
                  const award = props[`ScholarshipAward${index + 1}`];

                  return name ? (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="p-4 text-gray-700">{name}</td>
                      <td className="p-4 text-gray-600">{criteria}</td>
                      <td className="p-4 text-gray-800">{award}</td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          </div>
        ) : (
          // Fallback content when no scholarship data is available
          <p className="text-gray-600 mt-4">
            Currently, there are no scholarships data available for this
            institute right now.
          </p>
        )}
      </main>

      {/* Sidebar Section - Right on desktop, below on mobile */}

      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
}

export default Scholarship;
