import React from "react";
import { collegebg } from "../../images/index";
import { useNavigate } from "react-router-dom";
const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};
const CollegeCard = ({ college }) => {
  const navigate = useNavigate();

  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };
  return (
    <div className="bg-white rounded-lg shadow-lg p-2 max-w-sm transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-2xl sm:p-4">
      {/* Card Header */}
      <div
        className="flex items-center mb-2 sm:mb-4"
        style={{
          backgroundImage: `url(${college.CampusFacility1PhotoURL})`,
          backgroundPosition: "center",
          height: "160px", // Reduced height for mobile
        }}
      >
        <img
          src={college.logo_link}
          alt="College Logo"
          className="w-10 h-10 rounded-full object-cover mr-3 sm:w-12 sm:h-12"
        />
        <div>
          <button className="text-sm font-semibold text-white sm:text-lg" onClick={()=>handleCollegeClick(college.name)}>
            {college.name}
          </button>
          <p className="text-xs text-white flex items-center sm:text-sm">
            📍 {college.location} •{" "}
            <span className="text-white font-bold ml-1">#Rank: {college.college_rank || "N/A"}</span>
            <span className="text-white font-bold ml-1">#Rank: {college.rankbasis || "N/A"}</span>
          </p>
        </div>
      </div>

      {/* Card Body - Vertical Layout */}
      <div className="border-t border-gray-200 pl-3 sm:pl-4">
        {/* Courses Offered */}
        <div className="text-blue-600 text-xs sm:text-sm mb-1 sm:mb-2">
          <button
            onClick={()=>handleCollegeClick(college.name)}
            className="font-bold hover:underline block"
          >
             courses
          </button>
        </div>

        {/* Hostel Facility */}
        <div className="text-blue-600 text-xs sm:text-sm mb-1 sm:mb-2">
        <button
            onClick={()=>handleCollegeClick(college.name)}
            className="font-bold hover:underline block"
          >
             Hostel
          </button>
        </div>

        {/* Facilities Offered */}
        <div className="text-blue-600 text-xs sm:text-sm mb-1 sm:mb-2">
        <button
            onClick={()=>handleCollegeClick(college.name)}
            className="font-bold hover:underline block"
          >
           Facilities
          </button>
        </div>

        {/* Rating */}
        <div className="mb-1 sm:mb-2 flex justify-end">
          <div className="text-yellow-500 text-xs sm:text-sm flex items-center">
            ⭐ 4.8
          </div>
        </div>
      </div>

      {/* Fees Range */}
      <div className="text-right text-xs sm:text-sm text-gray-800 mt-2">
        <span className="block">Total Fees</span>
        <p className="font-bold">{college.course1Fee}</p>
      </div>

      {/* Card Footer */}
      <div className="flex justify-center space-x-2 pt-2 border-t border-gray-200 sm:space-x-4">
        <button className="bg-[#68bebf] text-white px-3 py-1 rounded-md text-xs sm:px-4 sm:py-2 hover:bg-[#5a9293] transition-colors"
         onClick={()=>handleCollegeClick(college.name)}>
          Fees
        </button>

        <a href={college.brochurelink} target="_blank" rel="noopener noreferrer">
        <button className="bg-[#68bebf] text-white px-3 py-1 rounded-md text-xs sm:px-4 sm:py-2 hover:bg-[#5a9293] transition-colors">
          Download Brochure
          </button>
          </a>
      </div>
    </div>
  );
};

export default CollegeCard;
