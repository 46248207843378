import React from 'react';

const Studygoalsmobileview = () => {
  const studyGoals = [
    { name: 'Engineering', colleges: 6231, icon: '👷', link: '/Btech' },
    { name: 'Management', colleges: 7675, icon: '📊', link: '/MBA/PGDM' },
    { name: 'Commerce', colleges: 4933, icon: '🛒', link: '#' },
    { name: 'Arts', colleges: 5550, icon: '🎨', link: '#' },
    { name: 'Medical', colleges: 2347, icon: '🧬', link: '/MBBS' },
    { name: 'Design', colleges: 1392, icon: '👗', link: '#' },
  ];

  return (
    <div className="sm:hidden w-full p-4 bg-gradient-to-r from-customTeal to-yellow-500 rounded-lg shadow-lg">
      <h1 className="text-2xl font-extrabold text-black mb-6 text-center">Select Your Study Goal</h1>
      <ul className="space-y-4">
        {studyGoals.map((goal, index) => (
          <li key={index} className="flex items-center justify-between py-2 border-b border-black-1000 hover:bg-teal-500 transition duration-300">
            <a href={goal.link} className="flex items-center justify-between w-full text-black">
              <div className="flex items-center space-x-2">
                <span className="text-2xl">{goal.icon}</span>
                <div>
                  <p className="text-lg font-semibold">{goal.name}</p>
                  <p className="text-sm text-blue-500">{goal.colleges} Colleges</p>
                </div>
              </div>
              <span className="text-xl">{'>'}</span>
            </a>
          </li>
        ))}
      </ul>
      {/* <a href="/Btech" className="w-full mt-6">
        <button className="w-full py-2 bg-white text-black text-center font-semibold rounded transition duration-300 ease-in-out hover:bg-orange-400 hover:text-white">
          More Courses
        </button>
      </a> */}
    </div>
  );
};

export default Studygoalsmobileview;