import React, { useState } from "react";
import { Blacklogo } from "../../images/index"; // Update path as per your directory structure
import { sendPasswordResetEmail, auth } from "../firebase"; // Assuming you have Firebase setup

const ForgotPassword = ({ closePopup }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Function to handle password reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous error message
    setMessage(""); // Clear any previous success message
    try {
      const response = await fetch("https://caderaedu.com/api/user/checkprovider", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        await sendPasswordResetEmail(auth, email);
        setMessage("Password reset email sent successfully!");
      } else {
        setError("User not found");
      }
      //setError("");
      //setMessage(""); // Clear any previous error message
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
      console.error("Error during password reset:", error.message);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-blue-200 bg-opacity-20 fixed inset-0 z-50">
      <div className="relative flex w-full max-w-2xl bg-gray-100 rounded-lg shadow-lg overflow-hidden">
        {/* 'X' Close Button */}
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-1000 hover:text-gray-1000 text-3xl p-4 rounded-full"
        >
          &times;
        </button>

        {/* Form Section */}
        <div className="w-full p-10 bg-[#d9dad9] text-center">
          <img src={Blacklogo} alt="Logo" className="mx-auto w-10 mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Forgot Password
          </h2>
          <p className="text-gray-600 mb-6">
            Enter your email to reset your password
          </p>

          {/* Display success or error message */}
          {message && <p className="text-green-500 mb-4">{message}</p>}
          {error && <p className="text-red-500 mb-4">{error}</p>}

          {/* Password Reset Form */}
          <form onSubmit={handlePasswordReset}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
              required
            />
            <button
              type="submit"
              className="w-full py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition duration-200"
            >
              Send Reset Email
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
