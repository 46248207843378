import React from "react";
import { Link } from "react-router-dom";

const Topcoursesmobileview = () => {
  const admissions = [
    { name: "BE/B.Tech", url: "/Btech" },
    { name: "Ph.D", url: "/PhD" },
    { name: "ME/M.Tech", url: "/MTech" },
    { name: "MBA/PGDM", url: "/MBA/PGDM" },
    { name: "BBA/BMS", url: "/BBA/BMS" },
    { name: "MBBS", url: "/MBBS" },
    { name: "PG Medical", url: "/PGMedical" },
    { name: "B.Com", url: "/Bcom" },
    { name: "M.Com", url: "/Mcom" },
    { name: "BA", url: "/BA" },
    { name: "MA", url: "/MA" },
    { name: "BFA", url: "/BFA" },
    { name: "BSW", url: "/BSW" },
    { name: "B.Sc", url: "/BSc" },
    { name: "M.Sc", url: "/MSc" },
  ];

  return (
    <div className="bg-gray-50 flex items-start justify-center py-6 sm:hidden">
      <div className="text-center">
        <h2 className="text-lg font-bold mb-2">Top Courses</h2>
        <div className="grid grid-cols-3 gap-2 px-2">
          {admissions.map((item, index) => (
            <Link
              to={item.url}
              key={index}
              className="border rounded-full py-1 px-2 text-center text-xs font-medium text-gray-700 hover:bg-gray-100 transition"
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topcoursesmobileview;
